import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import GlDialogTitle from '../../share/dialog/GlDialogTitle'
import GlDialogBody from '../../share/dialog/GlDialogBody'
import { deleteReportByIdService } from '../../../core/api/services/bookmetrie/reports.service'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../store/hooks'
import GlSpinner from '../../share/GlSpinner'
import deletePrivateReportsService from '../../../core/api/services/bookmetrie/private-reports/delete-private-reports.service'
import { AnalyticsDashboardDialogButton } from './AnalyticsDashboardDialogStyle'

const AnalyticsDashboardDeleteDialog = ({
  analyticsId,
  analyticsName,
  handleCancelAction,
  handleReloadAction = undefined,
  isPrivate,
}: {
  analyticsId: string
  analyticsName: string
  handleCancelAction: () => void
  handleReloadAction?: () => void
  isPrivate: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [isLoadingDeleteAction, setIsLoadingDeleteAction] =
    useState<boolean>(false)

  const deleteReport = (service: (id: string) => Promise<void>, id: string) => {
    setIsLoadingDeleteAction(true)
    service(id)
      .then(() => {
        dispatch(
          setShowSnackBar(
            t('snackbarMessage.deleteReportConfirm', {
              reportName: analyticsName,
            }),
          ),
        )
        handleReloadAction?.()
        handleCancelAction()
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => setIsLoadingDeleteAction(false))
  }

  const onClickCancelButton = (event: React.MouseEvent) => {
    event.preventDefault()
    handleCancelAction()
  }

  const onClickDeleteButton = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsLoadingDeleteAction(true)
    if (isPrivate) {
      deleteReport(deletePrivateReportsService, analyticsId)
    } else {
      deleteReport(deleteReportByIdService, analyticsId)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <GlDialogTitle label={analyticsName} />
      <GlDialogBody>
        <span>{t('webApp.reports.confirmDeleteAnalytics')}</span>
      </GlDialogBody>
      <div className="flex flex-row gap-4">
        <AnalyticsDashboardDialogButton
          className="flex-1"
          onClick={onClickCancelButton}
        >
          {t('commons.buttons.cancel')}
        </AnalyticsDashboardDialogButton>
        <AnalyticsDashboardDialogButton
          className="relative flex-1"
          primary
          onClick={onClickDeleteButton}
          disabled={isLoadingDeleteAction}
        >
          {(isLoadingDeleteAction && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <GlSpinner />
            </div>
          )) ||
            null}
          {t('commons.buttons.delete')}
        </AnalyticsDashboardDialogButton>
      </div>
    </div>
  )
}

export default AnalyticsDashboardDeleteDialog
