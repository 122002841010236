import { clilReferencesConfig } from '../config/clil-references.config'

export const ROOT_PARENT_CLIL_KEY = 'null'
const getClilLevelUtils = (clilLevel: string): number => {
  const clil = clilReferencesConfig[clilLevel]
  if (clil[4]) return 4
  if (clil[3]) return 3
  if (clil[2]) return 2
  return 1
}
export const clilLevelMapping: { [key: string]: number } = Object.keys(
  clilReferencesConfig,
).reduce((acc, obj) => {
  const clil = getClilLevelUtils(obj)
  return { ...acc, [obj]: clil }
}, {})

export const clilChildrenMapping: { [key: string]: string[] } = Object.keys(
  clilReferencesConfig,
).reduce((acc, obj) => {
  const clil = clilReferencesConfig[obj]
  const level = clilLevelMapping[obj]
  let parentClil = ROOT_PARENT_CLIL_KEY
  if (level > 1) {
    const parentLevel = level - 1
    parentClil = clil[parentLevel].id
  }
  const copy: { [key: string]: string[] } = { ...acc }
  if (!copy[parentClil]) {
    copy[parentClil] = []
  }
  copy[parentClil].push(obj)
  return copy
}, {})
