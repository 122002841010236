import React from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import GlLoader from '../../../../../share/GlLoader'
import { BookSearchInterface } from '../../../../../../core/interface/book-search.interface'
import { ClilMenuStyle } from '../../ReadershipProfileStyles'
import TitleItemMenu from './TitleItemMenu'

const TitlesMenu = ({
  isLoading,
  books,
  inputValueEan,
  handleOnClickTitleItem,
}: {
  isLoading: boolean
  books: BookSearchInterface[]
  inputValueEan: number[]
  handleOnClickTitleItem: (book: BookSearchInterface) => void
}) => {
  const { t } = useTranslation()
  return (
    <ClilMenuStyle
      className="absolute w-full flex flex-col top-0"
      style={{
        zIndex: 30,
      }}
    >
      {(isLoading && (
        <div className="flex-auto items-center justify-center p-1">
          <GlLoader width={36} height={36} />
        </div>
      )) ||
        (books.length && (
          <PerfectScrollbar className="relative flex flex-col max-h-[264px] p-1">
            {books.map((bookSearch: BookSearchInterface) => (
              <TitleItemMenu
                key={`title-item-book-${bookSearch.id_ean}`}
                book={bookSearch}
                inputValueEan={inputValueEan}
                handleOnClickTitleItem={handleOnClickTitleItem}
              />
            ))}
          </PerfectScrollbar>
        )) || (
          <div className="flex items-center justify-center p-2">
            <span>{t('yourSearchDoesNotProvideAnyResults')}</span>
          </div>
        )}
    </ClilMenuStyle>
  )
}

export default TitlesMenu
