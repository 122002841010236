import { nanoid } from 'nanoid'
import { RankItemActionInterface } from '../../interface/rank/rank-item-filter-action.interface'
import {
  ItemFilterActionEnum,
  ItemSortActionEnum,
} from '../../enums/item-filter-action.enum'

import ProgressIconActive from '../../../assets/icons/progress-icon-active.svg'
import ProgressIconInactive from '../../../assets/icons/progress-icon-inactive.svg'
import AuthorIconActive from '../../../assets/icons/author-icon-active.svg'
import AuthorIconInactive from '../../../assets/icons/author-icon-inactive.svg'
import ReleaseIconActive from '../../../assets/icons/release-year-icon-active.svg'
import ReleaseIconInactive from '../../../assets/icons/release-year-icon-inactive.svg'
import EditorIconActive from '../../../assets/icons/editor-icon-active.svg'
import EditorIconInactive from '../../../assets/icons/editor-icon-inactive.svg'
import SegmentIconActive from '../../../assets/icons/segment-icon-active.svg'
import SegmentIconInactive from '../../../assets/icons/segment-icon-inactive.svg'
import BookFormatIconActive from '../../../assets/icons/book-format-icon-active.svg'
import BookFormatIconInactive from '../../../assets/icons/book-format-icon-inactive.svg'
import RankingIconActive from '../../../assets/icons/ranking-icon-active.svg'
import RankingIconInactive from '../../../assets/icons/ranking-icon-inactive.svg'
import TitleIconActive from '../../../assets/icons/title-icon-active.svg'
import TitleIconInactive from '../../../assets/icons/title-icon-inactive.svg'

export const FILTER_KEY_RANK = 'rank'
export const FILTER_KEY_PROGRESS = 'progress'
export const FILTER_KEY_DELTA_RANK = 'delta_rank'
export const FILTER_KEY_TITLE = 'title'
export const FILTER_KEY_AUTHORS = 'authors'
export const FILTER_KEY_RELEASE_DATE = 'releaseDate'
export const FILTER_KEY_PUBLISHING_DATE = 'publishing_date'
export const FILTER_KEY_ISBN = 'id_ean'
export const FILTER_KEY_EDITOR = 'editor'
export const FILTER_KEY_SEGMENT1 = 'segment1'
export const FILTER_KEY_SEGMENT2 = 'segment2'
export const FILTER_KEY_FORMAT = 'format'

export const ItemsFilterActionConfig: RankItemActionInterface[] = [
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Progress,
    name: 'Progression',
    activeIcon: ProgressIconActive,
    inactiveIcon: ProgressIconInactive,
    active: false,
    key: FILTER_KEY_PROGRESS,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Author,
    name: 'Auteur',
    activeIcon: AuthorIconActive,
    inactiveIcon: AuthorIconInactive,
    active: false,
    key: FILTER_KEY_AUTHORS,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.ReleaseYear,
    name: 'Date parution',
    activeIcon: ReleaseIconActive,
    inactiveIcon: ReleaseIconInactive,
    active: false,
    key: FILTER_KEY_RELEASE_DATE,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Editor,
    name: 'Éditeur',
    activeIcon: EditorIconActive,
    inactiveIcon: EditorIconInactive,
    active: false,
    key: FILTER_KEY_EDITOR,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Segment1,
    name: 'Catégorie 1',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    key: FILTER_KEY_SEGMENT1,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Segment2,
    name: 'Catégorie 2',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    key: FILTER_KEY_SEGMENT2,
  },
  {
    id: nanoid(5),
    value: ItemFilterActionEnum.Format,
    name: 'Format',
    activeIcon: BookFormatIconActive,
    inactiveIcon: BookFormatIconInactive,
    active: false,
    key: FILTER_KEY_FORMAT,
  },
]
export const ItemsSortActionConfig: RankItemActionInterface[] = [
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Ranking,
    name: 'Rang',
    activeIcon: RankingIconActive,
    inactiveIcon: RankingIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_RANK,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Progress,
    name: 'Progression',
    activeIcon: ProgressIconActive,
    inactiveIcon: ProgressIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_PROGRESS,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Title,
    name: 'Titre',
    activeIcon: TitleIconActive,
    inactiveIcon: TitleIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_TITLE,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Author,
    name: 'Auteur',
    activeIcon: AuthorIconActive,
    inactiveIcon: AuthorIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_AUTHORS,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.ReleaseYear,
    name: 'Date parution',
    activeIcon: ReleaseIconActive,
    inactiveIcon: ReleaseIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_RELEASE_DATE,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Editor,
    name: 'Éditeur',
    activeIcon: EditorIconActive,
    inactiveIcon: EditorIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_EDITOR,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Segment1,
    name: 'Catégorie 1',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_SEGMENT1,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Segment2,
    name: 'Catégorie 2',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_SEGMENT2,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Format,
    name: 'Format',
    activeIcon: BookFormatIconActive,
    inactiveIcon: BookFormatIconInactive,
    active: false,
    chosen: false,
    key: FILTER_KEY_FORMAT,
  },
]

export const ItemsGroupActionConfig: RankItemActionInterface[] = [
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Author,
    name: 'Auteur',
    activeIcon: AuthorIconActive,
    inactiveIcon: AuthorIconInactive,
    active: false,
    key: FILTER_KEY_AUTHORS,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Editor,
    name: 'Éditeur',
    activeIcon: EditorIconActive,
    inactiveIcon: EditorIconInactive,
    active: false,
    key: FILTER_KEY_EDITOR,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Segment1,
    name: 'Catégorie 1',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    key: FILTER_KEY_SEGMENT1,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Segment2,
    name: 'Segment 2',
    activeIcon: SegmentIconActive,
    inactiveIcon: SegmentIconInactive,
    active: false,
    key: FILTER_KEY_SEGMENT2,
  },
  {
    id: nanoid(5),
    value: ItemSortActionEnum.Format,
    name: 'Format',
    activeIcon: BookFormatIconActive,
    inactiveIcon: BookFormatIconInactive,
    active: false,
    key: FILTER_KEY_FORMAT,
  },
]
