import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import {
  selectWebAppStateCompanyUserId,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasPrivateReportsAccess,
} from '../../store/getters/web-app.getters'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  selectAnalyticDashboardPrivateReports,
  selectIsLoadingPrivateReports,
} from '../../store/getters/private-reports-dashboard.getters'
import { analyticsDashboardGetPrivateReports } from '../../store/actions/private-reports-dashboard.actions'
import AnalyticsDashBoard from '../../components/analytics-dashboard/AnalyticsDashboard'
import privateReportsToggleOptionConfig from '../../core/config/analytics-dashboard/private-reports-toggle-option.config'
import privateReportDashboardModulesConfig from '../../core/config/analytics-dashboard/private-report-dashboard-modules.config'

const PrivateReportsDashBoard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isLoadingPrivateReports = useAppSelector(selectIsLoadingPrivateReports)
  const privateReports = useAppSelector(selectAnalyticDashboardPrivateReports)

  const mySelf = useAppSelector(selectWebAppStateMySelf)

  const hasPrivateReportsAccess = useAppSelector(
    selectWebAppStateMySelfHasPrivateReportsAccess,
  )

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  useEffect(() => {
    if (mySelf && !hasPrivateReportsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    } else {
      void dispatch(analyticsDashboardGetPrivateReports())
    }
  }, [mySelf, hasPrivateReportsAccess])

  const reloadPage = () => {
    void dispatch(analyticsDashboardGetPrivateReports())
  }

  return (
    (hasPrivateReportsAccess && (
      <AnalyticsDashBoard
        reports={privateReports}
        isLoadingReports={isLoadingPrivateReports}
        analyticsTypesOptions={privateReportsToggleOptionConfig}
        dashboardModules={privateReportDashboardModulesConfig}
        handleReloadAction={reloadPage}
        companyUserId={companyUserId}
        isPrivate
      />
    )) ||
    null
  )
}
export default PrivateReportsDashBoard
