import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTopsByCatalogIdService } from '../../core/api/services/bookmetrie/get-tops-by-catalog-id.service'
import { postTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/post-top-requests-saved.service'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { RootState } from '../store'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { getB2bBookDetailsByEanIdService } from '../../core/api/services/others/get-b2b-book-details-by-ean-id.service'
import { getTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/get-top-requests-saved.service'
import { putTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/put-top-requests-saved.service'
import { deleteTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/delete-top-requests-saved.service'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import getBookRecoV3Service from '../../core/api/services/bookmetrie/get-book-reco-v3.service'
import { DYNAMICS_RESULTS_POST_TOP_REQUEST_SAVED_ACTION } from '../prefix-actions.config'

export const dynamicsResultsStateGetTopsByCatalogId = createAsyncThunk(
  'dynamics-results/get-tops',
  async (
    {
      tag_group,
      catalogId,
      period,
      startDate,
      endDate,
      gender,
      age,
    }: {
      tag_group: string
      catalogId: string
      period: PeriodTypeEnum
      startDate: string
      endDate: string
      gender: GenderFilterEnum
      age: AgeFilterEnum
    },
    { getState },
  ): Promise<any> => {
    try {
      const { dynamicsResultsState } = getState() as RootState
      const { cancelTokenGetTrendsByCatalogId } = dynamicsResultsState

      const { data } = await getTopsByCatalogIdService(
        catalogId,
        {
          tag_group,
          period_type: period,
          start_date: startDate,
          end_date: endDate,
          sex_category: gender,
          age_category: age,
        },
        cancelTokenGetTrendsByCatalogId?.token,
      )
      return data
    } catch (error) {
      if (axios.isCancel(error)) {
        return { cancel: true }
      }
      throw new Error()
    }
  },
)

export const dynamicsResultsStateGetTopRequestSavedAction = createAsyncThunk(
  'dynamics-results/get-top-request-saved-action',
  async (): Promise<any> => {
    try {
      const { data } = await getTopRequestSavedService()
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultsStatePutTopRequestSavedAction = createAsyncThunk(
  'dynamics-results/put-top-request-saved-action',
  async ({
    requestId,
    request,
  }: {
    requestId: string
    request: Partial<TopRequestSavedInterface>
  }): Promise<any> => {
    try {
      const { data } = await putTopRequestSavedService(requestId, request)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultsStateDeleteTopRequestSavedAction = createAsyncThunk(
  'dynamics-results/delete-top-request-saved-action',
  async ({ requestId }: { requestId: string }): Promise<any> => {
    try {
      const { data } = await deleteTopRequestSavedService(requestId)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultStatePostTopRequestSavedAction = createAsyncThunk(
  DYNAMICS_RESULTS_POST_TOP_REQUEST_SAVED_ACTION,
  async ({
    request,
  }: {
    request: Partial<TopRequestSavedInterface>
  }): Promise<any> => {
    try {
      const { data } = await postTopRequestSavedService(request)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultsStateGetBookDetailsConstellation = createAsyncThunk(
  'dynamics-results/get-book-details-constellation',
  async (eanId: number, { getState }) => {
    try {
      const { dynamicsResultsState } = getState() as RootState
      const { cancelTokenBookDetailsWorkMirror } = dynamicsResultsState
      const { data } = await getBookRecoV3Service(
        {
          eanIds: [eanId],
          nbrecos: 7,
        },
        cancelTokenBookDetailsWorkMirror?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultsSateGetBookDetail = createAsyncThunk(
  'dynamics-results/get-book-detail',
  async (ean: number, { getState }) => {
    try {
      const { dynamicsResultsState } = getState() as RootState
      const { cancelTokenBookDetails } = dynamicsResultsState
      const { data } = await getB2bBookDetailsByEanIdService(
        ean,
        cancelTokenBookDetails?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dynamicsResultStateGetBookDetailsData = createAsyncThunk(
  'dynamics-results/get-book-detail-data',
  async (
    { book }: { book: TrendV2Interface | null },
    { dispatch },
  ): Promise<TrendV2Interface | null> => {
    try {
      const data = await new Promise((resolve) => {
        if (book) {
          void dispatch(dynamicsResultsSateGetBookDetail(book.id_ean))
          void dispatch(
            dynamicsResultsStateGetBookDetailsConstellation(book.id_ean),
          )
        }
        resolve(book)
      })

      return data as TrendV2Interface
    } catch (error) {
      throw new Error()
    }
  },
)
