import { memo, useEffect } from 'react'
import { ConstellationBookDataInterface } from './ConstellationBookData.interface'
import computeLineBetweenCircleUtil from '../../../../../core/utils/compute-line-between-circle.util'
import { WorkMirrorBookInterface } from '../../../../../core/interface/report/work-mirror-book.interface'
import getClilColor from '../../../../../core/utils/get-clil-color'

const SvgBookGroup = ({
  svgContainer,
  data,
  edgeCenter = 0,
  handleDisplayBookCard,
  bookIsSelected,
  handleBookSelected,
}: {
  svgContainer: any
  data: ConstellationBookDataInterface
  edgeCenter?: number
  handleDisplayBookCard: (book: ConstellationBookDataInterface | null) => void
  bookIsSelected: boolean
  handleBookSelected: (book: WorkMirrorBookInterface | null) => void
}) => {
  useEffect(() => {
    if (edgeCenter) {
      const coords = computeLineBetweenCircleUtil({
        x1: data.center.x,
        y1: data.center.y,
        r1: 50,
        x2: data.x,
        y2: data.y,
        r2: data.r,
      })
      const lineCenter = svgContainer
        .append('defs')
        .append('linearGradient')
        .attr('id', `line-center-${data.workMirror.id_ean}`)
        .attr('x1', data.center.x)
        .attr('x2', data.x)
        .attr('y1', data.center.y)
        .attr('y2', data.y)
        .attr('gradientUnits', 'userSpaceOnUse')
      lineCenter.append('stop').attr('stop-color', '#a4a4a4')

      lineCenter
        .append('stop')
        .attr('offset', 1)
        .attr('stop-color', '#a4a4a4')
        .attr('stop-opacity', 0)

      svgContainer
        .append('line')
        .attr('x1', coords.x1)
        .attr('y1', coords.y1)
        .attr('x2', coords.x2)
        .attr('y2', coords.y2)
        .attr('stroke', `url(#line-center-${data.workMirror.id_ean})`)
        .style('stroke-width', edgeCenter)
        .attr('class', `line-center`)
        .style('opacity', 0.5)
        .style('filter', 'blur(0.05px)')

      const lineCenter2 = svgContainer
        .append('defs')
        .append('linearGradient')
        .attr('id', `line-center-${data.workMirror.id_ean}`)
        .attr('x1', data.center.x)
        .attr('x2', data.x)
        .attr('y1', data.center.y)
        .attr('y2', data.y)
        .attr('gradientUnits', 'userSpaceOnUse')
      lineCenter2.append('stop').attr('stop-color', '#a4a4a4')

      lineCenter2
        .append('stop')
        .attr('offset', 1)
        .attr('stop-color', '#a4a4a4')
        .attr('stop-opacity', 0)

      lineCenter2
        .append('line')
        .attr('x1', coords.x1)
        .attr('y1', coords.y1)
        .attr('x2', coords.x2)
        .attr('y2', coords.y2)
        .attr('stroke', `url(#line-center-${data.workMirror.id_ean})`)
        .style('stroke-width', edgeCenter)
        .attr('class', `line-center2`)
        .style('opacity', 0.5)
        .style('filter', 'blur(5px)')
    }

    if (data?.edges?.length) {
      data?.edges?.forEach((edge: { x: any; y: any; r: any }) => {
        const coords = computeLineBetweenCircleUtil({
          x1: data.x,
          y1: data.y,
          r1: data.r,
          x2: edge.x,
          y2: edge.y,
          r2: edge.r,
        })
        svgContainer
          .append('line')
          .attr('class', `line-${data?.workMirror?.id_ean}`)
          .attr('x1', coords.x1)
          .attr('y1', coords.y1)
          .attr('x2', coords.x2)
          .attr('y2', coords.y2)
          .style('stroke', '#252525')
          .style('stroke-width', 1)
          .style('stroke-dasharray', '2 2')
          .style('opacity', 0)
      })
    }
    const group = svgContainer.append('g')
    group
      .attr('class', 'node-book')
      .attr('id', `node-book-${data?.workMirror?.id_ean}`)
    group
      .append('circle')
      .attr('cx', data.x)
      .attr('cy', data.y)
      .attr('r', data.r)
      .attr('fill', getClilColor(data?.workMirror?.book?.clil1))

    const circleEffect = group
      .append('circle')
      .attr('cx', data.x)
      .attr('cy', data.y)
      .attr('r', data.r)
      .attr('fill', `url(#radialGradient-${data?.workMirror?.book?.clil1}-out`)

    const circleSelected = group
      .append('circle')
      .attr('cx', data.x)
      .attr('cy', data.y)
      .attr('r', data.r - 2)
      .attr('fill', `url(#radialGradient-${data?.workMirror?.book?.clil1}-in)`)
      .style('opacity', 0)

    if (bookIsSelected) {
      circleSelected.style('opacity', 1)
    } else {
      circleSelected.style('opacity', 0)
      circleEffect.attr(
        'fill',
        `url(#radialGradient-${data?.workMirror?.book?.clil1}-out`,
      )
    }

    group
      .on('mouseover', () => {
        if (bookIsSelected) {
          circleSelected.style('opacity', 1)
          circleEffect.style('opacity', 0)
        } else {
          circleSelected.style('opacity', 0)
          circleEffect.style('opacity', 1)
          circleEffect.attr(
            'fill',
            `url(#radialGradient-${data?.workMirror?.book?.clil1}-in)`,
          )
        }
        svgContainer?.selectAll(`[class="line-center"]`)?.style('opacity', 0)
        svgContainer
          ?.selectAll(`[class=line-${data.workMirror.id_ean}]`)
          ?.style('opacity', 0.3)
        svgContainer
          ?.selectAll(
            `[class="node-book"]:not(:is(${[
              `#node-book-${data?.workMirror?.id_ean}`,
              ...(data?.workMirror?.edges || []).map(
                (edge: any) => `#node-book-${edge}`,
              ),
            ].join(',')}))`,
          )
          .style('opacity', 0.3)
        handleDisplayBookCard(data)
      })
      .on('mouseout', () => {
        handleDisplayBookCard(null)
        if (bookIsSelected) {
          circleSelected.style('opacity', 1)
          circleEffect.style('opacity', 0)
        } else {
          circleSelected.style('opacity', 0)
          circleEffect.style('opacity', 1)
          circleEffect.attr(
            'fill',
            `url(#radialGradient-${data?.workMirror?.book?.clil1}-out)`,
          )
        }
        svgContainer
          .selectAll(`[class="line-${data?.workMirror?.id_ean}"]`)
          .style('opacity', 0)
        svgContainer?.selectAll(`[class="line-center"]`)?.style('opacity', 1)
        svgContainer
          ?.selectAll(
            `[class="node-book"]:not([id="node-book-${data?.workMirror?.id_ean}"])`,
          )
          .style('opacity', 1)
      })
      .on('click', () => {
        if (bookIsSelected) {
          handleBookSelected(null)
        } else {
          handleBookSelected(data?.workMirror)
        }
      })

    const a = group
      .append('text')
      .text(
        `${data?.workMirror?.book?.title?.slice(0, 35)}${
          data?.workMirror?.book?.title?.length > 35 ? '...' : ''
        }`,
      )
      .attr('class', 'titleA')

    const w1 = a.node()?.getBBox().width || 0
    a.attr('x', data.x - w1 / 2).attr('y', data.y - 10)
    const b = group
      .append('text')
      .text(data?.workMirror?.book?.authors[0])
      .attr('class', 'authorA')

    const w2 = b.node()?.getBBox().width || 0

    b.attr('x', data.x - w2 / 2).attr('y', data.y + 10)
  }, [bookIsSelected])
  return null
}

export default memo(SvgBookGroup)
