import { nanoid } from 'nanoid'

const GlCarrouselNavigation = ({
  length,
  current,
  handleCurrentIndex,
}: any) => (
  <div className="bottom-0 w-full flex flex-row justify-center items-center gap-1">
    <button
      type="button"
      disabled={current === 0}
      onClick={() => handleCurrentIndex(((current as number) - 1) % length)}
    >
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.70499 1.0918L3.7959 6.00318L8.70499 10.91"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
    {Array(length)
      .fill(null)
      .map((_, index) => (
        <button
          key={nanoid(5)}
          type="button"
          onClick={() => handleCurrentIndex(index)}
        >
          {(current === index && (
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4.25" cy="4" r="3" fill="#E3E3E3" stroke="#E3E3E3" />
            </svg>
          )) || (
            <svg
              width="7"
              height="6"
              viewBox="0 0 7 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3.25" cy="3" r="2.5" stroke="#B2B2B2" />
            </svg>
          )}
        </button>
      ))}
    <button
      type="button"
      disabled={(current as number) + 1 === length}
      onClick={() => handleCurrentIndex(((current as number) + 1) % length)}
    >
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.29501 1.0918L9.2041 6.00318L4.29501 10.91"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </div>
)

export default GlCarrouselNavigation
