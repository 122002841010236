import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { StudyObjectGroupCompareEnum } from '../../configs/StudiesObjectsCompareGroupTypesConfig'
import { EllipsisDivStyle, TagStyle } from '../../inputs/recap/RecapInputStyles'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'
import { clilLevelMapping } from '../../../../../../core/utils/get-clil-level.utils'
import { ReactComponent as TitleIcon } from '../../../../../../assets/icons/app/book.icon.svg'
import { ReactComponent as AuthorIcon } from '../../../../../../assets/icons/app/author.icon.svg'
import { ReactComponent as SegmentIcon } from '../../../../../../assets/icons/app/segment.icon.svg'
import { ReactComponent as BottomArrowIcon } from '../../../../../../assets/icons/app/bottom-chevron.icon.svg'

const StudyGroupDescriptionStyle = styled.div`
  color: #666;
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-wrap: nowrap;
`

interface Props {
  color: string
  handleClickDropdown: () => void
  studyObjectType: StudyObjectGroupCompareEnum
  data: BookInterface | AuthorInterface | { clil: string }
  isDropdownOpen: boolean
  disableDropdown?: boolean
}
const StudyObjectGroupDropdownButton = ({
  color,
  handleClickDropdown,
  studyObjectType,
  data,
  isDropdownOpen,
  disableDropdown,
}: Props) => {
  const { t } = useTranslation()

  const getTitle = () => {
    switch (studyObjectType) {
      case StudyObjectGroupCompareEnum.TITLE:
        return (data as BookInterface).title
      case StudyObjectGroupCompareEnum.AUTHOR:
        return (data as AuthorInterface).name
      case StudyObjectGroupCompareEnum.CLIL:
        return clilReferencesConfig[(data as { clil: string }).clil].libelle
      default:
        return ''
    }
  }

  const getDescription = () => {
    switch (studyObjectType) {
      case StudyObjectGroupCompareEnum.TITLE:
      case StudyObjectGroupCompareEnum.AUTHOR:
        return `${
          (data as BookInterface | AuthorInterface).children?.length
        } ${t('studyTitles')}`
      case StudyObjectGroupCompareEnum.CLIL:
        return `${t('level')} ${
          clilLevelMapping[(data as { clil: string }).clil]
        }`
      default:
        return ''
    }
  }

  const getIcon = () => {
    switch (studyObjectType) {
      case StudyObjectGroupCompareEnum.TITLE:
        return TitleIcon
      case StudyObjectGroupCompareEnum.AUTHOR:
        return AuthorIcon
      case StudyObjectGroupCompareEnum.CLIL:
        return SegmentIcon
      default:
        return TitleIcon
    }
  }

  const onClickDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!disableDropdown) {
      handleClickDropdown()
    }
  }

  const Icon = getIcon()

  return (
    <TagStyle
      style={{
        maxWidth: '100%',
        cursor: disableDropdown ? 'default' : 'pointer',
      }}
      color={color}
      onClick={onClickDropdown}
    >
      <div className="flex justify-between">
        <div className="flex flex-row items-center justify-start py-1 px-2 rounded gap-2">
          <Icon
            style={{
              width: 20,
              height: 20,
            }}
          />
          <EllipsisDivStyle className="flex flex-auto">
            <span>{getTitle()}</span>
          </EllipsisDivStyle>
          <StudyGroupDescriptionStyle>
            {getDescription()}
          </StudyGroupDescriptionStyle>
          {studyObjectType === StudyObjectGroupCompareEnum.CLIL && (
            <StudyGroupDescriptionStyle>
              {(data as { clil: string }).clil}
            </StudyGroupDescriptionStyle>
          )}
        </div>
        {!disableDropdown && (
          <div className="flex flex-row items-center justify-start py-1 px-2 rounded gap-2">
            <BottomArrowIcon
              style={{
                width: 12,
                height: 12,
                transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                color: '#666',
              }}
            />
          </div>
        )}
      </div>
    </TagStyle>
  )
}

export default StudyObjectGroupDropdownButton
