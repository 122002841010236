import { DatasetModuleInterface } from '../interface/analytics/dataset-module.interface'
import { AnalyticOutputLayoutSizeEnum } from '../enums/analytic-output-layout-size.enum'

export const UpdateDatasetmoduleByDropdown = (
  datasetModule: DatasetModuleInterface,
  optionId: string,
  value: AnalyticOutputLayoutSizeEnum,
) => {
  return {
    ...datasetModule,
    layout_size: optionId === 'layout_size' ? value : datasetModule.layout_size,
  }
}
