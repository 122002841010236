import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SaveIcon } from '../../assets/icons/save-icon-white-16.icon.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/share-icon-white-16.icon.svg'
import { ReactComponent as ArrowTopIcon } from '../../assets/icons/app/arrow-top.icon.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/app/add.icon.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/app/cross.icon.svg'
import { BookInterface } from '../../core/interface/book.interface'
import { WM_REPORT_SIZE_MAX_BOOK_SELECTION } from '../../core/config/reports/report-details.config'
import BookAutoCompleteSelection from './BookAutoCompleteSelection'
import TitleV2 from '../analytics-details/work-mirror/TitleV2'
import { clilReferencesConfig } from '../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../core/config/clil-refs-mapping.config'
import GlLoader from '../share/GlLoader'
import { WorkMirrorBookInterface } from '../../core/interface/report/work-mirror-book.interface'
import { AnalyticsPermissionEnum } from '../../core/enums/analytics-permission.enum'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { analyticsDetailV2StateSetBooksInputsMenuOpen } from '../../store/reducers/analytics-details/analytic-details-v2.reducer'
import { selectAnalyticDetailsStateBooksInputsMenuOpen } from '../../store/getters/analytics-details/analytic-details-v2.getters'

const CardStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 56px;
  width: 360px;
  background-color: black;
  border-radius: 8px;
  padding: 8px;
  z-index: 10;
`

const HeaderStyle = styled.div``
const LabelButtonStyle = styled.button`
  overflow: hidden;
  color: var(--grayscale-light-gray, #bebebe);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  height: 40px;
  border: 1px dashed transparent;
  border-radius: 6px;
  width: 100%;
  padding: 8px;

  :hover {
    border-color: #bebebe;
  }

  :active {
    background-color: #bebebe33;
  }
`

const ImageCloseStyle = styled.img`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  object-fit: cover;
  border-radius: 8px;
`

const TitleCloseStyle = styled.span`
  overflow: hidden;
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Vollkorn, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.14px;
  max-width: 308px;
`

const ButtonOpenMenuStyle = styled.button`
  transform: ${({ open }: { open: boolean }) =>
    open ? 'rotate(0deg)' : 'rotate(180deg)'};
`

const WMStyle = styled.span`
  color: #8dda86;
  text-align: center;
  /* Caption/C1 - Medium - Uppercase */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
`

const CardBookInputStyle = styled.div`
  background: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? '#e3e3e3' : 'white'};
  width: 100%;
  border-radius: 4px;
  padding: 2px;
`

const ItemAuthorStyle = styled.div`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #595959;
  font-family: Vollkorn, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125.37%; /* 15.044px */
  letter-spacing: -0.12px;
`

const AnalyticDetailsBooksInput = ({
  isLoadingNewConstellationGetBookReco,
  eans,
  isLoadingAdd,
  isLoadingRemove,
  needSave,
  canShare,
  booksInput,
  handleSaveAnalytics,
  handleShareAnalytics,
  handleAddBookToAnalytic,
  handleRemoveBookInput,
  bookDetailsSelected,
  handleBookSelected,
  permission,
}: {
  isLoadingNewConstellationGetBookReco: boolean
  eans: string | null
  isLoadingAdd: boolean
  isLoadingRemove: boolean
  needSave: boolean
  canShare: boolean
  booksInput: BookInterface[]
  handleSaveAnalytics: () => void
  handleShareAnalytics: () => void
  handleAddBookToAnalytic: (eanId: number) => void
  handleRemoveBookInput: (eanId: number) => void
  bookDetailsSelected: WorkMirrorBookInterface | null
  handleBookSelected: (book: WorkMirrorBookInterface | null) => void
  permission?: AnalyticsPermissionEnum
}) => {
  const { t } = useTranslation()
  const [indexRemove, setIndexRemove] = useState<number | null>(null)

  const dispatch = useAppDispatch()
  const booksInputsMenuOpen = useAppSelector(
    selectAnalyticDetailsStateBooksInputsMenuOpen,
  )

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(analyticsDetailV2StateSetBooksInputsMenuOpen(!booksInputsMenuOpen))
  }

  const handleOnClickAddBook = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(analyticsDetailV2StateSetBooksInputsMenuOpen(true))
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const handleOnClickSaveAnalytics = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleSaveAnalytics()
  }

  const handleOnClickShareAnalytics = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleShareAnalytics()
  }

  const handleBookToSelected = (book: BookInterface) => {
    handleBookSelected(
      bookDetailsSelected?.id_ean === book.id_ean
        ? null
        : {
            popularity: 0,
            position: 0,
            id_ean: book.id_ean,
            book,
          },
    )
  }

  return (
    <CardStyle className="flex flex-col gap-[8px]">
      <HeaderStyle className="flex flex-row justify-between items-center h-full">
        <div className="flex flex-row justify-start items-center h-[40px] gap-[4px]">
          {(permission === AnalyticsPermissionEnum.EDIT && (
            <>
              <button
                type="button"
                className="rounded p-[4px] text-white disabled:text-[#e3e3e366] disabled:bg-black hover:bg-[#252525] active:bg-[#e3e3e333]"
                disabled={!booksInput.length}
                onClick={handleOnClickSaveAnalytics}
              >
                <div className="relative">
                  <SaveIcon className="w-4 h-4" />
                  {(needSave && booksInput.length && (
                    <div className="absolute bg-[#F97E60] w-[4px] h-[4px] top-[-2px] right-[-2px] rounded-2xl" />
                  )) ||
                    null}
                </div>
              </button>
              <button
                type="button"
                className="rounded p-[4px] text-white disabled:text-[#e3e3e366] disabled:bg-black hover:bg-[#252525] active:bg-[#e3e3e333]"
                disabled={!canShare || !booksInput.length}
                onClick={handleOnClickShareAnalytics}
              >
                <ShareIcon className="w-4 h-4" />
              </button>
            </>
          )) ||
            null}
        </div>

        <WMStyle>{t('worksMirrors')}</WMStyle>
        <div
          className="flex flex-col items-center justify-around"
          style={{
            minHeight: 40,
            maxHeight: 40,
          }}
        >
          <ButtonOpenMenuStyle
            type="button"
            open={booksInputsMenuOpen}
            onClick={handleOpenMenu}
          >
            <ArrowTopIcon className="text-[#f4f4f4] w-5 h-5" />
          </ButtonOpenMenuStyle>
        </div>
      </HeaderStyle>
      {(booksInputsMenuOpen && (
        <div className="flex flex-col gap-1">
          {booksInput.map(
            (bookInput: BookInterface, index: number) =>
              (index === indexRemove && isLoadingRemove && (
                <div
                  key={`book-input-details-${bookInput.id_ean}`}
                  className="flex flex-row items-center justify-center"
                  style={{
                    width: '100&',
                    height: 40,
                  }}
                >
                  <GlLoader width={30} height={30} />
                </div>
              )) || (
                <CardBookInputStyle
                  key={`book-input-card-${bookInput.id_ean}`}
                  isSelected={bookDetailsSelected?.id_ean === bookInput.id_ean}
                  className="relative flex flex-row items-center justify-start gap-2"
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault()
                    handleBookToSelected(bookInput)
                  }}
                >
                  <img
                    src={bookInput?.imgs || getBookCoverByClil(bookInput.clil1)}
                    alt="book cover"
                    style={{
                      objectFit: 'contain',
                      minWidth: 40,
                      minHeight: 40,
                      maxWidth: 40,
                      maxHeight: 40,
                      borderRadius: '4px',
                      borderTop: '1px solid transparent',
                      borderBottom: '1px solid transparent',
                    }}
                  />
                  <div className="flex flex-col items-start">
                    <TitleV2 title={bookInput?.title?.trim()} />
                    <ItemAuthorStyle>
                      {bookInput?.authors[0]?.trim()}
                    </ItemAuthorStyle>
                  </div>
                  {(permission === AnalyticsPermissionEnum.EDIT && (
                    <button
                      type="button"
                      className="absolute top-4 right-2 text-black"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation()
                        setIndexRemove(index)
                        handleRemoveBookInput(bookInput?.id_ean)
                      }}
                      disabled={isLoadingAdd || isLoadingRemove}
                    >
                      <CrossIcon className="w-3 h-3" />
                    </button>
                  )) ||
                    null}
                </CardBookInputStyle>
              ),
          )}
          {(isLoadingNewConstellationGetBookReco &&
            booksInput.length < WM_REPORT_SIZE_MAX_BOOK_SELECTION && (
              <div
                className="flex flex-row items-center justify-center"
                style={{
                  width: '100&',
                  height: 40,
                }}
              >
                <GlLoader width={30} height={30} />
              </div>
            )) ||
            null}
          {(isLoadingAdd &&
            booksInput.length < WM_REPORT_SIZE_MAX_BOOK_SELECTION && (
              <div
                className="flex flex-row items-center justify-center"
                style={{
                  width: '100&',
                  height: 40,
                }}
              >
                <GlLoader width={30} height={30} />
              </div>
            )) ||
            null}
          {(!isLoadingAdd &&
            !isLoadingNewConstellationGetBookReco &&
            booksInput.length < 3 &&
            permission === AnalyticsPermissionEnum.EDIT && (
              <BookAutoCompleteSelection
                menuOpen
                setMenuOpen={() => null}
                booksInput={booksInput}
                handleAddBookToAnalytic={handleAddBookToAnalytic}
              />
            )) ||
            null}
        </div>
      )) || (
        <div className="flex flex-col gap-2">
          {booksInput.map((bookInput: BookInterface) => (
            <div
              key={`book-input-miature-${bookInput.id_ean}`}
              className="flex flex-row items-center justify-start gap-2"
            >
              <ImageCloseStyle
                src={bookInput?.imgs || getBookCoverByClil(bookInput.clil1)}
                alt="cover book"
              />
              <TitleCloseStyle>{bookInput.title}</TitleCloseStyle>
            </div>
          ))}
          {(!isLoadingRemove &&
            !isLoadingNewConstellationGetBookReco &&
            permission === AnalyticsPermissionEnum.EDIT &&
            booksInput.length < WM_REPORT_SIZE_MAX_BOOK_SELECTION && (
              <div className="flex flex-row w-full">
                <LabelButtonStyle
                  type="button"
                  className="flex flex-row justify-start items-center gap-1"
                  onClick={handleOnClickAddBook}
                >
                  <AddIcon className="w-4 h-4" />
                  <span>{t("ajouter jusqu'a 3 livres")}</span>
                </LabelButtonStyle>
              </div>
            )) ||
            null}

          {(isLoadingRemove && booksInput.length < 3 && (
            <div
              className="flex flex-row items-center justify-center"
              style={{
                width: '100&',
                height: 40,
              }}
            >
              <GlLoader width={30} height={30} />
            </div>
          )) ||
            null}

          {(isLoadingNewConstellationGetBookReco && booksInput.length < 3 && (
            <div
              className="flex flex-row items-center justify-center"
              style={{
                width: '100&',
                height: 40,
              }}
            >
              <GlLoader width={30} height={30} />
            </div>
          )) ||
            null}
        </div>
      )}
    </CardStyle>
  )
}

export default AnalyticDetailsBooksInput
