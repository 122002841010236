import styled from '@emotion/styled'

export const IconContainerStyle = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #f7f7f7;
`

export const TitleSectionStyle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #3b3b3b;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: 'Fira Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
`
