import styled from '@emotion/styled'

export const AnalyticsDashboardDialogButton = styled.button`
  color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && 'white') || '#3B3B3B'};
  background-color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && '#252525') || '#E9E9E9'};
  opacity: ${({
    primary = false,
    isLoading,
  }: {
    primary?: boolean
    isLoading?: boolean
  }) => (isLoading && 0.6) || (primary && 1) || 0.7};
  border-radius: 4px;
  text-align: center;
  /* Body/B1 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
  height: 36px;

  :disabled {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525b3') || '#E9E9E9cc'};
  }

  :hover {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525cc') || '#E9E9E9cc'};
  }
  :active {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525e6') || '#E9E9E9e6'};
  }
`
