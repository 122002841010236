import styled from '@emotion/styled'
import GlProgress from '../../../../share/GlProgress'
import DynamicsBillRankPosition from './DynamicsBillRankPosition'

const RankLayoutStyle = styled.div`
  min-width: 80px;
`
interface Props {
  rank: number
  delta_rank: number | null
}

const DynamicsBillRank = ({ rank, delta_rank }: Props) => {
  return (
    <RankLayoutStyle className="flex flex-col justify-center items-center gap-2 h-full bg-[#252525]">
      <DynamicsBillRankPosition rank={rank} />
      <GlProgress progress={delta_rank} />
    </RankLayoutStyle>
  )
}

export default DynamicsBillRank
