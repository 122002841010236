import styled from '@emotion/styled'

const LabelStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const GaugeContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--spacing-XS, 4px);
  align-self: stretch;
`

const GaugeStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`

const DynamicGaugeStyle = styled.div`
  display: flex;
  height: var(--size-icon-l, 32px);
  padding-left: 82px;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  border-radius: var(--radius-S, 4px);
  background: linear-gradient(90deg, #fff, #d9d9d9);
  animation: gradient 1s ease infinite;
  background-size: 150% 150%;
  @keyframes gradient {
    0% {
      background-position: 20% 50%;
    }
    40% {
      background-position: 50% 50%;
    }
    50% {
      background-position: 80% 50%;
    }
    60% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 20% 50%;
    }
  }
`

const PreviewCardWaiting = ({ icon, label }: { icon: any; label: any }) => {
  return (
    <div
      className="flex-1 flex flex-col p-2 justify-between"
      style={{
        height: 160,
        minWidth: 220,
      }}
    >
      <div className="flex flex-row gap-4">
        {icon}
        <LabelStyle>{label}</LabelStyle>
      </div>
      <div className="flex flex-col text-right gap-1 items-end">
        <GaugeContainerStyle>
          <GaugeStyle className="pl-[150px]">
            <DynamicGaugeStyle />
          </GaugeStyle>
        </GaugeContainerStyle>
        <GaugeContainerStyle>
          <GaugeStyle>
            <DynamicGaugeStyle />
          </GaugeStyle>
        </GaugeContainerStyle>
      </div>
    </div>
  )
}

export default PreviewCardWaiting
