import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { SettingsStateInterface } from '../interfaces/settings.state.interface'
import {
  settingsGetAccountsProfileActions,
  settingsPostAccountsChangePasswordActions,
} from '../actions/settings.actions'

const initialState: SettingsStateInterface = {
  isLoading: false,
  teams: null,
  user: null,
  isLoadingProfile: false,
  profile: null,
  cancelTokenGetProfile: null,
  isLoadingChangePassword: false,
  hasErrorChangePassword: false,
}

export const settingsSlice = createSlice({
  name: 'settingsState',
  initialState,
  reducers: {
    reinitializeSettingsState: (
      state: SettingsStateInterface,
    ): SettingsStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        settingsGetAccountsProfileActions.pending,
        (state: SettingsStateInterface) => {
          const { cancelTokenGetProfile } = state
          if (cancelTokenGetProfile) {
            cancelTokenGetProfile.cancel
          }
          return {
            ...state,
            isLoadingProfile: true,
            profile: null,
            cancelTokenGetProfile: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        settingsGetAccountsProfileActions.fulfilled,
        (state: SettingsStateInterface, action: PayloadAction<any>) => ({
          ...state,
          isLoadingProfile: false,
          profile: action.payload,
        }),
      )
      .addCase(
        settingsGetAccountsProfileActions.rejected,
        (state: SettingsStateInterface) => ({
          ...state,
          isLoadingProfile: false,
          profile: null,
        }),
      )
      .addCase(
        settingsPostAccountsChangePasswordActions.pending,
        (state: SettingsStateInterface) => ({
          ...state,
          isLoadingChangePassword: true,
          hasErrorChangePassword: false,
        }),
      )
      .addCase(
        settingsPostAccountsChangePasswordActions.fulfilled,
        (state: SettingsStateInterface) => ({
          ...state,
          isLoadingChangePassword: false,
          hasErrorChangePassword: false,
        }),
      )
      .addCase(
        settingsPostAccountsChangePasswordActions.rejected,
        (state: SettingsStateInterface) => {
          return {
            ...state,
            isLoadingChangePassword: false,
            hasErrorChangePassword: true,
          }
        },
      )
  },
})

export const { reinitializeSettingsState } = settingsSlice.actions
export const settingsReducer = settingsSlice.reducer
