import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const postAccountsChangePasswordService = ({
  currentPassword,
  password,
  confirmPassword,
}: {
  currentPassword: string
  password: string
  confirmPassword: string
}) =>
  apiInstance.post(ApiPathConfig.ACCOUNTS_CHANGE_PASSWORD, {
    current_password: currentPassword,
    password,
    confirm_password: confirmPassword,
  })
