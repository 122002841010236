import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'
import GlHDivider from './GlHDivider'
import { ReactComponent as BottomArrowIcon } from '../../assets/icons/app/bottom-arrow-light.icon.svg'
import GlSlideToggle from './GlSlideToggle'

const HeaderStyle = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e3e3e3;
  }
`

const TitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #252525;
`
const BadgeCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: #f7f7f7;
  border: 1px solid #e3e3e3;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #595959;
  border-radius: 100px;
`

const ArrowButtonStyle = styled.button`
  border-radius: 24px;

  transform: ${({ isOpen }: { isOpen: boolean }) =>
    (isOpen && 'rotate(0)') || 'rotate(180deg)'};
  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
`

const BodyCardStyle = styled.div`
  height: ${({ isOpen, maxHeight }: { isOpen: boolean; maxHeight: number }) =>
    (isOpen && `${maxHeight}px`) || 0};
  visibility: ${({ isOpen }: { isOpen: boolean }) =>
    (isOpen && 'visible !important') || 'hidden !important'};
  -webkit-transition: height 200ms ease-in-out, visibility 200ms ease-in-out;
  -moz-transition: height 200ms ease-in-out, visibility 200ms ease-in-out;
  -o-transition: height 200ms ease-in-out, visibility 200ms ease-in-out;
  transition: height 200ms ease-in-out, visibility 200ms ease-in-out;
  padding: -8px;
`

const GlCardExpand = ({
  label,
  anchorName,
  icon,
  buttonIcon,
  children,
  count,
  maxHeight,
  open,
  setOpen,
  hasToggleButton = false,
  handleDisplayOnlyMyReports,
}: {
  hasToggleButton?: boolean
  buttonIcon?: ReactElement
  anchorName?: string
  label: string
  icon?: ReactElement
  children: ReactElement
  count?: number
  maxHeight: number
  open: boolean
  setOpen: (value: boolean) => void
  handleDisplayOnlyMyReports?: (value: boolean) => void
}) => {
  const { t } = useTranslation()
  return (
    <div
      id={`${anchorName || nanoid(5)}-anchor`}
      className="flex flex-col rounded-lg bg-white"
      style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)' }}
    >
      <HeaderStyle
        className="w-full flex flex-row justify-between items-center px-4 pt-4 pb-2"
        onClick={() => setOpen(!open)}
      >
        <div className="flex flex-row gap-2 items-center">
          <ArrowButtonStyle
            type="button"
            isOpen={open}
            className="rounded-3xl p-1"
          >
            <BottomArrowIcon className="w-6 h-6 text-[#b2b2b2]" />
          </ArrowButtonStyle>
          <TitleStyle className="flex">{label}</TitleStyle>
          {count && count > 0 && <BadgeCount>{count}</BadgeCount>}
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          {hasToggleButton && (
            <GlSlideToggle
              label={t('webApp.reports.dashboard.myAnalyticsOnly')}
              handleOnToggle={(value) =>
                handleDisplayOnlyMyReports && handleDisplayOnlyMyReports(value)
              }
            />
          )}
          {buttonIcon || icon || null}
        </div>
      </HeaderStyle>
      <GlHDivider />
      <BodyCardStyle isOpen={open} maxHeight={maxHeight}>
        {children}
      </BodyCardStyle>
    </div>
  )
}

export default GlCardExpand
