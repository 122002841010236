import { ReactElement } from 'react'
import AuthHeader from './AuthHeader'

interface Props {
  children: ReactElement
}
const PublicLayout = ({ children }: Props) => {
  return (
    <div className="w-full h-full flex flex-col bg-webApp overflow-hidden">
      <div className="flex flex-none w-full">
        <AuthHeader />
      </div>
      <div className="flex flex-auto" style={{ height: 'calc(100% - 48px)' }}>
        {children}
      </div>
    </div>
  )
}

export default PublicLayout
