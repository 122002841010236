export enum AnalyticsChartTypeEnum {
  VERTICAL_BAR_CHART = 'vertical_bar_chart',
  HORIZONTAL_BAR_CHART = 'horizontal_bar_chart',
  VERTICAL_STACKED_BAR_CHART = 'vertical_stacked_bar_chart',
  TREEMAP = 'treemap',
  RADAR = 'radar',
  POLAR = 'polar',
  TABULAR = 'tabular',
  BUBBLE_CHART = 'bubble_chart',
  SCATTER_PLOT = 'scatter_plot',
  CONSTELLATION = 'constellation',
}
