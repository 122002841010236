import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import {
  selectWebAppStateCompanyUserId,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasAnalyticsAccess,
} from '../../store/getters/web-app.getters'
import { analyticsDashboardGetInternalReports } from '../../store/actions/analytics-dashboard.actions'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  selectAnalyticDashboardInternalReports,
  selectIsLoadingInternalReports,
} from '../../store/getters/analytics-dashboard.getters'
import AnalyticsDashBoard from '../../components/analytics-dashboard/AnalyticsDashboard'
import reportsToggleOptionConfig from '../../core/config/analytics-dashboard/reports-toggle-option.config'
import reportDashboardModulesConfig from '../../core/config/analytics-dashboard/report-dashboard-modules.config'
import useReadershipProfileGetMySelfAvailableTokenHook from '../../core/hooks/useReadershipProfileGetMySelfAvailableTokenHook'

const ReportsDashboard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    state: { isLoadingMySelf, availableTokens },
  } = useReadershipProfileGetMySelfAvailableTokenHook()

  const isLoadingInternalReports = useAppSelector(
    selectIsLoadingInternalReports,
  )
  const internalReports = useAppSelector(selectAnalyticDashboardInternalReports)

  const mySelf = useAppSelector(selectWebAppStateMySelf)

  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  useEffect(() => {
    if (mySelf && !hasAnalyticsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    } else {
      void dispatch(analyticsDashboardGetInternalReports())
    }
  }, [mySelf, hasAnalyticsAccess])

  const reloadPage = () => {
    void dispatch(analyticsDashboardGetInternalReports())
  }

  return (
    (hasAnalyticsAccess && (
      <AnalyticsDashBoard
        reports={internalReports}
        isLoadingReports={isLoadingInternalReports}
        analyticsTypesOptions={reportsToggleOptionConfig}
        dashboardModules={reportDashboardModulesConfig}
        handleReloadAction={reloadPage}
        companyUserId={companyUserId}
        isLoadingMySelf={isLoadingMySelf}
        availableTokens={availableTokens}
      />
    )) ||
    null
  )
}
export default ReportsDashboard
