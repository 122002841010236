import { reduce } from 'lodash'
import dayjs from 'dayjs'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_PUBLISHING_DATE,
} from '../config/ranks/items-filter-action.config'
import { getProgressEnumValue } from '../enums/progress.enum'
import { TrendV2Interface } from '../interface/trend-v2.interface'

export const aggregateTrendsDataByColumnUtil = (
  trends: TrendV2Interface[],
): any => {
  if (trends && trends.length) {
    const result: {
      [key: string]: Set<string | number>
    } = {}
    trends.forEach((trend: TrendV2Interface) => {
      reduce(
        trend,
        (previous: any, value: any, key: any) => {
          if (!previous[key]) {
            previous[key] = new Set<string | number>()
          }
          switch (key) {
            case FILTER_KEY_AUTHORS:
              ;(value || []).forEach((author: string) =>
                previous[key].add(author),
              )
              break
            case FILTER_KEY_PUBLISHING_DATE:
              previous[key].add(dayjs(value).year())
              break
            case FILTER_KEY_DELTA_RANK:
              previous[key].add(getProgressEnumValue(value))
              break
            default:
              value && previous[key].add(value)
              break
          }
          return previous
        },
        result,
      )
    })
    result[FILTER_KEY_DELTA_RANK] = new Set<string | number>(
      Array.from(result[FILTER_KEY_DELTA_RANK]).sort(),
    )
    result[FILTER_KEY_PUBLISHING_DATE] = new Set<string | number>(
      Array.from(result[FILTER_KEY_PUBLISHING_DATE])
        .sort()
        .reverse()
        .filter((value) => typeof value === 'number' && value >= 2012),
    )
    return result
  }
}
