import styled from '@emotion/styled'
import { ReactNode } from 'react'

const DialogBodyStyle = styled.div`
  color: ${({ color }: { color: string; textAlignLeft: boolean }) => color};
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: ${({
    textAlignLeft,
  }: {
    color: string
    textAlignLeft: boolean
  }) => (textAlignLeft && 'left') || 'center'};
`

const GlDialogBody = ({
  children,
  color = '#666',
  textAlignLeft = false,
}: {
  children: ReactNode
  color?: string
  textAlignLeft?: boolean
}) => {
  return (
    <DialogBodyStyle
      color={color}
      textAlignLeft={textAlignLeft}
      className="flex flex-col"
    >
      {children}
    </DialogBodyStyle>
  )
}

export default GlDialogBody
