import { ReactComponent as ArtsIcon } from '../../assets/icons/clil/arts-icon.svg'
import ArtsCoverIcon from '../../assets/icons/cover/art-cover.icon.svg'
import { ReactComponent as ComicsMangasIcon } from '../../assets/icons/clil/comics-mangas.icon.svg'
import ComicsMangasCoverIcon from '../../assets/icons/cover/comics-mangas-cover.icon.svg'
import { ReactComponent as AtlasMapIcon } from '../../assets/icons/clil/atlas-map.icon.svg'
import AtlasMapCoverIcon from '../../assets/icons/cover/atlas-map-cover.icon.svg'
import { ReactComponent as DocumentationIcon } from '../../assets/icons/clil/documentations.icon.svg'
import DocumentationsCoverIcon from '../../assets/icons/cover/documentations-cover.icon.svg'
import { ReactComponent as LawIcon } from '../../assets/icons/clil/law-icon.svg'
import LawCoverIcon from '../../assets/icons/cover/law-cover.icon.svg'
import { ReactComponent as EncyclopediaIcon } from '../../assets/icons/clil/encyclopedia.icon.svg'
import EncyclopediaCoverIcon from '../../assets/icons/cover/encyclopedia-cover.icon.svg'
import { ReactComponent as EsotericIcon } from '../../assets/icons/clil/esoterism.icon.svg'
import EsotericCoverIcon from '../../assets/icons/cover/esoterism-cover.icon.svg'
import { ReactComponent as GeographyIcon } from '../../assets/icons/clil/geography.icon.svg'
import GeographyCoverIcon from '../../assets/icons/cover/geography-cover.icon.svg'
import { ReactComponent as HistoryIcon } from '../../assets/icons/clil/history.icon.svg'
import HistoryCoverIcon from '../../assets/icons/cover/history-cover.icon.svg'
import { ReactComponent as ComputerSciencesIcon } from '../../assets/icons/clil/computer-sciences.icon.svg'
import ComputerSciencesCoverIcon from '../../assets/icons/cover/computer-sciences-cover.icon.svg'
import { ReactComponent as TeenIcon } from '../../assets/icons/clil/teen.icon.svg'
import TeenCoverIcon from '../../assets/icons/cover/teen-cover.icon.svg'
import { ReactComponent as YoungIcon } from '../../assets/icons/clil/young.icon.svg'
import YoungCoverIcon from '../../assets/icons/cover/young-cover.icon.svg'
import { ReactComponent as GeneralLiteratureIcon } from '../../assets/icons/clil/general-literature.icon.svg'
import GeneralLiteratureCoverIcon from '../../assets/icons/cover/general-literature-cover.icon.svg'
import { ReactComponent as PracticesIcon } from '../../assets/icons/clil/practices.icon.svg'
import PracticesCoverIcon from '../../assets/icons/cover/practices-cover.icon.svg'
import { ReactComponent as ManagementIcon } from '../../assets/icons/clil/management.icon.svg'
import ManagementCoverIcon from '../../assets/icons/cover/management-cover.icon.svg'
import { ReactComponent as MedicineIcon } from '../../assets/icons/clil/medicine.icon.svg'
import MedicineCoverIcon from '../../assets/icons/cover/medicine-cover.icon.svg'
import { ReactComponent as ExtracurricularIcon } from '../../assets/icons/clil/extracurricular.icon.svg'
import ExtracurricularCoverIcon from '../../assets/icons/cover/extracurricular-cover.icon.svg'
import { ReactComponent as ReligionIcon } from '../../assets/icons/clil/religion.icon.svg'
import ReligionCoverIcon from '../../assets/icons/cover/religion-cover.icon.svg'
import { ReactComponent as NoCategoryIcon } from '../../assets/icons/clil/no-category.icon.svg'
import NoCategoryCoverIcon from '../../assets/icons/cover/no-category-cover.icon.svg'
import { ReactComponent as EconomicSciencesIcon } from '../../assets/icons/clil/economic-sciences.icon.svg'
import EconomicSciencesCoverIcon from '../../assets/icons/cover/economics-sciences-cover.icon.svg'
import { ReactComponent as SocialSciencesIcon } from '../../assets/icons/clil/social-sciences.icon.svg'
import SocialSciencesCoverIcon from '../../assets/icons/cover/social-sciences-cover.icon.svg'
import { ReactComponent as PoliticSciencesIcon } from '../../assets/icons/clil/politic-sciences.icon.svg'
import PoliticSciencesCoverIcon from '../../assets/icons/cover/politic-sciences-cover.icon.svg'
import { ReactComponent as PureSciencesIcon } from '../../assets/icons/clil/pure-sciences.icon.svg'
import PureSciencesCoverIcon from '../../assets/icons/cover/pure-sciences-cover.icon.svg'
import { ReactComponent as SchoolIcon } from '../../assets/icons/clil/school.icon.svg'
import SchoolCoverIcon from '../../assets/icons/cover/school-cover.svg'
import { ReactComponent as TechniqueIcon } from '../../assets/icons/clil/technique.icon.svg'
import TechniqueCoverIcon from '../../assets/icons/cover/technique-cover.icon.svg'

export interface ClilInterface {
  color: string
  icon: any
  name: string
  cover: string
}

export interface ClilReferenceInterface {
  [key: string | number]: ClilInterface
}

const ClilReferencesMappingConfig: ClilReferenceInterface = {
  3435: {
    color: '#D1C8B3',
    icon: <GeneralLiteratureIcon width={12} height={12} />,
    name: 'commons.clil.generalLiterature',
    cover: GeneralLiteratureCoverIcon,
  },
  3722: {
    color: '#F2D60F',
    icon: <TeenIcon width={12} height={12} />,
    name: 'commons.clil.teens',
    cover: TeenCoverIcon,
  },
  3000: {
    color: '#FFC947',
    icon: <SchoolIcon width={12} height={12} />,
    name: 'commons.clil.school',
    cover: SchoolCoverIcon,
  },
  3017: {
    color: '#FFAE4A',
    icon: <ExtracurricularIcon width={12} height={12} />,
    name: 'commons.clil.extracurricular',
    cover: ExtracurricularCoverIcon,
  },
  3013: {
    color: '#FFAE4A',
    icon: <ExtracurricularIcon width={12} height={12} />,
    name: 'commons.clil.extracurricular',
    cover: ExtracurricularCoverIcon,
  },
  3259: {
    color: '#FC955C',
    icon: <LawIcon width={12} height={12} />,
    name: 'commons.clil.law',
    cover: LawCoverIcon,
  },
  3771: {
    color: '#F97E60',
    icon: <ComicsMangasIcon width={12} height={12} />,
    name: 'commons.clil.comicsMangas',
    cover: ComicsMangasCoverIcon,
  },
  3177: {
    color: '#F67E75',
    icon: <ManagementIcon width={12} height={12} />,
    name: 'commons.clil.management',
    cover: ManagementCoverIcon,
  },
  3305: {
    color: '#FC7E87',
    icon: <EconomicSciencesIcon width={12} height={12} />,
    name: 'commons.clil.economicSciences',
    cover: EconomicSciencesCoverIcon,
  },
  3283: {
    color: '#FC86B7',
    icon: <PoliticSciencesIcon width={12} height={12} />,
    name: 'commons.clil.politicSciences',
    cover: PoliticSciencesCoverIcon,
  },
  3080: {
    color: '#F395E0',
    icon: <SocialSciencesIcon width={12} height={12} />,
    name: 'commons.clil.socialSciences',
    cover: SocialSciencesCoverIcon,
  },
  3365: {
    color: '#D59AED',
    icon: <EsotericIcon width={12} height={12} />,
    name: 'commons.clil.esoteric',
    cover: EsotericCoverIcon,
  },
  3345: {
    color: '#BB99FD',
    icon: <ReligionIcon width={12} height={12} />,
    name: 'commons.clil.religion',
    cover: ReligionCoverIcon,
  },
  3193: {
    color: '#92A4FF',
    icon: <ComputerSciencesIcon width={12} height={12} />,
    name: 'commons.clil.computerSciences',
    cover: ComputerSciencesCoverIcon,
  },
  3069: {
    color: '#7AB9FF',
    icon: <TechniqueIcon width={12} height={12} />,
    name: 'commons.clil.technique',
    cover: TechniqueCoverIcon,
  },
  3051: {
    color: '#83D4F5',
    icon: <PureSciencesIcon width={12} height={12} />,
    name: 'commons.clil.pureSciences',
    cover: PureSciencesCoverIcon,
  },
  3165: {
    color: '#85DBDE',
    icon: <MedicineIcon width={12} height={12} />,
    name: 'commons.clil.medicines',
    cover: MedicineCoverIcon,
  },
  3802: {
    color: '#92E4D1',
    icon: <PracticesIcon width={12} height={12} />,
    name: 'commons.clil.practices',
    cover: PracticesCoverIcon,
  },
  3667: {
    color: '#7ADAA9',
    icon: <ArtsIcon width={12} height={12} />,
    name: 'commons.clil.arts',
    cover: ArtsCoverIcon,
  },
  3801: {
    color: '#89DA8E',
    icon: <DocumentationIcon width={12} height={12} />,
    name: 'commons.clil.documentation',
    cover: DocumentationsCoverIcon,
  },
  3377: {
    color: '#B3E87E',
    icon: <HistoryIcon width={12} height={12} />,
    name: 'commons.clil.history',
    cover: HistoryCoverIcon,
  },
  3395: {
    color: '#C7EA77',
    icon: <GeographyIcon width={12} height={12} />,
    name: 'commons.clil.geography',
    cover: GeographyCoverIcon,
  },
  3898: {
    color: '#DBE591',
    icon: <AtlasMapIcon width={12} height={12} />,
    name: 'commons.clil.atlasMaps',
    cover: AtlasMapCoverIcon,
  },
  3418: {
    color: '#CBD7BD',
    icon: <EncyclopediaIcon width={12} height={12} />,
    name: 'commons.clil.encyclopedia',
    cover: EncyclopediaCoverIcon,
  },
  4129: {
    color: '#D8CB95',
    icon: <YoungIcon width={12} height={12} />,
    name: 'commons.clil.youngAdults',
    cover: YoungCoverIcon,
  },
  '0000': {
    color: '#979797',
    icon: <NoCategoryIcon width={12} height={12} />,
    name: 'commons.clil.noCategory',
    cover: NoCategoryCoverIcon,
  },
}

export default ClilReferencesMappingConfig
