import { RootState } from '../store'
import { ChangePasswordStateInterface } from '../interfaces/change-password.state.interface'

export const selectChangePasswordState = (
  state: RootState,
): ChangePasswordStateInterface => {
  const { changePasswordState } = state
  return changePasswordState
}

export const selectChangePasswordStateIsLoadingValidateToken = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.isLoadingValidateToken
}

export const selectChangePasswordStateSuccessValidateToken = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.successValidateToken
}

export const selectChangePasswordStateErrorValidateToken = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.errorValidateToken
}

export const selectChangePasswordStateIsLoadingChangePassword = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.isLoadingChangePassword
}

export const selectChangePasswordStateSuccessChangePassword = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.successChangePassword
}

export const selectChangePasswordStateErrorChangePassword = (
  state: RootState,
): boolean => {
  const { changePasswordState } = state
  return changePasswordState.errorChangePassword
}
