import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const getReportByIdService = (
  reportId: string,
  cancelToken?: CancelToken,
) => {
  return apiInstance.get(ApiPathConfig.REPORT_DETAIL_BY_ID(reportId), {
    cancelToken,
  })
}
