import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  SVGProps,
  useState,
} from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const ButtonStyle = styled.button`
  background-color: ${({ enable }: { enable: boolean }) =>
    enable ? 'white' : 'transparent'};
  color: ${({ enable }: { enable: boolean }) =>
    enable ? '#252525' : '#666666'};
  border-radius: 4px;
  padding: 6px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  /* Caption/C1 - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;

  span:last-child {
    width: 120px;
  }
`

export interface ToggleButtonOptionInterface<T> {
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: ReactElement
  // eslint-disable-next-line react/no-unused-prop-types
  label: string
  // eslint-disable-next-line react/no-unused-prop-types
  value: T
  // eslint-disable-next-line react/no-unused-prop-types
  enable: boolean
}

const GlToggleButton = <T,>({
  handleOnSelect,
  optionSelected,
  options,
}: {
  handleOnSelect: (value: T) => void
  optionSelected: T
  options: ToggleButtonOptionInterface<T>[]
}) => {
  const { t } = useTranslation()
  return (
    <div
      className="flex flex-row items-center justify-center gap-1 bg-[#f7f7f7]"
      style={{
        padding: 2,
        borderRadius: 4,
      }}
    >
      {options
        .filter((option: ToggleButtonOptionInterface<T>) => option.enable)
        .map(({ icon, label, value }: ToggleButtonOptionInterface<T>) => (
          <ButtonStyle
            className="flex flex-row items-center justify-center"
            key={`analytics-switch-view-${value}`}
            type="button"
            enable={value === optionSelected}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              handleOnSelect(value)
            }}
          >
            {(icon && <span>{icon}</span>) || null}
            <span>{t(label)}</span>
          </ButtonStyle>
        ))}
    </div>
  )
}

export default GlToggleButton
