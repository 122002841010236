import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { SelectOptionInterface } from '../../../../../../../core/interface/select-option.interface'
import { analyticsDetailsV2StatePutTextBoxesOutputAction } from '../../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { useAppDispatch } from '../../../../../../../store/hooks'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import { AnalyticOutputInterface } from '../../../../../../../core/interface/analytics/analytic-output.interface'
import EditMenuSection from './EditMenuSection'
import { EditSectionEnum } from '../../../../../../../core/enums/analytic-edit-section.enum'
import { EditOptionInterface } from '../../../../../../../core/interface/analytics/analytic-edit-option.interface'
import GlHDivider from '../../../../../../share/GlHDivider'
import { AnalyticOutputTextSizeEnum } from '../../../../../../../core/enums/analytic-output-text-size.enum'
import { AnalyticOutputTextboxThemeEnum } from '../../../../../../../core/enums/analytic-output-textbox-theme.enum'
import { ConvertOutputTextboxToOption } from '../../../../../../../core/utils/convert-output-textbox-to-option.util'

const TextStyle = styled.span`
  font-family: 'Fira Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
`

const EditMenuTextBox = ({ output }: { output: AnalyticOutputInterface }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const outputOptionsDatas: EditOptionInterface<
    | AnalyticOutputLayoutSizeEnum
    | AnalyticOutputTextSizeEnum
    | AnalyticOutputTextboxThemeEnum
    | string
    | boolean
  >[] = ConvertOutputTextboxToOption(output)

  const handleOnSizeDropdown = (
    optionId: string,
    value: SelectOptionInterface<
      string | boolean | AnalyticOutputLayoutSizeEnum
    >,
  ) => {
    void dispatch(
      analyticsDetailsV2StatePutTextBoxesOutputAction({
        outputId: output.id,
        textBoxesObject: {
          layout_size:
            optionId === 'layout_size'
              ? (value.value as AnalyticOutputLayoutSizeEnum)
              : output.layout_size || AnalyticOutputLayoutSizeEnum.HALF,
          title: output.textbox?.title || '',
          text: output.textbox?.text || '',
          use_dark_theme:
            optionId === 'use_dark_theme'
              ? (value.value as boolean)
              : output.textbox?.use_dark_theme || false,
          text_size:
            optionId === 'text_size'
              ? parseInt(value.value as string, 10)
              : output.textbox?.text_size || AnalyticOutputTextSizeEnum.MEDIUM,
        },
      }),
    )
  }

  return (
    <>
      <TextStyle>{t('text')}</TextStyle>
      <GlHDivider />
      <EditMenuSection
        sectionTitle={t('EditReadershipProfil.display')}
        section={EditSectionEnum.DISPLAY}
        options={outputOptionsDatas}
        handleOnSizeDropdown={handleOnSizeDropdown}
      />
      <GlHDivider />
      <EditMenuSection
        sectionTitle={t('addModuleMenu.options')}
        section={EditSectionEnum.OPTIONS}
        options={outputOptionsDatas}
        handleOnSizeDropdown={handleOnSizeDropdown}
      />
    </>
  )
}

export default EditMenuTextBox
