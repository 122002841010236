import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { PercentageNumber } from '../../../../../../../../share/PercentageNumber'

interface ValueTooltip {
  value: number
  color: string
  key: string
}

interface Props {
  values: Array<ValueTooltip>
  maximumFractionDigits: number
  isClickable?: boolean
  addPositiveSign?: boolean
  textColorBlack?: boolean
}

const PayloadValueStyle = styled.div<{
  color: string
  isFirst: boolean
  isLast: boolean
  textColorBlack?: boolean
}>`
  ${({ color, isFirst, isLast, textColorBlack = false }) => `
    color: ${textColorBlack ? '#252525' : color};
    background-color: ${color}1a;
    padding: 4px 9px;
    text-align: center;
    ${isFirst ? 'border-radius: 4px 0 0 4px;' : ''}
    ${isLast ? 'border-radius: 0 4px 4px 0;' : ''}
    ${isFirst && isLast ? 'border-radius: 4px;' : ''}
  `}
`

const CustomContentValuesTooltip = ({
  values,
  maximumFractionDigits,
  isClickable,
  addPositiveSign = false,
  textColorBlack = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex items-center">
        {values.map((item, index, array) => (
          <div key={item.key} className="flex-1">
            <PayloadValueStyle
              color={item.color}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              textColorBlack={textColorBlack}
            >
              <PercentageNumber
                value={item.value}
                maximumFractionDigits={maximumFractionDigits}
                addPositiveSign={addPositiveSign}
              />
            </PayloadValueStyle>
          </div>
        ))}
      </div>
      {isClickable && (
        <div className="bg-[#666] text-white p-2 rounded">
          <p>{t('clickToZoom')}</p>
        </div>
      )}
    </>
  )
}

export default CustomContentValuesTooltip
