import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { select, zoom } from 'd3'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { WorkMirrorBookInterface } from '../../../../../core/interface/report/work-mirror-book.interface'
import { BookInterface } from '../../../../../core/interface/book.interface'
import workMirrorConstellationPositionUtil from './work-mirror-constellation-position.util'
import appendGroupCirclesBaseUtil from './appendGroupCirclesBaseUtil'
import appendCenterBookInputsUtil from './appendCenterBookInputsUtil'
import SvgBookGroup from './SvgBookGroup'
import { ConstellationBookDataInterface } from './ConstellationBookData.interface'
import BookCardConstellation from './BookCardConstellation'
import {
  buildRelativePath,
  PathConfig,
} from '../../../../../core/config/PathConfig'
import { ReportTypeEnum } from '../../../../../core/enums/report-type.enum'

const MARGIN_BOUNCE_MENU = 35

const D3ConstellationWorkMirror = ({
  booksInput,
  booksOutput,
  handleBookHovered,
  bookDetailsSelected,
  handleBookSelected,
}: {
  booksInput: BookInterface[]
  booksOutput: WorkMirrorBookInterface[]
  handleBookHovered: (eanId: number | null) => void
  bookDetailsSelected: WorkMirrorBookInterface | null
  handleBookSelected: (bookDetails: WorkMirrorBookInterface | null) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const svgRef = useRef<any>(null)
  const svgContainerRef = useRef<any>(null)
  const [booksConstellation, setBooksConstellation] = useState<{
    level1: ConstellationBookDataInterface[]
    level2: ConstellationBookDataInterface[]
    level3: ConstellationBookDataInterface[]
    level4: ConstellationBookDataInterface[]
    level5: ConstellationBookDataInterface[]
  }>({
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
  })

  let timer: NodeJS.Timeout | null = null
  const [clils, setClils] = useState<Set<any>>(new Set())
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [bookDisplay, setBookDisplay] =
    useState<ConstellationBookDataInterface | null>(null)
  const [menuPositionTop, setMenuPositionTop] = useState<boolean>(false)
  const componentRef = useRef<HTMLDivElement>(null)
  const [heightOfBounceMenu, setHeightOfBounceMenu] = useState<number>(0)

  const [transform, setTransform] = useState<{
    x: number
    y: number
    k: number
  }>({ x: 0, y: 0, k: 0 })

  const setTransformCb = useCallback(
    (values: { x: number; y: number; k: number }) => {
      setTransform(values)
    },
    [transform],
  )

  useEffect(() => {
    setClils(
      new Set(
        booksOutput.map((output: WorkMirrorBookInterface) => output.book.clil1),
      ),
    )
    const width = svgRef?.current?.clientWidth
    const height = svgRef?.current?.clientHeight

    setTransformCb({
      x: 0,
      y: 0,
      k: 1,
    })
    const svg = select(svgRef.current)
    const svgContainer = select(svgContainerRef.current)

    const radiusBaseCircle: number[] = [200, 400, 600, 800, 1000]

    appendGroupCirclesBaseUtil(svgContainer, {
      radiusBaseCircle,
      width,
      height,
    })

    if ((booksInput || []).length && (booksOutput || []).length) {
      const { level1, level2, level3, level4, level5 } =
        workMirrorConstellationPositionUtil({
          center: { x: width / 2, y: height / 2 },
          radiusBaseCircle,
          booksOutput,
        })
      setBooksConstellation({ level1, level2, level3, level4, level5 })
      appendCenterBookInputsUtil(svgContainer, booksInput, { width, height })
    } else {
      svgContainer
        .selectAll('g')
        .append('circle')
        .attr('cx', width / 2)
        .attr('cy', height / 2)
        .attr('r', 50)
        .attr('fill', 'black')
        .attr('stroke', '#b2b2b233')
    }

    svg.call(
      zoom().on('zoom', (event) => {
        svgContainer.attr('transform', event.transform)
        setTransformCb(event.transform)
      }),
    )

    return () => {}
  }, [])

  const handleDisplayBookCard = useCallback(
    (data: ConstellationBookDataInterface | null) => {
      if (data) {
        setBookDisplay(data)
        setNeedClose(false)
        handleBookHovered(data?.workMirror?.book?.id_ean)
      } else {
        setNeedClose(true)
      }
    },
    [],
  )

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setNeedClose(false)
      setBookDisplay(null)
      handleBookHovered(null)
    }, 500)
  }

  useEffect(() => {
    if (needClose) {
      handleTimeOut()
    }

    if (bookDisplay) {
      const componentHeight = componentRef?.current?.clientHeight as number
      setHeightOfBounceMenu(componentHeight)
      if (
        (transform?.y || 0) +
          (bookDisplay?.y || 0) * (transform?.k || 0) +
          (bookDisplay?.r || 0) * 2 +
          componentHeight +
          MARGIN_BOUNCE_MENU >
          window.innerHeight &&
        !menuPositionTop
      ) {
        setMenuPositionTop(true)
      } else if (
        +(transform?.y || 0) +
          +(bookDisplay?.y || 0) * +(transform?.k || 0) +
          (bookDisplay?.r || 0) * 2 +
          componentHeight +
          MARGIN_BOUNCE_MENU <=
          window.innerHeight &&
        menuPositionTop
      ) {
        setMenuPositionTop(false)
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [bookDisplay, needClose])
  const handleOnMouseEnter = () => {
    setNeedClose(false)
  }
  const handleOnMouseLeave = () => {
    setNeedClose(true)
  }

  const handleOnClickBounceToNewReport = (event: React.MouseEvent) => {
    event.preventDefault()
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        `new?type=${ReportTypeEnum.WORK_MIRROR}&eans=${bookDisplay?.workMirror.id_ean}`,
      ]),
    )
  }

  return (
    <div
      className="absolute top-0 left-0 w-full h-full flex"
      style={{
        zIndex: 6,
      }}
    >
      <div className="relative w-full h-full">
        {bookDisplay && (
          <div
            ref={componentRef}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            className={`absolute flex items-center justify-center ${
              menuPositionTop ? 'flex-col-reverse' : 'flex-col'
            }`}
            style={{
              top: menuPositionTop
                ? (transform?.y || 0) +
                  (bookDisplay?.y || 0) * (transform?.k || 0) -
                  bookDisplay.r * 1 -
                  heightOfBounceMenu
                : (transform?.y || 0) +
                  (bookDisplay?.y || 0) * (transform?.k || 0) +
                  bookDisplay.r,
              left:
                (transform?.x || 0) +
                (bookDisplay?.x || 0) * (transform?.k || 0) -
                126,
            }}
          >
            <button
              type="button"
              className="flex flex-row gap-2 rounded p-2 items-center justify-center hover:shadow-xl bg-black text-white"
              onClick={handleOnClickBounceToNewReport}
            >
              {t('commons.buttons.bounce')}
            </button>
            <BookCardConstellation data={bookDisplay} />
          </div>
        )}
        <svg
          ref={svgRef}
          className="w-full h-full  bg-white"
          style={{
            borderRadius: 16,
          }}
          preserveAspectRatio="xMidYMid slice"
        >
          <g ref={svgContainerRef}>
            <defs>
              {Array.from(clils).map((clil) => {
                return (
                  <React.Fragment key={`${clil}-radialGradient`}>
                    <radialGradient
                      id={`radialGradient-${clil}-out`}
                      cx="50%"
                      cy="50%"
                      r="50%"
                    >
                      <stop offset="0.841325" stopColor="white" />
                      <stop offset="1" stopColor="white" stopOpacity="0.5" />
                    </radialGradient>
                    <radialGradient
                      id={`radialGradient-${clil}-in`}
                      cx="50%"
                      cy="50%"
                      r="50%"
                    >
                      <stop offset="0.202484" stopColor="white" />
                      <stop offset="1" stopColor="white" stopOpacity="0.5" />
                    </radialGradient>
                  </React.Fragment>
                )
              })}
            </defs>
            {booksConstellation.level1.map(
              (value: ConstellationBookDataInterface) => (
                <SvgBookGroup
                  key={`book-node-level1-${value?.workMirror?.id_ean}`}
                  data={value}
                  edgeCenter={10}
                  svgContainer={select(svgContainerRef.current)}
                  handleDisplayBookCard={handleDisplayBookCard}
                  bookIsSelected={
                    bookDetailsSelected?.id_ean === value?.workMirror?.id_ean
                  }
                  handleBookSelected={handleBookSelected}
                />
              ),
            )}
            {booksConstellation.level2.map(
              (value: ConstellationBookDataInterface) => (
                <SvgBookGroup
                  key={`book-node-level2-${value?.workMirror?.id_ean}`}
                  data={value}
                  edgeCenter={4}
                  svgContainer={select(svgContainerRef.current)}
                  handleDisplayBookCard={handleDisplayBookCard}
                  bookIsSelected={
                    bookDetailsSelected?.id_ean === value?.workMirror?.id_ean
                  }
                  handleBookSelected={handleBookSelected}
                />
              ),
            )}
            {booksConstellation.level3.map(
              (value: ConstellationBookDataInterface) => (
                <SvgBookGroup
                  key={`book-node-level3-${value?.workMirror?.id_ean}`}
                  data={value}
                  edgeCenter={2}
                  svgContainer={select(svgContainerRef.current)}
                  handleDisplayBookCard={handleDisplayBookCard}
                  bookIsSelected={
                    bookDetailsSelected?.id_ean === value?.workMirror?.id_ean
                  }
                  handleBookSelected={handleBookSelected}
                />
              ),
            )}
            {booksConstellation.level4.map(
              (value: ConstellationBookDataInterface) => (
                <SvgBookGroup
                  key={`book-node-level4-${value?.workMirror?.id_ean}`}
                  data={value}
                  svgContainer={select(svgContainerRef.current)}
                  handleDisplayBookCard={handleDisplayBookCard}
                  bookIsSelected={
                    bookDetailsSelected?.id_ean === value?.workMirror?.id_ean
                  }
                  handleBookSelected={handleBookSelected}
                />
              ),
            )}
            {booksConstellation.level5.map(
              (value: ConstellationBookDataInterface) => (
                <SvgBookGroup
                  key={`book-node-level5-${value?.workMirror?.id_ean}`}
                  data={value}
                  svgContainer={select(svgContainerRef.current)}
                  handleDisplayBookCard={handleDisplayBookCard}
                  bookIsSelected={
                    bookDetailsSelected?.id_ean === value?.workMirror?.id_ean
                  }
                  handleBookSelected={handleBookSelected}
                />
              ),
            )}
          </g>
        </svg>
      </div>
    </div>
  )
}

export default memo(D3ConstellationWorkMirror)
