import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

const MainStyle = styled.h1`
  margin: 8px 0 8px 8px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? '#252525' : '#25252566'};
`

const H1Style = styled.h1`
  margin: 8px 0 8px 16px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? '#252525' : '#25252566'};
`
const H2Style = styled.h2`
  margin: 8px 0 8px 24px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? '#252525' : '#25252566'};
`

const H3Style = styled.h2`
  margin: 8px 0 8px 32px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? '#252525' : '#25252566'};
`

const LegalNavType = ({
  isActive,
  type,
  path,
  content,
}: {
  isActive: boolean
  type: string
  path: string
  content: string
}) => {
  if (type === 'main') {
    return (
      <Link to={path} className="no-underline">
        <MainStyle
          isActive={isActive}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Link>
    )
  }
  if (type === 'h1') {
    return (
      <Link to={path} className="no-underline">
        <H1Style
          isActive={isActive}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Link>
    )
  }

  if (type === 'h2') {
    return (
      <Link to={path} className="no-underline">
        <H2Style
          isActive={isActive}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Link>
    )
  }

  if (type === 'h3') {
    return (
      <Link to={path} className="no-underline">
        <H3Style
          isActive={isActive}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Link>
    )
  }

  return null
}

export default LegalNavType
