import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BookInterface } from '../../core/interface/book.interface'
import { CompareGroupValueType } from '../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { CompareGroupType } from '../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import AudienceCatalogInterface from '../../core/interface/audience/audience-catalog.interface'
import RecapInputTag from '../analytics-details-v2/view/readership-profile/inputs/recap/RecapInputTag'
import { SetColorConfig } from '../../core/config/SetColorConfig'
import NewAudienceTag from './new-audience/NewAudienceTag'
import GlSpinner from '../share/GlSpinner'
import { ValidationButtonStyle } from '../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'

interface Props {
  searchType: CompareGroupType
  values: CompareGroupValueType
  booksSelected: Array<BookInterface>
  userAlreadyOwnBook: boolean
  useFullGleephCatalog: boolean
  catalogSelected: AudienceCatalogInterface | null
  isNano: boolean
  volume: number
  isLoading: boolean
  menuPositionTop?: boolean
  onValidate?: () => void
}

const AudienceRecap = ({
  searchType,
  values,
  booksSelected,
  userAlreadyOwnBook,
  useFullGleephCatalog,
  catalogSelected,
  isNano,
  volume,
  isLoading,
  menuPositionTop = false,
  onValidate,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div
      className={`w-full h-14 bg-white rounded-xl flex flex-row items-center justify-${
        menuPositionTop ? 'between' : 'center'
      } px-8 py-2 text-[#252525]`}
      style={{
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <div className="flex flex-row gap-2 items-center">
        <span>
          {t(
            userAlreadyOwnBook
              ? 'createAudience.iTargetReadersNear'
              : 'createAudience.iTargetReadersOf',
          )}
        </span>
        <RecapInputTag<CompareGroupType, CompareGroupValueType>
          type={searchType}
          value={values}
          color={SetColorConfig[0].color1}
          menuPositionTop={menuPositionTop}
          booksSelected={booksSelected}
        />
        <span>{t('createAudience.inCatalog')}</span>
        <NewAudienceTag
          color={SetColorConfig[0].color2}
          label={t(
            `createAudience.catalogTag.${
              useFullGleephCatalog ? 'full' : catalogSelected?.external_name
            }`,
          )}
        />
        <span>{t('createAudience.withVolume')}</span>
        <NewAudienceTag
          color="#86DAAD"
          label={new Intl.NumberFormat().format(volume)}
        />
        <span>
          {t(isNano ? 'createAudience.potentialNano' : 'commons.user', {
            count: volume,
          })}
        </span>
      </div>
      {onValidate && (
        <ValidationButtonStyle
          type="button"
          disabled={!values || isLoading}
          onClick={onValidate}
        >
          <span>{t('validateTheSearch')}</span>
          {isLoading && (
            <div className="absolute w-full h-full flex items-center justify-center top-0">
              <GlSpinner color="#25252566" />
            </div>
          )}
        </ValidationButtonStyle>
      )}
    </div>
  )
}

export default memo(AudienceRecap)
