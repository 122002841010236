import { ReactElement } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import AnalyticDetailsHeaderLayout from './AnalyticDetailsHeaderLayout'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import GlLoader from '../share/GlLoader'
import { patchReportService } from '../../core/api/services/bookmetrie/reports.service'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../store/hooks'
import { AnalyticsPermissionEnum } from '../../core/enums/analytics-permission.enum'
import { AnalyticsStatusEnum } from '../../core/enums/analytics-status.enum'

const LayoutStyle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
`

const AnalyticDetailsLayout = ({
  isLoading,
  hasButtonPrevious,
  children,
  handleExitAnalyticDetails,
  analyticName,
  handleRenameAnalytic,
  sharedBy,
  disableEdit,
}: {
  isLoading: boolean
  hasButtonPrevious: boolean
  children: ReactElement
  handleExitAnalyticDetails: (toExit?: boolean) => void
  analyticName: string
  handleRenameAnalytic: (newName: string) => void
  sharedBy?: string
  disableEdit?: boolean
}) => {
  return (
    <LayoutStyle>
      <AnalyticDetailsHeaderLayout
        handleExitAnalyticDetails={handleExitAnalyticDetails}
        analyticName={analyticName}
        isLoading={isLoading}
        hasButtonPrevious={hasButtonPrevious}
        handleRenameAnalytic={handleRenameAnalytic}
        disableEdit={disableEdit}
        sharedBy={sharedBy}
      />
      {(isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <GlLoader />
        </div>
      )) ||
        children}
    </LayoutStyle>
  )
}

export default AnalyticDetailsLayout
