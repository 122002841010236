import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LeftChevronIcon } from '../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as ReloadIcon } from '../../assets/icons/app/reload.icon.svg'
import { ReactComponent as SharedIcon } from '../../assets/icons/app/shared.icon.svg'
import GlSpacer from '../share/GlSpacer'

const HeaderLayoutStyle = styled.header`
  height: 48px;
  padding: 8px;
  color: white;
  background: #252525;
`

const LabelShareStyle = styled.div`
  display: flex;
  height: 18px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  padding: 4px;
  margin-left: 4px;
`

const ExitButtonStyle = styled.button`
  color: #929292;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  height: 28px;
  margin: 8px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #929292;
`

const AnalyticsTypeStyleInput = styled.input`
  min-width: 130px;
  border: none;
  outline: none;
  background-color: #252525;
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px;

  &:disabled {
    pointer-events: none;
  }
  &:hover:enabled {
    background-color: #525252;
  }
  &:focus:enabled {
    background-color: #525252;
  }
`

const AnalyticDetailsHeaderLayout = ({
  isLoading,
  hasButtonPrevious,
  analyticName,
  handleExitAnalyticDetails,
  handleRenameAnalytic,
  sharedBy,
  disableEdit = false,
}: {
  isLoading: boolean
  hasButtonPrevious: boolean
  analyticName: string
  handleExitAnalyticDetails: (toExit?: boolean) => void
  handleRenameAnalytic: (newName: string) => void
  sharedBy?: string
  disableEdit?: boolean
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [inputReportNameValue, setInputReportNameValue] =
    useState<string>(analyticName)

  useEffect(() => {
    setInputReportNameValue(analyticName)
  }, [analyticName])

  function handleKeyEnterUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  return (
    <HeaderLayoutStyle className="flex flex-row items-center justify-center">
      <div className="absolute left-[16px] flex flex-row gap-2 items-center justify-between">
        <ExitButtonStyle
          type="button"
          className="flex flex-row items-center justify-center gap-1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault()
            handleExitAnalyticDetails()
          }}
        >
          <LeftChevronIcon className="w-[14px] h-[14px]" />
          <span>Retour</span>
        </ExitButtonStyle>
        {hasButtonPrevious && (
          <ExitButtonStyle
            type="button"
            className="flex flex-row items-center justify-center gap-1"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              navigate(-1)
            }}
          >
            <ReloadIcon className="w-[14px] h-[14px] text-white" />
            <span className="text-white">Recherche précédente</span>
          </ExitButtonStyle>
        )}
      </div>

      <GlSpacer />
      {!isLoading && (
        <AnalyticsTypeStyleInput
          type="text"
          style={{ width: `${inputReportNameValue.length}ch` }}
          value={inputReportNameValue}
          onChange={(event) => setInputReportNameValue(event.target.value)}
          onBlur={() => handleRenameAnalytic(inputReportNameValue)}
          onKeyUp={handleKeyEnterUp}
          disabled={disableEdit}
        />
      )}
      {!!sharedBy && (
        <LabelShareStyle>
          <SharedIcon />
          <span>
            {t('webApp.reports.shared.sharedBy', {
              fullName: sharedBy,
            })}
          </span>
        </LabelShareStyle>
      )}
      <GlSpacer />
    </HeaderLayoutStyle>
  )
}

export default AnalyticDetailsHeaderLayout
