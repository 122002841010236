import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import StackedTooltip from './chartComponents/tooltip/StackedTooltip'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import {
  clilChildrenMapping,
  ROOT_PARENT_CLIL_KEY,
} from '../../../../../../core/utils/get-clil-level.utils'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { getColors } from './getColors'

interface VerticalStackedClilDatasetInterface {
  [SourceEnum.CLIL]: string
  [p: string]: number | string
}

const AnalyticsOutputVerticalStackedReCharts = ({
  valuesConfigurations,
  display,
  title,
  subTitle,
  dataset,
  datasetModule,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  parentId,
  typeDataset,
  setColor,
  isOrganizeMenuOpen,
  outputIdToScroll,
  onClick,
  level,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  display: AnalyticOutputLayoutSizeEnum
  title?: string
  subTitle?: string
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  parentId: number | null
  typeDataset: DatasetTypeEnum
  setColor: SetColorEnum
  isOrganizeMenuOpen?: boolean
  outputIdToScroll?: string
  onClick?: (clil: number, lvl: number) => void
  level?: number
}) => {
  const { t } = useTranslation()
  const [currentTooltip, setCurrentTooltip] = useState('')

  const primaryValueConfiguration = valuesConfigurations.filter(
    (p) => p.is_primary_key,
  )[0]

  const isClil = typeDataset === DatasetTypeEnum.CLIL
  const isClickable = isClil && onClick && level && level < 3 && !isPreview

  const colorMapping = getColors(setColor)

  const colorOpacity = (baseColor: string, index: number, total: number) => {
    const opacity = Math.floor((((index + 1) / total) * 0.8 + 0.2) * 255)
    const opacityHex = opacity.toString(16).padStart(2, '0')
    return `${baseColor}${opacityHex}`
  }

  const getRelevantClil = () => {
    const parentIdString = (parentId || ROOT_PARENT_CLIL_KEY).toString()
    return clilChildrenMapping[parentIdString]
  }

  // TODO : remplacer la gestion des clils par un array plutot qu'un dictionnaire
  //  https://gleeph.atlassian.net/browse/B2B-1061
  function encodeClilKey(clil: string) {
    return ` ${clil}`
  }

  const visibleDataset = useMemo(() => {
    if (!dataset) return []
    const visibleSources =
      valuesConfigurations
        .filter((c) => !c.is_primary_key && c.show_in_chart)
        .map((value) => value.source) || []

    if (isClil) {
      const data = dataset as unknown as VerticalStackedClilDatasetInterface[]

      return data
        .filter((data) =>
          visibleSources.includes(
            data[primaryValueConfiguration.source] as SourceEnum,
          ),
        )
        .map((entry) => {
          const filteredEntry: VerticalStackedClilDatasetInterface = {
            [SourceEnum.CLIL]: entry[SourceEnum.CLIL],
          }
          getRelevantClil().forEach((key) => {
            if (entry[key] !== undefined) {
              filteredEntry[key] = entry[key]
            }
          })
          const matchElement = Object.entries(filteredEntry)
            .sort((a, b) => {
              if (a[0] === SourceEnum.CLIL) return -1
              return Number(b[1]) - Number(a[1])
            })
            .slice(0, 7)

          return matchElement.reduce(
            (acc, obj) => ({
              ...acc,
              [obj[0] === SourceEnum.CLIL ? obj[0] : encodeClilKey(obj[0])]:
                obj[1],
            }),
            {} as VerticalStackedClilDatasetInterface,
          )
        })
    }

    return dataset.filter((data) =>
      visibleSources.includes(
        data[primaryValueConfiguration.source] as SourceEnum,
      ),
    )
  }, [dataset, valuesConfigurations])

  const thereIsSubjectObjectValue = visibleDataset.some(
    (data) =>
      data[SourceEnum.CLIL] === SourceEnum.SUBJECT_OBJECT_VALUE ||
      data[SourceEnum.BOOK_READ_COUNT] === SourceEnum.SUBJECT_OBJECT_VALUE,
  )
  const thereIsCompareGroupValue = visibleDataset.some(
    (data) =>
      data[SourceEnum.CLIL] === SourceEnum.COMPARE_GROUP_VALUE ||
      data[SourceEnum.BOOK_READ_COUNT] === SourceEnum.COMPARE_GROUP_VALUE,
  )

  const CustomTooltip = useCallback(
    (props: any) => {
      const colorArray = []
      if (thereIsSubjectObjectValue) {
        colorArray.push(colorMapping[SourceEnum.SUBJECT_OBJECT_VALUE])
      }
      if (thereIsCompareGroupValue) {
        colorArray.push(colorMapping[SourceEnum.COMPARE_GROUP_VALUE])
      }
      return (
        <StackedTooltip
          {...props}
          typeDataset={typeDataset}
          targetKey={currentTooltip}
          dataset={visibleDataset}
          colorMapping={colorArray}
          isClickable={isClickable}
        />
      )
    },
    [currentTooltip],
  )

  const onClickClil = (payload: CategoricalChartState) => {
    const { activePayload } = payload
    if (isClickable && activePayload) {
      onClick(
        Number(
          activePayload
            .find((payload) => payload.dataKey === currentTooltip)
            .dataKey.trim(),
        ),
        level,
      )
    }
  }

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={
        primaryValueConfiguration.source === SourceEnum.AGE
          ? [t('InfoBulles.distributionByAgeVertical')]
          : [t('InfoBulles.distributionByLibraries')]
      }
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.VERTICAL_STACKED_BAR}
      shrink={isOrganizeMenuOpen}
    >
      <ResponsiveContainer width="100%">
        <BarChart
          style={{ cursor: isClickable ? 'pointer' : 'default' }}
          data={visibleDataset}
          margin={{
            bottom:
              isOrganizeMenuOpen && outputIdToScroll !== datasetModule.id
                ? 25
                : 0,
          }}
          onClick={onClickClil}
        >
          <CartesianGrid horizontal vertical={false} />
          <XAxis
            dataKey={primaryValueConfiguration.source}
            strokeWidth={3}
            strokeOpacity={0.5}
            tickLine={false}
            tickFormatter={(tick: string) => t(tick)}
            height={20}
          />
          <YAxis
            orientation="right"
            axisLine={false}
            tickLine={false}
            domain={[0, 100]}
            tickFormatter={(tick) => tick.toFixed(0)}
          />
          <Tooltip cursor={{ fill: 'transparent' }} content={CustomTooltip} />
          {(visibleDataset.length &&
            Object.keys(visibleDataset[0])
              .filter((key) => key !== primaryValueConfiguration.source)
              .map((key, index, array) => {
                return (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId="a"
                    onMouseEnter={() => setCurrentTooltip(key)}
                    onMouseLeave={() => setCurrentTooltip('')}
                    shape={<Rectangle stroke="white" />}
                  >
                    {thereIsSubjectObjectValue && (
                      <Cell
                        key="cell-studyObjectValue"
                        fill={colorOpacity(
                          colorMapping[SourceEnum.SUBJECT_OBJECT_VALUE],
                          index,
                          array.length,
                        )}
                      />
                    )}
                    {thereIsCompareGroupValue && (
                      <Cell
                        key="cell-compareGroupValue"
                        fill={colorOpacity(
                          colorMapping[SourceEnum.COMPARE_GROUP_VALUE],
                          index,
                          array.length,
                        )}
                      />
                    )}
                  </Bar>
                )
              })) ||
            null}
        </BarChart>
      </ResponsiveContainer>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputVerticalStackedReCharts
