import { ReactElement } from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import BackGroundAuthIcon from '../../assets/icons/app/background-auth.icon.svg'
import AuthHeader from './AuthHeader'
import CustomSnackBar from '../share/snackbar/GlSnackBarMessage'

const BackGroundLayout = styled.div`
  background-image: url('${BackGroundAuthIcon}');
  background-repeat: no-repeat;
  background-position: 50% 20%;
  top: calc(100% - 85%);
`
interface Props {
  children: ReactElement
}
const AuthAppLayout = ({ children }: Props) => {
  return (
    <BackGroundLayout className="w-full h-full flex flex-col bg-webApp overflow-hidden">
      <AuthHeader />
      <PerfectScrollbar className="flex-auto p-16">
        <div className="m-16 h-full flex-auto flex flex-row  items-center justify-center">
          {children}
        </div>
      </PerfectScrollbar>
      <CustomSnackBar />
    </BackGroundLayout>
  )
}

export default AuthAppLayout
