import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import ShareAnalyticDetailsReadershipProfilView from './readership/ShareAnalyticDetailsReadershipProfilView'
import ShareAnalyticDetailsWorkMirrorView from './work-mirror/ShareAnalyticDetailsWorkMirrorView'

const ShareAnalyticDetailsSwitchView = ({
  type,
}: {
  type: AnalyticsTypeEnum
}) => {
  switch (type) {
    case AnalyticsTypeEnum.WORK_MIRROR:
      return <ShareAnalyticDetailsWorkMirrorView />
    case AnalyticsTypeEnum.READERSHIP_PROFILE:
      return <ShareAnalyticDetailsReadershipProfilView />
    default:
      return null
  }
}

export default ShareAnalyticDetailsSwitchView
