import { RootState } from '../store'

export const selectSignInStateIsLoadingPostSignIn = (
  state: RootState,
): boolean => {
  const { signInState } = state
  return signInState.isLoadingPostSignIn
}

export const selectSignInStateSignInSuccess = (
  state: RootState,
): { access: string; refresh: string } | null => {
  const { signInState } = state
  return signInState.signInSuccess
}

export const selectSignInStateSignInError = (state: RootState): boolean => {
  const { signInState } = state
  return signInState.signInError
}
export const selectSignInStateSignInMessageError = (
  state: RootState,
): string => {
  const { signInState } = state
  return signInState.signInMessageError
}
