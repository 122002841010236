import React, { useEffect, useState } from 'react'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'
import { ReactComponent as ChevronTopIcon } from '../../../assets/icons/app/top-chevron.icon.svg'
import { ReactComponent as ChevronBottomIcon } from '../../../assets/icons/app/bottom-chevron.icon.svg'

interface Props {
  sortDirection: SortDirectionEnum
  handleChangeDirection: (sortDirection: SortDirectionEnum) => void
  hasEmptyDirection?: boolean
}
const SortButtons = ({
  sortDirection = SortDirectionEnum.EMPTY,
  handleChangeDirection,
  hasEmptyDirection = false,
}: Props) => {
  const [direction, setDirection] = useState<SortDirectionEnum>(sortDirection)
  useEffect(() => {
    setDirection(sortDirection)
  }, [sortDirection])

  return (
    <div className="flex-none flex flex-col gap-0.5 items-center justify-center hover:bg-[#f4f4f4] active:bg-[#e3e3e3] rounded-sm">
      <button
        type="button"
        className="p-1 pb-0.5"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          event.stopPropagation()
          const newDirection =
            hasEmptyDirection && direction === SortDirectionEnum.ASC
              ? SortDirectionEnum.EMPTY
              : SortDirectionEnum.ASC
          setDirection(newDirection)
          handleChangeDirection(newDirection)
        }}
      >
        <ChevronTopIcon
          color={direction === SortDirectionEnum.ASC ? '#252525' : '#b2b2b2'}
        />
      </button>
      <button
        type="button"
        className="p-1 pt-0.5"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          event.stopPropagation()
          const newDirection =
            hasEmptyDirection && direction === SortDirectionEnum.DESC
              ? SortDirectionEnum.EMPTY
              : SortDirectionEnum.DESC
          setDirection(newDirection)
          handleChangeDirection(newDirection)
        }}
      >
        <ChevronBottomIcon
          color={direction === SortDirectionEnum.DESC ? '#252525' : '#b2b2b2'}
        />
      </button>
    </div>
  )
}

export default SortButtons
