import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import {
  SubTitleSelectionStyle,
  TitleSelectionStyle,
} from '../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'
import InputSelectionButton from '../analytics-details-v2/view/readership-profile/inputs/InputSelectionButton'
import {
  ReadershipProfileInputTypeInterface,
  StudyObjectGroupCompareEnum,
} from '../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import InputTypeSwitchInput from '../analytics-details-v2/view/readership-profile/inputs/InputTypeSwitchInput'
import { ReactComponent as RevertIcon } from '../../assets/icons/app/revert-icon.svg'
import { BookInterface } from '../../core/interface/book.interface'

const RevertButtonStyle = styled.button`
  color: #3b3b3b;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(37, 37, 37, 0.1);
  height: 28px;
`

const InputTypeChoice = <InputType, ValueType>({
  disable,
  keyValue,
  color,
  title,
  subTitle,
  inputsTypeConfig,
  inputTypeSelected,
  inputValue,
  handleOnClickInputSelectionButton,
  handleSelectValue,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  keyValue: string
  color: string
  title: string
  subTitle: string
  inputsTypeConfig: ReadershipProfileInputTypeInterface<InputType>[]
  inputTypeSelected: InputType
  handleOnClickInputSelectionButton: (type: InputType) => void
  inputValue: ValueType
  handleSelectValue: (type: ValueType, booksToRemove?: BookInterface[]) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  const { t } = useTranslation()
  const onClickReinitButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleSelectValue(null as ValueType, [...booksSelected])
  }

  return (
    <>
      <div className="flex-none flex flex-col">
        <TitleSelectionStyle>{title}</TitleSelectionStyle>
        <div className="flex flex-row items-center justify-between">
          <SubTitleSelectionStyle className="h-full flex items-end">
            <span>{subTitle}</span>
          </SubTitleSelectionStyle>
          {inputValue && (
            <RevertButtonStyle
              disabled={disable}
              type="button"
              className="flex flex-row items-center justify-center gap-2"
              onClick={onClickReinitButton}
            >
              <span>{t('reset')}</span>
              <RevertIcon />
            </RevertButtonStyle>
          )}
        </div>
      </div>
      {!inputValue && (
        <div className="flex-none flex flex-row gap-4">
          {inputsTypeConfig.map(
            (inputType: ReadershipProfileInputTypeInterface<InputType>) => (
              <InputSelectionButton
                key={`${keyValue}-${inputType?.value}`}
                active={inputTypeSelected === inputType?.value}
                labelKey={inputType.labelKey}
                icon={inputType.icon}
                handleOnClickInputSelectionButton={() =>
                  handleOnClickInputSelectionButton(inputType.value)
                }
              />
            ),
          )}
        </div>
      )}
      <InputTypeSwitchInput<InputType, ValueType>
        disable={disable}
        color={color}
        type={inputTypeSelected as StudyObjectGroupCompareEnum}
        inputValue={inputValue}
        handleSelectValue={handleSelectValue}
        booksSelected={booksSelected}
        handleUpdateBooksSelected={handleUpdateBooksSelected}
        handleOpenBookDetails={handleOpenBookDetails}
        eanSelected={eanSelected}
      />
    </>
  )
}

export default InputTypeChoice
