import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { BookSearchInterface } from '../../../interface/book-search.interface'

export const getBookSearchService = (
  q: string,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<BookSearchInterface[]>> =>
  apiInstance.get(ApiPathConfig.BOOK_SEARCH, { cancelToken, params: { q } })
