import { arc, pie, select } from 'd3'
import { BookInterface } from '../../../../../core/interface/book.interface'
import { clilReferencesConfig } from '../../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../../core/config/clil-refs-mapping.config'

const getBookCoverByClil = (value: any) => {
  const clil1Level =
    (value?.clil1 && clilReferencesConfig[value.clil1]) ||
    clilReferencesConfig['0000']
  const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
  return cover.cover
}

const appendCenterBookInputsUtil = (
  svgContainer: any,
  booksInput: BookInterface[],
  {
    width,
    height,
  }: {
    width: number
    height: number
  },
) => {
  const dataBookInput = Array(booksInput.length).fill(50)
  const arcGenerator = arc().innerRadius(0).outerRadius(50)

  const getWidthImage = (index: number): number => {
    switch (dataBookInput.length) {
      case 1:
        return 100
      case 2:
        return index === 0 ? 50 : 50
      case 3:
        return index === 0 ? 200 : index === 1 ? -50 : -50
      default:
        return 0
    }
  }

  const getHeightImage = (index: number): number => {
    switch (dataBookInput.length) {
      case 1:
        return -50
      case 2:
        return index === 0 ? 50 : -100
      case 3:
        return index === 0 ? -50 : index === 1 ? -50 : -50
      default:
        return 0
    }
  }

  const pieCenter = pie()
  if (dataBookInput.length === 3) {
    pieCenter.startAngle(-Math.PI / 6).endAngle(2 * Math.PI - Math.PI / 6)
  }
  pieCenter.value((d: any) => d)

  const arcs = pieCenter(dataBookInput)
  const pathsA = svgContainer
    .selectAll('path')
    .data(arcs)
    .enter()
    .append('path')
    .attr('transform', `translate(${width / 2}, ${height / 2})`)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .attr('d', arcGenerator)

    .style('fill', 'transparent')
    .style('stroke', 'white')
    .style('stroke-width', '2px')

  // eslint-disable-next-line func-names
  pathsA.each(function (d: any, i: number) {
    const imgUrl =
      booksInput[i]?.imgs || getBookCoverByClil(booksInput[i]?.clil1)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const p = select(this)
    const defs = svgContainer.append('defs')
    defs
      .append('pattern')
      .attr('id', `pattern-${i}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('image')
      .attr('width', 100)
      .attr('height', 200)
      .attr('xlink:href', imgUrl)

    p.style('fill', `url(#pattern-${i})`)
  })
}

export default appendCenterBookInputsUtil
