import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const postPasswordResetWithConfirmTokenService = ({
  token,
  password,
}: {
  token: string
  password: string
}) =>
  apiInstance.post(ApiPathConfig.FORGOT_PASSWORD_CONFIRM, {
    token,
    password,
  })
