import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { selectWebAppStateShowSnackbar } from '../../../store/getters/web-app.getters'

const fadeIn = keyframes`
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 1rem;
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    bottom: 1rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
`
const SnackBarStyle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  align-items: center;
  background-color: #595959;
  color: white;
  bottom: 1rem;
  animation: ${({ show }: { show: boolean }) => (show ? fadeIn : fadeOut)} 0.5s
    linear;
`

const GlSnackBarMessage = () => {
  const dispatch = useAppDispatch()
  const showSnackbar = useAppSelector(selectWebAppStateShowSnackbar)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      dispatch(setShowSnackBar(null))
    }, 5000)
  }

  useEffect(() => {
    if (showSnackbar) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [showSnackbar, timer])

  return (
    (showSnackbar && (
      <SnackBarStyle
        show={!!showSnackbar}
        className="flex flex-row fixed self-center rounded-lg p-2 shadow-md"
        style={{
          zIndex: 1000,
        }}
        dangerouslySetInnerHTML={{
          __html: showSnackbar,
        }}
      />
    )) ||
    null
  )
}

export default memo(GlSnackBarMessage)
