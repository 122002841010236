import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { AnalyticsDashboardStateInterface } from '../interfaces/analytics-dashboard.state.interface'
import {
  analyticsDashboardGetInternalReports,
  analyticsDashboardGetInternalReportsNext,
  reportDashboardGetRecommendedReports,
} from '../actions/analytics-dashboard.actions'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'

const initialState: AnalyticsDashboardStateInterface = {
  isLoadingRecommendedReports: false,
  recommendedReports: null,
  isLoadingInternalReports: false,
  isLoadingInternalReportsNext: false,
  internalReports: null,
  cancelTokenInternalReportsNext: null,
}

const reportsSlice = createSlice({
  name: 'reportsState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        reportDashboardGetRecommendedReports.pending,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingRecommendedReports: true,
          recommendedReports: null,
        }),
      )
      .addCase(
        reportDashboardGetRecommendedReports.fulfilled,
        (
          state: AnalyticsDashboardStateInterface,
          action: PayloadAction<PaginationResponseInterface<ReportLightResponseInterface> | null>,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingRecommendedReports: false,
          recommendedReports: action.payload,
        }),
      )
      .addCase(
        reportDashboardGetRecommendedReports.rejected,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingRecommendedReports: false,
          recommendedReports: null,
        }),
      )
      .addCase(
        analyticsDashboardGetInternalReports.pending,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingInternalReports: true,
        }),
      )
      .addCase(
        analyticsDashboardGetInternalReports.fulfilled,
        (
          state: AnalyticsDashboardStateInterface,
          action: PayloadAction<PaginationResponseInterface<ReportLightResponseInterface> | null>,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          internalReports: action.payload,
          isLoadingInternalReports: false,
        }),
      )
      .addCase(
        analyticsDashboardGetInternalReports.rejected,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingInternalReports: false,
        }),
      )
      .addCase(
        analyticsDashboardGetInternalReportsNext.pending,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => {
          const { cancelTokenInternalReportsNext } = state
          if (cancelTokenInternalReportsNext) {
            cancelTokenInternalReportsNext.cancel()
          }
          return {
            ...state,
            isLoadingInternalReportsNext: true,
            cancelTokenInternalReportsNext: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticsDashboardGetInternalReportsNext.fulfilled,
        (
          state: AnalyticsDashboardStateInterface,
          action: PayloadAction<PaginationResponseInterface<ReportLightResponseInterface> | null>,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingInternalReportsNext: false,
          internalReports: {
            count: action.payload?.count || 0,
            next: action.payload?.next || '',
            previous: action.payload?.previous || '',
            results: [
              ...(state.internalReports?.results || []),
              ...(action.payload?.results || []),
            ],
          },
        }),
      )
      .addCase(
        analyticsDashboardGetInternalReportsNext.rejected,
        (
          state: AnalyticsDashboardStateInterface,
        ): AnalyticsDashboardStateInterface => ({
          ...state,
          isLoadingInternalReportsNext: false,
        }),
      )
  },
})

// export const {} = reportsSlice.actions

export const analyticsDashboardReducer = reportsSlice.reducer
