import styled from '@emotion/styled'
import React from 'react'
import { EditSectionEnum } from '../../../../../../../core/enums/analytic-edit-section.enum'
import { EditOptionInterface } from '../../../../../../../core/interface/analytics/analytic-edit-option.interface'
import GlSlideToggleWithText from '../../../../../../share/GlSlideToggleWithText'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import { EditOptionTypeEnum } from '../../../../../../../core/enums/analytic-edit-option-type.enum'
import { SelectOptionInterface } from '../../../../../../../core/interface/select-option.interface'
import GlSelectWithText from '../../../../../../share/GlSelectWithText'

const EditMenuTitleStyle = styled.span`
  color: #929292;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`

const EditMenuSection = ({
  sectionTitle,
  section,
  options,
  handleOnToggle,
  handleOnSizeDropdown,
  titleCustomStyle,
  textCustomStyle,
}: {
  sectionTitle: string
  section: EditSectionEnum
  options?: EditOptionInterface<any>[]
  handleOnToggle?: (optionId: string, value: boolean) => void
  handleOnSizeDropdown?: (
    optionId: string,
    value: SelectOptionInterface<any>,
  ) => void
  titleCustomStyle?: React.CSSProperties
  textCustomStyle?: React.CSSProperties
}) => {
  const filteredOptions =
    (options && options.filter((option) => option.section === section)) || []
  return (
    (filteredOptions.length > 0 && (
      <div className="flex flex-col items-start self-stretch gap-2">
        <EditMenuTitleStyle style={titleCustomStyle}>
          {sectionTitle}
        </EditMenuTitleStyle>
        {filteredOptions.map((option) => {
          switch (option.type) {
            case EditOptionTypeEnum.TOGGLE:
              if (!handleOnToggle) return null
              return (
                <GlSlideToggleWithText
                  key={option.id}
                  id={option.id}
                  label={option.label}
                  value={option.value}
                  handleOnToggle={handleOnToggle}
                  textCustomStyle={textCustomStyle}
                />
              )
            case EditOptionTypeEnum.DROPDOWN:
              if (
                option.dropdownEntries &&
                option.dropdownEntrySelected &&
                handleOnSizeDropdown
              ) {
                return (
                  <GlSelectWithText<AnalyticOutputLayoutSizeEnum>
                    key={option.id}
                    dropdownEntries={
                      option.dropdownEntries as SelectOptionInterface<AnalyticOutputLayoutSizeEnum>[]
                    }
                    dropdownEntrySelected={
                      option.dropdownEntrySelected as SelectOptionInterface<AnalyticOutputLayoutSizeEnum>
                    }
                    handleChangeSelect={(value) =>
                      handleOnSizeDropdown(option.id, value)
                    }
                    customStyle={{ width: 'auto', minWidth: 150 }}
                    label={option.label}
                    textCustomStyle={textCustomStyle}
                  />
                )
              }
              return null
            default:
              return null
          }
        })}
      </div>
    )) ||
    null
  )
}

export default EditMenuSection
