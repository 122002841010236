import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import TreemapRechart from './chartComponents/TreemapRechart'

import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'

import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'

const AnalyticsOutputTreemapRechart = ({
  valuesConfigurations,
  dataset,
  onClick = () => {},
  title,
  subTitle,
  display,
  level = 1,
  typeDataset,
  datasetModule,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  isScreenSmall,
  isOrganizeMenuOpen,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  dataset: Array<DatasetInterface>
  onClick?: (clil: number, lvl: number) => void
  title: string
  subTitle: string
  display: AnalyticOutputLayoutSizeEnum
  level?: number
  typeDataset: DatasetTypeEnum
  datasetModule: DatasetModuleInterface
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
}) => {
  const { t } = useTranslation()
  const [mappedDataset, setMappedDataset] = useState<
    Array<
      {
        label: number | string
      } & DatasetInterface
    >
  >([])

  const primaryValueConfiguration = valuesConfigurations.filter(
    (p) => p.is_primary_key,
  )[0]
  useEffect(() => {
    if (!dataset || dataset.length === 0) return
    const tmpDataset = dataset.map((item) => ({
      ...item,
      label: item[primaryValueConfiguration.source],
    }))
    setMappedDataset(
      tmpDataset as Array<
        {
          label: number | string
        } & DatasetInterface
      >,
    )
  }, [dataset])

  const otherValuesConfigurations = valuesConfigurations.filter(
    (c, index) => !c.is_primary_key,
  )
  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={[t('InfoBulles.clilTreemap.1'), t('InfoBulles.clilTreemap.2')]}
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.TREEMAP}
      shrink={(isPreview && isScreenSmall) || isOrganizeMenuOpen}
    >
      <TreemapRechart
        dataKey={otherValuesConfigurations[0].source}
        dataset={mappedDataset}
        onClick={onClick}
        level={level}
        typeDataset={typeDataset}
        isPreview={isPreview}
      />
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputTreemapRechart
