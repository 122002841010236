import React from 'react'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import { StudyObjectGroupCompareEnum } from '../../configs/StudiesObjectsCompareGroupTypesConfig'
import { clilChildrenMapping } from '../../../../../../core/utils/get-clil-level.utils'
import EditionsTableRow from '../../editions-table/EditionsTableRow'
import StudyObjectGroupDropdownButton from './StudyObjectGroupDropdownButton'
import StudyObjectGroupDropdownItemClil from './StudyObjectGroupDropdownItemClil'

const StudyObjectGroup = ({
  data,
  color,
  studyObjectType,
  isDropdownOpen,
  handleClickDropdown,
  eanSelected,
  handleOpenBookDetails,
  disableDropdown,
}: {
  data: BookInterface | AuthorInterface | { clil: string }
  color: string
  studyObjectType: StudyObjectGroupCompareEnum
  isDropdownOpen: boolean
  handleClickDropdown: () => void
  eanSelected: number | null
  handleOpenBookDetails: (eanId: number | null, showBookDetail: boolean) => void
  disableDropdown?: boolean
}) => {
  return (
    <>
      <StudyObjectGroupDropdownButton
        color={color}
        handleClickDropdown={handleClickDropdown}
        studyObjectType={studyObjectType}
        data={data}
        isDropdownOpen={isDropdownOpen}
        disableDropdown={disableDropdown}
      />
      {isDropdownOpen && (
        <div className="px-1">
          {studyObjectType === StudyObjectGroupCompareEnum.CLIL ? (
            clilChildrenMapping[(data as { clil: string }).clil]?.map(
              (clil) => (
                <StudyObjectGroupDropdownItemClil clil={clil} key={clil} />
              ),
            )
          ) : (
            <div className="flex flex-col gap-2">
              {(data as BookInterface | AuthorInterface).children?.map(
                (book: BookInterface) => (
                  <EditionsTableRow
                    key={book.id_ean}
                    disable={false}
                    book={book}
                    color={color}
                    disableCheckbox
                    handleOnClick={() =>
                      handleOpenBookDetails(
                        eanSelected === book.id_ean ? null : book.id_ean,
                        true,
                      )
                    }
                  />
                ),
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default StudyObjectGroup
