import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import { AnalyticsStatusEnum } from '../../core/enums/analytics-status.enum'
import { ReactComponent as WMIcon } from '../../assets/icons/app/work-mirror.icon.svg'
import { ReactComponent as PrivateWMIcon } from '../../assets/icons/private-work-mirror.icon.svg'
import { ReactComponent as UniversIcon } from '../../assets/icons/app/univers.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../assets/icons/readership-profil-card.icon.svg'
import { ReactComponent as PrivateReadershipProfilIcon } from '../../assets/icons/private-readership-profil.icon.svg'
import { ReactComponent as AudienceIcon } from '../../assets/icons/audience.icon.svg'
import { ReactComponent as MoreIcon } from '../../assets/icons/app/more.icon.svg'

const AnalyticIcon = ({
  type,
  status,
  size = 32,
  color = 'white',
  hideText = false,
}: {
  type: AnalyticsTypeEnum
  size?: number
  color?: string
  status?: AnalyticsStatusEnum
  hideText?: boolean
}) => {
  const { t } = useTranslation()

  const iconStyle = {
    width: size,
    height: size,
    color,
    zIndex: 1,
  }

  switch (type) {
    case AnalyticsTypeEnum.WORK_MIRROR:
      return <WMIcon className="text-white" style={iconStyle} />
    case AnalyticsTypeEnum.PRIVATE_WORK_MIRROR:
      return <PrivateWMIcon className="text-white" style={iconStyle} />
    case AnalyticsTypeEnum.UNIVERS:
      return <UniversIcon className="text-white" style={iconStyle} />
    case AnalyticsTypeEnum.AUDIENCE:
      return <AudienceIcon className="text-white" style={iconStyle} />
    case AnalyticsTypeEnum.READERSHIP_PROFILE:
      if (status === AnalyticsStatusEnum.GENERATING) {
        return (
          <div className="text-white flex flex-col items-center justify-center">
            <MoreIcon style={iconStyle} />
            {!hideText && <span>{t('Generating')}</span>}
          </div>
        )
      }
      return <ReadershipProfilIcon className="text-white" style={iconStyle} />
    case AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE:
      if (status === AnalyticsStatusEnum.GENERATING) {
        return (
          <div className="text-white flex flex-col items-center justify-center">
            <MoreIcon style={iconStyle} />
            <span>{t('Generating')}</span>
          </div>
        )
      }
      return (
        <PrivateReadershipProfilIcon className="text-white" style={iconStyle} />
      )
    default:
  }
  return null
}

export default memo(AnalyticIcon)
