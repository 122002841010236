export enum ItemFilterActionEnum {
  Progress,
  Author,
  ReleaseYear,
  Editor,
  Segment1,
  Segment2,
  Format,
}

export enum ItemSortActionEnum {
  Ranking,
  Progress,
  Title,
  Author,
  ReleaseYear,
  Editor,
  Segment1,
  Segment2,
  Format,
}
