import React, { memo } from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as LeftChevronIcon } from '../../../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as EditionIcon } from '../../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as FormatIcon } from '../../../../assets/icons/app/format.icon.svg'
import { ReactComponent as StarPopularityIcon } from '../../../../assets/icons/app/star-popularity.icon.svg'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'
import GlSpacer from '../../../share/GlSpacer'
import GlLoader from '../../../share/GlLoader'
import { BookInterface } from '../../../../core/interface/book.interface'
import GlCarrousel from '../../../share/carrousel/GlCarrousel'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilV2 from '../../../share/badge/GlBadgeClilV2'
import GlReadMore from '../../../share/GlReadMore'
import { BookDetailResponseInterface } from '../../../../core/interface/rank/book-detail.interface'
import RankCommentHorizontalList from '../../../trends/book-details/RankCommentHorizontalList'
import DynamicsResultsBookDetailsProvider from '../../../dynamics-results/body/book-details/DynamicsResultsBookDetailsProvider'
import GlHDivider from '../../../share/GlHDivider'
import { getPopularityIndexByValue } from '../../../../core/enums/popularity.enum'

const TitleStyle = styled.div`
  color: white;
  /* Book Title/T1 */
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 370px;
`

const AuthorStyle = styled.div`
  color: #e9e9e9;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const BadgeStyle = styled.span`
  color: #fff;
  /* Caption/C1 - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  background: #e9e9e933;
  padding: 4px;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  /* Caption/C1 - Medium - Uppercase */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 4px;
  background: #e9e9e9;
  border-radius: 4px;
`

const HeaderStyle = styled.div`
  color: #3b3b3b;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const CardStyle = styled.div`
  min-width: 256px;
  max-width: 256px;
  min-height: 96px;
  max-height: 96px;
  background: white;
  border-radius: 4px;
`
const WorkMirrorBookDetailsCard = ({
  isLoadingGetBookDetails,
  isLoadingGetEditions,
  isLoadingGetB2BBookDetails,
  bookDetailsSelectedB2BInfo,
  openListCard,
  bookDetails,
  handleCloseBookDetails,
  bookDetailsSelectedEditions,
  bookDetailsBookReco,
  isLoadingBookDetailsGetBookReco,
}: {
  isLoadingGetBookDetails: boolean
  isLoadingGetEditions: boolean
  isLoadingGetB2BBookDetails: boolean
  bookDetailsSelectedB2BInfo: BookDetailResponseInterface | null
  openListCard: boolean
  bookDetails: WorkMirrorBookInterface | null
  handleCloseBookDetails: (returnToList?: boolean) => void
  bookDetailsSelectedEditions: BookInterface[]
  bookDetailsBookReco: BookInterface[]
  isLoadingBookDetailsGetBookReco: boolean
}) => {
  const { t } = useTranslation()
  const onClickCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseBookDetails(true)
  }

  const onClickReturnToListButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleCloseBookDetails(false)
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const percentage = (value: number) =>
    (value && `${Math.round((1 - value) * 1000) / 10}%`) || ''

  const getProvider = () => {
    if (bookDetailsSelectedB2BInfo && bookDetailsSelectedB2BInfo.data) {
      const { provider } = bookDetailsSelectedB2BInfo.data
      return provider
    }
    return null
  }

  return (
    <div
      className="flex flex-col basis-1/3 gap-4 h-full"
      style={{
        maxWidth: 506,
      }}
    >
      <div className="flex flex-row items-center justify-between">
        {(openListCard && (
          <button
            type="button"
            className="rounded flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9]"
            onClick={onClickReturnToListButton}
          >
            <LeftChevronIcon className="w-4 h-4" />
            <span>{t('returnToList')}</span>
          </button>
        )) || <GlSpacer />}
        <button
          type="button"
          className="flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9] rounded"
          onClick={onClickCloseButton}
        >
          <CrossIcon className="w-4 h-4" />
          <span>{t('commons.buttons.close')}</span>
        </button>
      </div>
      <div className="relative flex-auto flex flex-col rounded-xl bg-white">
        {(isLoadingGetBookDetails && (
          <div className="absolute w-full h-full flex items-center justify-center">
            <GlLoader />
          </div>
        )) || (
          <div className="flex-none flex flex-col rounded-t-xl bg-[#444444] shadow-2xl">
            {(isLoadingGetEditions && (
              <div
                className="flex flex-col items-center justify-center"
                style={{
                  height: 188,
                }}
              >
                <GlLoader />
              </div>
            )) || (
              <GlCarrousel
                initialCurrentIndex={
                  bookDetailsSelectedEditions.findIndex(
                    (edition: BookInterface) =>
                      edition.id_ean === bookDetails?.id_ean,
                  ) || 0
                }
              >
                {bookDetailsSelectedEditions
                  .slice(0, 10)
                  .map((edition: BookInterface) => (
                    <div
                      key={`book-details-item-${edition.id_ean}`}
                      className="flex flex-col"
                    >
                      <div className="flex flex-row p-4  rounded-t-[8px] gap-2">
                        <img
                          className="rounded"
                          src={
                            edition?.imgs || getBookCoverByClil(edition.clil1)
                          }
                          alt="cover book"
                          style={{
                            minWidth: 88,
                            maxWidth: 88,
                            minHeight: 120,
                            maxHeight: 120,
                            objectFit: 'cover',
                          }}
                        />
                        <div
                          className="flex flex-col gap-1"
                          style={{
                            minHeight: 120,
                            maxHeight: 120,
                          }}
                        >
                          <TitleStyle className="flex flex-row  basis-1/4">
                            {edition.title}
                          </TitleStyle>
                          <AuthorStyle className="flex flex-row basis-1/4">
                            {edition.authors[0]}
                          </AuthorStyle>
                          <div className="flex flex-row basis-1/4">
                            <GlBadgeClilV2
                              id={String(edition.clil1)}
                              fontColor="white"
                            />
                          </div>
                          <div className="flex flex-row gap-2 basis-1/4">
                            {(edition.publishing_date && (
                              <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                                {dayjs(edition.publishing_date).format(
                                  'DD/MM/YYYY',
                                )}
                              </BadgeStyle>
                            )) ||
                              null}
                            <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                              <EditionIcon className="w-4 h-4" />
                              {edition.edition}
                            </BadgeStyle>
                            <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                              <FormatIcon className="w-4 h-4" />
                              {edition.format}
                            </BadgeStyle>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </GlCarrousel>
            )}
          </div>
        )}
        <PerfectScrollbar
          className="flex-auto flex flex-col p-4 bg-white rounded-b-xl"
          style={{
            height: 0,
          }}
        >
          <div className="flex flex-col gap-4">
            {(!!bookDetails?.distance && !!bookDetails?.popularity && (
              <div className="flex flex-row gap-4">
                <LabelStyle className="flex flex-auto items-center justify-between">
                  <div>{t('proximity')}</div>
                  <div>{percentage(bookDetails?.distance || 0)}</div>
                </LabelStyle>
                <LabelStyle className="flex flex-auto items-center justify-between">
                  <div>{t('popularity')}</div>
                  <div className="flex flex-row items-center justify-center gap-2">
                    {Array(5)
                      .fill(null)
                      .map((_: null, index: number) => (
                        <StarPopularityIcon
                          key={`star-popularity-${index + 1}`}
                          style={{
                            color:
                              getPopularityIndexByValue(
                                bookDetails?.popularity,
                              ) >= index
                                ? '#252525'
                                : '#bebebe',
                          }}
                        />
                      ))}
                  </div>
                </LabelStyle>
              </div>
            )) ||
              null}
            <div className="flex flex-col gap-4">
              <HeaderStyle>Resumé</HeaderStyle>
              <div className="text-xs">
                {(bookDetailsSelectedB2BInfo &&
                  bookDetailsSelectedB2BInfo.data.description && (
                    <GlReadMore
                      content={bookDetailsSelectedB2BInfo.data.description}
                      nbShowsCharacters={120}
                    />
                  )) || (
                  <GlReadMore
                    content={t('noDescriptionAvailable')}
                    nbShowsCharacters={120}
                  />
                )}
              </div>
              <GlHDivider />
            </div>
            {(isLoadingGetB2BBookDetails && (
              <div>
                <GlLoader />
              </div>
            )) ||
              (bookDetailsSelectedB2BInfo &&
                (bookDetailsSelectedB2BInfo?.comments || []).length && (
                  <div
                    className="flex flex-col gap-4"
                    style={{
                      maxWidth: 474,
                    }}
                  >
                    <HeaderStyle>{t('latestReviewOfThisWork')}</HeaderStyle>
                    <div className="text-xs">
                      <RankCommentHorizontalList
                        comments={bookDetailsSelectedB2BInfo?.comments || []}
                      />
                    </div>
                    <GlHDivider />
                  </div>
                )) ||
              null}
            {(isLoadingBookDetailsGetBookReco && (
              <div>
                <GlLoader />
              </div>
            )) ||
              (bookDetailsBookReco.length && (
                <div
                  className="flex flex-col gap-4 py-2"
                  style={{
                    maxWidth: 474,
                  }}
                >
                  <HeaderStyle>
                    {t('webApp.ranks.bookDetails.worksMirrors')}
                  </HeaderStyle>
                  <PerfectScrollbar className="relative flex flex-row gap-2 py-2">
                    {bookDetailsBookReco.map((book: BookInterface) => (
                      <CardStyle
                        key={`similar-title-${book.id_ean}`}
                        className="flex flex-row  m-1 p-2 gap-2"
                        style={{
                          boxShadow:
                            ' 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.08)',
                        }}
                      >
                        <img
                          src={book.imgs || getBookCoverByClil(book.id_ean)}
                          alt=""
                          style={{
                            width: 60,
                            height: 80,
                            borderRadius: 2,
                          }}
                        />
                        <div className="flex flex-col gap-1 items-start justify-start">
                          <TitleStyle
                            style={{
                              color: 'black',
                              maxWidth: 174,
                            }}
                          >
                            {book.title}
                          </TitleStyle>
                          <AuthorStyle
                            style={{
                              color: 'grey',
                            }}
                          >
                            {book.authors[0]}
                          </AuthorStyle>
                        </div>
                      </CardStyle>
                    ))}
                  </PerfectScrollbar>
                </div>
              )) ||
              null}
            {(bookDetailsSelectedB2BInfo?.data?.provider && (
              <div className="flex flex-row items-center justify-center">
                <DynamicsResultsBookDetailsProvider provider={getProvider()} />
              </div>
            )) ||
              null}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default memo(WorkMirrorBookDetailsCard)
