import { RootState } from '../store'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../core/enums/analytic-input-type.enum'
import { BookInterface } from '../../core/interface/book.interface'
import { WorkMirrorBookInterface } from '../../core/interface/report/work-mirror-book.interface'
import { AnalyticOutputInterface } from '../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../../core/enums/analytic-output-type.enum'
import { BookDetailResponseInterface } from '../../core/interface/rank/book-detail.interface'

export const selectShareAnalyticDetailsStateIsLoadingGetShareAnalyticDetails = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.isLoadingGetShareAnalyticDetails
}

export const selectShareAnalyticDetailsStateAnalyticDetails = (
  state: RootState,
): AnalyticDetailsInterface => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.analyticDetails
}

export const selectShareAnalyticDetailsStateAnalyticDetailsOutput = (
  state: RootState,
): AnalyticOutputInterface[] => {
  const { analyticDetails } = state.shareAnalyticDetailsState
  const input = (analyticDetails?.inputs || []).find(
    (input: AnalyticInputInterface) =>
      input.type === AnalyticInputTypeEnum.READERSHIP_PROFILE,
  )
  if (input) {
    return input.outputs
  }

  return []
}

export const selectShareAnalyticDetailsStateGetShareAnalyticDetailsSuccess = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.getShareAnalyticDetailsSuccess
}

export const selectShareAnalyticDetailsStateGetShareAnalyticDetailsError = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.getShareAnalyticDetailsError
}

export const selectShareAnalyticDetailsStateAnalyticDetailsBooksInput = (
  state: RootState,
): BookInterface[] => {
  const { shareAnalyticDetailsState } = state
  const { analyticDetails } = shareAnalyticDetailsState

  return (
    analyticDetails?.inputs.find(
      (input: AnalyticInputInterface) =>
        input.type === AnalyticInputTypeEnum.BOOKS,
    )?.books || []
  )
}

export const selectShareAnalyticDetailsStateAnalyticDetailsWorksMirrorsOutput =
  (state: RootState): WorkMirrorBookInterface[] => {
    const { shareAnalyticDetailsState } = state
    const { analyticDetails } = shareAnalyticDetailsState

    if (analyticDetails) {
      const input = analyticDetails?.inputs.find(
        (input: AnalyticInputInterface) =>
          input.type === AnalyticInputTypeEnum.BOOKS,
      )
      if (input) {
        return (
          input.outputs.find(
            (output: AnalyticOutputInterface) =>
              output.type === AnalyticOutputTypeEnum.WORK_MIRROR,
          )?.work_mirror_books || []
        )
      }
    }
    return []
  }

export const selectShareAnalyticDetailsStateBookDetailsSelected = (
  state: RootState,
): WorkMirrorBookInterface | null => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.bookDetailsSelected
}

export const selectShareAnalyticDetailsStateIsLoadingBookDetailsSelected = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.isLoadingBookDetailsSelected
}

export const selectShareAnalyticDetailsStateIsLoadingGetB2BBookDetails = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.isLoadingGetB2BBookDetails
}

export const selectShareAnalyticDetailsStateBookDetailsSelectedB2BInfo = (
  state: RootState,
): BookDetailResponseInterface | null => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.bookDetailsSelectedB2BInfo
}

export const selectShareAnalyticDetailsStateGetB2BBookDetailsSuccess = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.getB2BBookDetailsSuccess
}
export const selectShareAnalyticDetailsStateGetB2BBookDetailsError = (
  state: RootState,
): boolean => {
  const { shareAnalyticDetailsState } = state
  return shareAnalyticDetailsState.getB2BBookDetailsError
}
