import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as StarPopularityIcon } from '../../assets/icons/app/star-popularity.icon.svg'

const LabelStyle = styled.div`
  background: #e9e9e9;
  border-radius: 4px;
  width: 119px;
  padding: 4px 0;
`

const LabelTitleStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`

const LabelPercentageStyle = styled.div`
  color: #252525;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
`

const GlPopularityPanel = ({
  percentage,
  labelPercentage = 'score',
  popularity,
}: {
  percentage: string
  labelPercentage?: string
  popularity?: number
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row gap-2">
      <LabelStyle className="flex-auto flex flex-col items-center justify-center">
        <LabelTitleStyle>{t(labelPercentage)}</LabelTitleStyle>
        <LabelPercentageStyle>{percentage}</LabelPercentageStyle>
      </LabelStyle>
      {popularity !== undefined && (
        <LabelStyle className="flex-auto flex flex-col items-center justify-center">
          <LabelTitleStyle>{t('popularity')}</LabelTitleStyle>
          <div className="flex flex-row items-center justify-center gap-2">
            {Array(5)
              .fill(null)
              .map((_: null, index: number) => (
                <StarPopularityIcon
                  key={`star${index.toString()}`}
                  style={{
                    color: popularity >= index ? '#252525' : '#bebebe',
                  }}
                />
              ))}
          </div>
        </LabelStyle>
      )}
    </div>
  )
}

export default GlPopularityPanel
