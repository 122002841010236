import React, { memo } from 'react'
import { TagStyle } from '../../analytics-details-v2/view/readership-profile/inputs/recap/RecapInputStyles'

interface Props {
  color: string
  label: string
}

const NewAudienceTag = ({ color, label }: Props) => {
  return (
    <TagStyle color={color} className="py-1 px-2 rounded">
      <span>{label}</span>
    </TagStyle>
  )
}

export default memo(NewAudienceTag)
