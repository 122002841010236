import { nanoid } from 'nanoid'
import { SetColorEnum } from '../../../../../../../../core/enums/set-color.enum'
import { DatasetTitleSectionStyle } from '../../../../ReadershipProfileStyles'
import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetModuleInterface } from '../../../../../../../../core/interface/analytics/dataset-module.interface'
import OutputChartTypeSwitch from '../../../OutputChartTypeSwitch'
import { DatasetTypeEnum } from '../../../../../../../../core/enums/dataset-type.enum'
import { AnalyticOutputTypeEnum } from '../../../../../../../../core/enums/analytic-output-type.enum'
import { AnalyticModuleReplace } from '../../../../../../../../core/interface/analytics/analytic-module-replace.interface'

type Props = {
  title: string
  dataType: DatasetTypeEnum
  datasets: Array<Array<DatasetInterface>>
  onClickClil: (clil: number, lvl: number) => void
  datasetModules: Array<DatasetModuleInterface>
  setColor: SetColorEnum
  level: number
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleChangeDatasetOutput?: (moduleToReplace: AnalyticModuleReplace) => void
  parentId?: number | null
  isOrganizeMenuOpen: boolean
  outputIdToScroll?: string
}

const LibraryStructureRecharts = ({
  title,
  dataType,
  datasets,
  onClickClil,
  datasetModules,
  setColor,
  level,
  handleAnalyticsDeleteOutput,
  handleChangeDatasetOutput,
  parentId,
  isOrganizeMenuOpen,
  outputIdToScroll,
}: Props) => {
  return (
    <>
      <DatasetTitleSectionStyle
        id={`treemap-${level}`}
        className="grid-item w-full pl-4 pt-4"
      >
        {title}
      </DatasetTitleSectionStyle>
      {datasetModules.map((datasetModule, index) => (
        <OutputChartTypeSwitch
          key={`${datasetModule.id}`}
          display={datasetModule.layout_size}
          dataType={dataType}
          dataset={datasets[index]}
          datasetModule={datasetModule}
          level={level}
          onClickClil={onClickClil}
          setColor={setColor}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleChangeDatasetOutput={handleChangeDatasetOutput}
          parentId={parentId}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          outputIdToScroll={outputIdToScroll}
        />
      ))}
    </>
  )
}

export default LibraryStructureRecharts
