import { Children, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import GlCarrouselNavigation from './GlCarrouselNavigation'

const CarouselWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`

const Slider = styled.div`
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`

const GlCarrousel = ({
  children,
  initialCurrentIndex = 0,
}: {
  children: any
  initialCurrentIndex?: number
}) => {
  const wrapperRef = useRef(null)
  const [current, setCurrent] = useState(initialCurrentIndex)

  return (
    <CarouselWrapper ref={wrapperRef} className="flex flex-col relative">
      <div className="flex flex-row relative">
        <Slide>
          {Children.map(children, (child, index) => (
            <Slider
              key={nanoid(5)}
              className="flex flex-col"
              style={{
                width: '100%',
                display: current === index ? 'flex' : 'none',
              }}
            >
              {child}
            </Slider>
          ))}
        </Slide>
      </div>
      <div className="flex flex-col p-4">
        {(Children.count(children) > 1 && (
          <GlCarrouselNavigation
            length={Children.count(children)}
            current={current}
            handleCurrentIndex={setCurrent}
          />
        )) ||
          null}
      </div>
    </CarouselWrapper>
  )
}

export default GlCarrousel
