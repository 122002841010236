import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { ReactElement } from 'react'
import { useClickOutside } from '../../core/hooks/useClickOutside.hook'

const SidebarContainer = styled.div`
  -webkit-transition: left 300ms ease-in-out, right 300ms ease-in-out,
    width 300ms ease-in-out;
  -moz-transition: left 300ms ease-in-out, right 300ms ease-in-out,
    width 300ms ease-in-out;
  -o-transition: left 300ms ease-in-out, right 300ms ease-in-out,
    width 300ms ease-in-out;
  transition: left 300ms ease-in-out, right 300ms ease-in-out,
    width 300ms ease-in-out;
`

interface Props {
  children: ReactElement
  position?: 'right' | 'left'
  hasOverlay?: boolean
  open: boolean
  width?: number
  backgroundColor?: string
  boxShadow?: string
  handleClickOutside?: () => void
  scrollable?: boolean
  height?: string
}

const GlSideBar = ({
  children,
  position = 'right',
  hasOverlay = true,
  open,
  width = 360,
  backgroundColor = 'bg-[#F7F7F7]',
  boxShadow = 'shadow-[0px_2px_10px_rgba(0,0,0,0.16)]',
  handleClickOutside = () => {},
  scrollable = true,
  height = 'h-full',
}: Props) => {
  const ref = useClickOutside(handleClickOutside)
  return (
    <SidebarContainer
      ref={ref}
      className={`${height} ${backgroundColor} ${boxShadow}  ${
        (hasOverlay && 'fixed') || 'relative'
      } `}
      style={{
        zIndex: hasOverlay ? 1003 : 0,
        width: !hasOverlay ? (open ? width : 0) : width,
        [position]:
          position === 'right' && hasOverlay ? (open ? 0 : -1 * width) : 0,
      }}
    >
      {scrollable ? <PerfectScrollbar>{children}</PerfectScrollbar> : children}
    </SidebarContainer>
  )
}

export default GlSideBar
