import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const putReadershipProfilePreviewService = (
  reportId: string,
  cardId: string,
  isVisible: boolean,
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.ANALYTICS_CARD_PREVIEW(reportId, cardId),
    { is_visible: isVisible },
    {
      cancelToken,
    },
  )
