import React, { memo } from 'react'
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg'

interface Props {
  label: string
}

const GlInfo = ({ label }: Props) => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <InfoIcon className="w-3 h-3 text-[#929292]" />
      <span className="text-[10px] text-[#929292]">{label}</span>
    </div>
  )
}

export default memo(GlInfo)
