import { ReactNode } from 'react'

const ScatterXAxisLabel: (props: any) => ReactNode = ({
  value,
  viewBox,
}: {
  value: string
  viewBox: {
    x: number
    y: number
    width: number
  }
}) => {
  const { x, y, width } = viewBox
  return (
    <g>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          <feFlood floodColor="white" />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
      </defs>
      <text
        filter="url(#solid)"
        x={Number(width) + Number(x)}
        y={Number(y) + 5}
        textAnchor="end"
      >
        {value}
      </text>
      <text
        x={Number(width) + Number(x)}
        y={Number(y) + 5}
        fill="#000"
        fontSize={14}
        textAnchor="end"
      >
        {value}
      </text>
    </g>
  )
}

export default ScatterXAxisLabel
