import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoIcon } from '../../assets/icons/bookmetrie-logo.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'

const ButtonStyle = styled.button`
  border-radius: var(--S, 4px);
  border: 1px solid var(--grayscale-white-10, rgba(255, 255, 255, 0.1));
  display: flex;
  height: 28px;
  padding: 8px;
  align-items: center;
  color: #e9e9e9;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  :hover {
    background-color: #f7f7f733;
  }
  :active {
    background-color: #f7f7f766;
  }
`

const HeaderStyle = styled.div`
  color: white;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`
const SharedHeader = ({
  isLoggedIn,
  hasAnalyticsAccess,
  analyticType,
  analyticName,
}: {
  isLoggedIn: boolean
  hasAnalyticsAccess: boolean
  analyticType?: AnalyticsTypeEnum
  analyticName: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToSignInViewOrAnalytic = (event: React.MouseEvent) => {
    event.preventDefault()
    if (isLoggedIn) {
      const path = [PathConfig.BOOKMETRIE]
      if (hasAnalyticsAccess) {
        path.push(PathConfig.ANALYTICS)
      }
      navigate(buildRelativePath(path))
    } else {
      navigate(buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN]))
    }
  }

  return (
    <header
      className="flex flex-row bg-[#252525] justify-between py-3 px-6 relative top-0 h-12 items-center"
      style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}
    >
      <LogoIcon />
      {((analyticType === AnalyticsTypeEnum.WORK_MIRROR ||
        analyticType === AnalyticsTypeEnum.READERSHIP_PROFILE) && (
        <HeaderStyle>
          <span className="text-[#929292]">{t('analytics')}</span>
          <span>&nbsp;/&nbsp;</span>
          <span className="text-[#929292]">
            {analyticType === AnalyticsTypeEnum.WORK_MIRROR &&
              t('webApp.ranks.bookDetails.worksMirrors')}
            {analyticType === AnalyticsTypeEnum.READERSHIP_PROFILE &&
              t('readership_profile')}
          </span>
          <span>&nbsp;/&nbsp;</span>
          <span>{analyticName}</span>
        </HeaderStyle>
      )) ||
        null}
      <ButtonStyle type="button" onClick={navigateToSignInViewOrAnalytic}>
        {(isLoggedIn && (
          <span>
            {(!hasAnalyticsAccess && t('commons.buttons.goToBookMetrie')) ||
              t('commons.buttons.goToAnalytics')}
          </span>
        )) || <span>{t('commons.buttons.signIn')}</span>}
      </ButtonStyle>
    </header>
  )
}

export default SharedHeader
