import { Dot } from 'recharts'

type Props = {
  fill: string
  radius: number
  height: number
  width: number
}
const RadarInnerRadius = ({ fill, radius, height, width }: Props) => {
  return <Dot cx={width / 2} cy={height / 2} r={radius - 1} fill={fill} />
}

export default RadarInnerRadius
