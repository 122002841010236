import { createAsyncThunk } from '@reduxjs/toolkit'
import { postForgotPasswordService } from '../../core/api/services/bookmetrie/authentication/post-forgot-password.service'

export const forgotPasswordAction = createAsyncThunk(
  'authentication/forgot-password',
  async ({ email }: { email: string }): Promise<any> => {
    try {
      const { data } = await postForgotPasswordService({ email })
      return data
    } catch (error) {
      return false
    }
  },
)
