import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { DashboardStateInterface } from '../interfaces/dashboard.state.interface'
import {
  dashboardGetTrendsByCatalogIdAction,
  dashboardGetLastPrivateReportsAction,
  dashboardGetLastTopRequestsSaveAction,
} from '../actions/dashboard.actions'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { RanksResponseInterface } from '../../core/interface/rank/ranks-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'

const initialState: DashboardStateInterface = {
  // Catalogs
  catalogSelected: null,

  // Trends
  isLoadingTrends: true,
  trends: [],
  cancelTokenGetTrendsByCatalogId: null,

  // Last Private Reports
  isLoadingLastReports: true,
  lastReports: [],
  cancelTokenGetLastPrivateReports: null,
  // Last Top requests
  isLoadingTopRequestSaved: true,
  topsRequestSaved: null,
  cancelTokenGetLastSavedTopRequests: null,
}

export const dashboardSlice = createSlice({
  name: 'dashboardState',
  initialState,
  reducers: {
    reinitializeDashboardState: (
      state: DashboardStateInterface,
    ): DashboardStateInterface => ({
      ...state,
      ...initialState,
    }),
    dashboardStateSetCatalogSelected: (
      state: DashboardStateInterface,
      action: PayloadAction<CatalogInterface>,
    ): DashboardStateInterface => ({
      ...state,
      catalogSelected: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        dashboardGetLastPrivateReportsAction.pending,
        (state: DashboardStateInterface): DashboardStateInterface => {
          const { cancelTokenGetLastPrivateReports } = state
          cancelTokenGetLastPrivateReports &&
            cancelTokenGetLastPrivateReports.cancel()
          return {
            ...state,
            isLoadingLastReports: true,
            lastReports: [],
            cancelTokenGetLastPrivateReports: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dashboardGetLastPrivateReportsAction.fulfilled,
        (
          state: DashboardStateInterface,
          action: PayloadAction<ReportLightResponseInterface[]>,
        ): DashboardStateInterface => ({
          ...state,
          isLoadingLastReports: false,
          lastReports: action.payload,
        }),
      )
      .addCase(
        dashboardGetLastPrivateReportsAction.rejected,
        (state: DashboardStateInterface): DashboardStateInterface => ({
          ...state,
          isLoadingLastReports: false,
          lastReports: [],
        }),
      )
      .addCase(
        dashboardGetTrendsByCatalogIdAction.pending,
        (state: DashboardStateInterface): DashboardStateInterface => {
          const { cancelTokenGetTrendsByCatalogId } = state
          cancelTokenGetTrendsByCatalogId &&
            cancelTokenGetTrendsByCatalogId.cancel()

          return {
            ...state,
            isLoadingTrends: true,
            trends: [],
            cancelTokenGetTrendsByCatalogId: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dashboardGetTrendsByCatalogIdAction.fulfilled,
        (
          state: DashboardStateInterface,
          action: PayloadAction<RanksResponseInterface[]>,
        ): DashboardStateInterface => {
          return {
            ...state,
            isLoadingTrends: false,
            trends: action.payload,
          }
        },
      )
      .addCase(
        dashboardGetTrendsByCatalogIdAction.rejected,
        (state: DashboardStateInterface): DashboardStateInterface => {
          return {
            ...state,
            isLoadingTrends: false,
            trends: [],
          }
        },
      )
      .addCase(
        dashboardGetLastTopRequestsSaveAction.pending,
        (state: DashboardStateInterface): DashboardStateInterface => {
          const { cancelTokenGetLastSavedTopRequests } = state
          cancelTokenGetLastSavedTopRequests &&
            cancelTokenGetLastSavedTopRequests.cancel()

          return {
            ...state,
            isLoadingTopRequestSaved: true,
            topsRequestSaved: initialState.topsRequestSaved,
            cancelTokenGetLastSavedTopRequests: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dashboardGetLastTopRequestsSaveAction.fulfilled,
        (
          state: DashboardStateInterface,
          action: PayloadAction<
            PaginationResponseInterface<TopRequestSavedInterface>
          >,
        ): DashboardStateInterface => {
          return {
            ...state,
            isLoadingTopRequestSaved: false,
            topsRequestSaved: action.payload,
          }
        },
      )
      .addCase(
        dashboardGetLastTopRequestsSaveAction.rejected,
        (state: DashboardStateInterface): DashboardStateInterface => {
          return {
            ...state,
            isLoadingTopRequestSaved: false,
            topsRequestSaved: initialState.topsRequestSaved,
          }
        },
      )
  },
})

export const { reinitializeDashboardState, dashboardStateSetCatalogSelected } =
  dashboardSlice.actions
export const dashboardReducer = dashboardSlice.reducer
