import { useTranslation } from 'react-i18next'
import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
  StudyObjectType,
} from '../../configs/StudiesObjectsCompareGroupTypesConfig'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'

import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { TagEmptyStyle } from './RecapInputStyles'
import RecapInputTagBooks from './RecapInputTagBooks'
import RecapInputTagAuthors from './RecapInputTagAuthors'
import RecapInputTagClils from './RecapInputTagClils'

const RecapInputTag = <InputType, ValueType>({
  type,
  value,
  color,
  menuPositionTop = false,
  booksSelected = [],
}: {
  type: StudyObjectType | CompareGroupType
  value: StudyObjectValueType | CompareGroupValueType
  color: string
  menuPositionTop?: boolean
  booksSelected?: BookInterface[]
}) => {
  const { t } = useTranslation()

  if (type && value) {
    switch (type) {
      case StudyObjectGroupCompareEnum.TITLE:
        if (value instanceof Array)
          return (
            <RecapInputTagBooks
              value={value as BookInterface[]}
              color={color}
              menuPositionTop={menuPositionTop}
              booksSelected={booksSelected}
            />
          )
        break
      case StudyObjectGroupCompareEnum.AUTHOR:
        return (
          <RecapInputTagAuthors
            value={value as AuthorInterface[]}
            color={color}
            menuPositionTop={menuPositionTop}
            booksSelected={booksSelected}
          />
        )
      case StudyObjectGroupCompareEnum.CLIL:
        if (value instanceof Array) {
          return (
            <RecapInputTagClils
              value={value as string[]}
              color={color}
              menuPositionTop={menuPositionTop}
            />
          )
        }
        break
      default:
        break
    }
  }
  return (
    <TagEmptyStyle>
      <span>{t('completeTheSearch')}</span>
    </TagEmptyStyle>
  )
}

export default RecapInputTag
