import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import DynamicsResultsCommentHorizontalList from './DynamicsResultsCommentHorizontalList'
import { CommentInterface } from '../../../../core/interface/rank/book-detail.interface'

const TitleStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  // text-transform: uppercase;
  color: #595959;
`

const RankCommentContainer = styled.div`
  padding-right: 8px;
`
interface Props {
  comments: CommentInterface[]
  nbComments: number
}

const DynamicsResultsComments = ({ comments, nbComments }: Props) => {
  const { t } = useTranslation()
  return (
    <RankCommentContainer>
      <TitleStyle className="flex items-center ml-5">
        <span>Derniers avis sur cette œuvre</span>
      </TitleStyle>
      <div className="text-xs">
        <DynamicsResultsCommentHorizontalList comments={comments} />
      </div>
    </RankCommentContainer>
  )
}
export default DynamicsResultsComments
