import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { NewReportInterface } from '../../../../interface/report/new-report.interface'
import { ReportInterface } from '../../../../interface/report/report.interface'

const postNewReportService = (
  report: NewReportInterface,
  cancelToken?: CancelToken,
): Promise<any> => {
  return apiInstance.post<ReportInterface>(ApiPathConfig.REPORTS_NEW, report, {
    cancelToken,
  })
}

export default postNewReportService
