import React from 'react'
import '../../styles/_shared.scss'
import '../../styles/components/Header.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoIcon } from '../../assets/icons/bookmetrie-logo.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'

const AuthHeader = () => {
  return (
    <header className="flex flex-row bg-black justify-between py-3 px-6 relative top-0 h-12 items-center w-full">
      <Link to={buildRelativePath([PathConfig.BASE])}>
        <LogoIcon />
      </Link>
    </header>
  )
}

export default AuthHeader
