import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'
import SortButtons from '../buttons/SortButtons'

const Label = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`
interface Props {
  selected: boolean
  selectedFromSortAction?: boolean | null
  label: string
  className?: string
  icon?: string | null
  hasSortButton?: boolean
  sortValue: SortDirectionEnum
  handleDirectionSelected?: (direction: SortDirectionEnum) => void
}

const GlCellHeaderV3 = ({
  selected = false,
  selectedFromSortAction = false,
  label,
  className = '',
  icon = null,
  hasSortButton = undefined,
  sortValue,
  handleDirectionSelected = undefined,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        borderBottom: '1px solid  #E9E9E9',
      }}
      className={`flex flex-auto items-center justify-between p-2 ${
        handleDirectionSelected ? 'hover:bg-[#f7f7f7]' : ''
      } ${className}`}
    >
      <button
        type="button"
        className={`h-full w-full ${
          handleDirectionSelected ? 'cursor-pointer' : 'cursor-default'
        }`}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          handleDirectionSelected &&
            handleDirectionSelected(
              sortValue === SortDirectionEnum.EMPTY
                ? SortDirectionEnum.ASC
                : sortValue === SortDirectionEnum.ASC
                ? SortDirectionEnum.DESC
                : SortDirectionEnum.EMPTY,
            )
        }}
      >
        <div className="flex flex-row flex-auto items-center justify-start gap-2">
          {icon && <img className="w-4 h-4" src={icon} alt="" />}
          <Label>{t(label)}</Label>
        </div>
      </button>
      {hasSortButton && !selectedFromSortAction && (
        <SortButtons
          hasEmptyDirection
          sortDirection={selected ? sortValue : SortDirectionEnum.EMPTY}
          handleChangeDirection={(direction: SortDirectionEnum) =>
            handleDirectionSelected && handleDirectionSelected(direction)
          }
        />
      )}
    </div>
  )
}

export default GlCellHeaderV3
