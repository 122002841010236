import styled from '@emotion/styled'
import GlCellBody from '../../../../share/table/GlCellBody'
import EmptyBookIcon from '../../../../../assets/icons/empty-book-icon.svg'

const BadgeDisable = styled.div`
  font-family: 'Fira Mono', monospace;
  padding: 1px 5px 0;
  background: #b2b2b2;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: white;
`

const CellLabel = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #b2b2b2;
`

const ProgressLabel = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #595959;
`

const SegmentContainer = styled.div`
  padding: 2px 8px 2px 4px;
  background: #b2b2b2;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
`

const TitleNoResult = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  color: #252525;
`

const TitleLabel = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
`

const ISBNLabel = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b2b2b2;
`
const DynamicsResultsTableBodyNoData = () => {
  return (
    <div>
      <div className="bg-white flex flex-row">
        <GlCellBody className="min-w-[62px] max-w-[62px]">
          <BadgeDisable>1</BadgeDisable>
        </GlCellBody>
        <GlCellBody className="min-w-[72px] max-w-[72px]">
          <div className="flex flex-row items-center justify-center gap-1">
            <svg
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2.5V0.5H0V2.5H4H8ZM8 6.5V4.5H0V6.5H4H8Z"
                fill="#B2B2B2"
              />
            </svg>
            <ProgressLabel>0</ProgressLabel>
          </div>
        </GlCellBody>
        <GlCellBody className="max-w-[400px]">
          <div className="flex flex-row gap-1">
            <img src={EmptyBookIcon} alt="" />
            <div className="flex flex-col gap-1">
              <TitleLabel>Titre du livre</TitleLabel>
              <ISBNLabel>ISBN XX XXXXXXXXXXXXX</ISBNLabel>
            </div>
          </div>
        </GlCellBody>
        <GlCellBody className="max-w-[240px]">
          <CellLabel>{`Nom d'auteur`}</CellLabel>
        </GlCellBody>
        <GlCellBody className="min-w-[80px] max-w-[80px]">
          <CellLabel>Année</CellLabel>
        </GlCellBody>
        <GlCellBody className="min-w-[120px] max-w-[220px]">
          <CellLabel>Éditeur</CellLabel>
        </GlCellBody>
        <GlCellBody className="min-w-[120px] max-w-[120px]">
          <SegmentContainer>
            <div className="flex flex-row items-center justify-center gap-1">
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5459 4.33127C3.5459 2.96837 4.64484 1.86353 6.00044 1.86353C7.35605 1.86353 8.45499 2.96837 8.45499 4.33127C8.45499 4.8997 8.26383 5.42324 7.94261 5.84045C7.18677 6.8222 6.00044 7.80342 6.00044 9.04241V9.04241M6.00044 10.538V11.1363"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>CATÉGORIE 1</div>
            </div>
          </SegmentContainer>
        </GlCellBody>
        <GlCellBody className="min-w-[120px] max-w-[120px]">
          <SegmentContainer>
            <div className="flex flex-row items-center justify-center gap-1">
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5459 4.33127C3.5459 2.96837 4.64484 1.86353 6.00044 1.86353C7.35605 1.86353 8.45499 2.96837 8.45499 4.33127C8.45499 4.8997 8.26383 5.42324 7.94261 5.84045C7.18677 6.8222 6.00044 7.80342 6.00044 9.04241V9.04241M6.00044 10.538V11.1363"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>CATÉGORIE 2</div>
            </div>
          </SegmentContainer>
        </GlCellBody>
        <GlCellBody className="min-w-[120px] max-w-[120px]">
          <CellLabel>Format</CellLabel>
        </GlCellBody>
      </div>
      <div className="flex flex-col m-4">
        <div className="flex flex-row justify-center">
          <TitleNoResult>Pas de résultat</TitleNoResult>
        </div>
      </div>
    </div>
  )
}

export default DynamicsResultsTableBodyNoData
