import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/app/search.icon.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as ResetIcon } from '../../../../assets/icons/app/reset-icon.svg'
import {
  normalizedContains,
  removeDiacritics,
} from '../../../../core/utils/search-text.util'
import { ReactComponent as WorkIcon } from '../../../../assets/icons/headCellIcon/work-icon.svg'
import { ReactComponent as AuthorIcon } from '../../../../assets/icons/headCellIcon/author-icon.svg'
import { ReactComponent as EditorIcon } from '../../../../assets/icons/headCellIcon/editor-icon.svg'
import { ReactComponent as FormatIcon } from '../../../../assets/icons/headCellIcon/format-icon.svg'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_EDITOR,
  FILTER_KEY_FORMAT,
  FILTER_KEY_TITLE,
} from '../../../../core/config/dynamics/filters-items-dynamics.config'
import GlHDivider from '../../../share/GlHDivider'

const ButtonItemStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const AutoCompletionLabel = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const InputStyle = styled.div`
  width: ${({ isOpen }: { isOpen: boolean }) => (isOpen && '320px') || '18px'};
  visibility: ${({ isOpen }: { isOpen: boolean }) =>
    (isOpen && 'visible !important') || 'hidden !important'};
  -webkit-transition: width 200ms ease-in-out, visibility 200ms ease-in-out;
  -moz-transition: width 200ms ease-in-out, visibility 200ms ease-in-out;
  -o-transition: width 200ms ease-in-out, visibility 200ms ease-in-out;
  transition: width 200ms ease-in-out, visibility 200ms ease-in-out;
  padding-left: 18px;
`

const DynamicsResultsSearchButton = ({
  searchValue,
  handleSearchValue,
  trendsAggregateByColumn,
}: {
  searchValue: string
  handleSearchValue: (value: string) => void
  trendsAggregateByColumn: { [key: string]: Set<any> }
}) => {
  const { t } = useTranslation()
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)
  const [lastSearch, setLastSearch] = useState<
    { icon: ReactElement; value: string }[]
  >([])
  const inputRef = useRef<HTMLInputElement>(null)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (open && !searchValue) {
        setOpen(false)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasFocus && !searchValue) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasFocus, searchValue, timer])

  useEffect(() => {
    if (open && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open])

  const getListAutoComplete = (): { icon: ReactElement; value: string }[] => {
    if (!trendsAggregateByColumn) {
      return []
    }

    const titles = Array.from(
      trendsAggregateByColumn[FILTER_KEY_TITLE] || new Set(),
    )
      .filter((value: any) => {
        return normalizedContains(value, searchValue)
      })
      .map((result: any) => ({
        icon: <WorkIcon className="w-4 h-4" />,
        value: result,
      }))

    const authors = Array.from(
      trendsAggregateByColumn[FILTER_KEY_AUTHORS] || new Set(),
    )
      .filter((value: any) => {
        return normalizedContains(value, searchValue)
      })
      .map((result: any) => ({
        icon: <AuthorIcon className="w-4 h-4" />,
        value: result,
      }))

    const editors = Array.from(
      trendsAggregateByColumn[FILTER_KEY_EDITOR] || new Set(),
    )
      .filter((value: any) => {
        return normalizedContains(value, searchValue)
      })
      .map((result: any) => ({
        icon: <EditorIcon className="w-4 h-4" />,
        value: result,
      }))

    const formats = Array.from(
      trendsAggregateByColumn[FILTER_KEY_FORMAT] || new Set(),
    )
      .filter((value: any) => {
        return normalizedContains(value, searchValue)
      })
      .map((result: any) => ({
        icon: <FormatIcon className="w-4 h-4" />,
        value: result,
      }))

    return [...titles, ...authors, ...editors, ...formats].sort((a, b) => {
      const nameA = (a.value || '').toUpperCase() // ignore upper and lowercase
      const nameB = (b.value || '').toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    })
  }

  const textStrong = (value: string): string => {
    const indexOfFirst = removeDiacritics(String(value).toLowerCase()).indexOf(
      removeDiacritics(searchValue.toLowerCase()),
    )
    const valueToReplace = String(value).substring(
      indexOfFirst,
      indexOfFirst + searchValue.length,
    )
    return String(value || '').replace(
      valueToReplace,
      `<strong class="font-bold">${valueToReplace}</strong>`,
    )
  }

  return (
    <div className="relative">
      {!open && (
        <button
          type="button"
          className="border border-[#595959] w-8 h-8 flex items-center justify-center rounded bg-[#595959] text-white"
          onClick={() => setOpen(true)}
        >
          <SearchIcon className="w-[18px] h-[18px]" />
        </button>
      )}
      {open && (
        <InputStyle
          className="relative"
          isOpen={open}
          onFocus={() => {
            setHasFocus(true)
            setOpenMenu(true)
          }}
          onBlur={() => {
            if (!searchValue) {
              setNeedClose(true)
            }
          }}
        >
          <SearchIcon className="h-[18px] absolute top-2 left-3 text-white" />
          <input
            ref={inputRef}
            className="pl-8 border border-[#595959] w-full h-8 flex items-center justify-center rounded bg-[#595959] text-white"
            type="text"
            placeholder={t('commons.buttons.search')}
            value={searchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSearchValue(event.target.value)
            }
          />
          {!searchValue && (
            <button
              type="button"
              className="absolute top-2 right-2"
              onClick={() => {
                setOpen(false)
                handleSearchValue('')
                setOpenMenu(false)
              }}
            >
              <CrossIcon className="w-4 h-4 text-white" />
            </button>
          )}
          {searchValue && (
            <button
              type="button"
              className="absolute top-2 right-2"
              onClick={() => {
                handleSearchValue('')
                inputRef && inputRef?.current?.focus()
              }}
            >
              <ResetIcon className="w-4 h-4 text-white" />
            </button>
          )}
        </InputStyle>
      )}
      {!getListAutoComplete().some(
        (item: { icon: ReactElement; value: string }) =>
          item.value === searchValue,
      ) &&
        openMenu &&
        searchValue &&
        open && (
          <div
            className="absolute bg-white w-full left-0 top-10 z-45 shadow-2xl border rounded flex flex-col"
            style={{
              zIndex: 50,
              maxHeight: 256,
            }}
          >
            {(getListAutoComplete().length && (
              <PerfectScrollbar className="max-h-256px">
                {getListAutoComplete().map(
                  (
                    item: { icon: ReactElement; value: string },
                    index: number,
                  ) => (
                    <ButtonItemStyle
                      type="button"
                      key={nanoid(5)}
                      className={`
                  flex
                  flex-row
                  justify-start
                  items-center
                  gap-2
                  px-3
                  py-[4.5px]
                  w-full
                  active:bg-[#e3e3e3]
                  hover:bg-[#f7f7f7]
                  ${(index === 0 && 'rounded-t-[4px]') || ''}
                  ${
                    (index + 1 === getListAutoComplete().length &&
                      'rounded-b-[4px]') ||
                    ''
                  }
                `}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault()
                        handleSearchValue(item.value)
                        setLastSearch(
                          [
                            item,
                            ...lastSearch.filter(
                              (item1) => item1.value !== item.value,
                            ),
                          ].slice(0, 4),
                        )
                        inputRef && inputRef?.current?.focus()
                      }}
                    >
                      {item.icon}
                      <AutoCompletionLabel
                        className="text-start"
                        dangerouslySetInnerHTML={{
                          __html: textStrong(item.value),
                        }}
                      />
                    </ButtonItemStyle>
                  ),
                )}
              </PerfectScrollbar>
            )) || <div>{t('commons.noResult')}</div>}
          </div>
        )}
      {(openMenu && !searchValue && lastSearch.length && (
        <div
          className="absolute bg-white w-full left-0 top-10 z-45 shadow-2xl border rounded flex flex-col"
          style={{
            zIndex: 50,
            maxHeight: 256,
          }}
        >
          <div>{t('commons.lastSearch')}</div>
          <GlHDivider />
          {lastSearch.map(
            (item: { icon: ReactElement; value: string }, index: number) => (
              <ButtonItemStyle
                type="button"
                key={nanoid(5)}
                className={`
                  flex
                  flex-row
                  justify-start
                  items-center
                  gap-2
                  px-3
                  py-[4.5px]
                  w-full
                  active:bg-[#e3e3e3]
                  hover:bg-[#f7f7f7]
                  ${(index === 0 && 'rounded-t-[4px]') || ''}
                  ${
                    (index + 1 === getListAutoComplete().length &&
                      'rounded-b-[4px]') ||
                    ''
                  }
                `}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  handleSearchValue(item.value)
                  setLastSearch(
                    [
                      item,
                      ...lastSearch.filter(
                        (item1) => item1.value !== item.value,
                      ),
                    ].slice(0, 4),
                  )
                }}
              >
                {item.icon}
                <AutoCompletionLabel
                  className="text-start"
                  dangerouslySetInnerHTML={{
                    __html: textStrong(item.value),
                  }}
                />
              </ButtonItemStyle>
            ),
          )}
        </div>
      )) ||
        null}
    </div>
  )
}

export default DynamicsResultsSearchButton
