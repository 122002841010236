import { useTranslation } from 'react-i18next'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import { DatasetTypeEnum } from '../../../../../../../../core/enums/dataset-type.enum'
import { clilReferencesConfig } from '../../../../../../../../core/config/clil-references.config'
import CustomContentHeaderTooltip from './tooltipContent/CustomContentHeaderTooltip'
import CustomContentValuesTooltip from './tooltipContent/CustomContentValuesTooltip'
import { TootipContainerStyle } from '../../../../ReadershipProfileStyles'

interface TooltipPayload {
  payload: {
    [SourceEnum.CLIL]: string
    [SourceEnum.SUBJECT_OBJECT_VALUE]: number
  }
  dataKey: string
  name: string
  value: number
  color: string
}

interface ValueTooltip {
  value: number
  color: string
  key: string
}

type Props = {
  active: boolean
  payload: Array<TooltipPayload>
  typeDataset: DatasetTypeEnum
  label: string
  showCompareStudyObject?: boolean
  showDelta?: boolean
  addPositiveSign?: boolean
  isClickable?: boolean
  backgroundColorClil?: boolean
  textColorBlack?: boolean
}

const CustomTooltipMulti = ({
  active,
  payload,
  typeDataset,
  label,
  showCompareStudyObject = false,
  showDelta = false,
  addPositiveSign = false,
  isClickable = false,
  backgroundColorClil = false,
  textColorBlack = false,
}: Props) => {
  const { t } = useTranslation()

  const getLabelDataset = () => {
    switch (typeDataset) {
      case DatasetTypeEnum.AGE:
        return t('years')
      case DatasetTypeEnum.BOOK_READ:
        return t('books')
      default:
        return ''
    }
  }

  if (active && payload && payload.length) {
    const studyObjectValue =
      payload.find(
        (item: TooltipPayload) => item.name === SourceEnum.SUBJECT_OBJECT_VALUE,
      ) || payload[0]

    const delta = studyObjectValue.value / 100 - 1
    const roundedDelta = Math.round(delta * 100)

    const values: ValueTooltip[] = []
    if (showCompareStudyObject) {
      values.push({
        value: showDelta
          ? roundedDelta
          : studyObjectValue.payload[SourceEnum.SUBJECT_OBJECT_VALUE],
        color: backgroundColorClil
          ? clilReferencesConfig[studyObjectValue.payload[SourceEnum.CLIL]]
              .color
          : studyObjectValue.color,
        key: studyObjectValue.payload[SourceEnum.CLIL],
      })
    } else {
      payload.forEach((item: TooltipPayload) => {
        values.push({
          value: item.value,
          color: item.color,
          key: item.dataKey,
        })
      })
    }

    return (
      <TootipContainerStyle>
        <CustomContentHeaderTooltip
          typeDataset={typeDataset}
          label={
            typeDataset === DatasetTypeEnum.CLIL && showCompareStudyObject
              ? studyObjectValue.payload[SourceEnum.CLIL]
              : label
          }
          labelDataset={getLabelDataset()}
        />
        <CustomContentValuesTooltip
          values={values}
          maximumFractionDigits={showCompareStudyObject ? 2 : 1}
          isClickable={isClickable}
          addPositiveSign={showDelta && addPositiveSign}
          textColorBlack={showCompareStudyObject && textColorBlack}
        />
      </TootipContainerStyle>
    )
  }
  return null
}

export default CustomTooltipMulti
