import { clilReferencesConfig } from './clil-references.config'

export interface ClilDropDownInterface {
  id: string
  label: string
  parentId: string | null
  level: number | null
  order?: number
  show?: boolean
}

const order: { [key: string]: number } = {
  '3435': 1,
  '3771': 2,
  '3722': 3,
  '4129': 4,
  '3802': 5,
  '3080': 6,
  '3377': 7,
  '3013': 8,
  '3667': 9,
  '3365': 10,
  '3345': 11,
  '3051': 12,
  '3283': 13,
  '3177': 14,
  '3165': 15,
  '3259': 16,
  '3395': 17,
  '3000': 18,
  '3305': 19,
  '3069': 20,
  '3193': 21,
  '3898': 22,
}

const clilDropdownUtil = (): ClilDropDownInterface[] => {
  return Object.keys(clilReferencesConfig)
    .filter((key: string) => !(key === '0000' || key === '999999'))
    .map((key) => {
      const clil = clilReferencesConfig[key]
      return {
        id: key,
        label: clil.libelle,
        parentId:
          clil['1'].id === key
            ? null
            : clil['2'].id === key
            ? clil['1'].id
            : clil['3'].id === key
            ? clil['2'].id
            : clil['4'].id === key
            ? clil['3'].id
            : null,
        level:
          clil['1'].id === key
            ? 1
            : clil['2'].id === key
            ? 2
            : clil['3'].id === key
            ? 3
            : clil['4'].id === key
            ? 4
            : null,
        order: order[key] ? order[key] : 999,
      }
    })
    .sort((a, b) => {
      return a.order - b.order
    })
}

export default clilDropdownUtil
