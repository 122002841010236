import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'

export const deleteStudyObjectOutputService = (
  reportId: string,
  inputId: string,
  outputId: string,
  cancelToken?: CancelToken,
) =>
  apiInstance.delete(
    ApiPathConfig.STUDY_OBJECT_OUTPUT_BY_ID(reportId, inputId, outputId),
    {
      cancelToken,
    },
  )
