export enum GenderFilterEnum {
  SEX_CATEGORY_ALL = 'all',
  SEX_CATEGORY_MALE = 'H',
  SEX_CATEGORY_FEMALE = 'F',
}

export const GenderMapEnum: { [key: string]: GenderFilterEnum } = {
  all: GenderFilterEnum.SEX_CATEGORY_ALL,
  H: GenderFilterEnum.SEX_CATEGORY_MALE,
  F: GenderFilterEnum.SEX_CATEGORY_FEMALE,
}
