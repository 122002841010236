import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
} from '../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { AuthorInterface } from '../interface/author.interface'
import { BookInterface } from '../interface/book.interface'

const getAnalyticsIdsValueUtils = (
  type: StudyObjectGroupCompareEnum | CompareGroupType,
  values: AuthorInterface[] | BookInterface[] | string[],
) => {
  switch (type) {
    case StudyObjectGroupCompareEnum.AUTHOR:
      return (values as AuthorInterface[]).map(
        (author: AuthorInterface) => author.id,
      )
    case StudyObjectGroupCompareEnum.TITLE:
      return (values as BookInterface[]).map((book: BookInterface) => book.glid)
    case StudyObjectGroupCompareEnum.CLIL:
      return (values as string[]) || []
    case null:
    default:
      return []
  }
}

export default getAnalyticsIdsValueUtils
