import { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import AddModuleSwitch from './AddModuleSwitch'
import AddModuleHeader from './AddModuleHeader'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  analyticsDetailsV2StateGetAvailableModulesAction,
  analyticsDetailsV2StatePostDatasetModulesAction,
  analyticsDetailsV2StatePostImageOutputAction,
  analyticsDetailsV2StatePostStudyObjectOutputAction,
  analyticsDetailsV2StatePostTextBoxesOutputAction,
  analyticsDetailsV2StatePostTitleOutputAction,
  analyticsDetailsV2StateReplaceDatasetModulesAction,
} from '../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { AddModuleDataRepresentationEnum } from '../../../../../../core/enums/add-module-data-representation.enum'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { DatasetModuleAvailableModulesInterface } from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import {
  selectAnalyticDetailsStateAnalyticDetailsDatasetOutput,
  selectAnalyticDetailsStateAvailableModules,
} from '../../../../../../store/getters/analytics-details/analytic-details-v2.getters'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'
import { AnalyticModuleReplace } from '../../../../../../core/interface/analytics/analytic-module-replace.interface'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { AnalyticOutputImageModeEnum } from '../../../../../../core/enums/analytic-output-image-mode.enum'

export interface InterfaceRepresentationDataList {
  id: string
  label: string
  description: string
  type: string
  data_type?: DatasetTypeEnum
}

const representationDataList: InterfaceRepresentationDataList[] = [
  {
    id: 'readersAge-title',
    label: 'addModuleMenu.readersAge.title',
    description: '',
    type: 'title',
  },
  {
    id: 'readersAge-studyProposals-readersAge-title',
    label: 'addModuleMenu.readersAge.studyProposals.readersAge.title',
    description:
      'addModuleMenu.readersAge.studyProposals.readersAge.description',
    type: 'text',
    data_type: DatasetTypeEnum.AGE,
  },
  {
    id: 'readersLibrary-title',
    label: 'addModuleMenu.readersLibrary.title',
    description: '',
    type: 'title',
  },
  {
    id: 'readersLibrary-studyProposals-librarySize-title',
    label: 'addModuleMenu.readersLibrary.studyProposals.librarySize.title',
    description:
      'addModuleMenu.readersLibrary.studyProposals.librarySize.description',
    type: 'text',
    data_type: DatasetTypeEnum.BOOK_READ,
  },
  {
    id: 'readersLibrary-studyProposals-libraryComposition-title',
    label:
      'addModuleMenu.readersLibrary.studyProposals.libraryComposition.title',
    description:
      'addModuleMenu.readersLibrary.studyProposals.libraryComposition.description',
    type: 'text',
    data_type: DatasetTypeEnum.CLIL,
  },
  {
    id: 'mirror-title',
    label: 'addModuleMenu.mirror.title',
    description: '',
    type: 'title',
  },
  {
    id: 'mirror-studyProposals-worksMirror-title',
    label: 'addModuleMenu.mirror.studyProposals.worksMirror.title',
    description: 'addModuleMenu.mirror.studyProposals.worksMirror.description',
    type: 'text',
    data_type: DatasetTypeEnum.WORKS_MIRROR,
  },
  {
    id: 'mirror-studyProposals-authorsMirror-title',
    label: 'addModuleMenu.mirror.studyProposals.authorsMirror.title',
    description:
      'addModuleMenu.mirror.studyProposals.authorsMirror.description',
    type: 'text',
    data_type: DatasetTypeEnum.AUTHORS_MIRROR,
  },
  {
    id: 'topBooks-title',
    label: 'addModuleMenu.topBooks.title',
    description: '',
    type: 'title',
  },
  {
    id: 'topBooks-studyProposals-topMostFrequent-title',
    label: 'addModuleMenu.topBooks.studyProposals.topMostFrequent.title',
    description:
      'addModuleMenu.topBooks.studyProposals.topMostFrequent.description',
    type: 'text',
    data_type: DatasetTypeEnum.MOST_FREQUENT,
  },
  {
    id: 'topBooks-studyProposals-topOverrepresented-title',
    label: 'addModuleMenu.topBooks.studyProposals.topOverrepresented.title',
    description:
      'addModuleMenu.topBooks.studyProposals.topOverrepresented.description',
    type: 'text',
    data_type: DatasetTypeEnum.OVERREPRESENTED,
  },
]

const AddModuleView = ({
  setShowAddModuleView,
  moduleToReplace,
  analyticsSetColor,
}: {
  setShowAddModuleView: (show: boolean) => void
  moduleToReplace?: AnalyticModuleReplace
  analyticsSetColor: SetColorEnum
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [step, setStep] = useState<AddModuleStepEnum>(
    moduleToReplace
      ? AddModuleStepEnum.DATA_REPRESENTATION
      : AddModuleStepEnum.HOME,
  )
  const [dataRepresentationSelected, setDataRepresentationSelected] =
    useState<InterfaceRepresentationDataList>(
      moduleToReplace
        ? representationDataList.find(
            (representationData) =>
              representationData.data_type === moduleToReplace.dataType,
          )!
        : representationDataList[1],
    )
  const [datasetModuleSelected, setDatasetModuleSelected] =
    useState<DatasetModuleAvailableModulesInterface>()
  const [outputIdOfSelectedDataset, setOutputIdOfSelectedDataset] =
    useState<string>('')

  const availableModules = useAppSelector(
    selectAnalyticDetailsStateAvailableModules,
  )

  const datasetOutputInReport = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsDatasetOutput,
  )

  useEffect(() => {
    function areChartsListsTheSame(
      a: { [key in DatasetTypeEnum]?: AnalyticsChartTypeEnum[] },
      b: { [key in DatasetTypeEnum]?: AnalyticsChartTypeEnum[] },
    ) {
      const aKeys = Object.keys(a) as DatasetTypeEnum[]
      const bKeys = Object.keys(b) as DatasetTypeEnum[]

      if (aKeys.length !== bKeys.length) {
        return false
      }

      return aKeys.every((key) => {
        const aArray = a[key]?.sort() || []
        const bArray = b[key]?.sort() || []

        if (aArray.length !== bArray.length) {
          return false
        }

        return aArray.every((value, index) => value === bArray[index])
      })
    }

    function checkReportHasSameUsedCharts() {
      const uniqDatasetModules = datasetOutputInReport.reduce(
        (acc, output) => ({
          ...acc,
          [output.data_type]: uniq(
            output.dataset_modules.map((module) => module.type),
          ),
        }),
        {},
      )

      const usedAvailableModules = availableModules.datasets.reduce(
        (acc, module) => ({
          ...acc,
          [module.data_type]: module.available_modules
            .filter((availableModule) => availableModule.is_used)
            .map((availableModule) => availableModule.type),
        }),
        {},
      )

      return areChartsListsTheSame(uniqDatasetModules, usedAvailableModules)
    }

    if (!availableModules) {
      void dispatch(analyticsDetailsV2StateGetAvailableModulesAction())
    } else if (!checkReportHasSameUsedCharts()) {
      void dispatch(analyticsDetailsV2StateGetAvailableModulesAction())
    }
  }, [])

  function handleClickOnEditorialElement(
    dataRepresentation: AddModuleDataRepresentationEnum,
  ) {
    const {
      layout_sizes: { outputs },
    } = availableModules

    switch (dataRepresentation) {
      case AddModuleDataRepresentationEnum.TITLE:
        void dispatch(
          analyticsDetailsV2StatePostTitleOutputAction({
            titleObject: {
              text: t('addModuleMenu.newTitle'),
              level: 1,
              layout_size:
                outputs?.readership_profile_title.default_size ||
                AnalyticOutputLayoutSizeEnum.FULL,
            },
          }),
        )
        break
      case AddModuleDataRepresentationEnum.TEXT:
        void dispatch(
          analyticsDetailsV2StatePostTextBoxesOutputAction({
            textBoxesObject: {
              title: t('addModuleMenu.newText.title'),
              text: t('addModuleMenu.newText.text'),
              layout_size:
                outputs?.readership_profile_textbox.default_size ||
                AnalyticOutputLayoutSizeEnum.THIRD,
            },
          }),
        )
        break
      case AddModuleDataRepresentationEnum.PICTURE: {
        const formData = new FormData()
        formData.append('image', '')
        formData.append(
          'layout_size',
          AnalyticOutputLayoutSizeEnum.HALF.toString(),
        )
        formData.append('image_mode', AnalyticOutputImageModeEnum.LANDSCAPE)
        void dispatch(
          analyticsDetailsV2StatePostImageOutputAction({
            imageObject: formData,
          }),
        )
        break
      }
      case AddModuleDataRepresentationEnum.STUDY_TITLES:
        void dispatch(
          analyticsDetailsV2StatePostStudyObjectOutputAction(
            AnalyticOutputLayoutSizeEnum.FULL,
          ),
        )
        break
      default:
        break
    }
    setShowAddModuleView(false)
  }

  function handleClickOnModule(
    datasetModule:
      | DatasetModuleAvailableModulesInterface
      | DatasetModuleInterface,
    outputId: string,
  ) {
    if (moduleToReplace) {
      if (moduleToReplace.moduleType !== datasetModule.type) {
        void dispatch(
          analyticsDetailsV2StateReplaceDatasetModulesAction({
            outputId,
            outputIdToReplace: moduleToReplace.id,
            datasetModule,
          }),
        )
      }
    } else {
      void dispatch(
        analyticsDetailsV2StatePostDatasetModulesAction({
          outputId,
          datasetModule,
        }),
      )
    }

    setShowAddModuleView(false)
  }

  return (
    <div
      className="absolute z-50 top-0 left-0 flex items-center flex-col gap-8 p-8 w-full h-full"
      style={{
        background: 'rgba(37, 37, 37, 0.94)',
        backdropFilter: 'blur(50px)',
      }}
    >
      <AddModuleHeader
        closeMenu={() => setShowAddModuleView(false)}
        step={step}
        setStep={setStep}
        moduleToReplace={moduleToReplace}
        dataRepresentationSelected={dataRepresentationSelected}
      />
      <PerfectScrollbar className="flex items-center flex-col w-full h-full">
        <AddModuleSwitch
          availableModules={availableModules}
          dataRepresentationSelected={dataRepresentationSelected}
          setDataRepresentationSelected={setDataRepresentationSelected}
          step={step}
          setStep={setStep}
          representationDataList={
            moduleToReplace
              ? [dataRepresentationSelected]
              : representationDataList
          }
          handleClickOnEditorialElement={handleClickOnEditorialElement}
          handleClickOnModule={handleClickOnModule}
          datasetModuleSelected={datasetModuleSelected}
          setDatasetModuleSelected={setDatasetModuleSelected}
          outputIdOfSelectedDataset={outputIdOfSelectedDataset}
          setOutputIdOfSelectedDataset={setOutputIdOfSelectedDataset}
          replaceModule={!!moduleToReplace}
          analyticsSetColor={analyticsSetColor}
        />
      </PerfectScrollbar>
    </div>
  )
}

export default AddModuleView
