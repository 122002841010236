import { Outlet } from 'react-router-dom'
import AuthAppLayout from '../../components/layouts/AuthAppLayout'

const AuthApp = () => {
  return (
    <AuthAppLayout>
      <Outlet />
    </AuthAppLayout>
  )
}

export default AuthApp
