import React from 'react'
import { nanoid } from 'nanoid'
import { sortBy, xor } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import GlBadgeClilV2 from '../../../share/badge/GlBadgeClilV2'
import {
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
  FILTER_KEY_DELTA_RANK,
} from '../../../../core/config/dynamics/filters-items-dynamics.config'
import { normalizedContains } from '../../../../core/utils/search-text.util'
import { getIconProgress } from '../../../../core/enums/progress.enum'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import { ReactComponent as CircleIcon } from '../../../../assets/icons/circle.icon.svg'
import GlHDivider from '../../../share/GlHDivider'

const AllItemStyle = styled.div`
  color: #252525;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-transform: uppercase;
  padding: 6px 12px;

  width: 100%;
  height: 28px;

  &:hover {
    background: #f7f7f7;
  }
`

const ItemStyle = styled.div`
  color: #252525;
  /* Fira Sans/Medium 12 */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  padding: 6px 12px;

  width: 100%;
  height: 28px;
  &:hover {
    background: #f7f7f7;
  }

  &:active {
    background: #e3e3e3;
  }
`

interface Props {
  searchValue: string
  dataAggregate: Array<number | string>
  dataSelected: Array<number | string>
  columnKey?: string
  handleChangeListFilterSelected: (newList: Array<string | number>) => void
}

const DynamicsResultsItemsMenuFiltersActions = ({
  searchValue,
  dataAggregate,
  dataSelected,
  handleChangeListFilterSelected,
  columnKey,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full">
      {(dataAggregate.filter((value: string | number) =>
        searchValue &&
        columnKey !== FILTER_KEY_SEGMENT1 &&
        columnKey !== FILTER_KEY_SEGMENT2 &&
        columnKey !== FILTER_KEY_DELTA_RANK
          ? normalizedContains(`${value}`, searchValue)
          : value,
      ).length > 0 && (
        <>
          <AllItemStyle
            className="flex flex-row w-full h-[28px] items-center justify-between"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault()
              if (
                (dataSelected.length > 0 &&
                  dataSelected.length < dataAggregate.length) ||
                dataSelected.length === 0
              )
                handleChangeListFilterSelected(
                  Array.from(dataAggregate).map(
                    (value: string | number) => value,
                  ),
                )
              else handleChangeListFilterSelected([])
            }}
          >
            <span>{t('commons.label.all')}</span>
            {dataSelected.length === dataAggregate.length ? (
              <SelectedIcon className="w-4 h-4" />
            ) : dataSelected.length > 0 ? (
              <CircleIcon className="w-4 h-4" />
            ) : null}
          </AllItemStyle>
          <GlHDivider />
        </>
      )) || (
        <div className="w-full h-full flex flex-col items-center justify-center pt-2 mb-[-32px]">
          <span>Pas de résultat sur cette recherche</span>
        </div>
      )}
      <PerfectScrollbar className="max-h-[256px]">
        <div className="flex flex-col">
          {sortBy(dataSelected)
            .filter((value: string | number) =>
              searchValue &&
              columnKey !== FILTER_KEY_SEGMENT1 &&
              columnKey !== FILTER_KEY_SEGMENT2
                ? normalizedContains(`${value}`, searchValue)
                : value || value === 0,
            )
            .map(
              (item: any) =>
                (columnKey === FILTER_KEY_DELTA_RANK && (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                  >
                    <span>{getIconProgress(item)}</span>
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4" />
                    ) : null}
                  </ItemStyle>
                )) ||
                ((columnKey === FILTER_KEY_SEGMENT1 ||
                  columnKey === FILTER_KEY_SEGMENT2) && (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between relative"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                    style={{
                      padding: 4,
                    }}
                  >
                    <GlBadgeClilV2 id={`${item}`} />
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4 absolute top-[6px] right-[6px]" />
                    ) : null}
                  </ItemStyle>
                )) || (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                  >
                    <span>{item}</span>
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4" />
                    ) : null}
                  </ItemStyle>
                ),
            )}
        </div>
      </PerfectScrollbar>
      {(dataAggregate.filter((value: string | number) =>
        searchValue &&
        columnKey !== FILTER_KEY_SEGMENT1 &&
        columnKey !== FILTER_KEY_SEGMENT2
          ? normalizedContains(`${value}`, searchValue)
          : value,
      ).length > 0 && <GlHDivider />) ||
        null}
      <PerfectScrollbar className="max-h-[258px]">
        <div className="flex flex-col">
          {sortBy(dataAggregate)
            .filter((value: string | number) =>
              searchValue &&
              columnKey !== FILTER_KEY_SEGMENT1 &&
              columnKey !== FILTER_KEY_SEGMENT2
                ? normalizedContains(`${value}`, searchValue)
                : value || value === 0,
            )
            .map(
              (item: any) =>
                (columnKey === FILTER_KEY_DELTA_RANK && (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                  >
                    <span>{getIconProgress(item)}</span>
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4" />
                    ) : null}
                  </ItemStyle>
                )) ||
                ((columnKey === FILTER_KEY_SEGMENT1 ||
                  columnKey === FILTER_KEY_SEGMENT2) && (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between relative"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                    style={{
                      padding: 4,
                    }}
                  >
                    <GlBadgeClilV2 id={`${item}`} />
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4 absolute top-[6px] right-[6px]" />
                    ) : null}
                  </ItemStyle>
                )) || (
                  <ItemStyle
                    key={nanoid(5)}
                    className="flex flex-row items-center justify-between"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      const newList = xor(dataSelected, [item])
                      handleChangeListFilterSelected(newList)
                    }}
                  >
                    <span>{item}</span>
                    {dataSelected.includes(item) ? (
                      <SelectedIcon className="w-4 h-4" />
                    ) : null}
                  </ItemStyle>
                ),
            )}
        </div>
      </PerfectScrollbar>
    </div>
  )
}

export default DynamicsResultsItemsMenuFiltersActions
