export enum AnalyticOutputTextboxThemeEnum {
  LIGHT = 'Blanc',
  DARK = 'Noir',
}

export const convertColorToUseDarkTheme = (
  color: AnalyticOutputTextboxThemeEnum,
) => {
  return color === AnalyticOutputTextboxThemeEnum.DARK
}

export const mapTexboxTheme = () => {
  return Object.values(AnalyticOutputTextboxThemeEnum).map((theme) => ({
    value: convertColorToUseDarkTheme(theme),
    label: theme,
  }))
}
