import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { ConstellationBookDataInterface } from './ConstellationBookData.interface'
import { clilReferencesConfig } from '../../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilIcon from '../../../../share/badge/GlBadgeClilIcon'
import { ReactComponent as StarPopularityIcon } from '../../../../../assets/icons/app/star-popularity.icon.svg'
import { getPopularityIndexByValue } from '../../../../../core/enums/popularity.enum'

const CardStyle = styled.div`
  padding: 8px;
  width: 258px;
  border-radius: 4px;
  background: white;
`

const LabelStyle = styled.div`
  background: #e9e9e9;
  border-radius: 4px;
  width: 119px;
  padding: 4px 0;
`
const TitleStyle = styled.div`
  color: #252525;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const LabelTitleStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  /* Caption/C2 - Medium - Uppercase */
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
`
const AuthorStyle = styled.div`
  color: #666;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`
const ImageStyle = styled.img`
  width: 60px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
`

const ShareBookCardConstellation = ({
  data,
}: {
  data: ConstellationBookDataInterface
}) => {
  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const percentage =
    (data.workMirror.distance &&
      `${Math.round((1 - data.workMirror.distance) * 1000) / 10}%`) ||
    ''

  const popularity = getPopularityIndexByValue(data.workMirror.popularity)
  return (
    <CardStyle className="flex flex-col gap-2 shadow-2xl">
      <div className="flex flex-row gap-2">
        <ImageStyle
          className="flex-none"
          src={
            data.workMirror.book.imgs ||
            getBookCoverByClil(data.workMirror.book.clil1)
          }
          alt=""
        />
        <div className="flex-auto flex flex-col gap-2">
          <TitleStyle>{data.workMirror.book.title}</TitleStyle>
          <AuthorStyle>{data.workMirror.book.authors[0]}</AuthorStyle>
        </div>
        <GlBadgeClilIcon id={data.workMirror.book.clil1} />
      </div>
      <div className="flex flex-row gap-2">
        <LabelStyle className="flex-auto flex flex-col items-center justify-center">
          <LabelTitleStyle>PROXIMITÉ</LabelTitleStyle>
          <LabelTitleStyle>{percentage}</LabelTitleStyle>
        </LabelStyle>
        <LabelStyle className="flex-auto flex flex-col items-center justify-center">
          <LabelTitleStyle>POPULARITÉ</LabelTitleStyle>
          <div className="flex flex-row items-center justify-center gap-2">
            {Array(5)
              .fill(null)
              .map((_: null, index: number) => (
                <StarPopularityIcon
                  key={nanoid(5)}
                  style={{
                    color: popularity >= index ? '#252525' : '#bebebe',
                  }}
                />
              ))}
          </div>
        </LabelStyle>
      </div>
    </CardStyle>
  )
}
export default ShareBookCardConstellation
