import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { TextDescriptionStyle, TitleAddModuleStyle } from './AddModuleStyles'
import GlHDivider from '../../../../../share/GlHDivider'
import { EditSectionEnum } from '../../../../../../core/enums/analytic-edit-section.enum'
import EditMenuSection from '../../outputs/chart/chartComponents/EditMenuSection'
import { EditOptionInterface } from '../../../../../../core/interface/analytics/analytic-edit-option.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { SelectOptionInterface } from '../../../../../../core/interface/select-option.interface'

const OptionContainer = styled.div`
  display: flex;
  min-width: 404px;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
`

const AddModuleOptionsDisplay = ({
  options,
  handleOnToggle,
  handleOnSizeDropdown,
}: {
  options: EditOptionInterface<string>[]
  handleOnToggle: (optionId: string, value: boolean) => void
  handleOnSizeDropdown: (
    optionId: string,
    value: SelectOptionInterface<AnalyticOutputLayoutSizeEnum>,
  ) => void
}) => {
  const { t } = useTranslation()

  const hasDataOption = options.some(
    (option: EditOptionInterface<string>) =>
      option.section === EditSectionEnum.DATAS,
  )
  const hasDisplayOption = options.some(
    (option: EditOptionInterface<string>) =>
      option.section === EditSectionEnum.DISPLAY,
  )

  return (
    <OptionContainer>
      <TitleAddModuleStyle className="text-white">
        {t('addModuleMenu.displayOptions')}
      </TitleAddModuleStyle>
      <TextDescriptionStyle>
        {t('addModuleMenu.canEditEverytime')}
      </TextDescriptionStyle>
      {hasDataOption && (
        <>
          <GlHDivider opacity={0.1} />
          <EditMenuSection
            sectionTitle={t('EditReadershipProfil.data')}
            section={EditSectionEnum.DATAS}
            options={options}
            handleOnToggle={handleOnToggle}
            handleOnSizeDropdown={handleOnSizeDropdown}
            titleCustomStyle={{
              color: '#BEBEBE',
            }}
            textCustomStyle={{
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '24px',
            }}
          />
        </>
      )}
      {hasDisplayOption && (
        <>
          <GlHDivider opacity={0.1} />
          <EditMenuSection
            sectionTitle={t('EditReadershipProfil.display')}
            section={EditSectionEnum.DISPLAY}
            options={options}
            handleOnToggle={handleOnToggle}
            handleOnSizeDropdown={handleOnSizeDropdown}
            titleCustomStyle={{
              color: '#BEBEBE',
            }}
            textCustomStyle={{
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '24px',
            }}
          />
        </>
      )}
    </OptionContainer>
  )
}

export default AddModuleOptionsDisplay
