import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../enums/analytic-output-layout-size.enum'

export const putTextBoxesOutputService = (
  reportId: string,
  inputId: string,
  outputId: string,
  textBoxesObject: {
    title: string
    text: string
    layout_size: AnalyticOutputLayoutSizeEnum
  },
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.TEXT_BOXES_OUTPUT_BY_ID(reportId, inputId, outputId),
    textBoxesObject,
    {
      cancelToken,
    },
  )
