import React from 'react'
import styled from '@emotion/styled'

const DividerStyle = styled.hr<{ color: string; opacity: number }>`
  border-top: 1px solid ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  margin: 0 !important;
  width: 100%;
`

const GlHDivider = ({
  color = '#e3e3e3',
  opacity = 1,
}: {
  color?: string
  opacity?: number
}) => <DividerStyle color={color} opacity={opacity} />

export default GlHDivider
