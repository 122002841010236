import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import IconInterface from '../../core/interface/rank/icon.interface'

const IconStyle = styled.div`
  background-color: ${({ color }: { color: string }) => color};
`

const CatalogNameStyle = styled.div`
  background-repeat: no-repeat;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #252525;
  margin-left: 6px;
`
interface Props {
  catalog: CatalogInterface
}
const GlCatalog = ({ catalog }: Props) => {
  const { name, icons } = catalog
  return (
    <div className="flex flex-row justify-start items-center gap-[2px]">
      {icons.map((icon: IconInterface) => (
        <IconStyle
          key={nanoid(5)}
          color={icon.color}
          className="flex justify-center items-center rounded h-5 w-5"
        >
          <img
            className="flex color-[#252525]"
            width={16}
            height={16}
            src={icon.icon}
            alt="Icon catalog"
          />
        </IconStyle>
      ))}

      <CatalogNameStyle>
        <span>{name}</span>
      </CatalogNameStyle>
    </div>
  )
}

export default GlCatalog
