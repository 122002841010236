import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PerfectScrollbar from 'react-perfect-scrollbar'
import timezone from 'dayjs/plugin/timezone'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  dashboardStateSetCatalogSelected,
  reinitializeDashboardState,
} from '../../store/reducers/dashboard.reducer'
import {
  selectDashboardIsLoadingLastReports,
  selectDashboardIsLoadingTopRequestSaved,
  selectDashboardLastReports,
  selectDashboardStateCatalogSelected,
  selectDashboardStateIsLoadingTrends,
  selectDashboardTopsRequestSaved,
  selectDashboardTrends,
} from '../../store/getters/dashboard.getters'
import { ranksShowList } from '../../store/reducers/trends.reducer'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import {
  selectWebAppStateCompanyUserId,
  selectWebAppStateFullName,
  selectWebAppStateHomeTopCatalogs,
  selectWebAppStateIsLoadingGetTopHomeCatalogs,
  selectWebAppStateMySelfHasAnalyticsAccess,
  selectWebAppStateMySelfHasDynamicsAccess,
  selectWebAppStateTagGroups,
} from '../../store/getters/web-app.getters'
import BackGroundAuthIcon from '../../assets/icons/app/background-auth.icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/app/search.icon.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/app/add.icon.svg'
import {
  dashboardGetLastPrivateReportsAction,
  dashboardGetLastTopRequestsSaveAction,
  dashboardGetTrendsByCatalogIdAction,
} from '../../store/actions/dashboard.actions'
import DashboardCatalogsFilters from '../../components/dahsboard/DashboardCatalogsFilters'
import GlLoader from '../../components/share/GlLoader'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { REFERENCE_TIMEZONE } from '../../core/config/TimeZoneConfig'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import DynamicsSearchTopRequestSaved from '../../components/dynamics-search/DynamicsSearchTopRequestSaved'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { serverInUpdate } from '../../core/utils/get-period-by-period-type.util'
import GlImageBook from '../../components/share/images/GlImageBook'
import { clilReferencesConfig } from '../../core/config/clil-references.config'
import GlDialog from '../../components/share/dialog/GlDialog'
import { ReactComponent as BookDaily } from '../../assets/icons/panel/book_daily_added.icon.svg'
import { ReactComponent as MonthlySessions } from '../../assets/icons/panel/monthly_sessions.icon.svg'
import { ReactComponent as ShelvesSaved } from '../../assets/icons/panel/shelves_saved.icon.svg'
import { ReactComponent as TotalBooks } from '../../assets/icons/panel/total_books.icon.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/app/cross.icon.svg'
import AnalyticsCard from '../../components/analytics-dashboard/card-view/AnalyticsCard'
import GlSideBar from '../../components/share/GlSideBar'
import WorkMirrorBookDetailsCard from '../../components/analytics-details-v2/view/work-mirror/WorkMirrorBookDetailsCard'
import { analyticDetailsV2StateGetBookDetailsDataById } from '../../store/actions/analytics-details/analytic-details-v2.actions'
import { selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues } from '../../store/getters/analytics-details/analytic-details-v2.getters'
import { BookInterface } from '../../core/interface/book.interface'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'

dayjs.extend(timezone)

const DashboardLayout = styled.div`
  background-image: url('${BackGroundAuthIcon}');
  background-repeat: no-repeat;
  background-position: 96% 0%;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  padding: 0 8px 0 0;
  margin-bottom: 48px;
`

const WelcomeStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #252525;
`

const CardStyleMain = styled.div`
  background: ${({ transparent }: { transparent: boolean }) =>
    (transparent && 'rgba(255, 255, 255, 0.20)') || 'white'};
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  ${({ transparent }: { transparent: boolean }) =>
    transparent && 'backdrop-filter: blur(10px);'}
  )}
`

const CardStyleMainHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-family: 'Vollkorn', serif;
  font-variant-numeric: lining-nums proportional-nums;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  color: #252525;
  &:hover .all-see-btn {
    visibility: visible;
  }
`

const CardSLineStyleOne = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  color: #252525;
`
const CardSLineStyleTwo = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`
const CardSLineStyleSub = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #595959;
`

const BookTitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
`
const BookSubStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #929292;
`

const ButtonAllSeeStyle = styled.button`
  visibility: hidden;
  border-radius: 4px;
  border: 1px solid #252525;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #595959;
  padding: 6px 8px;
  &:hover {
    background: #f4f4f4;
  }
  &:active {
    background: #e3e3e3;
  }
`

const PanelBookmetrieDataTitleStyle = styled.div`
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const PanelBookmetrieDataStyle = styled.div`
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.48px;
`

const PanelBookmetrieTitleStyle = styled.div`
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Vollkorn', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`

const PanelBookmetrieBodyStyle = styled.div`
  color: #252525;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const PanelBookmetrieLastUpdateStyle = styled.div`
  color: #e9e9e9;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const DashboardView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showBookmetriePanel, setShowBookmetriePanel] = useState<boolean>(false)
  const [showBookCard, setShowBookCard] = useState(true)

  const dispatch = useAppDispatch()

  const hasDynamicsAccess = useAppSelector(
    selectWebAppStateMySelfHasDynamicsAccess,
  )
  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )
  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)
  const fullName = useAppSelector(selectWebAppStateFullName)
  const isLoadingLastReports = useAppSelector(
    selectDashboardIsLoadingLastReports,
  )
  const lastReports = useAppSelector(selectDashboardLastReports)
  const isLoadingGetCatalogs = useAppSelector(
    selectWebAppStateIsLoadingGetTopHomeCatalogs,
  )
  const catalogs = useAppSelector(selectWebAppStateHomeTopCatalogs)

  const catalogSelected = useAppSelector(selectDashboardStateCatalogSelected)

  const isLoadingTrends = useAppSelector(selectDashboardStateIsLoadingTrends)
  const trends = useAppSelector(selectDashboardTrends)
  const tagGroups = useAppSelector(selectWebAppStateTagGroups)

  const isLoadingTopRequestSaved = useAppSelector(
    selectDashboardIsLoadingTopRequestSaved,
  )
  const topsRequestSaved = useAppSelector(selectDashboardTopsRequestSaved)

  const {
    isLoadingBookDetailsGetBookReco,
    bookDetailsBookReco,
    isLoadingGetBookDetails,
    isLoadingGetEditions,
    isLoadingGetB2BBookDetails,
    bookDetailsSelected,
    bookDetailsSelectedEditions,
    bookDetailsSelectedB2BInfo,
  } = useAppSelector(selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues)

  useEffect(() => {
    if (!catalogSelected) {
      dispatch(dashboardStateSetCatalogSelected(catalogs[0]))
    }
  }, [catalogs])

  useEffect(() => {
    if (hasDynamicsAccess) {
      void dispatch(dashboardGetLastTopRequestsSaveAction())
    }
  }, [hasDynamicsAccess])

  useEffect(() => {
    if (hasAnalyticsAccess) {
      void dispatch(dashboardGetLastPrivateReportsAction())
    }
  }, [hasAnalyticsAccess])

  useEffect(() => {
    return () => {
      dispatch(reinitializeDashboardState())
    }
  }, [])

  useEffect(() => {
    if (catalogSelected && hasDynamicsAccess && tagGroups.length) {
      void dispatch(dashboardGetTrendsByCatalogIdAction({ catalogSelected }))
    }
  }, [catalogSelected, hasDynamicsAccess, tagGroups])

  const handleCatalogSelected = (catalogSelected: CatalogInterface): void => {
    void dispatch(dashboardStateSetCatalogSelected(catalogSelected))
  }

  const getClilColor = (value: any) => {
    return (
      (value && clilReferencesConfig[value]?.color) ||
      clilReferencesConfig['0000'].color
    )
  }

  const handleOpenBookDetails = useCallback(
    (eanId: number | null, showBookDetail: boolean) => {
      setShowBookCard(showBookDetail)
      void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId }))
    },
    [
      bookDetailsSelected,
      bookDetailsSelectedEditions,
      bookDetailsSelectedB2BInfo,
    ],
  )

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId: null }))
  }, [bookDetailsSelected])

  const handleClickAnalytics = (id: string, type: AnalyticsTypeEnum) => {
    navigate(
      buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS, id]),
    )
  }

  return (
    <DashboardLayout className="absolute">
      <PerfectScrollbar className="w-full h-full flex flex-col py-8 px-12 gap-10">
        <WelcomeStyle>{t('commons.welcome', { fullName })}</WelcomeStyle>
        <div className="flex flex-row gap-4">
          <div className="flex flex-col flex-auto basis-3/5 min-w-[819px] gap-4">
            {(hasDynamicsAccess && (
              <CardStyleMain
                transparent={false}
                className="flex flex-col gap-4 p-4"
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row justify-start items-center gap-16">
                    <Link
                      to={buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        PathConfig.DYNAMICS,
                        PathConfig.SEARCH,
                      ])}
                      onClick={() => {
                        dispatch(ranksShowList(true))
                      }}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <CardStyleMainHeader>
                        {t('webApp.dashboard.lastDynamics')}
                      </CardStyleMainHeader>
                    </Link>
                    {(topsRequestSaved && topsRequestSaved.count && (
                      <ButtonAllSeeStyle
                        type="button"
                        className="all-see-btn"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          event.preventDefault()
                          navigate(
                            buildRelativePath(
                              [
                                PathConfig.BOOKMETRIE,
                                PathConfig.DYNAMICS,
                                PathConfig.SEARCH,
                              ],
                              'topRequestSaved-anchor',
                            ),
                          )
                        }}
                      >
                        {t('commons.buttons.allSee')}
                      </ButtonAllSeeStyle>
                    )) ||
                      null}
                  </div>
                </div>
                {(isLoadingTopRequestSaved && (
                  <div className="relative flex flex-col gap-[2px] min-h-[180px]">
                    <div className="absolute w-full h-full flex items-center justify-center">
                      <GlLoader />
                    </div>
                  </div>
                )) || (
                  <div className="relative flex flex-col gap-[2px]  min-h-[180px]">
                    {(topsRequestSaved &&
                      topsRequestSaved.count &&
                      topsRequestSaved.results
                        .slice(0, 3)
                        .map(
                          (
                            request: TopRequestSavedInterface,
                            index: number,
                          ) => (
                            <DynamicsSearchTopRequestSaved
                              key={`top-request-save-${request.id}`}
                              name={request.name || ''}
                              catalog={request.catalog}
                              tagGroup={request.tag_group}
                              tagGroupName={request.tag_group_name}
                              sexCategory={request.sex_category}
                              ageCategory={request.age_category}
                              periodType={request.period_type}
                              startDate={request.start_date}
                              endDate={request.end_date}
                              id={request.id}
                              index={index}
                              handleOnClickTopRequestSaved={() => null}
                              handleRenameTopRequestSaved={() => null}
                              handleDeleteTopRequestSaved={() => null}
                              readOnly
                            />
                          ),
                        )) || (
                      <>
                        <div className="absolute w-full h-[150px] top-0 left-0 items-center justify-around flex flex-col">
                          <div className="flex flex-col gap-1 items-center justify-center ">
                            <CardSLineStyleOne>
                              {t('webApp.dashboard.noDynamicsConsulted')}
                            </CardSLineStyleOne>
                            <CardSLineStyleTwo>
                              {t('webApp.dashboard.launchDynamicsSearch')}
                            </CardSLineStyleTwo>
                          </div>

                          <button
                            type="button"
                            className="bg-[#252525] text-white flex flex-row items-center justify-center gap-2 py-1 px-3 rounded-[4px] uppercase"
                            onClick={() => {
                              navigate(
                                buildRelativePath(
                                  [
                                    PathConfig.BOOKMETRIE,
                                    PathConfig.DYNAMICS,
                                    PathConfig.SEARCH,
                                  ],
                                  'topRequestSave',
                                ),
                              )
                            }}
                          >
                            <span>{t('commons.buttons.search')}</span>
                            <SearchIcon className="h-4 w-4" />
                          </button>
                        </div>
                        <div className="h-[52px] bg-[#f7f7f7] rounded-[4px]" />
                        <div className="h-[52px] bg-[#f7f7f7] rounded-[4px]" />
                        <div className="h-[52px] bg-[#f7f7f7] rounded-[4px]" />
                      </>
                    )}
                  </div>
                )}
              </CardStyleMain>
            )) ||
              null}
            {(hasAnalyticsAccess && (
              <CardStyleMain
                transparent={false}
                className="flex flex-col gap-[17px] p-4"
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row justify-start items-center gap-16">
                    <Link
                      to={buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        PathConfig.ANALYTICS,
                      ])}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <CardStyleMainHeader>
                        {t('webApp.dashboard.lastAnalytics')}
                      </CardStyleMainHeader>
                    </Link>
                    {(lastReports.length && (
                      <ButtonAllSeeStyle
                        type="button"
                        className="all-see-btn"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          event.preventDefault()
                          navigate(
                            buildRelativePath([
                              PathConfig.BOOKMETRIE,
                              PathConfig.ANALYTICS,
                            ]),
                          )
                        }}
                      >
                        {t('commons.buttons.allSee')}
                      </ButtonAllSeeStyle>
                    )) ||
                      null}
                  </div>
                </div>
                {(isLoadingLastReports && (
                  <div className="relative flex flex-row gap-8 justify-start items-center min-h-[366px]">
                    <div className="absolute w-full h-full flex items-center justify-center">
                      <GlLoader />
                    </div>
                  </div>
                )) || (
                  <div className="relative flex flex-row flex-wrap gap-y-3.5 justify-evenly items-center min-h-[366px] max-w-[1000px] self-center">
                    {(lastReports.length &&
                      lastReports.map(
                        (report: ReportLightResponseInterface) => (
                          <AnalyticsCard
                            key={`analytics-card-${report.id}`}
                            analytic={report}
                            handleReloadAction={() => {
                              void dispatch(
                                dashboardGetLastPrivateReportsAction(),
                              )
                            }}
                            width={254}
                            height={176}
                            companyUserId={companyUserId}
                            handleClickAnalytics={handleClickAnalytics}
                          />
                        ),
                      )) || (
                      <div className="w-full gap-4 top-0 left-0 items-center justify-around flex flex-col">
                        <div className="flex flex-col gap-1 items-center justify-center ">
                          <CardSLineStyleOne>
                            {t('webApp.dashboard.noAnalyticsCreated')}
                          </CardSLineStyleOne>
                          <CardSLineStyleTwo>
                            {t('webApp.dashboard.createAnAnalytics')}
                          </CardSLineStyleTwo>
                        </div>

                        <button
                          type="button"
                          className="bg-[#252525] text-white flex flex-row items-center justify-center gap-2 py-1 px-3 rounded-[4px] uppercase"
                          onClick={() => {
                            navigate(
                              buildRelativePath([
                                PathConfig.BOOKMETRIE,
                                PathConfig.ANALYTICS,
                              ]),
                            )
                          }}
                        >
                          <div>{t('commons.buttons.create')}</div>
                          <AddIcon className="h-4 w-4" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </CardStyleMain>
            )) ||
              null}
          </div>
          {(hasDynamicsAccess && (
            <div className="flex flex-col flex-auto basis-2/5 gap-4">
              <CardStyleMain
                transparent
                className="flex flex-col gap-2 pt-4 px-2 "
              >
                <CardStyleMainHeader>
                  {t('webApp.dashboard.top10')}
                  <DashboardCatalogsFilters
                    isLoading={isLoadingGetCatalogs}
                    catalogs={catalogs}
                    catalogIdSelected={catalogSelected?.id}
                    handleCatalogSelected={handleCatalogSelected}
                  />
                </CardStyleMainHeader>
                <CardSLineStyleSub className="mb-2">
                  {(serverInUpdate() && (
                    <span>
                      {t('webApp.dashboard.dataFromBeforeYesterday', {
                        date: dayjs
                          .tz(dayjs().subtract(2, 'day'), REFERENCE_TIMEZONE)
                          .format('dddd DD MMMM'),
                      })}
                    </span>
                  )) || (
                    <span>
                      {t('webApp.dashboard.dataFromYesterday', {
                        date: dayjs
                          .tz(dayjs().subtract(1, 'day'), REFERENCE_TIMEZONE)
                          .format('dddd DD MMMM'),
                      })}
                    </span>
                  )}
                </CardSLineStyleSub>
                {(isLoadingTrends && (
                  <div className="relative rounded bg-white w-full">
                    <div className="absolute top-8 left-0 h-full w-full flex justify-center items-center">
                      <GlLoader />
                    </div>
                  </div>
                )) || (
                  <div className="relative rounded bg-white w-full">
                    <div className="rounded-[16px] h-[509px]">
                      <PerfectScrollbar className="flex flex-col w-full h-full gap-0.5">
                        {(trends.length &&
                          trends
                            .slice(0, 10)
                            .map((trend: TrendV2Interface, index: number) => (
                              <div
                                key={`trend-card-${trend.id_ean}`}
                                className="flex flex-row w-full cursor-pointer	"
                                style={{
                                  height: (index && 52) || 78,
                                }}
                                onClick={() =>
                                  handleOpenBookDetails(
                                    trend.id_ean === bookDetailsSelected?.id_ean
                                      ? null
                                      : trend.id_ean,
                                    trend.id_ean !==
                                      bookDetailsSelected?.id_ean,
                                  )
                                }
                              >
                                <div className="w-[28px] bg-[#252525] flex items-center justify-center text-white rounded-l-lg">
                                  {index + 1}
                                </div>
                                <GlImageBook
                                  pathUrl={trend?.imgs}
                                  height={index === 0 ? 78 : 52}
                                  width={index === 0 ? 54 : 36}
                                  color={getClilColor(trend?.clil1)}
                                />
                                <div className="flex flex-col items-start justify-center p-4 gap-2">
                                  <BookTitleStyle>{trend.title}</BookTitleStyle>
                                  <BookSubStyle className="flex flex-row gap-2">
                                    <span className="text-[#666]">
                                      {trend.authors[0]}
                                    </span>
                                    <span>
                                      {trend.edition},{' '}
                                      {dayjs(trend.publishing_date).format(
                                        'YYYY',
                                      )}
                                    </span>
                                  </BookSubStyle>
                                </div>
                              </div>
                            ))) || (
                          <div className="flex flex-col w-full h-full items-center justify-center">
                            <span>{t('commons.label.noResult')}</span>
                          </div>
                        )}
                      </PerfectScrollbar>
                    </div>
                  </div>
                )}
              </CardStyleMain>
              <div
                className="flex flex-col items-start w-full p-2 gap-2 cursor-pointer"
                style={{
                  borderRadius: 'var(--radius-M, 8px)',
                  background: 'var(--Grayscale-Default-Gray, #252525)',
                  boxShadow:
                    '0px 0px 20px 0px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)',
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  setShowBookmetriePanel(true)
                }}
              >
                <div className="flex items-start self-stretch gap-2">
                  <div
                    className="flex flex-col justify-center items-start flex-1 gap-1 px-4 py-2"
                    style={{
                      borderRadius: 'var(--radius-S, 4px)',
                      background:
                        'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                    }}
                  >
                    <PanelBookmetrieDataTitleStyle>
                      {t('savedLibraries')}
                    </PanelBookmetrieDataTitleStyle>
                    <div className="flex justify-center items-center gap-2">
                      <ShelvesSaved className="h-[24px] w-[24px]" />
                      <PanelBookmetrieDataStyle
                        style={{
                          fontSize: 20,
                        }}
                      >
                        {t('BookmetriePanelValues.SavedLibrairies')}
                      </PanelBookmetrieDataStyle>
                    </div>
                  </div>
                  <div
                    className="flex flex-col justify-center items-start flex-1 gap-1 px-4 py-2"
                    style={{
                      borderRadius: 'var(--radius-S, 4px)',
                      background:
                        'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                    }}
                  >
                    <PanelBookmetrieDataTitleStyle>
                      {t('TotalBooks')}
                    </PanelBookmetrieDataTitleStyle>
                    <div className="flex justify-center items-center gap-2">
                      <TotalBooks className="h-[24px] w-[24px]" />
                      <PanelBookmetrieDataStyle
                        style={{
                          fontSize: 20,
                        }}
                      >
                        {t('BookmetriePanelValues.TotalBooks')}
                      </PanelBookmetrieDataStyle>
                    </div>
                  </div>
                </div>
                <PanelBookmetrieLastUpdateStyle className="flex justify-between items-start self-stretch px-1">
                  <span className="font-normal">
                    {t('BookmetriePanelDatas')}
                  </span>
                  <span className="text-[#929292]">{t('MoreInfo')}</span>
                </PanelBookmetrieLastUpdateStyle>
              </div>
            </div>
          )) ||
            null}
        </div>
      </PerfectScrollbar>

      <GlDialog
        open={showBookmetriePanel}
        onClose={() => setShowBookmetriePanel(false)}
      >
        <div className="flex flex-col shrink-0	gap-4 w-[948px] h-[588px]">
          <PanelBookmetrieTitleStyle className="flex items-center self-stretch justify-between">
            {t('BookmetriePanelDatas')}

            <button
              type="button"
              className="p-[6px]"
              onClick={() => setShowBookmetriePanel(false)}
            >
              <CrossIcon
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </button>
          </PanelBookmetrieTitleStyle>
          <div className="flex gap-8 flex-[1_1_0%] self-stretch">
            <div
              className="flex-1 flex flex-col justify-center self-stretch relative"
              style={{
                borderRadius: 'var(--radius-M, 8px)',
                background:
                  'linear-gradient(180deg, #252525 0%, rgba(37, 37, 37, 0.60) 100%)',
              }}
            >
              <div className="flex flex-col items-center self-strech gap-2  px-16 py-8">
                <div
                  className="flex items-center self-strech gap-4 py-2 px-4 w-full"
                  style={{
                    borderRadius: 'var(--radius-M, 8px)',
                    background:
                      'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                  }}
                >
                  <ShelvesSaved />
                  <div className="flex flex-col justify-center items-start gap-4">
                    <PanelBookmetrieDataTitleStyle>
                      {t('savedLibraries')}
                    </PanelBookmetrieDataTitleStyle>
                    <PanelBookmetrieDataStyle>
                      {t('BookmetriePanelValues.SavedLibrairies')}
                    </PanelBookmetrieDataStyle>
                  </div>
                </div>
                <div
                  className="flex items-center self-strech gap-4  py-2 px-4 w-full"
                  style={{
                    borderRadius: 'var(--radius-M, 8px)',
                    background:
                      'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                  }}
                >
                  <TotalBooks />
                  <div className="flex flex-col justify-center items-start gap-4">
                    <PanelBookmetrieDataTitleStyle>
                      {t('TotalBooks')}
                    </PanelBookmetrieDataTitleStyle>
                    <PanelBookmetrieDataStyle>
                      {t('BookmetriePanelValues.TotalBooks')}
                    </PanelBookmetrieDataStyle>
                  </div>
                </div>
                <div
                  className="flex items-center self-strech gap-4  py-2 px-4 w-full"
                  style={{
                    borderRadius: 'var(--radius-M, 8px)',
                    background:
                      'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                  }}
                >
                  <BookDaily />
                  <div className="flex flex-col justify-center items-start gap-4">
                    <PanelBookmetrieDataTitleStyle>
                      {t('DailyBookAdded')}
                    </PanelBookmetrieDataTitleStyle>
                    <PanelBookmetrieDataStyle>
                      {t('BookmetriePanelValues.DailyBookAdded')}
                    </PanelBookmetrieDataStyle>
                  </div>
                </div>
                <div
                  className="flex items-center self-strech gap-4  py-2 px-4 w-full"
                  style={{
                    borderRadius: 'var(--radius-M, 8px)',
                    background:
                      'var(--Grayscale-White---10, rgba(255, 255, 255, 0.10))',
                  }}
                >
                  <MonthlySessions />
                  <div className="flex flex-col justify-center items-start gap-4">
                    <PanelBookmetrieDataTitleStyle>
                      {t('trimestrialSession')}
                    </PanelBookmetrieDataTitleStyle>
                    <PanelBookmetrieDataStyle>
                      {t('BookmetriePanelValues.TrimestrialSession')}
                    </PanelBookmetrieDataStyle>
                  </div>
                </div>
              </div>
              <PanelBookmetrieLastUpdateStyle className="absolute bottom-3 left-4">
                {t('LastUpdated')}
              </PanelBookmetrieLastUpdateStyle>
            </div>
            <div className="flex-1 ">
              <PanelBookmetrieBodyStyle className="flex flex-col items-start justify-start self-stretch gap-4 pr-2">
                <div className="font-medium	">{t('BookmetrieBody.1')}</div>
                <div>{t('BookmetrieBody.2')}</div>
                <div>{t('BookmetrieBody.3')}</div>
              </PanelBookmetrieBodyStyle>
            </div>
          </div>
        </div>
      </GlDialog>
      {bookDetailsSelected && (
        <div className="absolute top-0">
          <GlSideBar
            open
            position="right"
            hasOverlay
            width={506}
            scrollable={false}
            height="h-full"
          >
            <WorkMirrorBookDetailsCard
              isLoadingBookDetailsGetBookReco={isLoadingBookDetailsGetBookReco}
              bookDetailsBookReco={bookDetailsBookReco}
              isLoadingGetBookDetails={isLoadingGetBookDetails}
              isLoadingGetEditions={isLoadingGetEditions}
              isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
              openListCard={false}
              bookDetails={bookDetailsSelected}
              bookDetailsSelectedEditions={
                showBookCard
                  ? bookDetailsSelectedEditions.filter(
                      (edition: BookInterface) =>
                        edition.id_ean === bookDetailsSelected.id_ean,
                    )
                  : bookDetailsSelectedEditions
              }
              bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
              handleCloseBookDetails={handleCloseBookDetails}
            />
          </GlSideBar>
        </div>
      )}
    </DashboardLayout>
  )
}

export default DashboardView
