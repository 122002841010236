import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../store/hooks'

const ISBNStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b2b2b2;
  user-select: none;
  border-radius: 2px;
  padding: 2px 6px;
`
const GlISBNButton = ({ isbn }: { isbn: number }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <ISBNStyle
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        navigator.clipboard
          .writeText(String(isbn))
          .then(() => {
            dispatch(setShowSnackBar(t('snackbarMessage.copied')))
          })
          .catch(() => {})
      }}
      className="opacity-90 hover:opacity-60 active:opacity-100 border border-[#B2B2B266] hover:border-[#B2B2B233] active:border-[#B2B2B27F]"
    >
      {`ISBN ${isbn}`}
    </ISBNStyle>
  )
}
export default GlISBNButton
