import { AxiosResponse, CancelToken } from 'axios'
import { awsBack } from '../../backend-api.setup'
import { BookDetailResponseInterface } from '../../../interface/rank/book-detail.interface'
import { ApiPathConfig } from '../../../config/ApiPathConfig'

export const getB2bBookDetailsByEanIdService = (
  eanId: number,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<BookDetailResponseInterface>> =>
  awsBack.get<BookDetailResponseInterface>(ApiPathConfig.BOOK_DETAIL(eanId), {
    cancelToken,
  })
