import { clilReferencesConfig } from '../config/clil-references.config'

const getClilColor = (clilValue: any): string => {
  return (
    (clilValue && clilReferencesConfig[clilValue]?.color) ||
    clilReferencesConfig['0000']?.color
  )
}

export default getClilColor
