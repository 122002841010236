import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { PaginationResponseInterface } from '../../../../interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../../../interface/rank/top-requests-saved.interface'

export const getTopRequestSavedNextService = (
  nextApiPath: string,
  cancelToken?: CancelToken,
) => {
  const url = new URL(nextApiPath)
  return apiInstance.get<PaginationResponseInterface<TopRequestSavedInterface>>(
    url.pathname + url.search,
    {
      cancelToken,
    },
  )
}
