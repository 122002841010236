export type ContentType = 'main' | 'h1' | 'h2' | 'p' | 'line'
export const GcuCurrentConfig: any = {
  id: 'gcu',
  version: '1.0',
  date: '2023-06-19',
  type: 'main',
  titleMain: 'CONDITIONS GÉNÉRALES D’UTILISATION BOOKMÉTRIE BY GLEEPH',
  shortTitle: `Conditions générales d'utilisation`,
  contents: [
    {
      type: 'h1',
      titleMain: 'PRÉAMBULE',
      shortTitle: 'Préambule',
      contents: [
        {
          type: 'p',
          content: `Bookmétrie by Gleeph est un site proposé par <b>F-451</b>, société par actions simplifiée au capital de 50 277,10 €,
                    ayant son siège social au 28 Bis Avenue de Strasbourg - 17 340 Châtelaillon Plage, immatriculée au Registre du
                    Commerce et des Sociétés de LA ROCHELLE sous le numéro 808 534 465 RCS LA ROCHELLE (ci-après <b>« F-451 »</b>).
                    Courriel : info@gleeph.pro`,
        },
        {
          type: 'p',
          content: `Le directeur de la publication est Guillaume DEBAIG.`,
        },
        {
          type: 'p',
          content: `Le Site est hébergé par la société Google Cloud France SARL, 8 Rue de Londres, 75009 Paris.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 1 – DÉFINITIONS',
      shortTitle: 'Article 1 - Définitions',
      contents: [
        {
          type: 'line',
          content: `<b>« Application »</b> ou <b>« Application GLEEPH »</b> : Désigne l’application mobile fournie par <b>F-451</b> à l’Utilisateur de
                    l’Application, téléchargeable sur les plateformes de téléchargement mobile compatibles.`,
        },
        {
          type: 'line',
          content: `<b>« Avis »</b> : désigne un avis sur un Livre publié par un Utilisateur de l’Application.`,
        },
        {
          type: 'line',
          content: `<b>« Client »</b> : Désigne le client ayant passé un contrat avec <b>F-451</b>, dont l’objet est la mise à disposition de ses
                      salariés des Services Gleeph (ci-après le « Contrat »).`,
        },
        {
          type: 'line',
          content: `<b>« Commande »</b> : commande de Prestation d’analyse du lectorat effectuée par un Utilisateur dans le cadre du
                      forfait choisi par le Client.`,
        },
        {
          type: 'line',
          content: `<b>« Compte Utilisateur »</b> ou <b>« Compte »</b>: Désigne le compte électronique créé par <b>F-451</b>, sur la base
                      d’informations fournies par le Client et/ou par l’Utilisateur et mis à disposition de ce dernier afin qu’il ait accès
                      à tout ou partie des Services Gleeph.`,
        },
        {
          type: 'line',
          content: `<b>« Contenu Utilisateur de l’Application »</b> : désigne les Avis et autres publications effectuées par l’Utilisateur de
                      l’Application sur l’Application Gleeph.`,
        },
        {
          type: 'line',
          content: `<b>« Données Personnelles »</b> : Désignent toute information relative à l’Utilisateur permettant de l’identifier de
                      manière directe ou indirecte confiée à <b>F-451</b> par le Client ou par l’Utilisateur lui-même par tout moyen.`,
        },
        {
          type: 'line',
          content: `<b>« Bookmétrie by Gleeph »</b> ou « SITE Bookmétrie by Gleeph » ou « Site » : Désigne le site Internet développé
                      par <b>F-451</b> et permettant à l’Utilisateur d’utiliser les Services.`,
        },
        {
          type: 'line',
          content: `<b>« Livre(s) »</b> : désigne(nt) le(s) livre(s) objet des Services.`,
        },
        {
          type: 'line',
          content: `<b>« Prestations d’analyse du lectorat »</b> : prestations d’analyse telles que notamment les Tops et les Rapports ou
                    tout autre prestation que déciderait de proposer <b>F-451</b>.`,
        },
        {
          type: 'line',
          content: `<b>« Résultats d’analyse du lectorat »</b> : désigne la restitution des Prestations d’analyse du lectorat.`,
        },
        {
          type: 'line',
          content: `<b>« Services »</b> ou <b>« Services Gleeph »</b> : Désignent les services proposés par <b>F-451</b> via le Site tels que décrits aux
                    présentes Conditions Générales d’Utilisation et notamment les Prestations d’analyse du lectorat. <b>F-451</b> pourra
                    proposer de nouveaux Services.`,
        },
        {
          type: 'line',
          content: `<b>« Utilisateur »</b> Désigne la personne physique désignée par le Client et inscrite sur Bookmétrie by Gleeph.`,
        },
        {
          type: 'line',
          content: `<b>« Utilisateur de l’Application »</b> : Désigne la personne physique ayant créé son compte utilisateur sur
                    l’Application Gleeph.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 2 - OBJET ET CHAMP D’APPLICATION',
      shortTitle: "Article 2 - Objet et champ d'application",
      contents: [
        {
          type: 'p',
          content: `Les présentes Conditions Générales d’Utilisation (ci-après désignées « CGU ») ont pour objet de définir les
                  modalités d’accès et d’utilisation des Services Gleeph par l’Utilisateur. Les présentes CGU régissent les relations
                  contractuelles entre <b>F-451</b> et l’Utilisateur. L’Utilisateur s’engage expressément à respecter les CGU dans leur
                  intégralité.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 3 – MODALITÉS D’ACCÈS AU SITE',
      shortTitle: "Article 3 - Modalités d'accès au site",
      contents: [
        {
          type: 'h2',
          content: `a) Droit d’accès au Site`,
        },
        {
          type: 'p',
          content: `Le Contrat souscrit par le Client confère à l’Utilisateur un droit d’accès non exclusif, personnel et incessible
                    au Site Bookmétrie by Gleeph. L’Utilisateur s’engage à ne pas donner accès au Site à un tiers.`,
        },
        {
          type: 'h2',
          content: `b) Outils de communication permettant l’accès aux Services`,
        },
        {
          type: 'p',
          content: `Pour accéder aux Services, le Client devra avoir accès à un ordinateur disposant d’une connexion Internet.`,
        },
        {
          type: 'h2',
          content: `c) Activation du Compte Utilisateur`,
        },
        {
          type: 'p',
          content: `Pour accéder aux Services, l’Utilisateur devra activer son Compte Utilisateur à l’aide de l’identifiant qui lui
                    aura été communiqué. Après avoir créé son mot de passe et s’être connecté à son Compte Utilisateur sur
                    le Site, l’Utilisateur doit renseigner les informations nécessaires au fonctionnement des Services et
                    signalées comme étant obligatoires.`,
        },
        {
          type: 'p',
          content: `Un mail de confirmation d’activation de son Compte Utilisateur sera envoyé sur son adresse email.`,
        },
        {
          type: 'h2',
          content: `d) Sécurité de l’accès à l’Application`,
        },
        {
          type: 'p',
          content: `Les identifiants utilisés par l’Utilisateur pour s’authentifier lors de l’accès à l’Application (ci-après les
                    « Eléments d’Identification ») comprennent un identifiant ainsi qu’un mot de passe personnalisé choisis
                    par l’Utilisateur.`,
        },
        {
          type: 'p',
          content: `L’Utilisateur s’engage à conserver ses Eléments d’Identification personnels et confidentiels. Leur
                    conservation et leur utilisation s’effectuent sous la seule responsabilité de l’Utilisateur. En tout état de
                    cause, l’Utilisateur demeure seul responsable de l’usage qui pourrait être fait de son Compte Utilisateur
                    par un tiers qui aurait accédé à ses Eléments d’Identification. A ce titre, <b>F-451</b> ne saurait encourir de
                    responsabilité vis-à-vis de l’Utilisateur pour tout préjudice subi à la suite d’une utilisation illicite,
                    frauduleuse ou abusive de ses Eléments d’Identification.`,
        },
        {
          type: 'h2',
          content: `e) Notification en cas de perte ou vol ou utilisation frauduleuse`,
        },
        {
          type: 'p',
          content: `En cas de perte ou de vol de son ordinateur, de vol de ses Eléments d’Identification, ou en cas de tentative
                    de fraude sur son Compte Utilisateur, l’Utilisateur s’engage à prévenir <b>F-451</b> dans les plus brefs délais par
                    email envoyé à <a href="mailto:info@gleeph.pro">info@gleeph.pro.</a>`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> procédera alors à la suspension des Services dans les meilleurs délais dans les conditions visées aux
                    présentes <a href="/legal#gcu">CGU</a>.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 4 – LES SERVICES DE BOOKMÉTRIE BY GLEEPH',
      shortTitle: 'Article 4 - Les services de bookmétrie by GLEEPH',
      contents: [
        {
          type: 'p',
          content: `<b>F-451</b> met à disposition de l’Utilisateur le Site Bookmétrie by Gleeph lui permettant de bénéficier de tout ou
                    partie des Services Gleeph.`,
        },
        {
          type: 'p',
          content: `L’étendue des Services Gleeph auxquels l’Utilisateur peut avoir accès dépend de l’abonnement choisi par le
                    Client.`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> dispose d’un panel de lecteurs qui utilisent l’Application Gleeph lui permettant de proposer à ses Clients
                    une Prestation d’analyse du lectorat. Cette Prestation d’analyse du lectorat permet à l’Utilisateur :`,
        },
        {
          type: 'p',
          content: `<ol>
                      <li style="list-style-type: disc; list-style-position: inside;">d’étudier tous types de lectorats et de produire des analyses statistiques anonymes sur la base de
                      différents critères (ex : un auteur, un titre, une collection…) concernant ce panel de lecteurs, et de
                      fournir les réponses anonymisées d’études ad hoc de ce panel de lecteurs.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">de consulter des listes de titres miroirs : l’algorithme développé par F-451 calcule des proximités entre
                      titres. Le résultat du calcul est la probabilité qu’un lecteur du Livre source aime le(s) Livre(s) cible(s).</li>
                    </ol>`,
        },
        {
          type: 'p',
          content: `Dans ce cadre, le fait pour l’Utilisateur de cliquer sur « Valider » ou « Enregistrer » vaut engagement
                    irrévocable de l’Utilisateur de passer commande de Prestation d’analyse du lectorat. Les données enregistrées
                    dans les systèmes informatiques de <b>F-451</b> sont stockées de manière sécurisée et constituent la preuve de
                    l’ensemble des commandes réalisées par l’Utilisateur.`,
        },
        {
          type: 'p',
          content: `La liste des fonctionnalités précédentes est donnée à titre indicatif et pourra être amenée à évoluer au fil des
                    évolutions du Site sans remettre en cause la validité des présentes <a href="/legal#gcu">CGU</a>.`,
        },
        {
          type: 'line',
          content: `<b>Résultats :</b>`,
        },
        {
          type: 'line',
          content: `Il est expressément entendu entre les Parties que les données transmises par <b>F-451</b> à l’Utilisateur seront des
                    données anonymisées et qu’aucune donnée personnelle relative aux Utilisateurs de l’Application ne sera
                    transmise au Client.`,
        },
        {
          type: 'line',
          content: `Les Résultats de l’analyse de lectorat seront accessibles en ligne sur le Site.`,
        },
        {
          type: 'line',
          content: `L’Utilisateur a la possibilité de partager l’URL de la page de certains Résultats.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 5 - PROPRIÉTÉ INTELLECTUELLE',
      shortTitle: 'Article 5 - Propriété intellectuelle',
      contents: [
        {
          type: 'h2',
          content: `Site et Contenus Utilisateur`,
        },
        {
          type: 'p',
          content: `Les créations relatives à la structure générale du Site ainsi que ses contenus, les Contenus Utilisateur de
                    l’Application et notamment, sans que cette liste ne revête un caractère exhaustif, les logos, les marques, les
                    noms de domaines, les graphismes, les images, les photographies, les applications logicielles, les animations,
                    les textes, et tout autre élément, qu’il soit visuel et/ou sonore, sont protégés par la législation relative à la
                    propriété intellectuelle en vigueur en France, et ce pour le monde entier.`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> et/ou ses partenaires et/ou les Utilisateurs de l’Application sont titulaires de l’intégralité des droits y
                    afférents.`,
        },
        {
          type: 'p',
          content: `Ces créations et ces contenus ne peuvent être ni reproduits, ni utilisés, ni modifiés, ni altérés, ni vendus, ni
                    loués, ni supprimés, ni représentés, totalement ou partiellement, sans l’autorisation expresse de <b>F-451</b>, de ses
                    partenaires, ou des Utilisateurs de l’Application sous peine de poursuites judiciaires.`,
        },
        {
          type: 'h2',
          content: `Résultats d’analyse du lectorat`,
        },
        {
          type: 'p',
          content: `Les Résultats d’analyse du lectorat demeurent la propriété exclusive de <b>F-451</b>. Le Contrat ne saurait être
                    interprété comme conférant au Client, à l’Utilisateur et aux personnes ayant reçu des liens de partage de
                    manière expresse ou tacite, un quelconque droit de propriété sur ces éléments.`,
        },
        {
          type: 'p',
          content: `Le Contrat ne transférant au Client, à l’Utilisateur et aux personnes ayant reçu des liens de partage aucun droit
                    de propriété matérielle ou intellectuelle sur les Résultats d’analyse du lectorat, le Client, l’Utilisateur et les
                    personnes ayant reçu des liens de partage s&#39;engagent à ne pas altérer, masquer, dissimuler ou enlever toute
                    information ou mention relative au copyright ou au droit de propriété indiquant la propriété de <b>F-451</b> sur ces
                    éléments.`,
        },
        {
          type: 'p',
          content: `Par le présent Contrat, <b>F-451</b> concède au Client et à l’Utilisateur un droit d’accès et d’utilisation personnel, non
                    exclusif et non cessible des Prestations d’analyse du lectorat pour ses propres besoins, comprenant le droit de
                    représentation et de diffusion au sein de son entreprise.`,
        },
        {
          type: 'p',
          content: `En particulier, le Client et l’Utilisateur s’interdisent expressément de reproduire, de commercialiser ou de
                    mettre à disposition de tiers, en totalité ou en partie, les informations et les données issues des Résultats
                    d’analyse du lectorat.`,
        },
        {
          type: 'p',
          content: `Le Client et l’Utilisateur seront toutefois autorisés à reproduire de courts extraits de ces éléments sous réserve
                    d’identifier systématiquement <b>F-451</b> et/ou GLEEPH en tant qu’auteur.`,
        },
        {
          type: 'p',
          content: `Ces droits sont concédés pour le territoire français et pour toute la durée du Contrat.`,
        },
        {
          type: 'h2',
          content: `Dispositions spécifiques aux Livres`,
        },
        {
          type: 'p',
          content: `Les Livres, de même que les couvertures et 4 ème de couverture recensés sur le Site sont des œuvres de l’esprit
                    qui bénéficient de la protection du droit d’auteur.`,
        },
        {
          type: 'p',
          content: `En conséquence, la reprise intégrale des Livres, des couvertures ou 4 ème de couvertures par l’Utilisateur sans
                    autorisation des auteurs est prohibée.`,
        },
        {
          type: 'p',
          content: `L’Utilisateur pourra toutefois procéder à de courtes citations sous réserve de respecter les dispositions de
                    l’Article L.122-5 du Code de la Propriété Intellectuelle.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 6 - OBLIGATIONS DE <b>F-451</b>',
      shortTitle: 'ARTICLE 6 - Obligations de&nbsp;<b>F-451</b>',
      contents: [
        {
          type: 'line',
          content: ``,
        },
        {
          type: 'p',
          content: `Dans le cadre des présentes CGU, <b>F-451</b> s’engage à :
            <ul>
              <li  style="list-style-type: disc; list-style-position: inside;">dans le cadre d’une obligation de moyens, fournir un accès au Site, et fournir ses meilleurs efforts pour
                remédier à tout dysfonctionnement porté à sa connaissance. <b>F-451</b> pourra cependant se trouver dans
                l’obligation de suspendre momentanément ou définitivement le Site sans préavis, notamment pour des
                raisons techniques et/ou de maintenance, quelle qu’en soit l’origine ou la cause, sans que ceci n’entraîne de
                responsabilité de la part de <b>F-451</b>
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">
              mettre en place une adresse email (<a href="mailto:info@gleeph.pro">info@gleeph.pro</a>) accessible pour recevoir les demandes des
              Utilisateurs.
              </li>
            </ul>
          `,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 7 - OBLIGATIONS DE L’UTILISATEUR',
      shortTitle: `ARTICLE 7 - Obligations de l'utilisateur`,
      contents: [
        {
          type: 'p',
          content: `L’Utilisateur s’engage à :
          <ul>
            <li  style="list-style-type: disc; list-style-position: inside;">vérifier l’exactitude, maintenir et remettre à jour régulièrement par le biais du Site, 
              les données ayant servi à son inscription et autres Données Personnelles afin de les conserver 
              vraies, exactes, à jour et complètes.
            </li>
            <li style="list-style-type: disc; list-style-position: inside;">conserver les Eléments d’Identification personnels et confidentiels et modifier régulièrement
              les mots de passe afin de garantir la sécurité du Compte Utilisateur.
            </li>
            <li  style="list-style-type: disc; list-style-position: inside;">prendre à sa charge les éventuels coûts de connexion au Site pouvant être facturés par 
            l’opérateur et/ou le fournisseur d’accès à internet.
            </li>
          </ul>
          `,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 8 - EXCLUSION DE RESPONSABILITÉ',
      shortTitle: 'ARTICLE 8 - Exclusion de responsabilité',
      contents: [
        {
          type: 'p',
          content: `Le Site et son contenu sont fournis en l’état et sous réserve de disponibilité.`,
        },
        {
          type: 'p',
          content: `Le Site est normalement accessible 24 heures sur 24, et 7 jours sur 7, sous réserve :
            <ul>
              <li  style="list-style-type: disc; list-style-position: inside;">d’un cas de force majeure.</li>
              <li  style="list-style-type: disc; list-style-position: inside;">de la disponibilité du réseau internet.</li>
              <li  style="list-style-type: disc; list-style-position: inside;">des suspensions temporaires du Site 
                pour des raisons techniques liées à des mises à jour du Site, à sa
                maintenance ou à toute autre cause technique.
              </li>
            </ul>
          `,
        },
        {
          type: 'p',
          content: `Il est précisé que le réseau Internet et les systèmes informatiques et de télécommunications utilisés dans le
                    cadre de l’accès et de l’utilisation du Site ne sont pas exempts d’erreurs et des interruptions et pannes peuvent
                    occasionnellement survenir. La responsabilité de <b>F-451</b> ne saurait être engagée pour tous les inconvénients ou
                    dommages liées à l'utilisation du réseau Internet, et notamment, sans que cette liste soit limitative, la
                    mauvaise transmission et/ou réception de toute donnée et/ou information sur Internet, la défaillance de tout
                    matériel de réception ou des lignes de communication, tout dysfonctionnement du réseau Internet empêchant
                    le bon fonctionnement du Site et/ou des Services. Il appartient aux Utilisateurs de prendre toute mesure
                    appropriée pour assurer la sécurité de leurs équipements, données, logiciels ou autres, notamment contre la
                    contamination par tout virus et/ou de tentative d’intrusion dont ils pourraient être victimes.`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> décline également toute responsabilité en cas d’utilisation anormale ou d’une exploitation illicite du Site
                    et/ou des Services.`,
        },
        {
          type: 'p',
          content: `De plus, la responsabilité de F-451 ne pourra pas être engagée pour :
            <ul>
              <li  style="list-style-type: disc; list-style-position: inside;">des dommages indirects.</li>
              <li  style="list-style-type: disc; list-style-position: inside;">les coûts de connexion au Site qui
                pourraient être facturés par le fournisseur d’accès à Internet de
                l’Utilisateur ou du Client.
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">l'impossibilité partielle ou totale d'accéder
                au Site pendant les opérations de maintenance et d’évolution
                visées aux présentes,
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">l'impossibilité partielle ou totale d'accéder 
                au Site, si tous les moyens sont mis en œuvre pour rétablir
                l’accès à l’Utilisateur dans les meilleurs délais, ou si ladite impossibilité est liée à l’Utilisateur ou aux outils
                de communication qu’il utilise et/ou la défaillance de son Fournisseur d’Accès à Internet.
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">l’inexactitude, des erreurs ou omissions
                des informations présentes sur le Site.
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">des modifications des informations et 
                des Services se trouvant sur le Site. Lesdites informations et Services sont donnés à titre indicatif
                et peuvent être modifiés ou mis à jour dans les conditions définies ci-après.
              </li>
              <li  style="list-style-type: disc; list-style-position: inside;">Le Contenu Utilisateur</li>
              <li  style="list-style-type: disc; list-style-position: inside;">De l’impossibilité partielle ou totale
                d’accéder au Site si ladite impossibilité est liée à l’Utilisateur ou au Client.
              </li>
            </ul>
          `,
        },
        {
          type: 'p',
          content: `<b>F-451</b> ne garantit pas l'actualité et 'état complet des données présentées dans les Résultats d’analyse du
                    lectorat.`,
        },
        {
          type: 'p',
          content: `Toutes les informations et données diffusées dans ce cadre sont fournies à titre d’information. La fourniture de
                    ces données ne saurait être assimilée, de quelque façon que ce soit, à un conseil spécifique ou à une aide à la
                    décision aux fins d’effectuer notamment une transaction ou de prendre une décision d’investissement. <b>F-451</b>
                    ne saurait être tenue pour responsable d’un quelconque dommage, direct ou indirect, pouvant résulter
                    d’erreurs, d’omissions ou de retards dans la transmission desdites informations.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 9 – FORCE MAJEURE',
      shortTitle: 'ARTICLE 9 – Force majeure',
      contents: [
        {
          type: 'p',
          content: `<b>F-451</b> ne sera pas tenue pour responsable ou considéré comme ayant failli aux obligations des présentes CGU
dès lors qu’un cas de force majeure, tel qu’entendu par la loi et la jurisprudence, remet en cause l’exécution
des présentes.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 10 - SUSPENSION ET EXCLUSION DU SITE',
      shortTitle: 'ARTICLE 10 - Suspension et exclusion du site',
      contents: [
        {
          type: 'p',
          content: `De plein droit, sans droit à indemnité, sans formalités judiciaires et sans préjudice de toutes éventuelles
                    poursuites intentées par <b>F-451</b> notamment en dommages et intérêts, <b>F-451</b> se réserve le droit de suspendre
                    immédiatement tout ou partie de l’accès aux Services, au Site ou de supprimer immédiatement le Compte
                    Utilisateur notamment, sans que cette liste ne revête un caractère exhaustif :`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li  style="list-style-type: disc; list-style-position: inside;">suite à la demande du Client.
                      </li>
                      <li  style="list-style-type: disc; list-style-position: inside;">suite à la notification de vol 
                        ou de perte des Eléments d’Identification.
                      </li>
                      <li  style="list-style-type: disc; list-style-position: inside;">en raison de plusieurs essais 
                        infructueux de composition des identifiants sur le Site. L’Utilisateur devra alors
                        contacter le Service Client Gleeph afin de régulariser la situation.
                      </li>
                      <li  style="list-style-type: disc; list-style-position: inside;">ou en cas de fraude ou de 
                        tentative de fraude, et plus généralement de toute violation des présentes CGU,
                        en raison de tout acte de piratage, d’utilisation ou de tentative d’utilisation des données en violation des
                        lois et règlements, notamment s’introduire dans un système informatique, en altérer le contenu ou
                        commettre l’une quelconque des infractions réprimées par les articles 323-1 à 323-7 du Code Pénal.<br />
                        Est notamment considérée comme tentative de fraude, toute tentative d’altérer, falsifier, de contourner
                        l’usage et/ou les éléments de sécurité des Comptes Utilisateurs, des Services GLEEPH, du Site.
                      </li>
                      <li  style="list-style-type: disc; list-style-position: inside;">en raison du non-respect des dispositions des présentes <a href="/legal#gcu">CGU</a>.</li>
                      <li  style="list-style-type: disc; list-style-position: inside;">dans tout autre cas visé aux présentes <a href="/legal#gcu">CGU</a>.</li>
                    </ul>`,
        },
        {
          type: 'p',
          content: `La suspension ou l’exclusion seront notifiées par email à l’Utilisateur.<br>
                    Le cas échéant, l’email de notification visé ci-dessus précisera à l’Utilisateur le moyen de régulariser sa
                    situation, excepté dans les cas où une disposition légale le lui interdit. La suspension sera maintenue jusqu’à
                    régularisation de la situation.`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> ne pourra en aucun cas être tenue responsable des conséquences dommageables qui pourraient résulter
                    de la suspension ou de la suppression des accès au Site, et/ou d’un éventuel défaut/retard de l’information
                    concernant ledit blocage, sauf en cas de faute lourde ou intentionnelle.`,
        },
        {
          type: 'p',
          content: `En outre, <b>F-451</b> ne pourra en aucun cas être tenue pour responsable des conséquences dommageables de la
                    suspension des Services Gleeph suite à une notification de perte, vol, détournement, utilisation non autorisée
                    ou événement semblable par un tiers qui s’identifie comme l’Utilisateur.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 11 – TRAITEMENT DES DONNÉES PERSONNELLES',
      shortTitle: 'ARTICLE 11 - Traitement des données personnelles',
      contents: [
        {
          type: 'p',
          content: `Lorsque l’Utilisateur navigue sur le Site et/ou utilise les Services, <b>F-451</b> collecte et utilise des Données
                    Personnelles de l’Utilisateur.`,
        },
        {
          type: 'p',
          content: `<b>F-451</b> s’engage à assurer la protection des Données Personnelles de l’Utilisateur. Pour plus d’information sur
                    les modalités de collecte, d’utilisation et de traitement des Données Personnelles par <b>F-451</b>, l’Utilisateur est
                    invité à consulter <a href="/legal#privacy">la politique de confidentialité</a> de <b>F-451</b> disponible sur le Site.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 12 - RENONCIATION – NULLITÉ',
      shortTitle: 'ARTICLE 12 - Renonciation - Nullité',
      contents: [
        {
          type: 'p',
          content: `Sauf dispositions contraires, le fait que <b>F-451</b> ou l’Utilisateur n’ait pas exigé l’application d’une clause
                    quelconque des CGU ne pourra en aucun cas être considéré comme une renonciation aux droits découlant de
                    ladite clause.`,
        },
        {
          type: 'p',
          content: `Dans l'hypothèse où une disposition des présentes CGU serait nulle, illégale, inopposable ou inapplicable d'une
                    manière quelconque, la validité, la légalité ou l'application des autres dispositions des présentes conditions
                    générales n'en seraient aucunement affectées ou altérées, les autres stipulations des conditions générales
                    demeurant en vigueur et conservant leur plein et entier effet.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 13 - TRANSFERT',
      shortTitle: 'ARTICLE 13 - Transfert',
      contents: [
        {
          type: 'p',
          content: `<b>F-451</b> se réserve le droit de transférer les présentes CGU à tout successeur, qui aurait alors les mêmes droits et
                    obligations que <b>F-451</b>, y compris sous forme de fusion, consolidation, restructuration ou location gérance, ou à
                    toute société dont une partie du capital ou des droits de vote sont détenus directement ou indirectement par
                    <b>F-451</b> ou à toute société qui viendrait à détenir une partie du capital ou droits de vote de <b>F-451</b>.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain:
        'ARTICLE 14 - MODIFICATIONS DES SERVICES ET DES CONDITIONS GÉNÉRALES D’UTILISATION',
      shortTitle: `ARTICLE 14 - Modifications des services et des conditions générales d'utilisation`,
      contents: [
        {
          type: 'p',
          content: `<b>F-451</b> pourra à tout moment modifier les Services, le Site et les présentes CGU en particulier lors des évolutions
                    fonctionnelles des Services.`,
        },
        {
          type: 'p',
          content: `Ces modifications entrent en vigueur dès leur mise en ligne et sont réputées opposables à l’Utilisateur à partir
                    de cette date.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain:
        'ARTICLE 15- DEMANDE DE SUPPRESSION DE SON COMPTE PAR L’UTILISATEUR',
      shortTitle: `ARTICLE 15 - Demande de suppresion de son compte par l'utilisateur`,
      contents: [
        {
          type: 'p',
          content: `La demande de suppression de Compte par l’Utilisateur devra être effectuée auprès du Client.
                    <b>F-451</b> ne pourra être tenue pour responsable de l’absence de transmission de la demande par le Client.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: 'ARTICLE 16 -LOI APPLICABLE – LITIGES - MÉDIATION',
      shortTitle: 'ARTICLE 16- Loi applicable - litiges - Médiation',
      contents: [
        {
          type: 'p',
          content: `Les présentes CGU et les relations entre l’Utilisateur et <b>F-451</b> sont régies par le droit français.
                    En cas de difficultés, l’Utilisateur s’engage à contacter le service client en écrivant à <a href="mailto:info@gleeph.pro">info@gleeph.pro</a> afin de
                    trouver une solution à l’amiable.`,
        },
      ],
    },
    {
      type: 'note',
      content: `<b>TOUTES DIFFICULTÉS RELATIVES À L’INTERPRÉTATION, À L’EXÉCUTION DES CGU OU DES SERVICES SERONT SOUMISES, À DÉFAUT
                D’ACCORD AMIABLE DANS LES CONDITIONS CI-DESSUS, À LA COMPÉTENCE EXCLUSIVE DES TRIBUNAUX COMPÉTENTS DE LA ROCHELLE, ET
                CECI MÊME EN CAS DE RÉFÉRÉ, D’APPEL EN GARANTIE OU DE PLURALITÉ DE DÉFENDEURS.</b>`,
    },
  ],
}
