import { AnalyticsTypeEnum } from '../../enums/analytics-type.enum'
import AnalyticsDashboardModuleInterface from '../../interface/analytics-dashboard/analytics-dashboard-module.interface'

const reportDashboardModulesConfig: Array<AnalyticsDashboardModuleInterface> = [
  {
    type: AnalyticsTypeEnum.WORK_MIRROR,
    enable: true,
  },
  {
    type: AnalyticsTypeEnum.READERSHIP_PROFILE,
    enable: true,
  },
  {
    type: AnalyticsTypeEnum.UNIVERS,
    enable: false,
  },
]

export default reportDashboardModulesConfig
