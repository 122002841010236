import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { SelectOptionInterface } from '../../../../../../../core/interface/select-option.interface'
import { analyticsDetailsV2StatePutImageOutputAction } from '../../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { useAppDispatch } from '../../../../../../../store/hooks'
import {
  AnalyticOutputLayoutSizeEnum,
  mapImageSize,
} from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import {
  AnalyticOutputImageCombinationEnum,
  AnalyticOutputInterface,
} from '../../../../../../../core/interface/analytics/analytic-output.interface'
import FakeImage from '../../../../../../../assets/images/fake-image.png'
import GlHDivider from '../../../../../../share/GlHDivider'
import { EditOptionInterface } from '../../../../../../../core/interface/analytics/analytic-edit-option.interface'
import { EditSectionEnum } from '../../../../../../../core/enums/analytic-edit-section.enum'
import { EditOptionTypeEnum } from '../../../../../../../core/enums/analytic-edit-option-type.enum'
import EditMenuSection from './EditMenuSection'
import {
  AnalyticOutputImageModeEnum,
  getImageModeOptions,
} from '../../../../../../../core/enums/analytic-output-image-mode.enum'

const ImageButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background-color: #00000040;
  background-blend-mode: multiply;
  background-position: center;
  background-repeat: no-repeat;
  height: 160px;
  :hover {
    background-color: #00000080;
  }
`

const TextStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
`

const EditMenuImage = ({ output }: { output: AnalyticOutputInterface }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [imageUploaded, setImageUploaded] = useState<File>()

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const sizeAvailable = mapImageSize(
    output.image?.image_mode || AnalyticOutputImageModeEnum.PORTRAIT,
    output.layout_size_config?.default_size,
  ).map((option) => ({
    ...option,
    label: t(option.label),
  }))

  const sizeOptionToDisplay =
    sizeAvailable.find((size) => size.value === output.layout_size) ||
    sizeAvailable[0]

  const imageModeOptions = getImageModeOptions().map((option) => ({
    ...option,
    label: t(option.label),
  }))

  const imageModeOptionToDisplay =
    imageModeOptions.find(
      (option) => option.value === output.image?.image_mode,
    ) || imageModeOptions[0]

  const outputOptionsDatas: EditOptionInterface<
    AnalyticOutputLayoutSizeEnum | AnalyticOutputImageModeEnum
  >[] = [
    {
      id: 'layout_size',
      section: EditSectionEnum.OPTIONS,
      label: t('pictureSize'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: sizeAvailable,
      dropdownEntrySelected: sizeOptionToDisplay,
    },
    {
      id: 'image_mode',
      section: EditSectionEnum.OPTIONS,
      label: t('imageMode'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: imageModeOptions,
      dropdownEntrySelected: imageModeOptionToDisplay,
    },
  ]

  function handleRedirectClickToInput() {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleValidateEditImage = (
    value: AnalyticOutputLayoutSizeEnum | AnalyticOutputImageModeEnum,
    optionId?: string,
  ) => {
    const formData = new FormData()
    if (imageUploaded) formData.append('image', imageUploaded)

    formData.append(
      'image_mode',
      optionId === 'image_mode'
        ? value.toString()
        : output.image?.image_mode || AnalyticOutputImageModeEnum.LANDSCAPE,
    )

    if (
      optionId === 'image_mode' &&
      !(`${value}_${output.layout_size}` in AnalyticOutputImageCombinationEnum)
    ) {
      formData.append(
        'layout_size',
        AnalyticOutputLayoutSizeEnum.THIRD.toString(),
      )
    } else {
      formData.append(
        'layout_size',
        optionId === 'layout_size'
          ? value.toString()
          : (output.layout_size && output.layout_size.toString()) ||
              AnalyticOutputLayoutSizeEnum.THIRD.toString(),
      )
    }

    void dispatch(
      analyticsDetailsV2StatePutImageOutputAction({
        outputId: output.id,
        imageObject: formData,
      }),
    )
  }

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const imageUploaded = event.target.files[0]
    setImageUploaded(imageUploaded)
  }

  const handleSizeDropdownSetOptionToDisplay = (
    optionId: string,
    value: SelectOptionInterface<
      AnalyticOutputLayoutSizeEnum | AnalyticOutputImageModeEnum
    >,
  ) => {
    handleValidateEditImage(value.value, optionId)
  }

  useEffect(() => {
    if (imageUploaded) {
      handleValidateEditImage(sizeOptionToDisplay.value)
    }
  }, [imageUploaded])

  return (
    <>
      <TextStyle>{t('addModuleMenu.picture.title')}</TextStyle>
      <GlHDivider />
      <ImageButtonStyle
        onClick={handleRedirectClickToInput}
        style={{
          backgroundImage: `url(${
            imageUploaded
              ? URL.createObjectURL(imageUploaded)
              : output.image?.card || FakeImage
          })`,
          backgroundSize:
            imageUploaded || output.image?.card ? 'contain' : 'cover',
        }}
      >
        <span className="text-white font-medium text-base">
          {t('EditReadershipProfil.uploadImage')}
        </span>
        <input
          type="file"
          onChange={handleUploadImage}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          accept="image/png, image/gif, image/jpeg"
        />
      </ImageButtonStyle>
      <div
        className="opacity-50"
        style={{
          pointerEvents: output.image || imageUploaded ? 'auto' : 'none',
          opacity: output.image || imageUploaded ? '1' : '0.5',
        }}
      >
        <EditMenuSection
          sectionTitle={t('EditReadershipProfil.data')}
          section={EditSectionEnum.OPTIONS}
          options={outputOptionsDatas}
          handleOnSizeDropdown={handleSizeDropdownSetOptionToDisplay}
        />
      </div>
    </>
  )
}

export default EditMenuImage
