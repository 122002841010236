import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import GlCatalog from '../share/GlCatalog'
import { ReactComponent as BottomArrowIcon } from '../../assets/icons/app/bottom-arrow-light.icon.svg'
import { ReactComponent as SelectedIcon } from '../../assets/icons/selected.icon.svg'

const ContainerStyle = styled.div`
  max-width: 300px;
  min-width: 250px;
`
const VerticalWheelStyle = styled.div`
  transform: ${({ selected }: { selected: number }) =>
    `translateY(${-selected * 32}px)`};
  -webkit-transition: transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out;
  -o-transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
`

const ListStyle = styled.ul`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
  max-height: 450px;
`
const ItemStyle = styled.li`
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e3e3e3;
  }
`

const DynamicsSearchCatalogsMenu = ({
  catalogs,
  catalogSelected,
  handleChangeSelected,
}: {
  catalogs: CatalogInterface[]
  catalogSelected: CatalogInterface
  handleChangeSelected: (catalog: CatalogInterface) => void
}) => {
  const [selectionOpen, setSelectionOpen] = useState(false)
  const [mouseEnterPermission, setMouseEnterPermission] = useState(true)

  const onClickList = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    !selectionOpen && setSelectionOpen(true)
  }

  const onClickItem = (catalog: CatalogInterface) => {
    setMouseEnterPermission(false)
    setSelectionOpen(false)
    handleChangeSelected(catalog)
  }

  return (
    <ContainerStyle
      className="relative h-8 cursor-pointer grow"
      onMouseEnter={() => mouseEnterPermission && setSelectionOpen(true)}
      onMouseLeave={() => {
        setSelectionOpen(false)
        setMouseEnterPermission(true)
      }}
      onClick={onClickList}
    >
      <div className="w-full h-full border-b border-[#e3e3e3] flex justify-end items-center p-2">
        <BottomArrowIcon className="w-3.5 h-3.5 text-[#b2b2b2]" />
      </div>
      <VerticalWheelStyle
        style={{ opacity: selectionOpen ? 0 : 1 }}
        selected={catalogs.findIndex(
          (catalog: CatalogInterface) => catalog.id === catalogSelected?.id,
        )}
        className="flex-col w-full absolute top-0 left-0 px-2"
      >
        {catalogs.map((catalog: CatalogInterface) => (
          <div
            style={{ opacity: catalog.id === catalogSelected?.id ? 1 : 0.65 }}
            className="flex h-8 self-center"
            key={nanoid(5)}
          >
            <GlCatalog catalog={catalog} />
          </div>
        ))}
      </VerticalWheelStyle>
      <ListStyle
        className="absolute top-0 left-0 bg-white  flex-col w-full"
        style={{ display: selectionOpen ? 'flex' : 'none' }}
      >
        <PerfectScrollbar>
          {catalogs.map((catalog: CatalogInterface) => (
            <ItemStyle
              className="h-8 flex p-2 flex-row justify-between items-center"
              key={nanoid(5)}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation()
                event.preventDefault()
                onClickItem(catalog)
              }}
            >
              <GlCatalog catalog={catalog} />
              {catalog.id === catalogSelected?.id && (
                <div className="w-4 h-4 bg-[#595959] rounded-full flex items-center justify-center">
                  <SelectedIcon className="w-4 h-4" />
                </div>
              )}
            </ItemStyle>
          ))}
        </PerfectScrollbar>
      </ListStyle>
    </ContainerStyle>
  )
}

export default DynamicsSearchCatalogsMenu
