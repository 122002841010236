import { RootState } from '../../store'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../interfaces/analytics-details/new-readership-profile-state.interface'
import {
  CompareGroupType,
  StudyObjectType,
} from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { BookInterface } from '../../../core/interface/book.interface'

export const selectAnalyticDetailsReadershipProfileStateIsLoadingReadershipProfileInput =
  (state: RootState): boolean => {
    const { isLoadingReadershipProfileInput } =
      state.analyticsDetailsReadershipProfileState
    return isLoadingReadershipProfileInput
  }
export const selectAnalyticDetailsReadershipProfileStateStudyObjectType = (
  state: RootState,
): StudyObjectType => {
  const { studyObjectType } = state.analyticsDetailsReadershipProfileState
  return studyObjectType
}
export const selectAnalyticDetailsReadershipProfileStateStudyObjectValue = (
  state: RootState,
): StudyObjectValueType => {
  const { studyObjectValue } = state.analyticsDetailsReadershipProfileState
  return studyObjectValue
}
export const selectAnalyticDetailsReadershipProfileStateStudyObjectBooks = (
  state: RootState,
): BookInterface[] => {
  const { studyObjectBooks } = state.analyticsDetailsReadershipProfileState
  return studyObjectBooks
}
export const selectAnalyticDetailsReadershipProfileStateCompareGroupType = (
  state: RootState,
): CompareGroupType => {
  const { compareGroupType } = state.analyticsDetailsReadershipProfileState
  return compareGroupType
}
export const selectAnalyticDetailsReadershipProfileStateCompareGroupValue = (
  state: RootState,
): CompareGroupValueType => {
  const { compareGroupValue } = state.analyticsDetailsReadershipProfileState
  return compareGroupValue
}
export const selectAnalyticDetailsReadershipProfileStateCompareGroupBooks = (
  state: RootState,
): BookInterface[] => {
  const { compareGroupBooks } = state.analyticsDetailsReadershipProfileState
  return compareGroupBooks
}
