import GlISBNButton from '../../../../share/buttons/GlISBNButton'
import GlBookTitle from '../../../../share/GlBookTitle'
import DynamicsBillAuthorsDate from './DynamicsBillAuthorsDate'
import DynamicsBillDetailsBadges from './DynamicsBillDetailsBadges'

interface Props {
  title: string
  eanId: number
  authors: string[]
  publishingDate: string
  clil1: number | null
  clil2: number | null
  edition: string
  format: string
  firstElement: boolean
}

const DynamicsBillDetails = ({
  title,
  eanId,
  authors,
  publishingDate,
  clil1,
  clil2,
  edition,
  format,
  firstElement,
}: Props) => {
  return (
    <div className="px-5 py-2.5 flex flex-row justify-between grow">
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col gap-2 items-start">
          <GlISBNButton isbn={eanId} />
          <GlBookTitle title={title} size={firstElement ? '26px' : '20px'} />
        </div>
        <DynamicsBillAuthorsDate
          authors={authors}
          publishingDate={publishingDate}
        />
      </div>
      <DynamicsBillDetailsBadges
        edition={edition}
        clil1={clil1}
        format={format}
        clil2={clil2}
      />
    </div>
  )
}

export default DynamicsBillDetails
