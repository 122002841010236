import { RootState } from '../store'
import { ForgotPasswordStateInterface } from '../interfaces/forgot-password.state.interface'

export const selectForgotPasswordState = (
  state: RootState,
): ForgotPasswordStateInterface => {
  const { forgotPasswordState } = state
  return forgotPasswordState
}

export const selectForgotPasswordStateIsLoading = (
  state: RootState,
): boolean => {
  const { forgotPasswordState } = state
  return forgotPasswordState.isLoading
}

export const selectForgotPasswordStateSuccess = (state: RootState): boolean => {
  const { forgotPasswordState } = state
  return forgotPasswordState.success
}

export const selectForgotPasswordStateError = (state: RootState): boolean => {
  const { forgotPasswordState } = state
  return forgotPasswordState.error
}
