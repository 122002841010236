import { useTranslation } from 'react-i18next'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { SetColorConfig } from '../../../../../../core/config/SetColorConfig'
import { SetColorConfigInterface } from '../../../../../../core/interface/set-color-config.interface'
import RadarRechart from './chartComponents/RadarRechart'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'

const AnalyticsOutputRadarRechart = ({
  valuesConfigurations,
  primaryKey,
  setColor,
  dataset,
  title,
  subTitle,
  display,
  typeDataset,
  datasetModule,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  isScreenSmall,
  isOrganizeMenuOpen,
  onClick,
  level,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  primaryKey: SourceEnum
  setColor: SetColorEnum
  dataset: Array<DatasetInterface>
  title: string
  subTitle: string
  display: AnalyticOutputLayoutSizeEnum
  typeDataset: DatasetTypeEnum
  datasetModule: DatasetModuleInterface
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
  onClick?: (clil: number, lvl: number) => void
  level?: number
}) => {
  const { t } = useTranslation()

  const { color1, color2 } = SetColorConfig.find(
    (setColorConfig: SetColorConfigInterface) =>
      setColorConfig.value === setColor,
  )!

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={
        typeDataset === DatasetTypeEnum.AGE
          ? [t('InfoBulles.distributionByAgeRadar')]
          : [
              t('InfoBulles.clilRadar.1'),
              t('InfoBulles.clilRadar.2'),
              t('InfoBulles.clilRadar.3'),
            ]
      }
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.RADAR}
      shrink={(isPreview && isScreenSmall) || isOrganizeMenuOpen}
    >
      <RadarRechart
        valuesConfigurations={valuesConfigurations}
        primaryKey={primaryKey}
        dataset={dataset}
        color1={color1}
        color2={color2}
        innerRadius={40}
        compareGroup
        typeDataset={typeDataset}
        onClick={onClick}
        level={level}
        isPreview={isPreview}
      />
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputRadarRechart
