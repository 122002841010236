import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAccountsProfileService } from '../../core/api/services/bookmetrie/accounts/get-accounts-profile.service'
import { postAccountsChangePasswordService } from '../../core/api/services/bookmetrie/accounts/post-accounts-change-password.service'
import { setWebAppStateLoggedIn } from '../reducers/web-app.reducer'
import { RootState } from '../store'
import setAccessRefreshTokenLocalStorageUtil from '../../core/utils/set-access-refresh-token-local-storage.util'

export const settingsGetAccountsProfileActions = createAsyncThunk(
  'settings/get-accounts-profile',
  async (_, { getState }): Promise<any> => {
    try {
      const { settingsState } = getState() as RootState
      const { cancelTokenGetProfile } = settingsState
      const { data } = await getAccountsProfileService(
        cancelTokenGetProfile?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const settingsPostAccountsChangePasswordActions = createAsyncThunk(
  'settings/post-accounts-change-password',
  async (
    {
      currentPassword,
      password,
      confirmPassword,
    }: {
      currentPassword: string
      password: string
      confirmPassword: string
    },
    { dispatch },
  ): Promise<any> => {
    try {
      const { data } = await postAccountsChangePasswordService({
        currentPassword,
        password,
        confirmPassword,
      })

      const { access: accessToken, refresh: refreshToken } = data
      await setAccessRefreshTokenLocalStorageUtil({
        accessToken,
        refreshToken,
      })
      dispatch(setWebAppStateLoggedIn({ accessToken, refreshToken }))
      return data
    } catch (error) {
      throw new Error()
    }
  },
)
