import React, { ReactElement } from 'react'
import { nanoid } from 'nanoid'

const GlToggleIconButtons = ({
  buttons,
  buttonValueActive = undefined,
  handleOnClick,
}: {
  buttons: { icon: ReactElement; value: any }[]
  buttonValueActive?: any
  handleOnClick: (value: any) => void
}) => (
  <div className="flex flex-row">
    {buttons.map((button: { icon: ReactElement; value: any }, index) => (
      <button
        type="button"
        key={nanoid(5)}
        className={`
            relative
            border
            w-8
            h-8
            flex
            items-center
            justify-center
            ${
              (index === 0 && 'rounded-l') ||
              (index + 1 === buttons.length && 'rounded-r')
            }
            ${
              (button.value === buttonValueActive &&
                'bg-white border-white text-[#595959] ') ||
              'bg-[#595959] border-[#595959] text-white '
            }
           
          `}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          handleOnClick(button.value)
        }}
      >
        {button.icon}
      </button>
    ))}
  </div>
)

export default GlToggleIconButtons
