import { AnalyticOutputTypeEnum } from '../../../../../../../core/enums/analytic-output-type.enum'
import EditMenuDatasetModules from './EditMenuDatasetModules'
import { DatasetModuleInterface } from '../../../../../../../core/interface/analytics/dataset-module.interface'
import { ReadershipProfilePreviewComparisonInterface } from '../../../../../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import EditMenuPreview from './EditMenuPreview'
import EditMenuImage from './EditMenuImage'
import { AnalyticOutputInterface } from '../../../../../../../core/interface/analytics/analytic-output.interface'
import EditMenuTextBox from './EditMenuTextBox'

type Props = {
  outputType: AnalyticOutputTypeEnum
  title: string
  subTitle: string
  datasetModule?: DatasetModuleInterface
  previewModule?: ReadershipProfilePreviewComparisonInterface[]
  output?: AnalyticOutputInterface
}

const EditMenuView = ({
  outputType,
  title,
  subTitle,
  datasetModule,
  previewModule,
  output,
}: Props) => {
  switch (outputType) {
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_PREVIEW:
      return <EditMenuPreview previewModule={previewModule} />
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET:
      return (
        <EditMenuDatasetModules
          title={title}
          subTitle={subTitle}
          datasetModule={datasetModule}
        />
      )
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE:
      if (!output) return null
      return <EditMenuImage output={output} />
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX:
      if (!output) return null
      return <EditMenuTextBox output={output} />
    default:
      return null
  }
}

export default EditMenuView
