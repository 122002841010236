import React, { useCallback } from 'react'
import GlSpacer from '../../../share/GlSpacer'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import AnalyticDetailsTable from './table/AnalyticDetailsTable'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectAnalyticDetailsStateBookDetailsSelected } from '../../../../store/getters/analytics-details/analytic-details-v2.getters'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'
import { analyticDetailsV2StateGetBookDetailsData } from '../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { selectShareAnalyticDetailsStateAnalyticDetailsWorksMirrorsOutput } from '../../../../store/getters/share-analytic-details.getters'

const ShareAnalyticDetailsWorkMirrorList = ({
  bookEanIdHovered,
  handleCloseList,
}: {
  bookEanIdHovered: number | null
  handleCloseList: () => void
}) => {
  const onClickCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseList()
  }

  const worksMirrorsOutput = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetailsWorksMirrorsOutput,
  )

  const dispatch = useAppDispatch()
  const bookDetailsSelected = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelected,
  )
  const handleBookSelected = useCallback(
    (bookDetails: WorkMirrorBookInterface | null) => {
      void dispatch(analyticDetailsV2StateGetBookDetailsData({ bookDetails }))
    },
    [],
  )

  return (
    <div className="flex flex-col basis-1/3 gap-4">
      <div className="flex flex-row items-center justify-between">
        <GlSpacer />
        <button
          type="button"
          className="flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9] rounded"
          onClick={onClickCloseButton}
        >
          <CrossIcon className="w-4 h-4" />
          <span>Fermer</span>
        </button>
      </div>
      <div className="relative flex-auto flex flex-col rounded-xl bg-white">
        <AnalyticDetailsTable
          bookDetailsSelected={bookDetailsSelected}
          booksOutput={worksMirrorsOutput}
          isLoadingReloadOutput={false}
          handleBookSelected={handleBookSelected}
          bookEanIdHovered={bookEanIdHovered}
        />
      </div>
    </div>
  )
}

export default ShareAnalyticDetailsWorkMirrorList
