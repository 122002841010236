import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { PostSignInterface } from '../../core/interface/sign-in/post-sign.interface'
import { postSignInService } from '../../core/api/services/bookmetrie/authentication/post-sign-in.service'
import { setWebAppStateLoggedIn } from '../reducers/web-app.reducer'
import setAccessRefreshTokenLocalStorageUtil from '../../core/utils/set-access-refresh-token-local-storage.util'

export const postSignInAction = createAsyncThunk(
  'users/sign-in',
  async (
    { email, password }: PostSignInterface,
    { dispatch },
  ): Promise<any> => {
    try {
      const response = await postSignInService({ email, password })

      const { data } = response
      const { access: accessToken, refresh: refreshToken } = data
      await setAccessRefreshTokenLocalStorageUtil({
        accessToken,
        refreshToken,
      })
      dispatch(setWebAppStateLoggedIn({ accessToken, refreshToken }))
      return data
    } catch (error: any) {
      if (error instanceof AxiosError && error?.response?.data) {
        const err = error?.response?.data
        if (err.type === 'client_error' && err?.errors.length) {
          throw new Error(err.errors[0].code)
        }
      }
      throw new Error()
    }
  },
)
