import { useTranslation } from 'react-i18next'
import { AnalyticOutputImageModeEnum } from './analytic-output-image-mode.enum'

export enum AnalyticOutputLayoutSizeEnum {
  THIRD = 4,
  HALF = 6,
  TWO_THIRDS = 8,
  FULL = 12,
}

export const getSizeLabel = (
  size: AnalyticOutputLayoutSizeEnum,
  isDefault: boolean,
) => {
  let label = ''
  switch (size) {
    case AnalyticOutputLayoutSizeEnum.THIRD:
      label = 'third'
      break
    case AnalyticOutputLayoutSizeEnum.HALF:
      label = 'half'
      break
    case AnalyticOutputLayoutSizeEnum.TWO_THIRDS:
      label = 'twoThird'
      break
    case AnalyticOutputLayoutSizeEnum.FULL:
      label = 'full'
      break
    default:
      label = ''
  }

  return `pictureSizeOptions.${isDefault ? 'default.' : ''}${label}`
}

export const mapSizes = (
  sizes: AnalyticOutputLayoutSizeEnum[],
  defaultSize: AnalyticOutputLayoutSizeEnum | undefined,
) => {
  return sizes.map((size) => ({
    value: size,
    label: getSizeLabel(size, size === defaultSize),
  }))
}

export const mapImageSize = (
  imageMode: AnalyticOutputImageModeEnum,
  defaultSize: AnalyticOutputLayoutSizeEnum | undefined,
) => {
  const sizes = [AnalyticOutputLayoutSizeEnum.THIRD]
  if (imageMode !== AnalyticOutputImageModeEnum.PORTRAIT) {
    sizes.push(AnalyticOutputLayoutSizeEnum.HALF)
  }
  if (imageMode === AnalyticOutputImageModeEnum.LANDSCAPE) {
    sizes.push(
      AnalyticOutputLayoutSizeEnum.TWO_THIRDS,
      AnalyticOutputLayoutSizeEnum.FULL,
    )
  }
  return mapSizes(sizes, defaultSize)
}
