import React from 'react'
import styled from '@emotion/styled'

const DividerStyle = styled.div`
  width: 1px;
  height: ${({ height }: { height: string }) => height};
  background: #e3e3e3;
`

const GlVDivider = ({ height = '14px' }: { height?: string }) => (
  <DividerStyle height={height} />
)

export default GlVDivider
