import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import 'dayjs/locale/fr'

dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.locale('fr')
