import { createAsyncThunk } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

import { getCatalogsService } from '../../core/api/services/bookmetrie/get-catalogs.service'
import { RootState } from '../store'
import {
  WEBAPP_STATE_GET_READERSHIP_PROFILES_STATUS_ACTION,
  WEB_APP_GET_CATALOGS_ACTION,
  WEB_APP_GET_MY_SELF_ACTION,
  WEB_APP_GET_TOP_TAG_GROUPS,
  WEB_APP_GET_HOME_TOP_CATALOGS_ACTION,
  WEB_APP_GET_AUDIENCE_CATALOGS_ACTION,
} from '../prefix-actions.config'
import { getMySelfService } from '../../core/api/services/bookmetrie/get-my-self.service'
import callAsyncCallbackWithDelayUtil from '../../core/utils/call-callback-with-delay.util'
import { getTopTagGroupsService } from '../../core/api/services/bookmetrie/getTopTagGroupsService'
import { TagGroupInterface } from '../../core/interface/TagGroupInterface'
import { getReportByIdStatusService } from '../../core/api/services/bookmetrie/get-report-by-id-status.service'
import {
  AnalyticsProgressStatusInterface,
  ProgressStatusInterface,
} from '../interfaces/analytics-details/analytics-progress-status.interface'
import {
  setReadershipProfileGenerating,
  setShowSnackBar,
} from '../reducers/web-app.reducer'
import { getHomeTopCatalogsService } from '../../core/api/services/bookmetrie/get-home-top-catalogs.service'
import getAudienceCatalogService from '../../core/api/services/bookmetrie/private-reports/audience/get-audience-catalog.service'
import AudienceCatalogInterface from '../../core/interface/audience/audience-catalog.interface'

export const webAppGetCatalogsAction = createAsyncThunk(
  WEB_APP_GET_CATALOGS_ACTION,
  async (_, { getState }): Promise<any> => {
    try {
      const { webAppState } = getState() as RootState
      const { cancelTokenGetCatalogs } = webAppState

      const { data } = await getCatalogsService(cancelTokenGetCatalogs?.token)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const webAppGetAudienceCatalogsAction = createAsyncThunk(
  WEB_APP_GET_AUDIENCE_CATALOGS_ACTION,
  async (): Promise<AudienceCatalogInterface[]> => {
    try {
      const { data } = await getAudienceCatalogService()
      return data.results
    } catch (error) {
      throw new Error()
    }
  },
)

export const webAppGetHomeTopCatalogsAction = createAsyncThunk(
  WEB_APP_GET_HOME_TOP_CATALOGS_ACTION,
  async (_, { getState }): Promise<any> => {
    try {
      const { webAppState } = getState() as RootState
      const { cancelTokenGetCatalogs } = webAppState

      const { data } = await getHomeTopCatalogsService(
        cancelTokenGetCatalogs?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const webAppStateGetMySelfAction = createAsyncThunk(
  WEB_APP_GET_MY_SELF_ACTION,
  async (_, { getState }): Promise<any> => {
    const { webAppState } = getState() as RootState
    const { cancelTokenGetMySelf } = webAppState
    try {
      const { data } = await callAsyncCallbackWithDelayUtil(
        getMySelfService(cancelTokenGetMySelf?.token),
        500,
      )
      localStorage.setItem('mySelf', JSON.stringify(data))
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const webAppStateGetTopTagGroups = createAsyncThunk(
  WEB_APP_GET_TOP_TAG_GROUPS,
  async (_, { getState }): Promise<TagGroupInterface[]> => {
    const { webAppState } = getState() as RootState
    const { cancelTokenGetMySelf } = webAppState
    try {
      const { data } = await getTopTagGroupsService(cancelTokenGetMySelf?.token)
      localStorage.setItem('mySelf', JSON.stringify(data))
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const webAppStateGetReadershipProfilesStatus = createAsyncThunk(
  WEBAPP_STATE_GET_READERSHIP_PROFILES_STATUS_ACTION,
  async (
    _,
    { getState, dispatch },
  ): Promise<ProgressStatusInterface<AnalyticsProgressStatusInterface>[]> => {
    const { webAppState } = getState() as RootState
    try {
      let result = await Promise.all(
        webAppState.readershipProfileGenerating.map(
          (
            progressStatus: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
          ) => {
            if (progressStatus.data.generation_progress !== 1) {
              return getReportByIdStatusService({
                reportId: progressStatus.data.id,
              })
            }
            return { data: progressStatus.data }
          },
        ),
      )

      result = result.map(({ data }) => ({
        show:
          webAppState.readershipProfileGenerating.find(
            (
              progress: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
            ) => progress.data.id === data.id,
          ).data.status === data.status
            ? webAppState.readershipProfileGenerating.find(
                (
                  progress: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
                ) => progress.data.id === data.id,
              ).show
            : true,
        data,
      }))

      return result
    } catch (error) {
      throw new Error()
    }
  },
)
