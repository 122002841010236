import { ReactElement } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CrossIcon } from '../../../../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/icons/app/reset-icon.svg'
import { ReactComponent as DataRepresentationIcon } from '../../../../../../../assets/icons/app/title-dataset.icon.svg'
import GlHDivider from '../../../../../../share/GlHDivider'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import { useClickOutside } from '../../../../../../../core/hooks/useClickOutside.hook'

const WRAPPER_WIDTH = 380
const GAP = 16
const BUTTON_WIDTH = 72

const TextButtonStyle = styled.span`
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const InfoEditMenuWrapper = ({
  children,
  title,
  menuPos,
  handleCloseEdit,
  handleDeleteOutput,
  handleChangeOutput,
  withDelete = false,
  withChange = false,
  size,
}: {
  children: ReactElement
  title: string
  menuPos: number
  handleCloseEdit?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  handleChangeOutput?: () => void
  handleDeleteOutput?: () => void
  withDelete?: boolean
  withChange?: boolean
  size: AnalyticOutputLayoutSizeEnum
}) => {
  const { t } = useTranslation()
  const ref = useClickOutside(handleCloseEdit || (() => {}))

  const rightPosition = window.innerWidth - menuPos > window.innerWidth / 3.5

  const getPosition = () => {
    if (size === AnalyticOutputLayoutSizeEnum.FULL) {
      return { right: `${GAP + BUTTON_WIDTH}px` }
    }
    return rightPosition
      ? { right: `-${WRAPPER_WIDTH + GAP}px` }
      : { left: `-${WRAPPER_WIDTH + GAP}px` }
  }

  return (
    <div
      ref={ref}
      className="absolute flex flex-col w-[380px] z-[1001] bg-white p-4 gap-4 border border-[#e9e9e9] rounded-xl"
      style={{
        boxShadow:
          '0px 12px 32px 0px rgba(0, 0, 0, 0.20), 0px 8px 10px 0px rgba(0, 0, 0, 0.08)',
        ...getPosition(),
      }}
    >
      <div className="flex items-center justify-between self-stretch">
        <span className="font-bold">{title}</span>
        <CrossIcon
          className="w-[14px] h-[14px] cursor-pointer"
          onClick={handleCloseEdit}
        />
      </div>
      {children}
      {((withDelete && handleDeleteOutput) ||
        (withChange && handleChangeOutput)) && <GlHDivider />}
      {withChange && handleChangeOutput && (
        <div
          className="bg-[#252525] hover:bg-[#3B3B3B] flex h-8 w-full items-center pl-4 rounded gap-2 cursor-pointer"
          onClick={handleChangeOutput}
        >
          <DataRepresentationIcon className="text-white h-4 w-4" />
          <TextButtonStyle className="text-white">
            {t('commons.buttons.changeModule')}
          </TextButtonStyle>
        </div>
      )}
      {withDelete && handleDeleteOutput && (
        <div
          className="bg-white hover:bg-[#F7F7F7] flex h-8 w-full items-center pl-4 rounded gap-2 cursor-pointer border border-[#E9E9E9]"
          onClick={handleDeleteOutput}
        >
          <DeleteIcon className="text-[#F26363] h-4 w-4" />
          <TextButtonStyle className="text-[#F26363]">
            {t('commons.buttons.delete')}
          </TextButtonStyle>
        </div>
      )}
    </div>
  )
}

export default InfoEditMenuWrapper
