import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'

export const postReportByIdPurchaseService = (
  {
    reportId,
  }: {
    reportId: string
  },
  cancelToken?: CancelToken,
): Promise<AxiosResponse<void>> =>
  apiInstance.post(ApiPathConfig.REPORT_BY_ID_PURCHASE(reportId), {
    cancelToken,
  })
