import IGotIconActive from '../../../assets/icons/tags/i-got-it-icon-active.svg'
import IGotIconInactive from '../../../assets/icons/tags/i-got-it-icon-inactive.svg'
import { TagFilterEnum } from '../../enums/tag-filter.enum'
import StarIconActive from '../../../assets/icons/tags/star-icon-active.svg'
import StarIconInactive from '../../../assets/icons/tags/star-icon-inactive.svg'
import WhiteIconActive from '../../../assets/icons/tags/white-list-icon-active.svg'
import WhiteIconInactive from '../../../assets/icons/tags/white-list-icon-inactive.svg'
import ReadIconActive from '../../../assets/icons/tags/read-icon-active.svg'
import ReadIconInactive from '../../../assets/icons/tags/read-icon-inactive.svg'
import LikeIconActive from '../../../assets/icons/tags/like-icon-active.svg'
import LikeIconInactive from '../../../assets/icons/tags/like-icon-inactive.svg'

export interface TagInterface {
  label: string
  icon: {
    active: string
    inactive: string
  }
  color: string
  code: TagFilterEnum
}
export const TagsFilterConfig: TagInterface[] = [
  {
    label: "J'AI",
    icon: {
      active: IGotIconActive,
      inactive: IGotIconInactive,
    },
    // color: '#7ab9ff',
    color: '#7ab9ff',
    code: TagFilterEnum.TAG_IS_OWN,
  },
  {
    label: 'JE VEUX',
    icon: {
      active: StarIconActive,
      inactive: StarIconInactive,
    },
    color: '#ffae4a',
    code: TagFilterEnum.TAG_IS_WISH,
  },
  {
    label: 'JE LIS',
    icon: {
      active: WhiteIconActive,
      inactive: WhiteIconInactive,
    },
    color: '#d59aed',
    code: TagFilterEnum.TAG_IS_READING,
  },
  {
    label: "J'AI LU",
    icon: {
      active: ReadIconActive,
      inactive: ReadIconInactive,
    },
    color: '#89da8e',
    code: TagFilterEnum.TAG_IS_READ,
  },
  {
    label: "J'AIME",
    icon: {
      active: LikeIconActive,
      inactive: LikeIconInactive,
    },
    color: '#f97e60',
    code: TagFilterEnum.TAG_IS_LIKE,
  },
]
