import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import ClilReferencesMappingConfig from '../../../core/config/clil-refs-mapping.config'
import { clilReferencesConfig } from '../../../core/config/clil-references.config'

const BadgeStyle = styled.div<{
  smallFontSize?: boolean
  smallWidth?: boolean
  autoWidth?: boolean
}>`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ smallFontSize }) => (smallFontSize ? '12px' : '14px')};
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #252525;
  text-transform: lowercase;
  width: ${({ smallWidth, autoWidth }) =>
    autoWidth ? 'auto' : smallWidth ? '112px' : '220px'};
  height: 24px;
  overflow: hidden;
`

const TooltipContainer = styled.div`
  z-index: 300;
  position: fixed;
  background-color: ${({
    color,
  }: {
    color?: string
    top?: number
    right?: number
  }) => color || '#252525'};
  color: #252525;
  border: 1px solid #252525;
  top: ${({ top }: { color?: string; top?: number; right?: number }) => top}px;
  left: ${({ right }: { color?: string; top?: number; right?: number }) =>
    right}px;
  max-width: 300px;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  &::first-letter {
    text-transform: uppercase;
  }
`

const LabelStyle = styled.span<{ smallWidth?: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ smallWidth }) => (smallWidth ? '112px' : 'auto')};
  &::first-letter {
    text-transform: uppercase;
  }
`

const IconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ color }: { color: string }) => color};
`

interface GlBadgeClilV2Props {
  id: string
  smallFontSize?: boolean
  smallWidth?: boolean
  autoWidth?: boolean
  fontColor?: string
  noBackground?: boolean
}

const GlBadgeClilV2 = ({
  id,
  smallFontSize,
  smallWidth,
  autoWidth = false,
  fontColor = '#252525',
  noBackground = false,
}: GlBadgeClilV2Props) => {
  const {
    libelle,
    color,
    1: level1,
  } = clilReferencesConfig[id] || clilReferencesConfig['0000']
  const { t } = useTranslation()
  const clilRefMap =
    ClilReferencesMappingConfig[level1.id] ||
    ClilReferencesMappingConfig['0000']

  const [toolTipPosition, setToolTipPosition] = useState<{
    top: number
    right: number
  }>({
    top: 0,
    right: 0,
  })

  const [canDisplayTooltip, setCanDisplayTooltip] = useState(false)
  const [displayTooltip, setDisplayTooltip] = useState(false)
  const parentRef = useRef<any>(null)
  const childRef = useRef<any>(null)

  useEffect(() => {
    const childElement = childRef.current
    const parentElement = parentRef.current
    setCanDisplayTooltip(
      Number(childElement?.scrollWidth) + 20 >= parentElement?.scrollWidth,
    )
  }, [])

  return (
    (libelle && libelle !== 'SANS CATÉGORIE' && (
      <BadgeStyle
        ref={parentRef}
        smallFontSize={smallFontSize}
        smallWidth={smallWidth}
        autoWidth={autoWidth}
        className="flex flex-row items-center rounded gap-2 relative"
        style={{
          background: noBackground ? 'transparent' : `${color}1A`,
          color: fontColor,
        }}
        onMouseEnter={() => {
          if (canDisplayTooltip) {
            setToolTipPosition({
              top: Number(parentRef.current.getBoundingClientRect().top),
              right:
                Number(parentRef.current.getBoundingClientRect().x) +
                Number(parentRef.current.getBoundingClientRect().height),
            })
            setDisplayTooltip(true)
          }
        }}
        onMouseLeave={() => {
          setDisplayTooltip(false)
        }}
      >
        <IconStyle color={color}>
          <div className="scale-125">{clilRefMap.icon}</div>
        </IconStyle>
        <LabelStyle smallWidth={smallWidth} className="flex-1" ref={childRef}>
          {t(libelle)}
        </LabelStyle>
        {(displayTooltip && (
          <TooltipContainer
            color={color}
            top={toolTipPosition.top}
            right={toolTipPosition.right}
          >
            {t(libelle)}
          </TooltipContainer>
        )) ||
          null}
      </BadgeStyle>
    )) ||
    null
  )
}

export default GlBadgeClilV2
