import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { RootState } from '../store'
import {
  ANALYTICS_DASHBOARD_GET_PRIVATE_REPORTS,
  ANALYTICS_DASHBOARD_GET_PRIVATE_REPORTS_NEXT,
} from '../prefix-actions.config'
import getPrivateReportsService from '../../core/api/services/bookmetrie/private-reports/get-private-reports.service'
import getPrivateReportsNextService from '../../core/api/services/bookmetrie/private-reports/get-private-reports-next.service'

export const analyticsDashboardGetPrivateReportsNext = createAsyncThunk(
  ANALYTICS_DASHBOARD_GET_PRIVATE_REPORTS_NEXT,
  async (
    { nextApiPath }: { nextApiPath: string },
    { dispatch, getState },
  ): Promise<PaginationResponseInterface<ReportLightResponseInterface>> => {
    try {
      const { reportsDashboardState } = getState() as RootState
      const { cancelTokenInternalReportsNext } = reportsDashboardState

      const { data } = await getPrivateReportsNextService(
        nextApiPath,
        cancelTokenInternalReportsNext?.token,
      )
      if (data.next) {
        void dispatch(
          analyticsDashboardGetPrivateReportsNext({
            nextApiPath: data.next,
          }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDashboardGetPrivateReports = createAsyncThunk(
  ANALYTICS_DASHBOARD_GET_PRIVATE_REPORTS,
  async (
    _,
    { dispatch },
  ): Promise<PaginationResponseInterface<ReportLightResponseInterface>> => {
    try {
      const { data } = await getPrivateReportsService({
        page_size: 50,
        ordering: '-modified',
      })
      if (data.next) {
        void dispatch(
          analyticsDashboardGetPrivateReportsNext({
            nextApiPath: data.next,
          }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)
