import React, { memo } from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as StarPopularityIcon } from '../../../../assets/icons/app/star-popularity.icon.svg'
import GlSpacer from '../../../share/GlSpacer'
import GlLoader from '../../../share/GlLoader'
import { BookInterface } from '../../../../core/interface/book.interface'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilV2 from '../../../share/badge/GlBadgeClilV2'
import { AuthorInterface } from '../../../../core/interface/author.interface'

const AuthorStyle = styled.div`
  color: #fff;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

const AuthorStyleCard = styled.div`
  color: #666;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const LabelStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 4px;
  background: #e9e9e9;
  border-radius: 4px;
`

const HeaderStyle = styled.div`
  color: #3b3b3b;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 8px;
`

const CardStyle = styled.div`
  min-width: 225px;
  max-width: 225px;
  min-height: 96px;
  max-height: 96px;
  background: white;
  border-radius: 4px;
`

const TitleStyle = styled.div`
  color: black;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 143px;
`

const AuthorDetailsCard = ({
  isLoadingGetAuthorDetails,
  authorDetails,
  handleCloseAuthorDetails,
}: {
  isLoadingGetAuthorDetails: boolean
  authorDetails: AuthorInterface
  handleCloseAuthorDetails: (returnToList?: boolean) => void
}) => {
  const { t } = useTranslation()
  const onClickCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseAuthorDetails(true)
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  return (
    <div
      className="flex flex-col basis-1/3 gap-4 h-full"
      style={{
        maxWidth: 506,
      }}
    >
      <div className="flex flex-row items-center justify-between">
        <GlSpacer />
        <button
          type="button"
          className="flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9] rounded"
          onClick={onClickCloseButton}
        >
          <CrossIcon className="w-4 h-4" />
          <span>{t('commons.buttons.close')}</span>
        </button>
      </div>
      <div className="relative flex-auto flex flex-col rounded-xl bg-white">
        <div className="flex-none flex flex-col rounded-t-xl bg-[#444444] shadow-2xl">
          {isLoadingGetAuthorDetails || !authorDetails ? (
            <div
              className="flex flex-col items-center justify-center"
              style={{
                height: 120,
              }}
            >
              <GlLoader />
            </div>
          ) : (
            <div key={`author-details-${authorDetails.id}`}>
              <div
                className="flex flex-col justify-between p-4  rounded-t-[8px] gap-2"
                style={{ minHeight: 120 }}
              >
                <AuthorStyle>{authorDetails.name}</AuthorStyle>
                <div className="flex flex-row items-center flex-wrap gap-1.5">
                  {authorDetails.categories
                    .filter(
                      (category) =>
                        category !== '999999' && category !== '0000',
                    )
                    .map((category) => (
                      <div key={`author-clil-${category}`}>
                        <GlBadgeClilV2 id={category} fontColor="white" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col p-4 gap-4 bg-white rounded-b-xl h-full">
          {isLoadingGetAuthorDetails || !authorDetails ? (
            <div
              className="flex flex-col items-center justify-center"
              style={{
                height: 120,
              }}
            >
              <GlLoader />
            </div>
          ) : (
            <div className="flex flex-col gap-4 h-full">
              <LabelStyle className="flex items-center justify-between">
                <div>{t('popularity')}</div>
                <div className="flex flex-row items-center justify-center gap-2">
                  {Array(5)
                    .fill(null)
                    .map((_: null, index: number) => (
                      <StarPopularityIcon
                        key={`star-popularity-${index + 1}`}
                        style={{
                          color:
                            authorDetails.popularity >= index + 1
                              ? '#252525'
                              : '#bebebe',
                        }}
                      />
                    ))}
                </div>
              </LabelStyle>
              <HeaderStyle>
                {t('webApp.ranks.authorDetails.worksAuthor')}
              </HeaderStyle>
              <div className="flex flex-col flex-auto relative">
                <div className="absolute top-0 left-0 h-full w-full">
                  <PerfectScrollbar className="relative h-full flex flex-row flex-wrap gap-2 content-start">
                    {authorDetails.works.map((book: BookInterface) => (
                      <CardStyle
                        key={`similar-title-${book.id_ean}`}
                        className="flex flex-row  m-1 p-2 gap-2"
                        style={{
                          boxShadow:
                            ' 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.08)',
                        }}
                      >
                        <img
                          src={book.imgs || getBookCoverByClil(book.id_ean)}
                          alt=""
                          style={{
                            width: 60,
                            height: 80,
                            borderRadius: 2,
                          }}
                        />
                        <div className="flex flex-col gap-1 items-start justify-start">
                          <TitleStyle>{book.title}</TitleStyle>
                          <AuthorStyleCard>
                            {book.authors.join(', ')}
                          </AuthorStyleCard>
                        </div>
                      </CardStyle>
                    ))}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(AuthorDetailsCard)
