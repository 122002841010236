import styled from '@emotion/styled'
import React, { useState, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LittleArrowBottomArrowIcon } from '../../../../assets/icons/app/bottom.icon.svg'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import { useAppSelector } from '../../../../store/hooks'
import { selectWebAppStateTagGroups } from '../../../../store/getters/web-app.getters'
import { TagGroupInterface } from '../../../../core/interface/TagGroupInterface'

const TagsFilterContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #252525;
  width: 210px;
  height: 32px;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #252525;
  padding: 8px;
`

const MenuStyle = styled.div`
  z-index: 35;
  position: absolute;
  top: 36px;
  left: 0;
  height: ${({ open }: { open: boolean }) => (open ? 'auto' : '0')};
  max-height: ${({ open }: { open: boolean }) => (open ? '334px' : '0')};
  visibility: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }: { open: boolean }) => (open ? '1' : '0')};
  background: white;
  width: 100%;
  border-radius: 4px;
  -webkit-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
`

const DynamicsResultsTagsFilters = ({
  tagGroupSelected,
  handleChangeQuery,
  menuKey,
  menuOpen,
  handleMenuOpen,
}: {
  tagGroupSelected: string
  handleChangeQuery: (key: any, value: any) => void
  menuKey: string
  menuOpen: string | null
  handleMenuOpen: (value: string | null) => void
}) => {
  const tagGroups = useAppSelector(selectWebAppStateTagGroups)
  const { t } = useTranslation()
  let timer: NodeJS.Timeout | null = null
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen === menuKey) {
        handleMenuOpen(null)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer, menuOpen])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    handleMenuOpen(menuKey)
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  return (
    <TagsFilterContainerStyle
      className="flex flex-col relative bg-white"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <LabelStyle className="w-full h-full flex flex-row justify-between items-center gap-2">
        <div className="flex justify-start items-center gap-2">
          <span>
            {
              tagGroups.find(
                (tagGroup: TagGroupInterface) =>
                  tagGroup.id === tagGroupSelected,
              )?.name
            }
          </span>
        </div>
        <LittleArrowBottomArrowIcon className="text-[#b2b2b2]" />
      </LabelStyle>
      <MenuStyle
        open={menuOpen === menuKey}
        className="shadow-2xl z-30"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {tagGroups.map((tagGroup: TagGroupInterface, index: number) => (
          <div
            key={`tag-group-filter-${tagGroup.id}`}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault()
              handleChangeQuery('tag_group', tagGroup.id)
            }}
            className={`
                flex
                justify-between
                h-7
                items-center
                px-3
                py-[4.5px]
                ${(tagGroupSelected === tagGroup.id && 'bg-[#e3e3e3]') || ''}
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
                ${(index === 0 && 'rounded-t-[4px]') || ''}
              `}
          >
            <div className="flex flex-row gap-2 items-center">
              <span>{t(tagGroup.name)}</span>
            </div>
            {(tagGroupSelected === tagGroup.id && <SelectedIcon />) || null}
          </div>
        ))}
      </MenuStyle>
    </TagsFilterContainerStyle>
  )
}

export default memo(DynamicsResultsTagsFilters)
