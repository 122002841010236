import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../enums/analytic-output-layout-size.enum'

export const postTextBoxesOutputService = (
  reportId: string,
  inputId: string,
  textBoxesObject: {
    title: string
    text: string
    layout_size: AnalyticOutputLayoutSizeEnum
  },
  cancelToken?: CancelToken,
) =>
  apiInstance.post(
    ApiPathConfig.TEXT_BOXES_OUTPUT(reportId, inputId),
    textBoxesObject,
    {
      cancelToken,
    },
  )
