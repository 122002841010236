import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import '../styles/_shared.scss'
import WebAppLayout from '../components/layouts/WebAppLayout'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { reinitializeWebAppState } from '../store/reducers/web-app.reducer'
import {
  webAppGetAudienceCatalogsAction,
  webAppGetHomeTopCatalogsAction,
  webAppStateGetMySelfAction,
  webAppStateGetReadershipProfilesStatus,
  webAppStateGetTopTagGroups,
} from '../store/actions/web-app.actions'
import {
  selectWebAppStateMySelfHasPrivateReportsAccess,
  selectWebAppStateReadershipProfileGenerating,
} from '../store/getters/web-app.getters'
import {
  AnalyticsProgressStatusInterface,
  ProgressStatusInterface,
} from '../store/interfaces/analytics-details/analytics-progress-status.interface'

const WebApp = () => {
  const dispatch = useAppDispatch()

  const [dataLoad, setDataLoad] = useState<boolean>(false)

  const hasPrivateReportsAccess = useAppSelector(
    selectWebAppStateMySelfHasPrivateReportsAccess,
  )

  const tagGroupCall = async () => {
    await dispatch(webAppStateGetTopTagGroups())
  }
  useEffect(() => {
    if (!dataLoad) {
      void dispatch(webAppGetHomeTopCatalogsAction())
      void dispatch(webAppStateGetMySelfAction())
      void tagGroupCall()
      setDataLoad(true)
    }
    return () => {
      dispatch(reinitializeWebAppState())
    }
  }, [dataLoad])

  const readershipProfileGenerating = useAppSelector(
    selectWebAppStateReadershipProfileGenerating,
  )
  let interval: NodeJS.Timer | null = null

  useEffect(() => {
    if (
      readershipProfileGenerating.filter(
        (progress: ProgressStatusInterface<AnalyticsProgressStatusInterface>) =>
          progress.data.generation_progress !== 1,
      ).length
    ) {
      interval = setInterval(() => {
        void dispatch(webAppStateGetReadershipProfilesStatus())
      }, 5000)
      return () => {
        if (interval) {
          clearInterval(interval)
        }
      }
    }
  }, [readershipProfileGenerating])

  useEffect(() => {
    if (hasPrivateReportsAccess) {
      void dispatch(webAppGetAudienceCatalogsAction())
    }
  }, [hasPrivateReportsAccess])

  return (
    <WebAppLayout>
      <main className="flex-auto flex flex-col">
        <Outlet />
      </main>
    </WebAppLayout>
  )
}

export default WebApp
