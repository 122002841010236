import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'
import { DatasetModuleInterface } from '../../../../../interface/analytics/dataset-module.interface'

export const putDatasetModulesService = (
  reportId: string,
  datasetId: string,
  datasetModule: DatasetModuleInterface,
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.DATASET_MODULES_BY_ID(reportId, datasetId, datasetModule.id),
    datasetModule,
    {
      cancelToken,
    },
  )
