import React, { useMemo, useState } from 'react'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import {
  AnalyticOutputImageCombinationEnum,
  AnalyticOutputImageCombinationInterface,
  AnalyticOutputImageInterface,
  AnalyticOutputInterface,
} from '../../../../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import FakeImage from '../../../../../assets/images/fake-image.png'
import { AnalyticOutputImageModeEnum } from '../../../../../core/enums/analytic-output-image-mode.enum'
import InfoEditButtons from './chart/chartComponents/InfoEditButtons'
import {
  AnalyticOutputLayoutFullHeightEnum,
  AnalyticOutputLayoutShrinkHeightEnum,
} from '../../../../../core/enums/analytic-output-layout-full-height.enum'

const AnalyticsOutputImage = ({
  output,
  display,
  handleAnalyticsDeleteOutput,
  isOrganizeMenuOpen,
}: {
  output: AnalyticOutputInterface
  display: AnalyticOutputLayoutSizeEnum
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  isOrganizeMenuOpen: boolean
}) => {
  const [hoverInfoButton, setHoverInfoButton] = useState(false)
  const [hoverOutput, setHoverOutput] = useState(false)

  function handleMouseEnterOutput(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    event.preventDefault()
    setHoverOutput(true)
  }
  function handleMouseExitOutput(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    event.preventDefault()
    setHoverOutput(false)
  }

  function getOutputImage(
    images: AnalyticOutputImageInterface,
    layoutSize: AnalyticOutputLayoutSizeEnum | undefined,
  ) {
    let image
    if (
      layoutSize &&
      `${images.image_mode}_${layoutSize}` in AnalyticOutputImageCombinationEnum
    ) {
      image =
        images[
          `${images.image_mode}_${layoutSize}` as keyof AnalyticOutputImageCombinationInterface
        ]
    } else {
      image = images.card
    }
    return image || images.card
  }

  const height = useMemo(() => {
    const heightEnum = isOrganizeMenuOpen
      ? AnalyticOutputLayoutShrinkHeightEnum
      : AnalyticOutputLayoutFullHeightEnum

    switch (output.image?.image_mode) {
      case AnalyticOutputImageModeEnum.LANDSCAPE:
        return `${heightEnum.SMALL}px`
      case AnalyticOutputImageModeEnum.PORTRAIT:
      case AnalyticOutputImageModeEnum.SQUARE:
        return `${heightEnum.MEDIUM}px`
      default:
        return `${heightEnum.SMALL}px`
    }
  }, [output.image?.image_mode, isOrganizeMenuOpen])

  const backgroundImage = useMemo(() => {
    return `url(${
      output.image?.card
        ? getOutputImage(output.image, output.layout_size)
        : FakeImage
    })`
  }, [output.image])

  return (
    <div
      className={`grid-item rounded-xl  border border-[#E9E9E9] relative ${
        hoverInfoButton ? 'bg-[#25252580] bg-blend-multiply' : ''
      }`}
      style={{
        ...(display === AnalyticOutputLayoutSizeEnum.FULL
          ? {
              width: '100%',
            }
          : {
              width: `calc(100% * ${display} / ${AnalyticOutputLayoutSizeEnum.FULL} - 12px)`,
            }),
        ...{ height },
      }}
      onMouseEnter={handleMouseEnterOutput}
      onMouseLeave={handleMouseExitOutput}
      id={output.id}
    >
      <div className="p-4 absolute w-full right-0 z-40">
        <InfoEditButtons
          outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE}
          hoverInfoButton={hoverInfoButton}
          setHoverInfoButton={setHoverInfoButton}
          output={output}
          showInfoIcon={false}
          showIcon={hoverOutput}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          size={display}
        />
      </div>
      <div className="w-full h-full relative rounded-xl overflow-hidden">
        {output.image?.image_mode === AnalyticOutputImageModeEnum.SQUARE &&
          output.image?.card && (
            <div
              className="w-full h-full bg-cover blur-3xl	bg-center bg-no-repeat"
              style={{ backgroundImage }}
            />
          )}
        <div
          className={`absolute w-full h-full	bg-center bg-no-repeat ${
            hoverInfoButton ? 'bg-[#25252580] bg-blend-multiply' : ''
          }`}
          style={{
            backgroundSize:
              output.image?.image_mode === AnalyticOutputImageModeEnum.SQUARE &&
              output.image?.card
                ? 'contain'
                : 'cover',
            aspectRatio:
              output.image?.image_mode === AnalyticOutputImageModeEnum.SQUARE
                ? '1/1'
                : 'auto',
            top: 0,
            backgroundImage,
          }}
        />
      </div>
    </div>
  )
}

export default AnalyticsOutputImage
