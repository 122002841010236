import { TrendInterface } from '../interface/rank/trend.interface'
import { RanksResponseInterface } from '../interface/rank/ranks-response.interface'

export class RankDTOModel implements Partial<TrendInterface> {
  rank: number

  progress: number | null

  title: string

  isbn: number

  miniature: string

  authors: string[]

  releaseDate: string

  segment1: string

  segment2: string

  editor: string

  format: string

  constructor(value: RanksResponseInterface) {
    this.rank = value.rank
    this.progress = value.delta_rank
    this.title = value.title
    this.isbn = value.id_ean
    this.miniature = value.imgs
    this.authors = value.authors
    this.releaseDate = value.publishing_date
    this.editor = value.edition
    this.segment1 = String(value.clil1)
    this.segment2 = String(value.clil2)
    this.format = value.format
  }
}
