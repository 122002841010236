const RadarActiveDot = ({ cx, cy, fill, index }: any) => {
  return (
    <svg key={index}>
      <circle cx={cx} cy={cy} r="8" fill={fill} />
      <circle cx={cx} cy={cy} r="3" fill="white" />
    </svg>
  )
}

export default RadarActiveDot
