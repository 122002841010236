import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { uniq } from 'lodash'
import styled from '@emotion/styled'
import { DatasetTypeEnum } from '../../../../../core/enums/dataset-type.enum'
import clilRefsMappingConfig from '../../../../../core/config/clil-refs-mapping.config'
import { DatasetInterface } from '../../../../../core/interface/analytics/dataset.interface'

const LegendLabelStyle = styled.div`
  text-transform: lowercase;
  color: #252525;
  &::first-letter {
    text-transform: uppercase;
  }
`

const AnalyticsOutputHorizontalClilLegend = ({
  dataset,
  typeDataset,
}: {
  dataset: DatasetInterface[]
  typeDataset: DatasetTypeEnum
}) => {
  const { t } = useTranslation()

  return (
    ([
      DatasetTypeEnum.WORKS_MIRROR,
      DatasetTypeEnum.MOST_FREQUENT,
      DatasetTypeEnum.OVERREPRESENTED,
    ].includes(typeDataset) &&
      uniq(dataset.map((item) => item.clil1)) && (
        <div className="flex justify-center flex-wrap gap-2">
          {uniq(dataset.map((item) => item.clil1)).map((category) => {
            if (!category) return
            const { color, name } = clilRefsMappingConfig[category]

            return (
              <div className="flex flex-row items-center gap-2" key={category}>
                <div
                  style={{ backgroundColor: color }}
                  className="h-4 w-4 rounded-full"
                />
                <LegendLabelStyle>{t(name)}</LegendLabelStyle>
              </div>
            )
          })}
        </div>
      )) ||
    null
  )
}

export default memo(AnalyticsOutputHorizontalClilLegend)
