import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import GlReadMore from '../../../share/GlReadMore'

const ResumeStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  // text-transform: uppercase;
  color: #595959;
`
interface Props {
  description: string
}

const DynamicsResultsBookDetailsSummary = ({ description }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="my-2">
      <ResumeStyle className="mb-2">
        {t('webApp.bookDetail.comments.resume')}
      </ResumeStyle>
      <div className="text-xs">
        <GlReadMore content={description} nbShowsCharacters={120} />
      </div>
    </div>
  )
}
export default DynamicsResultsBookDetailsSummary
