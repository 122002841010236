import React from 'react'
import styled from '@emotion/styled'
import { getProviderIconUtil } from '../../../../core/utils/get-provider-icon.util'

const ProviderContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #929292;
  font-size: 10px;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
`

const DynamicsResultsBookDetailsProvider = ({
  provider,
}: {
  provider: string | null
}) => {
  return (
    <ProviderContainerStyle className="flex flex-row items-center justify-center gap-2">
      <div>Données bibliographiques fournies par</div>
      {getProviderIconUtil(provider)}
    </ProviderContainerStyle>
  )
}

export default DynamicsResultsBookDetailsProvider
