import { createAsyncThunk } from '@reduxjs/toolkit'
import apiInstance from '../../core/api/backend-api.setup'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { ApiPathConfig } from '../../core/config/ApiPathConfig'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { getAnalyticsLightService } from '../../core/api/services/bookmetrie/analytics/get-analytics-light.service'
import { getAnalyticsLightNextService } from '../../core/api/services/bookmetrie/analytics/get-analytics-light-next.service'
import { RootState } from '../store'
import { ANALYTICS_DASHBOARD_GET_INTERNAL_REPORTS } from '../prefix-actions.config'

export const analyticsDashboardGetInternalReportsNext = createAsyncThunk(
  'reports-dashboard/get-internal-reports-next',
  async (
    { nextApiPath }: { nextApiPath: string },
    { dispatch, getState },
  ): Promise<PaginationResponseInterface<ReportLightResponseInterface>> => {
    try {
      const { reportsDashboardState } = getState() as RootState
      const { cancelTokenInternalReportsNext } = reportsDashboardState

      const { data } = await getAnalyticsLightNextService(
        nextApiPath,
        cancelTokenInternalReportsNext?.token,
      )
      if (data.next) {
        void dispatch(
          analyticsDashboardGetInternalReportsNext({ nextApiPath: data.next }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDashboardGetInternalReports = createAsyncThunk(
  ANALYTICS_DASHBOARD_GET_INTERNAL_REPORTS,
  async (
    _,
    { dispatch },
  ): Promise<PaginationResponseInterface<ReportLightResponseInterface>> => {
    try {
      const { data } = await getAnalyticsLightService({
        publicValue: false,
        page_size: 50,
        ordering: '-modified',
      })
      if (data.next) {
        void dispatch(
          analyticsDashboardGetInternalReportsNext({ nextApiPath: data.next }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const reportDashboardGetRecommendedReports = createAsyncThunk(
  'reports-dashboard/get-recommended-reports',
  async (): Promise<
    PaginationResponseInterface<ReportLightResponseInterface>
  > => {
    try {
      const { data } = await getAnalyticsLightService({
        publicValue: true,
        page_size: 50,
        ordering: '-modified',
      })
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const reportDashboardDeleteReportById = createAsyncThunk(
  'reports-dashboard/delete-report-by-id',
  async ({ reportId }: { reportId: string }): Promise<any> => {
    try {
      const { data } = await apiInstance.delete(
        ApiPathConfig.REPORT_DETAIL_BY_ID(reportId),
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)
