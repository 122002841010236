import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import {
  getBookGetService,
  GetBookGetTypeEnum,
} from '../../../../../../core/api/services/bookmetrie/get-book-get.service'

interface UseLoadEditionsByTitleState {
  isLoading: boolean
  editions: BookInterface[]
}

interface UseLoadEditionsByTitleHook {
  state: UseLoadEditionsByTitleState
}

const useLoadEditionsByTitleHook = ({
  glId,
  callbackAfterLoad,
}: {
  glId: string
  callbackAfterLoad: (books: BookInterface[]) => void
}): UseLoadEditionsByTitleHook => {
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editions, setEditions] = useState<BookInterface[]>([])
  const getEditionsByEanId = (glId: string) => {
    setIsLoading(true)
    if (cancelToken) {
      cancelToken.cancel()
    }
    const newCancelToken = axios.CancelToken.source()
    setCancelToken(newCancelToken)
    getBookGetService({ type: GetBookGetTypeEnum.GLID, id: glId })
      .then(({ data }) => {
        setEditions(data)
        callbackAfterLoad(data.map((edition: BookInterface) => edition))
      })
      .catch(() => {
        setEditions([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getEditionsByEanId(glId)
  }, [glId])

  return {
    state: {
      isLoading,
      editions,
    },
  }
}

export default useLoadEditionsByTitleHook
