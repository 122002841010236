import { AnalyticsChartTypeEnum } from './analytics-chart-type.enum'
import { AnalyticOutputTypeEnum } from './analytic-output-type.enum'

export enum AnalyticOutputLayoutFullHeightEnum {
  SMALL = 280,
  MEDIUM = 576,
  LARGE = 872,
}

export enum AnalyticOutputLayoutShrinkHeightEnum {
  SMALL = 204,
  MEDIUM = 424,
  LARGE = 644,
}

enum AdditionalChartTypeEnum {
  CONSTELLATION_CHART_TABULAR = 'CONSTELLATION_CHART_TABULAR',
  SCATTER_CHART_TABULAR = 'SCATTER_CHART_TABULAR',
}

export enum SizableHeightAnalyticOutput {
  TEXTBOX = AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX,
  VERTICAL_BAR = AnalyticsChartTypeEnum.VERTICAL_BAR_CHART,
  HORIZONTAL_BAR = AnalyticsChartTypeEnum.HORIZONTAL_BAR_CHART,
  VERTICAL_STACKED_BAR = AnalyticsChartTypeEnum.VERTICAL_STACKED_BAR_CHART,
  TREEMAP = AnalyticsChartTypeEnum.TREEMAP,
  RADAR = AnalyticsChartTypeEnum.RADAR,
  TABULAR = AnalyticsChartTypeEnum.TABULAR,
  SCATTER = AnalyticsChartTypeEnum.SCATTER_PLOT,
  SCATTER_TABULAR = AdditionalChartTypeEnum.SCATTER_CHART_TABULAR,
  CONSTELLATION = AnalyticsChartTypeEnum.CONSTELLATION,
  CONSTELLATION_TABULAR = AdditionalChartTypeEnum.CONSTELLATION_CHART_TABULAR,
}

export const AnalyticOutputLayoutHeightMap: {
  [key in SizableHeightAnalyticOutput]: {
    full: AnalyticOutputLayoutFullHeightEnum
    shrink: AnalyticOutputLayoutShrinkHeightEnum
  }
} = {
  [SizableHeightAnalyticOutput.TEXTBOX]: {
    full: AnalyticOutputLayoutFullHeightEnum.SMALL,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.SMALL,
  },
  [SizableHeightAnalyticOutput.VERTICAL_BAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.SMALL,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.SMALL,
  },
  [SizableHeightAnalyticOutput.HORIZONTAL_BAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.SMALL,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.SMALL,
  },
  [SizableHeightAnalyticOutput.VERTICAL_STACKED_BAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.SMALL,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.SMALL,
  },
  [SizableHeightAnalyticOutput.TREEMAP]: {
    full: AnalyticOutputLayoutFullHeightEnum.MEDIUM,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.MEDIUM,
  },
  [SizableHeightAnalyticOutput.RADAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.MEDIUM,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.MEDIUM,
  },
  [SizableHeightAnalyticOutput.TABULAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.MEDIUM,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.MEDIUM,
  },
  [SizableHeightAnalyticOutput.SCATTER]: {
    full: AnalyticOutputLayoutFullHeightEnum.MEDIUM,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.MEDIUM,
  },
  [SizableHeightAnalyticOutput.SCATTER_TABULAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.LARGE,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.LARGE,
  },
  [SizableHeightAnalyticOutput.CONSTELLATION]: {
    full: AnalyticOutputLayoutFullHeightEnum.MEDIUM,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.MEDIUM,
  },
  [SizableHeightAnalyticOutput.CONSTELLATION_TABULAR]: {
    full: AnalyticOutputLayoutFullHeightEnum.LARGE,
    shrink: AnalyticOutputLayoutShrinkHeightEnum.LARGE,
  },
}
