import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import {
  AnalyticOutputInterface,
  DatasetOutputInterface,
} from '../../../../../core/interface/analytics/analytic-output.interface'
import AnalyticsOutputTitle from './AnalyticsOutputTitle'
import ReadershipProfilePreviewOutput from './ReadershipProfilePreviewOutput'
import { DatasetModuleInterface } from '../../../../../core/interface/analytics/dataset-module.interface'
import OutputChartTypeSwitch from './OutputChartTypeSwitch'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import AnalyticsOutputLibraryStructureRechart from './chart/AnalyticsOutputLibraryStructureRechart'
import { DatasetTypeEnum } from '../../../../../core/enums/dataset-type.enum'
import AnalyticsOutputTextBox from './AnalyticsOutputTextBox'
import AnalyticsOutputImage from './AnalyticsOutputImage'
import { AnalyticModuleReplace } from '../../../../../core/interface/analytics/analytic-module-replace.interface'
import { SetColorConfigInterface } from '../../../../../core/interface/set-color-config.interface'
import AnalyticsOutputStudyObject from './AnalyticsOutputStudyObject'

const OutputTypeSwitch = ({
  output,
  scrollToTreemap,
  eanSelected,
  handleOpenBookDetails,
  handleOpenAuthorDetails,
  editMode = false,
  handleAnalyticsDeleteOutput,
  handleChangeDatasetOutput,
  analyticsColorConfig,
  outputIdToScroll,
  isOrganizeMenuOpen = false,
  refreshLayout,
}: {
  output: AnalyticOutputInterface
  scrollToTreemap: (id: string) => void
  eanSelected: number | null
  handleOpenBookDetails: (eanId: number | null, showBookDetail: boolean) => void
  handleOpenAuthorDetails?: (
    authorId: string | null,
    authorName: string | null,
    ean: number | null,
  ) => void
  editMode?: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleChangeDatasetOutput?: (moduleToReplace: AnalyticModuleReplace) => void
  analyticsColorConfig: SetColorConfigInterface
  outputIdToScroll?: string
  isOrganizeMenuOpen?: boolean
  refreshLayout?: () => void
}) => {
  switch (output.type) {
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_PREVIEW:
      return (
        <ReadershipProfilePreviewOutput
          setColor={analyticsColorConfig.value}
          outputId={output.id}
          previewOutputs={
            output?.readership_profile_preview?.comparison_cards || []
          }
        />
      )
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_TITLE:
      if (!output.title || !output.layout_size) return null
      return (
        <AnalyticsOutputTitle
          outputId={output.id}
          display={output.layout_size}
          titleObject={output.title}
          editMode={editMode}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
        />
      )
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET:
      if (!output?.dataset_output) {
        return null
      }
      // eslint-disable-next-line no-case-declarations
      const datasetOutput: DatasetOutputInterface = output?.dataset_output
      return (
        <>
          <div className="flex-none" style={{ display: 'none' }} />
          {(datasetOutput.data_type === DatasetTypeEnum.CLIL && (
            <AnalyticsOutputLibraryStructureRechart
              datasetModules={datasetOutput.dataset_modules}
              setColor={analyticsColorConfig.value}
              scrollToTreemap={scrollToTreemap}
              handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
              handleChangeDatasetOutput={handleChangeDatasetOutput}
              isOrganizeMenuOpen={isOrganizeMenuOpen}
              outputIdToScroll={outputIdToScroll}
              refreshLayout={refreshLayout}
            />
          )) ||
            datasetOutput.dataset_modules.map(
              (datasetModule: DatasetModuleInterface) => (
                <OutputChartTypeSwitch
                  key={datasetModule.id}
                  dataType={datasetOutput.data_type}
                  dataset={datasetModule.dataset || []}
                  datasetModule={datasetModule}
                  display={
                    datasetModule?.layout_size ||
                    (AnalyticOutputLayoutSizeEnum.FULL as AnalyticOutputLayoutSizeEnum)
                  }
                  eanSelected={eanSelected}
                  setColor={analyticsColorConfig.value}
                  handleOpenBookDetails={handleOpenBookDetails}
                  handleOpenAuthorDetails={handleOpenAuthorDetails}
                  handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
                  handleChangeDatasetOutput={handleChangeDatasetOutput}
                  isOrganizeMenuOpen={isOrganizeMenuOpen}
                  outputIdToScroll={outputIdToScroll}
                />
              ),
            )}
        </>
      )
    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE:
      if (!output.layout_size) return null
      return (
        <AnalyticsOutputImage
          output={output}
          display={output.layout_size}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
        />
      )

    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX:
      if (!output.textbox || !output.layout_size) return null
      return (
        <AnalyticsOutputTextBox
          output={output}
          display={output.layout_size}
          editMode={editMode}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
        />
      )

    case AnalyticOutputTypeEnum.READERSHIP_PROFILE_STUDY_OBJECT:
      if (!output.study_object || !output.layout_size) return null
      return (
        <AnalyticsOutputStudyObject
          outputId={output.id}
          outputStudyObject={output.study_object}
          setColor={analyticsColorConfig.value}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleOpenBookDetails={handleOpenBookDetails}
          eanSelected={eanSelected}
        />
      )

    default:
      return null
  }
}

export default OutputTypeSwitch
