import { useTranslation } from 'react-i18next'
import { DatasetModuleInterface } from '../interface/analytics/dataset-module.interface'
import { ValueConfigurationsInterface } from '../interface/analytics/value-configurations.interface'
import { EditSectionEnum } from '../enums/analytic-edit-section.enum'
import { SourceEnum } from '../interface/analytics/source.enum'
import { EditOptionTypeEnum } from '../enums/analytic-edit-option-type.enum'
import { AnalyticsChartTypeEnum } from '../enums/analytics-chart-type.enum'
import { getSizeLabel } from '../enums/analytic-output-layout-size.enum'

export const ConvertDatasetModuleToOption = (
  datasetModule: DatasetModuleInterface | undefined,
) => {
  const { t } = useTranslation()
  const datasetModuleChartsOptions =
    datasetModule?.values_configurations
      .filter(
        (value_configuration: ValueConfigurationsInterface) =>
          value_configuration.is_configurable &&
          value_configuration.target_axis &&
          datasetModule.type !== AnalyticsChartTypeEnum.BUBBLE_CHART &&
          datasetModule.type !== AnalyticsChartTypeEnum.SCATTER_PLOT &&
          datasetModule.type !== AnalyticsChartTypeEnum.CONSTELLATION,
      )
      .map((value_configuration: ValueConfigurationsInterface) => {
        return {
          id: value_configuration.source.toString(),
          section: EditSectionEnum.DATAS,
          label: t(
            value_configuration.source === SourceEnum.EAN_ID
              ? `id_ean`
              : `EditReadershipProfil.${value_configuration.source}`,
          ),
          type: EditOptionTypeEnum.TOGGLE,
          value: value_configuration.show_in_chart || false,
        }
      }) || []

  const datasetModuleTabularOptions =
    datasetModule?.values_configurations
      .filter(
        (value_configuration: ValueConfigurationsInterface) =>
          value_configuration.is_configurable &&
          (!value_configuration.target_axis ||
            datasetModule.type === AnalyticsChartTypeEnum.BUBBLE_CHART ||
            datasetModule.type === AnalyticsChartTypeEnum.SCATTER_PLOT ||
            datasetModule.type === AnalyticsChartTypeEnum.CONSTELLATION) &&
          value_configuration.source !== SourceEnum.EAN_CLIL &&
          value_configuration.source !== SourceEnum.WORK_CLIL,
      )
      .map((value_configuration: ValueConfigurationsInterface) => {
        return {
          id: value_configuration.source.toString(),
          section: EditSectionEnum.DATAS,
          label: t(
            value_configuration.source === SourceEnum.EAN_ID
              ? `id_ean`
              : `EditReadershipProfil.${value_configuration.source}`,
          ),
          type: EditOptionTypeEnum.TOGGLE,
          value: value_configuration.is_visible,
        }
      }) || []

  const datasetModuleMixedOptions = []
  if (datasetModule && datasetModule?.show_table !== null) {
    datasetModuleMixedOptions.push({
      id: 'show_table',
      section: EditSectionEnum.DISPLAY,
      label: t('webApp.reports.tab'),
      type: EditOptionTypeEnum.TOGGLE,
      value: datasetModule?.show_table,
    })
  }

  if (datasetModule && datasetModule?.layout_size_config.sizes.length > 1) {
    datasetModuleMixedOptions.push({
      id: 'layout_size',
      section: EditSectionEnum.DISPLAY,
      label: t('EditReadershipProfil.layout_size'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: datasetModule.layout_size_config.sizes.map((size) => ({
        value: size,
        label: getSizeLabel(
          size,
          size === datasetModule.layout_size_config.default_size,
        ),
        default: datasetModule.layout_size_config.default_size,
      })),
      dropdownEntrySelected: {
        value: datasetModule.layout_size,
        label: getSizeLabel(
          datasetModule.layout_size,
          datasetModule.layout_size ===
            datasetModule.layout_size_config.default_size,
        ),
        default: datasetModule.layout_size_config.default_size,
      },
    })
  }

  // if (datasetModule?.show_axis_legend_available) {
  //   datasetModuleOptions?.push({
  //     id: datasetModule.id,
  //     section: EditSectionEnum.DISPLAY,
  //     label: t('EditReadershipProfil.legendAndAxis'),
  //     type: EditOptionTypeEnum.TOGGLE,
  //     value: datasetModule?.show_axis_legend,
  //   })
  // }

  return [
    ...datasetModuleChartsOptions,
    ...datasetModuleTabularOptions,
    ...datasetModuleMixedOptions,
  ]
}
