export enum ValueFormatEnum {
  FLOAT = 'float',
  PERCENT = 'percent',
  RATIO = 'ratio',
  STRING = 'string',
  STRING_ARRAY = 'string_array',
  INTEGER = 'integer',
  RAW = 'raw',
  DATE = 'date',
}
