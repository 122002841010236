import styled from '@emotion/styled'

const BookTitleStyle = styled.span`
  font-family: 'Vollkorn', serif;
  font-style: italic;
  font-weight: 400;
  font-size: ${({ size }: { size: string }) => size};
  line-height: 100%;
  color: #252525;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
interface Props {
  title: string
  size?: string
}
const GlBookTitle = ({ title, size = '20px' }: Props) => (
  <BookTitleStyle size={size}>{title}</BookTitleStyle>
)

export default GlBookTitle
