import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { getOnboardingInvitationsService } from '../../core/api/services/bookmetrie/authentication/get-onboarding-invitations.service'
import { postOnboardingInvitationsService } from '../../core/api/services/bookmetrie/authentication/post-onboarding-invitations.service'
import { setWebAppStateLoggedIn } from '../reducers/web-app.reducer'
import { RootState } from '../store'
import {
  INVITATION_GET_STATUS_BY_INVITATION_TOKEN,
  INVITATION_POST_ONBOARDING_INVITATION_ACTION,
} from '../prefix-actions.config'
import setAccessRefreshTokenLocalStorageUtil from '../../core/utils/set-access-refresh-token-local-storage.util'

export const invitationGetOnBoardingInvitationAction = createAsyncThunk(
  INVITATION_GET_STATUS_BY_INVITATION_TOKEN,
  async (
    { invitationToken }: { invitationToken: string },
    { getState },
  ): Promise<any> => {
    try {
      const { invitationState } = getState() as RootState
      const { cancelTokenGetOnBoardingInvitation } = invitationState

      const { data } = await getOnboardingInvitationsService(
        invitationToken,
        cancelTokenGetOnBoardingInvitation?.token,
      )

      return data
    } catch (error: any) {
      let errorMessage = null

      if (
        error instanceof AxiosError<AxiosResponse<any>> &&
        error?.response?.data &&
        error?.response?.data?.errors.length &&
        (error?.response?.status === 400 || error?.response?.status === 404)
      ) {
        const { data } = error.response
        errorMessage = JSON.stringify(data?.errors[0])
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw new Error(errorMessage)
    }
  },
)

export const invitationPostOnBoardingInvitationAction = createAsyncThunk(
  INVITATION_POST_ONBOARDING_INVITATION_ACTION,
  async (
    {
      invitationToken,
      postData,
    }: {
      invitationToken: string
      postData: {
        password: string
        confirm_password: string
        accept_eula: boolean
      }
    },
    { dispatch },
  ) => {
    try {
      const { data } = await postOnboardingInvitationsService(
        invitationToken,
        postData,
      )

      const { access: accessToken, refresh: refreshToken } = data
      await setAccessRefreshTokenLocalStorageUtil({
        accessToken,
        refreshToken,
      })
      dispatch(setWebAppStateLoggedIn({ accessToken, refreshToken }))

      return data
    } catch {
      throw new Error()
    }
  },
)
