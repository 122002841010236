import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import {
  SubTitleSelectionStyle,
  TitleSelectionStyle,
} from '../../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'
import GlSlideToggleWithText from '../../share/GlSlideToggleWithText'
import AudienceCatalogInterface from '../../../core/interface/audience/audience-catalog.interface'
import GlInfo from '../../share/GlInfo'
import NewAudienceCatalogItem from './NewAudienceCatalogItem'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface Props {
  catalogs: Array<AudienceCatalogInterface>
  useFullGleephCatalog: boolean
  setUseFullGleephCatalog: (value: boolean) => void
  catalogSelected: AudienceCatalogInterface | null
  setCatalogSelected: (value: AudienceCatalogInterface | null) => void
  isNano: boolean
  setIsNano: (value: boolean) => void
}

const NewAudienceCatalog = ({
  catalogs,
  useFullGleephCatalog,
  setUseFullGleephCatalog,
  catalogSelected,
  setCatalogSelected,
  isNano,
  setIsNano,
}: Props) => {
  const { t } = useTranslation()

  const handleOnToggleNano = useCallback(() => setIsNano(!isNano), [isNano])
  const handleOnToggleCatalog = useCallback(
    (catalog: AudienceCatalogInterface) => () => setCatalogSelected(catalog),
    [isNano],
  )
  const handleOnToggleFullGleephCatalog = useCallback(
    () => setUseFullGleephCatalog(!useFullGleephCatalog),
    [useFullGleephCatalog],
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <TitleSelectionStyle>
          {t('createAudience.inCatalog')}
        </TitleSelectionStyle>
        <SubTitleSelectionStyle>
          <span>{t('createAudience.usersCatalog')}</span>
        </SubTitleSelectionStyle>
      </div>
      <div className="flex flex-col">
        <GlSlideToggleWithText
          id="useFullGleephCatalog"
          label={t('createAudience.useFullGleephCatalog')}
          value={useFullGleephCatalog}
          handleOnToggle={handleOnToggleFullGleephCatalog}
          noJustify
        />
        {useFullGleephCatalog && (
          <span className="text-xs text-[#929292] leading-5">
            {t('createAudience.fullGleephCatalogUsers')}
          </span>
        )}
      </div>
      {!useFullGleephCatalog && (
        <>
          <StyledDiv>
            {catalogs.map((catalog) => (
              <NewAudienceCatalogItem
                key={catalog.name}
                isSelected={catalogSelected?.id === catalog.id}
                onClick={handleOnToggleCatalog(catalog)}
                name={catalog.external_name}
                nbUsers={catalog.user_reco_catalog.nb_users}
              />
            ))}
            <GlInfo label={t('createAudience.catalogInfo')} />
          </StyledDiv>
          <StyledDiv>
            <GlSlideToggleWithText
              id="nano"
              label={t('createAudience.isNano')}
              value={isNano}
              handleOnToggle={handleOnToggleNano}
              noJustify
            />
            <GlInfo label={t('createAudience.nanoInfo')} />
          </StyledDiv>
        </>
      )}
    </div>
  )
}

export default memo(NewAudienceCatalog)
