import { Outlet } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

const ShareApp = () => (
  <div className="w-full h-full flex flex-col bg-webApp overflow-hidden flex flex-col">
    <PerfectScrollbar>
      <Outlet />
    </PerfectScrollbar>
  </div>
)

export default ShareApp
