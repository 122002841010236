import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import BarChartImg from '../../../../../../assets/images/bar-chart.png'
import HorizontalBarChartImg from '../../../../../../assets/images/horizontal-bar-chart.png'
import PolarChartImg from '../../../../../../assets/images/polar-chart.png'
import RadarChartImg from '../../../../../../assets/images/radar-chart.png'
import TreemapChartImg from '../../../../../../assets/images/treemap-chart.png'
import TabularImg from '../../../../../../assets/images/tabular.png'
import StackBarChartImg from '../../../../../../assets/images/stack-bar-chart.png'
import BubbleCharImg from '../../../../../../assets/images/bubble-chart.png'
import ScatterplotImg from '../../../../../../assets/images/scatterplot.png'
import ConstellationImg from '../../../../../../assets/images/constellation.png'

import { ReactComponent as ArrowIcon } from '../../../../../../assets/icons/app/left-arrow.icon.svg'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import { TextDescriptionStyle, TitleAddModuleStyle } from './AddModuleStyles'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'
import { InterfaceRepresentationDataList } from './AddModuleView'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectAnalyticDetailsStateIsLoadingGetAvailableModules } from '../../../../../../store/getters/analytics-details/analytic-details-v2.getters'
import GlLoader from '../../../../../share/GlLoader'
import {
  AnalyticAvailableModulesInterface,
  DatasetAvaiblableModulesInterface,
  DatasetModuleAvailableModulesInterface,
} from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import AddModuleSideMenu from './AddModuleSideMenu'
import AddModuleAvailableModulesCard from './AddModuleAvailableModulesCard'

const AddModuleDataRepresentation = ({
  dataRepresentationSelected,
  setDataRepresentationSelected,
  setStep,
  representationDataList,
  handleClickOnModule,
  setDatasetModuleSelected,
  setOutputIdOfSelectedDataset,
  availableModules,
  replaceModule,
}: {
  dataRepresentationSelected: InterfaceRepresentationDataList
  setDataRepresentationSelected: (data: InterfaceRepresentationDataList) => void
  setStep: (step: AddModuleStepEnum) => void
  representationDataList: InterfaceRepresentationDataList[]
  handleClickOnModule: (
    datasetModule: DatasetModuleAvailableModulesInterface,
    outputId: string,
  ) => void
  setDatasetModuleSelected: (
    datasetModule: DatasetModuleAvailableModulesInterface,
  ) => void
  setOutputIdOfSelectedDataset: (outputId: string) => void
  availableModules: AnalyticAvailableModulesInterface
  replaceModule: boolean
}) => {
  const { t } = useTranslation()

  const isLoadingGetAvailableModules = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetAvailableModules,
  )

  const scrollRef = useRef<HTMLElement | null>(null)

  const availableModulesOfRepresentationSelected =
    availableModules?.datasets.find(
      (dataset: DatasetAvaiblableModulesInterface) =>
        dataset.data_type === dataRepresentationSelected.data_type,
    )

  function handleClickOnAddModule(
    dataset_module: DatasetModuleAvailableModulesInterface,
  ) {
    if (availableModulesOfRepresentationSelected?.id) {
      handleClickOnModule(
        dataset_module,
        availableModulesOfRepresentationSelected.id,
      )
    }
  }

  function handleClickOnOptionModule(
    dataset_module: DatasetModuleAvailableModulesInterface,
  ) {
    if (!availableModulesOfRepresentationSelected) return
    setDatasetModuleSelected({
      ...dataset_module,
      id: `${dataset_module.type.toString()}-${dataRepresentationSelected.data_type?.toString()}`,
    })
    setOutputIdOfSelectedDataset(availableModulesOfRepresentationSelected.id)
    setStep(AddModuleStepEnum.OPTIONS)
  }

  const mappingIdGrapheImg = (id: string) => {
    switch (id) {
      case AnalyticsChartTypeEnum.VERTICAL_BAR_CHART:
        return BarChartImg
      case AnalyticsChartTypeEnum.HORIZONTAL_BAR_CHART:
        return HorizontalBarChartImg
      case AnalyticsChartTypeEnum.POLAR:
        return PolarChartImg
      case AnalyticsChartTypeEnum.RADAR:
        return RadarChartImg
      case AnalyticsChartTypeEnum.TREEMAP:
        return TreemapChartImg
      case AnalyticsChartTypeEnum.TABULAR:
        return TabularImg
      case AnalyticsChartTypeEnum.VERTICAL_STACKED_BAR_CHART:
        return StackBarChartImg
      case AnalyticsChartTypeEnum.BUBBLE_CHART:
        return BubbleCharImg
      case AnalyticsChartTypeEnum.SCATTER_PLOT:
        return ScatterplotImg
      case AnalyticsChartTypeEnum.CONSTELLATION:
        return ConstellationImg
      default:
        return ''
    }
  }

  function scrollToLeft() {
    const curr = scrollRef.current
    if (curr) {
      curr.scroll({
        left: curr.scrollLeft - 200,
      })
    }
  }

  function scrollToRight() {
    const curr = scrollRef.current
    if (curr) {
      curr.scroll({
        left: curr.scrollLeft + 200,
      })
    }
  }

  return (
    <div className="flex flex-1 gap-8 w-full max-h-[900px]">
      <AddModuleSideMenu
        representationDataList={representationDataList}
        dataRepresentationSelected={dataRepresentationSelected}
        setDataRepresentationSelected={setDataRepresentationSelected}
      />
      <div className="flex flex-col w-full max-w-[1200px]">
        <div className="flex flex-col gap-4">
          <TitleAddModuleStyle className="text-white">
            {dataRepresentationSelected
              ? t(dataRepresentationSelected.label)
              : t('addModuleMenu.datasRepresentation.title')}
          </TitleAddModuleStyle>
          <TextDescriptionStyle className="self-stretch max-w-[800px]">
            {dataRepresentationSelected
              ? t(dataRepresentationSelected.description)
              : t('addModuleMenu.datasRepresentation.title')}
          </TextDescriptionStyle>
        </div>

        {(isLoadingGetAvailableModules && (
          <div className="flex h-full items-center justify-center">
            <GlLoader />
          </div>
        )) || (
          <PerfectScrollbar
            containerRef={(el) => {
              scrollRef.current = el
            }}
            className="flex items-end gap-8"
          >
            {availableModulesOfRepresentationSelected &&
              availableModulesOfRepresentationSelected.available_modules?.map(
                (item: DatasetModuleAvailableModulesInterface) => (
                  <AddModuleAvailableModulesCard
                    key={item.type}
                    item={item}
                    handleClickOnOptionModule={handleClickOnOptionModule}
                    handleClickOnAddModule={handleClickOnAddModule}
                    mappingIdGrapheImg={mappingIdGrapheImg}
                    alreadyInReport={item.is_used}
                    replaceModule={replaceModule}
                  />
                ),
              )}
            {!!scrollRef.current?.scrollLeft && (
              <div className="fixed self-center">
                <div
                  className="flex items-center justify-center rounded w-8 h-8 bg-white"
                  onClick={scrollToLeft}
                >
                  <ArrowIcon className="h-[20px] w-[20px]" />
                </div>
              </div>
            )}
            {availableModulesOfRepresentationSelected &&
              availableModulesOfRepresentationSelected.available_modules
                ?.length > 5 && (
                <div className="fixed flex self-center right-32">
                  <div
                    className="flex items-center justify-center rounded w-8 h-8 bg-white"
                    onClick={scrollToRight}
                  >
                    <ArrowIcon className="h-[20px] w-[20px] rotate-180" />
                  </div>
                </div>
              )}
          </PerfectScrollbar>
        )}
      </div>
    </div>
  )
}

export default AddModuleDataRepresentation
