import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import AudienceCatalogInterface from '../interface/audience/audience-catalog.interface'
import { webAppGetAudienceCatalogsAction } from '../../store/actions/web-app.actions'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectWebAppStateAudienceCatalogs } from '../../store/getters/web-app.getters'

const useAudienceCatalogHook = (): {
  catalogs: AudienceCatalogInterface[]
  isLoading: boolean
} => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const catalogs = useAppSelector(selectWebAppStateAudienceCatalogs)

  useEffect(() => {
    const fetchCatalogs = async () => {
      await dispatch(webAppGetAudienceCatalogsAction())
    }

    if (catalogs.length === 0) {
      setIsLoading(true)
      try {
        void fetchCatalogs()
      } catch (e) {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      } finally {
        setIsLoading(false)
      }
    }
  }, [])

  return { catalogs, isLoading }
}

export default useAudienceCatalogHook
