import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import InputTypeChoice from '../../share/InputTypeChoice'
import {
  CompareGroupType,
  GroupsCompareTypesConfig,
  StudyObjectGroupCompareEnum,
} from '../../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { CompareGroupValueType } from '../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { SetColorConfig } from '../../../core/config/SetColorConfig'
import GlSlideToggleWithText from '../../share/GlSlideToggleWithText'
import { BookInterface } from '../../../core/interface/book.interface'

interface Props {
  isLoading: boolean
  searchType: CompareGroupType
  setSearchType: (type: CompareGroupType) => void
  values: CompareGroupValueType
  setValues: (type: CompareGroupValueType) => void
  booksSelected: BookInterface[]
  setBooksSelected: (books: BookInterface[]) => void
  userAlreadyOwnBook: boolean
  setUserAlreadyOwnBook: (value: boolean) => void
  eanSelected: number | null
  handleOpenBookDetails: (ean: number | null) => void
}

const NewAudienceTarget = ({
  isLoading,
  searchType,
  setSearchType,
  values,
  setValues,
  booksSelected,
  setBooksSelected,
  userAlreadyOwnBook,
  setUserAlreadyOwnBook,
  eanSelected,
  handleOpenBookDetails,
}: Props) => {
  const { t } = useTranslation()

  const handleOnToggle = useCallback(
    () => setUserAlreadyOwnBook(!userAlreadyOwnBook),
    [userAlreadyOwnBook],
  )

  const handleSelectValue = useCallback(
    (
      value: CompareGroupValueType,
      booksToRemove: BookInterface[] | undefined,
    ) => {
      setValues(value)
      setBooksSelected(
        booksSelected.filter((book) => !booksToRemove?.includes(book)),
      )
    },
    [setValues, setBooksSelected, booksSelected],
  )

  return (
    <>
      <InputTypeChoice<CompareGroupType, CompareGroupValueType>
        disable={isLoading}
        color={SetColorConfig[0].color1}
        keyValue="audience"
        title={t('createAudience.iTargetReadersOf')}
        subTitle={t('createAudience.targetCriteria')}
        inputsTypeConfig={GroupsCompareTypesConfig}
        inputTypeSelected={searchType}
        handleOnClickInputSelectionButton={setSearchType}
        inputValue={values}
        handleSelectValue={handleSelectValue}
        booksSelected={booksSelected}
        handleUpdateBooksSelected={setBooksSelected}
        eanSelected={eanSelected}
        handleOpenBookDetails={handleOpenBookDetails}
      />
      {searchType === StudyObjectGroupCompareEnum.TITLE && (
        <GlSlideToggleWithText
          id="userAlreadyOwnBook"
          label={t('createAudience.userAlreadyOwnBook')}
          value={userAlreadyOwnBook}
          handleOnToggle={handleOnToggle}
          noJustify
        />
      )}
    </>
  )
}

export default memo(NewAudienceTarget)
