import React, { memo, useCallback } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TableViewIcon } from '../../../../assets/icons/app/table-view.icon.svg'
import ShareD3ConstellationWorkMirror from './constellation/ShareD3ConstellationWorkMirror'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'
import ShareAnalyticDetailsBooksInputs from '../../components/ShareAnalyticDetailsBooksInputs'
import {
  selectShareAnalyticDetailsStateAnalyticDetailsBooksInput,
  selectShareAnalyticDetailsStateAnalyticDetailsWorksMirrorsOutput,
  selectShareAnalyticDetailsStateBookDetailsSelected,
} from '../../../../store/getters/share-analytic-details.getters'
import { shareAnalyticDetailsV2StateGetBookDetailsData } from '../../../../store/actions/share-analytic-details.actions'

const CardLayoutStyle = styled.div`
  z-index: 0;
  background: rgba(255, 255, 255, 0.01);
`

const ShareAnalyticDetailsWorkMirrorConstellationCard = ({
  openListCard,
  handleOpenListView,
  handleBookHovered,
}: {
  openListCard: boolean
  handleOpenListView: () => void
  handleBookHovered: (eanId: number | null) => void
}) => {
  const { t } = useTranslation()
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleOpenListView()
  }

  const booksInput = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetailsBooksInput,
  )

  const worksMirrorsOutput = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetailsWorksMirrorsOutput,
  )

  const bookDetailsSelected = useAppSelector(
    selectShareAnalyticDetailsStateBookDetailsSelected,
  )

  const dispatch = useAppDispatch()
  const handleBookSelected = useCallback(
    (bookDetails: WorkMirrorBookInterface | null) => {
      void dispatch(
        shareAnalyticDetailsV2StateGetBookDetailsData({ bookDetails }),
      )
    },
    [bookDetailsSelected],
  )

  return (
    <CardLayoutStyle className="relative flex-auto flex flex-col rounded-xl shadow-lg bg-white basis-2/3">
      <div className="flex items-start justify-between m-2">
        <ShareAnalyticDetailsBooksInputs
          booksInput={booksInput}
          bookDetailsSelected={bookDetailsSelected}
          handleBookSelected={handleBookSelected}
        />
        {(!openListCard && (
          <button
            type="button"
            className="flex flex-row gap-2 rounded p-2 items-center justify-center hover:shadow-xl bg-black text-white"
            onClick={handleOnClick}
            style={{
              zIndex: 10,
            }}
          >
            <TableViewIcon />
            <span>{t('commons.buttons.list')}</span>
          </button>
        )) ||
          null}
      </div>

      <ShareD3ConstellationWorkMirror
        booksInput={booksInput}
        booksOutput={worksMirrorsOutput}
        handleBookHovered={handleBookHovered}
        bookDetailsSelected={bookDetailsSelected}
        handleBookSelected={handleBookSelected}
      />
    </CardLayoutStyle>
  )
}

export default memo(ShareAnalyticDetailsWorkMirrorConstellationCard)
