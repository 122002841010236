import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { ReactComponent as AuthorIcon } from '../../../../../assets/icons/app/author.icon.svg'
import { ReactComponent as ReleaseDateIcon } from '../../../../../assets/icons/app/date.icon.svg'
import GlLabelIcon from '../../../../share/GlLabelIcon'
import GlVDivider from '../../../../share/GlVDivider'

const LabelSpan = styled.span`
  font-family: 'Vollkorn', serif;
`

interface Props {
  authors: string[]
  publishingDate: string
}
const DynamicsBillAuthorsDate = ({ authors, publishingDate }: Props) => (
  <div className="flex flex-row gap-2.5">
    <GlLabelIcon>
      <AuthorIcon className="w-4 h-4 text-[#252525]" />
      <LabelSpan>{authors.join(', ')}</LabelSpan>
    </GlLabelIcon>
    <GlVDivider />
    <GlLabelIcon>
      <ReleaseDateIcon className="w-4 h-4 text-[#252525]" />
      <LabelSpan>{dayjs(publishingDate).format('DD/MM/YY')}</LabelSpan>
    </GlLabelIcon>
  </div>
)

export default DynamicsBillAuthorsDate
