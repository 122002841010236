import React from 'react'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import ConstellationDataInterface from './constellation-data.interface'

const ConstellationItem = ({
  data,
  isAuthor,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: {
  data: ConstellationDataInterface
  isAuthor: boolean
  onMouseEnter: (data: ConstellationDataInterface) => void
  onMouseLeave: (data: null) => void
  onClick?: (eanId: number | undefined) => void
}) => {
  const text = isAuthor
    ? data.data[SourceEnum.AUTHOR_NAME]
    : data.data[SourceEnum.WORK_TITLE]
  return (
    <g
      className={`${!isAuthor && onClick ? 'cursor-pointer' : ''}`}
      onClick={() => onClick && onClick(data.data[SourceEnum.WORK_BESTSHOT_ID])}
      onMouseEnter={(e) => onMouseEnter(data)}
      onMouseLeave={() => onMouseLeave(null)}
    >
      <circle
        cx={data.x}
        cy={data.y}
        r={data.r}
        fill={isAuthor ? 'white' : data.color}
        stroke={isAuthor ? data.color : '#929292'}
        strokeWidth={isAuthor ? '3' : '1'}
      />
      <text
        x={data.x}
        y={data.y + data.r}
        alignmentBaseline="before-edge"
        textAnchor="middle"
      >
        {`${text.slice(0, 16)}${text.length > 16 ? '...' : ''}`}
      </text>
    </g>
  )
}

export default ConstellationItem
