import React from 'react'
import styled from '@emotion/styled'
import GlCellBody from '../../../../share/table/GlCellBody'
import GlBadge from '../../../../share/badge/GlBadge'
import RankTitle from '../../../../trends/body/RankTitle'
import RankCellAuthors from '../../../../trends/body/RankCellAuthors'
import { WorkMirrorBookInterface } from '../../../../../core/interface/report/work-mirror-book.interface'
import GlBadgeClilIcon from '../../../../share/badge/GlBadgeClilIcon'

const LineStyle = styled.div`
  ${({ hasSelect, hasHover }: { hasSelect: boolean; hasHover: boolean }) =>
    (hasHover &&
      `
      background-color: #e3e3e3 !important;
    `) ||
    (hasSelect &&
      `
    &:hover {
      background-color: #f7f7f7 !important;
    }
  
    &:active {
      background-color: #e3e3e3 !important;
    }
  `)}
`

const ProximityStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  padding: 0px 4px;
`

const AnalyticDetailsTableBody = ({
  booksOutput,
  handleBookSelected,
  bookDetailsSelected,
  hasSelect,
  bookEanIdHovered,
}: {
  booksOutput: WorkMirrorBookInterface[]
  handleBookSelected: (book: WorkMirrorBookInterface | null) => void
  bookDetailsSelected: WorkMirrorBookInterface | null
  hasSelect: boolean
  bookEanIdHovered: number | null
}) => {
  const percentage = (value: number) =>
    (value && `${Math.round((1 - value) * 1000) / 10}%`) || ''

  return (
    <div className="flex grow flex-col">
      {booksOutput.map((book: WorkMirrorBookInterface, index: number) => (
        <LineStyle
          id={`line-table-${book.id_ean}`}
          hasSelect={hasSelect}
          hasHover={bookEanIdHovered === book.id_ean}
          key={`line-table-${book.id_ean}`}
          style={{
            backgroundColor:
              hasSelect && bookDetailsSelected?.id_ean === book.id_ean
                ? '#e3e3e3'
                : 'white',
            borderRadius:
              index + 1 === booksOutput.length ? '0 0 16px 16px' : 'none',
          }}
          className={`
            cursor-pointer
            bg-white
            flex
            flex-row
            hover:bg-[#f7f7f7]
            active:bg-[#e3e3e3]
            ${booksOutput.length > index + 1 ? 'border-b-2' : ''}
          `}
          onClick={() => {
            handleBookSelected(book)
          }}
        >
          <GlCellBody
            className="min-w-[80px] max-w-[80px]"
            labelValue={book?.distance}
          >
            <div className="flex flex-row items-center justify-start gap-1">
              <GlBadge label={`${book?.position || index + 1}`} />
              <ProximityStyle>
                {percentage(book?.distance as number)}
              </ProximityStyle>
            </div>
          </GlCellBody>
          <GlCellBody className="min-w-[238px] max-w-[238px]">
            <RankTitle title={book?.book.title} titleOnly />
          </GlCellBody>
          <GlCellBody className="min-w-[128px] max-w-[128px]">
            <RankCellAuthors authors={book?.book.authors} firstDisplay />
          </GlCellBody>
          <GlCellBody className="min-w-[32px] max-w-[32px]">
            <GlBadgeClilIcon id={`${book?.book.clil1}`} smallFontSize />
          </GlCellBody>
        </LineStyle>
      ))}
    </div>
  )
}

export default AnalyticDetailsTableBody
