import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import {
  CompareGroupType,
  StudyObjectType,
} from '../../../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'

export const postReadershipProfilePreviewService = (
  preview: {
    title: string
    studyObject: {
      type: StudyObjectType
      ids: string[] | number[]
      eans: number[]
    }
    compareGroup: {
      type: CompareGroupType
      ids: string[] | number[]
      eans: number[]
    }
  },
  cancelToken?: CancelToken,
) => apiInstance.post(ApiPathConfig.ANALYTICS_PREVIEW, preview, { cancelToken })
