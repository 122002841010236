export enum PopularityEnum {
  POPULARITY_UP_3,
  POPULARITY_UP_2,
  POPULARITY_UP_1,
  POPULARITY_NONE,
  POPULARITY_DOWN_1,
  POPULARITY_DOWN_2,
  POPULARITY_DOWN_3,
  POPULARITY_NC,
}

export const getPopularityEnumByValue = (value: number) => {
  if (value >= 1000) return PopularityEnum.POPULARITY_UP_3
  if (value >= 600 && value < 1000) return PopularityEnum.POPULARITY_UP_2
  if (value >= 300 && value < 600) return PopularityEnum.POPULARITY_UP_1
  if (value >= 100 && value < 300) return PopularityEnum.POPULARITY_NONE
  // if (value >= 0 && value < 50) return PopularityEnum.POPULARITY_DOWN_1
  // if (value >= 5 && value < 15) return PopularityEnum.POPULARITY_DOWN_2
  //  if (value >= 0 && value < 5) return PopularityEnum.POPULARITY_DOWN_3
  return PopularityEnum.POPULARITY_NC
}

export const getPopularityIndexByValue = (value: number) => {
  if (value >= 1000) return 4
  if (value >= 600 && value < 1000) return 3
  if (value >= 300 && value < 600) return 2
  if (value >= 100 && value < 300) return 1
  // if (value >= 0 && value < 50) return PopularityEnum.POPULARITY_DOWN_1
  // if (value >= 5 && value < 15) return PopularityEnum.POPULARITY_DOWN_2
  //  if (value >= 0 && value < 5) return PopularityEnum.POPULARITY_DOWN_3
  return 0
}

export const getPopularityRadiusValue = (value: number) => {
  if (value >= 500) return 50
  if (value >= 300 && value < 500) return 43.75
  if (value >= 100 && value < 300) return 37.5
  if (value >= 50 && value < 100) return 31.25
  // if (value >= 0 && value < 50) return PopularityEnum.POPULARITY_DOWN_1
  // if (value >= 5 && value < 15) return PopularityEnum.POPULARITY_DOWN_2
  //  if (value >= 0 && value < 5) return PopularityEnum.POPULARITY_DOWN_3
  return 25
}

export const getIconPopularity = (value: PopularityEnum) => {
  switch (value) {
    case PopularityEnum.POPULARITY_UP_3:
      return (
        <svg
          width="36"
          height="16"
          viewBox="0 0 36 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.984 4.044V7.644H10.488V9.468H6.984V13.044H4.968V9.468H1.488V7.644H4.968V4.044H6.984ZM18.9606 4.044V7.644H22.4646V9.468H18.9606V13.044H16.9446V9.468H13.4646V7.644H16.9446V4.044H18.9606ZM30.9371 4.044V7.644H34.4411V9.468H30.9371V13.044H28.9211V9.468H25.4411V7.644H28.9211V4.044H30.9371Z"
            fill="#89DA8E"
          />
        </svg>
      )

    case PopularityEnum.POPULARITY_UP_2:
      return (
        <svg
          width="22"
          height="10"
          viewBox="0 0 22 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.984 0.0439987V3.644H9.488V5.468H5.984V9.044H3.968V5.468H0.488V3.644H3.968V0.0439987H5.984ZM17.9606 0.0439987V3.644H21.4646V5.468H17.9606V9.044H15.9446V5.468H12.4646V3.644H15.9446V0.0439987H17.9606Z"
            fill="#89DA8E"
          />
        </svg>
      )

    case PopularityEnum.POPULARITY_UP_1:
      return (
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.984 4.044V7.644H10.488V9.468H6.984V13.044H4.968V9.468H1.488V7.644H4.968V4.044H6.984Z"
            fill="#89DA8E"
          />
        </svg>
      )

    case PopularityEnum.POPULARITY_NONE:
      return (
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 7V5H2V7H6H10ZM10 11V9H2V11H6H10Z"
            fill="#B2B2B2"
          />
        </svg>
      )

    case PopularityEnum.POPULARITY_DOWN_1:
      return (
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.44 9.924V8.052H8.232V9.924H1.44Z" fill="#F97E60" />
        </svg>
      )

    case PopularityEnum.POPULARITY_DOWN_2:
      return (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.44 9.924V8.052H8.232V9.924H1.44ZM11.1197 9.924V8.052H17.9117V9.924H11.1197Z"
            fill="#F97E60"
          />
        </svg>
      )

    case PopularityEnum.POPULARITY_DOWN_3:
      return (
        <svg
          width="30"
          height="16"
          viewBox="0 0 30 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.44 9.924V8.052H8.232V9.924H1.44ZM11.1197 9.924V8.052H17.9117V9.924H11.1197ZM20.7994 9.924V8.052H27.5914V9.924H20.7994Z"
            fill="#F97E60"
          />
        </svg>
      )

    default:
      return (
        <svg
          width="24"
          height="15"
          viewBox="0 0 24 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.745 13H5.84L1.64 4.225C1.7 4.915 1.745 5.555 1.775 6.145C1.815 6.725 1.835 7.43 1.835 8.26V13H0.5V2.665H2.36L6.605 11.455C6.575 11.195 6.535 10.74 6.485 10.09C6.435 9.44 6.41 8.845 6.41 8.305V2.665H7.745V13ZM14.2943 0.894999L15.4643 1.165L11.9993 14.56L10.8143 14.275L14.2943 0.894999ZM20.5902 4.915C21.0602 4.915 21.4902 4.985 21.8802 5.125C22.2702 5.255 22.6452 5.47 23.0052 5.77L22.3452 6.64C22.0652 6.44 21.7902 6.295 21.5202 6.205C21.2602 6.105 20.9702 6.055 20.6502 6.055C20.0102 6.055 19.5102 6.31 19.1502 6.82C18.8002 7.33 18.6252 8.085 18.6252 9.085C18.6252 10.085 18.8002 10.825 19.1502 11.305C19.5002 11.775 20.0002 12.01 20.6502 12.01C20.9602 12.01 21.2452 11.965 21.5052 11.875C21.7652 11.775 22.0552 11.62 22.3752 11.41L23.0052 12.31C22.2852 12.89 21.4802 13.18 20.5902 13.18C19.5202 13.18 18.6752 12.82 18.0552 12.1C17.4452 11.38 17.1402 10.385 17.1402 9.115C17.1402 8.275 17.2802 7.54 17.5602 6.91C17.8402 6.28 18.2352 5.79 18.7452 5.44C19.2652 5.09 19.8802 4.915 20.5902 4.915Z"
            fill="#B2B2B2"
          />
        </svg>
      )
  }
}
