import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DynamicsSearchTopRequestSavedAction from './DynamicsSearchTopRequestSavedAction'
import DynamicsSearchTopRequestSavedDefault from './DynamicsSearchTopRequestSavedDefault'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import {
  buildPathWithParams,
  buildRelativePath,
  PathConfig,
} from '../../core/config/PathConfig'
import { DynamicsRequestTypeEnum } from '../../core/enums/dynamics/dynamics-request-type.enum'

const ContainerStyle = styled.div`
  &:hover {
    background-color: #f4f4f4;
    .action-icon {
      display: flex;
    }
  }
  &:active {
    background-color: #e3e3e3;
    .action-icon {
      display: flex;
    }
  }
  height: ${({ isXl }: { isXl: boolean }) => (isXl ? '80px' : '52px')};
`

const IndexStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #b2b2b2;
`

const InputStyle = styled.input`
  background-color: ${({ isEditing }: { isEditing: boolean }) =>
    isEditing ? '#B2B2B266' : 'transparent'};
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #252525;
  padding: ${({ isEditing }: { isEditing: boolean }) =>
    isEditing ? '12px' : '0'};

  :focus {
    outline: none;
  }
`

interface Props {
  name: string
  catalog: CatalogInterface
  tagGroup: string
  tagGroupName: string
  sexCategory: GenderFilterEnum
  ageCategory: AgeFilterEnum
  periodType: PeriodTypeEnum
  startDate: string
  endDate: string
  id: string
  index?: number
  handleOnClickTopRequestSaved: (id: string) => void
  handleRenameTopRequestSaved: (id: string, name: string) => void
  handleDeleteTopRequestSaved: (id: string) => void
  canEditOnClickOnBG?: boolean
  isXl?: boolean
  setRefRequestName?: (requestName: string) => void
  readOnly?: boolean
}

const DynamicsSearchTopRequestSaved = ({
  name,
  catalog,
  tagGroup,
  tagGroupName,
  sexCategory,
  ageCategory,
  id,
  index,
  periodType,
  startDate,
  endDate,
  handleOnClickTopRequestSaved,
  handleRenameTopRequestSaved,
  handleDeleteTopRequestSaved,
  canEditOnClickOnBG = false,
  isXl = false,
  setRefRequestName,
  readOnly = false,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [requestName, setRequestName] = useState(name)
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const saveInput = () => {
    setRequestName(requestName.trim())
    setIsEditing(false)
    setRefRequestName ? setRefRequestName(requestName) : null
  }

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      inputRef.current.focus()
    } else if (name !== requestName) {
      handleRenameTopRequestSaved(id, requestName)
    }
  }, [isEditing])

  const handleEditRequestName = () => {
    setIsEditing(true)
  }

  const onClickLine = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (canEditOnClickOnBG) {
      handleEditRequestName()
    } else {
      navigate(
        buildPathWithParams(
          buildRelativePath([
            PathConfig.BOOKMETRIE,
            PathConfig.DYNAMICS,
            PathConfig.RESULTS,
          ]),
          {
            catalogId: catalog.id,
            tag_group: tagGroup,
            period: periodType,
            startDate,
            endDate,
            gender: sexCategory,
            age: ageCategory,
            requestType: DynamicsRequestTypeEnum.SAVED,
          },
        ),
      )
    }
  }

  const onClickTopRequestSaved = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleOnClickTopRequestSaved(id)
  }

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestName(event.target.value)
  }

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      saveInput()
    }
  }

  const handleDeleteRequest = () => {
    handleDeleteTopRequestSaved(id)
  }

  return (
    <ContainerStyle
      className="w-full flex bg-[#F7F7F7] rounded justify-between p-1 cursor-pointer"
      onClick={onClickLine}
      isXl={isXl}
    >
      <div className="flex items-center gap-2 w-full ml-3">
        {(requestName && requestName.length > 0) || isEditing ? (
          <InputStyle
            isEditing={isEditing}
            placeholder={t('webApp.dynamics.search.searchPlaceholder') || ''}
            ref={inputRef}
            readOnly={!isEditing}
            className="flex items-center cursor-pointer w-full rounded h-full"
            type="text"
            value={requestName}
            onChange={onChangeValue}
            onKeyDown={onPressEnter}
            onBlur={saveInput}
            maxLength={100}
          />
        ) : (
          <DynamicsSearchTopRequestSavedDefault
            catalog={catalog}
            tagGroupName={tagGroupName}
            sexCategory={sexCategory}
            ageCategory={ageCategory}
            periodTypeSelected={periodType}
            startDateSelected={startDate}
            endDateSelected={endDate}
          />
        )}
      </div>
      {!readOnly && !isEditing && !canEditOnClickOnBG && (
        <DynamicsSearchTopRequestSavedAction
          handleEditRequestName={handleEditRequestName}
          handleDeleteRequest={handleDeleteRequest}
        />
      )}
    </ContainerStyle>
  )
}

export default DynamicsSearchTopRequestSaved
