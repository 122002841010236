import React, { memo, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import { ReactComponent as CrossIcon } from '../../assets/icons/app/cross.icon.svg'
import useBookAutoCompletionHook from '../analytics-details/work-mirror/book-autocomplete/useBookAutoCompletionHook'
import { BookInterface } from '../../core/interface/book.interface'
import GlLoader from '../share/GlLoader'
import { BookSearchInterface } from '../../core/interface/book-search.interface'
import { clilReferencesConfig } from '../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../core/config/clil-refs-mapping.config'
import TitleV2 from '../analytics-details/work-mirror/TitleV2'

const CardStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  border-radius: 8px;
`

const ButtonAddStyle = styled.button`
  border: 1px solid #595959;
  border-radius: 4px;
  padding: 8px;
  background-color: #000000;
  height: 40px;
  color: #bebebe;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.28px;
  overflow: hidden;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Medium */
  line-height: 20px; /* 142.857% */
`

const ItemButtonStyle = styled.button`
  padding: 4px;
  background: ${({ isSelectable }: { isSelectable: boolean }) =>
    !isSelectable ? '#e3e3e3' : ''};
  cursor: ${({ isSelectable }: { isSelectable: boolean }) =>
    isSelectable ? 'pointer' : 'default'};

  :hover {
    background: ${({ isSelectable }: { isSelectable: boolean }) =>
      isSelectable ? '#f7f7f7' : ''};
  }

  :not(:disabled):active {
    background: #e3e3e3;
  }
  :focus {
    outline: none;
  }
`

const ItemAuthorStyle = styled.div`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #595959;
  font-family: Vollkorn, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125.37%; /* 15.044px */
  letter-spacing: -0.12px;
`

const InputStyle = styled.input`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  background-color: #ffffff33;
  height: 40px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;

  ::placeholder {
    color: #b2b2b266;
  }

  :focus {
    outline: none;
  }
`

const MenuStyle = styled.div`
  z-index: 10;
  width: 100% !important;
  height: auto;
  min-height: min-content;
  max-height: 208px;
  background-color: white;
  border-radius: 4px;
`
const BookAutoCompleteSelection = ({
  menuOpen,
  setMenuOpen,
  booksInput,
  handleAddBookToAnalytic,
}: {
  menuOpen: boolean
  setMenuOpen: (value: boolean) => void
  booksInput: BookInterface[]
  handleAddBookToAnalytic: (eanId: number) => void
}) => {
  const { t } = useTranslation()

  const [needCall, setNeedCall] = useState<boolean>(false)

  const [needClose, setNeedClose] = useState<boolean>(false)
  // const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [menuIsHovered, setMenuIsHovered] = useState<boolean>(false)
  const [displayInput, setDisplayInput] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [indexSelected, setIndexSelected] = useState<number | null>(null)

  const handleBookSelected = (book: BookInterface): void => {}

  const {
    state: {
      isLoadingGetBooksByQuery,
      getBooksByQuerySuccess,
      getBooksByQueryError,
      books,
    },
    actions: { getBooksByQuery, resetBooks },
  } = useBookAutoCompletionHook({
    handleBookSelected,
  })

  const handleAddBook = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setDisplayInput(true)
  }

  const handleResetQuery = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setQuery('')
  }
  const handleOnChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setQuery(event.target.value)
    setNeedCall(event.target.value.length > 2)
  }

  let timeGetBooksByQuery: NodeJS.Timeout | null = null
  const handleCallGetBooksByQuery = () => {
    timeGetBooksByQuery = setTimeout(() => {
      getBooksByQuery(query)
    }, 500)
  }

  useEffect(() => {
    if (needCall && query && query.length > 2) {
      handleCallGetBooksByQuery()
    }
    return () => {
      if (timeGetBooksByQuery) {
        clearTimeout(timeGetBooksByQuery)
      }
    }
  }, [query, needCall])

  const handleOnMouseEnterMenu = () => {
    setMenuIsHovered(true)
    setNeedClose(false)
    if (!menuOpen) {
      setMenuOpen(true)
    }
  }

  const handleOnMouseLeaveMenu = () => {
    setMenuIsHovered(false)
    setNeedClose(true)
  }

  const handleOnBlur = () => {
    setNeedClose(true)
  }

  const handleOnFocus = () => {
    setMenuOpen(true)
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setNeedClose(false)
      setMenuOpen(false)
    }, 300)
  }

  useEffect(() => {
    if (isLoadingGetBooksByQuery || getBooksByQueryError) {
      setIndexSelected(null)
    }
    if (getBooksByQuerySuccess && books.length) {
      setIndexSelected(0)
    }
  }, [
    isLoadingGetBooksByQuery,
    getBooksByQuerySuccess,
    getBooksByQueryError,
    books,
  ])

  useEffect(() => {
    if (needClose && !menuIsHovered) {
      handleTimeOut()
    }
    if (needClose && query.length < 3) {
      resetBooks()
    }
    if (needClose && !query) {
      setDisplayInput(false)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, timer, query])

  const onClickItem = (id_ean: number, is_recommendable: boolean) => {
    if (id_ean && is_recommendable) {
      handleAddBookToAnalytic(id_ean)
      setQuery('')
    }
  }

  return (
    <CardStyle>
      {(!displayInput && (
        <ButtonAddStyle
          type="button"
          className="flex flex-row gap-[8px] items-center justify-start"
          onClick={handleAddBook}
        >
          {/* AddIcon className="text-[#595959] w-[12px] h-[12px]" /> */}
          <span>Ajouter un livre par titre, auteur ou ISBN</span>
        </ButtonAddStyle>
      )) || (
        <div className="relative flex flex-col gap-[1px]">
          <div className="relative flex flex-col">
            <InputStyle
              autoFocus
              type="text"
              placeholder="Rechercher"
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              className="bg-white"
              value={query}
              onChange={handleOnChangeQuery}
            />
            {(query && (
              <button
                type="button"
                className="absolute top-4 right-2 text-white"
                onClick={handleResetQuery}
              >
                <CrossIcon className="w-3 h-3" />
              </button>
            )) ||
              null}
          </div>

          {menuOpen &&
            query.length > 2 &&
            (isLoadingGetBooksByQuery ||
              getBooksByQuerySuccess ||
              getBooksByQueryError) && (
              <MenuStyle
                className="absolute left-0 top-12 shadow-2xl flex flex-col"
                onMouseLeave={handleOnMouseLeaveMenu}
                onMouseEnter={handleOnMouseEnterMenu}
              >
                <PerfectScrollbar>
                  {(isLoadingGetBooksByQuery && (
                    <div className="flex-auto flex flex-col items-center justify-center w-full">
                      <GlLoader width={32} height={32} />
                    </div>
                  )) || (
                    <div className="flex-auto flex flex-col">
                      {(books.length &&
                        books.map(
                          (book: BookSearchInterface, index: number) => (
                            <ItemButtonStyle
                              key={nanoid(5)}
                              isSelectable={book.is_recommendable}
                              type="button"
                              className={`relative flex flex-row items-center justify-start gap-2 ${
                                index === 0
                                  ? 'rounded-t-[4px]'
                                  : index + 1 === books.length
                                  ? 'rounded-b-[4px]'
                                  : ''
                              }`}
                              onClick={(event: React.MouseEvent) => {
                                event.preventDefault()
                                onClickItem(book.id_ean, book.is_recommendable)
                              }}
                              onMouseEnter={(
                                event: React.MouseEvent<HTMLButtonElement>,
                              ) => {
                                event.currentTarget.focus()
                              }}
                            >
                              <img
                                src={
                                  book?.imgs || getBookCoverByClil(book.clil1)
                                }
                                alt="book cover"
                                style={{
                                  objectFit: 'cover',
                                  minWidth: 40,
                                  minHeight: 40,
                                  maxWidth: 40,
                                  maxHeight: 40,
                                  borderRadius: '4px',
                                  borderTop: '1px solid transparent',
                                  borderBottom: '1px solid transparent',
                                }}
                              />
                              <div className="flex-auto flex flex-col items-start">
                                <TitleV2 title={book?.title?.trim()} />
                                <ItemAuthorStyle>
                                  {book?.authors[0]?.trim()}
                                </ItemAuthorStyle>
                              </div>
                            </ItemButtonStyle>
                          ),
                        )) || (
                        <div className="flex flex-row items-center justify-center h-10">
                          <span>Pas de résultat</span>
                        </div>
                      )}
                    </div>
                  )}
                </PerfectScrollbar>
              </MenuStyle>
            )}
        </div>
      )}
    </CardStyle>
  )
}

export default memo(BookAutoCompleteSelection)
