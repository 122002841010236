import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const deleteTopRequestSavedService = (
  requestId: string,
  cancelToken?: CancelToken,
): Promise<any> =>
  apiInstance.delete(ApiPathConfig.TOP_REQUESTS_SAVED_BY_ID(requestId), {
    cancelToken,
  })
