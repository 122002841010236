import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { ReactComponent as CrossIcon } from '../../../assets/icons/app/cross.icon.svg'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

const zoomInKeyFrame = keyframes`
  0% {
    transform: scale(0, 0);
  }
  90% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
`

const OverlayStyle = styled.div`
  z-index: 1100;
  // 80 = codehexa 50%
  background-color: #0000004d;
  animation: ${() => fadeIn} 0.3s linear;
  width: 100%;
  height: 100%;
`

const CardStyle = styled.div`
  height: auto;
  border-radius: 8px;
  width: auto;
  animation: ${() => zoomInKeyFrame} 0.3s linear;
`
const GlDialog = ({
  open,
  children,
  onClose = undefined,
  hasCloseButton = false,
  backgroundImage = undefined,
}: {
  open: boolean
  children: ReactElement
  onClose?: () => void
  hasCloseButton?: boolean
  backgroundImage?: string
}) => {
  const onClickOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (onClose) {
      onClose()
    }
  }

  return (
    (open && (
      <OverlayStyle
        className="fixed w-full h-full top-0 left-0 flex flex-row justify-center items-center"
        onClick={onClickOverlay}
        style={{
          opacity: open ? 1 : 0,
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <CardStyle
          className="flex flex-col relative p-4 bg-white shadow-2xl "
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()
          }}
        >
          {(hasCloseButton && (
            <button
              type="button"
              className="
            absolute
            top-2
            right-2
            rounded-[4px]
            border
            border-transparent
            hover:border-[#e3e3e3]
            hover:bg-[#f4f4f4]
            active:bg-[#e3e3e3]
            p-[2px]
          "
              onClick={onClose}
            >
              <CrossIcon className="w-4 h-4" />
            </button>
          )) ||
            null}
          {children}
        </CardStyle>
      </OverlayStyle>
    )) ||
    null
  )
}

export default GlDialog
