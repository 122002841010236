import { createAsyncThunk } from '@reduxjs/toolkit'
import { postPasswordResetByValidateTokenService } from '../../core/api/services/bookmetrie/authentication/post-password-reset-by-validate-token.service'
import { postPasswordResetWithConfirmTokenService } from '../../core/api/services/bookmetrie/authentication/post-password-reset-with-confirm-token.service'

export const changePasswordPostPasswordResetByValidateTokenAction =
  createAsyncThunk(
    'change-password/post-password-reset-by-validate-token',
    async ({ token }: { token: string }): Promise<any> => {
      try {
        const { data } = await postPasswordResetByValidateTokenService({
          token,
        })
        return data
      } catch (error) {
        throw new Error('')
      }
    },
  )

export const changePasswordPostPasswordResetConfirmAction = createAsyncThunk(
  'change-password/post-password-reset-confirm',
  async ({
    token,
    password,
  }: {
    token: string
    password: string
  }): Promise<any> => {
    try {
      const { data } = await postPasswordResetWithConfirmTokenService({
        token,
        password,
      })
      return data
    } catch (error) {
      throw new Error('')
    }
  },
)
