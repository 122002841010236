import { AxiosResponse } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'
import { PaginationResponseInterface } from '../../../../../interface/pagination-response.interface'
import AudienceCatalogInterface from '../../../../../interface/audience/audience-catalog.interface'

const GetAudienceCatalog = (): Promise<
  AxiosResponse<PaginationResponseInterface<AudienceCatalogInterface>>
> => {
  return apiInstance.get(ApiPathConfig.AUDIENCE_CATALOGS)
}

export default GetAudienceCatalog
