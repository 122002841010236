import React from 'react'
import styled from '@emotion/styled'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'

const StudyDropdownClilTitleStyle = styled.div`
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const StudyDropdownDescriptionStyle = styled.div`
  color: #929292;
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
`

interface Props {
  clil: string
}

const StudyObjectGroupDropdownItemClil = ({ clil }: Props) => {
  return (
    <div className="p-1">
      <StudyDropdownClilTitleStyle>
        {clilReferencesConfig[clil].libelle}
      </StudyDropdownClilTitleStyle>
      <StudyDropdownDescriptionStyle>{clil}</StudyDropdownDescriptionStyle>
    </div>
  )
}

export default StudyObjectGroupDropdownItemClil
