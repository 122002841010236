import { useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { getBookSearchService } from '../../../../core/api/services/bookmetrie/get-book-search.service'
import { BookSearchInterface } from '../../../../core/interface/book-search.interface'
import { BookInterface } from '../../../../core/interface/book.interface'
import { getBookByEanIdService } from '../../../../core/api/services/bookmetrie/get-book-by-ean-id.service'

interface UseBookAutoCompletionState {
  isLoadingGetBooksByQuery: boolean
  getBooksByQuerySuccess: boolean
  getBooksByQueryError: boolean
  books: BookSearchInterface[]
}

interface UseBookAutoCompletionHook {
  state: UseBookAutoCompletionState
  actions: {
    getBooksByQuery(query: string): void
    getBookByEan(ean: string | number): void
    setBooks(books: BookSearchInterface[]): void
    resetBooks(): void
  }
}

const useBookAutoCompletionHook = ({
  handleBookSelected,
}: {
  handleBookSelected: (book: BookInterface) => void
}): UseBookAutoCompletionHook => {
  const [lastQuery, setlastQuery] = useState('')

  const [isLoadingGetBooksByQuery, setIsLoadingGetBooksByQuery] =
    useState<boolean>(false)
  const [getBooksByQuerySuccess, setGetBooksByQuerySuccess] =
    useState<boolean>(false)
  const [getBooksByQueryError, setGetBooksByQueryError] =
    useState<boolean>(false)

  const [cancelTokenGetBookSearch, setCancelTokenGetBookSearch] =
    useState<CancelTokenSource | null>(null)
  const [books, setBooks] = useState<BookSearchInterface[]>([])

  const getBooksByQuery = (query: string): void => {
    if (cancelTokenGetBookSearch) {
      cancelTokenGetBookSearch.cancel()
    }
    const cancelToken = axios.CancelToken.source()
    setCancelTokenGetBookSearch(cancelToken)
    setIsLoadingGetBooksByQuery(true)
    setGetBooksByQuerySuccess(false)
    setGetBooksByQueryError(false)
    getBookSearchService(query, cancelToken?.token)
      .then((response) => {
        setGetBooksByQuerySuccess(true)
        const { data } = response
        setBooks(data)
      })
      .catch(() => {
        setGetBooksByQueryError(true)
        setBooks([])
      })
      .finally(() => {
        setIsLoadingGetBooksByQuery(false)
        setCancelTokenGetBookSearch(null)
      })
  }

  const getBookByEan = (ean: string | number): void => {
    setIsLoadingGetBooksByQuery(true)
    getBookByEanIdService(ean)
      .then((response) => {
        const { data } = response
        handleBookSelected(data)
      })
      .catch(() => {})
      .finally(() => setIsLoadingGetBooksByQuery(false))
  }

  const resetBooks = () => {
    setIsLoadingGetBooksByQuery(false)
    setGetBooksByQuerySuccess(false)
    setIsLoadingGetBooksByQuery(false)
    setBooks([])
  }
  return {
    state: {
      isLoadingGetBooksByQuery,
      getBooksByQuerySuccess,
      getBooksByQueryError,
      books,
    },
    actions: {
      getBooksByQuery,
      getBookByEan,
      setBooks,
      resetBooks,
    },
  }
}

export default useBookAutoCompletionHook
