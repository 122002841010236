import axios, { CancelTokenSource } from 'axios'
import { useEffect, useState } from 'react'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import getAuthorSearchService from '../../../../../../core/api/services/bookmetrie/get-author-search.service'

interface UseAuthorAutoCompleteState {
  isLoadingGetAuthors: boolean
  authors: AuthorInterface[]
}

interface UseAuthorAutoCompleteActions {
  getAuthorsByQuery(query: string): void
}

interface UseAuthorInputAutocompletionHook {
  state: UseAuthorAutoCompleteState
  actions: UseAuthorAutoCompleteActions
}

const useAuthorAutocompleteHook = ({
  query,
}: {
  query: string
}): UseAuthorInputAutocompletionHook => {
  let timer: NodeJS.Timer | null = null

  const [cancelTokenGetAuthorSearch, setCancelTokenGetAuthorSearch] =
    useState<CancelTokenSource | null>(null)
  const [isLoadingGetAuthors, setIsLoadingGetAuthors] = useState<boolean>(false)
  const [authors, setAuthors] = useState<AuthorInterface[]>([])

  const getAuthorsByQuery = (query: string): void => {
    if (cancelTokenGetAuthorSearch) {
      cancelTokenGetAuthorSearch.cancel()
    }
    const cancelTokenSource = axios.CancelToken.source()
    setCancelTokenGetAuthorSearch(cancelTokenSource)
    getAuthorSearchService(query, cancelTokenSource.token)
      .then(({ data }) => {
        setAuthors(data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetAuthors(false)
      })
  }

  const handleTimeoutQueryChange = () => {
    timer = setTimeout(() => {
      getAuthorsByQuery(query)
    }, 500)
  }

  useEffect(() => {
    if (!query) {
      setAuthors([])
      setIsLoadingGetAuthors(false)
    } else {
      setIsLoadingGetAuthors(true)
      handleTimeoutQueryChange()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [query])

  return {
    state: { isLoadingGetAuthors, authors },
    actions: { getAuthorsByQuery },
  }
}

export default useAuthorAutocompleteHook
