const setAccessRefreshTokenLocalStorageUtil = async ({
  accessToken,
  refreshToken,
}: {
  accessToken: string
  refreshToken: string
}): Promise<boolean> =>
  new Promise((resolve) => {
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('refresh_token', refreshToken)
    resolve(true)
  })

export default setAccessRefreshTokenLocalStorageUtil
