import { RootState } from '../../store'
import {
  CompareGroupType,
  StudyObjectType,
} from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import {
  CompareGroupValueType,
  NewReadershipProfileStepEnum,
  StudyObjectValueType,
} from '../../interfaces/analytics-details/new-readership-profile-state.interface'
import { ReadershipProfilePreviewInterface } from '../../../core/interface/analytics/readership-profile-preview.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticPreviewTypeErrorEnum } from '../../../core/enums/analytic-preview-type-error'
import { BookInterface } from '../../../core/interface/book.interface'

export const selectNewAnalyticsReadershipProfileStateStep = (
  state: RootState,
): NewReadershipProfileStepEnum => {
  const { step } = state.newAnalyticsReadershipProfileState
  return step
}
export const selectNewAnalyticsReadershipProfileStateStudyObjectType = (
  state: RootState,
): StudyObjectType => {
  const { studyObjectType } = state.newAnalyticsReadershipProfileState
  return studyObjectType
}

export const selectNewAnalyticsReadershipProfileStateStudyObjectValue = (
  state: RootState,
): StudyObjectValueType => {
  const { studyObjectValue } = state.newAnalyticsReadershipProfileState
  return studyObjectValue
}

export const selectNewAnalyticsReadershipProfileStateStudyObjectBooks = (
  state: RootState,
): BookInterface[] => {
  const { studyObjectBooks } = state.newAnalyticsReadershipProfileState
  return studyObjectBooks
}

export const selectNewAnalyticsReadershipProfileStateCompareGroupType = (
  state: RootState,
): CompareGroupType => {
  const { compareGroupType } = state.newAnalyticsReadershipProfileState
  return compareGroupType
}

export const selectNewAnalyticsReadershipProfileStateCompareGroupValue = (
  state: RootState,
): CompareGroupValueType => {
  const { compareGroupValue } = state.newAnalyticsReadershipProfileState
  return compareGroupValue
}
export const selectNewAnalyticsReadershipProfileStateCompareGroupBooks = (
  state: RootState,
): BookInterface[] => {
  const { compareGroupBooks } = state.newAnalyticsReadershipProfileState
  return compareGroupBooks
}

export const selectNewAnalyticsReadershipProfileStateIsLoadingPostPreview = (
  state: RootState,
): boolean => {
  const { isLoadingPostPreview } = state.newAnalyticsReadershipProfileState
  return isLoadingPostPreview
}

export const selectNewAnalyticsReadershipProfileStatePostPreviewSuccess = (
  state: RootState,
): boolean => {
  const { postPreviewSuccess } = state.newAnalyticsReadershipProfileState
  return postPreviewSuccess
}

export const selectNewAnalyticsReadershipProfileStatePostPreviewError = (
  state: RootState,
): boolean => {
  const { postPreviewError } = state.newAnalyticsReadershipProfileState
  return postPreviewError
}

export const selectNewAnalyticsReadershipProfileStateIsLoadingGetPreviewById = (
  state: RootState,
): boolean => {
  const { isLoadingGetPreviewById } = state.newAnalyticsReadershipProfileState
  return isLoadingGetPreviewById
}

export const selectNewAnalyticsReadershipProfileStateGetPreviewByIdSuccess = (
  state: RootState,
): boolean => {
  const { getPreviewByIdSuccess } = state.newAnalyticsReadershipProfileState
  return getPreviewByIdSuccess
}
export const selectNewAnalyticsReadershipProfileStateGetPreviewByIdError = (
  state: RootState,
): boolean => {
  const { getPreviewByIdError } = state.newAnalyticsReadershipProfileState
  return getPreviewByIdError
}

export const selectNewAnalyticsReadershipProfileStatePreviewOutput = (
  state: RootState,
): ReadershipProfilePreviewInterface | null => {
  const { previewOutput } = state.newAnalyticsReadershipProfileState
  return previewOutput
}

export const selectNewAnalyticsReadershipProfileStateIsLoadingReadershipProfileValidation =
  (state: RootState): boolean => {
    const { isLoadingReadershipProfileValidation } =
      state.newAnalyticsReadershipProfileState
    return isLoadingReadershipProfileValidation
  }

export const selectNewAnalyticsReadershipProfileStateAnalyticsReadershipProfilePreview =
  (state: RootState): AnalyticDetailsInterface | null => {
    const { analyticsReadershipProfilePreview } =
      state.newAnalyticsReadershipProfileState
    return analyticsReadershipProfilePreview
  }

export const selectNewAnalyticsReadershipProfileStatePutReadershipProfileValidateSuccess =
  (state: RootState): boolean => {
    const { putReadershipProfileValidateSuccess } =
      state.newAnalyticsReadershipProfileState
    return putReadershipProfileValidateSuccess
  }

export const selectNewAnalyticsReadershipProfileStatePutReadershipProfileValidateError =
  (state: RootState): boolean => {
    const { putReadershipProfileValidateError } =
      state.newAnalyticsReadershipProfileState
    return putReadershipProfileValidateError
  }

export const selectNewAnalyticsReadershipProfileStatePostPreviewTypeError = (
  state: RootState,
): AnalyticPreviewTypeErrorEnum => {
  const { postPreviewTypeError } = state.newAnalyticsReadershipProfileState
  return postPreviewTypeError
}
