import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useRef } from 'react'
import { ReactComponent as CheckedBlockIcon } from '../../../assets/icons/app/checked-block.icon.svg'
import { ReactComponent as BarreCheckBoxIcon } from '../../../assets/icons/app/barre-checkbox.icon.svg'

const CheckboxLayout = styled.label`
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  height: 16px;
  :hover {
    background-color: #e3e3e3;
    border-radius: 2px;
    cursor: ${({ disable }: { disable: boolean }) =>
      disable ? 'not-allowed' : 'pointer'};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checker {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #2525251a;
    border-radius: 2px;
    box-sizing: border-box;
  }

  .checker-icon {
    display: none;
  }

  &:hover input ~ .checker {
    background-color: #e3e3e3;
    border-radius: 2px;
  }

  input:checked ~ .checker,
  input:indeterminate ~ .checker {
    display: none;
  }

  .checker:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checker:after {
    display: block;
    width: 16px;
    height: 16px;
    border: 3px solid ${({ color }: { color?: string }) => color || '#252525'};
    background: ${({ color }: { color?: string }) => color || '#252525'};
  }

  input:indeterminate ~ .checker:after {
    display: block;
    width: 16px;
    height: 16px;
    border-color: ${({ color }: { color?: string }) => color || '#252525'};
    border-style: solid;
    border-width: 7px 3px;
    background: white;
  }

  .checked-checked,
  .checked-indeterminate {
    display: none;
  }

  &:hover input ~ .checker {
    background-color: #e3e3e3;
    border-radius: 2px;
  }

  input:checked ~ .checked-checked {
    display: block;
    color: ${({ color }: { color?: string }) => color || '#252525'};
  }

  input:indeterminate ~ .checked-indeterminate {
    display: block;
    color: ${({ color }: { color?: string }) => color || '#252525'};
    border-radius: 2px;
  }
`

interface Props {
  disable?: boolean
  color?: string
  value: 'checked' | 'empty' | 'indeterminate'
  onChangeEvent: (checked: boolean) => void
}

const GlCheckBoxInputV2 = ({
  disable = false,
  value,
  color,
  onChangeEvent,
}: Props): ReactElement => {
  const checkboxRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (checkboxRef.current) {
      if (value === 'checked') {
        checkboxRef.current.checked = true
        checkboxRef.current.indeterminate = false
      } else if (value === 'empty') {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = false
      } else if (value === 'indeterminate') {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = true
      }
    }
  }, [value])

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onChangeEvent(event.target.checked)
  }

  return (
    <CheckboxLayout color={color} disable={disable}>
      <input
        ref={checkboxRef}
        type="checkbox"
        onChange={onChangeCheckBox}
        disabled={disable}
      />
      <CheckedBlockIcon
        className="checked-checked"
        style={{
          width: 16,
          height: 16,
        }}
      />
      <BarreCheckBoxIcon
        className="checked-indeterminate"
        style={{
          width: 16,
          height: 16,
        }}
      />
      <span className="checker" />
    </CheckboxLayout>
  )
}

export default GlCheckBoxInputV2
