import { ReactNode } from 'react'

const ScatterYAxisLabel: (props: any) => ReactNode = ({
  value,
  viewBox,
}: {
  value: string
  viewBox: {
    y: number
    width: number
  }
}) => {
  const { y, width } = viewBox

  return (
    <g>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          <feFlood floodColor="white" />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
      </defs>
      <text
        filter="url(#solid)"
        x={width / 2 + 25}
        y={y + 5}
        textAnchor="start"
      >
        {value}
      </text>
      <text x={width / 2 + 25} y={y + 5} fill="#000" textAnchor="start">
        {value}
      </text>
    </g>
  )
}

export default ScatterYAxisLabel
