export enum TagFilterEnum {
  TAG_IS_OWN = 'is_own',
  TAG_IS_LIKE = 'is_like',
  TAG_IS_WISH = 'is_wish',
  TAG_IS_READ = 'is_read',
  TAG_IS_READING = 'is_reading',
}

export const TagMapEnum: { [key: string]: TagFilterEnum } = {
  is_own: TagFilterEnum.TAG_IS_OWN,
  is_like: TagFilterEnum.TAG_IS_LIKE,
  is_wish: TagFilterEnum.TAG_IS_WISH,
  is_read: TagFilterEnum.TAG_IS_READ,
  is_reading: TagFilterEnum.TAG_IS_READING,
}
