import styled from '@emotion/styled'
import React from 'react'
import { ReactComponent as EditorIcon } from '../../../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as FormatIcon } from '../../../../../assets/icons/app/format.icon.svg'
import GlBadgeClilV2 from '../../../../share/badge/GlBadgeClilV2'

const LabelIconStyle = styled.div`
  display: flex;
  align-items: center;
  color: #595959;
  flex-direction: row;
  gap: 6px;
  width: 220px;
  height: 24px;
  background: #f7f7f7;
  border-radius: 2px;
`

const IconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #252525;
  width: 24px;
  height: 24px;
`

const LabelStyle = styled.span`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125.37%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface Props {
  edition: string
  clil1: number | null
  format: string
  clil2: number | null
}

const DynamicsBillDetailsBadges = ({
  edition,
  clil1,
  format,
  clil2,
}: Props) => {
  return (
    <div className="self-end grid gap-x-3 gap-y-1 grid-cols-2 mr-8">
      <LabelIconStyle>
        <IconStyle>
          <EditorIcon className="w-4 h-4" />
        </IconStyle>
        <LabelStyle>{edition}</LabelStyle>
      </LabelIconStyle>
      <GlBadgeClilV2 id={`${clil1}`} />
      <LabelIconStyle>
        <IconStyle>
          <FormatIcon className="w-4 h-4" />
        </IconStyle>
        <LabelStyle>{format}</LabelStyle>
      </LabelIconStyle>
      <GlBadgeClilV2 id={`${clil2}`} />
    </div>
  )
}

export default DynamicsBillDetailsBadges
