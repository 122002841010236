import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uniq } from 'lodash'

import { InputStyle } from '../../ReadershipProfileStyles'
import { ReactComponent as ChevronBottomIcon } from '../../../../../../assets/icons/app/bottom.icon.svg'
import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/app/cross.icon.svg'
import clilDropdownUtil, {
  ClilDropDownInterface,
} from '../../../../../../core/config/clil-dropdown.util'
import ClilMenu from './ClilMenu'
import { normalizedContains } from '../../../../../../core/utils/search-text.util'

const ClilInputAutoCompletion = ({
  color,
  disable,
  inputValue,
  handleSelectClil,
}: {
  color: string
  disable: boolean
  inputValue: string[]
  handleSelectClil: (clilIds: string[]) => void
}) => {
  const clils = clilDropdownUtil().map((clil: ClilDropDownInterface) => {
    return {
      ...clil,
      show: true,
    }
  })

  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [openLevels, setOpenLevels] = useState<string[]>([])
  const [needCloseMenu, setNeedCloseMenu] = useState<boolean>(false)
  const [clilsToDisplay, setClilsToDisplay] =
    useState<ClilDropDownInterface[]>(clils)

  const filterClilBySearch = (clilSearch: string) => {
    if (clilSearch) {
      return clils.map((clil: ClilDropDownInterface) => {
        if (normalizedContains(`${clil.id} ${clil.label}`, clilSearch)) {
          return { ...clil, show: true }
        }
        return { ...clil, show: false }
      })
    }
    return clils
  }

  const openLevelsOfClilFilterSearch = (clilId: string[]) => {
    setOpenLevels(clilId)
  }

  const addParentClilOnFilterSearch = (clilSearch: string) => {
    const clilsFiltered = filterClilBySearch(clilSearch)

    const clilParentIds = clilsFiltered
      .filter((clil) => clil.show)
      .map((clil) => clil.parentId)

    const clilGrandParentIds = clils
      .filter((clil) => clilParentIds.includes(clil.id))
      .map((clil) => clil.parentId)

    const allClilsIdsUniq = uniq([
      ...clilParentIds,
      ...clilGrandParentIds,
      ...clilsFiltered.filter((clil) => clil.show).map((clil) => clil.id),
    ])

    const clilsLegacy = clils.map((clil) => {
      if (allClilsIdsUniq.includes(clil.id)) {
        return { ...clil, show: true }
      }
      return { ...clil, show: false }
    })

    const clilIdsToOpen = clilsLegacy
      .filter((clil: ClilDropDownInterface) => clil.show && clil.parentId)
      .map((clil) => clil.parentId)

    if (clilIdsToOpen.length < 10) {
      openLevelsOfClilFilterSearch(uniq(clilIdsToOpen) as string[])
    }

    return clilsLegacy
  }

  const inputValueOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setQuery(event.target.value)
    setClilsToDisplay(addParentClilOnFilterSearch(event.target.value))
  }

  const onClickReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setQuery('')
    setClilsToDisplay(clils)
  }

  const onClickToggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setMenuOpen(!menuOpen)
    if (!menuOpen) {
      setQuery('')
      setClilsToDisplay(clils)
    }
  }

  const handleClilSelected = (clilNumber: string[]) => {
    handleSelectClil(clilNumber)
    setMenuOpen(false)
  }

  const handleFocusToInput = () => setMenuOpen(true)
  const handleBlurToInput = () => setNeedCloseMenu(true)

  let timer: NodeJS.Timer | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setMenuOpen(false)
      setNeedCloseMenu(false)
    }, 300)
  }

  useEffect(() => {
    if (needCloseMenu) {
      handleTimeOut()
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needCloseMenu])

  return (
    <div className="relative flex flex-col h-full gap-2">
      <div className="relative flex flex-col flex-none">
        <InputStyle
          disabled={disable}
          type="text"
          placeholder={t('SearchACategories')}
          value={query}
          onChange={inputValueOnChange}
          onFocus={handleFocusToInput}
          onBlur={handleBlurToInput}
        />
        {(query && (
          <button
            type="button"
            className="absolute right-10 top-4"
            onClick={onClickReset}
          >
            <CrossIcon
              style={{
                height: 16,
                width: 16,
              }}
            />
          </button>
        )) ||
          null}
        <button
          type="button"
          className="absolute right-3 top-3"
          onClick={onClickToggleMenu}
        >
          <ChevronBottomIcon
            style={{
              height: 24,
              width: 24,
            }}
          />
        </button>
      </div>
      <ClilMenu
        color={color}
        clils={clilsToDisplay}
        openLevels={openLevels}
        setOpenLevels={setOpenLevels}
        inputValue={inputValue}
        handleSelectClil={handleClilSelected}
      />
    </div>
  )
}

export default ClilInputAutoCompletion
