import { SetColorEnum } from '../../../../../core/enums/set-color.enum'
import { PreviewTypeConfig } from '../../../../../core/config/PreviewTypeConfig'
import PreviewCard from '../details/PreviewCard'
import { ReadershipProfilePreviewComparisonInterface } from '../../../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import PreviewCardWaiting from '../details/PreviewCardWaiting'
import { PreviewTypeEnum } from '../../../../../core/enums/preview-type.enum'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import AnalyticsOutputWrapper from './AnalyticsOutputWrapper'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'

const ReadershipProfilePreviewOutput = ({
  setColor,
  outputId,
  previewOutputs,
}: {
  setColor: SetColorEnum
  outputId?: string
  previewOutputs: ReadershipProfilePreviewComparisonInterface[]
}) => {
  const waitingPreviewOutputs = [
    {
      type: PreviewTypeEnum.READERS,
    },
    {
      type: PreviewTypeEnum.WOMAN_PERCENT,
    },
    {
      type: PreviewTypeEnum.TOTAL_BOOK,
    },
    {
      type: PreviewTypeEnum.MEAN_AGE,
    },
    {
      type: PreviewTypeEnum.AVG_BOOK_BY_READER,
    },
  ]

  return (
    <AnalyticsOutputWrapper
      outputId={outputId}
      display={AnalyticOutputLayoutSizeEnum.FULL}
      previewModule={previewOutputs}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_PREVIEW}
      customStyle={{ flexDirection: 'row-reverse', padding: 8, gap: 8 }}
    >
      <div className="flex flex-row w-full overflow-hidden hover:overflow-auto">
        {previewOutputs.length === 0
          ? waitingPreviewOutputs.map(
              (comparison: { type: PreviewTypeEnum }) => (
                <PreviewCardWaiting
                  key={`preview-card-${comparison.type}`}
                  icon={PreviewTypeConfig[comparison.type].icon}
                  label={PreviewTypeConfig[comparison.type].label}
                />
              ),
            )
          : previewOutputs
              .filter((c) => c.is_visible)
              .map(
                (comparison: ReadershipProfilePreviewComparisonInterface) => (
                  <PreviewCard
                    key={`preview-card-${comparison.id}`}
                    icon={PreviewTypeConfig[comparison.type]?.icon}
                    suffix={PreviewTypeConfig[comparison.type]?.suffix}
                    compareGroupValue={comparison?.comparison_value}
                    studyObjectValue={comparison?.subject_value}
                    label={PreviewTypeConfig[comparison?.type].label}
                    setColor={setColor}
                  />
                ),
              )}
      </div>
    </AnalyticsOutputWrapper>
  )
}

export default ReadershipProfilePreviewOutput
