import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CompareGroupValueType,
  NewReadershipProfileStateInterface,
  NewReadershipProfileStepEnum,
  StudyObjectValueType,
} from '../../interfaces/analytics-details/new-readership-profile-state.interface'
import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
  StudyObjectType,
} from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import {
  newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile,
  newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate,
} from '../../actions/analytics-details/new-analytics-readership-profile.actions'
import { AuthorInterface } from '../../../core/interface/author.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../../core/enums/analytic-input-type.enum'
import { AnalyticOutputTypeEnum } from '../../../core/enums/analytic-output-type.enum'
import { AnalyticPreviewTypeErrorEnum } from '../../../core/enums/analytic-preview-type-error'
import { BookInterface } from '../../../core/interface/book.interface'

const initialState: NewReadershipProfileStateInterface = {
  analyticsName: '',
  step: NewReadershipProfileStepEnum.START,
  studyObjectType: StudyObjectGroupCompareEnum.TITLE,
  studyObjectValue: null,
  studyObjectBooks: [],
  compareGroupType: StudyObjectGroupCompareEnum.TITLE,
  compareGroupValue: null,
  compareGroupBooks: [],
  isLoadingPostPreview: false,
  postPreviewSuccess: false,
  postPreviewError: false,
  previewId: null,
  previewOutput: null,
  getPreviewByIdSuccess: false,
  getPreviewByIdError: false,
  isLoadingGetPreviewById: false,
  isLoadingReadershipProfileValidation: false,
  analyticsReadershipProfilePreview: null,
  postReadershipProfileValidateSuccess: false,
  postReadershipProfileValidateError: false,
  postPreviewTypeError: null,
}

const newReadershipProfileSlice = createSlice({
  name: 'newReadershipProfileReducer',
  initialState,
  reducers: {
    newReadershipProfileStateSetAnalyticsName: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<string>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      analyticsName: action.payload,
    }),
    newReadershipProfileStateSetStudyObjectType: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<StudyObjectType>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      studyObjectType: action.payload,
    }),
    newReadershipProfileStateSetStudyObjectValue: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<{
        value: StudyObjectValueType
        eanIdsToRemove?: number[]
      }>,
    ): NewReadershipProfileStateInterface => {
      const { value, eanIdsToRemove } = action.payload
      return {
        ...state,
        studyObjectValue: action.payload.value,
        ...(eanIdsToRemove && eanIdsToRemove.length
          ? {
              studyObjectBooks: state.studyObjectBooks.filter(
                (book: BookInterface) => !eanIdsToRemove.includes(book.id_ean),
              ),
            }
          : {}),
      }
    },

    newReadershipProfileStateSetStudyObjectBooks: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<BookInterface[]>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      studyObjectBooks: action.payload,
    }),
    newReadershipProfileStateSetCompareGroupType: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<CompareGroupType>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      compareGroupType: action.payload,
    }),
    newReadershipProfileStateSetCompareGroupValue: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<{
        value: CompareGroupValueType
        eanIdsToRemove?: number[]
      }>,
    ): NewReadershipProfileStateInterface => {
      const { value, eanIdsToRemove } = action.payload
      return {
        ...state,
        compareGroupValue: value,
        ...(eanIdsToRemove && eanIdsToRemove.length
          ? {
              compareGroupBooks: [
                ...state.compareGroupBooks.filter(
                  (book: BookInterface) =>
                    !eanIdsToRemove.includes(book.id_ean),
                ),
              ],
            }
          : {}),
      }
    },
    newReadershipProfileStateSetCompareGroupBooks: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<BookInterface[]>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      compareGroupBooks: action.payload,
    }),
    newReadershipProfileStateReinitializePostPreview: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<number[]>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      postPreviewSuccess: false,
      postPreviewError: false,
    }),
    newReadershipProfileStateGotToStartStep: (
      state: NewReadershipProfileStateInterface,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      step: NewReadershipProfileStepEnum.START,
    }),
    reinitializeNewReadershipProfileState: (
      state: NewReadershipProfileStateInterface,
    ): NewReadershipProfileStateInterface => ({
      ...initialState,
    }),
    newReadershipProfileStateSetPostPreviewTypeError: (
      state: NewReadershipProfileStateInterface,
      action: PayloadAction<AnalyticPreviewTypeErrorEnum>,
    ): NewReadershipProfileStateInterface => ({
      ...state,
      postPreviewTypeError: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile.pending,
        (
          state: NewReadershipProfileStateInterface,
        ): NewReadershipProfileStateInterface => {
          return {
            ...state,
            isLoadingPostPreview: true,
            postPreviewSuccess: false,
            postPreviewError: false,
            previewOutput: null,
            step: NewReadershipProfileStepEnum.PREVIEW,
            analyticsReadershipProfilePreview: null,
          }
        },
      )
      .addCase(
        newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile.fulfilled,
        (
          state: NewReadershipProfileStateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): NewReadershipProfileStateInterface => ({
          ...state,
          isLoadingPostPreview: false,
          postPreviewSuccess: true,
          postPreviewError: false,
          step: NewReadershipProfileStepEnum.PREVIEW,
          previewOutput:
            action.payload.inputs
              .find(
                (input: AnalyticInputInterface) =>
                  input.type === AnalyticInputTypeEnum.READERSHIP_PROFILE,
              )
              ?.outputs.find(
                (output) =>
                  output.type ===
                  AnalyticOutputTypeEnum.READERSHIP_PROFILE_PREVIEW,
              )?.readership_profile_preview || null,
          analyticsReadershipProfilePreview: action.payload,
        }),
      )
      .addCase(
        newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile.rejected,
        (
          state: NewReadershipProfileStateInterface,
        ): NewReadershipProfileStateInterface => ({
          ...state,
          isLoadingPostPreview: false,
          postPreviewSuccess: false,
          postPreviewError: true,
          analyticsReadershipProfilePreview: null,
        }),
      )
      .addCase(
        newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate.pending,
        (
          state: NewReadershipProfileStateInterface,
        ): NewReadershipProfileStateInterface => ({
          ...state,
          isLoadingReadershipProfileValidation: true,
          postReadershipProfileValidateSuccess: false,
          postReadershipProfileValidateError: false,
        }),
      )
      .addCase(
        newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate.fulfilled,
        (
          state: NewReadershipProfileStateInterface,
        ): NewReadershipProfileStateInterface => ({
          ...state,
          isLoadingReadershipProfileValidation: false,
          postReadershipProfileValidateSuccess: true,
          postReadershipProfileValidateError: false,
        }),
      )
      .addCase(
        newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate.rejected,
        (
          state: NewReadershipProfileStateInterface,
        ): NewReadershipProfileStateInterface => ({
          ...state,
          isLoadingReadershipProfileValidation: false,
          postReadershipProfileValidateSuccess: false,
          postReadershipProfileValidateError: true,
        }),
      )
  },
})

export const {
  reinitializeNewReadershipProfileState,
  newReadershipProfileStateSetAnalyticsName,
  newReadershipProfileStateReinitializePostPreview,
  newReadershipProfileStateSetStudyObjectType,
  newReadershipProfileStateSetCompareGroupType,
  newReadershipProfileStateSetStudyObjectBooks,
  newReadershipProfileStateSetStudyObjectValue,
  newReadershipProfileStateSetCompareGroupValue,
  newReadershipProfileStateSetCompareGroupBooks,
  newReadershipProfileStateGotToStartStep,
  newReadershipProfileStateSetPostPreviewTypeError,
} = newReadershipProfileSlice.actions
export const newReadershipProfileReducer = newReadershipProfileSlice.reducer
