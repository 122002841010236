import React, { ReactElement, useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as CrossIcon } from '../../../assets/icons/app/cross.icon.svg'

const FormInput = styled.input`
  border: 1px solid #929292;
  border-radius: 4px;
  position: relative;
  padding: 8px;
  width: 100%;
  outline: 0;
  // box-shadow: 0 1px 0 0 #e5e5e5;
  overflow: hidden;
  color: black;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Body/B2 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  ::placeholder {
    color: #929292;
  }
  &:focus {
    border-color: ${({ hasError }: { hasError: boolean }) =>
      (hasError && '#f97e60') || '#929292'};
  }
`

const FormLabel = styled.label`
  position: absolute;
  left: 0;
  top: 10px;
  color: ${({ hasError }: { hasError: boolean }) =>
    (hasError && '#f97e60') || '#999'};
  background-color: #fff;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  transform: translateY(-125%);
  font-size: 0.75em;
`
const FormGroupContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  color: ${({ hasError }: { hasError: boolean }) =>
    (hasError && '#f97e60') || ''};
`

const CrossButtonStyle = styled.button`
  color: ${({ hasError }: { hasError: boolean }) =>
    (hasError && '#f97e60') || '999'};
  &:hover {
    color: #595959;
  }
  &:active {
    color: #000000;
  }
`

interface Props {
  hasError?: boolean
  hasErrorMessage?: string
  label?: string
  placeholder: string
  disabled?: boolean
  readOnly?: boolean
  type: 'text' | 'email' | 'number' | 'password'
  keyValue?: string
  children?: ReactElement
  value: string
  onChangeValue?: (value: string) => void
  suffix?: ReactElement
  prefix?: ReactElement
  hasClearButton?: boolean
}

const GlMatInputOutline = ({
  hasError = false,
  hasErrorMessage = undefined,
  label = undefined,
  placeholder,
  disabled = false,
  readOnly = false,
  type,
  keyValue,
  children = undefined,
  value,
  onChangeValue,
  suffix,
  prefix,
  hasClearButton = false,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false)
  return (
    <FormGroupContainer
      hasError={hasError}
      className="flex flex-col form-group w-100 relative"
    >
      {(label && (
        <FormLabel
          hasError={hasError}
          htmlFor={`${keyValue}-input`}
          className={focused ? `focused` : ''}
        >
          {label}
        </FormLabel>
      )) ||
        null}
      <div className="flex flex-row items-center justify-start gap-1">
        {suffix || null}
        <FormInput
          hasError={hasError}
          className="filled"
          id={`${keyValue}-input`}
          name={`${keyValue}-input`}
          type={type}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeValue && onChangeValue(event.target.value)
          }}
        />
      </div>

      {children}
      {prefix && (
        <div
          className="absolute top-4"
          style={{
            right: (value && hasClearButton && 32) || 8,
          }}
        >
          {prefix}
        </div>
      )}
      {value && hasClearButton && (
        <CrossButtonStyle
          hasError={hasError}
          type="button"
          className="absolute right-2 top-3.5"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            onChangeValue && onChangeValue('')
          }}
        >
          <CrossIcon className="w-3 h-3" />
        </CrossButtonStyle>
      )}
      {(hasError && (
        <div className="p-1 text-justify">{hasErrorMessage}</div>
      )) || <div className="p-1 text-justify h-[28px]" />}
    </FormGroupContainer>
  )
}

export default GlMatInputOutline
