import styled from '@emotion/styled'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { AnalyticOutputInterface } from '../../../../../../core/interface/analytics/analytic-output.interface'
import { ReactComponent as AddModuleIcon } from '../../../../../../assets/icons/app/add-icon-plain.icon.svg'
import { ReactComponent as TitleIcon } from '../../../../../../assets/icons/app/title-h.icon.svg'
import { ReactComponent as TitleDatasetIcon } from '../../../../../../assets/icons/app/title-dataset.icon.svg'
import { ReactComponent as TitleTabularIcon } from '../../../../../../assets/icons/app/table-view.icon.svg'
import { ReactComponent as TextBoxesIcon } from '../../../../../../assets/icons/app/text-boxes.icon.svg'
import { ReactComponent as ImageIcon } from '../../../../../../assets/icons/app/image.icon.svg'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'
import OrganizeMenuDraggableItem from './OrganizeMenuDraggableItem'
import { IconContainerStyle, TitleSectionStyle } from './OrganizeMenuStyles'

const InputAddModuleTextStyle = styled.div`
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const InputAddModuleStyle = styled.button`
  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  background-color: #252525;
  border-radius: 4px;
  &:hover {
    background-color: #525252;
  }
`
const DraggableItemsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: screen;
`
const DragIndicatorStyle = styled.div`
  background-color: #e3e3e3;
  height: 4px;
  border-radius: 4px;
`

interface Props {
  outputs: AnalyticOutputInterface[]
  setShowAddModuleView: (value: boolean) => void
  handleOrderChange: (outputs: AnalyticOutputInterface[]) => void
}

const OrganizeMenuStructure = ({
  outputs,
  setShowAddModuleView,
  handleOrderChange,
}: Props) => {
  const { t } = useTranslation()
  const dragOuput = useRef<number>()
  const [dragOverOuput, setDragOverOuput] = useState<number>()

  const dragStartOutput = (position: number) => {
    dragOuput.current = position
  }

  const dragEnterOutput = (position: number) => {
    setDragOverOuput(position)
  }

  const dropOnOutput = () => {
    if (
      dragOuput.current === undefined ||
      dragOverOuput === undefined ||
      dragOuput.current === dragOverOuput
    )
      return
    const copyListItems = [...outputs]
    const dragOuputContent = copyListItems[dragOuput.current]
    copyListItems.splice(dragOuput.current, 1)
    copyListItems.splice(dragOverOuput, 0, dragOuputContent)
    dragOuput.current = undefined
    setDragOverOuput(undefined)
    handleOrderChange(copyListItems)
  }

  const handleScrollToThisId = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }

  return (
    <PerfectScrollbar
      className="flex flex-col gap-4"
      onDragOver={(e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
      }}
    >
      <InputAddModuleStyle onClick={() => setShowAddModuleView(true)}>
        <AddModuleIcon className="w-[14px] h-[14px] text-[#fff]" />
        <InputAddModuleTextStyle>
          {t('organizeMenu.addModule')}
        </InputAddModuleTextStyle>
      </InputAddModuleStyle>
      <div>
        <DraggableItemsStyle>
          {outputs &&
            outputs.map(
              (output: AnalyticOutputInterface, output_index: number) => (
                <React.Fragment key={output.id}>
                  {dragOverOuput === output_index &&
                    dragOuput.current !== undefined &&
                    dragOuput.current !== output_index &&
                    dragOuput.current > dragOverOuput && <DragIndicatorStyle />}
                  {output.title?.text && (
                    <OrganizeMenuDraggableItem
                      itemId={output.id}
                      itemTitle={output.title?.text}
                      itemIndex={output_index}
                      ItemIcon={TitleIcon}
                      isTitle
                      dropOnOutput={dropOnOutput}
                      dragEnterOutput={dragEnterOutput}
                      dragStartOutput={dragStartOutput}
                      handleScrollToThisId={handleScrollToThisId}
                    />
                  )}
                  {output.readership_profile_preview && (
                    <OrganizeMenuDraggableItem
                      itemId={output.id}
                      itemTitle={t('organizeMenu.sample')}
                      itemIndex={output_index}
                      ItemIcon={TitleDatasetIcon}
                      dropOnOutput={dropOnOutput}
                      dragEnterOutput={dragEnterOutput}
                      dragStartOutput={dragStartOutput}
                      handleScrollToThisId={handleScrollToThisId}
                    />
                  )}
                  {output.textbox && (
                    <OrganizeMenuDraggableItem
                      itemId={output.id}
                      itemTitle={output.textbox.title}
                      itemIndex={output_index}
                      ItemIcon={TextBoxesIcon}
                      dropOnOutput={dropOnOutput}
                      dragEnterOutput={dragEnterOutput}
                      dragStartOutput={dragStartOutput}
                      handleScrollToThisId={handleScrollToThisId}
                    />
                  )}
                  {output.image && (
                    <OrganizeMenuDraggableItem
                      itemId={output.id}
                      itemTitle={t('addModuleMenu.picture.title')}
                      itemIndex={output_index}
                      ItemIcon={ImageIcon}
                      dropOnOutput={dropOnOutput}
                      dragEnterOutput={dragEnterOutput}
                      dragStartOutput={dragStartOutput}
                      handleScrollToThisId={handleScrollToThisId}
                    />
                  )}
                  {output.study_object && (
                    <OrganizeMenuDraggableItem
                      itemId={output.id}
                      itemTitle={t('organizeMenu.studyObject')}
                      itemIndex={output_index}
                      ItemIcon={TitleTabularIcon}
                      isTitle
                      dropOnOutput={dropOnOutput}
                      dragEnterOutput={dragEnterOutput}
                      dragStartOutput={dragStartOutput}
                      handleScrollToThisId={handleScrollToThisId}
                    />
                  )}
                  {output.dataset_output && (
                    <div
                      className="cursor-grab bg-white hover:bg-[#e9e9e9]"
                      key={output.id}
                      onDragStart={(e) => {
                        e.dataTransfer.effectAllowed = 'all'
                        dragStartOutput(output_index)
                      }}
                      onDragOver={(e) => {
                        e.preventDefault()
                        e.dataTransfer.dropEffect = 'move'
                      }}
                      onDragEnter={(e) => {
                        e.preventDefault()
                        e.dataTransfer.dropEffect = 'move'
                        dragEnterOutput(output_index)
                      }}
                      onDragEnd={dropOnOutput}
                      draggable
                    >
                      {output.dataset_output?.dataset_modules.map(
                        (dataset_module) => {
                          return (
                            <div
                              className="flex py-1 pr-1 pl-8 gap-2 items-center"
                              key={dataset_module.id}
                              onClick={() =>
                                handleScrollToThisId(dataset_module.id)
                              }
                            >
                              <IconContainerStyle>
                                {dataset_module.type ===
                                AnalyticsChartTypeEnum.TABULAR ? (
                                  <TitleTabularIcon className="w-4 h-4 shrink-0" />
                                ) : (
                                  <TitleDatasetIcon className="w-4 h-4 shrink-0" />
                                )}
                              </IconContainerStyle>

                              <TitleSectionStyle>
                                {dataset_module.title}
                              </TitleSectionStyle>
                            </div>
                          )
                        },
                      )}
                    </div>
                  )}
                  {dragOverOuput === output_index &&
                    dragOuput.current !== undefined &&
                    dragOuput.current !== output_index &&
                    dragOuput.current < dragOverOuput && <DragIndicatorStyle />}
                </React.Fragment>
              ),
            )}
        </DraggableItemsStyle>
      </div>
    </PerfectScrollbar>
  )
}

export default OrganizeMenuStructure
