import { PaginationResponseInterface } from '../../../core/interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../../core/interface/report/report-light-response.interface'
import AnalyticsCard from './AnalyticsCard'
import CreateAnalyticsCard from './CreateAnalyticsCard'
import AnalyticsDashboardAvailableModuleType from '../../../core/types/analytics-dashboard-available-module.interface'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import AnalyticsDashboardType from '../../../core/enums/analytics-dashboard-type.enum'

const AnalyticsDashboardCardView = ({
  analytics,
  displayOnlyMyAnalytics,
  handleCreateNewAnalytics,
  handleClickAnalytics,
  analyticsTypeSelected,
  handleReloadAction,
  companyUserId,
  isPrivate,
}: {
  displayOnlyMyAnalytics: boolean
  analytics: PaginationResponseInterface<ReportLightResponseInterface> | null
  handleCreateNewAnalytics: () => void
  handleClickAnalytics: (id: string, type: AnalyticsTypeEnum) => void
  analyticsTypeSelected: AnalyticsDashboardAvailableModuleType
  handleReloadAction: () => void
  companyUserId: string
  isPrivate?: boolean
}) => {
  return (
    <div className="absolute top-0 left-0 flex-auto flex flex-row flex-wrap gap-4 justify-center items-center w-full h-full">
      {(analytics && analytics?.count > 0 && (
        <>
          <CreateAnalyticsCard
            isPrivate={isPrivate}
            handleCreateNewAnalytics={handleCreateNewAnalytics}
          />
          {analytics.results
            .filter((analytic: ReportLightResponseInterface) =>
              displayOnlyMyAnalytics
                ? analytic.owner?.id === companyUserId
                : analytic,
            )
            .filter((analytic: ReportLightResponseInterface) => {
              if (analyticsTypeSelected === AnalyticsDashboardType.ALL) {
                return analytic
              }
              return `${analytic.type}` === `${analyticsTypeSelected}`
            })
            .map((analytic: ReportLightResponseInterface) => (
              <AnalyticsCard
                key={`analytics-card-${analytic.id}`}
                analytic={analytic}
                handleReloadAction={handleReloadAction}
                handleClickAnalytics={handleClickAnalytics}
                companyUserId={companyUserId}
              />
            ))}
        </>
      )) || (
        <div className="flex items-center justify-center w-full h-full">
          <CreateAnalyticsCard
            handleCreateNewAnalytics={handleCreateNewAnalytics}
          />
        </div>
      )}
    </div>
  )
}

export default AnalyticsDashboardCardView
