import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookInterface } from '../../../core/interface/book.interface'
import { WorkMirrorBookInterface } from '../../../core/interface/report/work-mirror-book.interface'
import { clilReferencesConfig } from '../../../core/config/clil-references.config'
import TitleV2 from '../../analytics-details/work-mirror/TitleV2'
import ClilReferencesMappingConfig from '../../../core/config/clil-refs-mapping.config'
import { ReactComponent as ArrowTopIcon } from '../../../assets/icons/app/arrow-top.icon.svg'

const CardStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 56px;
  width: 360px;
  background-color: black;
  border-radius: 8px;
  padding: 8px;
  z-index: 10;
`

const HeaderStyle = styled.div``

const ImageCloseStyle = styled.img`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  object-fit: fill;
  border-radius: 8px;
`

const TitleCloseStyle = styled.span`
  overflow: hidden;
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Vollkorn, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.14px;
  max-width: 308px;
`

const ButtonOpenMenuStyle = styled.button`
  transform: ${({ open }: { open: boolean }) =>
    open ? 'rotate(0deg)' : 'rotate(180deg)'};
`

const WMStyle = styled.span`
  color: #8dda86;
  text-align: center;
  /* Caption/C1 - Medium - Uppercase */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
`

const CardBookInputStyle = styled.div`
  background: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? '#e3e3e3' : 'white'};
  width: 100%;
  border-radius: 4px;
  padding: 2px;
`

const ItemAuthorStyle = styled.div`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #595959;
  font-family: Vollkorn, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125.37%; /* 15.044px */
  letter-spacing: -0.12px;
`

const ShareAnalyticDetailsBooksInputs = ({
  booksInput,
  bookDetailsSelected,
  handleBookSelected,
}: {
  booksInput: BookInterface[]
  bookDetailsSelected: WorkMirrorBookInterface | null
  handleBookSelected: (book: WorkMirrorBookInterface | null) => void
}) => {
  const { t } = useTranslation()
  const [bookInputMenu, setBookInputMenu] = useState<boolean>(false)

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setBookInputMenu(!bookInputMenu)
    },
    [bookInputMenu],
  )

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const handleBookToSelected = (book: BookInterface) => {
    handleBookSelected(
      bookDetailsSelected?.id_ean === book.id_ean
        ? null
        : {
            popularity: 0,
            position: 0,
            id_ean: book.id_ean,
            book,
          },
    )
  }

  return (
    <CardStyle className="flex flex-col gap-[8px]">
      <HeaderStyle className="flex flex-row justify-between items-center h-full">
        <div className="flex flex-row justify-start items-center h-[40px] gap-[4px]" />
        <WMStyle>{t('worksMirrors')}</WMStyle>
        <div
          className="flex flex-col items-center justify-around"
          style={{
            minHeight: 40,
            maxHeight: 40,
          }}
        >
          <ButtonOpenMenuStyle
            type="button"
            open={bookInputMenu}
            onClick={handleOpenMenu}
          >
            <ArrowTopIcon className="text-[#f4f4f4] w-5 h-5" />
          </ButtonOpenMenuStyle>
        </div>
      </HeaderStyle>
      {(bookInputMenu && (
        <div className="flex flex-col gap-1">
          {booksInput.map((bookInput: BookInterface) => (
            <CardBookInputStyle
              key={`input-book-card-${bookInput.id_ean}`}
              isSelected={bookDetailsSelected?.id_ean === bookInput.id_ean}
              className="relative flex flex-row items-center justify-start gap-2"
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                handleBookToSelected(bookInput)
              }}
            >
              <img
                src={bookInput?.imgs || getBookCoverByClil(bookInput.clil1)}
                alt="book cover"
                style={{
                  objectFit: 'cover',
                  minWidth: 40,
                  minHeight: 40,
                  maxWidth: 40,
                  maxHeight: 40,
                  borderRadius: '4px',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                }}
              />
              <div className="flex flex-col items-start">
                <TitleV2 title={bookInput?.title?.trim()} />
                <ItemAuthorStyle>
                  {bookInput?.authors[0]?.trim()}
                </ItemAuthorStyle>
              </div>
            </CardBookInputStyle>
          ))}
        </div>
      )) || (
        <div className="flex flex-col gap-2">
          {booksInput.map((bookInput: BookInterface) => (
            <div
              key={`input-book-card-${bookInput.id_ean}`}
              className="flex flex-row items-center justify-start gap-2"
            >
              <ImageCloseStyle
                src={bookInput?.imgs || getBookCoverByClil(bookInput.clil1)}
                alt="cover book"
              />
              <TitleCloseStyle>{bookInput.title}</TitleCloseStyle>
            </div>
          ))}
        </div>
      )}
    </CardStyle>
  )
}

export default ShareAnalyticDetailsBooksInputs
