import { useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Check } from '../../../assets/icons/app/check.icon.svg'
import { ReactComponent as Close } from '../../../assets/icons/close-icon-inactive.svg'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  setReadershipProfileGenerating,
  setShowSnackBar,
} from '../../../store/reducers/web-app.reducer'
import { selectWebAppStateReadershipProfileGenerating } from '../../../store/getters/web-app.getters'
import {
  AnalyticsProgressStatusInterface,
  ProgressStatusInterface,
} from '../../../store/interfaces/analytics-details/analytics-progress-status.interface'
import { AnalyticsStatusEnum } from '../../../core/enums/analytics-status.enum'
import { PathConfig, buildRelativePath } from '../../../core/config/PathConfig'

const fadeIn = keyframes`
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 1rem;
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    bottom: 1rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
`
const SnackBarStyle = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  background-color: #252525;
  padding: 8px 8px;
  color: white;
  animation: ${({ show }: { show: boolean }) => (show ? fadeIn : fadeOut)} 0.5s
    linear;
`

const StackBarContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  bottom: 1rem;
  position: fixed;
  left: 5rem;
  z-index: 1000;
`

const GlSnackBarRPNotif = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const progress = useAppSelector(selectWebAppStateReadershipProfileGenerating)

  const onClickHideSnackBar = (reportId: string) => {
    const newProgress: ProgressStatusInterface<AnalyticsProgressStatusInterface>[] =
      progress
        .filter(
          (report) =>
            report.data.generation_progress !== 1 ||
            report.data.id !== reportId,
        )
        .map(
          (
            report: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
          ) => {
            if (report.data.id === reportId) {
              return { ...report, show: false }
            }
            return report
          },
        )
    dispatch(setReadershipProfileGenerating(newProgress))
  }

  const handleRedirectToReport = (reportId: string) => {
    onClickHideSnackBar(reportId)
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        reportId,
      ]),
    )
  }
  return (
    <StackBarContainerStyle>
      {progress
        .filter(
          (
            progress: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
          ) =>
            (progress.data.status === 'generated' ||
              progress.data.status === 'generating') &&
            progress.show === true,
        )
        .map(
          (
            progress: ProgressStatusInterface<AnalyticsProgressStatusInterface>,
          ) => {
            const isGenerated =
              progress.data.status === AnalyticsStatusEnum.GENERATED
            return (
              <div>
                {isGenerated ? (
                  <SnackBarStyle
                    key={progress.data.id}
                    show={progress.show}
                    className="flex flex-row self-center rounded-lg p-2 shadow-md w-[469px]"
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Check className="text-[#86DAAD] w-6 h-6 ml-[6px]" />
                      <span>{t('GeneratedReadershipProfile')}</span>
                      <button
                        type="button"
                        className="
                        rounded
                        px-2
                        py-2
                        h-7
                        w-[120px]
                        flex
                        items-center
                        justify-center
                        ml-6
                        bg-[#FFF]
                        hover:bg-[#e3e3e3] 
                        "
                        onClick={() => {
                          handleRedirectToReport(progress.data.id)
                        }}
                      >
                        <span className="text-[#252525]">Consulter</span>
                      </button>
                      <Close
                        className="cursor-pointer text-[#FFBDBD] w-4 h-4 m-[6px]"
                        onClick={(event) => {
                          event.preventDefault()
                          onClickHideSnackBar(progress.data.id)
                        }}
                      />
                    </div>
                  </SnackBarStyle>
                ) : (
                  <SnackBarStyle
                    key={progress.data.id}
                    show={progress.show}
                    className="flex flex-row self-center rounded-lg p-2 shadow-md w-[351px]"
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    <div className="flex items-center gap-2 ml-[6px]">
                      <span>{t('GeneratingReadershipProfile')}</span>
                      <span className="ml-6">
                        {Math.round(progress.data.generation_progress * 100)}%
                      </span>
                      <Close
                        className="cursor-pointer text-[#FFBDBD] w-4 h-4 m-[6px]"
                        onClick={(event) => {
                          event.preventDefault()
                          onClickHideSnackBar(progress.data.id)
                        }}
                      />
                    </div>
                  </SnackBarStyle>
                )}
              </div>
            )
          },
        )}
    </StackBarContainerStyle>
  )
}

export default memo(GlSnackBarRPNotif)
