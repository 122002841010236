import { ClilInterface } from '../interface/clil.interface'

/**
 * ne pas modifier ce fichier directement
 * Utiliser des fonctions helpers qui vont calculer les besoins en fonction de
 * ces données.
 * Utiliser une cache si nécessaire
 * Important, lorsque vous mettez à jour ce document, assurez-vous de mettre à
 * jour tous les endroits ou c'est utilisé
 */
export const clilReferencesConfig: ClilInterface = {
  '999999': {
    '1': { id: '999999', libelle: 'SANS CATÉGORIE' },
    libelle: 'SANS CATÉGORIE',
    color: '#979797',
  },
  '0000': {
    '1': { id: '0000', libelle: 'SANS CATÉGORIE' },
    libelle: 'SANS CATÉGORIE',
    color: '#979797',
  },
  '3000': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    libelle: 'SCOLAIRE',
    color: '#FFC947',
  },
  '3001': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3001',
      libelle: 'Pré-scolaire et primaire',
    },
    libelle: 'Pré-scolaire et primaire',
    color: '#FFC947',
  },
  '3002': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3001',
      libelle: 'Pré-scolaire et primaire',
    },
    '3': {
      id: '3002',
      libelle: 'Pré-scolaire et maternelle',
    },
    libelle: 'Pré-scolaire et maternelle',
    color: '#FFC947',
  },
  '3003': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3001',
      libelle: 'Pré-scolaire et primaire',
    },
    '3': {
      id: '3003',
      libelle: 'Élémentaire',
    },
    libelle: 'Élémentaire',
    color: '#FFC947',
  },
  '3004': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3004',
      libelle: 'Manuels scolaires Secondaire Général',
    },
    libelle: 'Manuels scolaires Secondaire Général',
    color: '#FFC947',
  },
  '3005': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3004',
      libelle: 'Manuels scolaires Secondaire Général',
    },
    '3': {
      id: '3005',
      libelle: 'Collège',
    },
    libelle: 'Collège',
    color: '#FFC947',
  },
  '3006': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3004',
      libelle: 'Manuels scolaires Secondaire Général',
    },
    '3': {
      id: '3006',
      libelle: 'Lycée général',
    },
    libelle: 'Lycée général',
    color: '#FFC947',
  },
  '3007': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3007',
      libelle: 'Manuels scolaires Secondaire Technique et professionnel',
    },
    libelle: 'Manuels scolaires Secondaire Technique et professionnel',
    color: '#FFC947',
  },
  '3008': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3007',
      libelle: 'Manuels scolaires Secondaire Technique et professionnel',
    },
    '3': {
      id: '3008',
      libelle:
        'Lycée professionnel, Bac professionnel (4e/3e technologique, CAP, BEP...)',
    },
    libelle:
      'Lycée professionnel, Bac professionnel (4e/3e technologique, CAP, BEP...)',
    color: '#FFC947',
  },
  '3009': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3007',
      libelle: 'Manuels scolaires Secondaire Technique et professionnel',
    },
    '3': {
      id: '3009',
      libelle: 'Lycée technologique, Bac technologique',
    },
    libelle: 'Lycée technologique, Bac technologique',
    color: '#FFC947',
  },
  '3010': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3010',
      libelle: 'Manuels scolaires Supérieur Technique',
    },
    libelle: 'Manuels scolaires Supérieur Technique',
    color: '#FFC947',
  },
  '3011': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3010',
      libelle: 'Manuels scolaires Supérieur Technique',
    },
    '3': {
      id: '3011',
      libelle: 'Brevet de Technicien Supérieur (BTS)',
    },
    libelle: 'Brevet de Technicien Supérieur (BTS)',
    color: '#FFC947',
  },
  '3012': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '3010',
      libelle: 'Manuels scolaires Supérieur Technique',
    },
    '3': {
      id: '3012',
      libelle: 'Diplôme Universitaire de Technologie (DUT)',
    },
    libelle: 'Diplôme Universitaire de Technologie (DUT)',
    color: '#FFC947',
  },
  '4271': {
    '1': {
      id: '3000',
      libelle: 'SCOLAIRE',
    },
    '2': {
      id: '4271',
      libelle: 'Classes préparatoires aux grandes écoles (CPGE)',
    },
    libelle: 'Classes préparatoires aux grandes écoles (CPGE)',
    color: '#FFC947',
  },
  '3013': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    libelle: 'PARASCOLAIRE',
    color: '#FFAE4A',
  },
  '3014': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3014',
      libelle: 'Cahier de vacances',
    },
    libelle: 'Cahier de vacances',
    color: '#FFAE4A',
  },
  '3015': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3014',
      libelle: 'Cahier de vacances',
    },
    '3': {
      id: '3015',
      libelle: 'Maternelle',
    },
    libelle: 'Maternelle',
    color: '#FFAE4A',
  },
  '3016': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3014',
      libelle: 'Cahier de vacances',
    },
    '3': {
      id: '3016',
      libelle: 'Élémentaire',
    },
    libelle: 'Élémentaire',
    color: '#FFAE4A',
  },
  '3017': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3014',
      libelle: 'Cahier de vacances',
    },
    '3': {
      id: '3017',
      libelle: 'Collège',
    },
    libelle: 'Collège',
    color: '#FFAE4A',
  },
  '3018': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3014',
      libelle: 'Cahier de vacances',
    },
    '3': {
      id: '3018',
      libelle: 'Lycée',
    },
    libelle: 'Lycée',
    color: '#FFAE4A',
  },
  '3019': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3019',
      libelle: 'Cahier de soutien',
    },
    libelle: 'Cahier de soutien',
    color: '#FFAE4A',
  },
  '3020': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3019',
      libelle: 'Cahier de soutien',
    },
    '3': {
      id: '3020',
      libelle: 'Maternelle',
    },
    libelle: 'Maternelle',
    color: '#FFAE4A',
  },
  '3021': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3019',
      libelle: 'Cahier de soutien',
    },
    '3': {
      id: '3021',
      libelle: 'Élémentaire',
    },
    libelle: 'Élémentaire',
    color: '#FFAE4A',
  },
  '3022': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3019',
      libelle: 'Cahier de soutien',
    },
    '3': {
      id: '3022',
      libelle: 'Collège',
    },
    libelle: 'Collège',
    color: '#FFAE4A',
  },
  '3023': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3019',
      libelle: 'Cahier de soutien',
    },
    '3': {
      id: '3023',
      libelle: 'Lycée',
    },
    libelle: 'Lycée',
    color: '#FFAE4A',
  },
  '3024': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    libelle: 'Préparation aux examens',
    color: '#FFAE4A',
  },
  '3025': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '3025',
      libelle: 'Préparation au Brevet des collèges',
    },
    libelle: 'Préparation au Brevet des collèges',
    color: '#FFAE4A',
  },
  '3026': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '3026',
      libelle: 'Préparation au Baccalauréat général',
    },
    libelle: 'Préparation au Baccalauréat général',
    color: '#FFAE4A',
  },
  '3027': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '3027',
      libelle: 'Préparation au Baccalauréat professionnel et technique',
    },
    libelle: 'Préparation au Baccalauréat professionnel et technique',
    color: '#FFAE4A',
  },
  '4157': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '4157',
      libelle: 'Préparation aux CAP',
    },
    libelle: 'Préparation aux CAP',
    color: '#FFAE4A',
  },
  '4158': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '4158',
      libelle: 'Préparation aux BTS',
    },
    libelle: 'Préparation aux BTS',
    color: '#FFAE4A',
  },
  '4159': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3024',
      libelle: 'Préparation aux examens',
    },
    '3': {
      id: '4159',
      libelle: 'Préparation aux examens de l’enseignement supérieur',
    },
    libelle: 'Préparation aux examens de l’enseignement supérieur',
    color: '#FFAE4A',
  },
  '3028': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3028',
      libelle: 'Annales',
    },
    libelle: 'Annales',
    color: '#FFAE4A',
  },
  '3029': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3028',
      libelle: 'Annales',
    },
    '3': {
      id: '3029',
      libelle: 'Annales Brevet des collèges',
    },
    libelle: 'Annales Brevet des collèges',
    color: '#FFAE4A',
  },
  '3030': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3028',
      libelle: 'Annales',
    },
    '3': {
      id: '3030',
      libelle: 'Annales Baccalauréat général',
    },
    libelle: 'Annales Baccalauréat général',
    color: '#FFAE4A',
  },
  '3031': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3028',
      libelle: 'Annales',
    },
    '3': {
      id: '3031',
      libelle: 'Annales Baccalauréat professionnel et technique',
    },
    libelle: 'Annales Baccalauréat professionnel et technique',
    color: '#FFAE4A',
  },
  '3032': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    libelle: 'Ouvrage de référence',
    color: '#FFAE4A',
  },
  '3033': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3033',
      libelle: 'Mémentos, aide-mémoires, méthodes de français',
    },
    libelle: 'Mémentos, aide-mémoires, méthodes de français',
    color: '#FFAE4A',
  },
  '4137': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3033',
      libelle: 'Mémentos, aide-mémoires, méthodes de français',
    },
    '4': {
      id: '4137',
      libelle: 'Grammaire',
    },
    libelle: 'Grammaire',
    color: '#FFAE4A',
  },
  '4138': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3033',
      libelle: 'Mémentos, aide-mémoires, méthodes de français',
    },
    '4': {
      id: '4138',
      libelle: 'Orthographe',
    },
    libelle: 'Orthographe',
    color: '#FFAE4A',
  },
  '4139': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3033',
      libelle: 'Mémentos, aide-mémoires, méthodes de français',
    },
    '4': {
      id: '4139',
      libelle: 'Conjugaison',
    },
    libelle: 'Conjugaison',
    color: '#FFAE4A',
  },
  '4140': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3033',
      libelle: 'Mémentos, aide-mémoires, méthodes de français',
    },
    '4': {
      id: '4140',
      libelle: 'Vocabulaire et expression',
    },
    libelle: 'Vocabulaire et expression',
    color: '#FFAE4A',
  },
  '3034': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3034',
      libelle: 'Mémentos, aide-mémoires et méthodes de langues étrangères',
    },
    libelle: 'Mémentos, aide-mémoires et méthodes de langues étrangères',
    color: '#FFAE4A',
  },
  '3035': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3035',
      libelle: 'Mémentos, aide-mémoires, méthodes hors langues',
    },
    libelle: 'Mémentos, aide-mémoires, méthodes hors langues',
    color: '#FFAE4A',
  },
  '3036': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3032',
      libelle: 'Ouvrage de référence',
    },
    '3': {
      id: '3036',
      libelle: 'Atlas parascolaire',
    },
    libelle: 'Atlas parascolaire',
    color: '#FFAE4A',
  },
  '3037': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3037',
      libelle: 'Formation pour adultes',
    },
    libelle: 'Formation pour adultes',
    color: '#FFAE4A',
  },
  '3038': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3038',
      libelle: 'Classiques pédagogiques',
    },
    libelle: 'Classiques pédagogiques',
    color: '#FFAE4A',
  },
  '4141': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3038',
      libelle: 'Classiques pédagogiques',
    },
    '3': {
      id: '4141',
      libelle: 'Œuvre commentée',
    },
    libelle: 'Œuvre commentée',
    color: '#FFAE4A',
  },
  '4142': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3038',
      libelle: 'Classiques pédagogiques',
    },
    '3': {
      id: '4142',
      libelle: "Analyse d'œuvre",
    },
    libelle: "Analyse d'œuvre",
    color: '#FFAE4A',
  },
  '3039': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    libelle: 'Pédagogie et formation des enseignants',
    color: '#FFAE4A',
  },
  '3040': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3040',
      libelle: 'Didactique, pédagogie',
    },
    libelle: 'Didactique, pédagogie',
    color: '#FFAE4A',
  },
  '3041': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3041',
      libelle: 'Matériel éducatif pour la maternelle',
    },
    libelle: 'Matériel éducatif pour la maternelle',
    color: '#FFAE4A',
  },
  '3042': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3042',
      libelle: 'Supports pédagogiques pour le niveau élémentaire',
    },
    libelle: 'Supports pédagogiques pour le niveau élémentaire',
    color: '#FFAE4A',
  },
  '3043': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3043',
      libelle:
        'Supports et ouvrages pédagogiques liés aux programmes Collège et Lycée ou à la Formation continue',
    },
    libelle:
      'Supports et ouvrages pédagogiques liés aux programmes Collège et Lycée ou à la Formation continue',
    color: '#FFAE4A',
  },
  '3044': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3044',
      libelle: 'Éducation Nationale : concours, carrières',
    },
    libelle: 'Éducation Nationale : concours, carrières',
    color: '#FFAE4A',
  },
  '3045': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3039',
      libelle: 'Pédagogie et formation des enseignants',
    },
    '3': {
      id: '3045',
      libelle: 'Revues pédagogiques, Revues des professeurs',
    },
    libelle: 'Revues pédagogiques, Revues des professeurs',
    color: '#FFAE4A',
  },
  '3046': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3046',
      libelle: 'Orientation, Préparation aux concours',
    },
    libelle: 'Orientation, Préparation aux concours',
    color: '#FFAE4A',
  },
  '3047': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3046',
      libelle: 'Orientation, Préparation aux concours',
    },
    '3': {
      id: '3047',
      libelle: 'Études, Orientation, Métiers',
    },
    libelle: 'Études, Orientation, Métiers',
    color: '#FFAE4A',
  },
  '3048': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3046',
      libelle: 'Orientation, Préparation aux concours',
    },
    '3': {
      id: '3048',
      libelle: 'Concours administratifs',
    },
    libelle: 'Concours administratifs',
    color: '#FFAE4A',
  },
  '3049': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3046',
      libelle: 'Orientation, Préparation aux concours',
    },
    '3': {
      id: '3049',
      libelle: 'Préparation aux autres concours et examens',
    },
    libelle: 'Préparation aux autres concours et examens',
    color: '#FFAE4A',
  },
  '3050': {
    '1': {
      id: '3013',
      libelle: 'PARASCOLAIRE',
    },
    '2': {
      id: '3050',
      libelle: 'Français Langue Étrangère',
    },
    libelle: 'Français Langue Étrangère',
    color: '#FFAE4A',
  },
  '3051': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    libelle: 'SCIENCES FONDAMENTALES',
    color: '#83D4F5',
  },
  '4050': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4050',
      libelle: 'Histoire des sciences',
    },
    libelle: 'Histoire des sciences',
    color: '#83D4F5',
  },
  '4130': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4130',
      libelle: 'Biographie et autobiographie : science et technologie',
    },
    libelle: 'Biographie et autobiographie : science et technologie',
    color: '#83D4F5',
  },
  '3052': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3052',
      libelle: 'Mathématiques',
    },
    libelle: 'Mathématiques',
    color: '#83D4F5',
  },
  '3053': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3052',
      libelle: 'Mathématiques',
    },
    '3': {
      id: '3053',
      libelle: 'Algèbre',
    },
    libelle: 'Algèbre',
    color: '#83D4F5',
  },
  '3054': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3052',
      libelle: 'Mathématiques',
    },
    '3': {
      id: '3054',
      libelle: 'Analyse',
    },
    libelle: 'Analyse',
    color: '#83D4F5',
  },
  '3055': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3052',
      libelle: 'Mathématiques',
    },
    '3': {
      id: '3055',
      libelle: 'Géométrie',
    },
    libelle: 'Géométrie',
    color: '#83D4F5',
  },
  '3056': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3052',
      libelle: 'Mathématiques',
    },
    '3': {
      id: '3056',
      libelle: 'Probabilités et statistiques',
    },
    libelle: 'Probabilités et statistiques',
    color: '#83D4F5',
  },
  '3057': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3057',
      libelle: 'Informatique théorique, Mathématiques discrètes',
    },
    libelle: 'Informatique théorique, Mathématiques discrètes',
    color: '#83D4F5',
  },
  '3058': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3058',
      libelle: 'Physique',
    },
    libelle: 'Physique',
    color: '#83D4F5',
  },
  '3059': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    libelle: 'Chimie',
    color: '#83D4F5',
  },
  '3060': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    '3': {
      id: '3060',
      libelle: 'Biochimie',
    },
    libelle: 'Biochimie',
    color: '#83D4F5',
  },
  '3061': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    '3': {
      id: '3061',
      libelle: 'Chimie analytique',
    },
    libelle: 'Chimie analytique',
    color: '#83D4F5',
  },
  '3062': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    '3': {
      id: '3062',
      libelle: 'Chimie organique',
    },
    libelle: 'Chimie organique',
    color: '#83D4F5',
  },
  '3063': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    '3': {
      id: '3063',
      libelle: 'Chimie minérale (inorganique)',
    },
    libelle: 'Chimie minérale (inorganique)',
    color: '#83D4F5',
  },
  '3064': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3059',
      libelle: 'Chimie',
    },
    '3': {
      id: '3064',
      libelle: 'Chimie expérimentale',
    },
    libelle: 'Chimie expérimentale',
    color: '#83D4F5',
  },
  '3065': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    libelle: 'Sciences de la vie',
    color: '#83D4F5',
  },
  '4042': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    '3': {
      id: '4042',
      libelle: 'Biologie',
    },
    libelle: 'Biologie',
    color: '#83D4F5',
  },
  '4041': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    '3': {
      id: '4041',
      libelle: 'Botanique',
    },
    libelle: 'Botanique',
    color: '#83D4F5',
  },
  '4092': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    '3': {
      id: '4092',
      libelle: 'Ecologie',
    },
    libelle: 'Ecologie',
    color: '#83D4F5',
  },
  '4043': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    '3': {
      id: '4043',
      libelle: 'Microbiologie',
    },
    libelle: 'Microbiologie',
    color: '#83D4F5',
  },
  '4044': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3065',
      libelle: 'Sciences de la vie',
    },
    '3': {
      id: '4044',
      libelle: 'Zoologie',
    },
    libelle: 'Zoologie',
    color: '#83D4F5',
  },
  '3066': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3066',
      libelle: 'Astronomie',
    },
    libelle: 'Astronomie',
    color: '#83D4F5',
  },
  '3067': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3067',
      libelle: 'Sciences de la terre (géologie, climatologie, hydrologie...)',
    },
    libelle: 'Sciences de la terre (géologie, climatologie, hydrologie...)',
    color: '#83D4F5',
  },
  '3068': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '3068',
      libelle: 'Paléontologie',
    },
    libelle: 'Paléontologie',
    color: '#83D4F5',
  },
  '4117': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4117',
      libelle: 'Archéologie',
    },
    libelle: 'Archéologie',
    color: '#83D4F5',
  },
  '4118': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4118',
      libelle: 'Ethique des sciences et des technologies',
    },
    libelle: 'Ethique des sciences et des technologies',
    color: '#83D4F5',
  },
  '4119': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4119',
      libelle: 'Astrophysique',
    },
    libelle: 'Astrophysique',
    color: '#83D4F5',
  },
  '4120': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4120',
      libelle: 'Electromagnétisme',
    },
    libelle: 'Electromagnétisme',
    color: '#83D4F5',
  },
  '4121': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4121',
      libelle: 'Thermodynamique',
    },
    libelle: 'Thermodynamique',
    color: '#83D4F5',
  },
  '4122': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4122',
      libelle: 'Physique quantique',
    },
    libelle: 'Physique quantique',
    color: '#83D4F5',
  },
  '4123': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4123',
      libelle: 'Théorie de la relativité',
    },
    libelle: 'Théorie de la relativité',
    color: '#83D4F5',
  },
  '4124': {
    '1': {
      id: '3051',
      libelle: 'SCIENCES FONDAMENTALES',
    },
    '2': {
      id: '4124',
      libelle: 'Neurosciences',
    },
    libelle: 'Neurosciences',
    color: '#83D4F5',
  },
  '3069': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    color: '#7AB9FF',
  },
  '3070': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3070',
      libelle: 'Agriculture',
    },
    libelle: 'Agriculture',
    color: '#7AB9FF',
  },
  '3071': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3071',
      libelle: 'Génie civil',
    },
    libelle: 'Génie civil',
    color: '#7AB9FF',
  },
  '3072': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3072',
      libelle: 'Électronique',
    },
    libelle: 'Électronique',
    color: '#7AB9FF',
  },
  '3073': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3073',
      libelle: 'Mécanique',
    },
    libelle: 'Mécanique',
    color: '#7AB9FF',
  },
  '3074': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3074',
      libelle: 'Sciences et techniques industrielles',
    },
    libelle: 'Sciences et techniques industrielles',
    color: '#7AB9FF',
  },
  '3075': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3075',
      libelle: 'Bâtiment',
    },
    libelle: 'Bâtiment',
    color: '#7AB9FF',
  },
  '3076': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3076',
      libelle: 'Architecture, Urbanisme',
    },
    libelle: 'Architecture, Urbanisme',
    color: '#7AB9FF',
  },
  '3077': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3077',
      libelle: 'Graphisme et image',
    },
    libelle: 'Graphisme et image',
    color: '#7AB9FF',
  },
  '3078': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3078',
      libelle: 'Optique',
    },
    libelle: 'Optique',
    color: '#7AB9FF',
  },
  '3079': {
    '1': {
      id: '3069',
      libelle: 'TECHNIQUES ET SCIENCES APPLIQUÉES',
    },
    '2': {
      id: '3079',
      libelle: 'Énergies',
    },
    libelle: 'Énergies',
    color: '#7AB9FF',
  },
  '3080': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    color: '#F395E0',
  },
  '3081': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    libelle: 'Sciences sociales',
    color: '#F395E0',
  },
  '3082': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3082',
      libelle: 'Changement social',
    },
    libelle: 'Changement social',
    color: '#F395E0',
  },
  '3083': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3083',
      libelle: 'Classes sociales et stratification',
    },
    libelle: 'Classes sociales et stratification',
    color: '#F395E0',
  },
  '3084': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3084',
      libelle: 'Diversités, Discriminations',
    },
    libelle: 'Diversités, Discriminations',
    color: '#F395E0',
  },
  '3085': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3085',
      libelle: 'Addiction',
    },
    libelle: 'Addiction',
    color: '#F395E0',
  },
  '3086': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3086',
      libelle: 'Exclusion et pauvreté',
    },
    libelle: 'Exclusion et pauvreté',
    color: '#F395E0',
  },
  '3087': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3087',
      libelle: 'Sociologie du genre',
    },
    libelle: 'Sociologie du genre',
    color: '#F395E0',
  },
  '3088': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3088',
      libelle: 'Générations',
    },
    libelle: 'Générations',
    color: '#F395E0',
  },
  '3089': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3089',
      libelle: 'Histoire sociale',
    },
    libelle: 'Histoire sociale',
    color: '#F395E0',
  },
  '3090': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3090',
      libelle: 'Inégalités',
    },
    libelle: 'Inégalités',
    color: '#F395E0',
  },
  '3091': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3091',
      libelle: 'Migrations et immigrations',
    },
    libelle: 'Migrations et immigrations',
    color: '#F395E0',
  },
  '4054': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3091',
      libelle: 'Migrations et immigrations',
    },
    '4': {
      id: '4054',
      libelle: 'Démographie',
    },
    libelle: 'Démographie',
    color: '#F395E0',
  },
  '3092': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3092',
      libelle: 'Mobilités',
    },
    libelle: 'Mobilités',
    color: '#F395E0',
  },
  '3093': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3093',
      libelle: 'Santé',
    },
    libelle: 'Santé',
    color: '#F395E0',
  },
  '3094': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3094',
      libelle: 'Sexualité',
    },
    libelle: 'Sexualité',
    color: '#F395E0',
  },
  '3095': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '3095',
      libelle: 'Sociabilité',
    },
    libelle: 'Sociabilité',
    color: '#F395E0',
  },
  '4093': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    libelle: 'Sociologie',
    color: '#F395E0',
  },
  '3096': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '3096',
      libelle: 'Sociologie de la famille',
    },
    libelle: 'Sociologie de la famille',
    color: '#F395E0',
  },
  '3097': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '3097',
      libelle: 'Sociologie des organisations',
    },
    libelle: 'Sociologie des organisations',
    color: '#F395E0',
  },
  '3098': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '3098',
      libelle: 'Sociologie des sciences',
    },
    libelle: 'Sociologie des sciences',
    color: '#F395E0',
  },
  '3099': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '3099',
      libelle: 'Sociologie du temps',
    },
    libelle: 'Sociologie du temps',
    color: '#F395E0',
  },
  '4096': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '4096',
      libelle: 'Sociologie du sport',
    },
    libelle: 'Sociologie du sport',
    color: '#F395E0',
  },
  '4095': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '4095',
      libelle: 'Sociologie du tourisme',
    },
    libelle: 'Sociologie du tourisme',
    color: '#F395E0',
  },
  '4094': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3081',
      libelle: 'Sciences sociales',
    },
    '3': {
      id: '4093',
      libelle: 'Sociologie',
    },
    '4': {
      id: '4094',
      libelle: 'Sociologie des transports',
    },
    libelle: 'Sociologie des transports',
    color: '#F395E0',
  },
  '3100': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    libelle: 'Action sociale, aide sociale',
    color: '#F395E0',
  },
  '3101': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3101',
      libelle: 'Accueil familial',
    },
    libelle: 'Accueil familial',
    color: '#F395E0',
  },
  '3102': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3102',
      libelle: "Aide et protection de l'enfance",
    },
    libelle: "Aide et protection de l'enfance",
    color: '#F395E0',
  },
  '3103': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3103',
      libelle: 'Conduites à risques',
    },
    libelle: 'Conduites à risques',
    color: '#F395E0',
  },
  '3104': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3104',
      libelle: 'Travail social, intervention, animation, accompagnement',
    },
    libelle: 'Travail social, intervention, animation, accompagnement',
    color: '#F395E0',
  },
  '3105': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3105',
      libelle: 'Public empêché, besoins spécifiques',
    },
    libelle: 'Public empêché, besoins spécifiques',
    color: '#F395E0',
  },
  '3106': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3106',
      libelle: 'Maladie',
    },
    libelle: 'Maladie',
    color: '#F395E0',
  },
  '3107': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3107',
      libelle: 'Vieillesse, action gérontologique',
    },
    libelle: 'Vieillesse, action gérontologique',
    color: '#F395E0',
  },
  '3108': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3108',
      libelle: 'Protection sociale',
    },
    libelle: 'Protection sociale',
    color: '#F395E0',
  },
  '3109': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3109',
      libelle: 'Fragilité sociale',
    },
    libelle: 'Fragilité sociale',
    color: '#F395E0',
  },
  '3110': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3100',
      libelle: 'Action sociale, aide sociale',
    },
    '3': {
      id: '3110',
      libelle: 'Solidarité, humanitaire',
    },
    libelle: 'Solidarité, humanitaire',
    color: '#F395E0',
  },
  '3111': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    libelle: 'Anthropologie',
    color: '#F395E0',
  },
  '3112': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3112',
      libelle: 'Anthropologie physique et biologique',
    },
    libelle: 'Anthropologie physique et biologique',
    color: '#F395E0',
  },
  '3113': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3112',
      libelle: 'Anthropologie physique et biologique',
    },
    '4': {
      id: '3113',
      libelle: 'Homo sapiens',
    },
    libelle: 'Homo sapiens',
    color: '#F395E0',
  },
  '3114': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3112',
      libelle: 'Anthropologie physique et biologique',
    },
    '4': {
      id: '3114',
      libelle: 'Primatologie comparée',
    },
    libelle: 'Primatologie comparée',
    color: '#F395E0',
  },
  '3115': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3112',
      libelle: 'Anthropologie physique et biologique',
    },
    '4': {
      id: '3115',
      libelle: 'Paléoanthropologie',
    },
    libelle: 'Paléoanthropologie',
    color: '#F395E0',
  },
  '3116': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    libelle: 'Anthropologie sociale et culturelle',
    color: '#F395E0',
  },
  '3117': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    '4': {
      id: '3117',
      libelle: "Théorie de l'évolution (évolutionnisme)",
    },
    libelle: "Théorie de l'évolution (évolutionnisme)",
    color: '#F395E0',
  },
  '3118': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    '4': {
      id: '3118',
      libelle: 'Homme et environnement',
    },
    libelle: 'Homme et environnement',
    color: '#F395E0',
  },
  '3119': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    '4': {
      id: '3119',
      libelle: 'Anthropologie politique',
    },
    libelle: 'Anthropologie politique',
    color: '#F395E0',
  },
  '3120': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    '4': {
      id: '3120',
      libelle: 'Anthropologie des mondes contemporains',
    },
    libelle: 'Anthropologie des mondes contemporains',
    color: '#F395E0',
  },
  '3121': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '3116',
      libelle: 'Anthropologie sociale et culturelle',
    },
    '4': {
      id: '3121',
      libelle: 'Anthropologie économique',
    },
    libelle: 'Anthropologie économique',
    color: '#F395E0',
  },
  '4097': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3111',
      libelle: 'Anthropologie',
    },
    '3': {
      id: '4097',
      libelle: 'Diasporas',
    },
    libelle: 'Diasporas',
    color: '#F395E0',
  },
  '3122': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3122',
      libelle: 'Ethnologie',
    },
    libelle: 'Ethnologie',
    color: '#F395E0',
  },
  '3123': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3122',
      libelle: 'Ethnologie',
    },
    '3': {
      id: '3123',
      libelle: 'Les modèles et les codes sociaux',
    },
    libelle: 'Les modèles et les codes sociaux',
    color: '#F395E0',
  },
  '3124': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3122',
      libelle: 'Ethnologie',
    },
    '3': {
      id: '3124',
      libelle: 'La parenté et les alliances',
    },
    libelle: 'La parenté et les alliances',
    color: '#F395E0',
  },
  '3125': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3122',
      libelle: 'Ethnologie',
    },
    '3': {
      id: '3125',
      libelle: 'Aspects symboliques',
    },
    libelle: 'Aspects symboliques',
    color: '#F395E0',
  },
  '3126': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    libelle: 'Philosophie',
    color: '#F395E0',
  },
  '3916': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3916',
      libelle: 'Histoire de la philosophie',
    },
    libelle: 'Histoire de la philosophie',
    color: '#F395E0',
  },
  '3127': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3127',
      libelle: 'Philosophie antique',
    },
    libelle: 'Philosophie antique',
    color: '#F395E0',
  },
  '3128': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3128',
      libelle: 'Philosophie médiévale (scolastique)',
    },
    libelle: 'Philosophie médiévale (scolastique)',
    color: '#F395E0',
  },
  '3129': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3129',
      libelle: 'Philosophie moderne',
    },
    libelle: 'Philosophie moderne',
    color: '#F395E0',
  },
  '3130': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3129',
      libelle: 'Philosophie moderne',
    },
    '4': {
      id: '3130',
      libelle: 'Philosophie humaniste',
    },
    libelle: 'Philosophie humaniste',
    color: '#F395E0',
  },
  '3131': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3129',
      libelle: 'Philosophie moderne',
    },
    '4': {
      id: '3131',
      libelle: 'Philosophe XVIIe siècle',
    },
    libelle: 'Philosophe XVIIe siècle',
    color: '#F395E0',
  },
  '3132': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3129',
      libelle: 'Philosophie moderne',
    },
    '4': {
      id: '3132',
      libelle: 'Philosophie des Lumières',
    },
    libelle: 'Philosophie des Lumières',
    color: '#F395E0',
  },
  '3133': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '3133',
      libelle: 'Philosophie contemporaine',
    },
    libelle: 'Philosophie contemporaine',
    color: '#F395E0',
  },
  '4125': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '4125',
      libelle: "Esthétique et philosophie de l'art",
    },
    libelle: "Esthétique et philosophie de l'art",
    color: '#F395E0',
  },
  '4126': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '4126',
      libelle: 'Métaphysique et ontologie',
    },
    libelle: 'Métaphysique et ontologie',
    color: '#F395E0',
  },
  '4127': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '4127',
      libelle: 'Philosophie éthique et politique',
    },
    libelle: 'Philosophie éthique et politique',
    color: '#F395E0',
  },
  '4160': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '4127',
      libelle: 'Philosophie éthique et politique',
    },
    '4': {
      id: '4160',
      libelle: 'Bioéthique',
    },
    libelle: 'Bioéthique',
    color: '#F395E0',
  },
  '4128': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3126',
      libelle: 'Philosophie',
    },
    '3': {
      id: '4128',
      libelle: 'Epistémologie, philosophie des sciences, logique',
    },
    libelle: 'Epistémologie, philosophie des sciences, logique',
    color: '#F395E0',
  },
  '3134': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    libelle: 'Psychologie',
    color: '#F395E0',
  },
  '3135': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3135',
      libelle: 'Psychologie générale',
    },
    libelle: 'Psychologie générale',
    color: '#F395E0',
  },
  '3136': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3136',
      libelle: 'Psychologie du comportement (ou behaviorisme)',
    },
    libelle: 'Psychologie du comportement (ou behaviorisme)',
    color: '#F395E0',
  },
  '3137': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3137',
      libelle: 'Psychologie cognitive',
    },
    libelle: 'Psychologie cognitive',
    color: '#F395E0',
  },
  '3138': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3138',
      libelle: 'Psychologie du développement',
    },
    libelle: 'Psychologie du développement',
    color: '#F395E0',
  },
  '3139': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3139',
      libelle: 'Psychopathologie',
    },
    libelle: 'Psychopathologie',
    color: '#F395E0',
  },
  '3140': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3140',
      libelle: 'Psychologie sociale',
    },
    libelle: 'Psychologie sociale',
    color: '#F395E0',
  },
  '3141': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3141',
      libelle: 'Psychophysiologie',
    },
    libelle: 'Psychophysiologie',
    color: '#F395E0',
  },
  '3142': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3142',
      libelle: 'Psychologie évolutionniste',
    },
    libelle: 'Psychologie évolutionniste',
    color: '#F395E0',
  },
  '3143': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3143',
      libelle: "Psychologie de l'éducation",
    },
    libelle: "Psychologie de l'éducation",
    color: '#F395E0',
  },
  '3144': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3144',
      libelle: "Psychologie de l'apprentissage",
    },
    libelle: "Psychologie de l'apprentissage",
    color: '#F395E0',
  },
  '3145': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3134',
      libelle: 'Psychologie',
    },
    '3': {
      id: '3145',
      libelle: 'Psychologie animale (ou éthologie)',
    },
    libelle: 'Psychologie animale (ou éthologie)',
    color: '#F395E0',
  },
  '3918': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3918',
      libelle: 'Psychanalyse',
    },
    libelle: 'Psychanalyse',
    color: '#F395E0',
  },
  '3146': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    libelle: 'Lettres et Sciences du langage',
    color: '#F395E0',
  },
  '4024': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4024',
      libelle: 'Lettres',
    },
    libelle: 'Lettres',
    color: '#F395E0',
  },
  '4025': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4024',
      libelle: 'Lettres',
    },
    '4': {
      id: '4025',
      libelle: 'Méthodologie',
    },
    libelle: 'Méthodologie',
    color: '#F395E0',
  },
  '4053': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4024',
      libelle: 'Lettres',
    },
    '4': {
      id: '4053',
      libelle: 'Théorie littéraire',
    },
    libelle: 'Théorie littéraire',
    color: '#F395E0',
  },
  '4027': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4024',
      libelle: 'Lettres',
    },
    '4': {
      id: '4027',
      libelle: 'Etudes littéraires générales et thématiques',
    },
    libelle: 'Etudes littéraires générales et thématiques',
    color: '#F395E0',
  },
  '4028': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4024',
      libelle: 'Lettres',
    },
    '4': {
      id: '4028',
      libelle: 'Etudes de littérature comparée',
    },
    libelle: 'Etudes de littérature comparée',
    color: '#F395E0',
  },
  '4026': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4026',
      libelle: 'Langue française',
    },
    libelle: 'Langue française',
    color: '#F395E0',
  },
  '4029': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4029',
      libelle: 'Langues régionales',
    },
    libelle: 'Langues régionales',
    color: '#F395E0',
  },
  '4030': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4030',
      libelle: 'Langues anciennes',
    },
    libelle: 'Langues anciennes',
    color: '#F395E0',
  },
  '4031': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4030',
      libelle: 'Langues anciennes',
    },
    '4': {
      id: '4031',
      libelle: 'Etudes de littérature grecque',
    },
    libelle: 'Etudes de littérature grecque',
    color: '#F395E0',
  },
  '4032': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4030',
      libelle: 'Langues anciennes',
    },
    '4': {
      id: '4032',
      libelle: 'Etudes de littérature latine',
    },
    libelle: 'Etudes de littérature latine',
    color: '#F395E0',
  },
  '4033': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '4033',
      libelle: 'Langues étrangères',
    },
    libelle: 'Langues étrangères',
    color: '#F395E0',
  },
  '3147': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    libelle: 'Linguistique, Sciences du langage',
    color: '#F395E0',
  },
  '3148': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3148',
      libelle: 'Théories et écoles linguistiques',
    },
    libelle: 'Théories et écoles linguistiques',
    color: '#F395E0',
  },
  '3149': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3149',
      libelle: 'Phonétique et phonologie',
    },
    libelle: 'Phonétique et phonologie',
    color: '#F395E0',
  },
  '3150': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3150',
      libelle: 'Morphologie et syntaxe',
    },
    libelle: 'Morphologie et syntaxe',
    color: '#F395E0',
  },
  '3151': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3151',
      libelle: 'Lexicologie, pragmatique lexicale, sémantique',
    },
    libelle: 'Lexicologie, pragmatique lexicale, sémantique',
    color: '#F395E0',
  },
  '3152': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3152',
      libelle: 'Linguistique diachronique (philologie)',
    },
    libelle: 'Linguistique diachronique (philologie)',
    color: '#F395E0',
  },
  '3153': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3153',
      libelle: 'Typologie linguistique',
    },
    libelle: 'Typologie linguistique',
    color: '#F395E0',
  },
  '3154': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3154',
      libelle: 'Stylistique et analyse du discours, esthétique',
    },
    libelle: 'Stylistique et analyse du discours, esthétique',
    color: '#F395E0',
  },
  '3155': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3155',
      libelle: 'Sémiologie',
    },
    libelle: 'Sémiologie',
    color: '#F395E0',
  },
  '3156': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3147',
      libelle: 'Linguistique, Sciences du langage',
    },
    '4': {
      id: '3156',
      libelle: 'Psycholinguistique',
    },
    libelle: 'Psycholinguistique',
    color: '#F395E0',
  },
  '3157': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3157',
      libelle: "Sciences de l'information et de la communication",
    },
    libelle: "Sciences de l'information et de la communication",
    color: '#F395E0',
  },
  '3158': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3157',
      libelle: "Sciences de l'information et de la communication",
    },
    '4': {
      id: '3158',
      libelle: 'Théories de la communication',
    },
    libelle: 'Théories de la communication',
    color: '#F395E0',
  },
  '3159': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3157',
      libelle: "Sciences de l'information et de la communication",
    },
    '4': {
      id: '3159',
      libelle: "Économie, Production de l'information",
    },
    libelle: "Économie, Production de l'information",
    color: '#F395E0',
  },
  '3160': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3157',
      libelle: "Sciences de l'information et de la communication",
    },
    '4': {
      id: '3160',
      libelle: 'Systèmes médiatiques, SIC et TIC',
    },
    libelle: 'Systèmes médiatiques, SIC et TIC',
    color: '#F395E0',
  },
  '3161': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3161',
      libelle: "Sciences de l'éducation",
    },
    libelle: "Sciences de l'éducation",
    color: '#F395E0',
  },
  '3162': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3162',
      libelle:
        'Systèmes de formation et apprentissages : évaluation et modélisation',
    },
    libelle:
      'Systèmes de formation et apprentissages : évaluation et modélisation',
    color: '#F395E0',
  },
  '3163': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3163',
      libelle:
        'Perspectives sociocognitives, apprentissage et conduites sociales',
    },
    libelle:
      'Perspectives sociocognitives, apprentissage et conduites sociales',
    color: '#F395E0',
  },
  '3164': {
    '1': {
      id: '3080',
      libelle: 'SCIENCES HUMAINES ET SOCIALES, LETTRES',
    },
    '2': {
      id: '3146',
      libelle: 'Lettres et Sciences du langage',
    },
    '3': {
      id: '3164',
      libelle: 'Technologisation, formation, activités professionnelles',
    },
    libelle: 'Technologisation, formation, activités professionnelles',
    color: '#F395E0',
  },
  '3165': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    color: '#85DBDE',
  },
  '3166': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3166',
      libelle: 'Dictionnaire, Guide, Outil, Pratique',
    },
    libelle: 'Dictionnaire, Guide, Outil, Pratique',
    color: '#85DBDE',
  },
  '3167': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3167',
      libelle: 'Histoire de la médecine',
    },
    libelle: 'Histoire de la médecine',
    color: '#85DBDE',
  },
  '3168': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3168',
      libelle: 'Sciences fondamentales',
    },
    libelle: 'Sciences fondamentales',
    color: '#85DBDE',
  },
  '3169': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3169',
      libelle: 'Spécialités médicales',
    },
    libelle: 'Spécialités médicales',
    color: '#85DBDE',
  },
  '3170': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3170',
      libelle: 'Imagerie médicale',
    },
    libelle: 'Imagerie médicale',
    color: '#85DBDE',
  },
  '3171': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3171',
      libelle: 'Pharmacie',
    },
    libelle: 'Pharmacie',
    color: '#85DBDE',
  },
  '3172': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3172',
      libelle: 'Dentaire, Odontostomatologie',
    },
    libelle: 'Dentaire, Odontostomatologie',
    color: '#85DBDE',
  },
  '3173': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3173',
      libelle: 'Neurologie, Psychiatrie',
    },
    libelle: 'Neurologie, Psychiatrie',
    color: '#85DBDE',
  },
  '3174': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3174',
      libelle: 'Paramédical, Médico-social, Aide-soignant, Infirmier',
    },
    libelle: 'Paramédical, Médico-social, Aide-soignant, Infirmier',
    color: '#85DBDE',
  },
  '3175': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3175',
      libelle: 'Médecine Vétérinaire',
    },
    libelle: 'Médecine Vétérinaire',
    color: '#85DBDE',
  },
  '3176': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '3176',
      libelle: 'Revues médicales, Revues paramédicales',
    },
    libelle: 'Revues médicales, Revues paramédicales',
    color: '#85DBDE',
  },
  '4161': {
    '1': {
      id: '3165',
      libelle: 'MÉDECINE, PHARMACIE, PARAMÉDICAL, MÉDECINE VÉTÉRINAIRE',
    },
    '2': {
      id: '4161',
      libelle: 'Éthique médicale',
    },
    libelle: 'Éthique médicale',
    color: '#85DBDE',
  },
  '3177': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    color: '#F67E75',
  },
  '3178': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3178',
      libelle: 'Entreprise',
    },
    libelle: 'Entreprise',
    color: '#F67E75',
  },
  '3179': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3179',
      libelle: "Création d'entreprise",
    },
    libelle: "Création d'entreprise",
    color: '#F67E75',
  },
  '3180': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3180',
      libelle: 'Comptabilité, expertise comptable, contrôle de gestion',
    },
    libelle: 'Comptabilité, expertise comptable, contrôle de gestion',
    color: '#F67E75',
  },
  '3181': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3181',
      libelle: 'Finance',
    },
    libelle: 'Finance',
    color: '#F67E75',
  },
  '3182': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3181',
      libelle: 'Finance',
    },
    '3': {
      id: '3182',
      libelle: "Finance d'entreprise",
    },
    libelle: "Finance d'entreprise",
    color: '#F67E75',
  },
  '3183': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3181',
      libelle: 'Finance',
    },
    '3': {
      id: '3183',
      libelle: 'Finance de marchés',
    },
    libelle: 'Finance de marchés',
    color: '#F67E75',
  },
  '3184': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3184',
      libelle: 'Gestion industrielle',
    },
    libelle: 'Gestion industrielle',
    color: '#F67E75',
  },
  '3185': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3185',
      libelle: 'Méthodologie et gestion de projets',
    },
    libelle: 'Méthodologie et gestion de projets',
    color: '#F67E75',
  },
  '3186': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3186',
      libelle: 'Management',
    },
    libelle: 'Management',
    color: '#F67E75',
  },
  '3929': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3929',
      libelle: 'Communication',
    },
    libelle: 'Communication',
    color: '#F67E75',
  },
  '3187': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3187',
      libelle: 'Marketing',
    },
    libelle: 'Marketing',
    color: '#F67E75',
  },
  '3188': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3188',
      libelle: 'Production',
    },
    libelle: 'Production',
    color: '#F67E75',
  },
  '3189': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3189',
      libelle: 'Ressources humaines',
    },
    libelle: 'Ressources humaines',
    color: '#F67E75',
  },
  '3190': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3190',
      libelle: 'Stratégie',
    },
    libelle: 'Stratégie',
    color: '#F67E75',
  },
  '3191': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3191',
      libelle: 'Commerce international',
    },
    libelle: 'Commerce international',
    color: '#F67E75',
  },
  '3192': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3192',
      libelle: 'Développement durable, écologie',
    },
    libelle: 'Développement durable, écologie',
    color: '#F67E75',
  },
  '3930': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '3930',
      libelle: 'Recherche et développement (R&D)',
    },
    libelle: 'Recherche et développement (R&D)',
    color: '#F67E75',
  },
  '4143': {
    '1': {
      id: '3177',
      libelle: "MANAGEMENT, GESTION ET ÉCONOMIE D'ENTREPRISE",
    },
    '2': {
      id: '4143',
      libelle: 'Vente',
    },
    libelle: 'Vente',
    color: '#F67E75',
  },
  '3193': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    libelle: 'INFORMATIQUE',
    color: '#92A4FF',
  },
  '3194': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3194',
      libelle: 'Théorie',
    },
    libelle: 'Théorie',
    color: '#92A4FF',
  },
  '3195': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3195',
      libelle: 'Hardware et matériels',
    },
    libelle: 'Hardware et matériels',
    color: '#92A4FF',
  },
  '3196': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3195',
      libelle: 'Hardware et matériels',
    },
    '3': {
      id: '3196',
      libelle: 'Architecture des ordinateurs',
    },
    libelle: 'Architecture des ordinateurs',
    color: '#92A4FF',
  },
  '3197': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3195',
      libelle: 'Hardware et matériels',
    },
    '3': {
      id: '3197',
      libelle: 'Périphériques',
    },
    libelle: 'Périphériques',
    color: '#92A4FF',
  },
  '3198': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    libelle: 'Graphisme',
    color: '#92A4FF',
  },
  '3199': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    '3': {
      id: '3199',
      libelle: 'PAO',
    },
    libelle: 'PAO',
    color: '#92A4FF',
  },
  '3200': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    '3': {
      id: '3200',
      libelle: 'CAO',
    },
    libelle: 'CAO',
    color: '#92A4FF',
  },
  '3201': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    '3': {
      id: '3201',
      libelle: "DAO - Retouche d'images",
    },
    libelle: "DAO - Retouche d'images",
    color: '#92A4FF',
  },
  '3202': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    '3': {
      id: '3202',
      libelle: '3D',
    },
    libelle: '3D',
    color: '#92A4FF',
  },
  '3203': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3198',
      libelle: 'Graphisme',
    },
    '3': {
      id: '3203',
      libelle: 'Modélisation',
    },
    libelle: 'Modélisation',
    color: '#92A4FF',
  },
  '3204': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    color: '#92A4FF',
  },
  '3205': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    '3': {
      id: '3205',
      libelle: 'Réseaux sociaux, Blog',
    },
    libelle: 'Réseaux sociaux, Blog',
    color: '#92A4FF',
  },
  '3206': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    '3': {
      id: '3206',
      libelle: 'CMS',
    },
    libelle: 'CMS',
    color: '#92A4FF',
  },
  '3207': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    '3': {
      id: '3207',
      libelle: 'E-Commerce',
    },
    libelle: 'E-Commerce',
    color: '#92A4FF',
  },
  '3208': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    '3': {
      id: '3208',
      libelle: 'CSS, Design web',
    },
    libelle: 'CSS, Design web',
    color: '#92A4FF',
  },
  '3209': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3204',
      libelle: 'Web (logiciel e-commerce, CMS, blog, réseaux sociaux...)',
    },
    '3': {
      id: '3209',
      libelle: 'SEO, Web analytics',
    },
    libelle: 'SEO, Web analytics',
    color: '#92A4FF',
  },
  '3210': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3210',
      libelle: 'Audio, Vidéo',
    },
    libelle: 'Audio, Vidéo',
    color: '#92A4FF',
  },
  '3211': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3210',
      libelle: 'Audio, Vidéo',
    },
    '3': {
      id: '3211',
      libelle: 'Audio',
    },
    libelle: 'Audio',
    color: '#92A4FF',
  },
  '3212': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3210',
      libelle: 'Audio, Vidéo',
    },
    '3': {
      id: '3212',
      libelle: 'Vidéo',
    },
    libelle: 'Vidéo',
    color: '#92A4FF',
  },
  '3213': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    libelle: "Informatique d'entreprise",
    color: '#92A4FF',
  },
  '3214': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3214',
      libelle: 'Conduite de projet',
    },
    libelle: 'Conduite de projet',
    color: '#92A4FF',
  },
  '3215': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3215',
      libelle: 'Gestion des sources',
    },
    libelle: 'Gestion des sources',
    color: '#92A4FF',
  },
  '3216': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3216',
      libelle: 'Stockage et archivage de données',
    },
    libelle: 'Stockage et archivage de données',
    color: '#92A4FF',
  },
  '3217': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3217',
      libelle: 'Entrepôt de données',
    },
    libelle: 'Entrepôt de données',
    color: '#92A4FF',
  },
  '3218': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3218',
      libelle: "Management des systèmes d'information",
    },
    libelle: "Management des systèmes d'information",
    color: '#92A4FF',
  },
  '3219': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3213',
      libelle: "Informatique d'entreprise",
    },
    '3': {
      id: '3219',
      libelle: "Messagerie d'entreprise",
    },
    libelle: "Messagerie d'entreprise",
    color: '#92A4FF',
  },
  '3220': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3220',
      libelle: 'Informatique personnelle',
    },
    libelle: 'Informatique personnelle',
    color: '#92A4FF',
  },
  '3221': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3220',
      libelle: 'Informatique personnelle',
    },
    '3': {
      id: '3221',
      libelle: "Systèmes d'exploitation",
    },
    libelle: "Systèmes d'exploitation",
    color: '#92A4FF',
  },
  '3222': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3220',
      libelle: 'Informatique personnelle',
    },
    '3': {
      id: '3222',
      libelle: 'Bureautique',
    },
    libelle: 'Bureautique',
    color: '#92A4FF',
  },
  '3223': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3220',
      libelle: 'Informatique personnelle',
    },
    '3': {
      id: '3223',
      libelle: 'Internet (gestion de la messagerie, navigateur...)',
    },
    libelle: 'Internet (gestion de la messagerie, navigateur...)',
    color: '#92A4FF',
  },
  '3224': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3220',
      libelle: 'Informatique personnelle',
    },
    '3': {
      id: '3224',
      libelle: 'Réseaux',
    },
    libelle: 'Réseaux',
    color: '#92A4FF',
  },
  '3225': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    libelle: 'Programmation et développement',
    color: '#92A4FF',
  },
  '3226': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3226',
      libelle: 'Assembleur',
    },
    libelle: 'Assembleur',
    color: '#92A4FF',
  },
  '3227': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3227',
      libelle: 'HTML',
    },
    libelle: 'HTML',
    color: '#92A4FF',
  },
  '3228': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3228',
      libelle: 'Java - J2EE',
    },
    libelle: 'Java - J2EE',
    color: '#92A4FF',
  },
  '3229': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3229',
      libelle: 'Javascript',
    },
    libelle: 'Javascript',
    color: '#92A4FF',
  },
  '3230': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3230',
      libelle: 'Langages C (C, C++, C#, Objective-C)',
    },
    libelle: 'Langages C (C, C++, C#, Objective-C)',
    color: '#92A4FF',
  },
  '3231': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3231',
      libelle: 'Python',
    },
    libelle: 'Python',
    color: '#92A4FF',
  },
  '3232': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3232',
      libelle: 'Ruby',
    },
    libelle: 'Ruby',
    color: '#92A4FF',
  },
  '3233': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3233',
      libelle: 'Modélisation et génie logiciel',
    },
    libelle: 'Modélisation et génie logiciel',
    color: '#92A4FF',
  },
  '3234': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3234',
      libelle: 'PERL',
    },
    libelle: 'PERL',
    color: '#92A4FF',
  },
  '3235': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3235',
      libelle: 'PHP',
    },
    libelle: 'PHP',
    color: '#92A4FF',
  },
  '3236': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3236',
      libelle: 'Shell',
    },
    libelle: 'Shell',
    color: '#92A4FF',
  },
  '3237': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3225',
      libelle: 'Programmation et développement',
    },
    '3': {
      id: '3237',
      libelle: 'Visual Basic',
    },
    libelle: 'Visual Basic',
    color: '#92A4FF',
  },
  '3238': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3238',
      libelle: 'Réseaux et Télécommunications',
    },
    libelle: 'Réseaux et Télécommunications',
    color: '#92A4FF',
  },
  '3239': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3238',
      libelle: 'Réseaux et Télécommunications',
    },
    '3': {
      id: '3239',
      libelle: 'Équipements réseaux',
    },
    libelle: 'Équipements réseaux',
    color: '#92A4FF',
  },
  '3240': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3238',
      libelle: 'Réseaux et Télécommunications',
    },
    '3': {
      id: '3240',
      libelle: 'Généralités Réseaux',
    },
    libelle: 'Généralités Réseaux',
    color: '#92A4FF',
  },
  '3241': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3238',
      libelle: 'Réseaux et Télécommunications',
    },
    '3': {
      id: '3241',
      libelle: 'Protocoles et standards',
    },
    libelle: 'Protocoles et standards',
    color: '#92A4FF',
  },
  '3242': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3238',
      libelle: 'Réseaux et Télécommunications',
    },
    '3': {
      id: '3242',
      libelle: 'Réseaux sans fil et télécommunications mobiles',
    },
    libelle: 'Réseaux sans fil et télécommunications mobiles',
    color: '#92A4FF',
  },
  '3243': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    libelle: 'Système',
    color: '#92A4FF',
  },
  '3244': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3244',
      libelle: 'Généralités système',
    },
    libelle: 'Généralités système',
    color: '#92A4FF',
  },
  '3245': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3245',
      libelle: 'Unix - Linux',
    },
    libelle: 'Unix - Linux',
    color: '#92A4FF',
  },
  '3246': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3246',
      libelle: 'MacOS',
    },
    libelle: 'MacOS',
    color: '#92A4FF',
  },
  '3247': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3247',
      libelle: 'Windows',
    },
    libelle: 'Windows',
    color: '#92A4FF',
  },
  '3248': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3248',
      libelle: 'Systèmes embarqués',
    },
    libelle: 'Systèmes embarqués',
    color: '#92A4FF',
  },
  '3249': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3249',
      libelle: 'Virtualisation',
    },
    libelle: 'Virtualisation',
    color: '#92A4FF',
  },
  '3250': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3243',
      libelle: 'Système',
    },
    '3': {
      id: '3250',
      libelle: 'Systèmes mobiles (androïd...)',
    },
    libelle: 'Systèmes mobiles (androïd...)',
    color: '#92A4FF',
  },
  '3251': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    libelle: 'Données et Bases de données',
    color: '#92A4FF',
  },
  '3252': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3252',
      libelle: 'Bases de données',
    },
    libelle: 'Bases de données',
    color: '#92A4FF',
  },
  '3253': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3253',
      libelle: 'Bases de données relationnelles',
    },
    libelle: 'Bases de données relationnelles',
    color: '#92A4FF',
  },
  '3254': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3254',
      libelle: 'Bases de données non relationnelles',
    },
    libelle: 'Bases de données non relationnelles',
    color: '#92A4FF',
  },
  '3255': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3255',
      libelle: 'Data mining',
    },
    libelle: 'Data mining',
    color: '#92A4FF',
  },
  '3256': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3256',
      libelle: 'XML',
    },
    libelle: 'XML',
    color: '#92A4FF',
  },
  '3257': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3251',
      libelle: 'Données et Bases de données',
    },
    '3': {
      id: '3257',
      libelle: 'Indexation et moteurs de recherche',
    },
    libelle: 'Indexation et moteurs de recherche',
    color: '#92A4FF',
  },
  '3258': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    libelle: 'Sécurité',
    color: '#92A4FF',
  },
  '4066': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4066',
      libelle: 'Protection de la vie privée et des données',
    },
    libelle: 'Protection de la vie privée et des données',
    color: '#92A4FF',
  },
  '4067': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4067',
      libelle: 'Fraude et piratage informatique',
    },
    libelle: 'Fraude et piratage informatique',
    color: '#92A4FF',
  },
  '4068': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4068',
      libelle: 'Virus informatiques, chevaux de Troie et vers',
    },
    libelle: 'Virus informatiques, chevaux de Troie et vers',
    color: '#92A4FF',
  },
  '4069': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4069',
      libelle: 'Pare-feu',
    },
    libelle: 'Pare-feu',
    color: '#92A4FF',
  },
  '4070': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4070',
      libelle: 'Spam',
    },
    libelle: 'Spam',
    color: '#92A4FF',
  },
  '4072': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4072',
      libelle: 'Cryptage des données',
    },
    libelle: 'Cryptage des données',
    color: '#92A4FF',
  },
  '4071': {
    '1': {
      id: '3193',
      libelle: 'INFORMATIQUE',
    },
    '2': {
      id: '3258',
      libelle: 'Sécurité',
    },
    '3': {
      id: '4071',
      libelle: 'Spyware / logiciel espion',
    },
    libelle: 'Spyware / logiciel espion',
    color: '#92A4FF',
  },
  '3259': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    libelle: 'DROIT',
    color: '#FC955C',
  },
  '3260': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    libelle: 'Droit général',
    color: '#FC955C',
  },
  '4272': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '4272',
      libelle: 'Professions du droit',
    },
    libelle: 'Professions du droit',
    color: '#FC955C',
  },
  '4273': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '4272',
      libelle: 'Professions du droit',
    },
    '4': {
      id: '4273',
      libelle: 'Déontologie',
    },
    libelle: 'Déontologie',
    color: '#FC955C',
  },
  '4274': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '4274',
      libelle: 'Vocabulaire juridique',
    },
    libelle: 'Vocabulaire juridique',
    color: '#FC955C',
  },
  '3261': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '3261',
      libelle: 'Introduction au droit',
    },
    libelle: 'Introduction au droit',
    color: '#FC955C',
  },
  '3262': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '3262',
      libelle: 'Histoire du droit',
    },
    libelle: 'Histoire du droit',
    color: '#FC955C',
  },
  '3263': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '3263',
      libelle: 'Théorie, philosophie et sociologie du droit',
    },
    libelle: 'Théorie, philosophie et sociologie du droit',
    color: '#FC955C',
  },
  '4098': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3260',
      libelle: 'Droit général',
    },
    '3': {
      id: '4098',
      libelle: 'Droit comparé',
    },
    libelle: 'Droit comparé',
    color: '#FC955C',
  },
  '3264': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    libelle: 'Droit public',
    color: '#FC955C',
  },
  '4099': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '4099',
      libelle: "Libertés publiques - Droits de l'Homme",
    },
    libelle: "Libertés publiques - Droits de l'Homme",
    color: '#FC955C',
  },
  '4100': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '4100',
      libelle: 'Droit des étrangers',
    },
    libelle: 'Droit des étrangers',
    color: '#FC955C',
  },
  '4101': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '4101',
      libelle: 'Droit électoral',
    },
    libelle: 'Droit électoral',
    color: '#FC955C',
  },
  '3265': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '3265',
      libelle: 'Droit constitutionnel',
    },
    libelle: 'Droit constitutionnel',
    color: '#FC955C',
  },
  '3266': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '3266',
      libelle: 'Droit administratif',
    },
    libelle: 'Droit administratif',
    color: '#FC955C',
  },
  '3267': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '3267',
      libelle: 'Finances publiques',
    },
    libelle: 'Finances publiques',
    color: '#FC955C',
  },
  '4275': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3264',
      libelle: 'Droit public',
    },
    '3': {
      id: '4275',
      libelle: 'Droit de la régulation',
    },
    libelle: 'Droit de la régulation',
    color: '#FC955C',
  },
  '3271': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3271',
      libelle: 'Droit pénal et procédure pénale',
    },
    libelle: 'Droit pénal et procédure pénale',
    color: '#FC955C',
  },
  '4276': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3271',
      libelle: 'Droit pénal et procédure pénale',
    },
    '3': {
      id: '4276',
      libelle: 'Droit pénal',
    },
    libelle: 'Droit pénal',
    color: '#FC955C',
  },
  '4277': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3271',
      libelle: 'Droit pénal et procédure pénale',
    },
    '3': {
      id: '4277',
      libelle: 'Procédure pénale',
    },
    libelle: 'Procédure pénale',
    color: '#FC955C',
  },
  '3268': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    libelle: 'Droit privé',
    color: '#FC955C',
  },
  '3269': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3269',
      libelle: 'Droit civil',
    },
    libelle: 'Droit civil',
    color: '#FC955C',
  },
  '4278': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '4278',
      libelle: 'Procédure civile/ Droit de l’exécution/ Droit processuel',
    },
    libelle: 'Procédure civile/ Droit de l’exécution/ Droit processuel',
    color: '#FC955C',
  },
  '3270': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3270',
      libelle: 'Droit des affaires et commercial',
    },
    libelle: 'Droit des affaires et commercial',
    color: '#FC955C',
  },
  '4102': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3270',
      libelle: 'Droit des affaires et commercial',
    },
    '4': {
      id: '4102',
      libelle: "Droit de l'arbitrage",
    },
    libelle: "Droit de l'arbitrage",
    color: '#FC955C',
  },
  '3272': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3272',
      libelle: 'Droit de la propriété intellectuelle et industrielle',
    },
    libelle: 'Droit de la propriété intellectuelle et industrielle',
    color: '#FC955C',
  },
  '3273': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3273',
      libelle: 'Droit social',
    },
    libelle: 'Droit social',
    color: '#FC955C',
  },
  '3274': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3274',
      libelle: 'Droit de la concurrence',
    },
    libelle: 'Droit de la concurrence',
    color: '#FC955C',
  },
  '3275': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3275',
      libelle: 'Droit fiscal',
    },
    libelle: 'Droit fiscal',
    color: '#FC955C',
  },
  '3276': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '3276',
      libelle: 'Droit de la consommation',
    },
    libelle: 'Droit de la consommation',
    color: '#FC955C',
  },
  '4073': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3268',
      libelle: 'Droit privé',
    },
    '3': {
      id: '4073',
      libelle: 'Droit du travail',
    },
    libelle: 'Droit du travail',
    color: '#FC955C',
  },
  '3277': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3277',
      libelle: 'Droit international',
    },
    libelle: 'Droit international',
    color: '#FC955C',
  },
  '3278': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3277',
      libelle: 'Droit international',
    },
    '3': {
      id: '3278',
      libelle: 'Droit international privé',
    },
    libelle: 'Droit international privé',
    color: '#FC955C',
  },
  '3279': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3277',
      libelle: 'Droit international',
    },
    '3': {
      id: '3279',
      libelle: 'Droit international public',
    },
    libelle: 'Droit international public',
    color: '#FC955C',
  },
  '3280': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3280',
      libelle: "Droit de l'Union européenne",
    },
    libelle: "Droit de l'Union européenne",
    color: '#FC955C',
  },
  '3281': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3280',
      libelle: "Droit de l'Union européenne",
    },
    '3': {
      id: '3281',
      libelle: "Droit institutionnel de l'Union européenne",
    },
    libelle: "Droit institutionnel de l'Union européenne",
    color: '#FC955C',
  },
  '3282': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '3280',
      libelle: "Droit de l'Union européenne",
    },
    '3': {
      id: '3282',
      libelle: "Droit matériel de l'Union européenne",
    },
    libelle: "Droit matériel de l'Union européenne",
    color: '#FC955C',
  },
  '4103': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4103',
      libelle: 'Droit immobilier - Urbanisme - Construction',
    },
    libelle: 'Droit immobilier - Urbanisme - Construction',
    color: '#FC955C',
  },
  '4104': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4104',
      libelle: 'Droit environnement - Transport - Rural - Energie',
    },
    libelle: 'Droit environnement - Transport - Rural - Energie',
    color: '#FC955C',
  },
  '4105': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4105',
      libelle: "Droit des médias et de l'information",
    },
    libelle: "Droit des médias et de l'information",
    color: '#FC955C',
  },
  '4106': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4106',
      libelle: 'Droit des associations et des fondations',
    },
    libelle: 'Droit des associations et des fondations',
    color: '#FC955C',
  },
  '4107': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4107',
      libelle: 'Droit des assurances',
    },
    libelle: 'Droit des assurances',
    color: '#FC955C',
  },
  '4108': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4108',
      libelle: 'Droit notarial',
    },
    libelle: 'Droit notarial',
    color: '#FC955C',
  },
  '4109': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4109',
      libelle: 'Droit de la santé - Droit médical',
    },
    libelle: 'Droit de la santé - Droit médical',
    color: '#FC955C',
  },
  '4110': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4110',
      libelle: 'Droit du sport',
    },
    libelle: 'Droit du sport',
    color: '#FC955C',
  },
  '4111': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4111',
      libelle: 'Droit du tourisme',
    },
    libelle: 'Droit du tourisme',
    color: '#FC955C',
  },
  '4112': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4112',
      libelle: 'Droit de la culture',
    },
    libelle: 'Droit de la culture',
    color: '#FC955C',
  },
  '4113': {
    '1': {
      id: '3259',
      libelle: 'DROIT',
    },
    '2': {
      id: '4113',
      libelle: "Droit de l'éducation",
    },
    libelle: "Droit de l'éducation",
    color: '#FC955C',
  },
  '3283': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    libelle: 'SCIENCES POLITIQUES',
    color: '#FC86B7',
  },
  '3284': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    libelle: 'Histoire des idées politiques',
    color: '#FC86B7',
  },
  '3285': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    '3': {
      id: '3285',
      libelle: "Histoire des idées politiques jusqu'à la fin du XVIIe siècle",
    },
    libelle: "Histoire des idées politiques jusqu'à la fin du XVIIe siècle",
    color: '#FC86B7',
  },
  '3286': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    '3': {
      id: '3286',
      libelle: 'Histoire des idées politiques depuis le XVIIIe siècle',
    },
    libelle: 'Histoire des idées politiques depuis le XVIIIe siècle',
    color: '#FC86B7',
  },
  '3287': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    '3': {
      id: '3287',
      libelle: 'Vie politique française et européenne',
    },
    libelle: 'Vie politique française et européenne',
    color: '#FC86B7',
  },
  '3288': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    '3': {
      id: '3288',
      libelle: 'Analyse des comportements politiques',
    },
    libelle: 'Analyse des comportements politiques',
    color: '#FC86B7',
  },
  '3289': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3284',
      libelle: 'Histoire des idées politiques',
    },
    '3': {
      id: '3289',
      libelle: 'Philosophie politique controverses contemporaines',
    },
    libelle: 'Philosophie politique controverses contemporaines',
    color: '#FC86B7',
  },
  '3290': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    libelle: "Organisation de l'État et action publique",
    color: '#FC86B7',
  },
  '3291': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3291',
      libelle: 'Institutions politiques françaises',
    },
    libelle: 'Institutions politiques françaises',
    color: '#FC86B7',
  },
  '3292': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3292',
      libelle: 'Institutions administratives locales',
    },
    libelle: 'Institutions administratives locales',
    color: '#FC86B7',
  },
  '3293': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3293',
      libelle: 'Socialisation politique',
    },
    libelle: 'Socialisation politique',
    color: '#FC86B7',
  },
  '3294': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3294',
      libelle: 'Démocratie délibérative, participative',
    },
    libelle: 'Démocratie délibérative, participative',
    color: '#FC86B7',
  },
  '3295': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3295',
      libelle: 'Élections, mobilisation électorale, campagnes',
    },
    libelle: 'Élections, mobilisation électorale, campagnes',
    color: '#FC86B7',
  },
  '3296': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3290',
      libelle: "Organisation de l'État et action publique",
    },
    '3': {
      id: '3296',
      libelle: 'Construction européenne',
    },
    libelle: 'Construction européenne',
    color: '#FC86B7',
  },
  '3297': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3297',
      libelle: 'Relations internationales',
    },
    libelle: 'Relations internationales',
    color: '#FC86B7',
  },
  '3298': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3297',
      libelle: 'Relations internationales',
    },
    '3': {
      id: '3298',
      libelle: 'Relations internationales',
    },
    libelle: 'Relations internationales',
    color: '#FC86B7',
  },
  '3299': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3297',
      libelle: 'Relations internationales',
    },
    '3': {
      id: '3299',
      libelle: 'Politique étrangère',
    },
    libelle: 'Politique étrangère',
    color: '#FC86B7',
  },
  '3300': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3300',
      libelle: 'Politique et pouvoir',
    },
    libelle: 'Politique et pouvoir',
    color: '#FC86B7',
  },
  '3301': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3300',
      libelle: 'Politique et pouvoir',
    },
    '3': {
      id: '3301',
      libelle: 'Les forces armées, les forces de police',
    },
    libelle: 'Les forces armées, les forces de police',
    color: '#FC86B7',
  },
  '3302': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3300',
      libelle: 'Politique et pouvoir',
    },
    '3': {
      id: '3302',
      libelle: 'Le pouvoir judiciaire',
    },
    libelle: 'Le pouvoir judiciaire',
    color: '#FC86B7',
  },
  '3303': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3300',
      libelle: 'Politique et pouvoir',
    },
    '3': {
      id: '3303',
      libelle: 'Le pouvoir économique',
    },
    libelle: 'Le pouvoir économique',
    color: '#FC86B7',
  },
  '3304': {
    '1': {
      id: '3283',
      libelle: 'SCIENCES POLITIQUES',
    },
    '2': {
      id: '3300',
      libelle: 'Politique et pouvoir',
    },
    '3': {
      id: '3304',
      libelle: "Le pouvoir religieux et l'État",
    },
    libelle: "Le pouvoir religieux et l'État",
    color: '#FC86B7',
  },
  '3305': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    libelle: 'SCIENCES ÉCONOMIQUES',
    color: '#FC7E87',
  },
  '3306': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    libelle: 'Économie de la mondialisation et du développement',
    color: '#FC7E87',
  },
  '3307': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    '3': {
      id: '3307',
      libelle: 'Commerce international',
    },
    libelle: 'Commerce international',
    color: '#FC7E87',
  },
  '3308': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    '3': {
      id: '3308',
      libelle: 'Économie du développement',
    },
    libelle: 'Économie du développement',
    color: '#FC7E87',
  },
  '3309': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    '3': {
      id: '3309',
      libelle: 'Économie géographique',
    },
    libelle: 'Économie géographique',
    color: '#FC7E87',
  },
  '3310': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    '3': {
      id: '3310',
      libelle: "Économie de l'environnement et des ressources naturelles",
    },
    libelle: "Économie de l'environnement et des ressources naturelles",
    color: '#FC7E87',
  },
  '3311': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3306',
      libelle: 'Économie de la mondialisation et du développement',
    },
    '3': {
      id: '3311',
      libelle: "Économie de l'agriculture",
    },
    libelle: "Économie de l'agriculture",
    color: '#FC7E87',
  },
  '3312': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    libelle: 'Économie publique, économie du travail et inégalités',
    color: '#FC7E87',
  },
  '3313': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3313',
      libelle: 'Économie du bonheur',
    },
    libelle: 'Économie du bonheur',
    color: '#FC7E87',
  },
  '3314': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3314',
      libelle: 'Économie politique des institutions',
    },
    libelle: 'Économie politique des institutions',
    color: '#FC7E87',
  },
  '3315': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3315',
      libelle: 'Éducation',
    },
    libelle: 'Éducation',
    color: '#FC7E87',
  },
  '3317': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3317',
      libelle: 'Patrimoine, revenu, redistribution',
    },
    libelle: 'Patrimoine, revenu, redistribution',
    color: '#FC7E87',
  },
  '3318': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3318',
      libelle: 'Santé',
    },
    libelle: 'Santé',
    color: '#FC7E87',
  },
  '3319': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3312',
      libelle: 'Économie publique, économie du travail et inégalités',
    },
    '3': {
      id: '3319',
      libelle: 'Travail, emploi et politiques sociales',
    },
    libelle: 'Travail, emploi et politiques sociales',
    color: '#FC7E87',
  },
  '3320': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    libelle: 'Économie théorique et expérimentale',
    color: '#FC7E87',
  },
  '3321': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3321',
      libelle: 'Comportements individuels',
    },
    libelle: 'Comportements individuels',
    color: '#FC7E87',
  },
  '3322': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3322',
      libelle: 'Économétrie',
    },
    libelle: 'Économétrie',
    color: '#FC7E87',
  },
  '3323': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3323',
      libelle: 'Économie expérimentale',
    },
    libelle: 'Économie expérimentale',
    color: '#FC7E87',
  },
  '3324': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3324',
      libelle: 'Interaction des comportements',
    },
    libelle: 'Interaction des comportements',
    color: '#FC7E87',
  },
  '3325': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3325',
      libelle: 'Microéconomie',
    },
    libelle: 'Microéconomie',
    color: '#FC7E87',
  },
  '3326': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3326',
      libelle: 'Méthode quantitative',
    },
    libelle: 'Méthode quantitative',
    color: '#FC7E87',
  },
  '3327': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3327',
      libelle: 'Théorie des jeux',
    },
    libelle: 'Théorie des jeux',
    color: '#FC7E87',
  },
  '3328': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3328',
      libelle: 'Macroéconomie',
    },
    libelle: 'Macroéconomie',
    color: '#FC7E87',
  },
  '3329': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3329',
      libelle: 'Dynamique macroéconomique',
    },
    libelle: 'Dynamique macroéconomique',
    color: '#FC7E87',
  },
  '3330': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3330',
      libelle: 'Économie financière',
    },
    libelle: 'Économie financière',
    color: '#FC7E87',
  },
  '3331': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3331',
      libelle: 'Finance internationale',
    },
    libelle: 'Finance internationale',
    color: '#FC7E87',
  },
  '3332': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3332',
      libelle: "Macroéconomie et accumulation d'actifs",
    },
    libelle: "Macroéconomie et accumulation d'actifs",
    color: '#FC7E87',
  },
  '3333': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3333',
      libelle: 'Marché du travail',
    },
    libelle: 'Marché du travail',
    color: '#FC7E87',
  },
  '3334': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3320',
      libelle: 'Économie théorique et expérimentale',
    },
    '3': {
      id: '3334',
      libelle: 'Politiques monétaires et budgétaires',
    },
    libelle: 'Politiques monétaires et budgétaires',
    color: '#FC7E87',
  },
  '3335': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    libelle: 'Marchés, risque et organisation',
    color: '#FC7E87',
  },
  '4114': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    '3': {
      id: '4114',
      libelle: 'Bourse - Patrimoine',
    },
    libelle: 'Bourse - Patrimoine',
    color: '#FC7E87',
  },
  '3336': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    '3': {
      id: '3336',
      libelle: 'Organisation industrielle',
    },
    libelle: 'Organisation industrielle',
    color: '#FC7E87',
  },
  '3337': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    '3': {
      id: '3337',
      libelle:
        "Régulation de secteurs spécifiques (santé, enseignement, marchés de l'énergie, politique de l'environnement...)",
    },
    libelle:
      "Régulation de secteurs spécifiques (santé, enseignement, marchés de l'énergie, politique de l'environnement...)",
    color: '#FC7E87',
  },
  '3338': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    '3': {
      id: '3338',
      libelle: 'Risques, marchés financiers et assurance',
    },
    libelle: 'Risques, marchés financiers et assurance',
    color: '#FC7E87',
  },
  '3339': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3335',
      libelle: 'Marchés, risque et organisation',
    },
    '3': {
      id: '3339',
      libelle: 'Structure de marché et politique de concurrence',
    },
    libelle: 'Structure de marché et politique de concurrence',
    color: '#FC7E87',
  },
  '3340': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3340',
      libelle: 'Histoire économique',
    },
    libelle: 'Histoire économique',
    color: '#FC7E87',
  },
  '3341': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3340',
      libelle: 'Histoire économique',
    },
    '3': {
      id: '3341',
      libelle: 'Histoire de la pensée économique',
    },
    libelle: 'Histoire de la pensée économique',
    color: '#FC7E87',
  },
  '3342': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3340',
      libelle: 'Histoire économique',
    },
    '3': {
      id: '3342',
      libelle: 'Accumulation et transmission de la richesse',
    },
    libelle: 'Accumulation et transmission de la richesse',
    color: '#FC7E87',
  },
  '3343': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3340',
      libelle: 'Histoire économique',
    },
    '3': {
      id: '3343',
      libelle: 'Mobilité occupationnelle et géographique',
    },
    libelle: 'Mobilité occupationnelle et géographique',
    color: '#FC7E87',
  },
  '3344': {
    '1': {
      id: '3305',
      libelle: 'SCIENCES ÉCONOMIQUES',
    },
    '2': {
      id: '3340',
      libelle: 'Histoire économique',
    },
    '3': {
      id: '3344',
      libelle: 'Histoire des institutions et marchés monétaires et financiers',
    },
    libelle: 'Histoire des institutions et marchés monétaires et financiers',
    color: '#FC7E87',
  },
  '3345': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    libelle: 'RELIGION',
    color: '#BB99FD',
  },
  '3347': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    libelle: 'Christianisme',
    color: '#BB99FD',
  },
  '4048': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4048',
      libelle: 'Textes bibliques',
    },
    libelle: 'Textes bibliques',
    color: '#BB99FD',
  },
  '4162': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4048',
      libelle: 'Textes bibliques',
    },
    '4': {
      id: '4162',
      libelle: 'Ancien testament',
    },
    libelle: 'Ancien testament',
    color: '#BB99FD',
  },
  '4163': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4048',
      libelle: 'Textes bibliques',
    },
    '4': {
      id: '4163',
      libelle: 'Bibles',
    },
    libelle: 'Bibles',
    color: '#BB99FD',
  },
  '4164': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4048',
      libelle: 'Textes bibliques',
    },
    '4': {
      id: '4164',
      libelle: 'Evangiles et Nouveau Testament',
    },
    libelle: 'Evangiles et Nouveau Testament',
    color: '#BB99FD',
  },
  '4165': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4048',
      libelle: 'Textes bibliques',
    },
    '4': {
      id: '4165',
      libelle: 'Bibles pour enfants',
    },
    libelle: 'Bibles pour enfants',
    color: '#BB99FD',
  },
  '4049': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4049',
      libelle: 'Etudes bibliques',
    },
    libelle: 'Etudes bibliques',
    color: '#BB99FD',
  },
  '4166': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4049',
      libelle: 'Etudes bibliques',
    },
    '4': {
      id: '4166',
      libelle: 'Introduction à la Bible',
    },
    libelle: 'Introduction à la Bible',
    color: '#BB99FD',
  },
  '4167': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4049',
      libelle: 'Etudes bibliques',
    },
    '4': {
      id: '4167',
      libelle: 'Histoire biblique',
    },
    libelle: 'Histoire biblique',
    color: '#BB99FD',
  },
  '4168': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4049',
      libelle: 'Etudes bibliques',
    },
    '4': {
      id: '4168',
      libelle: 'Dictionnaires et atlas bibliques',
    },
    libelle: 'Dictionnaires et atlas bibliques',
    color: '#BB99FD',
  },
  '4169': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4049',
      libelle: 'Etudes bibliques',
    },
    '4': {
      id: '4169',
      libelle: 'Exégèse et philologie biblique',
    },
    libelle: 'Exégèse et philologie biblique',
    color: '#BB99FD',
  },
  '4170': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4170',
      libelle: 'Mysticisme',
    },
    libelle: 'Mysticisme',
    color: '#BB99FD',
  },
  '4171': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4171',
      libelle: 'Œcuménisme',
    },
    libelle: 'Œcuménisme',
    color: '#BB99FD',
  },
  '3359': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3359',
      libelle: 'Spiritualité et témoignages chrétiens',
    },
    libelle: 'Spiritualité et témoignages chrétiens',
    color: '#BB99FD',
  },
  '3348': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    libelle: 'Catholicisme',
    color: '#BB99FD',
  },
  '4172': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '4172',
      libelle: 'Fêtes et pratiques catholiques',
    },
    libelle: 'Fêtes et pratiques catholiques',
    color: '#BB99FD',
  },
  '4173': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '4173',
      libelle: 'Textes spirituels du catholicisme',
    },
    libelle: 'Textes spirituels du catholicisme',
    color: '#BB99FD',
  },
  '4174': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '4174',
      libelle: 'Liturgies et prières',
    },
    libelle: 'Liturgies et prières',
    color: '#BB99FD',
  },
  '4175': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '4175',
      libelle: 'Textes du magistère',
    },
    libelle: 'Textes du magistère',
    color: '#BB99FD',
  },
  '3358': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '3358',
      libelle: 'Saints et grands figures du catholicisme',
    },
    libelle: 'Saints et grands figures du catholicisme',
    color: '#BB99FD',
  },
  '3361': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '3361',
      libelle: 'Catéchèse jeunesse',
    },
    libelle: 'Catéchèse jeunesse',
    color: '#BB99FD',
  },
  '3362': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '3362',
      libelle: 'Catéchuménat, catéchèse adulte',
    },
    libelle: 'Catéchuménat, catéchèse adulte',
    color: '#BB99FD',
  },
  '4279': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3348',
      libelle: 'Catholicisme',
    },
    '4': {
      id: '4279',
      libelle: 'Préparation aux sacrements',
    },
    libelle: 'Préparation aux sacrements',
    color: '#BB99FD',
  },
  '3349': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    libelle: 'Protestantisme',
    color: '#BB99FD',
  },
  '4176': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4176',
      libelle: 'Fêtes et pratiques protestantes',
    },
    libelle: 'Fêtes et pratiques protestantes',
    color: '#BB99FD',
  },
  '4177': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4177',
      libelle: 'Textes spirituels du protestantisme',
    },
    libelle: 'Textes spirituels du protestantisme',
    color: '#BB99FD',
  },
  '4178': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4178',
      libelle: 'Liturgies et prières',
    },
    libelle: 'Liturgies et prières',
    color: '#BB99FD',
  },
  '4179': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4179',
      libelle: 'Grands figures du protestantisme',
    },
    libelle: 'Grands figures du protestantisme',
    color: '#BB99FD',
  },
  '4180': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4180',
      libelle: 'Catéchèse jeunesse',
    },
    libelle: 'Catéchèse jeunesse',
    color: '#BB99FD',
  },
  '4181': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3349',
      libelle: 'Protestantisme',
    },
    '4': {
      id: '4181',
      libelle: 'Catéchuménat, catéchèse adulte',
    },
    libelle: 'Catéchuménat, catéchèse adulte',
    color: '#BB99FD',
  },
  '3350': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    libelle: 'Orthodoxie',
    color: '#BB99FD',
  },
  '4182': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4182',
      libelle: 'Fêtes et pratiques orthodoxes',
    },
    libelle: 'Fêtes et pratiques orthodoxes',
    color: '#BB99FD',
  },
  '4183': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4183',
      libelle: "Textes spirituels de l'orthodoxie",
    },
    libelle: "Textes spirituels de l'orthodoxie",
    color: '#BB99FD',
  },
  '4184': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4184',
      libelle: 'Liturgies et prières',
    },
    libelle: 'Liturgies et prières',
    color: '#BB99FD',
  },
  '4185': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4185',
      libelle: 'Textes du magistère',
    },
    libelle: 'Textes du magistère',
    color: '#BB99FD',
  },
  '4186': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4186',
      libelle: "Saints et grandes figures de l'orthodoxie",
    },
    libelle: "Saints et grandes figures de l'orthodoxie",
    color: '#BB99FD',
  },
  '4187': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4187',
      libelle: 'Catéchèse jeunesse',
    },
    libelle: 'Catéchèse jeunesse',
    color: '#BB99FD',
  },
  '4188': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '3350',
      libelle: 'Orthodoxie',
    },
    '4': {
      id: '4188',
      libelle: 'Catéchuménat, catéchèse adulte',
    },
    libelle: 'Catéchuménat, catéchèse adulte',
    color: '#BB99FD',
  },
  '4046': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    libelle: 'Théologie',
    color: '#BB99FD',
  },
  '4189': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    '4': {
      id: '4189',
      libelle: 'Théologies médiévales',
    },
    libelle: 'Théologies médiévales',
    color: '#BB99FD',
  },
  '4190': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    '4': {
      id: '4190',
      libelle: 'Théologies modernes (XVI-XVIII siècle)',
    },
    libelle: 'Théologies modernes (XVI-XVIII siècle)',
    color: '#BB99FD',
  },
  '4191': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    '4': {
      id: '4191',
      libelle: 'Théologies contemporaines',
    },
    libelle: 'Théologies contemporaines',
    color: '#BB99FD',
  },
  '4192': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    '4': {
      id: '4192',
      libelle: 'Philosophie et théologie',
    },
    libelle: 'Philosophie et théologie',
    color: '#BB99FD',
  },
  '4193': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4046',
      libelle: 'Théologie',
    },
    '4': {
      id: '4193',
      libelle: 'Thèmes doctrinaux',
    },
    libelle: 'Thèmes doctrinaux',
    color: '#BB99FD',
  },
  '4045': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    libelle: 'Patristique',
    color: '#BB99FD',
  },
  '4194': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    '4': {
      id: '4194',
      libelle: 'Pères du désert',
    },
    libelle: 'Pères du désert',
    color: '#BB99FD',
  },
  '4195': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    '4': {
      id: '4195',
      libelle: 'Pères grecs',
    },
    libelle: 'Pères grecs',
    color: '#BB99FD',
  },
  '4196': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    '4': {
      id: '4196',
      libelle: 'Pères latins',
    },
    libelle: 'Pères latins',
    color: '#BB99FD',
  },
  '4197': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    '4': {
      id: '4197',
      libelle: 'Pères orientaux',
    },
    libelle: 'Pères orientaux',
    color: '#BB99FD',
  },
  '4198': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4045',
      libelle: 'Patristique',
    },
    '4': {
      id: '4198',
      libelle: 'Patrologie',
    },
    libelle: 'Patrologie',
    color: '#BB99FD',
  },
  '4199': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3347',
      libelle: 'Christianisme',
    },
    '3': {
      id: '4199',
      libelle: 'Monachisme',
    },
    libelle: 'Monachisme',
    color: '#BB99FD',
  },
  '3351': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    libelle: 'Islam',
    color: '#BB99FD',
  },
  '4200': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4200',
      libelle: "Fêtes et pratiques de l'islam",
    },
    libelle: "Fêtes et pratiques de l'islam",
    color: '#BB99FD',
  },
  '4201': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4201',
      libelle: 'Coran',
    },
    libelle: 'Coran',
    color: '#BB99FD',
  },
  '4202': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4201',
      libelle: 'Coran',
    },
    '4': {
      id: '4202',
      libelle: 'Etudes et commentaires coraniques',
    },
    libelle: 'Etudes et commentaires coraniques',
    color: '#BB99FD',
  },
  '4203': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4203',
      libelle: 'Prières et invocations',
    },
    libelle: 'Prières et invocations',
    color: '#BB99FD',
  },
  '4204': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4204',
      libelle: 'Philosophie',
    },
    libelle: 'Philosophie',
    color: '#BB99FD',
  },
  '4205': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4205',
      libelle: 'Hadith',
    },
    libelle: 'Hadith',
    color: '#BB99FD',
  },
  '4206': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4206',
      libelle: 'Droit et jurisprudence',
    },
    libelle: 'Droit et jurisprudence',
    color: '#BB99FD',
  },
  '4207': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4207',
      libelle: "Grandes figures de l'islam",
    },
    libelle: "Grandes figures de l'islam",
    color: '#BB99FD',
  },
  '4208': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4208',
      libelle: 'Prophètes',
    },
    libelle: 'Prophètes',
    color: '#BB99FD',
  },
  '4209': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4209',
      libelle: "Courants de l'islam",
    },
    libelle: "Courants de l'islam",
    color: '#BB99FD',
  },
  '4210': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4209',
      libelle: "Courants de l'islam",
    },
    '4': {
      id: '4210',
      libelle: 'Sunnisme',
    },
    libelle: 'Sunnisme',
    color: '#BB99FD',
  },
  '4211': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4209',
      libelle: "Courants de l'islam",
    },
    '4': {
      id: '4211',
      libelle: 'Chiismes',
    },
    libelle: 'Chiismes',
    color: '#BB99FD',
  },
  '4212': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3351',
      libelle: 'Islam',
    },
    '3': {
      id: '4209',
      libelle: "Courants de l'islam",
    },
    '4': {
      id: '4212',
      libelle: 'Soufisme',
    },
    libelle: 'Soufisme',
    color: '#BB99FD',
  },
  '3352': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    libelle: 'Judaïsme',
    color: '#BB99FD',
  },
  '4213': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4213',
      libelle: 'Torah',
    },
    libelle: 'Torah',
    color: '#BB99FD',
  },
  '4214': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4213',
      libelle: 'Torah',
    },
    '4': {
      id: '4214',
      libelle: 'Talmud et écrits talmudiques',
    },
    libelle: 'Talmud et écrits talmudiques',
    color: '#BB99FD',
  },
  '4215': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4213',
      libelle: 'Torah',
    },
    '4': {
      id: '4215',
      libelle: 'Loi juive',
    },
    libelle: 'Loi juive',
    color: '#BB99FD',
  },
  '4216': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4216',
      libelle: 'Kabbale et autres textes spirituels',
    },
    libelle: 'Kabbale et autres textes spirituels',
    color: '#BB99FD',
  },
  '4217': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4217',
      libelle: 'Liturgies et prières',
    },
    libelle: 'Liturgies et prières',
    color: '#BB99FD',
  },
  '4218': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4218',
      libelle: 'Fêtes et pratiques du judaïsme',
    },
    libelle: 'Fêtes et pratiques du judaïsme',
    color: '#BB99FD',
  },
  '4219': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4219',
      libelle: 'Enseignement du judaïsme',
    },
    libelle: 'Enseignement du judaïsme',
    color: '#BB99FD',
  },
  '4220': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4220',
      libelle: 'Grandes figures du judaïsme',
    },
    libelle: 'Grandes figures du judaïsme',
    color: '#BB99FD',
  },
  '4221': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3352',
      libelle: 'Judaïsme',
    },
    '3': {
      id: '4221',
      libelle: 'Littérature rabbinique',
    },
    libelle: 'Littérature rabbinique',
    color: '#BB99FD',
  },
  '3353': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    libelle: 'Religions et sagesses orientales',
    color: '#BB99FD',
  },
  '3354': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    libelle: 'Bouddhisme',
    color: '#BB99FD',
  },
  '4222': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4222',
      libelle: 'Prières',
    },
    libelle: 'Prières',
    color: '#BB99FD',
  },
  '4223': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4223',
      libelle: 'Fêtes et pratiques du bouddhisme',
    },
    libelle: 'Fêtes et pratiques du bouddhisme',
    color: '#BB99FD',
  },
  '4224': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4224',
      libelle: 'Enseignement du bouddhisme',
    },
    libelle: 'Enseignement du bouddhisme',
    color: '#BB99FD',
  },
  '4225': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4225',
      libelle: 'Textes spirituels du bouddhisme',
    },
    libelle: 'Textes spirituels du bouddhisme',
    color: '#BB99FD',
  },
  '4226': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4226',
      libelle: 'Grands figures du bouddhisme',
    },
    libelle: 'Grands figures du bouddhisme',
    color: '#BB99FD',
  },
  '4227': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4227',
      libelle: 'Bouddhisme ancien',
    },
    libelle: 'Bouddhisme ancien',
    color: '#BB99FD',
  },
  '4228': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4228',
      libelle: 'Bouddhisme theravada',
    },
    libelle: 'Bouddhisme theravada',
    color: '#BB99FD',
  },
  '4229': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4229',
      libelle: 'Bouddhisme mahayana',
    },
    libelle: 'Bouddhisme mahayana',
    color: '#BB99FD',
  },
  '4230': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3354',
      libelle: 'Bouddhisme',
    },
    '4': {
      id: '4230',
      libelle: 'Bouddhisme tibétain (vajrayana)',
    },
    libelle: 'Bouddhisme tibétain (vajrayana)',
    color: '#BB99FD',
  },
  '3355': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    libelle: 'Hindouisme',
    color: '#BB99FD',
  },
  '4231': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4231',
      libelle: 'Prières',
    },
    libelle: 'Prières',
    color: '#BB99FD',
  },
  '4232': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4232',
      libelle: 'Fêtes et pratiques de l’hindouisme',
    },
    libelle: 'Fêtes et pratiques de l’hindouisme',
    color: '#BB99FD',
  },
  '4233': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4233',
      libelle: 'Enseignement de l’hindouisme',
    },
    libelle: 'Enseignement de l’hindouisme',
    color: '#BB99FD',
  },
  '4234': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4234',
      libelle: 'Théologie de l’hindouisme',
    },
    libelle: 'Théologie de l’hindouisme',
    color: '#BB99FD',
  },
  '4235': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4235',
      libelle: 'Textes spirituels de l’hindouisme',
    },
    libelle: 'Textes spirituels de l’hindouisme',
    color: '#BB99FD',
  },
  '4236': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '3355',
      libelle: 'Hindouisme',
    },
    '4': {
      id: '4236',
      libelle: 'Grandes figures de l’hindouisme',
    },
    libelle: 'Grandes figures de l’hindouisme',
    color: '#BB99FD',
  },
  '4237': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    libelle: 'Shintoïsme',
    color: '#BB99FD',
  },
  '4238': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    '4': {
      id: '4238',
      libelle: 'Prières',
    },
    libelle: 'Prières',
    color: '#BB99FD',
  },
  '4239': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    '4': {
      id: '4239',
      libelle: 'Fêtes et pratiques du shintoïsme',
    },
    libelle: 'Fêtes et pratiques du shintoïsme',
    color: '#BB99FD',
  },
  '4240': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    '4': {
      id: '4240',
      libelle: 'Enseignement du shintoïsme',
    },
    libelle: 'Enseignement du shintoïsme',
    color: '#BB99FD',
  },
  '4241': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    '4': {
      id: '4241',
      libelle: 'Textes spirituels du shintoïsme',
    },
    libelle: 'Textes spirituels du shintoïsme',
    color: '#BB99FD',
  },
  '4242': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4237',
      libelle: 'Shintoïsme',
    },
    '4': {
      id: '4242',
      libelle: 'Grandes figures du shintoïsme',
    },
    libelle: 'Grandes figures du shintoïsme',
    color: '#BB99FD',
  },
  '4243': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    libelle: 'Taoïsme',
    color: '#BB99FD',
  },
  '4244': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    '4': {
      id: '4244',
      libelle: 'Prières',
    },
    libelle: 'Prières',
    color: '#BB99FD',
  },
  '4245': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    '4': {
      id: '4245',
      libelle: 'Fêtes et pratiques du taoïsme',
    },
    libelle: 'Fêtes et pratiques du taoïsme',
    color: '#BB99FD',
  },
  '4246': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    '4': {
      id: '4246',
      libelle: 'Enseignement du taoïsme',
    },
    libelle: 'Enseignement du taoïsme',
    color: '#BB99FD',
  },
  '4247': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    '4': {
      id: '4247',
      libelle: 'Textes spirituels du taoïsme',
    },
    libelle: 'Textes spirituels du taoïsme',
    color: '#BB99FD',
  },
  '4248': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4243',
      libelle: 'Taoïsme',
    },
    '4': {
      id: '4248',
      libelle: 'Grandes figures du taoïsme',
    },
    libelle: 'Grandes figures du taoïsme',
    color: '#BB99FD',
  },
  '4249': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    libelle: 'Confucianisme',
    color: '#BB99FD',
  },
  '4250': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    '4': {
      id: '4250',
      libelle: 'Prières',
    },
    libelle: 'Prières',
    color: '#BB99FD',
  },
  '4251': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    '4': {
      id: '4251',
      libelle: 'Fêtes et pratiques du confucianisme',
    },
    libelle: 'Fêtes et pratiques du confucianisme',
    color: '#BB99FD',
  },
  '4252': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    '4': {
      id: '4252',
      libelle: 'Enseignement du confucianisme',
    },
    libelle: 'Enseignement du confucianisme',
    color: '#BB99FD',
  },
  '4253': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    '4': {
      id: '4253',
      libelle: 'Textes spirituels du confucianisme',
    },
    libelle: 'Textes spirituels du confucianisme',
    color: '#BB99FD',
  },
  '4254': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3353',
      libelle: 'Religions et sagesses orientales',
    },
    '3': {
      id: '4249',
      libelle: 'Confucianisme',
    },
    '4': {
      id: '4254',
      libelle: 'Grands figures du confucianisme',
    },
    libelle: 'Grands figures du confucianisme',
    color: '#BB99FD',
  },
  '4255': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4255',
      libelle: 'Polythéisme, spiritualité ethnique',
    },
    libelle: 'Polythéisme, spiritualité ethnique',
    color: '#BB99FD',
  },
  '3917': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3917',
      libelle: "Religions de l'Antiquité",
    },
    libelle: "Religions de l'Antiquité",
    color: '#BB99FD',
  },
  '4064': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4064',
      libelle: 'Agnosticisme',
    },
    libelle: 'Agnosticisme',
    color: '#BB99FD',
  },
  '4256': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4256',
      libelle: 'Athéisme',
    },
    libelle: 'Athéisme',
    color: '#BB99FD',
  },
  '3356': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    libelle: 'Histoire des religions',
    color: '#BB99FD',
  },
  '4034': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4034',
      libelle: 'Histoire du christianisme',
    },
    libelle: 'Histoire du christianisme',
    color: '#BB99FD',
  },
  '4257': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4034',
      libelle: 'Histoire du christianisme',
    },
    '4': {
      id: '4257',
      libelle: 'Histoire du catholicisme',
    },
    libelle: 'Histoire du catholicisme',
    color: '#BB99FD',
  },
  '4258': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4034',
      libelle: 'Histoire du christianisme',
    },
    '4': {
      id: '4258',
      libelle: 'Histoire du protestantisme',
    },
    libelle: 'Histoire du protestantisme',
    color: '#BB99FD',
  },
  '4259': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4034',
      libelle: 'Histoire du christianisme',
    },
    '4': {
      id: '4259',
      libelle: 'Histoire de l’orthodoxie',
    },
    libelle: 'Histoire de l’orthodoxie',
    color: '#BB99FD',
  },
  '4051': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4051',
      libelle: "Histoire de l'islam",
    },
    libelle: "Histoire de l'islam",
    color: '#BB99FD',
  },
  '4035': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4035',
      libelle: 'Histoire du judaïsme',
    },
    libelle: 'Histoire du judaïsme',
    color: '#BB99FD',
  },
  '4260': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4260',
      libelle: 'Histoire des religions orientales',
    },
    libelle: 'Histoire des religions orientales',
    color: '#BB99FD',
  },
  '4261': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4260',
      libelle: 'Histoire des religions orientales',
    },
    '4': {
      id: '4261',
      libelle: 'Histoire du bouddhisme',
    },
    libelle: 'Histoire du bouddhisme',
    color: '#BB99FD',
  },
  '4262': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3356',
      libelle: 'Histoire des religions',
    },
    '3': {
      id: '4260',
      libelle: 'Histoire des religions orientales',
    },
    '4': {
      id: '4262',
      libelle: "Histoire de l'hindouisme",
    },
    libelle: "Histoire de l'hindouisme",
    color: '#BB99FD',
  },
  '3363': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3363',
      libelle: 'Jeunesse',
    },
    libelle: 'Jeunesse',
    color: '#BB99FD',
  },
  '4263': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3363',
      libelle: 'Jeunesse',
    },
    '3': {
      id: '4263',
      libelle: 'Christianisme',
    },
    libelle: 'Christianisme',
    color: '#BB99FD',
  },
  '4264': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3363',
      libelle: 'Jeunesse',
    },
    '3': {
      id: '4264',
      libelle: 'Islam',
    },
    libelle: 'Islam',
    color: '#BB99FD',
  },
  '4265': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3363',
      libelle: 'Jeunesse',
    },
    '3': {
      id: '4265',
      libelle: 'Judaïsme',
    },
    libelle: 'Judaïsme',
    color: '#BB99FD',
  },
  '4266': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3363',
      libelle: 'Jeunesse',
    },
    '3': {
      id: '4266',
      libelle: 'Religions orientales',
    },
    libelle: 'Religions orientales',
    color: '#BB99FD',
  },
  '3364': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '3364',
      libelle: 'Religion et société',
    },
    libelle: 'Religion et société',
    color: '#BB99FD',
  },
  '4267': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4267',
      libelle: 'Dialogue interreligieux',
    },
    libelle: 'Dialogue interreligieux',
    color: '#BB99FD',
  },
  '4268': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4268',
      libelle: 'Religion comparée',
    },
    libelle: 'Religion comparée',
    color: '#BB99FD',
  },
  '4269': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4269',
      libelle: 'Spiritualité, témoignages de sagesses',
    },
    libelle: 'Spiritualité, témoignages de sagesses',
    color: '#BB99FD',
  },
  '4270': {
    '1': {
      id: '3345',
      libelle: 'RELIGION',
    },
    '2': {
      id: '4270',
      libelle: 'Méditations',
    },
    libelle: 'Méditations',
    color: '#BB99FD',
  },
  '3365': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    libelle: 'ÉSOTÉRISME, OCCULTISME',
    color: '#D59AED',
  },
  '3366': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    libelle: 'Magie',
    color: '#D59AED',
  },
  '3367': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3367',
      libelle: 'Hermétisme, Alchimie',
    },
    libelle: 'Hermétisme, Alchimie',
    color: '#D59AED',
  },
  '3368': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3368',
      libelle: 'Ésotérisme',
    },
    libelle: 'Ésotérisme',
    color: '#D59AED',
  },
  '3369': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3369',
      libelle: 'Sorcellerie',
    },
    libelle: 'Sorcellerie',
    color: '#D59AED',
  },
  '3370': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3370',
      libelle: 'Symboles',
    },
    libelle: 'Symboles',
    color: '#D59AED',
  },
  '3371': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3371',
      libelle: 'Parapsychisme, Magnétisme, Guérisseurs',
    },
    libelle: 'Parapsychisme, Magnétisme, Guérisseurs',
    color: '#D59AED',
  },
  '3372': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3366',
      libelle: 'Magie',
    },
    '3': {
      id: '3372',
      libelle: 'Art divinatoire',
    },
    libelle: 'Art divinatoire',
    color: '#D59AED',
  },
  '3373': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3373',
      libelle: 'Énigme, Phénomène inexpliqué',
    },
    libelle: 'Énigme, Phénomène inexpliqué',
    color: '#D59AED',
  },
  '3374': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3374',
      libelle: 'Esprit, Paranormal',
    },
    libelle: 'Esprit, Paranormal',
    color: '#D59AED',
  },
  '3375': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3375',
      libelle: 'Sociétés secrètes',
    },
    libelle: 'Sociétés secrètes',
    color: '#D59AED',
  },
  '3376': {
    '1': {
      id: '3365',
      libelle: 'ÉSOTÉRISME, OCCULTISME',
    },
    '2': {
      id: '3376',
      libelle: 'Croyance, Mythe, Rêve',
    },
    libelle: 'Croyance, Mythe, Rêve',
    color: '#D59AED',
  },
  '3377': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    libelle: 'HISTOIRE',
    color: '#B3E87E',
  },
  '3378': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    libelle: 'Histoire générale et thématique',
    color: '#B3E87E',
  },
  '3379': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '3379',
      libelle: 'Personnages historiques',
    },
    libelle: 'Personnages historiques',
    color: '#B3E87E',
  },
  '3380': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '3380',
      libelle: 'Documents historiques',
    },
    libelle: 'Documents historiques',
    color: '#B3E87E',
  },
  '3381': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '3381',
      libelle: 'Monde',
    },
    libelle: 'Monde',
    color: '#B3E87E',
  },
  '4040': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '4040',
      libelle: 'Afrique',
    },
    libelle: 'Afrique',
    color: '#B3E87E',
  },
  '4037': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '4037',
      libelle: 'Amériques',
    },
    libelle: 'Amériques',
    color: '#B3E87E',
  },
  '4036': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '4036',
      libelle: 'Asie',
    },
    libelle: 'Asie',
    color: '#B3E87E',
  },
  '4039': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '4039',
      libelle: 'Moyen-Orient',
    },
    libelle: 'Moyen-Orient',
    color: '#B3E87E',
  },
  '4038': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '4038',
      libelle: 'Océanie',
    },
    libelle: 'Océanie',
    color: '#B3E87E',
  },
  '3382': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '3382',
      libelle: 'Europe',
    },
    libelle: 'Europe',
    color: '#B3E87E',
  },
  '3383': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3378',
      libelle: 'Histoire générale et thématique',
    },
    '3': {
      id: '3383',
      libelle: 'France',
    },
    libelle: 'France',
    color: '#B3E87E',
  },
  '3384': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3384',
      libelle: 'Préhistoire',
    },
    libelle: 'Préhistoire',
    color: '#B3E87E',
  },
  '3385': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3385',
      libelle: 'Antiquité',
    },
    libelle: 'Antiquité',
    color: '#B3E87E',
  },
  '3386': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3386',
      libelle: 'Moyen Age',
    },
    libelle: 'Moyen Age',
    color: '#B3E87E',
  },
  '3387': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3387',
      libelle: 'Renaissance',
    },
    libelle: 'Renaissance',
    color: '#B3E87E',
  },
  '3388': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3388',
      libelle: 'Les Temps Modernes (avant 1799)',
    },
    libelle: 'Les Temps Modernes (avant 1799)',
    color: '#B3E87E',
  },
  '3389': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3389',
      libelle: 'Histoire contemporaine (1799 - 1914)',
    },
    libelle: 'Histoire contemporaine (1799 - 1914)',
    color: '#B3E87E',
  },
  '3390': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3390',
      libelle: 'Première Guerre Mondiale',
    },
    libelle: 'Première Guerre Mondiale',
    color: '#B3E87E',
  },
  '3391': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3391',
      libelle: "Histoire de l'entre-deux guerres (1919-1939)",
    },
    libelle: "Histoire de l'entre-deux guerres (1919-1939)",
    color: '#B3E87E',
  },
  '3392': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3392',
      libelle: 'Deuxième Guerre Mondiale',
    },
    libelle: 'Deuxième Guerre Mondiale',
    color: '#B3E87E',
  },
  '3393': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3393',
      libelle: 'Période 1945-1989',
    },
    libelle: 'Période 1945-1989',
    color: '#B3E87E',
  },
  '3394': {
    '1': {
      id: '3377',
      libelle: 'HISTOIRE',
    },
    '2': {
      id: '3394',
      libelle: 'Histoire post-moderne (depuis 1989)',
    },
    libelle: 'Histoire post-moderne (depuis 1989)',
    color: '#B3E87E',
  },
  '3395': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    libelle: 'GÉOGRAPHIE',
    color: '#C7EA77',
  },
  '3396': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3396',
      libelle: 'Géographie générale, thématique',
    },
    libelle: 'Géographie générale, thématique',
    color: '#C7EA77',
  },
  '3397': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3397',
      libelle: 'Géographie régionale, géographie des territoires',
    },
    libelle: 'Géographie régionale, géographie des territoires',
    color: '#C7EA77',
  },
  '3398': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3398',
      libelle: 'Géographie physique, géo-système',
    },
    libelle: 'Géographie physique, géo-système',
    color: '#C7EA77',
  },
  '3399': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3398',
      libelle: 'Géographie physique, géo-système',
    },
    '3': {
      id: '3399',
      libelle: 'Géomorphologie',
    },
    libelle: 'Géomorphologie',
    color: '#C7EA77',
  },
  '3400': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3398',
      libelle: 'Géographie physique, géo-système',
    },
    '3': {
      id: '3400',
      libelle: 'Climatologie',
    },
    libelle: 'Climatologie',
    color: '#C7EA77',
  },
  '3401': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3398',
      libelle: 'Géographie physique, géo-système',
    },
    '3': {
      id: '3401',
      libelle: 'Hydrologie',
    },
    libelle: 'Hydrologie',
    color: '#C7EA77',
  },
  '3402': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3398',
      libelle: 'Géographie physique, géo-système',
    },
    '3': {
      id: '3402',
      libelle: 'Biogéographie',
    },
    libelle: 'Biogéographie',
    color: '#C7EA77',
  },
  '3403': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3403',
      libelle: 'Géographie humaine, écoumène',
    },
    libelle: 'Géographie humaine, écoumène',
    color: '#C7EA77',
  },
  '3404': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3403',
      libelle: 'Géographie humaine, écoumène',
    },
    '3': {
      id: '3404',
      libelle: 'Géographie sociale',
    },
    libelle: 'Géographie sociale',
    color: '#C7EA77',
  },
  '3405': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3403',
      libelle: 'Géographie humaine, écoumène',
    },
    '3': {
      id: '3405',
      libelle: 'Géopolitique',
    },
    libelle: 'Géopolitique',
    color: '#C7EA77',
  },
  '3406': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3403',
      libelle: 'Géographie humaine, écoumène',
    },
    '3': {
      id: '3406',
      libelle: "Géographie de l'environnement",
    },
    libelle: "Géographie de l'environnement",
    color: '#C7EA77',
  },
  '3407': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3403',
      libelle: 'Géographie humaine, écoumène',
    },
    '3': {
      id: '3407',
      libelle: 'Géographie culturelle',
    },
    libelle: 'Géographie culturelle',
    color: '#C7EA77',
  },
  '3408': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3408',
      libelle: 'Géographie mathématique',
    },
    libelle: 'Géographie mathématique',
    color: '#C7EA77',
  },
  '3409': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3408',
      libelle: 'Géographie mathématique',
    },
    '3': {
      id: '3409',
      libelle: 'Techniques cartographiques',
    },
    libelle: 'Techniques cartographiques',
    color: '#C7EA77',
  },
  '3410': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3408',
      libelle: 'Géographie mathématique',
    },
    '3': {
      id: '3410',
      libelle: 'Photogrammétrie',
    },
    libelle: 'Photogrammétrie',
    color: '#C7EA77',
  },
  '3412': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3408',
      libelle: 'Géographie mathématique',
    },
    '3': {
      id: '3412',
      libelle: 'Analyse spatiale',
    },
    libelle: 'Analyse spatiale',
    color: '#C7EA77',
  },
  '3413': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3413',
      libelle: 'Techniques géographiques',
    },
    libelle: 'Techniques géographiques',
    color: '#C7EA77',
  },
  '3414': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3413',
      libelle: 'Techniques géographiques',
    },
    '3': {
      id: '3414',
      libelle: "Système d'information géographique, géomatique",
    },
    libelle: "Système d'information géographique, géomatique",
    color: '#C7EA77',
  },
  '3415': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3413',
      libelle: 'Techniques géographiques',
    },
    '3': {
      id: '3415',
      libelle: 'Télédétection',
    },
    libelle: 'Télédétection',
    color: '#C7EA77',
  },
  '3416': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3416',
      libelle: 'Géographie quantitative, géostatistique',
    },
    libelle: 'Géographie quantitative, géostatistique',
    color: '#C7EA77',
  },
  '3417': {
    '1': {
      id: '3395',
      libelle: 'GÉOGRAPHIE',
    },
    '2': {
      id: '3417',
      libelle: 'Géographie qualitative',
    },
    libelle: 'Géographie qualitative',
    color: '#C7EA77',
  },
  '3418': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    libelle: 'OUVRAGES DE RÉFÉRENCE',
    color: '#CBD7BD',
  },
  '3419': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    libelle: 'Dictionnaires de français',
    color: '#CBD7BD',
  },
  '3420': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    '3': {
      id: '3420',
      libelle: 'Dictionnaires de français en un seul volume',
    },
    libelle: 'Dictionnaires de français en un seul volume',
    color: '#CBD7BD',
  },
  '3421': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    '3': {
      id: '3421',
      libelle: 'Dictionnaires de français en plusieurs volumes',
    },
    libelle: 'Dictionnaires de français en plusieurs volumes',
    color: '#CBD7BD',
  },
  '3422': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    '3': {
      id: '3422',
      libelle: 'Dictionnaires généralistes',
    },
    libelle: 'Dictionnaires généralistes',
    color: '#CBD7BD',
  },
  '3423': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    '3': {
      id: '3423',
      libelle: 'Dictionnaires de langage',
    },
    libelle: 'Dictionnaires de langage',
    color: '#CBD7BD',
  },
  '3424': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3419',
      libelle: 'Dictionnaires de français',
    },
    '3': {
      id: '3424',
      libelle: 'Dictionnaires scolaires',
    },
    libelle: 'Dictionnaires scolaires',
    color: '#CBD7BD',
  },
  '3425': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3425',
      libelle: 'Dictionnaires de langues étrangères',
    },
    libelle: 'Dictionnaires de langues étrangères',
    color: '#CBD7BD',
  },
  '3426': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3425',
      libelle: 'Dictionnaires de langues étrangères',
    },
    '3': {
      id: '3426',
      libelle: "Dictionnaires de langues d'origine étrangère",
    },
    libelle: "Dictionnaires de langues d'origine étrangère",
    color: '#CBD7BD',
  },
  '3427': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3425',
      libelle: 'Dictionnaires de langues étrangères',
    },
    '3': {
      id: '3427',
      libelle: 'Dictionnaires bilingues français/autres langues',
    },
    libelle: 'Dictionnaires bilingues français/autres langues',
    color: '#CBD7BD',
  },
  '4078': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '4078',
      libelle: 'Dictionnaires de sciences humaines',
    },
    libelle: 'Dictionnaires de sciences humaines',
    color: '#CBD7BD',
  },
  '3428': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3428',
      libelle: 'Encyclopédies générales',
    },
    libelle: 'Encyclopédies générales',
    color: '#CBD7BD',
  },
  '3429': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3428',
      libelle: 'Encyclopédies générales',
    },
    '3': {
      id: '3429',
      libelle: 'Encyclopédie générale en un seul volume',
    },
    libelle: 'Encyclopédie générale en un seul volume',
    color: '#CBD7BD',
  },
  '3430': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3428',
      libelle: 'Encyclopédies générales',
    },
    '3': {
      id: '3430',
      libelle: 'Encyclopédie générale en plusieurs volumes',
    },
    libelle: 'Encyclopédie générale en plusieurs volumes',
    color: '#CBD7BD',
  },
  '3431': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3431',
      libelle: 'Encyclopédies et dictionnaires thématiques',
    },
    libelle: 'Encyclopédies et dictionnaires thématiques',
    color: '#CBD7BD',
  },
  '3432': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3431',
      libelle: 'Encyclopédies et dictionnaires thématiques',
    },
    '3': {
      id: '3432',
      libelle: 'Encyclopédie et dictionnaire thématique en un seul volume',
    },
    libelle: 'Encyclopédie et dictionnaire thématique en un seul volume',
    color: '#CBD7BD',
  },
  '3433': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3431',
      libelle: 'Encyclopédies et dictionnaires thématiques',
    },
    '3': {
      id: '3433',
      libelle: 'Encyclopédie et dictionnaire thématique en plusieurs volumes',
    },
    libelle: 'Encyclopédie et dictionnaire thématique en plusieurs volumes',
    color: '#CBD7BD',
  },
  '3434': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3434',
      libelle: 'Encyclopédies en fascicules',
    },
    libelle: 'Encyclopédies en fascicules',
    color: '#CBD7BD',
  },
  '3801': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3801',
      libelle: 'Ouvrages de documentation',
    },
    libelle: 'Ouvrages de documentation',
    color: '#CBD7BD',
  },
  '4281': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3801',
      libelle: 'Ouvrages de documentation',
    },
    '3': {
      id: '4281',
      libelle: 'Annuaire',
    },
    libelle: 'Annuaire',
    color: '#CBD7BD',
  },
  '4282': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3801',
      libelle: 'Ouvrages de documentation',
    },
    '3': {
      id: '4282',
      libelle: 'Catalogue éditeur',
    },
    libelle: 'Catalogue éditeur',
    color: '#CBD7BD',
  },
  '4283': {
    '1': {
      id: '3418',
      libelle: 'OUVRAGES DE RÉFÉRENCE',
    },
    '2': {
      id: '3801',
      libelle: 'Ouvrages de documentation',
    },
    '3': {
      id: '4283',
      libelle: 'Bibliothéconomie',
    },
    libelle: 'Bibliothéconomie',
    color: '#CBD7BD',
  },
  '3435': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    libelle: 'LITTÉRATURE GÉNÉRALE',
    color: '#D1C8B3',
  },
  '3436': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    libelle: 'Oeuvres classiques',
    color: '#D1C8B3',
  },
  '3437': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    '3': {
      id: '3437',
      libelle: 'Antiquité',
    },
    libelle: 'Antiquité',
    color: '#D1C8B3',
  },
  '3438': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    '3': {
      id: '3438',
      libelle: 'Moyen Age',
    },
    libelle: 'Moyen Age',
    color: '#D1C8B3',
  },
  '3439': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    '3': {
      id: '3439',
      libelle: 'Moderne (avant 1799)',
    },
    libelle: 'Moderne (avant 1799)',
    color: '#D1C8B3',
  },
  '3440': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    '3': {
      id: '3440',
      libelle: 'XIXe siècle',
    },
    libelle: 'XIXe siècle',
    color: '#D1C8B3',
  },
  '3441': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3436',
      libelle: 'Oeuvres classiques',
    },
    '3': {
      id: '3441',
      libelle: 'XXe siècle avant 1945',
    },
    libelle: 'XXe siècle avant 1945',
    color: '#D1C8B3',
  },
  '3442': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3442',
      libelle: 'Romans',
    },
    libelle: 'Romans',
    color: '#D1C8B3',
  },
  '3443': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3442',
      libelle: 'Romans',
    },
    '3': {
      id: '3443',
      libelle: 'Romans francophones',
    },
    libelle: 'Romans francophones',
    color: '#D1C8B3',
  },
  '3444': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3442',
      libelle: 'Romans',
    },
    '3': {
      id: '3444',
      libelle: 'Romans étrangers',
    },
    libelle: 'Romans étrangers',
    color: '#D1C8B3',
  },
  '3445': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    libelle: 'Romans et nouvelles de genre',
    color: '#D1C8B3',
  },
  '3446': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3446',
      libelle: "Romans d'aventures",
    },
    libelle: "Romans d'aventures",
    color: '#D1C8B3',
  },
  '3447': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3447',
      libelle: "Romans d'espionnage",
    },
    libelle: "Romans d'espionnage",
    color: '#D1C8B3',
  },
  '3448': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3448',
      libelle: 'Romans policiers',
    },
    libelle: 'Romans policiers',
    color: '#D1C8B3',
  },
  '3449': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3448',
      libelle: 'Romans policiers',
    },
    '4': {
      id: '3449',
      libelle: 'Policier historique',
    },
    libelle: 'Policier historique',
    color: '#D1C8B3',
  },
  '3450': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3448',
      libelle: 'Romans policiers',
    },
    '4': {
      id: '3450',
      libelle: 'Policier procédural (type série les Experts)',
    },
    libelle: 'Policier procédural (type série les Experts)',
    color: '#D1C8B3',
  },
  '3451': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3448',
      libelle: 'Romans policiers',
    },
    '4': {
      id: '3451',
      libelle: 'Policier humoristique',
    },
    libelle: 'Policier humoristique',
    color: '#D1C8B3',
  },
  '4317': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3448',
      libelle: 'Romans policiers',
    },
    '4': {
      id: '4317',
      libelle: 'Cozy crime, cosy mystery',
    },
    libelle: 'Cozy crime, cosy mystery',
    color: '#D1C8B3',
  },
  '3452': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3452',
      libelle: 'Romans noirs',
    },
    libelle: 'Romans noirs',
    color: '#D1C8B3',
  },
  '3453': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    libelle: 'Thriller',
    color: '#D1C8B3',
  },
  '3454': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    '4': {
      id: '3454',
      libelle: 'Thriller psychologique',
    },
    libelle: 'Thriller psychologique',
    color: '#D1C8B3',
  },
  '3455': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    '4': {
      id: '3455',
      libelle: 'Thriller conspiration, politique, espionnage et militaire',
    },
    libelle: 'Thriller conspiration, politique, espionnage et militaire',
    color: '#D1C8B3',
  },
  '3456': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    '4': {
      id: '3456',
      libelle: 'Thriller médical et scientifique',
    },
    libelle: 'Thriller médical et scientifique',
    color: '#D1C8B3',
  },
  '3457': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    '4': {
      id: '3457',
      libelle: 'Thriller juridique et financier',
    },
    libelle: 'Thriller juridique et financier',
    color: '#D1C8B3',
  },
  '3458': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3453',
      libelle: 'Thriller',
    },
    '4': {
      id: '3458',
      libelle: 'Thriller ésotérique',
    },
    libelle: 'Thriller ésotérique',
    color: '#D1C8B3',
  },
  '3459': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    libelle: "Romans d'amour, romans sentimentaux",
    color: '#D1C8B3',
  },
  '3460': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    '4': {
      id: '3460',
      libelle: 'Sentimental contemporain',
    },
    libelle: 'Sentimental contemporain',
    color: '#D1C8B3',
  },
  '3461': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    '4': {
      id: '3461',
      libelle: 'Sentimental suspens',
    },
    libelle: 'Sentimental suspens',
    color: '#D1C8B3',
  },
  '3462': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    '4': {
      id: '3462',
      libelle: 'Sentimental historique',
    },
    libelle: 'Sentimental historique',
    color: '#D1C8B3',
  },
  '3463': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    '4': {
      id: '3463',
      libelle: 'Sentimental paranormal, bit-lit',
    },
    libelle: 'Sentimental paranormal, bit-lit',
    color: '#D1C8B3',
  },
  '3464': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3459',
      libelle: "Romans d'amour, romans sentimentaux",
    },
    '4': {
      id: '3464',
      libelle: 'Comédie sentimentale',
    },
    libelle: 'Comédie sentimentale',
    color: '#D1C8B3',
  },
  '3465': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3465',
      libelle: 'Chick lit',
    },
    libelle: 'Chick lit',
    color: '#D1C8B3',
  },
  '3466': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    libelle: 'Science-fiction',
    color: '#D1C8B3',
  },
  '3467': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3467',
      libelle: 'Fiction spéculative',
    },
    libelle: 'Fiction spéculative',
    color: '#D1C8B3',
  },
  '3468': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3468',
      libelle: 'Dystopie et Uchronie',
    },
    libelle: 'Dystopie et Uchronie',
    color: '#D1C8B3',
  },
  '3469': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3469',
      libelle: 'Cyberpunk, technologique',
    },
    libelle: 'Cyberpunk, technologique',
    color: '#D1C8B3',
  },
  '3504': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3504',
      libelle: 'Hard Science',
    },
    libelle: 'Hard Science',
    color: '#D1C8B3',
  },
  '3470': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3470',
      libelle: 'Space Opéra et Planet Opéra',
    },
    libelle: 'Space Opéra et Planet Opéra',
    color: '#D1C8B3',
  },
  '3471': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3471',
      libelle: 'Militaire',
    },
    libelle: 'Militaire',
    color: '#D1C8B3',
  },
  '3472': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3472',
      libelle: 'Voyage dans le temps',
    },
    libelle: 'Voyage dans le temps',
    color: '#D1C8B3',
  },
  '3473': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3466',
      libelle: 'Science-fiction',
    },
    '4': {
      id: '3473',
      libelle: 'Post-Apocalyptique',
    },
    libelle: 'Post-Apocalyptique',
    color: '#D1C8B3',
  },
  '3474': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3474',
      libelle: 'Fantastique, Terreur',
    },
    libelle: 'Fantastique, Terreur',
    color: '#D1C8B3',
  },
  '3475': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3474',
      libelle: 'Fantastique, Terreur',
    },
    '4': {
      id: '3475',
      libelle: 'Horreur, Terreur',
    },
    libelle: 'Horreur, Terreur',
    color: '#D1C8B3',
  },
  '3476': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3474',
      libelle: 'Fantastique, Terreur',
    },
    '4': {
      id: '3476',
      libelle: 'Gothique',
    },
    libelle: 'Gothique',
    color: '#D1C8B3',
  },
  '3477': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3474',
      libelle: 'Fantastique, Terreur',
    },
    '4': {
      id: '3477',
      libelle: 'Créatures surnaturelles (vampire, zombie, fantôme, fée, etc.)',
    },
    libelle: 'Créatures surnaturelles (vampire, zombie, fantôme, fée, etc.)',
    color: '#D1C8B3',
  },
  '3478': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    libelle: 'Fantasy, Merveilleux',
    color: '#D1C8B3',
  },
  '3479': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3479',
      libelle: 'Fantasy médiévale et arthurienne',
    },
    libelle: 'Fantasy médiévale et arthurienne',
    color: '#D1C8B3',
  },
  '3480': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3480',
      libelle: 'Heroic fantasy',
    },
    libelle: 'Heroic fantasy',
    color: '#D1C8B3',
  },
  '3481': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3481',
      libelle: 'Fantasy épique',
    },
    libelle: 'Fantasy épique',
    color: '#D1C8B3',
  },
  '3482': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3482',
      libelle: 'Fantasy humoristique',
    },
    libelle: 'Fantasy humoristique',
    color: '#D1C8B3',
  },
  '3483': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3483',
      libelle: 'Dark Fantasy',
    },
    libelle: 'Dark Fantasy',
    color: '#D1C8B3',
  },
  '3484': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3484',
      libelle: 'Fantasy historique',
    },
    libelle: 'Fantasy historique',
    color: '#D1C8B3',
  },
  '3485': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3485',
      libelle: 'Fantasy urbaine',
    },
    libelle: 'Fantasy urbaine',
    color: '#D1C8B3',
  },
  '3486': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3478',
      libelle: 'Fantasy, Merveilleux',
    },
    '4': {
      id: '3486',
      libelle: 'Fantasy animalière',
    },
    libelle: 'Fantasy animalière',
    color: '#D1C8B3',
  },
  '3487': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3487',
      libelle: 'Romans érotiques',
    },
    libelle: 'Romans érotiques',
    color: '#D1C8B3',
  },
  '3488': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3488',
      libelle: 'Romans humoristiques',
    },
    libelle: 'Romans humoristiques',
    color: '#D1C8B3',
  },
  '3489': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    libelle: 'Romans historiques',
    color: '#D1C8B3',
  },
  '3490': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3490',
      libelle: 'Préhistoire',
    },
    libelle: 'Préhistoire',
    color: '#D1C8B3',
  },
  '3491': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3491',
      libelle: 'Antiquité',
    },
    libelle: 'Antiquité',
    color: '#D1C8B3',
  },
  '3492': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3492',
      libelle: 'Moyen Age',
    },
    libelle: 'Moyen Age',
    color: '#D1C8B3',
  },
  '3493': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3493',
      libelle: 'Moderne (avant 1799)',
    },
    libelle: 'Moderne (avant 1799)',
    color: '#D1C8B3',
  },
  '3494': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3494',
      libelle: 'XIXe siècle',
    },
    libelle: 'XIXe siècle',
    color: '#D1C8B3',
  },
  '3505': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3505',
      libelle: '1914-1918',
    },
    libelle: '1914-1918',
    color: '#D1C8B3',
  },
  '3506': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3506',
      libelle: 'XXe siècle avant 1945',
    },
    libelle: 'XXe siècle avant 1945',
    color: '#D1C8B3',
  },
  '3507': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3445',
      libelle: 'Romans et nouvelles de genre',
    },
    '3': {
      id: '3489',
      libelle: 'Romans historiques',
    },
    '4': {
      id: '3507',
      libelle: '1939-1945',
    },
    libelle: '1939-1945',
    color: '#D1C8B3',
  },
  '3495': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    libelle: 'Contes et légendes',
    color: '#D1C8B3',
  },
  '3496': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3496',
      libelle: 'Contes et légendes du monde',
    },
    libelle: 'Contes et légendes du monde',
    color: '#D1C8B3',
  },
  '3497': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3497',
      libelle: 'Contes et légendes de France',
    },
    libelle: 'Contes et légendes de France',
    color: '#D1C8B3',
  },
  '3498': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3498',
      libelle: "Contes et légendes d'Europe",
    },
    libelle: "Contes et légendes d'Europe",
    color: '#D1C8B3',
  },
  '3499': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3499',
      libelle: 'Contes et légendes Maghreb, Moyen-Orient',
    },
    libelle: 'Contes et légendes Maghreb, Moyen-Orient',
    color: '#D1C8B3',
  },
  '3500': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3500',
      libelle: "Contes et légendes d'Afrique",
    },
    libelle: "Contes et légendes d'Afrique",
    color: '#D1C8B3',
  },
  '3501': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3501',
      libelle: "Contes et légendes d'Asie",
    },
    libelle: "Contes et légendes d'Asie",
    color: '#D1C8B3',
  },
  '3502': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3502',
      libelle: 'Contes et légendes des Amériques',
    },
    libelle: 'Contes et légendes des Amériques',
    color: '#D1C8B3',
  },
  '3503': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3495',
      libelle: 'Contes et légendes',
    },
    '3': {
      id: '3503',
      libelle: "Contes et légendes d'Océanie",
    },
    libelle: "Contes et légendes d'Océanie",
    color: '#D1C8B3',
  },
  '3508': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    libelle: 'Romans régionaux, Terroir',
    color: '#D1C8B3',
  },
  '3509': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3509',
      libelle: 'Alsace, Lorraine',
    },
    libelle: 'Alsace, Lorraine',
    color: '#D1C8B3',
  },
  '3510': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3509',
      libelle: 'Alsace, Lorraine',
    },
    '4': {
      id: '3510',
      libelle: 'Alsace (Strasbourg, Colmar, Mulhouse)',
    },
    libelle: 'Alsace (Strasbourg, Colmar, Mulhouse)',
    color: '#D1C8B3',
  },
  '3511': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3509',
      libelle: 'Alsace, Lorraine',
    },
    '4': {
      id: '3511',
      libelle: 'Lorraine (Metz, Nancy)',
    },
    libelle: 'Lorraine (Metz, Nancy)',
    color: '#D1C8B3',
  },
  '3512': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    libelle: 'Aquitaine',
    color: '#D1C8B3',
  },
  '3513': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    '4': {
      id: '3513',
      libelle: 'Béarn (Pau)',
    },
    libelle: 'Béarn (Pau)',
    color: '#D1C8B3',
  },
  '3514': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    '4': {
      id: '3514',
      libelle: 'Gascogne',
    },
    libelle: 'Gascogne',
    color: '#D1C8B3',
  },
  '3515': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    '4': {
      id: '3515',
      libelle: 'Guyenne (Bordeaux)',
    },
    libelle: 'Guyenne (Bordeaux)',
    color: '#D1C8B3',
  },
  '3516': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    '4': {
      id: '3516',
      libelle: 'Navarre',
    },
    libelle: 'Navarre',
    color: '#D1C8B3',
  },
  '3517': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3512',
      libelle: 'Aquitaine',
    },
    '4': {
      id: '3517',
      libelle: 'Périgord',
    },
    libelle: 'Périgord',
    color: '#D1C8B3',
  },
  '3518': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3518',
      libelle: 'Auvergne',
    },
    libelle: 'Auvergne',
    color: '#D1C8B3',
  },
  '3519': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3518',
      libelle: 'Auvergne',
    },
    '4': {
      id: '3519',
      libelle: 'Bourbonnais (Moulins)',
    },
    libelle: 'Bourbonnais (Moulins)',
    color: '#D1C8B3',
  },
  '3520': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3518',
      libelle: 'Auvergne',
    },
    '4': {
      id: '3520',
      libelle: 'Livradois, Forez',
    },
    libelle: 'Livradois, Forez',
    color: '#D1C8B3',
  },
  '3521': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3518',
      libelle: 'Auvergne',
    },
    '4': {
      id: '3521',
      libelle: 'Velay (Le Puy en Velay, Yssingeaux)',
    },
    libelle: 'Velay (Le Puy en Velay, Yssingeaux)',
    color: '#D1C8B3',
  },
  '3522': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3522',
      libelle: 'Normandie',
    },
    libelle: 'Normandie',
    color: '#D1C8B3',
  },
  '3523': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3522',
      libelle: 'Normandie',
    },
    '4': {
      id: '3523',
      libelle: 'Alençon',
    },
    libelle: 'Alençon',
    color: '#D1C8B3',
  },
  '3524': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3522',
      libelle: 'Normandie',
    },
    '4': {
      id: '3524',
      libelle: 'Perche',
    },
    libelle: 'Perche',
    color: '#D1C8B3',
  },
  '3525': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3522',
      libelle: 'Normandie',
    },
    '4': {
      id: '3525',
      libelle: "Pays d'Auge",
    },
    libelle: "Pays d'Auge",
    color: '#D1C8B3',
  },
  '3526': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3522',
      libelle: 'Normandie',
    },
    '4': {
      id: '3526',
      libelle: 'Pays de Caux',
    },
    libelle: 'Pays de Caux',
    color: '#D1C8B3',
  },
  '3527': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    libelle: 'Bourgogne',
    color: '#D1C8B3',
  },
  '3528': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    '4': {
      id: '3528',
      libelle: 'Chalonnais',
    },
    libelle: 'Chalonnais',
    color: '#D1C8B3',
  },
  '3529': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    '4': {
      id: '3529',
      libelle: 'Charolais',
    },
    libelle: 'Charolais',
    color: '#D1C8B3',
  },
  '3530': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    '4': {
      id: '3530',
      libelle: 'Mâconnais',
    },
    libelle: 'Mâconnais',
    color: '#D1C8B3',
  },
  '3531': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    '4': {
      id: '3531',
      libelle: 'Morvan',
    },
    libelle: 'Morvan',
    color: '#D1C8B3',
  },
  '3532': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3527',
      libelle: 'Bourgogne',
    },
    '4': {
      id: '3532',
      libelle: 'Nivernois (Nevers)',
    },
    libelle: 'Nivernois (Nevers)',
    color: '#D1C8B3',
  },
  '3533': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3533',
      libelle: 'Bretagne',
    },
    libelle: 'Bretagne',
    color: '#D1C8B3',
  },
  '3534': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    libelle: 'Centre, Val-de-Loire',
    color: '#D1C8B3',
  },
  '3535': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3535',
      libelle: 'Berry (Bourges)',
    },
    libelle: 'Berry (Bourges)',
    color: '#D1C8B3',
  },
  '3536': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3536',
      libelle: 'Blésois',
    },
    libelle: 'Blésois',
    color: '#D1C8B3',
  },
  '3537': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3537',
      libelle: 'Chartrain',
    },
    libelle: 'Chartrain',
    color: '#D1C8B3',
  },
  '3538': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3538',
      libelle: 'Drouais',
    },
    libelle: 'Drouais',
    color: '#D1C8B3',
  },
  '3539': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3539',
      libelle: 'Dunois',
    },
    libelle: 'Dunois',
    color: '#D1C8B3',
  },
  '3540': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3540',
      libelle: 'Orléanais',
    },
    libelle: 'Orléanais',
    color: '#D1C8B3',
  },
  '3541': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3541',
      libelle: 'Sancerrois',
    },
    libelle: 'Sancerrois',
    color: '#D1C8B3',
  },
  '3542': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3542',
      libelle: 'Sologne',
    },
    libelle: 'Sologne',
    color: '#D1C8B3',
  },
  '3543': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3543',
      libelle: 'Touraine (Tours)',
    },
    libelle: 'Touraine (Tours)',
    color: '#D1C8B3',
  },
  '3544': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3534',
      libelle: 'Centre, Val-de-Loire',
    },
    '4': {
      id: '3544',
      libelle: 'Val-de-Loire',
    },
    libelle: 'Val-de-Loire',
    color: '#D1C8B3',
  },
  '3545': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3545',
      libelle: 'Champagne, Ardennes',
    },
    libelle: 'Champagne, Ardennes',
    color: '#D1C8B3',
  },
  '3546': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3545',
      libelle: 'Champagne, Ardennes',
    },
    '4': {
      id: '3546',
      libelle: 'Givet',
    },
    libelle: 'Givet',
    color: '#D1C8B3',
  },
  '3547': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3545',
      libelle: 'Champagne, Ardennes',
    },
    '4': {
      id: '3547',
      libelle: 'Mouzon',
    },
    libelle: 'Mouzon',
    color: '#D1C8B3',
  },
  '3548': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3548',
      libelle: 'Corse',
    },
    libelle: 'Corse',
    color: '#D1C8B3',
  },
  '3549': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3549',
      libelle: 'Franche-Comté',
    },
    libelle: 'Franche-Comté',
    color: '#D1C8B3',
  },
  '3550': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3550',
      libelle: 'Paris, Ile-de-France',
    },
    libelle: 'Paris, Ile-de-France',
    color: '#D1C8B3',
  },
  '3551': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3550',
      libelle: 'Paris, Ile-de-France',
    },
    '4': {
      id: '3551',
      libelle: 'Paris',
    },
    libelle: 'Paris',
    color: '#D1C8B3',
  },
  '3552': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3550',
      libelle: 'Paris, Ile-de-France',
    },
    '4': {
      id: '3552',
      libelle: 'Ile-de-France',
    },
    libelle: 'Ile-de-France',
    color: '#D1C8B3',
  },
  '3553': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3553',
      libelle: 'Languedoc, Roussillon',
    },
    libelle: 'Languedoc, Roussillon',
    color: '#D1C8B3',
  },
  '3554': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3553',
      libelle: 'Languedoc, Roussillon',
    },
    '4': {
      id: '3554',
      libelle: 'Cévennes',
    },
    libelle: 'Cévennes',
    color: '#D1C8B3',
  },
  '3555': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3553',
      libelle: 'Languedoc, Roussillon',
    },
    '4': {
      id: '3555',
      libelle: 'Gévaudan',
    },
    libelle: 'Gévaudan',
    color: '#D1C8B3',
  },
  '3556': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3553',
      libelle: 'Languedoc, Roussillon',
    },
    '4': {
      id: '3556',
      libelle: 'Languedoc (Montpellier)',
    },
    libelle: 'Languedoc (Montpellier)',
    color: '#D1C8B3',
  },
  '3557': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3557',
      libelle: 'Nord, Pas de Calais, Picardie',
    },
    libelle: 'Nord, Pas de Calais, Picardie',
    color: '#D1C8B3',
  },
  '3558': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3557',
      libelle: 'Nord, Pas de Calais, Picardie',
    },
    '4': {
      id: '3558',
      libelle: 'Artois (Arras)',
    },
    libelle: 'Artois (Arras)',
    color: '#D1C8B3',
  },
  '3559': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3557',
      libelle: 'Nord, Pas de Calais, Picardie',
    },
    '4': {
      id: '3559',
      libelle: 'Flandre (Lille)',
    },
    libelle: 'Flandre (Lille)',
    color: '#D1C8B3',
  },
  '3560': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3557',
      libelle: 'Nord, Pas de Calais, Picardie',
    },
    '4': {
      id: '3560',
      libelle: 'Picardie (Amiens)',
    },
    libelle: 'Picardie (Amiens)',
    color: '#D1C8B3',
  },
  '3561': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3561',
      libelle: 'Pays de la Loire',
    },
    libelle: 'Pays de la Loire',
    color: '#D1C8B3',
  },
  '3562': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3561',
      libelle: 'Pays de la Loire',
    },
    '4': {
      id: '3562',
      libelle: 'Anjou (Angers)',
    },
    libelle: 'Anjou (Angers)',
    color: '#D1C8B3',
  },
  '3563': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3561',
      libelle: 'Pays de la Loire',
    },
    '4': {
      id: '3563',
      libelle: 'Maine et Perche (Le Mans)',
    },
    libelle: 'Maine et Perche (Le Mans)',
    color: '#D1C8B3',
  },
  '3564': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3561',
      libelle: 'Pays de la Loire',
    },
    '4': {
      id: '3564',
      libelle: 'Nantais',
    },
    libelle: 'Nantais',
    color: '#D1C8B3',
  },
  '3565': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3561',
      libelle: 'Pays de la Loire',
    },
    '4': {
      id: '3565',
      libelle: 'Vendée',
    },
    libelle: 'Vendée',
    color: '#D1C8B3',
  },
  '3566': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3566',
      libelle: 'Poitou-Charentes',
    },
    libelle: 'Poitou-Charentes',
    color: '#D1C8B3',
  },
  '3567': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3566',
      libelle: 'Poitou-Charentes',
    },
    '4': {
      id: '3567',
      libelle: 'Angoumois (Angoulême)',
    },
    libelle: 'Angoumois (Angoulême)',
    color: '#D1C8B3',
  },
  '3568': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3566',
      libelle: 'Poitou-Charentes',
    },
    '4': {
      id: '3568',
      libelle: 'Aunis-et-Saintonge (La Rochelle)',
    },
    libelle: 'Aunis-et-Saintonge (La Rochelle)',
    color: '#D1C8B3',
  },
  '3569': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3566',
      libelle: 'Poitou-Charentes',
    },
    '4': {
      id: '3569',
      libelle: 'Poitou (Poitiers)',
    },
    libelle: 'Poitou (Poitiers)',
    color: '#D1C8B3',
  },
  '3570': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    libelle: 'PACA',
    color: '#D1C8B3',
  },
  '3571': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3571',
      libelle: 'Camargue',
    },
    libelle: 'Camargue',
    color: '#D1C8B3',
  },
  '3572': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3572',
      libelle: 'Luberon',
    },
    libelle: 'Luberon',
    color: '#D1C8B3',
  },
  '3573': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3573',
      libelle: 'Nice',
    },
    libelle: 'Nice',
    color: '#D1C8B3',
  },
  '3574': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3574',
      libelle: 'Orange',
    },
    libelle: 'Orange',
    color: '#D1C8B3',
  },
  '3575': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3575',
      libelle: 'Provence (Aix)',
    },
    libelle: 'Provence (Aix)',
    color: '#D1C8B3',
  },
  '3576': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3576',
      libelle: 'Riviera',
    },
    libelle: 'Riviera',
    color: '#D1C8B3',
  },
  '3577': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3570',
      libelle: 'PACA',
    },
    '4': {
      id: '3577',
      libelle: 'Venaissin',
    },
    libelle: 'Venaissin',
    color: '#D1C8B3',
  },
  '3578': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    libelle: 'Rhône-Alpes',
    color: '#D1C8B3',
  },
  '3579': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3579',
      libelle: 'Beaujolais',
    },
    libelle: 'Beaujolais',
    color: '#D1C8B3',
  },
  '3580': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3580',
      libelle: 'Bresse',
    },
    libelle: 'Bresse',
    color: '#D1C8B3',
  },
  '3581': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3581',
      libelle: 'Dauphiné (Grenoble)',
    },
    libelle: 'Dauphiné (Grenoble)',
    color: '#D1C8B3',
  },
  '3582': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3582',
      libelle: 'Forez',
    },
    libelle: 'Forez',
    color: '#D1C8B3',
  },
  '3583': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3583',
      libelle: 'Lyonnais (Lyon)',
    },
    libelle: 'Lyonnais (Lyon)',
    color: '#D1C8B3',
  },
  '3584': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3584',
      libelle: 'Mercantour',
    },
    libelle: 'Mercantour',
    color: '#D1C8B3',
  },
  '3585': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3585',
      libelle: 'Savoie',
    },
    libelle: 'Savoie',
    color: '#D1C8B3',
  },
  '3586': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3578',
      libelle: 'Rhône-Alpes',
    },
    '4': {
      id: '3586',
      libelle: 'Vivarais',
    },
    libelle: 'Vivarais',
    color: '#D1C8B3',
  },
  '3587': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3587',
      libelle: 'Limousin',
    },
    libelle: 'Limousin',
    color: '#D1C8B3',
  },
  '3588': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3587',
      libelle: 'Limousin',
    },
    '4': {
      id: '3588',
      libelle: 'La Marche (Guéret)',
    },
    libelle: 'La Marche (Guéret)',
    color: '#D1C8B3',
  },
  '3589': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3587',
      libelle: 'Limousin',
    },
    '4': {
      id: '3589',
      libelle: 'Limousin (Limoges)',
    },
    libelle: 'Limousin (Limoges)',
    color: '#D1C8B3',
  },
  '3590': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    libelle: 'Midi-Pyrénées',
    color: '#D1C8B3',
  },
  '3591': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3591',
      libelle: 'Armagnac',
    },
    libelle: 'Armagnac',
    color: '#D1C8B3',
  },
  '3592': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3592',
      libelle: 'Aubrac',
    },
    libelle: 'Aubrac',
    color: '#D1C8B3',
  },
  '3593': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3593',
      libelle: 'Bigorre',
    },
    libelle: 'Bigorre',
    color: '#D1C8B3',
  },
  '3594': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3594',
      libelle: 'Causses',
    },
    libelle: 'Causses',
    color: '#D1C8B3',
  },
  '3595': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3595',
      libelle: 'Comminges',
    },
    libelle: 'Comminges',
    color: '#D1C8B3',
  },
  '3596': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3596',
      libelle: 'Couserans',
    },
    libelle: 'Couserans',
    color: '#D1C8B3',
  },
  '3597': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3597',
      libelle: 'Comté de Foix',
    },
    libelle: 'Comté de Foix',
    color: '#D1C8B3',
  },
  '3598': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3598',
      libelle: 'Haut-Languedoc, Toulousain',
    },
    libelle: 'Haut-Languedoc, Toulousain',
    color: '#D1C8B3',
  },
  '3599': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3599',
      libelle: 'Quercy',
    },
    libelle: 'Quercy',
    color: '#D1C8B3',
  },
  '3600': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3600',
      libelle: 'Lavedan',
    },
    libelle: 'Lavedan',
    color: '#D1C8B3',
  },
  '3601': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3590',
      libelle: 'Midi-Pyrénées',
    },
    '4': {
      id: '3601',
      libelle: 'Rouergue',
    },
    libelle: 'Rouergue',
    color: '#D1C8B3',
  },
  '3602': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    libelle: 'DROM-COM, Outre-mer, Territoires français',
    color: '#D1C8B3',
  },
  '3603': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3603',
      libelle: 'Antilles françaises',
    },
    libelle: 'Antilles françaises',
    color: '#D1C8B3',
  },
  '3604': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3604',
      libelle: 'Guyane',
    },
    libelle: 'Guyane',
    color: '#D1C8B3',
  },
  '3605': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3605',
      libelle: 'La Réunion',
    },
    libelle: 'La Réunion',
    color: '#D1C8B3',
  },
  '3606': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3606',
      libelle: 'Nouvelle-Calédonie (Mélanésie)',
    },
    libelle: 'Nouvelle-Calédonie (Mélanésie)',
    color: '#D1C8B3',
  },
  '3607': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3607',
      libelle: 'Polynésie française, Wallis-et-Futuna',
    },
    libelle: 'Polynésie française, Wallis-et-Futuna',
    color: '#D1C8B3',
  },
  '3608': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3608',
      libelle: 'Saint-Pierre et Miquelon',
    },
    libelle: 'Saint-Pierre et Miquelon',
    color: '#D1C8B3',
  },
  '3609': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3602',
      libelle: 'DROM-COM, Outre-mer, Territoires français',
    },
    '4': {
      id: '3609',
      libelle: 'TAAF (Terres australes antarctiques françaises)',
    },
    libelle: 'TAAF (Terres australes antarctiques françaises)',
    color: '#D1C8B3',
  },
  '3610': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3610',
      libelle: 'Monaco',
    },
    libelle: 'Monaco',
    color: '#D1C8B3',
  },
  '3611': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    color: '#D1C8B3',
  },
  '3612': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3612',
      libelle: 'Brabant wallon',
    },
    libelle: 'Brabant wallon',
    color: '#D1C8B3',
  },
  '3613': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3613',
      libelle: 'Flandres',
    },
    libelle: 'Flandres',
    color: '#D1C8B3',
  },
  '3614': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3614',
      libelle: 'Hainaut',
    },
    libelle: 'Hainaut',
    color: '#D1C8B3',
  },
  '3615': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3615',
      libelle: 'Liège',
    },
    libelle: 'Liège',
    color: '#D1C8B3',
  },
  '3616': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3616',
      libelle: 'Namur',
    },
    libelle: 'Namur',
    color: '#D1C8B3',
  },
  '3617': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3617',
      libelle: 'Luxembourg',
    },
    libelle: 'Luxembourg',
    color: '#D1C8B3',
  },
  '3618': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3611',
      libelle: 'Benelux (Belgique, Pays-Bas, Luxembourg)',
    },
    '4': {
      id: '3618',
      libelle: 'Pays-Bas',
    },
    libelle: 'Pays-Bas',
    color: '#D1C8B3',
  },
  '3619': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3619',
      libelle: 'Provinces de Québec',
    },
    libelle: 'Provinces de Québec',
    color: '#D1C8B3',
  },
  '3620': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3508',
      libelle: 'Romans régionaux, Terroir',
    },
    '3': {
      id: '3620',
      libelle: 'Cantons de Suisse',
    },
    libelle: 'Cantons de Suisse',
    color: '#D1C8B3',
  },
  '3621': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3621',
      libelle: 'Nouvelles',
    },
    libelle: 'Nouvelles',
    color: '#D1C8B3',
  },
  '3622': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    libelle: 'Théâtre',
    color: '#D1C8B3',
  },
  '3623': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3623',
      libelle: 'Tragédie',
    },
    libelle: 'Tragédie',
    color: '#D1C8B3',
  },
  '3624': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3623',
      libelle: 'Tragédie',
    },
    '4': {
      id: '3624',
      libelle: 'Tragédie antique',
    },
    libelle: 'Tragédie antique',
    color: '#D1C8B3',
  },
  '3625': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3623',
      libelle: 'Tragédie',
    },
    '4': {
      id: '3625',
      libelle: 'Tragédie classique',
    },
    libelle: 'Tragédie classique',
    color: '#D1C8B3',
  },
  '4084': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3623',
      libelle: 'Tragédie',
    },
    '4': {
      id: '4084',
      libelle: 'Dramaturgie contemporaine',
    },
    libelle: 'Dramaturgie contemporaine',
    color: '#D1C8B3',
  },
  '3626': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    libelle: 'Comédie',
    color: '#D1C8B3',
  },
  '4088': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '4088',
      libelle: 'Comédie musicale',
    },
    libelle: 'Comédie musicale',
    color: '#D1C8B3',
  },
  '3627': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '3627',
      libelle: 'Comédie de caractères, de moeurs',
    },
    libelle: 'Comédie de caractères, de moeurs',
    color: '#D1C8B3',
  },
  '3628': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '3628',
      libelle: 'Comédie ballet',
    },
    libelle: 'Comédie ballet',
    color: '#D1C8B3',
  },
  '3629': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '3629',
      libelle: 'Farce',
    },
    libelle: 'Farce',
    color: '#D1C8B3',
  },
  '3630': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '3630',
      libelle: 'Vaudeville',
    },
    libelle: 'Vaudeville',
    color: '#D1C8B3',
  },
  '4085': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '4085',
      libelle: 'Comédie antique et classique',
    },
    libelle: 'Comédie antique et classique',
    color: '#D1C8B3',
  },
  '4086': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '4086',
      libelle: 'Comédie contemporaine',
    },
    libelle: 'Comédie contemporaine',
    color: '#D1C8B3',
  },
  '4087': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '4087',
      libelle: 'Sketch',
    },
    libelle: 'Sketch',
    color: '#D1C8B3',
  },
  '4089': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3626',
      libelle: 'Comédie',
    },
    '4': {
      id: '4089',
      libelle: 'Comédie dramatique',
    },
    libelle: 'Comédie dramatique',
    color: '#D1C8B3',
  },
  '3631': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3631',
      libelle: 'Tragi-comédie',
    },
    libelle: 'Tragi-comédie',
    color: '#D1C8B3',
  },
  '3632': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '3632',
      libelle: 'Drame',
    },
    libelle: 'Drame',
    color: '#D1C8B3',
  },
  '4090': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3622',
      libelle: 'Théâtre',
    },
    '3': {
      id: '4090',
      libelle: 'Monologue',
    },
    libelle: 'Monologue',
    color: '#D1C8B3',
  },
  '3633': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    libelle: 'Poésie',
    color: '#D1C8B3',
  },
  '3634': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    '3': {
      id: '3634',
      libelle: 'Poésie lyrique (poésie chantée : ballade, élégie, rondeau...)',
    },
    libelle: 'Poésie lyrique (poésie chantée : ballade, élégie, rondeau...)',
    color: '#D1C8B3',
  },
  '3635': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    '3': {
      id: '3635',
      libelle: 'Poésie épique (épopée...)',
    },
    libelle: 'Poésie épique (épopée...)',
    color: '#D1C8B3',
  },
  '3636': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    '3': {
      id: '3636',
      libelle: 'Poésie satirique',
    },
    libelle: 'Poésie satirique',
    color: '#D1C8B3',
  },
  '3637': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    '3': {
      id: '3637',
      libelle: 'Poésie didactique (fables...)',
    },
    libelle: 'Poésie didactique (fables...)',
    color: '#D1C8B3',
  },
  '3638': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3633',
      libelle: 'Poésie',
    },
    '3': {
      id: '3638',
      libelle: 'Poésie contemporaine',
    },
    libelle: 'Poésie contemporaine',
    color: '#D1C8B3',
  },
  '3639': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3639',
      libelle: 'Art épistolaire, Correspondances, Discours',
    },
    libelle: 'Art épistolaire, Correspondances, Discours',
    color: '#D1C8B3',
  },
  '3640': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3640',
      libelle: 'Récits de voyages',
    },
    libelle: 'Récits de voyages',
    color: '#D1C8B3',
  },
  '3641': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3641',
      libelle: 'Récits',
    },
    libelle: 'Récits',
    color: '#D1C8B3',
  },
  '3642': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3642',
      libelle: 'Pamphlets, Maximes, Pensées, Portraits',
    },
    libelle: 'Pamphlets, Maximes, Pensées, Portraits',
    color: '#D1C8B3',
  },
  '3643': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3643',
      libelle: 'Essais littéraires',
    },
    libelle: 'Essais littéraires',
    color: '#D1C8B3',
  },
  '3644': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    libelle: 'Actualités, Reportages',
    color: '#D1C8B3',
  },
  '3645': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3645',
      libelle:
        'Société (culture, éducation, famille, mode, religion, sexualité)',
    },
    libelle: 'Société (culture, éducation, famille, mode, religion, sexualité)',
    color: '#D1C8B3',
  },
  '3646': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3646',
      libelle: 'Nature, écologie, climat, énergie, terre, agroalimentaire',
    },
    libelle: 'Nature, écologie, climat, énergie, terre, agroalimentaire',
    color: '#D1C8B3',
  },
  '3647': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3647',
      libelle: 'Aventures, documentaires, exploration, voyages',
    },
    libelle: 'Aventures, documentaires, exploration, voyages',
    color: '#D1C8B3',
  },
  '3648': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3648',
      libelle: 'Affaires, criminalité, enquêtes, justice, police',
    },
    libelle: 'Affaires, criminalité, enquêtes, justice, police',
    color: '#D1C8B3',
  },
  '3649': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3649',
      libelle: 'Médecine, santé, psychiatrie',
    },
    libelle: 'Médecine, santé, psychiatrie',
    color: '#D1C8B3',
  },
  '3650': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3650',
      libelle: 'Économie, Entreprise, Finance, Industrie',
    },
    libelle: 'Économie, Entreprise, Finance, Industrie',
    color: '#D1C8B3',
  },
  '3651': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3651',
      libelle:
        'Sciences, Techniques (astronomie, biologie, physique, chimie...)',
    },
    libelle: 'Sciences, Techniques (astronomie, biologie, physique, chimie...)',
    color: '#D1C8B3',
  },
  '3652': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3652',
      libelle: 'Média, Télévision, Presse, Radio, Edition, Internet',
    },
    libelle: 'Média, Télévision, Presse, Radio, Edition, Internet',
    color: '#D1C8B3',
  },
  '3653': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3653',
      libelle: 'Sport',
    },
    libelle: 'Sport',
    color: '#D1C8B3',
  },
  '3654': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3654',
      libelle: 'Musique',
    },
    libelle: 'Musique',
    color: '#D1C8B3',
  },
  '3655': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3655',
      libelle: 'Cinéma',
    },
    libelle: 'Cinéma',
    color: '#D1C8B3',
  },
  '4318': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3655',
      libelle: 'Cinéma',
    },
    '4': {
      id: '4318',
      libelle: 'Scénario de film',
    },
    libelle: 'Scénario de film',
    color: '#D1C8B3',
  },
  '3656': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3656',
      libelle: 'Théâtre',
    },
    libelle: 'Théâtre',
    color: '#D1C8B3',
  },
  '3657': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3657',
      libelle: 'Spectacle, Danse',
    },
    libelle: 'Spectacle, Danse',
    color: '#D1C8B3',
  },
  '3658': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3644',
      libelle: 'Actualités, Reportages',
    },
    '3': {
      id: '3658',
      libelle: 'Politique',
    },
    libelle: 'Politique',
    color: '#D1C8B3',
  },
  '3659': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3659',
      libelle: 'LGBT (lesbien, gay, bisexuel et transgenre)',
    },
    libelle: 'LGBT (lesbien, gay, bisexuel et transgenre)',
    color: '#D1C8B3',
  },
  '3660': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    libelle: 'Biographies, Mémoires',
    color: '#D1C8B3',
  },
  '3661': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    '3': {
      id: '3661',
      libelle: 'Autobiographies',
    },
    libelle: 'Autobiographies',
    color: '#D1C8B3',
  },
  '3662': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    '3': {
      id: '3662',
      libelle: 'Biographies',
    },
    libelle: 'Biographies',
    color: '#D1C8B3',
  },
  '3663': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    '3': {
      id: '3663',
      libelle: 'Journal intime',
    },
    libelle: 'Journal intime',
    color: '#D1C8B3',
  },
  '3664': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    '3': {
      id: '3664',
      libelle: 'Mémoires',
    },
    libelle: 'Mémoires',
    color: '#D1C8B3',
  },
  '3665': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '3660',
      libelle: 'Biographies, Mémoires',
    },
    '3': {
      id: '3665',
      libelle: 'Témoignages',
    },
    libelle: 'Témoignages',
    color: '#D1C8B3',
  },
  '4320': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '4320',
      libelle: 'Conversations, dialogues, entretiens',
    },
    libelle: 'Conversations, dialogues, entretiens',
    color: '#D1C8B3',
  },
  '4319': {
    '1': {
      id: '3435',
      libelle: 'LITTÉRATURE GÉNÉRALE',
    },
    '2': {
      id: '4319',
      libelle: 'Pastiche, parodie, satire',
    },
    libelle: 'Pastiche, parodie, satire',
    color: '#D1C8B3',
  },
  '3667': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    libelle: 'ARTS ET BEAUX LIVRES',
    color: '#7ADAA9',
  },
  '3668': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    libelle: "Histoire de l'art, études",
    color: '#7ADAA9',
  },
  '3669': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3669',
      libelle: "Histoire de l'art",
    },
    libelle: "Histoire de l'art",
    color: '#7ADAA9',
  },
  '3670': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3670',
      libelle: "Ecrits sur l'art",
    },
    libelle: "Ecrits sur l'art",
    color: '#7ADAA9',
  },
  '3671': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3671',
      libelle: 'Essais biographiques',
    },
    libelle: 'Essais biographiques',
    color: '#7ADAA9',
  },
  '3672': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3672',
      libelle: "Dictionnaires d'histoire de l'art",
    },
    libelle: "Dictionnaires d'histoire de l'art",
    color: '#7ADAA9',
  },
  '3673': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3673',
      libelle: 'Musées, muséologie',
    },
    libelle: 'Musées, muséologie',
    color: '#7ADAA9',
  },
  '3674': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3674',
      libelle: "Marché de l'art",
    },
    libelle: "Marché de l'art",
    color: '#7ADAA9',
  },
  '3675': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3668',
      libelle: "Histoire de l'art, études",
    },
    '3': {
      id: '3675',
      libelle: "Revues sur l'art",
    },
    libelle: "Revues sur l'art",
    color: '#7ADAA9',
  },
  '3677': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3677',
      libelle: 'Architecture, urbanisme',
    },
    libelle: 'Architecture, urbanisme',
    color: '#7ADAA9',
  },
  '3678': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3678',
      libelle: 'Sculpture',
    },
    libelle: 'Sculpture',
    color: '#7ADAA9',
  },
  '3679': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3679',
      libelle: 'Peinture',
    },
    libelle: 'Peinture',
    color: '#7ADAA9',
  },
  '3680': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3679',
      libelle: 'Peinture',
    },
    '3': {
      id: '3680',
      libelle: 'Peintres, Monographie',
    },
    libelle: 'Peintres, Monographie',
    color: '#7ADAA9',
  },
  '3681': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3679',
      libelle: 'Peinture',
    },
    '3': {
      id: '3681',
      libelle: 'Histoire de la peinture',
    },
    libelle: 'Histoire de la peinture',
    color: '#7ADAA9',
  },
  '3682': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3682',
      libelle:
        'Arts graphiques (dessin, estampes, fresques, graffiti, gravures...)',
    },
    libelle:
      'Arts graphiques (dessin, estampes, fresques, graffiti, gravures...)',
    color: '#7ADAA9',
  },
  '3683': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3682',
      libelle:
        'Arts graphiques (dessin, estampes, fresques, graffiti, gravures...)',
    },
    '3': {
      id: '3683',
      libelle: 'Dessin',
    },
    libelle: 'Dessin',
    color: '#7ADAA9',
  },
  '3684': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3682',
      libelle:
        'Arts graphiques (dessin, estampes, fresques, graffiti, gravures...)',
    },
    '3': {
      id: '3684',
      libelle: 'Typographie, Calligraphie',
    },
    libelle: 'Typographie, Calligraphie',
    color: '#7ADAA9',
  },
  '3685': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3682',
      libelle:
        'Arts graphiques (dessin, estampes, fresques, graffiti, gravures...)',
    },
    '3': {
      id: '3685',
      libelle: 'Estampes',
    },
    libelle: 'Estampes',
    color: '#7ADAA9',
  },
  '3686': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3686',
      libelle: 'Arts du spectacle',
    },
    libelle: 'Arts du spectacle',
    color: '#7ADAA9',
  },
  '3687': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    libelle: 'Musique',
    color: '#7ADAA9',
  },
  '3934': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3934',
      libelle: 'Dictionnaire et guide de la musique',
    },
    libelle: 'Dictionnaire et guide de la musique',
    color: '#7ADAA9',
  },
  '3935': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3935',
      libelle: 'Méthode, formation musicale',
    },
    libelle: 'Méthode, formation musicale',
    color: '#7ADAA9',
  },
  '3936': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3936',
      libelle: 'Histoire de la musique',
    },
    libelle: 'Histoire de la musique',
    color: '#7ADAA9',
  },
  '3937': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3937',
      libelle: 'Instruments généralités',
    },
    libelle: 'Instruments généralités',
    color: '#7ADAA9',
  },
  '4056': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4056',
      libelle: 'Instruments à clavier',
    },
    libelle: 'Instruments à clavier',
    color: '#7ADAA9',
  },
  '4057': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4057',
      libelle: 'Instruments à percussions',
    },
    libelle: 'Instruments à percussions',
    color: '#7ADAA9',
  },
  '4058': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4058',
      libelle: 'Instruments à cordes',
    },
    libelle: 'Instruments à cordes',
    color: '#7ADAA9',
  },
  '4059': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4059',
      libelle: 'Instruments à vent',
    },
    libelle: 'Instruments à vent',
    color: '#7ADAA9',
  },
  '4060': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4060',
      libelle: 'Instruments mécaniques',
    },
    libelle: 'Instruments mécaniques',
    color: '#7ADAA9',
  },
  '4061': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '4061',
      libelle: 'Instruments électroniques',
    },
    libelle: 'Instruments électroniques',
    color: '#7ADAA9',
  },
  '3938': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3938',
      libelle: 'Musique classique',
    },
    libelle: 'Musique classique',
    color: '#7ADAA9',
  },
  '3932': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3932',
      libelle: 'Chanson, variété française',
    },
    libelle: 'Chanson, variété française',
    color: '#7ADAA9',
  },
  '3939': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3939',
      libelle: 'Chanson, variété internationale',
    },
    libelle: 'Chanson, variété internationale',
    color: '#7ADAA9',
  },
  '3940': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3940',
      libelle: 'Pop',
    },
    libelle: 'Pop',
    color: '#7ADAA9',
  },
  '3948': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3948',
      libelle: 'Rock',
    },
    libelle: 'Rock',
    color: '#7ADAA9',
  },
  '3941': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3941',
      libelle: 'Reggae, dub',
    },
    libelle: 'Reggae, dub',
    color: '#7ADAA9',
  },
  '3942': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3942',
      libelle: 'Electronique',
    },
    libelle: 'Electronique',
    color: '#7ADAA9',
  },
  '3933': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3933',
      libelle: 'Blues, jazz, soul',
    },
    libelle: 'Blues, jazz, soul',
    color: '#7ADAA9',
  },
  '3943': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3943',
      libelle: 'Country, folk',
    },
    libelle: 'Country, folk',
    color: '#7ADAA9',
  },
  '3944': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3944',
      libelle: 'Hip hop, rap',
    },
    libelle: 'Hip hop, rap',
    color: '#7ADAA9',
  },
  '3945': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3945',
      libelle: 'Opéra',
    },
    libelle: 'Opéra',
    color: '#7ADAA9',
  },
  '4325': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3945',
      libelle: 'Opéra',
    },
    '4': {
      id: '4325',
      libelle: "Livret d'opéra",
    },
    libelle: "Livret d'opéra",
    color: '#7ADAA9',
  },
  '3946': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3946',
      libelle: 'Musique de film, comédie musicale',
    },
    libelle: 'Musique de film, comédie musicale',
    color: '#7ADAA9',
  },
  '3947': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3687',
      libelle: 'Musique',
    },
    '3': {
      id: '3947',
      libelle: 'Musique du monde',
    },
    libelle: 'Musique du monde',
    color: '#7ADAA9',
  },
  '3688': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3688',
      libelle: 'Poésie',
    },
    libelle: 'Poésie',
    color: '#7ADAA9',
  },
  '3689': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3689',
      libelle: 'Cinéma',
    },
    libelle: 'Cinéma',
    color: '#7ADAA9',
  },
  '3690': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3690',
      libelle: 'Théâtre',
    },
    libelle: 'Théâtre',
    color: '#7ADAA9',
  },
  '3691': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    libelle: 'Photographie',
    color: '#7ADAA9',
  },
  '3692': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    '3': {
      id: '3692',
      libelle: 'Essai sur la photographie',
    },
    libelle: 'Essai sur la photographie',
    color: '#7ADAA9',
  },
  '3693': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    '3': {
      id: '3693',
      libelle: 'Histoire de la photographie',
    },
    libelle: 'Histoire de la photographie',
    color: '#7ADAA9',
  },
  '3694': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    '3': {
      id: '3694',
      libelle: 'Monographies',
    },
    libelle: 'Monographies',
    color: '#7ADAA9',
  },
  '3695': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    '3': {
      id: '3695',
      libelle: 'Techniques photographiques',
    },
    libelle: 'Techniques photographiques',
    color: '#7ADAA9',
  },
  '3696': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3691',
      libelle: 'Photographie',
    },
    '3': {
      id: '3696',
      libelle: 'Vidéo',
    },
    libelle: 'Vidéo',
    color: '#7ADAA9',
  },
  '3697': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    libelle: 'Arts décoratifs',
    color: '#7ADAA9',
  },
  '3698': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3698',
      libelle: 'Tapisseries, Tapis et Brocarts',
    },
    libelle: 'Tapisseries, Tapis et Brocarts',
    color: '#7ADAA9',
  },
  '3699': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3699',
      libelle: 'Métal ciselé, Ferronnerie',
    },
    libelle: 'Métal ciselé, Ferronnerie',
    color: '#7ADAA9',
  },
  '3700': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3700',
      libelle: 'Pierres fines et précieuses',
    },
    libelle: 'Pierres fines et précieuses',
    color: '#7ADAA9',
  },
  '3701': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3701',
      libelle: 'Email',
    },
    libelle: 'Email',
    color: '#7ADAA9',
  },
  '3702': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3702',
      libelle: 'Sculpture (bois, ivoire, jade...)',
    },
    libelle: 'Sculpture (bois, ivoire, jade...)',
    color: '#7ADAA9',
  },
  '3703': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3703',
      libelle: 'Travail du verre et du cristal',
    },
    libelle: 'Travail du verre et du cristal',
    color: '#7ADAA9',
  },
  '3704': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3704',
      libelle: 'Marqueterie',
    },
    libelle: 'Marqueterie',
    color: '#7ADAA9',
  },
  '3705': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3705',
      libelle: 'Céramique',
    },
    libelle: 'Céramique',
    color: '#7ADAA9',
  },
  '3706': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3706',
      libelle: 'Enluminure et miniatures',
    },
    libelle: 'Enluminure et miniatures',
    color: '#7ADAA9',
  },
  '3707': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3707',
      libelle: 'Mosaïque',
    },
    libelle: 'Mosaïque',
    color: '#7ADAA9',
  },
  '3708': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3708',
      libelle: 'Art floral',
    },
    libelle: 'Art floral',
    color: '#7ADAA9',
  },
  '3709': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3697',
      libelle: 'Arts décoratifs',
    },
    '3': {
      id: '3709',
      libelle: 'Design',
    },
    libelle: 'Design',
    color: '#7ADAA9',
  },
  '4055': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '4055',
      libelle: "Catalogues d'expositions",
    },
    libelle: "Catalogues d'expositions",
    color: '#7ADAA9',
  },
  '3710': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    color: '#7ADAA9',
  },
  '3711': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3711',
      libelle: 'Histoire, Civilisation, Société',
    },
    libelle: 'Histoire, Civilisation, Société',
    color: '#7ADAA9',
  },
  '4131': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '4131',
      libelle: 'Sciences',
    },
    libelle: 'Sciences',
    color: '#7ADAA9',
  },
  '3712': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3712',
      libelle: 'Astronomie, Espace',
    },
    libelle: 'Astronomie, Espace',
    color: '#7ADAA9',
  },
  '3713': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3713',
      libelle: 'Nature, Animaux',
    },
    libelle: 'Nature, Animaux',
    color: '#7ADAA9',
  },
  '3714': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3714',
      libelle: 'Transports (bateau, train, avion, auto, moto)',
    },
    libelle: 'Transports (bateau, train, avion, auto, moto)',
    color: '#7ADAA9',
  },
  '3715': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3715',
      libelle: 'Mode, textile, parfums, bijoux, joaillerie, montres',
    },
    libelle: 'Mode, textile, parfums, bijoux, joaillerie, montres',
    color: '#7ADAA9',
  },
  '3716': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3716',
      libelle: 'Nu, Charme, Érotisme',
    },
    libelle: 'Nu, Charme, Érotisme',
    color: '#7ADAA9',
  },
  '3717': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3717',
      libelle: 'Jardins et plantes',
    },
    libelle: 'Jardins et plantes',
    color: '#7ADAA9',
  },
  '3718': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3718',
      libelle: 'Lieux, paysage, pays, voyages',
    },
    libelle: 'Lieux, paysage, pays, voyages',
    color: '#7ADAA9',
  },
  '3719': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3719',
      libelle: 'Littérature, poésie, manuscrit',
    },
    libelle: 'Littérature, poésie, manuscrit',
    color: '#7ADAA9',
  },
  '3720': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3720',
      libelle: 'Sports',
    },
    libelle: 'Sports',
    color: '#7ADAA9',
  },
  '3721': {
    '1': {
      id: '3667',
      libelle: 'ARTS ET BEAUX LIVRES',
    },
    '2': {
      id: '3710',
      libelle: 'Beaux livres illustrés (histoire, nature, mode, transports...)',
    },
    '3': {
      id: '3721',
      libelle: 'Gastronomie',
    },
    libelle: 'Gastronomie',
    color: '#7ADAA9',
  },
  '3722': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    libelle: 'JEUNESSE',
    color: '#F2D60F',
  },
  '3723': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3723',
      libelle: 'Éveil, petite enfance (- de 3 ans)',
    },
    libelle: 'Éveil, petite enfance (- de 3 ans)',
    color: '#F2D60F',
  },
  '3724': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3723',
      libelle: 'Éveil, petite enfance (- de 3 ans)',
    },
    '3': {
      id: '3724',
      libelle: 'Tout-carton, imagier',
    },
    libelle: 'Tout-carton, imagier',
    color: '#F2D60F',
  },
  '3725': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3723',
      libelle: 'Éveil, petite enfance (- de 3 ans)',
    },
    '3': {
      id: '3725',
      libelle: 'Albums',
    },
    libelle: 'Albums',
    color: '#F2D60F',
  },
  '3726': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3723',
      libelle: 'Éveil, petite enfance (- de 3 ans)',
    },
    '3': {
      id: '3726',
      libelle: 'Albums animés, pop-up',
    },
    libelle: 'Albums animés, pop-up',
    color: '#F2D60F',
  },
  '3727': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3723',
      libelle: 'Éveil, petite enfance (- de 3 ans)',
    },
    '3': {
      id: '3727',
      libelle:
        'Livres objets, livre jouet, livre puzzle, livre surprise, livre matière',
    },
    libelle:
      'Livres objets, livre jouet, livre puzzle, livre surprise, livre matière',
    color: '#F2D60F',
  },
  '3728': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    libelle: 'Livres illustrés (+ de 3 ans)',
    color: '#F2D60F',
  },
  '3729': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    '3': {
      id: '3729',
      libelle: 'Premières histoires',
    },
    libelle: 'Premières histoires',
    color: '#F2D60F',
  },
  '3730': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    '3': {
      id: '3730',
      libelle: 'Albums',
    },
    libelle: 'Albums',
    color: '#F2D60F',
  },
  '3731': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    '3': {
      id: '3731',
      libelle: 'Albums animés, pop-up',
    },
    libelle: 'Albums animés, pop-up',
    color: '#F2D60F',
  },
  '3732': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    '3': {
      id: '3732',
      libelle: 'Livres objets, livre jouet, livre puzzle, livre surprise',
    },
    libelle: 'Livres objets, livre jouet, livre puzzle, livre surprise',
    color: '#F2D60F',
  },
  '3733': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3728',
      libelle: 'Livres illustrés (+ de 3 ans)',
    },
    '3': {
      id: '3733',
      libelle: 'Recueils (contes, fables...)',
    },
    libelle: 'Recueils (contes, fables...)',
    color: '#F2D60F',
  },
  '3734': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3734',
      libelle: "Livres d'activités",
    },
    libelle: "Livres d'activités",
    color: '#F2D60F',
  },
  '3735': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3734',
      libelle: "Livres d'activités",
    },
    '3': {
      id: '3735',
      libelle: 'Activités manuelles',
    },
    libelle: 'Activités manuelles',
    color: '#F2D60F',
  },
  '3736': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3734',
      libelle: "Livres d'activités",
    },
    '3': {
      id: '3736',
      libelle: 'Activités artistiques',
    },
    libelle: 'Activités artistiques',
    color: '#F2D60F',
  },
  '3737': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3734',
      libelle: "Livres d'activités",
    },
    '3': {
      id: '3737',
      libelle: "Activités d'extérieur",
    },
    libelle: "Activités d'extérieur",
    color: '#F2D60F',
  },
  '3738': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    libelle: 'Livres et produits à usage unique',
    color: '#F2D60F',
  },
  '3739': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    '3': {
      id: '3739',
      libelle: 'Autocollants, gommettes, collage',
    },
    libelle: 'Autocollants, gommettes, collage',
    color: '#F2D60F',
  },
  '3740': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    '3': {
      id: '3740',
      libelle: 'Coloriage, dessin',
    },
    libelle: 'Coloriage, dessin',
    color: '#F2D60F',
  },
  '3741': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    '3': {
      id: '3741',
      libelle:
        'Images à coller (ayant une existence propre constituant une série, une collection)',
    },
    libelle:
      'Images à coller (ayant une existence propre constituant une série, une collection)',
    color: '#F2D60F',
  },
  '3742': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    '3': {
      id: '3742',
      libelle: 'Tatouage, transfert, magnet',
    },
    libelle: 'Tatouage, transfert, magnet',
    color: '#F2D60F',
  },
  '3743': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3738',
      libelle: 'Livres et produits à usage unique',
    },
    '3': {
      id: '3743',
      libelle:
        "Papeterie (dérivative d'une oeuvre jeunesse. Ex : calendriers, agendas...)",
    },
    libelle:
      "Papeterie (dérivative d'une oeuvre jeunesse. Ex : calendriers, agendas...)",
    color: '#F2D60F',
  },
  '3744': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    libelle: 'Fiction Jeunesse',
    color: '#F2D60F',
  },
  '3745': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    '3': {
      id: '3745',
      libelle: 'Premières lectures, premiers romans',
    },
    libelle: 'Premières lectures, premiers romans',
    color: '#F2D60F',
  },
  '3746': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    '3': {
      id: '3746',
      libelle: 'Histoires',
    },
    libelle: 'Histoires',
    color: '#F2D60F',
  },
  '3747': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    '3': {
      id: '3747',
      libelle: 'Séries, héros préférés',
    },
    libelle: 'Séries, héros préférés',
    color: '#F2D60F',
  },
  '3748': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    '3': {
      id: '3748',
      libelle: 'Contes et mythologie',
    },
    libelle: 'Contes et mythologie',
    color: '#F2D60F',
  },
  '3749': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3744',
      libelle: 'Fiction Jeunesse',
    },
    '3': {
      id: '3749',
      libelle: 'Romans',
    },
    libelle: 'Romans',
    color: '#F2D60F',
  },
  '3750': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    libelle: 'Fiction adolescents',
    color: '#F2D60F',
  },
  '3751': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    libelle: 'Romans',
    color: '#F2D60F',
  },
  '3752': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3752',
      libelle: 'Fantastique, Paranormal',
    },
    libelle: 'Fantastique, Paranormal',
    color: '#F2D60F',
  },
  '3753': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3753',
      libelle: 'Magie, Fantasy',
    },
    libelle: 'Magie, Fantasy',
    color: '#F2D60F',
  },
  '3754': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3754',
      libelle: 'Science-fiction',
    },
    libelle: 'Science-fiction',
    color: '#F2D60F',
  },
  '3755': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3755',
      libelle: 'Sentimental',
    },
    libelle: 'Sentimental',
    color: '#F2D60F',
  },
  '4156': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '4156',
      libelle: 'Chick lit',
    },
    libelle: 'Chick lit',
    color: '#F2D60F',
  },
  '3756': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3756',
      libelle: 'Policier, thriller',
    },
    libelle: 'Policier, thriller',
    color: '#F2D60F',
  },
  '4074': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '4074',
      libelle: 'Epouvante',
    },
    libelle: 'Epouvante',
    color: '#F2D60F',
  },
  '3757': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3757',
      libelle: 'Historique',
    },
    libelle: 'Historique',
    color: '#F2D60F',
  },
  '3758': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3751',
      libelle: 'Romans',
    },
    '4': {
      id: '3758',
      libelle: 'Action, aventures',
    },
    libelle: 'Action, aventures',
    color: '#F2D60F',
  },
  '3759': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3750',
      libelle: 'Fiction adolescents',
    },
    '3': {
      id: '3759',
      libelle: 'Témoignages',
    },
    libelle: 'Témoignages',
    color: '#F2D60F',
  },
  '3760': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    libelle: 'Documentaire / Encyclopédie',
    color: '#F2D60F',
  },
  '3761': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3761',
      libelle: 'Animaux',
    },
    libelle: 'Animaux',
    color: '#F2D60F',
  },
  '3762': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3762',
      libelle: 'Arts',
    },
    libelle: 'Arts',
    color: '#F2D60F',
  },
  '3763': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3763',
      libelle: 'Atlas',
    },
    libelle: 'Atlas',
    color: '#F2D60F',
  },
  '3764': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3764',
      libelle: 'Encyclopédie',
    },
    libelle: 'Encyclopédie',
    color: '#F2D60F',
  },
  '3765': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3765',
      libelle: 'Géographie',
    },
    libelle: 'Géographie',
    color: '#F2D60F',
  },
  '3766': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3766',
      libelle: 'Histoire, Monde et civilisations',
    },
    libelle: 'Histoire, Monde et civilisations',
    color: '#F2D60F',
  },
  '3767': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3767',
      libelle: 'Nature, environnement',
    },
    libelle: 'Nature, environnement',
    color: '#F2D60F',
  },
  '3768': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3768',
      libelle: 'Sciences',
    },
    libelle: 'Sciences',
    color: '#F2D60F',
  },
  '3769': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3769',
      libelle: 'Sport',
    },
    libelle: 'Sport',
    color: '#F2D60F',
  },
  '3770': {
    '1': {
      id: '3722',
      libelle: 'JEUNESSE',
    },
    '2': {
      id: '3760',
      libelle: 'Documentaire / Encyclopédie',
    },
    '3': {
      id: '3770',
      libelle: 'Vie quotidienne et société',
    },
    libelle: 'Vie quotidienne et société',
    color: '#F2D60F',
  },
  '3771': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    color: '#F97E60',
  },
  '3772': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    libelle: 'Bandes dessinées',
    color: '#F97E60',
  },
  '3773': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3773',
      libelle: 'Bandes dessinées tout public',
    },
    libelle: 'Bandes dessinées tout public',
    color: '#F97E60',
  },
  '3774': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    libelle: 'Bandes dessinées de genre',
    color: '#F97E60',
  },
  '3776': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3776',
      libelle: 'Science-fiction',
    },
    libelle: 'Science-fiction',
    color: '#F97E60',
  },
  '3777': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3777',
      libelle: 'Fantasy',
    },
    libelle: 'Fantasy',
    color: '#F97E60',
  },
  '3778': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3778',
      libelle: 'Fantastique, ésotérisme',
    },
    libelle: 'Fantastique, ésotérisme',
    color: '#F97E60',
  },
  '3779': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3779',
      libelle: 'Policier thriller',
    },
    libelle: 'Policier thriller',
    color: '#F97E60',
  },
  '4065': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '4065',
      libelle: 'Horreur',
    },
    libelle: 'Horreur',
    color: '#F97E60',
  },
  '3780': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3780',
      libelle: 'Histoire',
    },
    libelle: 'Histoire',
    color: '#F97E60',
  },
  '3781': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3781',
      libelle: 'Action et aventures',
    },
    libelle: 'Action et aventures',
    color: '#F97E60',
  },
  '3782': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3782',
      libelle: 'Western',
    },
    libelle: 'Western',
    color: '#F97E60',
  },
  '3783': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3783',
      libelle: 'Humour',
    },
    libelle: 'Humour',
    color: '#F97E60',
  },
  '3784': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3784',
      libelle: "Bandes dessinées d'auteur",
    },
    libelle: "Bandes dessinées d'auteur",
    color: '#F97E60',
  },
  '3785': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3774',
      libelle: 'Bandes dessinées de genre',
    },
    '4': {
      id: '3785',
      libelle: 'Public averti (érotique, hyper violence...)',
    },
    libelle: 'Public averti (érotique, hyper violence...)',
    color: '#F97E60',
  },
  '3775': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    libelle: 'Documentaire',
    color: '#F97E60',
  },
  '4132': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    '4': {
      id: '4132',
      libelle: 'Culture',
    },
    libelle: 'Culture',
    color: '#F97E60',
  },
  '4133': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    '4': {
      id: '4133',
      libelle: 'Société',
    },
    libelle: 'Société',
    color: '#F97E60',
  },
  '4134': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    '4': {
      id: '4134',
      libelle: 'Littérature',
    },
    libelle: 'Littérature',
    color: '#F97E60',
  },
  '4135': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    '4': {
      id: '4135',
      libelle: 'Adaptation littéraire',
    },
    libelle: 'Adaptation littéraire',
    color: '#F97E60',
  },
  '4136': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '3775',
      libelle: 'Documentaire',
    },
    '4': {
      id: '4136',
      libelle: 'Bande dessinée de savoir',
    },
    libelle: 'Bande dessinée de savoir',
    color: '#F97E60',
  },
  '4076': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '4076',
      libelle: "Bandes dessinées d'auteur",
    },
    libelle: "Bandes dessinées d'auteur",
    color: '#F97E60',
  },
  '4077': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '4077',
      libelle: 'Tirage de tête',
    },
    libelle: 'Tirage de tête',
    color: '#F97E60',
  },
  '4075': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3772',
      libelle: 'Bandes dessinées',
    },
    '3': {
      id: '4075',
      libelle: 'Guides et critiques',
    },
    libelle: 'Guides et critiques',
    color: '#F97E60',
  },
  '3786': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    libelle: 'Comics',
    color: '#F97E60',
  },
  '3787': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    '3': {
      id: '3787',
      libelle: 'Classique',
    },
    libelle: 'Classique',
    color: '#F97E60',
  },
  '3788': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    '3': {
      id: '3788',
      libelle: 'Strip',
    },
    libelle: 'Strip',
    color: '#F97E60',
  },
  '3789': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    '3': {
      id: '3789',
      libelle: 'Super Héros',
    },
    libelle: 'Super Héros',
    color: '#F97E60',
  },
  '3790': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    '3': {
      id: '3790',
      libelle: 'Adaptation',
    },
    libelle: 'Adaptation',
    color: '#F97E60',
  },
  '3791': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3786',
      libelle: 'Comics',
    },
    '3': {
      id: '3791',
      libelle: 'Graphic Novel',
    },
    libelle: 'Graphic Novel',
    color: '#F97E60',
  },
  '3792': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    libelle: 'Manga',
    color: '#F97E60',
  },
  '3793': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3793',
      libelle: 'Kodomo',
    },
    libelle: 'Kodomo',
    color: '#F97E60',
  },
  '3794': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3794',
      libelle: 'Shôjo',
    },
    libelle: 'Shôjo',
    color: '#F97E60',
  },
  '3795': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3795',
      libelle: 'Shonen',
    },
    libelle: 'Shonen',
    color: '#F97E60',
  },
  '3796': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3796',
      libelle: 'Seinen',
    },
    libelle: 'Seinen',
    color: '#F97E60',
  },
  '3797': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3797',
      libelle: 'Josei',
    },
    libelle: 'Josei',
    color: '#F97E60',
  },
  '4062': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '4062',
      libelle: 'Manga Yaoi',
    },
    libelle: 'Manga Yaoi',
    color: '#F97E60',
  },
  '4063': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '4063',
      libelle: 'Manga Yuri',
    },
    libelle: 'Manga Yuri',
    color: '#F97E60',
  },
  '3798': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3798',
      libelle: 'Public averti (érotique, hyper violence...)',
    },
    libelle: 'Public averti (érotique, hyper violence...)',
    color: '#F97E60',
  },
  '3799': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3799',
      libelle: 'Revues mangas',
    },
    libelle: 'Revues mangas',
    color: '#F97E60',
  },
  '3800': {
    '1': {
      id: '3771',
      libelle: 'BANDES DESSINÉES, COMICS, MANGAS',
    },
    '2': {
      id: '3792',
      libelle: 'Manga',
    },
    '3': {
      id: '3800',
      libelle: 'Guides mangas',
    },
    libelle: 'Guides mangas',
    color: '#F97E60',
  },
  '3802': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    libelle: 'PRATIQUE',
    color: '#92E4D1',
  },
  '3803': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    libelle: 'Tourisme, Guides et Monographies',
    color: '#92E4D1',
  },
  '3804': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    libelle: 'Guides de tourisme (destination)',
    color: '#92E4D1',
  },
  '3805': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3805',
      libelle: "Guide d'Europe",
    },
    libelle: "Guide d'Europe",
    color: '#92E4D1',
  },
  '3806': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3806',
      libelle: "Guide d'Afrique",
    },
    libelle: "Guide d'Afrique",
    color: '#92E4D1',
  },
  '3807': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3807',
      libelle: 'Guide du Moyen Orient',
    },
    libelle: 'Guide du Moyen Orient',
    color: '#92E4D1',
  },
  '3808': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3808',
      libelle: "Guide d'Asie",
    },
    libelle: "Guide d'Asie",
    color: '#92E4D1',
  },
  '3809': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3809',
      libelle: "Guide d'Amérique du Nord",
    },
    libelle: "Guide d'Amérique du Nord",
    color: '#92E4D1',
  },
  '3810': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3810',
      libelle: "Guide d'Amérique Centrale",
    },
    libelle: "Guide d'Amérique Centrale",
    color: '#92E4D1',
  },
  '3811': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3811',
      libelle: "Guide d'Amérique du Sud",
    },
    libelle: "Guide d'Amérique du Sud",
    color: '#92E4D1',
  },
  '3812': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3812',
      libelle: "Guide d'Océanie",
    },
    libelle: "Guide d'Océanie",
    color: '#92E4D1',
  },
  '3813': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3804',
      libelle: 'Guides de tourisme (destination)',
    },
    '4': {
      id: '3813',
      libelle: 'Guides France',
    },
    libelle: 'Guides France',
    color: '#92E4D1',
  },
  '3814': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3814',
      libelle: 'Guides de conversation',
    },
    libelle: 'Guides de conversation',
    color: '#92E4D1',
  },
  '3815': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3815',
      libelle: 'Guides spécialisés',
    },
    libelle: 'Guides spécialisés',
    color: '#92E4D1',
  },
  '3816': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3815',
      libelle: 'Guides spécialisés',
    },
    '4': {
      id: '3816',
      libelle: 'Guides hébergement et restauration',
    },
    libelle: 'Guides hébergement et restauration',
    color: '#92E4D1',
  },
  '3817': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3815',
      libelle: 'Guides spécialisés',
    },
    '4': {
      id: '3817',
      libelle: 'Guides camping Caravaning',
    },
    libelle: 'Guides camping Caravaning',
    color: '#92E4D1',
  },
  '3818': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3815',
      libelle: 'Guides spécialisés',
    },
    '4': {
      id: '3818',
      libelle: 'Guides de randonnées',
    },
    libelle: 'Guides de randonnées',
    color: '#92E4D1',
  },
  '3819': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3815',
      libelle: 'Guides spécialisés',
    },
    '4': {
      id: '3819',
      libelle: 'Guides fluviaux ou maritimes',
    },
    libelle: 'Guides fluviaux ou maritimes',
    color: '#92E4D1',
  },
  '3820': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3803',
      libelle: 'Tourisme, Guides et Monographies',
    },
    '3': {
      id: '3820',
      libelle:
        'Albums et beaux livres et carnets de voyages (paysages, pays, villes, lieux, voyages)',
    },
    libelle:
      'Albums et beaux livres et carnets de voyages (paysages, pays, villes, lieux, voyages)',
    color: '#92E4D1',
  },
  '3821': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3821',
      libelle: 'Méthodes de langues (hors scolaire)',
    },
    libelle: 'Méthodes de langues (hors scolaire)',
    color: '#92E4D1',
  },
  '3822': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3822',
      libelle: 'Vie professionnelle',
    },
    libelle: 'Vie professionnelle',
    color: '#92E4D1',
  },
  '3823': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3822',
      libelle: 'Vie professionnelle',
    },
    '3': {
      id: '3823',
      libelle: 'Efficacité professionnelle',
    },
    libelle: 'Efficacité professionnelle',
    color: '#92E4D1',
  },
  '3824': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3822',
      libelle: 'Vie professionnelle',
    },
    '3': {
      id: '3824',
      libelle: "Recherche d'emploi",
    },
    libelle: "Recherche d'emploi",
    color: '#92E4D1',
  },
  '3825': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    libelle:
      'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    color: '#92E4D1',
  },
  '3826': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3826',
      libelle: 'Régimes alimentaires',
    },
    libelle: 'Régimes alimentaires',
    color: '#92E4D1',
  },
  '3827': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3827',
      libelle: 'Médecine naturelle, douce, familiale',
    },
    libelle: 'Médecine naturelle, douce, familiale',
    color: '#92E4D1',
  },
  '3828': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3828',
      libelle: 'Forme, beauté',
    },
    libelle: 'Forme, beauté',
    color: '#92E4D1',
  },
  '3829': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3829',
      libelle: 'Sexualité',
    },
    libelle: 'Sexualité',
    color: '#92E4D1',
  },
  '3830': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3830',
      libelle: 'Bien-être',
    },
    libelle: 'Bien-être',
    color: '#92E4D1',
  },
  '3831': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '3831',
      libelle: 'Développement personnel',
    },
    libelle: 'Développement personnel',
    color: '#92E4D1',
  },
  '4047': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3825',
      libelle:
        'Santé et Bien-être (santé physique et mentale, hygiène, sexualité, psychologie, pédagogie, enfants)',
    },
    '3': {
      id: '4047',
      libelle: 'Vieillissement',
    },
    libelle: 'Vieillissement',
    color: '#92E4D1',
  },
  '3832': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    libelle: 'Vie quotidienne, Vie de la famille',
    color: '#92E4D1',
  },
  '3833': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3833',
      libelle: 'Enfance',
    },
    libelle: 'Enfance',
    color: '#92E4D1',
  },
  '3834': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3834',
      libelle: 'Seniors',
    },
    libelle: 'Seniors',
    color: '#92E4D1',
  },
  '3835': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3835',
      libelle: 'Maternité, puériculture',
    },
    libelle: 'Maternité, puériculture',
    color: '#92E4D1',
  },
  '3836': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3836',
      libelle: 'Éducation',
    },
    libelle: 'Éducation',
    color: '#92E4D1',
  },
  '3837': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3837',
      libelle: 'Économie domestique',
    },
    libelle: 'Économie domestique',
    color: '#92E4D1',
  },
  '3838': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '3838',
      libelle: 'Gestion du patrimoine',
    },
    libelle: 'Gestion du patrimoine',
    color: '#92E4D1',
  },
  '4081': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3832',
      libelle: 'Vie quotidienne, Vie de la famille',
    },
    '3': {
      id: '4081',
      libelle: 'Correspondance',
    },
    libelle: 'Correspondance',
    color: '#92E4D1',
  },
  '3839': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3839',
      libelle: 'Activités artistiques',
    },
    libelle: 'Activités artistiques',
    color: '#92E4D1',
  },
  '3840': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3840',
      libelle: 'Activités manuelles',
    },
    libelle: 'Activités manuelles',
    color: '#92E4D1',
  },
  '3841': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3840',
      libelle: 'Activités manuelles',
    },
    '3': {
      id: '3841',
      libelle: 'Loisirs créatifs',
    },
    libelle: 'Loisirs créatifs',
    color: '#92E4D1',
  },
  '3842': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3840',
      libelle: 'Activités manuelles',
    },
    '3': {
      id: '3842',
      libelle: 'Artisanat',
    },
    libelle: 'Artisanat',
    color: '#92E4D1',
  },
  '3843': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3840',
      libelle: 'Activités manuelles',
    },
    '3': {
      id: '3843',
      libelle: 'Art du fil',
    },
    libelle: 'Art du fil',
    color: '#92E4D1',
  },
  '3844': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    libelle: 'Bricolage, décoration, habitat',
    color: '#92E4D1',
  },
  '3845': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    libelle: 'Bricolage',
    color: '#92E4D1',
  },
  '4021': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '4021',
      libelle: 'Manuels de bricolage',
    },
    libelle: 'Manuels de bricolage',
    color: '#92E4D1',
  },
  '3931': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '3931',
      libelle: 'Entretien, restauration de la maison',
    },
    libelle: 'Entretien, restauration de la maison',
    color: '#92E4D1',
  },
  '3926': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '3926',
      libelle: 'Cuisine et bains',
    },
    libelle: 'Cuisine et bains',
    color: '#92E4D1',
  },
  '3925': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '3925',
      libelle: 'Electricité, domotique, sécurité, alarmes',
    },
    libelle: 'Electricité, domotique, sécurité, alarmes',
    color: '#92E4D1',
  },
  '3927': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '3927',
      libelle: 'Plomberie, chauffage, climatisation',
    },
    libelle: 'Plomberie, chauffage, climatisation',
    color: '#92E4D1',
  },
  '4022': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '4022',
      libelle: 'Maçonnerie',
    },
    libelle: 'Maçonnerie',
    color: '#92E4D1',
  },
  '4023': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3845',
      libelle: 'Bricolage',
    },
    '4': {
      id: '4023',
      libelle: 'Menuiserie',
    },
    libelle: 'Menuiserie',
    color: '#92E4D1',
  },
  '3846': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3846',
      libelle: 'Décoration',
    },
    libelle: 'Décoration',
    color: '#92E4D1',
  },
  '3928': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3844',
      libelle: 'Bricolage, décoration, habitat',
    },
    '3': {
      id: '3928',
      libelle: 'Habitat',
    },
    libelle: 'Habitat',
    color: '#92E4D1',
  },
  '3847': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    libelle: 'Cuisine, gastronomie',
    color: '#92E4D1',
  },
  '4080': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '4080',
      libelle: 'Histoire de la cuisine',
    },
    libelle: 'Histoire de la cuisine',
    color: '#92E4D1',
  },
  '3848': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3848',
      libelle: 'Cuisine au quotidien',
    },
    libelle: 'Cuisine au quotidien',
    color: '#92E4D1',
  },
  '3849': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3849',
      libelle: 'Cuisine actuelle et tendances',
    },
    libelle: 'Cuisine actuelle et tendances',
    color: '#92E4D1',
  },
  '3850': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3850',
      libelle: 'Cuisine de saison',
    },
    libelle: 'Cuisine de saison',
    color: '#92E4D1',
  },
  '3851': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3851',
      libelle: 'Cuisine régionale',
    },
    libelle: 'Cuisine régionale',
    color: '#92E4D1',
  },
  '3852': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3852',
      libelle: 'Cuisine du monde',
    },
    libelle: 'Cuisine du monde',
    color: '#92E4D1',
  },
  '3853': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3853',
      libelle: 'Cuisine des chefs',
    },
    libelle: 'Cuisine des chefs',
    color: '#92E4D1',
  },
  '4079': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '4079',
      libelle: 'Cuisine bio',
    },
    libelle: 'Cuisine bio',
    color: '#92E4D1',
  },
  '3854': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3854',
      libelle: 'Cuisine pour les enfants',
    },
    libelle: 'Cuisine pour les enfants',
    color: '#92E4D1',
  },
  '3855': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3855',
      libelle: 'Cuisine santé-minceur',
    },
    libelle: 'Cuisine santé-minceur',
    color: '#92E4D1',
  },
  '3856': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3847',
      libelle: 'Cuisine, gastronomie',
    },
    '3': {
      id: '3856',
      libelle: 'Pâtisserie, confiserie, chocolat',
    },
    libelle: 'Pâtisserie, confiserie, chocolat',
    color: '#92E4D1',
  },
  '3857': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    libelle: 'Boissons',
    color: '#92E4D1',
  },
  '3858': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    '3': {
      id: '3858',
      libelle: 'Vins, champagne',
    },
    libelle: 'Vins, champagne',
    color: '#92E4D1',
  },
  '3859': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    '3': {
      id: '3859',
      libelle: 'Alcools',
    },
    libelle: 'Alcools',
    color: '#92E4D1',
  },
  '3860': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    '3': {
      id: '3860',
      libelle: 'Cocktails',
    },
    libelle: 'Cocktails',
    color: '#92E4D1',
  },
  '3861': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    '3': {
      id: '3861',
      libelle: 'Thé, café, infusions',
    },
    libelle: 'Thé, café, infusions',
    color: '#92E4D1',
  },
  '3862': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3857',
      libelle: 'Boissons',
    },
    '3': {
      id: '3862',
      libelle: 'Boissons non alcoolisées (jus, smoothies, milk-shake...)',
    },
    libelle: 'Boissons non alcoolisées (jus, smoothies, milk-shake...)',
    color: '#92E4D1',
  },
  '3864': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    libelle: 'Sports et loisirs',
    color: '#92E4D1',
  },
  '3920': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    libelle: 'Sports collectifs',
    color: '#92E4D1',
  },
  '3921': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3921',
      libelle: 'Football',
    },
    libelle: 'Football',
    color: '#92E4D1',
  },
  '3949': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3949',
      libelle: 'Rugby',
    },
    libelle: 'Rugby',
    color: '#92E4D1',
  },
  '3950': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3950',
      libelle: 'Hand-ball',
    },
    libelle: 'Hand-ball',
    color: '#92E4D1',
  },
  '3951': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3951',
      libelle: 'Volley-ball',
    },
    libelle: 'Volley-ball',
    color: '#92E4D1',
  },
  '3952': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3952',
      libelle: 'Basket-ball',
    },
    libelle: 'Basket-ball',
    color: '#92E4D1',
  },
  '3953': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3953',
      libelle: 'Base-ball',
    },
    libelle: 'Base-ball',
    color: '#92E4D1',
  },
  '3954': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3954',
      libelle: 'Hockey',
    },
    libelle: 'Hockey',
    color: '#92E4D1',
  },
  '3955': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '3955',
      libelle: 'Polo',
    },
    libelle: 'Polo',
    color: '#92E4D1',
  },
  '4012': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3920',
      libelle: 'Sports collectifs',
    },
    '4': {
      id: '4012',
      libelle: 'Pelote basque',
    },
    libelle: 'Pelote basque',
    color: '#92E4D1',
  },
  '3956': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3956',
      libelle: 'Athlétisme',
    },
    libelle: 'Athlétisme',
    color: '#92E4D1',
  },
  '3957': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3957',
      libelle: 'Gymnastique',
    },
    libelle: 'Gymnastique',
    color: '#92E4D1',
  },
  '3958': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3958',
      libelle: 'Tir sportif',
    },
    libelle: 'Tir sportif',
    color: '#92E4D1',
  },
  '3959': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3958',
      libelle: 'Tir sportif',
    },
    '4': {
      id: '3959',
      libelle: 'Carabine, pistolet',
    },
    libelle: 'Carabine, pistolet',
    color: '#92E4D1',
  },
  '3960': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3958',
      libelle: 'Tir sportif',
    },
    '4': {
      id: '3960',
      libelle: 'Arc, arbalète',
    },
    libelle: 'Arc, arbalète',
    color: '#92E4D1',
  },
  '3961': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3961',
      libelle: 'Sport de combat',
    },
    libelle: 'Sport de combat',
    color: '#92E4D1',
  },
  '3962': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3961',
      libelle: 'Sport de combat',
    },
    '4': {
      id: '3962',
      libelle: 'Boxe',
    },
    libelle: 'Boxe',
    color: '#92E4D1',
  },
  '3963': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3961',
      libelle: 'Sport de combat',
    },
    '4': {
      id: '3963',
      libelle: 'Lutte',
    },
    libelle: 'Lutte',
    color: '#92E4D1',
  },
  '3964': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3961',
      libelle: 'Sport de combat',
    },
    '4': {
      id: '3964',
      libelle: 'Escrime',
    },
    libelle: 'Escrime',
    color: '#92E4D1',
  },
  '3965': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    libelle: 'Art martial',
    color: '#92E4D1',
  },
  '3966': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3966',
      libelle: 'Aïkido',
    },
    libelle: 'Aïkido',
    color: '#92E4D1',
  },
  '3967': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3967',
      libelle: 'Judo',
    },
    libelle: 'Judo',
    color: '#92E4D1',
  },
  '3968': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3968',
      libelle: 'Ju-jitsu',
    },
    libelle: 'Ju-jitsu',
    color: '#92E4D1',
  },
  '3969': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3969',
      libelle: 'Karaté',
    },
    libelle: 'Karaté',
    color: '#92E4D1',
  },
  '3970': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3970',
      libelle: 'Kendo',
    },
    libelle: 'Kendo',
    color: '#92E4D1',
  },
  '3971': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3971',
      libelle: 'Kung-fu',
    },
    libelle: 'Kung-fu',
    color: '#92E4D1',
  },
  '3972': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3972',
      libelle: 'Free fight',
    },
    libelle: 'Free fight',
    color: '#92E4D1',
  },
  '3973': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3965',
      libelle: 'Art martial',
    },
    '4': {
      id: '3973',
      libelle: 'Sumo',
    },
    libelle: 'Sumo',
    color: '#92E4D1',
  },
  '3974': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    libelle: 'Sports mécaniques',
    color: '#92E4D1',
  },
  '3975': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3975',
      libelle: 'Course automobile',
    },
    libelle: 'Course automobile',
    color: '#92E4D1',
  },
  '3976': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3976',
      libelle: 'Rallye automobile',
    },
    libelle: 'Rallye automobile',
    color: '#92E4D1',
  },
  '3977': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3977',
      libelle: 'Karting',
    },
    libelle: 'Karting',
    color: '#92E4D1',
  },
  '3978': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3978',
      libelle: 'Dragster',
    },
    libelle: 'Dragster',
    color: '#92E4D1',
  },
  '3979': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3979',
      libelle: 'Camion',
    },
    libelle: 'Camion',
    color: '#92E4D1',
  },
  '3980': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3980',
      libelle: 'Course de moto',
    },
    libelle: 'Course de moto',
    color: '#92E4D1',
  },
  '3981': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3981',
      libelle: 'Tout terrain moto',
    },
    libelle: 'Tout terrain moto',
    color: '#92E4D1',
  },
  '3982': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3982',
      libelle: 'Sports aériens',
    },
    libelle: 'Sports aériens',
    color: '#92E4D1',
  },
  '3983': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3974',
      libelle: 'Sports mécaniques',
    },
    '4': {
      id: '3983',
      libelle: 'Sport de navigation',
    },
    libelle: 'Sport de navigation',
    color: '#92E4D1',
  },
  '3984': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3984',
      libelle: 'Sports aériens non motorisés',
    },
    libelle: 'Sports aériens non motorisés',
    color: '#92E4D1',
  },
  '3985': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3985',
      libelle: 'Cyclisme, vélo tout terrain (VTT)',
    },
    libelle: 'Cyclisme, vélo tout terrain (VTT)',
    color: '#92E4D1',
  },
  '3986': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3985',
      libelle: 'Cyclisme, vélo tout terrain (VTT)',
    },
    '4': {
      id: '3986',
      libelle: 'Cyclisme',
    },
    libelle: 'Cyclisme',
    color: '#92E4D1',
  },
  '3987': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3985',
      libelle: 'Cyclisme, vélo tout terrain (VTT)',
    },
    '4': {
      id: '3987',
      libelle: 'Cyclo-cross, vélo tout terrain (VTT)',
    },
    libelle: 'Cyclo-cross, vélo tout terrain (VTT)',
    color: '#92E4D1',
  },
  '3988': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3988',
      libelle: 'Sports aquatiques',
    },
    libelle: 'Sports aquatiques',
    color: '#92E4D1',
  },
  '3989': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3988',
      libelle: 'Sports aquatiques',
    },
    '4': {
      id: '3989',
      libelle: 'Natation',
    },
    libelle: 'Natation',
    color: '#92E4D1',
  },
  '3990': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3988',
      libelle: 'Sports aquatiques',
    },
    '4': {
      id: '3990',
      libelle: 'Plongeon',
    },
    libelle: 'Plongeon',
    color: '#92E4D1',
  },
  '3991': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3988',
      libelle: 'Sports aquatiques',
    },
    '4': {
      id: '3991',
      libelle: 'Water-polo',
    },
    libelle: 'Water-polo',
    color: '#92E4D1',
  },
  '3992': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3988',
      libelle: 'Sports aquatiques',
    },
    '4': {
      id: '3992',
      libelle: 'Plongée sous-marine',
    },
    libelle: 'Plongée sous-marine',
    color: '#92E4D1',
  },
  '3993': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    libelle: 'Sports nautiques',
    color: '#92E4D1',
  },
  '3994': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3994',
      libelle: 'Voile, voilier',
    },
    libelle: 'Voile, voilier',
    color: '#92E4D1',
  },
  '3995': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3995',
      libelle: 'Ski nautique',
    },
    libelle: 'Ski nautique',
    color: '#92E4D1',
  },
  '3996': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3996',
      libelle: 'Aviron',
    },
    libelle: 'Aviron',
    color: '#92E4D1',
  },
  '3997': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3997',
      libelle: 'Planche à voile',
    },
    libelle: 'Planche à voile',
    color: '#92E4D1',
  },
  '3998': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3998',
      libelle: 'Surf',
    },
    libelle: 'Surf',
    color: '#92E4D1',
  },
  '3999': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3993',
      libelle: 'Sports nautiques',
    },
    '4': {
      id: '3999',
      libelle: 'Canoë',
    },
    libelle: 'Canoë',
    color: '#92E4D1',
  },
  '4000': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    libelle: "Sports de montagne, sports d'hiver",
    color: '#92E4D1',
  },
  '4001': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4001',
      libelle: 'Ski',
    },
    libelle: 'Ski',
    color: '#92E4D1',
  },
  '4002': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4002',
      libelle: 'Alpinisme, escalade',
    },
    libelle: 'Alpinisme, escalade',
    color: '#92E4D1',
  },
  '4003': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4003',
      libelle: 'Bobsleigh, luge',
    },
    libelle: 'Bobsleigh, luge',
    color: '#92E4D1',
  },
  '4004': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4004',
      libelle: 'Patinage',
    },
    libelle: 'Patinage',
    color: '#92E4D1',
  },
  '4005': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4005',
      libelle: 'Curling',
    },
    libelle: 'Curling',
    color: '#92E4D1',
  },
  '4018': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '4018',
      libelle: 'Hockey sur glace',
    },
    libelle: 'Hockey sur glace',
    color: '#92E4D1',
  },
  '3922': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4000',
      libelle: "Sports de montagne, sports d'hiver",
    },
    '4': {
      id: '3922',
      libelle: 'Spéléologie',
    },
    libelle: 'Spéléologie',
    color: '#92E4D1',
  },
  '4006': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4006',
      libelle: 'Sports équestres',
    },
    libelle: 'Sports équestres',
    color: '#92E4D1',
  },
  '4007': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4007',
      libelle: 'Sports de raquettes',
    },
    libelle: 'Sports de raquettes',
    color: '#92E4D1',
  },
  '4008': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4007',
      libelle: 'Sports de raquettes',
    },
    '4': {
      id: '4008',
      libelle: 'Tennis',
    },
    libelle: 'Tennis',
    color: '#92E4D1',
  },
  '4009': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4007',
      libelle: 'Sports de raquettes',
    },
    '4': {
      id: '4009',
      libelle: 'Tennis de table',
    },
    libelle: 'Tennis de table',
    color: '#92E4D1',
  },
  '4010': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4007',
      libelle: 'Sports de raquettes',
    },
    '4': {
      id: '4010',
      libelle: 'Squash',
    },
    libelle: 'Squash',
    color: '#92E4D1',
  },
  '4011': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4007',
      libelle: 'Sports de raquettes',
    },
    '4': {
      id: '4011',
      libelle: 'Badminton',
    },
    libelle: 'Badminton',
    color: '#92E4D1',
  },
  '4013': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4013',
      libelle: 'Golf',
    },
    libelle: 'Golf',
    color: '#92E4D1',
  },
  '4014': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4014',
      libelle: 'Jeux de boules',
    },
    libelle: 'Jeux de boules',
    color: '#92E4D1',
  },
  '4015': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4015',
      libelle: 'Sports extrêmes',
    },
    libelle: 'Sports extrêmes',
    color: '#92E4D1',
  },
  '4016': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4016',
      libelle: 'Olympisme, para-olympisme',
    },
    libelle: 'Olympisme, para-olympisme',
    color: '#92E4D1',
  },
  '4017': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4017',
      libelle: 'Entraînement sportif',
    },
    libelle: 'Entraînement sportif',
    color: '#92E4D1',
  },
  '3891': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3891',
      libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    },
    libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    color: '#92E4D1',
  },
  '3892': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3891',
      libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    },
    '4': {
      id: '3892',
      libelle: 'Jeux de rôle (guides, univers, etc.)',
    },
    libelle: 'Jeux de rôle (guides, univers, etc.)',
    color: '#92E4D1',
  },
  '3893': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3891',
      libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    },
    '4': {
      id: '3893',
      libelle: 'Sports cérébraux',
    },
    libelle: 'Sports cérébraux',
    color: '#92E4D1',
  },
  '3894': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3891',
      libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    },
    '4': {
      id: '3894',
      libelle: 'Jeux de cartes',
    },
    libelle: 'Jeux de cartes',
    color: '#92E4D1',
  },
  '3895': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '3891',
      libelle: 'Jeux de société, Sports cérébraux, Jeux de cartes',
    },
    '4': {
      id: '3895',
      libelle: 'Jeux de société',
    },
    libelle: 'Jeux de société',
    color: '#92E4D1',
  },
  '4091': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3864',
      libelle: 'Sports et loisirs',
    },
    '3': {
      id: '4091',
      libelle: 'Jeu vidéo',
    },
    libelle: 'Jeu vidéo',
    color: '#92E4D1',
  },
  '3923': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3923',
      libelle: 'Chasse, pêche',
    },
    libelle: 'Chasse, pêche',
    color: '#92E4D1',
  },
  '3865': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3865',
      libelle: 'Transport',
    },
    libelle: 'Transport',
    color: '#92E4D1',
  },
  '3866': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3866',
      libelle: 'Animaux',
    },
    libelle: 'Animaux',
    color: '#92E4D1',
  },
  '3867': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3866',
      libelle: 'Animaux',
    },
    '3': {
      id: '3867',
      libelle: "Animaux d'élevage",
    },
    libelle: "Animaux d'élevage",
    color: '#92E4D1',
  },
  '3868': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3866',
      libelle: 'Animaux',
    },
    '3': {
      id: '3868',
      libelle: 'Animaux de compagnie (ou domestiques)',
    },
    libelle: 'Animaux de compagnie (ou domestiques)',
    color: '#92E4D1',
  },
  '3869': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3866',
      libelle: 'Animaux',
    },
    '3': {
      id: '3869',
      libelle: 'Animaux sauvages',
    },
    libelle: 'Animaux sauvages',
    color: '#92E4D1',
  },
  '3870': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3866',
      libelle: 'Animaux',
    },
    '3': {
      id: '3870',
      libelle: 'Apiculture',
    },
    libelle: 'Apiculture',
    color: '#92E4D1',
  },
  '3871': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    libelle: 'Nature',
    color: '#92E4D1',
  },
  '3872': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3872',
      libelle: 'Écologie',
    },
    libelle: 'Écologie',
    color: '#92E4D1',
  },
  '3873': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3873',
      libelle: 'Champignons',
    },
    libelle: 'Champignons',
    color: '#92E4D1',
  },
  '3874': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3874',
      libelle: 'Fleurs sauvages',
    },
    libelle: 'Fleurs sauvages',
    color: '#92E4D1',
  },
  '3875': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3875',
      libelle: 'Arbres',
    },
    libelle: 'Arbres',
    color: '#92E4D1',
  },
  '3876': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3876',
      libelle: 'Océans et mers',
    },
    libelle: 'Océans et mers',
    color: '#92E4D1',
  },
  '3877': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3877',
      libelle: 'Montagne',
    },
    libelle: 'Montagne',
    color: '#92E4D1',
  },
  '3878': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3878',
      libelle: 'Ressources naturelles (roches, minéraux, fossiles)',
    },
    libelle: 'Ressources naturelles (roches, minéraux, fossiles)',
    color: '#92E4D1',
  },
  '3879': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3879',
      libelle: 'Volcans et forces naturelles',
    },
    libelle: 'Volcans et forces naturelles',
    color: '#92E4D1',
  },
  '3880': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3880',
      libelle: 'Climat et météo',
    },
    libelle: 'Climat et météo',
    color: '#92E4D1',
  },
  '3881': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3871',
      libelle: 'Nature',
    },
    '3': {
      id: '3881',
      libelle: 'Astronomie',
    },
    libelle: 'Astronomie',
    color: '#92E4D1',
  },
  '3882': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    libelle: 'Jardinage',
    color: '#92E4D1',
  },
  '4019': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '4019',
      libelle: 'Encyclopédies du jardinage',
    },
    libelle: 'Encyclopédies du jardinage',
    color: '#92E4D1',
  },
  '4020': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '4020',
      libelle: 'Jardins bio, écologiques',
    },
    libelle: 'Jardins bio, écologiques',
    color: '#92E4D1',
  },
  '3883': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3883',
      libelle: "Jardin d'ornement, fleurs",
    },
    libelle: "Jardin d'ornement, fleurs",
    color: '#92E4D1',
  },
  '3884': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3884',
      libelle: "Plantes d'intérieur",
    },
    libelle: "Plantes d'intérieur",
    color: '#92E4D1',
  },
  '3885': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3885',
      libelle: 'Potager',
    },
    libelle: 'Potager',
    color: '#92E4D1',
  },
  '3886': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3886',
      libelle: 'Arbres et arbustes',
    },
    libelle: 'Arbres et arbustes',
    color: '#92E4D1',
  },
  '3887': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3887',
      libelle: 'Verger',
    },
    libelle: 'Verger',
    color: '#92E4D1',
  },
  '3888': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3888',
      libelle: 'Techniques et soin des plantes',
    },
    libelle: 'Techniques et soin des plantes',
    color: '#92E4D1',
  },
  '3889': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3889',
      libelle:
        'Aménagements de jardins et petits espaces (terrasse, balcon, etc.)',
    },
    libelle:
      'Aménagements de jardins et petits espaces (terrasse, balcon, etc.)',
    color: '#92E4D1',
  },
  '3890': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3890',
      libelle: 'Art et histoire des jardins',
    },
    libelle: 'Art et histoire des jardins',
    color: '#92E4D1',
  },
  '3924': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3882',
      libelle: 'Jardinage',
    },
    '3': {
      id: '3924',
      libelle: 'Bassins, fontaines',
    },
    libelle: 'Bassins, fontaines',
    color: '#92E4D1',
  },
  '3896': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3896',
      libelle: 'Généalogie',
    },
    libelle: 'Généalogie',
    color: '#92E4D1',
  },
  '3897': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3897',
      libelle: 'Collections',
    },
    libelle: 'Collections',
    color: '#92E4D1',
  },
  '3666': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3666',
      libelle: 'Humour',
    },
    libelle: 'Humour',
    color: '#92E4D1',
  },
  '4321': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3666',
      libelle: 'Humour',
    },
    '3': {
      id: '4321',
      libelle: 'Humoristes',
    },
    libelle: 'Humoristes',
    color: '#92E4D1',
  },
  '4322': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3666',
      libelle: 'Humour',
    },
    '3': {
      id: '4322',
      libelle: 'Compilations',
    },
    libelle: 'Compilations',
    color: '#92E4D1',
  },
  '4323': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3666',
      libelle: 'Humour',
    },
    '3': {
      id: '4323',
      libelle: 'Humour thématique',
    },
    libelle: 'Humour thématique',
    color: '#92E4D1',
  },
  '4324': {
    '1': {
      id: '3802',
      libelle: 'PRATIQUE',
    },
    '2': {
      id: '3666',
      libelle: 'Humour',
    },
    '3': {
      id: '4324',
      libelle: 'Dessins de presse, caricatures',
    },
    libelle: 'Dessins de presse, caricatures',
    color: '#92E4D1',
  },
  '3898': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    color: '#DBE591',
  },
  '3899': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    libelle: 'Cartes géographiques',
    color: '#DBE591',
  },
  '3900': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3900',
      libelle: 'Cartes routières',
    },
    libelle: 'Cartes routières',
    color: '#DBE591',
  },
  '3901': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3901',
      libelle: 'Cartes géologiques',
    },
    libelle: 'Cartes géologiques',
    color: '#DBE591',
  },
  '3902': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3902',
      libelle: 'Cartes marines',
    },
    libelle: 'Cartes marines',
    color: '#DBE591',
  },
  '3903': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3903',
      libelle: 'Cartes météorologiques',
    },
    libelle: 'Cartes météorologiques',
    color: '#DBE591',
  },
  '3904': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3904',
      libelle: 'Cartes topographies',
    },
    libelle: 'Cartes topographies',
    color: '#DBE591',
  },
  '3905': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3905',
      libelle: 'Cartes touristiques',
    },
    libelle: 'Cartes touristiques',
    color: '#DBE591',
  },
  '3906': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3906',
      libelle: 'Cartes thématiques',
    },
    libelle: 'Cartes thématiques',
    color: '#DBE591',
  },
  '3907': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3907',
      libelle: 'Cartes célestes',
    },
    libelle: 'Cartes célestes',
    color: '#DBE591',
  },
  '3908': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3899',
      libelle: 'Cartes géographiques',
    },
    '3': {
      id: '3908',
      libelle: 'Cartes aériennes',
    },
    libelle: 'Cartes aériennes',
    color: '#DBE591',
  },
  '3909': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3909',
      libelle: 'Atlas',
    },
    libelle: 'Atlas',
    color: '#DBE591',
  },
  '3910': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3909',
      libelle: 'Atlas',
    },
    '3': {
      id: '3910',
      libelle: 'Atlas généraux',
    },
    libelle: 'Atlas généraux',
    color: '#DBE591',
  },
  '3911': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3909',
      libelle: 'Atlas',
    },
    '3': {
      id: '3911',
      libelle: 'Atlas géographiques',
    },
    libelle: 'Atlas géographiques',
    color: '#DBE591',
  },
  '3912': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3909',
      libelle: 'Atlas',
    },
    '3': {
      id: '3912',
      libelle: 'Atlas thématiques',
    },
    libelle: 'Atlas thématiques',
    color: '#DBE591',
  },
  '3913': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3909',
      libelle: 'Atlas',
    },
    '3': {
      id: '3913',
      libelle: 'Atlas routiers',
    },
    libelle: 'Atlas routiers',
    color: '#DBE591',
  },
  '3914': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3914',
      libelle: 'Plans',
    },
    libelle: 'Plans',
    color: '#DBE591',
  },
  '3915': {
    '1': {
      id: '3898',
      libelle: 'CARTES GÉOGRAPHIQUES ET ATLAS',
    },
    '2': {
      id: '3914',
      libelle: 'Plans',
    },
    '3': {
      id: '3915',
      libelle: 'Plans de ville',
    },
    libelle: 'Plans de ville',
    color: '#DBE591',
  },
  '4129': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    libelle: 'JEUNES ADULTES',
    color: '#D8CB95',
  },
  '4144': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4144',
      libelle: 'Fantastique, paranormal',
    },
    libelle: 'Fantastique, paranormal',
    color: '#D8CB95',
  },
  '4145': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4145',
      libelle: 'Magie, fantasy',
    },
    libelle: 'Magie, fantasy',
    color: '#D8CB95',
  },
  '4146': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4146',
      libelle: 'Science-fiction',
    },
    libelle: 'Science-fiction',
    color: '#D8CB95',
  },
  '4147': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4147',
      libelle: 'Policier, thriller',
    },
    libelle: 'Policier, thriller',
    color: '#D8CB95',
  },
  '4148': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4148',
      libelle: 'Contemporain',
    },
    libelle: 'Contemporain',
    color: '#D8CB95',
  },
  '4149': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4149',
      libelle: 'Historique',
    },
    libelle: 'Historique',
    color: '#D8CB95',
  },
  '4150': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4150',
      libelle: 'Action, aventures',
    },
    libelle: 'Action, aventures',
    color: '#D8CB95',
  },
  '4151': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4151',
      libelle: 'Sentimental',
    },
    libelle: 'Sentimental',
    color: '#D8CB95',
  },
  '4152': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4152',
      libelle: 'Chick lit',
    },
    libelle: 'Chick lit',
    color: '#D8CB95',
  },
  '4153': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4153',
      libelle: 'Epouvante',
    },
    libelle: 'Epouvante',
    color: '#D8CB95',
  },
  '4154': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4154',
      libelle: 'Témoignages',
    },
    libelle: 'Témoignages',
    color: '#D8CB95',
  },
  '4155': {
    '1': {
      id: '4129',
      libelle: 'JEUNES ADULTES',
    },
    '2': {
      id: '4155',
      libelle: 'Dystopie',
    },
    libelle: 'Dystopie',
    color: '#D8CB95',
  },
}
