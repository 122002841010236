import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WebAppStateInterface } from '../interfaces/web-app.state.interface'
import {
  webAppGetAudienceCatalogsAction,
  webAppGetCatalogsAction,
  webAppGetHomeTopCatalogsAction,
  webAppStateGetMySelfAction,
  webAppStateGetReadershipProfilesStatus,
  webAppStateGetTopTagGroups,
} from '../actions/web-app.actions'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { MySelfInterface } from '../../core/interface/my-self.interface'
import { CompanyInterface } from '../../core/interface/company.interface'
import { TagGroupInterface } from '../../core/interface/TagGroupInterface'
import {
  AnalyticsProgressStatusInterface,
  ProgressStatusInterface,
} from '../interfaces/analytics-details/analytics-progress-status.interface'
import AudienceCatalogInterface from '../../core/interface/audience/audience-catalog.interface'

const initialState: WebAppStateInterface = {
  accessToken: localStorage.getItem('access_token'),
  refreshToken: localStorage.getItem('refresh_token'),
  firstName: localStorage.getItem('firstName'),
  lastName: localStorage.getItem('lastName'),
  userId: localStorage.getItem('userId'),
  company:
    localStorage.getItem('company') &&
    JSON.parse(localStorage.getItem('company') as string),
  companyUserId: localStorage.getItem('companyUserId'),
  isLoadingGetHomeTopCatalogs: false,
  cancelTokenGetCatalogs: null,
  catalogs: [],
  audienceCatalogs: [],
  homeTopCatalogs: [],
  isLoadingMySelf: false,
  mySelf: JSON.parse(localStorage.getItem('mySelf') as string),
  cancelTokenGetMySelf: null,
  showSnackBar: null,
  displayExitButton: false,
  isLoadingGetTopTagGroups: false,
  tagGroups: [],
  cancelTokenGetTopTagGroups: null,
  readershipProfileGenerating: [],
}

export const webAppSlice = createSlice({
  name: 'webAppState',
  initialState,
  reducers: {
    setWebAppStateLoggedIn: (
      state: WebAppStateInterface,
      action: PayloadAction<{
        accessToken: string
        refreshToken: string
      }>,
    ): WebAppStateInterface => {
      const { accessToken, refreshToken } = action.payload
      return {
        ...state,
        accessToken,
        refreshToken,
      }
    },
    // TODO
    setLoggedIn: (
      state: WebAppStateInterface,
      action: PayloadAction<{
        access: string
        refresh: string
        firstName: string
        lastName: string
        company: CompanyInterface | null
        userId: string
        companyUserId: string
      }>,
    ): WebAppStateInterface => ({
      ...state,
      accessToken: action.payload.access,
      refreshToken: action.payload.refresh,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      company: action.payload.company,
      userId: action.payload.userId,
      companyUserId: action.payload.companyUserId,
    }),
    // TODO
    setLoggedOut: (state: WebAppStateInterface): WebAppStateInterface => ({
      ...state,
      accessToken: null,
      refreshToken: null,
      firstName: null,
      lastName: null,
      company: null,
      companyUserId: null,
    }),
    setWebAppStateLoggedOut: (
      state: WebAppStateInterface,
    ): WebAppStateInterface => ({
      ...state,
      accessToken: null,
      refreshToken: null,
    }),

    refreshToken: (
      state: WebAppStateInterface,
      action: PayloadAction<{ access: string; refresh: string }>,
    ): WebAppStateInterface => ({
      ...state,
      accessToken: action.payload.access,
      refreshToken: action.payload.refresh,
    }),
    setShowSnackBar: (
      state: WebAppStateInterface,
      action: PayloadAction<string | null>,
    ): WebAppStateInterface => ({
      ...state,
      showSnackBar: action.payload,
    }),
    reinitializeWebAppState: (
      state: WebAppStateInterface,
    ): WebAppStateInterface => ({
      ...state,
      catalogs: initialState.catalogs,
      audienceCatalogs: initialState.audienceCatalogs,
    }),
    setReadershipProfileGenerating: (
      state: WebAppStateInterface,
      action: PayloadAction<
        ProgressStatusInterface<AnalyticsProgressStatusInterface>[]
      >,
    ): WebAppStateInterface => ({
      ...state,
      readershipProfileGenerating: action.payload,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        webAppGetCatalogsAction.pending,
        (state: WebAppStateInterface): WebAppStateInterface => {
          return {
            ...state,
            catalogs: [],
          }
        },
      )
      .addCase(
        webAppGetCatalogsAction.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<CatalogInterface[]>,
        ): WebAppStateInterface => ({
          ...state,
          catalogs: action.payload,
        }),
      )
      .addCase(
        webAppGetCatalogsAction.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
          catalogs: [],
        }),
      )
      .addCase(
        webAppGetAudienceCatalogsAction.pending,
        (state: WebAppStateInterface): WebAppStateInterface => {
          return {
            ...state,
            audienceCatalogs: [],
          }
        },
      )
      .addCase(
        webAppGetAudienceCatalogsAction.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<AudienceCatalogInterface[]>,
        ): WebAppStateInterface => ({
          ...state,
          audienceCatalogs: action.payload.sort(
            (a, b) =>
              b.user_reco_catalog.nb_users - a.user_reco_catalog.nb_users,
          ),
        }),
      )
      .addCase(
        webAppGetAudienceCatalogsAction.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
          audienceCatalogs: [],
        }),
      )
      .addCase(
        webAppGetHomeTopCatalogsAction.pending,
        (state: WebAppStateInterface): WebAppStateInterface => {
          const { cancelTokenGetCatalogs } = state
          if (cancelTokenGetCatalogs) {
            cancelTokenGetCatalogs.cancel()
          }

          return {
            ...state,
            isLoadingGetHomeTopCatalogs: true,
            homeTopCatalogs: [],
            cancelTokenGetCatalogs: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        webAppGetHomeTopCatalogsAction.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<CatalogInterface[]>,
        ): WebAppStateInterface => ({
          ...state,
          isLoadingGetHomeTopCatalogs: false,
          homeTopCatalogs: action.payload,
        }),
      )
      .addCase(
        webAppGetHomeTopCatalogsAction.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
          isLoadingGetHomeTopCatalogs: false,
          homeTopCatalogs: [],
        }),
      )
      .addCase(
        webAppStateGetMySelfAction.pending,
        (state: WebAppStateInterface): WebAppStateInterface => {
          const { cancelTokenGetMySelf } = state
          if (cancelTokenGetMySelf) {
            cancelTokenGetMySelf.cancel()
          }
          return {
            ...state,
            isLoadingMySelf: true,
            cancelTokenGetMySelf: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        webAppStateGetMySelfAction.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<MySelfInterface>,
        ): WebAppStateInterface => ({
          ...state,
          isLoadingMySelf: false,
          mySelf: action.payload,
        }),
      )
      .addCase(
        webAppStateGetMySelfAction.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
          isLoadingMySelf: false,
          mySelf: null,
        }),
      )
      .addCase(
        webAppStateGetTopTagGroups.pending,
        (state: WebAppStateInterface): WebAppStateInterface => {
          const { cancelTokenGetTopTagGroups } = state
          if (cancelTokenGetTopTagGroups) {
            cancelTokenGetTopTagGroups.cancel()
          }
          return {
            ...state,
            isLoadingGetTopTagGroups: true,
            tagGroups: [],
            cancelTokenGetTopTagGroups: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        webAppStateGetTopTagGroups.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<TagGroupInterface[]>,
        ): WebAppStateInterface => ({
          ...state,
          isLoadingGetTopTagGroups: false,
          tagGroups: action.payload,
        }),
      )
      .addCase(
        webAppStateGetTopTagGroups.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
          isLoadingGetTopTagGroups: false,
          tagGroups: [],
        }),
      )
      .addCase(
        webAppStateGetReadershipProfilesStatus.pending,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
        }),
      )
      .addCase(
        webAppStateGetReadershipProfilesStatus.fulfilled,
        (
          state: WebAppStateInterface,
          action: PayloadAction<
            ProgressStatusInterface<AnalyticsProgressStatusInterface>[]
          >,
        ): WebAppStateInterface => ({
          ...state,
          readershipProfileGenerating: action.payload,
        }),
      )
      .addCase(
        webAppStateGetReadershipProfilesStatus.rejected,
        (state: WebAppStateInterface): WebAppStateInterface => ({
          ...state,
        }),
      )
  },
})

export const {
  setLoggedIn,
  setLoggedOut,
  setWebAppStateLoggedIn,
  setWebAppStateLoggedOut,
  setShowSnackBar,
  reinitializeWebAppState,
  setReadershipProfileGenerating,
} = webAppSlice.actions

export const webAppReducer = webAppSlice.reducer
