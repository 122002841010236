import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import axios from 'axios'
import { getReportsService } from '../../core/api/services/bookmetrie/analytics/get-reports.service'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { getTopsByCatalogIdService } from '../../core/api/services/bookmetrie/get-tops-by-catalog-id.service'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { REFERENCE_TIMEZONE } from '../../core/config/TimeZoneConfig'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { getTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/get-top-requests-saved.service'
import { serverInUpdate } from '../../core/utils/get-period-by-period-type.util'
import { RootState } from '../store'
import {
  DASHBOARD_GET_LAST_PRIVATE_REPORTS,
  DASHBOARD_GET_LAST_TOP_REQUEST_SAVE,
  DASHBOARD_GET_TRENDS_BY_CATALOG_ID,
} from '../prefix-actions.config'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'

dayjs.extend(timezone)

export const dashboardGetTrendsByCatalogIdAction = createAsyncThunk(
  DASHBOARD_GET_TRENDS_BY_CATALOG_ID,
  async (
    {
      catalogSelected,
    }: {
      catalogSelected: CatalogInterface
    },
    { getState },
  ): Promise<any> => {
    try {
      const { dashboardState, webAppState } = getState() as RootState
      const { cancelTokenGetTrendsByCatalogId } = dashboardState
      const { tagGroups } = webAppState

      const currentDate = dayjs().tz(REFERENCE_TIMEZONE)
      let endDate
      let startDate

      if (serverInUpdate()) {
        startDate = currentDate.subtract(2, 'days').startOf('day')
        endDate = currentDate.subtract(2, 'days').endOf('day')
      } else {
        startDate = currentDate.subtract(1, 'days').startOf('day')
        endDate = currentDate.subtract(1, 'days').endOf('day')
      }

      const { data } = await getTopsByCatalogIdService(
        catalogSelected.id,
        {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          period_type: PeriodTypeEnum.PERIOD,
          age_category: AgeFilterEnum.AGE_CATEGORY_ALL,
          sex_category: GenderFilterEnum.SEX_CATEGORY_ALL,
          tag_group: tagGroups[0]?.id,
        },
        cancelTokenGetTrendsByCatalogId?.token,
      )
      return data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      }
      throw new Error()
    }
  },
)

export const dashboardGetLastTopRequestsSaveAction = createAsyncThunk<
  PaginationResponseInterface<TopRequestSavedInterface>
>(
  DASHBOARD_GET_LAST_TOP_REQUEST_SAVE,
  async (): Promise<PaginationResponseInterface<TopRequestSavedInterface>> => {
    try {
      const { data } = await getTopRequestSavedService()
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const dashboardGetLastPrivateReportsAction = createAsyncThunk(
  DASHBOARD_GET_LAST_PRIVATE_REPORTS,
  async (_, { getState }): Promise<ReportLightResponseInterface[]> => {
    try {
      const { dashboardState } = getState() as RootState
      const { cancelTokenGetLastPrivateReports } = dashboardState

      const { data } = await getReportsService(
        {
          publicReport: false,
          page_size: 6,
          ordering: '-modified',
        },
        cancelTokenGetLastPrivateReports?.token,
      )
      return data?.results || []
    } catch (error) {
      throw new Error()
    }
  },
)
