import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { CatalogInterface } from '../../../core/interface/rank/catalog.interface'
import DynamicsResultsCatalogsFilters from '../filters/request-filters/DynamicsResultsCatalogsFilters'
import DynamicsResultsPeriodsFilters from '../filters/request-filters/DynamicsResultsPeriodsFilters'
import DynamicsResultsTagsFilters from '../filters/request-filters/DynamicsResultsTagsFilters'
import DynamicsResultsReadersFilters from '../filters/request-filters/DynamicsResultsReadersFilters'
import { AgeFilterEnum } from '../../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../../core/enums/gender-filter.enum'
import { PeriodTypeEnum } from '../../../core/enums/period-type.enum'
import { ReactComponent as WarningIcon } from '../../../assets/icons/app/warning.icon.svg'

const DisableCardStyle = styled.div`
  border-radius: 4px;
  background: #cdeeff;
  width: 100%;
  padding: 5px 6px;
  margin-top: 8px;
  color: #252525;
  font-family: Fira Sans, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const FilterHeaderStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16.5px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0 48px 16px 56px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`
const DynamicsResultsHeaderFiltersRequests = ({
  catalogIdSelected,
  catalogs,
  ageSelected,
  genderSelected,
  tagGroupSelected,
  handleChangeQuery,
  periodTypeSelected,
  startDateSelected,
  endDateSelected,
}: {
  ageSelected: AgeFilterEnum
  genderSelected: GenderFilterEnum
  tagGroupSelected: string
  catalogIdSelected: string
  catalogs: CatalogInterface[]
  handleChangeQuery: (key: any, value: any) => void
  periodTypeSelected: PeriodTypeEnum
  startDateSelected: string
  endDateSelected: string
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState<string | null>(null)
  const [displayWarningInfo, setDisplayWarningInfo] = useState<boolean>(false)

  return (
    <FilterHeaderStyle className="flex flex-row bg-[#252525] items-center justify-center gap-2 mt-2">
      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {t('webApp.dynamics.results.filters.request.showBooks')}
      </div>
      <DynamicsResultsCatalogsFilters
        catalogs={catalogs}
        catalogIdSelected={catalogIdSelected}
        handleChangeQuery={handleChangeQuery}
        menuKey="CATALOGS"
        menuOpen={menuOpen}
        handleMenuOpen={setMenuOpen}
      />
      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {t('webApp.dynamics.topRequest.the_most')}
      </div>
      <DynamicsResultsTagsFilters
        tagGroupSelected={tagGroupSelected}
        handleChangeQuery={handleChangeQuery}
        menuKey="TAGS"
        menuOpen={menuOpen}
        handleMenuOpen={setMenuOpen}
      />
      <DynamicsResultsPeriodsFilters
        periodTypeSelected={periodTypeSelected}
        handleChangeQuery={handleChangeQuery}
        startDateSelected={startDateSelected}
        endDateSelected={endDateSelected}
        menuKey="PERIODS"
        menuOpen={menuOpen}
        handleMenuOpen={setMenuOpen}
      />
      <span>{t('webApp.dynamics.topRequest.by')}</span>
      <DynamicsResultsReadersFilters
        disabled={
          periodTypeSelected === PeriodTypeEnum.DAY ||
          periodTypeSelected === PeriodTypeEnum.WEEK
        }
        noDisplayWarningDisableTooltip
        ageSelected={ageSelected}
        handleChangeQuery={handleChangeQuery}
        genderSelected={genderSelected}
        menuKey="READERS"
        menuOpen={menuOpen}
        handleMenuOpen={setMenuOpen}
      />
      <div className="w-[32px] relative">
        {((periodTypeSelected === PeriodTypeEnum.DAY ||
          periodTypeSelected === PeriodTypeEnum.WEEK) && (
          <>
            <WarningIcon
              className="text-white w-6 h-6 cursor-pointer waring-icon"
              onMouseEnter={() => {
                setDisplayWarningInfo(true)
              }}
              onMouseLeave={() => {
                setDisplayWarningInfo(false)
              }}
            />
            {(displayWarningInfo && (
              <DisableCardStyle
                className="flex flex-row items-start justify-between gap-2 absolute right-0 top-12 waring-info"
                style={{
                  zIndex: 70,
                  width: 256,
                }}
              >
                {t('webApp.dynamics.topRequest.readerDisabledInfo')}
              </DisableCardStyle>
            )) ||
              null}
          </>
        )) ||
          null}
      </div>
    </FilterHeaderStyle>
  )
}

export default DynamicsResultsHeaderFiltersRequests
