import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { InvitationStateInterface } from '../interfaces/invitation.state.interface'
import {
  invitationGetOnBoardingInvitationAction,
  invitationPostOnBoardingInvitationAction,
} from '../actions/invitation.actions'
import { isJsonStringUtil } from '../../core/utils/is-json-string.util'

const initialState: InvitationStateInterface = {
  isLoadingMain: false,
  email: '',
  isLoadingPost: false,
  hasError: false,
  errorInfoInvitationToken: null,
  cancelTokenGetOnBoardingInvitation: null,
  hasErrorPostInvitationPassword: false,
}

export const invitationSlice = createSlice({
  name: 'invitationState',
  initialState,
  reducers: {
    reinitializeInvitationState: (
      state: InvitationStateInterface,
    ): InvitationStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        invitationGetOnBoardingInvitationAction.pending,
        (state: InvitationStateInterface): InvitationStateInterface => {
          const { cancelTokenGetOnBoardingInvitation } = state
          if (cancelTokenGetOnBoardingInvitation) {
            cancelTokenGetOnBoardingInvitation.cancel()
          }

          return {
            ...state,
            isLoadingMain: true,
            email: '',
            cancelTokenGetOnBoardingInvitation: axios.CancelToken.source(),
            hasError: false,
            errorInfoInvitationToken: null,
          }
        },
      )
      .addCase(
        invitationGetOnBoardingInvitationAction.fulfilled,
        (
          state: InvitationStateInterface,
          action: PayloadAction<any>,
        ): InvitationStateInterface => {
          const { email } = action.payload
          return {
            ...state,
            isLoadingMain: false,
            email,
            hasError: false,
            errorInfoInvitationToken: null,
          }
        },
      )
      .addCase(
        invitationGetOnBoardingInvitationAction.rejected,
        (
          state: InvitationStateInterface,
          action: PayloadAction<any, any, any, any>,
        ): InvitationStateInterface => {
          const errorInfo = {
            hasError: true,
            errorInfoInvitationToken: null,
          }
          if (
            action.error &&
            action.error.message &&
            isJsonStringUtil(action.error.message)
          ) {
            errorInfo.errorInfoInvitationToken = JSON.parse(
              action.error.message,
            )
          }
          return {
            ...state,
            isLoadingMain: false,
            email: '',
            ...errorInfo,
          }
        },
      )
      .addCase(
        invitationPostOnBoardingInvitationAction.pending,
        (state: InvitationStateInterface): InvitationStateInterface => ({
          ...state,
          isLoadingPost: true,
          hasErrorPostInvitationPassword: false,
        }),
      )
      .addCase(
        invitationPostOnBoardingInvitationAction.fulfilled,
        (state: InvitationStateInterface): InvitationStateInterface => ({
          ...state,
          isLoadingPost: false,
          hasErrorPostInvitationPassword: false,
        }),
      )
      .addCase(
        invitationPostOnBoardingInvitationAction.rejected,
        (state: InvitationStateInterface): InvitationStateInterface => ({
          ...state,
          isLoadingPost: false,
          hasErrorPostInvitationPassword: true,
        }),
      )
  },
})

export const { reinitializeInvitationState } = invitationSlice.actions
export const invitationReducer = invitationSlice.reducer
