import React from 'react'
import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { ModuleTabularConfig, TabularColumnConfigType } from './TabularConfig'
import GlHeaderSortButtons from '../../../../../share/buttons/GlHeaderSortButtons'
import { SortDirectionEnum } from '../../../../../../core/enums/sort-direction.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'

const AnalyticsOutputHeaderTabular = ({
  valuesConfigurations,
  dataset,
  sortColumn,
  handleSortColumn,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  dataset: DatasetInterface[]
  sortColumn: {
    column: SourceEnum
    sortDirection: SortDirectionEnum
  }
  handleSortColumn: ({
    column,
    sortDirection,
  }: {
    column: SourceEnum
    sortDirection: SortDirectionEnum
  }) => void
}) => {
  const { t } = useTranslation()
  const tabularConfig = ModuleTabularConfig(valuesConfigurations)
  return (
    <div className="flex flex-row p-2 gap-2 bg-[#f7f7f7] rounded h-8">
      {dataset.length &&
        valuesConfigurations
          .filter(
            (c) =>
              c.is_visible &&
              c.source !== SourceEnum.WORK_CLIL &&
              c.source !== SourceEnum.EAN_CLIL,
          )
          .map((valueConfiguration) => {
            const keyValue = valueConfiguration.source
            const columnConfig = tabularConfig[
              keyValue
            ] as TabularColumnConfigType
            return (
              <div
                key={valueConfiguration.source}
                className="flex flex-1 items-center justify-start gap-2 cursor-pointer"
                style={{
                  minWidth: columnConfig.minWidth,
                  maxWidth: columnConfig.maxWidth,
                }}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.preventDefault()
                  handleSortColumn({
                    column: keyValue,
                    sortDirection:
                      sortColumn.column === keyValue
                        ? sortColumn.sortDirection === SortDirectionEnum.EMPTY
                          ? SortDirectionEnum.ASC
                          : sortColumn.sortDirection === SortDirectionEnum.ASC
                          ? SortDirectionEnum.DESC
                          : SortDirectionEnum.EMPTY
                        : SortDirectionEnum.ASC,
                  })
                }}
              >
                <span>{t(keyValue)}</span>
                {(sortColumn.column === keyValue && (
                  <GlHeaderSortButtons
                    sortDirection={sortColumn.sortDirection}
                  />
                )) ||
                  null}
              </div>
            )
          })}
    </div>
  )
}

export default AnalyticsOutputHeaderTabular
