import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useState } from 'react'

import GlHDivider from '../share/GlHDivider'
import { PermissionEnum } from '../../core/enums/permission.enum'
import { ReactComponent as BottomChevronIcon } from '../../assets/icons/app/bottom-chevron.icon.svg'
import { ReactComponent as CheckedIcon } from '../../assets/icons/app/checked.icon.svg'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'

const ContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const ButtonMainStyle = styled.button`
  width: 96px;
  border: ${({ isOwner }: { isOwner: boolean }) =>
    isOwner ? 'none' : '1px solid #e3e3e3'};
  border-radius: 4px;
  &:hover {
    ${({ isOwner }: { isOwner: boolean }) =>
      !isOwner ? ' background-color: #f4f4f4' : ''};
  }
  &:active {
    ${({ isOwner }: { isOwner: boolean }) =>
      !isOwner ? ' background-color: #e2e2e2' : ''};
  }
`

const ButtonStyle = styled.button`
  width: 100%;
  padding: 6px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e2e2e2;
  }
`

const MenuStyle = styled.div`
  background-color: white;
  position: absolute;
  z-index: 300;
  padding: 4px 0;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-width: 160px;
  max-width: 160px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const PermissionsMenu = ({
  permission,
  isOwner,
  displayMenuOwner,
  handleChangePermission = undefined,
  handleDeleteUserPermission = undefined,
  handleChangeOwner = undefined,
  analyticsType,
}: {
  permission: PermissionEnum
  isOwner: boolean
  displayMenuOwner: boolean
  handleChangePermission?: (permission: PermissionEnum) => void
  handleDeleteUserPermission?: () => void
  handleChangeOwner?: () => void
  analyticsType?: AnalyticsTypeEnum
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClickChangeOwnerAction = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleChangeOwner && handleChangeOwner()
    setIsOpen(false)
  }

  return (
    <div className="static">
      <ContainerStyle>
        <ButtonMainStyle
          type="button"
          isOwner={isOwner && !displayMenuOwner}
          className="flex flex-row justify-around items-center px-2 py-1 gap-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {t(
              `commons.permissions.${
                isOwner && !displayMenuOwner ? 'owner' : permission
              }`,
            )}
          </span>
          {(displayMenuOwner && <BottomChevronIcon className="w-4 h-4" />) ||
            null}
        </ButtonMainStyle>
      </ContainerStyle>
      {(displayMenuOwner && isOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={() => setIsOpen(false)}
          />
          <MenuStyle className="absolute top-8 right-0 flex flex-col z-160">
            {analyticsType !== AnalyticsTypeEnum.READERSHIP_PROFILE && (
              <ButtonStyle
                type="button"
                disabled={permission === PermissionEnum.EDIT}
                className="flex flex-row items-center"
                onClick={() => {
                  handleChangePermission &&
                    handleChangePermission(PermissionEnum.EDIT)
                  setIsOpen(false)
                }}
              >
                <span className="w-4 mr-1">
                  {(permission === PermissionEnum.EDIT && (
                    <CheckedIcon className="w-3 h-3" />
                  )) ||
                    null}
                </span>
                <span>{t('commons.permissions.edit')}</span>
              </ButtonStyle>
            )}
            <ButtonStyle
              type="button"
              disabled={permission === PermissionEnum.READ}
              className="flex flex-row items-center"
              onClick={() => {
                handleChangePermission &&
                  handleChangePermission(PermissionEnum.READ)
                setIsOpen(false)
              }}
            >
              <span className="w-4 mr-1">
                {(permission === PermissionEnum.READ && (
                  <CheckedIcon className="w-3 h-3" />
                )) ||
                  null}
              </span>
              <span>{t('commons.permissions.read')}</span>
            </ButtonStyle>
            {isOwner && (
              <>
                <GlHDivider color="#e2e2e2" />
                <ButtonStyle type="button" onClick={onClickChangeOwnerAction}>
                  {t('commons.permissions.ownerTransfer')}
                </ButtonStyle>
                <ButtonStyle
                  type="button"
                  className="text-[#F97E60]"
                  onClick={() => {
                    handleDeleteUserPermission && handleDeleteUserPermission()
                    setIsOpen(false)
                  }}
                >
                  {t('commons.permissions.revokeAccess')}
                </ButtonStyle>
              </>
            )}
          </MenuStyle>
        </>
      )) ||
        null}
    </div>
  )
}

export default PermissionsMenu
