import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import AnalyticDetailsWorkMirrorView from './work-mirror/AnalyticDetailsWorkMirrorView'
import AnalyticDetailsReadershipProfileView from './readership-profile/AnalyticDetailsReadershipProfileView'

const SwitchViewAnalytic = ({
  type,
  handleSaveAnalytics,
  handleShareAnalytics,
}: {
  type: AnalyticsTypeEnum
  handleSaveAnalytics: () => void
  handleShareAnalytics: () => void
}) => {
  switch (type) {
    case AnalyticsTypeEnum.WORK_MIRROR:
      return (
        <AnalyticDetailsWorkMirrorView
          handleSaveAnalytics={handleSaveAnalytics}
          handleShareAnalytics={handleShareAnalytics}
        />
      )
    case AnalyticsTypeEnum.READERSHIP_PROFILE:
      return (
        <AnalyticDetailsReadershipProfileView
          handleShareAnalytics={handleShareAnalytics}
        />
      )
    default:
      return null
  }
}

export default SwitchViewAnalytic
