import styled from '@emotion/styled'

const MainStyle = styled.h1`
  margin: 8px 8px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: #252525;
  text-justify: none;
`
const H1Style = styled.h1`
  margin: 8px 8px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: #252525;
`
const H2Style = styled.h2`
  margin: 8px 8px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #252525;
`

const H3Style = styled.h3`
  margin: 8px 8px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #252525;
`

const NoteStyle = styled.p`
  margin: 8px 8px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
  text-transform: uppercase;
`

const PStyle = styled.p`
  margin: 8px 8px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
`

const LineStyle = styled.div`
  margin: 8px 8px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
`
const LegalContentType = ({
  refId,
  type,
  content,
}: {
  refId: string
  type: string
  content: string
}) => {
  if (type === 'main') {
    return (
      <MainStyle
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'h1') {
    return (
      <H1Style
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'h2') {
    return (
      <H2Style
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'h3') {
    return (
      <H3Style
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'note') {
    return (
      <NoteStyle
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'p') {
    return (
      <PStyle
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }

  if (type === 'line') {
    return (
      <LineStyle
        id={refId}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  }
  return null
}

export default LegalContentType
