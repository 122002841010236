export const PathConfig = {
  BASE: '',
  BOOKMETRIE: 'bookmetrie',
  AUTH: 'auth',
  // Clé pour la création exemple ('/reports/new)
  NEW: 'new',
  RANKS: 'ranks',
  DYNAMICS: 'dynamics',
  SEARCH: 'search',
  RESULTS: 'results',
  ANALYTICS: 'analytics',
  PRIVATE_REPORTS: 'private-reports',
  AUDIENCE: 'audience',
  SHARED: 'share',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  INVITATION: 'invitation',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD: 'change-password',
  LEGAL: 'legal',
  PRIVACY: 'privacy',
  PARAMS: (name: string) => `:${name}Id`,
  TOKEN_PARAMS: (name: string) => `:${name}Token`,
  MANAGE: 'manage',
  SETTINGS_PROFILE: 'profile',
  SETTINGS_TEAMS: 'teams',
  SETTINGS_LEGAL: 'legal',
  SETTINGS_SUBSCRIPTION: 'subscription',
  DEV: 'dev',
}

export const buildRelativePath = (items?: string[], anchor?: string): string =>
  ['/', (items || []).join('/')].join('').concat((anchor && `#${anchor}`) || '')

export const buildPathWithParams = (
  path: string,
  params: { [key: string]: any },
) =>
  path.concat(
    `?${Object.keys(params)
      .map((key: string) => `${key}=${params[key]}`)
      .join('&')}`,
  )

export const KEY_PARAM_REPORT = 'report'
export const KEY_PARAM_AUDIENCE = 'audience'
export const KEY_PARAM_SLUG = 'slug'
export const KEY_PARAM_INVITATION = 'invitation'
export const KEY_PARAM_CHANGE_PASSWORD = 'changePassword'
