import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../store/hooks'
import getPrivateReportById from '../../core/api/services/bookmetrie/private-reports/get-private-report-by-id.service'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import { AudienceInputInterface } from '../../core/interface/audience/audience-input.interface'
import useAudienceCatalogHook from '../../core/hooks/useAudienceCatalog.hook'
import AnalyticDetailsLayout from '../../components/analytics-details-v2/AnalyticDetailsLayout'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { patchReportService } from '../../core/api/services/bookmetrie/reports.service'
import Audience from '../../components/audience/audience-view/Audience'

const AudienceView = () => {
  const { audienceId } = useParams<{ audienceId: string }>()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [audience, setAudience] =
    useState<AnalyticDetailsInterface<AudienceInputInterface> | null>(null)

  const { catalogs, isLoading: isLoadingCatalog } = useAudienceCatalogHook()

  useEffect(() => {
    if (!audienceId) return
    setIsLoading(true)
    getPrivateReportById(audienceId)
      .then((response) => {
        setAudience(response.data)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => setIsLoading(false))
  }, [])

  const backToPrivateReports = () => {
    navigate(
      buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.PRIVATE_REPORTS]),
    )
  }

  const handleRenameAnalytic = (name: string) => {
    if (!audienceId) return
    patchReportService(audienceId, { name })
      .then(() => {
        dispatch(
          setShowSnackBar(
            t('snackbarMessage.renameReportConfirm', {
              reportName: name,
            }),
          ),
        )
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
  }

  return (
    <AnalyticDetailsLayout
      analyticName={audience?.name || ''}
      handleRenameAnalytic={handleRenameAnalytic}
      handleExitAnalyticDetails={backToPrivateReports}
      isLoading={isLoading || isLoadingCatalog}
      hasButtonPrevious={false}
    >
      <Audience audience={audience} catalogs={catalogs} />
    </AnalyticDetailsLayout>
  )
}

export default AudienceView
