import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const putOutputsOrderService = (
  reportId: string,
  inputId: string,
  outputs: { outputs: string[] },
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.REPORT_BY_ID_INPUT_BY_ID_OUTPUTS_ORDER(reportId, inputId),
    outputs,
    {
      cancelToken,
    },
  )
