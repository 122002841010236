import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import GlLoader from '../../../share/GlLoader'
import GlHDivider from '../../../share/GlHDivider'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../core/config/clil-refs-mapping.config'
import { AnalyticInputInterface } from '../../../../core/interface/analytics/analytic-input.interface'
import { AnalyticOutputInterface } from '../../../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../../../../core/enums/analytic-output-type.enum'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'

const TitleAStyle = styled.div`
  color: white;
  /* Book Title/T1 */
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 370px;
`

const AuthorStyle = styled.div`
  color: #e9e9e9;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const CardStyle = styled.div`
  min-width: 256px;
  max-width: 256px;
  min-height: 96px;
  max-height: 96px;
  background: white;
  border-radius: 4px;
`

const TitleStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  // text-transform: uppercase;
  color: #595959;
`

const DynamicsResultsBookWorksMirrors = ({
  isLoadingBookDetailsWorkMirror,
  bookDetailsWorkMirror,
}: {
  isLoadingBookDetailsWorkMirror: boolean
  bookDetailsWorkMirror: AnalyticInputInterface | null
}) => {
  const { t } = useTranslation()
  const booksConstellationToShow = (): WorkMirrorBookInterface[] => {
    return (
      bookDetailsWorkMirror?.outputs.find(
        (output: AnalyticOutputInterface) =>
          output.type === AnalyticOutputTypeEnum.WORK_MIRROR,
      )?.work_mirror_books || []
    )
  }

  if (isLoadingBookDetailsWorkMirror) {
    return (
      <div className="mx-5">
        <GlLoader />
      </div>
    )
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  return (
    (booksConstellationToShow().length && (
      <div className="mx-5">
        <GlHDivider />
        <div className="mt-2">
          <TitleStyle>{t('webApp.ranks.bookDetails.worksMirrors')}</TitleStyle>
          <PerfectScrollbar className="flex flex-row gap-2 py-4">
            {booksConstellationToShow().map(
              (workMirror: WorkMirrorBookInterface, index: number) => (
                <CardStyle
                  key={`similar-title-${workMirror.id_ean}`}
                  className="flex flex-row  m-1 p-2 gap-2"
                  style={{
                    boxShadow:
                      ' 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.08)',
                    filter: index > 1 ? 'blur(5px)' : 'none',
                  }}
                >
                  <img
                    src={
                      workMirror?.book?.imgs ||
                      getBookCoverByClil(workMirror?.book?.clil1)
                    }
                    alt=""
                    style={{
                      width: 60,
                      height: 80,
                      borderRadius: 2,
                    }}
                  />
                  <div className="flex flex-col gap-1 items-start justify-start">
                    <TitleAStyle
                      style={{
                        color: 'black',
                        maxWidth: 174,
                      }}
                    >
                      {workMirror?.book?.title}
                    </TitleAStyle>
                    <AuthorStyle
                      style={{
                        color: 'grey',
                      }}
                    >
                      {workMirror?.book?.authors[0]}
                    </AuthorStyle>
                  </div>
                </CardStyle>
              ),
            )}
          </PerfectScrollbar>
        </div>
      </div>
    )) ||
    null
  )
}

export default DynamicsResultsBookWorksMirrors
