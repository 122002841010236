import React, { ReactElement } from 'react'
import styled from '@emotion/styled'

const Label = styled.div`
  ${({ main }: { main: boolean }) => {
    if (!main) {
      return `
        color: #3B3B3B;
        font-family: Fira Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      `
    }
    return `
      color: var(--grayscale-dark-gray, var(--color-gray-gray-dark, #3B3B3B));
      font-family: Vollkorn, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
  `
  }}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`
interface Props {
  labelValue?: string | number
  className?: string
  children?: ReactElement | null
  main?: boolean
}

const GlCellBodyV3 = ({
  labelValue = '',
  className = '',
  children = null,
  main = false,
}: Props) => {
  return (
    <div
      className={`flex flex-1 flex-auto items-center justify-start p-1 text-left ${className}`}
    >
      {children || <Label main={main}>{labelValue}</Label>}
    </div>
  )
}

export default GlCellBodyV3
