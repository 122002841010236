import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import GlHDivider from '../../../../../../share/GlHDivider'
import { DatasetModuleInterface } from '../../../../../../../core/interface/analytics/dataset-module.interface'
import { analyticsDetailsV2StatePutDatasetModulesAction } from '../../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { useAppDispatch } from '../../../../../../../store/hooks'
import { EditSectionEnum } from '../../../../../../../core/enums/analytic-edit-section.enum'
import { EditOptionInterface } from '../../../../../../../core/interface/analytics/analytic-edit-option.interface'
import EditMenuSection from './EditMenuSection'
import { ConvertDatasetModuleToOption } from '../../../../../../../core/utils/convert-datasetmodule-to-option.util'
import { UpdateDatasetModuleByToggle } from '../../../../../../../core/utils/update-datasetmodule-by-toggle.util'
import { UpdateDatasetmoduleByDropdown } from '../../../../../../../core/utils/update-datasetmodule-by-dropdown.util'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import { SelectOptionInterface } from '../../../../../../../core/interface/select-option.interface'

const InputDialog = styled.input`
  width: 100%;
  display: flex;
  height: 40px;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  outline: none;
  &:disabled {
    pointer-events: none;
  }
`

const EditMenuDatasetModules = ({
  title,
  subTitle,
  datasetModule,
}: {
  title: string
  subTitle: string
  datasetModule?: DatasetModuleInterface
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const datasetModuleOptionsDatas = ConvertDatasetModuleToOption(datasetModule)

  const [inputTitleValue, setInputTitleValue] = useState<string>(title)
  const [inputSubTitleValue, setInputSubTitleValue] = useState<string>(subTitle)

  const hasDataOption = datasetModuleOptionsDatas?.some(
    (option: EditOptionInterface<string>) =>
      option.section === EditSectionEnum.DATAS,
  )
  const hasDisplayOption = datasetModuleOptionsDatas?.some(
    (option: EditOptionInterface<string>) =>
      option.section === EditSectionEnum.DISPLAY,
  )

  const handleToggleSetOptionToDisplay = (optionId: string, value: boolean) => {
    if (!datasetModule) return
    const newDatasetModule = UpdateDatasetModuleByToggle(
      datasetModule,
      optionId,
      value,
    )

    void dispatch(
      analyticsDetailsV2StatePutDatasetModulesAction({
        datasetModule: newDatasetModule,
      }),
    )
  }

  const handleSizeDropdownSetOptionToDisplay = (
    optionId: string,
    value: SelectOptionInterface<AnalyticOutputLayoutSizeEnum>,
  ) => {
    if (!datasetModule) return
    const newDatasetModule = UpdateDatasetmoduleByDropdown(
      datasetModule,
      optionId,
      value.value,
    )

    void dispatch(
      analyticsDetailsV2StatePutDatasetModulesAction({
        datasetModule: newDatasetModule,
      }),
    )
  }

  const handleOnBlur = () => {
    if (
      (inputTitleValue === datasetModule?.title &&
        inputSubTitleValue === datasetModule?.subtitle) ||
      (title && inputTitleValue.length === 0) ||
      (subTitle && inputSubTitleValue.length === 0) ||
      !datasetModule
    )
      return

    void dispatch(
      analyticsDetailsV2StatePutDatasetModulesAction({
        datasetModule: {
          ...datasetModule,
          title: inputTitleValue,
          subtitle: inputSubTitleValue,
        },
      }),
    )
  }

  function handleKeyEnterUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  return (
    <>
      {title && (
        <InputDialog
          type="text"
          value={inputTitleValue}
          onChange={(event) => setInputTitleValue(event.target.value)}
          onBlur={handleOnBlur}
          onKeyUp={handleKeyEnterUp}
        />
      )}
      {subTitle && (
        <div className="w-full opacity-50">
          <InputDialog
            type="text"
            value={inputSubTitleValue}
            onChange={(event) => setInputSubTitleValue(event.target.value)}
            onBlur={handleOnBlur}
            onKeyUp={handleKeyEnterUp}
            disabled
          />
        </div>
      )}
      {hasDataOption && (
        <>
          <GlHDivider />
          <EditMenuSection
            sectionTitle={t('EditReadershipProfil.data')}
            section={EditSectionEnum.DATAS}
            options={datasetModuleOptionsDatas}
            handleOnToggle={handleToggleSetOptionToDisplay}
            handleOnSizeDropdown={handleSizeDropdownSetOptionToDisplay}
          />
        </>
      )}
      {hasDisplayOption && (
        <>
          <GlHDivider />
          <EditMenuSection
            sectionTitle={t('EditReadershipProfil.display')}
            section={EditSectionEnum.DISPLAY}
            options={datasetModuleOptionsDatas}
            handleOnToggle={handleToggleSetOptionToDisplay}
            handleOnSizeDropdown={handleSizeDropdownSetOptionToDisplay}
          />
        </>
      )}
    </>
  )
}

export default EditMenuDatasetModules
