import React from 'react'
import styled from '@emotion/styled'
import { SetColorEnum } from '../../../../../core/enums/set-color.enum'
import { SetColorConfig } from '../../../../../core/config/SetColorConfig'
import { SetColorConfigInterface } from '../../../../../core/interface/set-color-config.interface'

const LabelStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const ValueStyle = styled.div`
  text-align: right;
  font-family: Fira Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: 0.48px;
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
  min-width: fit-content !important;
  width: ${({ widthPercent = 0 }: { widthPercent: number }) => widthPercent}%;
`
const PreviewCard = ({
  icon,
  label,
  studyObjectValue,
  compareGroupValue,
  suffix = '',
  setColor,
}: {
  icon: any
  label: any
  studyObjectValue: any
  compareGroupValue: any
  suffix?: string
  setColor: SetColorEnum
}) => {
  const addSpace = (value: number): string => {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const isMoreThan = (main: number, toCompare: number): boolean => {
    return main > toCompare
  }

  const computeMinWidth = (max: number, min: number): number => {
    return (100 / max) * min
  }

  return (
    <div
      className="flex-1 flex flex-col p-2 justify-between"
      style={{
        height: 160,
        minWidth: 220,
      }}
    >
      <div className="flex flex-row gap-4">
        {icon}
        <LabelStyle>{label}</LabelStyle>
      </div>
      <div className="flex flex-col text-right gap-1 items-end">
        <ValueStyle
          widthPercent={
            isMoreThan(studyObjectValue, compareGroupValue)
              ? 100
              : computeMinWidth(compareGroupValue, studyObjectValue)
          }
          className="w-100"
          style={{
            color: SetColorConfig.find(
              (setColorConfig: SetColorConfigInterface) =>
                setColorConfig.value === setColor,
            )?.color1,
            backgroundColor: `${
              SetColorConfig.find(
                (setColorConfig: SetColorConfigInterface) =>
                  setColorConfig.value === setColor,
              )?.color1
            }1a`,
          }}
        >
          {parseInt(studyObjectValue, 10) === studyObjectValue
            ? studyObjectValue > 999
              ? addSpace(studyObjectValue)
              : studyObjectValue
            : parseInt(`${Math.round(studyObjectValue * 100) / 100}}`, 10)}
          {suffix}
        </ValueStyle>
        <ValueStyle
          widthPercent={
            isMoreThan(compareGroupValue, studyObjectValue)
              ? 100
              : computeMinWidth(studyObjectValue, compareGroupValue)
          }
          style={{
            color: SetColorConfig.find(
              (setColorConfig: SetColorConfigInterface) =>
                setColorConfig.value === setColor,
            )?.color2,
            backgroundColor: `${
              SetColorConfig.find(
                (setColorConfig: SetColorConfigInterface) =>
                  setColorConfig.value === setColor,
              )?.color2
            }1a`,
          }}
        >
          {parseInt(compareGroupValue, 10) === compareGroupValue
            ? studyObjectValue > 999
              ? addSpace(compareGroupValue)
              : compareGroupValue
            : parseInt(`${Math.round(compareGroupValue * 100) / 100}`, 10)}
          {suffix}
        </ValueStyle>
      </div>
    </div>
  )
}

export default PreviewCard
