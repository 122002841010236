import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const fadeIn = keyframes`
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 1rem;
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    bottom: 1rem;
    opacity: 1;
  }
  95% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 0;
  }
`
const SnackBarStyle = styled.div`
  left: 33%;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  align-items: center;
  background-color: #595959;
  color: white;
  bottom: 1rem;
  animation: ${({ show }: { show: boolean }) => (show ? fadeIn : fadeOut)} 0.5s
    linear;
  z-index: 1000;
`

const ButtonStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
`
const GlSnackBarConfirm = ({
  handleValidated,
  handleCanceled,
  handleOnClose,
  needOpen = false,
  message = '',
  duration = 5000,
  children = undefined,
}: {
  message?: string
  children?: ReactElement
  handleValidated?: () => void
  handleCanceled?: () => void
  handleOnClose?: () => void
  needOpen?: boolean
  duration?: number
}) => {
  const { t } = useTranslation()
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setShowSnackbar(false)
    }, duration)
  }

  useEffect(() => {
    if (needOpen) {
      setShowSnackbar(true)
      handleTimeOut()
      handleOnClose && handleOnClose()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needOpen, timer])

  return (
    (showSnackbar && (
      <SnackBarStyle
        show={showSnackbar}
        className="flex flex-row fixed rounded-lg p-2 shadow-md gap-2"
      >
        {children || <span>{message}</span>}
        <div className="flex flex-row gap-2">
          {handleValidated && (
            <ButtonStyle
              type="button"
              className="
                flex
                flex-row
                items-center
                justify-center
                bg-[#252525]
                opacity-60
                hover:opacity-80
                active:opacity-100
                w-[68px]
                h-6
                rounded
              "
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                setShowSnackbar(false)
                handleValidated()
              }}
            >
              <span>{t('commons.buttons.yes')}</span>
            </ButtonStyle>
          )}
          {handleCanceled && (
            <ButtonStyle
              type="button"
              className="
                flex
                flex-row
                items-center
                justify-center
                bg-[#f97e60]
                opacity-60
                hover:opacity-80
                active:opacity-100
                w-[68px]
                h-6
                rounded
              "
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                setShowSnackbar(false)
                handleCanceled()
              }}
            >
              <span>{t('commons.buttons.no')}</span>
            </ButtonStyle>
          )}
        </div>
      </SnackBarStyle>
    )) ||
    null
  )
}

export default GlSnackBarConfirm
