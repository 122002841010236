import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { InterfaceRepresentationDataList } from './AddModuleView'

const SideMenuTitleStyle = styled.div`
  color: #bebebe;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  padding: 8px 0px;
`

const SideMenuTextStyle = styled.div`
  color: #e9e9e9;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Vollkorn;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background: #3b3b3b;
    border-radius: 4px;
  }
`

interface Props {
  representationDataList: InterfaceRepresentationDataList[]
  dataRepresentationSelected: InterfaceRepresentationDataList
  setDataRepresentationSelected: (
    value: InterfaceRepresentationDataList,
  ) => void
}

const AddModuleSideMenu = ({
  representationDataList,
  dataRepresentationSelected,
  setDataRepresentationSelected,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-1 p-4 self-stretch shrink-0 rounded-xl bg-[#252525] w-[302px]">
      {representationDataList.map((item: InterfaceRepresentationDataList) => {
        if (item.type === 'title') {
          return (
            <SideMenuTitleStyle key={item.id}>
              {t(item.label)}
            </SideMenuTitleStyle>
          )
        }
        return (
          <SideMenuTextStyle
            key={item.id}
            onClick={() => setDataRepresentationSelected(item)}
            style={
              dataRepresentationSelected?.id === item.id
                ? { background: '#3B3B3B' }
                : {}
            }
          >
            {t(item.label)}
          </SideMenuTextStyle>
        )
      })}
    </div>
  )
}

export default AddModuleSideMenu
