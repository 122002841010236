import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { TrendV2Interface } from '../../../../core/interface/trend-v2.interface'
import DynamicsBill from './bills/DynamicsBill'
import DynamicsResultsBookDetails from '../book-details/DynamicsResultsBookDetails'
import { AnalyticInputInterface } from '../../../../core/interface/analytics/analytic-input.interface'
import { BookDetailInterface } from '../../../../core/interface/rank/book-detail.interface'

const LayoutStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 234px);
  display: flex;
  flex-direction: column;
`

const DynamicsResultsViewModeBills = ({
  trends,
  handleBookSelected,
  bookSelected,
  isLoadingBookDetails,
  isLoadingBookDetailsWorkMirror,
  bookDetails,
  bookDetailsWorkMirror,
}: {
  trends: TrendV2Interface[]
  handleBookSelected: (book: TrendV2Interface | null) => void
  isLoadingBookDetails: boolean
  isLoadingBookDetailsWorkMirror: boolean
  bookSelected: TrendV2Interface | null
  bookDetails: BookDetailInterface | null
  bookDetailsWorkMirror: AnalyticInputInterface | null
}) => {
  return (
    <div className="relative h-full flex flex-row">
      <LayoutStyle>
        <PerfectScrollbar
          style={{
            height: 1900,
          }}
        >
          {trends.map((trend: TrendV2Interface, index: number) => (
            <DynamicsBill
              key={`dynamics-bill-${trend.id_ean}`}
              firstElement={index === 0}
              trend={trend}
              handleOnClickLine={handleBookSelected}
              bookSelected={bookSelected}
            />
          ))}
        </PerfectScrollbar>
      </LayoutStyle>
      <DynamicsResultsBookDetails
        bookDetails={bookDetails}
        bookDetailsWorkMirror={bookDetailsWorkMirror}
        handleBookSelected={handleBookSelected}
        bookSelected={bookSelected}
        hasOverlay
        isLoadingBookDetails={isLoadingBookDetails}
        isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
      />
    </div>
  )
}

export default DynamicsResultsViewModeBills
