import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'

/*
const animation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(calc(-100% + 200px));
  }
`

const scrollText = css`
  transform: translateX(280px);
  animation: ${animation} 10s linear infinite;
  // to add in component
  :hover span {
    ${({ overflowActive }: { overflowActive: boolean }) =>
            overflowActive ? scrollText : ''}
  }
`
*/

const ItemParentStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 290px;
  height: 14px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px 0 0;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 8px;
    text-align: left;
    color: #252525;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Vollkorn, serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.14px;
  }
`

const TitleV2 = ({ title }: { title: string }) => {
  return (
    <ItemParentStyle>
      <span>{title}</span>
    </ItemParentStyle>
  )
}

export default TitleV2
