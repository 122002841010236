import { ReactComponent as OpenLibraryIcon } from '../../assets/icons/providers/open-library.icon.svg'
import { ReactComponent as GoogleBookIcon } from '../../assets/icons/providers/google-book.icon.svg'
import { ReactComponent as ElectreNgIcon } from '../../assets/icons/providers/electre-ng.icon.svg'
import titelive from '../../assets/icons/providers/titelive.icon.png'

export const getProviderIconUtil = (provider: string | null): any => {
  switch (provider) {
    case 'Electre':
    case 'ElectreNG':
      return <ElectreNgIcon className="w-[76px]" />
    case 'GoogleBooks':
      return <GoogleBookIcon className="w-[106px]" />
    case 'OpenLibrary':
    case 'OpenLibraryAPI':
      return <OpenLibraryIcon className="w-[76px]" />
    case 'TiteLive':
      return <img src={titelive} alt="Icone Titelibe" className="w-[76px]" />
    default:
      return null
  }
}
