import { useState } from 'react'
import { groupBy, intersection, orderBy, uniqBy, xorBy } from 'lodash'
import { BookInterface } from '../../../../../core/interface/book.interface'
import {
  AuthorInputGroupByEnum,
  AuthorInputGroupByType,
} from '../inputs/authors/EditionsByAuthorCard'
import EditionTableHeader from './EditionTableHeader'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import EditionsTableRow from './EditionsTableRow'
import EditionsTableRowDropDown from './EditionsTableRowDropDown'
import { normalizedContains } from '../../../../../core/utils/search-text.util'

const EditionsTableSelectable = ({
  disable,
  booksSelected,
  books,
  color,
  handleUpdateBooksSelected,
  groupByAction = null,
  filterByAction = '',
}: {
  disable: boolean
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  books: BookInterface[]
  color: string
  groupByAction?: AuthorInputGroupByType
  filterByAction?: string
}) => {
  const [sortEditions, setSortEditions] = useState<{
    columnKey: 'title' | 'edition' | 'format' | 'publishing_date'
    sortDirection: SortDirectionEnum
  }>({ columnKey: 'title', sortDirection: SortDirectionEnum.ASC })
  const isAllSelected = (): 'checked' | 'empty' | 'indeterminate' => {
    const booksEans = books.map((book: BookInterface) => book.id_ean)
    const compare = intersection(
      booksEans,
      booksSelected.map((book) => book.id_ean),
    )
    if (compare.length === booksEans.length) {
      return 'checked'
    }
    if (compare.length > 0 && compare.length < booksEans.length) {
      return 'indeterminate'
    }
    return 'empty'
  }

  const groupByFromGroupByAction = () => {
    let count = 0
    let booksReturn: BookInterface[] = []
    const groups = groupBy(
      books.filter((book: BookInterface) =>
        filterByAction ? normalizedContains(book.title, filterByAction) : book,
      ),
      groupByAction as string,
    )
    if (groupByAction && groupByAction === AuthorInputGroupByEnum.SERIES) {
      const isSeries: { [key: string]: BookInterface[] } = {}
      const noSeries: { [key: string]: BookInterface[] } = {}
      books.forEach((book) => {
        if (book.is_series) {
          if (isSeries[book.series_id]) {
            isSeries[book.series_id].push(book)
          } else {
            isSeries[book.series_id] = [book]
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (noSeries[book.glid]) {
            noSeries[book.glid].push(book)
          } else {
            noSeries[book.glid] = [book]
          }
        }
      })

      Object.keys(isSeries).forEach((keyValue: string) => {
        count += isSeries[keyValue].length
        const bookMain =
          (isSeries[keyValue].find(
            (book: BookInterface) =>
              !!book.series_display_name && keyValue.endsWith(`${book.id_ean}`),
          ) as BookInterface) ||
          isSeries[keyValue].find(
            (book: BookInterface) => !!book.series_display_name,
          ) ||
          isSeries[keyValue][0]
        booksReturn.push({ ...bookMain, books: isSeries[keyValue] })
      })

      Object.keys(noSeries).forEach((keyValue: string) => {
        count += noSeries[keyValue].length
        const bookMain =
          (noSeries[keyValue].find(
            (book: BookInterface) =>
              !!book.series_display_name && keyValue.endsWith(`${book.id_ean}`),
          ) as BookInterface) ||
          noSeries[keyValue].find(
            (book: BookInterface) => !!book.series_display_name,
          ) ||
          noSeries[keyValue][0]
        booksReturn.push({ ...bookMain, books: noSeries[keyValue] })
      })
    }

    if (groupByAction && groupByAction === AuthorInputGroupByEnum.TITLE) {
      booksReturn = Object.keys(groups).map((keyValue: string) => {
        count += groups[keyValue].length
        const elementGroup = groups[keyValue]
        const booksMain = elementGroup[0]

        return {
          ...booksMain,
          books: orderBy(elementGroup, ['format', 'publishing_date']),
        }
      }) as BookInterface[]
    }

    return orderBy(
      booksReturn,
      [sortEditions.columnKey],
      [sortEditions.sortDirection === SortDirectionEnum.ASC ? 'asc' : 'desc'],
    )
  }

  const updateBooksSelected = (book: BookInterface) => {
    handleUpdateBooksSelected(xorBy(booksSelected, [book], 'id_ean'))
  }
  const handleCheckboxValueChange = (checked: boolean) => {
    if (checked)
      handleUpdateBooksSelected(uniqBy([...booksSelected, ...books], 'id_ean'))
    else
      handleUpdateBooksSelected(
        xorBy(uniqBy([...booksSelected, ...books], 'id_ean'), books, 'id_ean'),
      )
  }

  const handleCheckboxTableRowDropdownHeader = (
    checked: boolean,
    books: BookInterface[],
  ) => {
    if (checked) {
      handleUpdateBooksSelected(uniqBy([...booksSelected, ...books], 'id_ean'))
    } else {
      handleUpdateBooksSelected(
        xorBy(uniqBy([...booksSelected, ...books], 'id_ean'), books, 'id_ean'),
      )
    }
  }

  return (
    <div className="flex flex-none flex-col gap-2">
      <EditionTableHeader
        disable={disable}
        checkboxValue={isAllSelected()}
        handleCheckboxValueChange={handleCheckboxValueChange}
        sortEditions={sortEditions}
        handleSortDirection={setSortEditions}
      />
      <div className="flex flex-col gap-1">
        {(groupByAction &&
          groupByFromGroupByAction().map(
            (edition: BookInterface) =>
              (edition?.books?.length && (
                <EditionsTableRowDropDown
                  disable={disable}
                  displaySerieName={
                    groupByAction === AuthorInputGroupByEnum.SERIES
                  }
                  book={edition}
                  color={color}
                  handleUpdateBookSelected={updateBooksSelected}
                  key={`table-dropdown-row-book-${edition.id_ean}`}
                  booksSelected={booksSelected}
                  handleCheckboxTableRowDropdownHeader={
                    handleCheckboxTableRowDropdownHeader
                  }
                />
              )) || (
                <EditionsTableRow
                  disable={disable}
                  key={`table-row-book-${edition.id_ean}`}
                  book={edition}
                  color={color}
                  isChecked={booksSelected
                    .map((b: BookInterface) => b.id_ean)
                    .includes(edition.id_ean)}
                  handleOnClick={() => updateBooksSelected(edition)}
                />
              ),
          )) ||
          (sortEditions.sortDirection === SortDirectionEnum.EMPTY
            ? books
            : orderBy(
                books,
                [sortEditions.columnKey],
                [
                  sortEditions.sortDirection === SortDirectionEnum.ASC
                    ? 'asc'
                    : 'desc',
                ],
              )
          )
            .filter((book: BookInterface) =>
              filterByAction
                ? normalizedContains(book.title, filterByAction)
                : book,
            )
            .map((book: BookInterface) => (
              <EditionsTableRow
                disable={disable}
                key={`table-row-book-${book.id_ean}`}
                book={book}
                color={color}
                isChecked={booksSelected
                  .map((b: BookInterface) => b.id_ean)
                  .includes(book.id_ean)}
                handleOnClick={() => updateBooksSelected(book)}
              />
            ))}
      </div>
    </div>
  )
}

export default EditionsTableSelectable
