import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import { BookInterface } from '../../../../../../core/interface/book.interface'
import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
  StudyObjectType,
} from '../../configs/StudiesObjectsCompareGroupTypesConfig'
import GlHDivider from '../../../../../share/GlHDivider'
import { ClilDropDownUniqueInterface } from '../../../../../../core/utils/get-clil-genealogy.util'

const PerfectScrollbarStyled = styled(PerfectScrollbar)`
  .ps__rail-y {
    opacity: 1;
  }
`

const BookToolTipTitleStyle = styled.div`
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: #ffffff;
`

const BookToolTipSubTitleStyle = styled.div`
  font-family: 'Fira Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.24px;
  color: #929292;
`

const BookToolTipFirstElementStyle = styled.div`
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: #e9e9e9;
`

const BookToolTipSecondElementStyle = styled.div`
  font-family: 'Fira Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.24px;
  color: #bebebe;
`

const RecapToolTip = ({
  type,
  objectsSelected,
  menuPositionTop = false,
  mousePosX,
  mousePosY,
  author = '',
  numberObjects = 0,
}: {
  type: StudyObjectType | CompareGroupType
  objectsSelected: BookInterface[] | ClilDropDownUniqueInterface[]
  menuPositionTop?: boolean
  mousePosX: number
  mousePosY: number
  author?: string
  numberObjects?: number
}) => {
  const { t } = useTranslation()

  const getTitle = () => {
    switch (type) {
      case StudyObjectGroupCompareEnum.TITLE:
        return `${objectsSelected.length} ${t('studyTitles')}`
      case StudyObjectGroupCompareEnum.AUTHOR:
        return author
      case StudyObjectGroupCompareEnum.CLIL:
        return t('studyClil', { count: numberObjects })
      default:
        return ''
    }
  }

  return (
    <div
      className="
          flex flex-col items-start gap-2
          bg-[#252525]/90 rounded-lg	
          w-[340px] p-3 z-[1000]
          fixed"
      style={
        menuPositionTop
          ? {
              bottom: `${window.innerHeight - mousePosY + 20}px`,
              left: `${mousePosX}px`,
            }
          : {
              top: `${mousePosY}px`,
              left: `${mousePosX}px`,
            }
      }
    >
      <div className="flex flex-col gap-1">
        <BookToolTipTitleStyle>{getTitle()}</BookToolTipTitleStyle>
        {type === StudyObjectGroupCompareEnum.AUTHOR && (
          <BookToolTipSubTitleStyle>
            {objectsSelected.length} {t('studyTitles')}
          </BookToolTipSubTitleStyle>
        )}
      </div>
      <GlHDivider color="#FFFFFF" />

      {type === StudyObjectGroupCompareEnum.CLIL ? (
        (objectsSelected as ClilDropDownUniqueInterface[])
          .slice(0, 40)
          .map((clil: ClilDropDownUniqueInterface) => (
            <div
              key={`tag-input-title-${clil.key}`}
              className="flex items-baseline flex-wrap self-stretch gap-2"
              style={
                clil.level === 2
                  ? { marginLeft: '8px' }
                  : clil.level === 3
                  ? { marginLeft: '16px' }
                  : {}
              }
            >
              <BookToolTipFirstElementStyle>
                {clil.label}
              </BookToolTipFirstElementStyle>
              <BookToolTipSecondElementStyle>
                {t('level')} {clil.level}
              </BookToolTipSecondElementStyle>
              <BookToolTipSubTitleStyle>{clil.id}</BookToolTipSubTitleStyle>
            </div>
          ))
      ) : (
        <PerfectScrollbarStyled className="max-h-[400px]">
          {(objectsSelected as BookInterface[]).map(
            (bookInput: BookInterface) => (
              <div
                key={`tag-input-title-${bookInput.id_ean}`}
                className="flex items-baseline flex-wrap self-stretch gap-2"
              >
                <BookToolTipFirstElementStyle>
                  {bookInput.title}
                </BookToolTipFirstElementStyle>
                <BookToolTipSecondElementStyle>
                  {bookInput.authors}
                </BookToolTipSecondElementStyle>
                <BookToolTipSubTitleStyle>
                  {bookInput.edition}
                </BookToolTipSubTitleStyle>
                <BookToolTipSubTitleStyle>
                  {dayjs(bookInput.publishing_date).format('DD/MM/YYYY')}
                </BookToolTipSubTitleStyle>
              </div>
            ),
          )}
        </PerfectScrollbarStyled>
      )}
    </div>
  )
}

export default RecapToolTip
