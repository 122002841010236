import React, { useEffect, useRef } from 'react'

// TODO Found better
const useClickOutsideHook = (callback: any) => {
  const ref = useRef(null)
  // TODO see that
  useEffect(() => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (ref.current && !ref.current.contains(event.target)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        callback(event)
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener('click', handleClick)
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('click', handleClick)
    }
  }, [])
  return ref
}

export default useClickOutsideHook
