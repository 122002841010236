import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GlMatInput from '../../components/share/inputs/GlMatInput'
import GlHDivider from '../../components/share/GlHDivider'
import GlInputCheckBox from '../../components/share/inputs/GlInputCheckBox'
import { ReactComponent as LockIcon } from '../../assets/icons/app/lock.icon.svg'
import { ReactComponent as VisibilityIcon } from '../../assets/icons/app/visibility.icon.svg'
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/app/visibility-off.icon.svg'
import GlPrefixIconButton from '../../components/share/buttons/GlPrefixIconButton'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  invitationGetOnBoardingInvitationAction,
  invitationPostOnBoardingInvitationAction,
} from '../../store/actions/invitation.actions'
import {
  selectInvitationEmail,
  selectInvitationErrorInfoInvitationToken,
  selectInvitationHasError,
  selectInvitationHasErrorPostInvitationPassword,
  selectInvitationIsLoadingMain,
  selectInvitationIsLoadingPost,
} from '../../store/getters/invitation.getters'
import GlLoader from '../../components/share/GlLoader'
import GlMdRoundedButton from '../../components/share/buttons/GlMdRoundedButton'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  getErrorMessagePasswordIsNotValid,
  passwordIsValidByRulesUtil,
  passwordIsValidUtil,
} from '../../core/utils/password.utils'
import { reinitializeInvitationState } from '../../store/reducers/invitation.reducer'

const TitleStyle = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
  width: 100%;
`

const CommentStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.05em;
  color: #000000;
`

const HintStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #b2b2b2;
  margin: 16px 0;
`

const LinkStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;

  &:hover {
    color: #252525;
  }
`

const InvitationView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { invitationToken } = useParams<{ invitationToken: string }>()

  const { t } = useTranslation()
  const [passwordChange, setPasswordChange] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false)

  const [passwordRepeatChange, setPasswordRepeatChange] =
    useState<boolean>(false)
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [passwordRepeatVisibility, setPasswordRepeatVisibility] =
    useState<boolean>(false)

  const [acceptEula, setAcceptEula] = useState<boolean>(false)

  const email = useAppSelector(selectInvitationEmail)
  const isLoadingMain = useAppSelector(selectInvitationIsLoadingMain)
  const isLoadingPost = useAppSelector(selectInvitationIsLoadingPost)
  const hasError = useAppSelector(selectInvitationHasError)
  const errorInfo = useAppSelector(selectInvitationErrorInfoInvitationToken)
  const hasErrorPostInvitationPassword = useAppSelector(
    selectInvitationHasErrorPostInvitationPassword,
  )

  useEffect(() => {
    invitationToken &&
      void dispatch(
        invitationGetOnBoardingInvitationAction({ invitationToken }),
      )
    return () => {
      dispatch(reinitializeInvitationState())
    }
  }, [invitationToken])

  useEffect(() => {
    if (hasErrorPostInvitationPassword) {
      void dispatch(setShowSnackBar(t('snackbarMessage.checkPasswordError')))
    }
  }, [hasError, errorInfo, hasErrorPostInvitationPassword])

  const isValidForm = (): boolean => {
    return (
      acceptEula &&
      !!password &&
      !!passwordRepeat &&
      password === passwordRepeat
    )
  }

  const handleOnSubmit = () => {
    const postData = {
      password,
      confirm_password: passwordRepeat,
      accept_eula: acceptEula,
    }

    void dispatch(
      invitationPostOnBoardingInvitationAction({
        invitationToken: invitationToken as string,
        postData,
      }),
    )
  }

  if (isLoadingMain) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <GlLoader />
      </div>
    )
  }

  const getTitleErrorMessageByCode = (value: any) => {
    switch (value?.code) {
      case 'not_found':
        return t('commons.errorMessageCode.linkNotFound')

      case 'user_is_active':
        dispatch(setShowSnackBar(t('invitationAlreadyActivated')))
        navigate(buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN]))
        break
      case 'token_expired':
        return t('invitationTokenExpired')
      case 'user_deactivated':
        return value.detail
      default:
        return t('snackbarMessage.simpleErrorMessage')
    }
  }
  // TODO trad
  const getContentErrorMessageByCode = (value: any) => {
    switch (value?.code) {
      case 'token_expired':
        return `
          <p>Pour avoir accès à Bookmétrie, vous pouvez nous écrire à l'adresse
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a><br />
          </p>
          <p>
            Pensez à bien indiqué l'adresse mail du compte utilisateur lié à l'invitation
          </p>
        `
      case 'not_found':
      case 'user_does_not_exist':
        return `
          <p>Vous pouvez nous contacter à cette adresse: 
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a><br />
          </p>
          <p>
            Pensez à bien indiqué l'adresse mail du compte utilisateur lié à l'invitation
         </p>
        `
      case 'user_deactivated':
        return `
 
          <p>Votre compte est actuellement désactivé.</p>
          <p>Pour avoir accès à Bookmétrie, vous pouvez nous écrire à l'adresse 
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a><br />
          </p>
          <p>
            Pensez à bien indiqué l'adresse mail du compte utilisateur lié à l'invitation
         </p>
        `
      default:
        return `
          <p>Veuillez recharger la page.</p>
          <p>Si le probleme persiste, vous pouvez nous contacter à cette adresse: 
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a><br />
          </p>
          <p>
            Pensez à bien spécifier en objet du mail et à la détailler autant que nécessaire en corps de mail.
            <br />
            L'ajout de captures d'écran dans certains cas peut aider à la bonne compréhension de votre demande.
          </p>
          `
    }
  }

  if (hasError) {
    return (
      <div className="flex flex-col bg-white items-center justify-center p-4 gap-4">
        <div className="flex flex-col items-center justify-center">
          <TitleStyle>{getTitleErrorMessageByCode(errorInfo)}</TitleStyle>
        </div>
        <GlHDivider />
        <div
          className="flex flex-col w-full"
          dangerouslySetInnerHTML={{
            __html: getContentErrorMessageByCode(errorInfo),
          }}
        />
        <GlHDivider />
        <div className="gap-4 flex flex-col">
          <div className="flex flex-row justify-center items-center gap-2">
            <Link
              className="text-center"
              to={buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN])}
            >
              <LinkStyle>{t('webApp.forgotPassword.signIn')}</LinkStyle>
            </Link>
            <span>/</span>
            <Link
              className="text-center"
              to={buildRelativePath([
                PathConfig.AUTH,
                PathConfig.FORGOT_PASSWORD,
              ])}
            >
              <LinkStyle>{t('webApp.signIn.forgotPassword')}</LinkStyle>
            </Link>
          </div>

          <GlMdRoundedButton
            label={t('commons.buttons.contactUs')}
            handleOnClick={() => {
              window.location.href = 'mailto:info@gleeph.pro'
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <form className="z-10 w-[516px]">
      <div className="flex flex-col bg-white items-center justify-center p-4 gap-4">
        <div className="flex flex-col items-center justify-center pb-4">
          <TitleStyle>{t('authentication.invitation.title1')}</TitleStyle>
          <TitleStyle>{t('authentication.invitation.title2')}</TitleStyle>
        </div>
        <GlHDivider />
        <div className="flex flex-col w-full">
          <GlMatInput
            label={t('commons.inputs.email.label')}
            readOnly
            placeholder={t('commons.inputs.email.placeholder')}
            keyValue="email"
            type="email"
            value={email}
            prefix={<LockIcon className="w-4 h-4" />}
          />

          <GlMatInput
            hasError={
              passwordChange &&
              (!password ||
                !passwordIsValidUtil(passwordIsValidByRulesUtil(password)))
            }
            hasErrorMessage={
              (!password && t('commons.errorMessageCode.fieldRequired')) ||
              getErrorMessagePasswordIsNotValid(
                passwordIsValidByRulesUtil(password),
              )
            }
            label={t('commons.inputs.password.label')}
            placeholder={t('commons.inputs.password.placeholder')}
            keyValue="new-password"
            type={(passwordVisibility && 'text') || 'password'}
            value={password}
            onChangeValue={(value: string) => {
              setPasswordChange(true)
              setPassword(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            }
          />

          <GlMatInput
            hasError={
              passwordRepeatChange &&
              (!passwordRepeat ||
                (!!password && !!passwordRepeat && password !== passwordRepeat))
            }
            hasErrorMessage={
              (!passwordRepeat &&
                t('commons.errorMessageCode.fieldRequired')) ||
              t('commons.errorMessageCode.passwordIsNotSame')
            }
            label={t('commons.inputs.confirmPassword.label')}
            placeholder={t('commons.inputs.confirmPassword.placeholder')}
            keyValue="new-password-repeat"
            type={(passwordRepeatVisibility && 'text') || 'password'}
            value={passwordRepeat}
            onChangeValue={(value: string) => {
              setPasswordRepeatChange(true)
              setPasswordRepeat(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordRepeatVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() =>
                  setPasswordRepeatVisibility(!passwordRepeatVisibility)
                }
              />
            }
          />
          <HintStyle>{t('authentication.invitation.passwordRules')}</HintStyle>
        </div>
        <CommentStyle
          dangerouslySetInnerHTML={{
            __html: `${t('authentication.invitation.information')}`,
          }}
        />

        <GlHDivider />
        <GlInputCheckBox
          value={(acceptEula && 'checked') || 'empty'}
          onChangeEvent={(event) => {
            setAcceptEula(event.target.checked)
          }}
        >
          <CommentStyle
            dangerouslySetInnerHTML={{
              __html: `${t('authentication.invitation.cguComment')}`,
            }}
          />
        </GlInputCheckBox>
        <GlMdRoundedButton
          label={t('commons.buttons.activateAccount')}
          hasLoader
          isLoading={isLoadingPost}
          disabled={
            !isValidForm() ||
            hasError ||
            !passwordIsValidUtil(passwordIsValidByRulesUtil(password))
          }
          handleOnClick={handleOnSubmit}
        />
      </div>
    </form>
  )
}

export default InvitationView
