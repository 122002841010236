import { AnalyticOutputTypeEnum } from '../../enums/analytic-output-type.enum'
import { WorkMirrorBookInterface } from '../report/work-mirror-book.interface'
import { ReadershipProfilePreviewInterface } from './readership-profile-preview.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../enums/analytic-output-layout-size.enum'
import {
  AnalyticLayoutSizeConfigInterface,
  DatasetModuleInterface,
} from './dataset-module.interface'
import { DatasetInterface } from './dataset.interface'
import { DatasetTypeEnum } from '../../enums/dataset-type.enum'
import { AnalyticOutputTextSizeEnum } from '../../enums/analytic-output-text-size.enum'
import { AnalyticOutputImageModeEnum } from '../../enums/analytic-output-image-mode.enum'
import { BookInterface } from '../book.interface'
import { AuthorInterface } from '../author.interface'
import { StudyObjectGroupCompareEnum } from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'

export interface DatasetOutputInterface {
  data_type: DatasetTypeEnum
  current_level: number[]
  dataset: DatasetInterface[]
  dataset_modules: DatasetModuleInterface[]
  max_level: number
}

export interface AnalyticOutputInterface {
  id: string
  type: AnalyticOutputTypeEnum
  layout_size?: AnalyticOutputLayoutSizeEnum
  layout_size_config?: AnalyticLayoutSizeConfigInterface
  display?: AnalyticOutputLayoutSizeEnum
  work_mirror_books?: WorkMirrorBookInterface[]
  title?: AnalyticOutputTitleInterface
  image?: AnalyticOutputImageInterface
  textbox?: AnalyticOutputTextBoxInterface
  study_object?: AnalyticOutputStudyObjectInterface
  readership_profile_preview?: ReadershipProfilePreviewInterface
  dataset_output?: DatasetOutputInterface
  context?: string
  audience_output_file?: { csv_file: string }
}

export interface AnalyticOutputTextBoxInterface {
  title: string
  text: string
  use_dark_theme: boolean
  text_size: AnalyticOutputTextSizeEnum
}

export enum AnalyticOutputImageCombinationEnum {
  landscape_4 = 'landscape_4',
  landscape_6 = 'landscape_6',
  landscape_8 = 'landscape_8',
  landscape_12 = 'landscape_12',
  portrait_4 = 'portrait_4',
  square_4 = 'square_4',
  square_6 = 'square_6',
}

export type AnalyticOutputImageCombinationInterface = {
  [key in AnalyticOutputImageCombinationEnum]: string
}

export interface AnalyticOutputImageInterface
  extends AnalyticOutputImageCombinationInterface {
  card: string
  image_mode: AnalyticOutputImageModeEnum
}

export interface AnalyticOutputTitleInterface {
  level: number
  text: string
}

export interface AnalyticOutputStudyObjectInterface {
  comparison_type: StudyObjectGroupCompareEnum
  subject_type: StudyObjectGroupCompareEnum
  subject_objects: (BookInterface | AuthorInterface | { clil: string })[]
  comparison_objects: (BookInterface | AuthorInterface | { clil: string })[]
}
