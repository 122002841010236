import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { AnalyticVisualThemeEnum } from '../../../../enums/analytic-visual-theme.enum'

export const putVisualTheme = (
  reportId: string,
  inputId: string,
  visualTheme: { visual_theme: AnalyticVisualThemeEnum },
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.REPORT_BY_ID_INPUT_BY_ID_READERSHIP_PROFILE(
      reportId,
      inputId,
    ),
    visualTheme,
    {
      cancelToken,
    },
  )
