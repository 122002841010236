import React, { RefObject, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import GlHDivider from '../share/GlHDivider'

const TitleStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #252525;
`

const DescriptionStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
  & > p {
    margin: 16px 0 !important;
  }
`

const RoundedButton = styled.button`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-radius: 20px;
  text-transform: uppercase;
  border: 1px solid #252525;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #ffffff;
  background-color: #252525;
  margin: 0 auto 2px auto;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }
`

function useLinkClickHandlers(ref: RefObject<HTMLDivElement>) {
  const handleLinkClick = (event: MouseEvent) => {
    const link = event.currentTarget as HTMLAnchorElement
    const href = link.getAttribute('href') as string
    window.open(href, '_blank')
  }

  useEffect(() => {
    if (!ref.current) return

    const links = ref.current.querySelectorAll('a')
    links.forEach((link) => link.addEventListener('click', handleLinkClick))

    return () => {
      links.forEach((link) =>
        link.removeEventListener('click', handleLinkClick),
      )
    }
  }, [ref])
}

const SettingContactCard = ({
  title,
  mainText,
  buttonText,
  onClickButton,
}: {
  title: string
  mainText: string
  buttonText: string
  onClickButton: () => void
}) => {
  const refContent = useRef<HTMLDivElement>(null)

  useLinkClickHandlers(refContent)

  return (
    <div
      className="flex flex-col gap-4"
      style={{
        width: 408,
      }}
    >
      <TitleStyle>{title}</TitleStyle>
      <GlHDivider />
      <DescriptionStyle
        ref={refContent}
        dangerouslySetInnerHTML={{ __html: mainText }}
      />
      <GlHDivider />
      <RoundedButton onClick={onClickButton}>
        <span>{buttonText}</span>
      </RoundedButton>
    </div>
  )
}

export default SettingContactCard
