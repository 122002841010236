import React, { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectWebAppStateAccessToken,
  selectWebAppStateRefreshToken,
} from '../../store/getters/web-app.getters'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'

interface Props {
  children: ReactElement
}
const NonAuthenticatedRoute = ({ children }: Props): ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(selectWebAppStateAccessToken)
  const refreshToken = useAppSelector(selectWebAppStateRefreshToken)
  const checkUserToken = () => {
    if (accessToken && refreshToken) {
      dispatch(setShowSnackBar(t('authentication.sign-in.success')))
      return navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    }
  }
  useEffect(checkUserToken, [accessToken, refreshToken])

  return children
}
export default NonAuthenticatedRoute
