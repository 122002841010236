import { memo, useCallback, useMemo } from 'react'
import {
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { AxisDomain } from 'recharts/types/util/types'
import { DatasetInterface } from '../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetTypeEnum } from '../../../../../../../core/enums/dataset-type.enum'
import { clilReferencesConfig } from '../../../../../../../core/config/clil-references.config'
import ScatterXAxisLabel from './scatterChart/ScatterXAxisLabel'
import ScatterYAxisLabel from './scatterChart/ScatterYAxisLabel'
import ScatterTooltip from './tooltip/ScatterTooltip'
import { ValueConfigurationsInterface } from '../../../../../../../core/interface/analytics/value-configurations.interface'
import { ValueFormatEnum } from '../../../../../../../core/interface/analytics/value-format.enum'
import { SourceEnum } from '../../../../../../../core/interface/analytics/source.enum'

interface Props {
  dataset: DatasetInterface[]
  typeDataset: DatasetTypeEnum
  xAxis: ValueConfigurationsInterface
  yAxis: ValueConfigurationsInterface
  zAxis?: ValueConfigurationsInterface
  hasClil: boolean
}

const ScatterRecharts = ({
  dataset,
  typeDataset,
  xAxis,
  yAxis,
  zAxis,
  hasClil,
}: Props) => {
  const { t } = useTranslation()

  const dateAxis =
    (xAxis?.value_format === ValueFormatEnum.DATE && xAxis) ||
    (yAxis?.value_format === ValueFormatEnum.DATE && yAxis) ||
    null

  const data = useMemo(
    () =>
      dataset
        .filter(
          (item) =>
            item[xAxis.source] !== null &&
            item[yAxis.source] !== null &&
            ((zAxis && item[zAxis.source] !== null) || true),
        )
        .map((item) => ({
          ...item,
          ...{
            [yAxis?.source]: Math.round((item[yAxis?.source] as number) || 0),
          },
          ...(dateAxis && {
            [dateAxis.source]: Date.parse(item[dateAxis.source] as string),
          }),
        })),
    [dataset],
  )

  const CustomizedLabel = useMemo(() => {
    return ({ x, y, width, value }: any) => (
      <text
        style={{
          pointerEvents: 'none',
          fill: typeDataset === DatasetTypeEnum.WORKS_MIRROR ? '#000' : '#fff',
        }}
        x={Number(x) + width / 2}
        y={Number(y) + width / 2}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    )
  }, [typeDataset])

  const yearRangeTs = useMemo(() => {
    return (source: SourceEnum) => {
      data.sort((a, b) => (a[source] as number) - (b[source] as number))

      const minYear = new Date(data[0][source] as number).getFullYear()
      const maxYear = new Date(
        data[data.length - 1][source] as number,
      ).getFullYear()

      return Array.from(
        { length: maxYear - minYear + 1 },
        (_, i) => minYear + i,
      ).map((date) => Date.parse(date.toString()))
    }
  }, [data])

  const scoreRange = useMemo(() => {
    return (source: SourceEnum): AxisDomain => {
      const scores = data.map((item) => item[source] as number).sort()

      const minScore = scores[0]
      let minScoreRounded = minScore
      if (minScore % 10 > 2) {
        minScoreRounded -= minScoreRounded % 10
      } else {
        minScoreRounded = minScoreRounded - (minScoreRounded % 10) - 10
      }

      const maxScore = scores[scores.length - 1]
      const maxScoreRounded = Math.ceil(maxScore / 10) * 10

      return [
        typeDataset === DatasetTypeEnum.OVERREPRESENTED
          ? minScoreRounded - Math.round((minScoreRounded * 0.01) / 10) * 10
          : minScoreRounded,
        typeDataset === DatasetTypeEnum.OVERREPRESENTED
          ? maxScoreRounded + Math.round((maxScoreRounded * 0.01) / 10) * 10
          : Math.min(maxScoreRounded, 100),
      ]
    }
  }, [])

  const getAxisDomain = useMemo(() => {
    return (axis: ValueConfigurationsInterface) => {
      return [
        ValueFormatEnum.INTEGER,
        ValueFormatEnum.PERCENT,
        ValueFormatEnum.FLOAT,
      ].includes(axis.value_format)
        ? scoreRange(axis.source)
        : ['auto', 'auto']
    }
  }, [])

  const CustomTooltip = useCallback((props: any) => {
    return <ScatterTooltip typeDataset={typeDataset} {...props} />
  }, [])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        margin={{
          top: 10,
        }}
      >
        <CartesianGrid opacity={0.5} />
        <XAxis
          dataKey={xAxis.source}
          domain={getAxisDomain(xAxis)}
          name={t(xAxis.source)}
          tickFormatter={(tick) =>
            xAxis?.value_format === ValueFormatEnum.DATE
              ? format(new Date(tick), 'yyyy')
              : tick
          }
          type="number"
          ticks={
            xAxis?.value_format === ValueFormatEnum.DATE
              ? yearRangeTs(xAxis.source)
              : undefined
          }
          dy={10}
        >
          <Label
            value={t(`axisLabel.${xAxis.source}`)}
            content={ScatterXAxisLabel}
          />
        </XAxis>
        <YAxis
          type="number"
          domain={getAxisDomain(yAxis)}
          tickFormatter={(tick) =>
            yAxis?.value_format === ValueFormatEnum.DATE
              ? format(new Date(tick), 'yyyy')
              : tick
          }
          dataKey={yAxis.source}
          name={t(yAxis.source)}
          ticks={
            yAxis.value_format === ValueFormatEnum.DATE
              ? yearRangeTs(yAxis.source)
              : undefined
          }
          dx={-5}
        >
          <Label
            value={t(`axisLabel.${yAxis.source}`)}
            content={ScatterYAxisLabel}
          />
        </YAxis>
        {(zAxis && (
          <ZAxis
            type="number"
            dataKey={zAxis.source}
            name={t(zAxis.source)}
            range={[200, 2000]}
          />
        )) || <ZAxis range={[100, 101]} />}
        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip} />
        <Scatter data={data}>
          {data.map((entry) => (
            <Cell
              key={`cell-${entry.rank}`}
              fill={
                hasClil
                  ? `${
                      clilReferencesConfig[`${entry.clil1 || 999999}`].color
                    }CC`
                  : '#252525CC'
              }
            />
          ))}
          {zAxis && <LabelList dataKey="rank" content={CustomizedLabel} />}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default memo(ScatterRecharts)
