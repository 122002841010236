import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnalyticOutputStudyObjectInterface } from '../../../../../core/interface/analytics/analytic-output.interface'
import { SetColorEnum } from '../../../../../core/enums/set-color.enum'
import { getColors } from './chart/getColors'
import StudyObjectGroup from './studyObject/StudyObjectGroup'
import { SourceEnum } from '../../../../../core/interface/analytics/source.enum'
import GlVDivider from '../../../../share/GlVDivider'
import { StudyObjectGroupCompareEnum } from '../configs/StudiesObjectsCompareGroupTypesConfig'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import { BookInterface } from '../../../../../core/interface/book.interface'
import { AuthorInterface } from '../../../../../core/interface/author.interface'
import GlTrashButton from '../../../../share/GlTrashButton'
import { clilChildrenMapping } from '../../../../../core/utils/get-clil-level.utils'

export const StudyObjectTitleStyle = styled.div`
  color: #252525;
  font-family: 'Vollkorn', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`

const AnalyticsOutputStudyObject = ({
  outputId,
  outputStudyObject,
  setColor,
  handleAnalyticsDeleteOutput,
  eanSelected,
  handleOpenBookDetails,
}: {
  outputId: string
  outputStudyObject: AnalyticOutputStudyObjectInterface
  setColor: SetColorEnum
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  eanSelected: number | null
  handleOpenBookDetails: (eanId: number | null, showBookDetail: boolean) => void
}) => {
  const { t } = useTranslation()

  const [isHovering, setIsHovering] = useState(false)
  const [currentStudySelected, setCurrentStudySelected] = useState<
    number | null
  >(null)
  const [currentComparisonSelected, setCurrentComparisonSelected] = useState<
    number | null
  >(null)

  useEffect(() => {
    if (outputStudyObject.subject_objects.length === 1) {
      setCurrentStudySelected(0)
    }
    if (
      outputStudyObject.comparison_objects.length === 1 &&
      !(
        outputStudyObject.comparison_type ===
          StudyObjectGroupCompareEnum.CLIL &&
        !clilChildrenMapping[
          (outputStudyObject.comparison_objects[0] as { clil: string }).clil
        ]
      )
    ) {
      setCurrentComparisonSelected(0)
    }
  }, [outputStudyObject])

  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleClickStudy = (index: number) => {
    currentStudySelected === index
      ? setCurrentStudySelected(null)
      : setCurrentStudySelected(index)
  }

  const handleClickComparison = (index: number) => {
    currentComparisonSelected === index
      ? setCurrentComparisonSelected(null)
      : setCurrentComparisonSelected(index)
  }

  function handleDelete() {
    if (handleAnalyticsDeleteOutput) {
      handleAnalyticsDeleteOutput(
        AnalyticOutputTypeEnum.READERSHIP_PROFILE_STUDY_OBJECT,
        outputId,
      )
    }
  }

  const colorMapping = getColors(setColor)

  const getStudyObjectId = (
    type: StudyObjectGroupCompareEnum,
    data: BookInterface | AuthorInterface | { clil: string },
  ) => {
    switch (type) {
      case StudyObjectGroupCompareEnum.TITLE:
        return (data as BookInterface).glid
      case StudyObjectGroupCompareEnum.AUTHOR:
        return (data as AuthorInterface).id
      case StudyObjectGroupCompareEnum.CLIL:
        return (data as { clil: string }).clil
      default:
        return ''
    }
  }

  return (
    <div
      className="grid-item flex flex-col rounded-xl gap-4 self-start p-4 relative border border-[#E9E9E9] w-full"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      id={outputId}
    >
      <div className="flex items-center justify-between">
        <StudyObjectTitleStyle>{t('studyTitleObject')}</StudyObjectTitleStyle>
        {isHovering && <GlTrashButton handleClick={handleDelete} />}
      </div>
      <div className="flex flex-1 gap-2">
        <PerfectScrollbar
          style={{ maxHeight: '240px' }}
          className="flex flex-col flex-1 gap-2"
        >
          {outputStudyObject.subject_objects.map((subjectObject, index) => (
            <StudyObjectGroup
              key={getStudyObjectId(
                outputStudyObject.subject_type,
                subjectObject,
              )}
              data={subjectObject}
              color={colorMapping[SourceEnum.SUBJECT_OBJECT_VALUE]}
              studyObjectType={outputStudyObject.subject_type}
              isDropdownOpen={index === currentStudySelected}
              handleClickDropdown={() => handleClickStudy(index)}
              eanSelected={eanSelected}
              handleOpenBookDetails={handleOpenBookDetails}
            />
          ))}
        </PerfectScrollbar>
        <GlVDivider height="240" />
        <PerfectScrollbar
          style={{ maxHeight: '240px' }}
          className="flex flex-col flex-1 gap-2"
        >
          {outputStudyObject.comparison_objects.map(
            (comparisonObject, index) => (
              <StudyObjectGroup
                key={getStudyObjectId(
                  outputStudyObject.comparison_type,
                  comparisonObject,
                )}
                data={comparisonObject}
                color={colorMapping[SourceEnum.COMPARE_GROUP_VALUE]}
                studyObjectType={outputStudyObject.comparison_type}
                isDropdownOpen={index === currentComparisonSelected}
                handleClickDropdown={() => handleClickComparison(index)}
                eanSelected={eanSelected}
                handleOpenBookDetails={handleOpenBookDetails}
                disableDropdown={
                  outputStudyObject.comparison_type ===
                    StudyObjectGroupCompareEnum.CLIL &&
                  !clilChildrenMapping[
                    (comparisonObject as { clil: string }).clil
                  ]
                }
              />
            ),
          )}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default AnalyticsOutputStudyObject
