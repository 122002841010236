import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PersonInterface } from '../../../../core/enums/person.interface'
import { PermissionEnum } from '../../../../core/enums/permission.enum'
import { patchUserPermissionToShareReportService } from '../../../../core/api/services/bookmetrie/share/patch-user-permission-to-share-report.service'
import { dashboardGetLastPrivateReportsAction } from '../../../../store/actions/dashboard.actions'
import { setShowSnackBar } from '../../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../../store/hooks'
import { putPermissionReportToUserService } from '../../../../core/api/services/bookmetrie/share/put-permission-report-to-user.service'
import { deleteUserToShareReportService } from '../../../../core/api/services/bookmetrie/share/delete-user-to-share-report.service'
import { patchReportChangeOwnerService } from '../../../../core/api/services/bookmetrie/share/patch-report-change-owner.service'
import { TeamInterface } from '../../../../core/interface/team.interface'
import { UserPermissionInterface } from '../../../../core/interface/user-permission.interface'
import { getReportByIdUsersService } from '../../../../core/api/services/bookmetrie/analytics/get-report-by-id-users.service'
import { getUsersService } from '../../../../core/api/services/bookmetrie/get-users.service'
import { getTeamsService } from '../../../../core/api/services/bookmetrie/get-teams.service'

interface UseAnalyticsDashboardShareDialogState {
  isLoading: boolean
  users: any[]
  teams: any[]
  usersShare: any[]
}

interface UseAnalyticsDashboardShareDialogHook {
  state: UseAnalyticsDashboardShareDialogState
  actions: {
    addUserTeamsToReport(
      analyticId: string,
      users: PersonInterface[],
      permission: PermissionEnum,
      message: string,
    ): void
    updatePermissionToUser(
      reportId: string,
      user: PersonInterface,
      permission: PermissionEnum,
    ): void
    handleChangeOwner({
      reportId,
      ownerId,
    }: {
      reportId: string
      ownerId: string
    }): void
    deleteUserToShareReport(reportId: string, user: PersonInterface): void
    getReportUsersShare(reportId: string): void
    getAllUsersFromCompany(): void
    getAllTeamsFromCompany(): void
  }
}

const useAnalyticsDashboardShareDialogHook = ({
  callbackAddUserSuccess,
}: {
  callbackAddUserSuccess?: () => void
}): UseAnalyticsDashboardShareDialogHook => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<PersonInterface[]>([])
  const [teams, setTeams] = useState<TeamInterface[]>([])
  const [usersShare, setUsersShare] = useState<UserPermissionInterface[]>([])

  const getReportUsersShare = (reportId: string) => {
    setIsLoading(true)
    getReportByIdUsersService(reportId)
      .then((response) => {
        const { data } = response
        setUsersShare(data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const addUserTeamsToReport = (
    reportId: string,
    users: PersonInterface[],
    permission: PermissionEnum,
    message: string,
  ) => {
    setIsLoading(true)
    patchUserPermissionToShareReportService(reportId, {
      users: users.map((user: PersonInterface) => user.id),
      permission,
      message,
    })
      .then(() => {
        dispatch(setShowSnackBar(t('webApp.reports.confirmAddShareUser')))
        if (callbackAddUserSuccess) {
          callbackAddUserSuccess()
        }
        getReportUsersShare(reportId)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.simpleErrorMessage')))
      })
      .finally(() => setIsLoading(false))
  }

  const updatePermissionToUser = (
    reportId: string,
    user: PersonInterface,
    permission: PermissionEnum,
  ) => {
    setIsLoading(true)
    putPermissionReportToUserService(reportId, user.id, permission)
      .then(() => {
        void dispatch(dashboardGetLastPrivateReportsAction())
        void dispatch(
          setShowSnackBar(t('webApp.reports.confirmChangeRightUser')),
        )
        getReportUsersShare(reportId)
      })
      .catch(() => {
        void dispatch(setShowSnackBar(t('snackbarMessage.simpleErrorMessage')))
        setIsLoading(false)
      })
  }

  const deleteUserToShareReport = (reportId: string, user: PersonInterface) => {
    setIsLoading(true)
    deleteUserToShareReportService(reportId, user.id)
      .then(() => {
        void dispatch(dashboardGetLastPrivateReportsAction())
        dispatch(setShowSnackBar(t('webApp.reports.confirmDeleteUser')))
        getReportUsersShare(reportId)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.simpleErrorMessage')))
        setIsLoading(false)
      })
  }

  const handleChangeOwner = ({
    reportId,
    ownerId,
  }: {
    reportId: string
    ownerId: string
  }) => {
    setIsLoading(true)
    patchReportChangeOwnerService({
      reportId,
      ownerId,
    })
      .then(() => {
        void dispatch(dashboardGetLastPrivateReportsAction())
        dispatch(setShowSnackBar(t('webApp.reports.confirmChangeOwner')))
        getReportUsersShare(reportId)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.simpleErrorMessage')))
        setIsLoading(false)
      })
  }

  const getAllUsersFromCompany = () => {
    getUsersService()
      .then((response) => {
        const { data } = response
        setUsers(data)
      })
      .catch(() => setUsers([]))
  }

  const getAllTeamsFromCompany = () => {
    getTeamsService()
      .then((response) => {
        const { data } = response
        setTeams(data)
      })
      .catch(() => setTeams([]))
  }

  return {
    state: {
      isLoading,
      users,
      teams,
      usersShare,
    },
    actions: {
      addUserTeamsToReport,
      updatePermissionToUser,
      handleChangeOwner,
      deleteUserToShareReport,
      getReportUsersShare,
      getAllUsersFromCompany,
      getAllTeamsFromCompany,
    },
  }
}

export default useAnalyticsDashboardShareDialogHook
