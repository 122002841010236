import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_EDITOR,
  FILTER_KEY_FORMAT,
  FILTER_KEY_PUBLISHING_DATE,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
} from '../../../../core/config/dynamics/filters-items-dynamics.config'
import GlFilterButtonV2 from '../../../share/buttons/GlFilterButtonV2'
import { ReactComponent as AuthorIcon } from '../../../../assets/icons/app/author.icon.svg'
import { ReactComponent as EditorIcon } from '../../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as FormatIcon } from '../../../../assets/icons/app/format.icon.svg'
import { ReactComponent as ProgressIcon } from '../../../../assets/icons/app/progress.icon.svg'
import { ReactComponent as DateIcon } from '../../../../assets/icons/app/date.icon.svg'
import { getIconProgress } from '../../../../core/enums/progress.enum'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import { DateFilterTypeEnum } from '../../../../core/enums/date-filter-type.enum'

const DynamicsResultsFilterActionTag = ({
  columnKey,
  filtersActions,
  handleRemoveItemFilter,
}: {
  columnKey: string
  filtersActions: {
    [key: string]: any
  }
  handleRemoveItemFilter: (columnKey: string, value: string) => void
}) => {
  const { t } = useTranslation()
  const getPublishingDateFilter = (
    type: DateFilterTypeEnum,
    values: Array<string | number | null>,
  ): string => {
    switch (type) {
      case DateFilterTypeEnum.IN:
        return t('webApp.dynamics.topsResults.filterDetails.in', {
          year: values[0],
        })
      case DateFilterTypeEnum.BEFORE:
        return t('webApp.dynamics.topsResults.filterDetails.before', {
          year: values[0],
        })
      case DateFilterTypeEnum.AFTER:
        return t('webApp.dynamics.topsResults.filterDetails.after', {
          year: values[0],
        })
      case DateFilterTypeEnum.BETWEEN:
        return t('webApp.dynamics.topsResults.filterDetails.between', {
          yearStart: values[0],
          yearEnd: values[1],
        })
      default:
        return ''
    }
  }
  switch (columnKey) {
    case FILTER_KEY_DELTA_RANK:
      return (
        <>
          {(filtersActions[columnKey] as Array<string>).map((value) => (
            <GlFilterButtonV2
              key={nanoid(5)}
              columnKey={columnKey}
              icon={<ProgressIcon className="w-4 h-4" />}
              label={value}
              handleRemoveItemFilter={() =>
                handleRemoveItemFilter(columnKey, value)
              }
            >
              {getIconProgress(Number(value))}
            </GlFilterButtonV2>
          ))}
        </>
      )
    case FILTER_KEY_AUTHORS:
      return (
        <>
          {(filtersActions[columnKey] as Array<string>).map((value) => (
            <GlFilterButtonV2
              key={nanoid(5)}
              columnKey={columnKey}
              icon={<AuthorIcon className="w-4 h-4" />}
              label={value}
              handleRemoveItemFilter={() =>
                handleRemoveItemFilter(columnKey, value)
              }
            />
          ))}
        </>
      )

    case FILTER_KEY_EDITOR:
      return (
        <>
          {(filtersActions[columnKey] as Array<string>).map((value) => (
            <GlFilterButtonV2
              key={nanoid(5)}
              columnKey={columnKey}
              icon={<EditorIcon className="w-4 h-4" />}
              label={value}
              handleRemoveItemFilter={() =>
                handleRemoveItemFilter(columnKey, value)
              }
            />
          ))}
        </>
      )
    case FILTER_KEY_SEGMENT1:
    case FILTER_KEY_SEGMENT2:
      return (
        <>
          {(filtersActions[columnKey] as Array<string>).map((value) => (
            <GlFilterButtonV2
              key={nanoid(5)}
              columnKey={columnKey}
              icon={<EditorIcon className="w-4 h-4" />}
              label={value && clilReferencesConfig[value]?.libelle}
              handleRemoveItemFilter={() =>
                handleRemoveItemFilter(columnKey, value)
              }
            />
          ))}
        </>
      )
    case FILTER_KEY_FORMAT:
      return (
        <>
          {(filtersActions[columnKey] as Array<string>).map((value) => (
            <GlFilterButtonV2
              key={nanoid(5)}
              columnKey={columnKey}
              icon={<FormatIcon className="w-4 h-4" />}
              label={value}
              handleRemoveItemFilter={() =>
                handleRemoveItemFilter(columnKey, value)
              }
            />
          ))}
        </>
      )
    case FILTER_KEY_PUBLISHING_DATE:
      return (
        filtersActions[columnKey] &&
        (filtersActions[columnKey] as Array<any>).filter(
          (value) => value !== null,
        ).length > 0 && (
          <GlFilterButtonV2
            columnKey={columnKey}
            icon={<DateIcon className="w-4 h-4" />}
            label={getPublishingDateFilter(
              filtersActions[
                `${FILTER_KEY_PUBLISHING_DATE}_type`
              ] as DateFilterTypeEnum,
              filtersActions[columnKey] as Array<any>,
            )}
            handleRemoveItemFilter={() => handleRemoveItemFilter(columnKey, '')}
          />
        )
      )
    default:
      return null
  }
}

export default DynamicsResultsFilterActionTag
