import clilDropdownUtil, {
  ClilDropDownInterface,
} from '../config/clil-dropdown.util'

export interface ClilDropDownUniqueInterface extends ClilDropDownInterface {
  key: string
}

const getClilGenealogy = (
  clilValue: string[],
): ClilDropDownUniqueInterface[] => {
  const clils = clilDropdownUtil()

  const clilsSelected = clilValue.map((clil_id: string) =>
    clils.find((clil: ClilDropDownInterface) => clil.id === clil_id),
  ) as ClilDropDownInterface[]

  const clilsSelectedEnrich: ClilDropDownUniqueInterface[] = []

  clilsSelected.forEach((clilSelected: ClilDropDownInterface) => {
    const parentClil = clils.find(
      (clil: ClilDropDownInterface) => clil.id === clilSelected.parentId,
    )
    if (parentClil) {
      const grandParentClil = clils.find(
        (clil: ClilDropDownInterface) => clil.id === parentClil.parentId,
      )
      if (grandParentClil) {
        clilsSelectedEnrich.push({
          ...grandParentClil,
          key: `${clilSelected.id}-${parentClil.id}-${grandParentClil.id}`,
        })
      }
      clilsSelectedEnrich.push({
        ...parentClil,
        key: `${clilSelected.id}-${parentClil.id}`,
      })
    }
    clilsSelectedEnrich.push({ ...clilSelected, key: clilSelected.id })
  })

  return clilsSelectedEnrich
}

export default getClilGenealogy
