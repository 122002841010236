import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as InfoIcon } from '../../../../../../../assets/icons/info-icon.svg'
import { ReactComponent as EditIcon } from '../../../../../../../assets/icons/app/edit.icon.svg'
import {
  DatasetSubTitleStyle,
  DatasetTitleStyle,
} from '../../../ReadershipProfileStyles'
import { DatasetModuleInterface } from '../../../../../../../core/interface/analytics/dataset-module.interface'
import { ReadershipProfilePreviewComparisonInterface } from '../../../../../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import DatasetModuleInfoMenu from './DatasetModuleInfoMenu'
import { AnalyticOutputTypeEnum } from '../../../../../../../core/enums/analytic-output-type.enum'
import EditMenuView from './EditMenuView'
import { AnalyticOutputInterface } from '../../../../../../../core/interface/analytics/analytic-output.interface'
import InfoEditMenuWrapper from './InfoEditMenuWrapper'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../../core/enums/analytic-output-layout-size.enum'
import { useAppSelector } from '../../../../../../../store/hooks'
import { selectAnalyticDetailsStateAnalyticDetailsPermission } from '../../../../../../../store/getters/analytics-details/analytic-details-v2.getters'
import { AnalyticsPermissionEnum } from '../../../../../../../core/enums/analytics-permission.enum'

const InfoEditButtons = ({
  outputType,
  title = '',
  subTitle = '',
  infoBody = [],
  showInfoIcon = true,
  hoverInfoButton = false,
  setHoverInfoButton,
  datasetModule,
  previewModule,
  output,
  showIcon = true,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  size,
}: {
  outputType: AnalyticOutputTypeEnum
  title?: string
  subTitle?: string
  infoBody?: string[]
  showInfoIcon?: boolean
  hoverInfoButton: boolean
  setHoverInfoButton: (hover: boolean) => void
  datasetModule?: DatasetModuleInterface
  previewModule?: ReadershipProfilePreviewComparisonInterface[]
  output?: AnalyticOutputInterface
  showIcon?: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  size: AnalyticOutputLayoutSizeEnum
}) => {
  const { t } = useTranslation()

  const analyticsDetailsPermission = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsPermission,
  )

  const [showInfoMenu, setShowInfoMenu] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  const parentRef = useRef<any>(null)
  const [menuPosition, setMenuPosition] = useState<number>(0)

  useEffect(() => {
    setTimeout(
      () => setMenuPosition(parentRef.current?.getBoundingClientRect().left),
      0,
    )
  }, [showEditDialog, showInfoMenu, size])

  function handleMouseEnterInfoButton(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) {
    event.preventDefault()
    if (showEditDialog) return
    setShowInfoMenu(true)
    setHoverInfoButton(true)
  }
  function handleMouseExitInfoButton(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) {
    event.preventDefault()
    setShowInfoMenu(false)
    setHoverInfoButton(false)
  }

  function handleOpenEdit(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault()
    setShowEditDialog(!showEditDialog)
    setHoverInfoButton(!hoverInfoButton)
  }

  function handleCloseEdit(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault()
    setShowEditDialog(false)
    setHoverInfoButton(false)
  }

  function handleDeleteOutput() {
    let outputId
    if (outputType === AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET) {
      if (!datasetModule) return
      outputId = datasetModule.id
    } else {
      if (!output) return
      outputId = output.id
    }
    handleAnalyticsDeleteOutput?.(outputType, outputId)
  }

  function handleChangeOutput() {
    if (handleAnalyticsChangeOutput) {
      handleAnalyticsChangeOutput()
      setShowEditDialog(false)
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between	relative">
        <div className="flex flex-col">
          {title && <DatasetTitleStyle>{title}</DatasetTitleStyle>}
          {subTitle && <DatasetSubTitleStyle>{subTitle}</DatasetSubTitleStyle>}
        </div>
        {(showIcon || showEditDialog) && (
          <div
            className="absolute flex flex-row gap-0.5 right-0"
            ref={parentRef}
          >
            {showInfoIcon && (
              <InfoIcon
                className="flex items-center justify-center bg-[#F7F7F7] rounded w-8 h-8 p-1.5 cursor-pointer"
                onMouseEnter={handleMouseEnterInfoButton}
                onMouseLeave={handleMouseExitInfoButton}
              />
            )}
            {analyticsDetailsPermission === AnalyticsPermissionEnum.EDIT && (
              <EditIcon
                {...(showEditDialog && {
                  'data-ignore-outside-clicks': true,
                })}
                className="flex items-center justify-center bg-[#F7F7F7] rounded w-8 h-8 p-1.5 cursor-pointer"
                onClick={handleOpenEdit}
              />
            )}
          </div>
        )}
      </div>
      {showInfoMenu && (
        <InfoEditMenuWrapper
          title={t('informations')}
          menuPos={menuPosition}
          size={size}
        >
          <DatasetModuleInfoMenu infoBody={infoBody} />
        </InfoEditMenuWrapper>
      )}
      {showEditDialog && (
        <InfoEditMenuWrapper
          title={t('EditReadershipProfil.edit')}
          menuPos={menuPosition}
          handleCloseEdit={handleCloseEdit}
          handleDeleteOutput={handleDeleteOutput}
          handleChangeOutput={handleChangeOutput}
          withDelete={
            outputType !== AnalyticOutputTypeEnum.READERSHIP_PROFILE_PREVIEW
          }
          withChange={
            outputType === AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET
          }
          size={size}
        >
          <EditMenuView
            outputType={outputType}
            title={title}
            subTitle={subTitle}
            datasetModule={datasetModule}
            previewModule={previewModule}
            output={output}
          />
        </InfoEditMenuWrapper>
      )}
    </>
  )
}

export default InfoEditButtons
