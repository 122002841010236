import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import ScatterRecharts from './chartComponents/ScatterRecharts'
import FullTabular from '../tabular/FullTabular'
import AnalyticsOutputHorizontalClilLegend from '../AnalyticsOutputHorizontalClilLegend'
import { AnalyticTargetAxisEnum } from '../../../../../../core/enums/analytic-target-axis.enum'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import {
  AnalyticOutputLayoutFullHeightEnum,
  AnalyticOutputLayoutShrinkHeightEnum,
  SizableHeightAnalyticOutput,
} from '../../../../../../core/enums/analytic-output-layout-full-height.enum'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'

const AnalyticsOutputScatterRecharts = ({
  valuesConfigurations,
  display,
  title,
  subTitle,
  dataset,
  datasetModule,
  typeDataset,
  isPreview,
  handleAnalyticsChangeOutput,
  handleAnalyticsDeleteOutput,
  eanSelected,
  handleOpenBookDetails,
  handleOpenAuthorDetails,
  isScreenSmall,
  isOrganizeMenuOpen,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  display: AnalyticOutputLayoutSizeEnum
  title: string
  subTitle?: string
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  typeDataset: DatasetTypeEnum
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  handleOpenAuthorDetails?: (
    authorId: string | null,
    authorName: string | null,
    ean: number | null,
  ) => void
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
}) => {
  const { t } = useTranslation()

  let infoBody1: string
  let infoBody2: string
  switch (typeDataset) {
    case DatasetTypeEnum.WORKS_MIRROR:
      infoBody1 = 'InfoBulles.workMirrorMulti.1'
      infoBody2 = 'InfoBulles.workMirrorMulti.2'
      break
    case DatasetTypeEnum.AUTHORS_MIRROR:
      infoBody1 = 'InfoBulles.authorMirrorMulti.1'
      infoBody2 = 'InfoBulles.authorMirrorMulti.2'
      break
    case DatasetTypeEnum.OVERREPRESENTED:
      infoBody1 = 'InfoBulles.overrepresentedMulti.1'
      infoBody2 = 'InfoBulles.overrepresentedMulti.2'
      break
    case DatasetTypeEnum.MOST_FREQUENT:
      infoBody1 = 'InfoBulles.mostFrequentMulti.1'
      infoBody2 = 'InfoBulles.mostFrequentMulti.2'
      break
    default:
      infoBody1 = ''
      infoBody2 = ''
  }

  const isBubble = datasetModule.type === AnalyticsChartTypeEnum.BUBBLE_CHART

  const resizedDataset = useMemo(
    () => dataset.slice(0, isBubble ? 10 : 30),
    [dataset],
  )

  const xAxis = valuesConfigurations.find(
    (value) => value.target_axis === AnalyticTargetAxisEnum.X,
  )
  const yAxis = valuesConfigurations.find(
    (value) => value.target_axis === AnalyticTargetAxisEnum.Y,
  )
  const zAxis = valuesConfigurations.find(
    (value) => value.target_axis === AnalyticTargetAxisEnum.Z,
  )

  const hasClil = !!valuesConfigurations.find((value) =>
    [SourceEnum.WORK_CLIL, SourceEnum.EAN_CLIL].includes(value.source),
  )

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={[t(infoBody1), t(infoBody2)]}
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={
        datasetModule.show_table
          ? SizableHeightAnalyticOutput.SCATTER_TABULAR
          : SizableHeightAnalyticOutput.SCATTER
      }
      shrink={(isPreview && isScreenSmall) || isOrganizeMenuOpen}
      customStyle={{
        ...(datasetModule.show_table &&
          isPreview && {
            height: isScreenSmall
              ? AnalyticOutputLayoutShrinkHeightEnum.MEDIUM
              : AnalyticOutputLayoutFullHeightEnum.MEDIUM,
          }),
      }}
    >
      <>
        {xAxis && yAxis && (zAxis || !isBubble) && (
          <>
            {/* https://github.com/recharts/recharts/issues/172#issuecomment-545975536 */}
            <div
              style={{ minHeight: AnalyticOutputLayoutShrinkHeightEnum.SMALL }}
              className="flex-1 overflow-hidden"
            >
              <ScatterRecharts
                dataset={resizedDataset}
                typeDataset={typeDataset}
                xAxis={xAxis}
                yAxis={yAxis}
                zAxis={zAxis}
                hasClil={hasClil}
              />
            </div>
            <AnalyticsOutputHorizontalClilLegend
              dataset={resizedDataset}
              typeDataset={typeDataset}
            />
          </>
        )}
        {datasetModule.show_table && (
          <FullTabular
            dataset={resizedDataset}
            valuesConfigurations={valuesConfigurations}
            typeDataset={typeDataset}
            typeModule={datasetModule.type}
            limitToTenLines
            shrink={isOrganizeMenuOpen || isPreview}
            eanSelected={eanSelected}
            handleOpenBookDetails={handleOpenBookDetails}
            handleOpenAuthorDetails={handleOpenAuthorDetails}
          />
        )}
      </>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputScatterRecharts
