export const removeDiacritics = (value: string) => {
  return String(value)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const normalizedContains = (
  value: string,
  searchValue: string,
): boolean => {
  const regExp = new RegExp(removeDiacritics(searchValue), 'gi')
  return regExp.test(removeDiacritics(value))
}
