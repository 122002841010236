import { TagFilterEnum } from '../../enums/tag-filter.enum'
import ownIcon from '../../../assets/icons/tags/own-tag.svg'
import wishIcon from '../../../assets/icons/tags/wish-tag.svg'
import readingIcon from '../../../assets/icons/tags/reading-tag.svg'
import readIcon from '../../../assets/icons/tags/read-tag.svg'
import likeIcon from '../../../assets/icons/tags/like-tag.svg'

export interface BookDetailTagInterface {
  label: string
  icon: string
  styleIcon: string
  value: TagFilterEnum
  default?: boolean
  id?: string
}
export const BookDetailTagsFilterConfig: BookDetailTagInterface[] = [
  {
    label: "J'ai",
    icon: ownIcon,
    styleIcon: 'mr-1.5',
    value: TagFilterEnum.TAG_IS_OWN,
    default: true,
    id: 'is_own',
  },
  {
    label: 'Je veux',
    icon: wishIcon,
    styleIcon: 'mr-1.5',
    value: TagFilterEnum.TAG_IS_WISH,
    id: 'is_wish',
  },
  {
    label: 'Je lis',
    icon: readingIcon,
    styleIcon: 'mr-1.5',
    value: TagFilterEnum.TAG_IS_READING,
    id: 'is_reading',
  },
  {
    label: "J'ai lu",
    icon: readIcon,
    styleIcon: 'mr-1.5',
    value: TagFilterEnum.TAG_IS_READ,
    id: 'is_read',
  },
  {
    label: "J'ai aimé",
    icon: likeIcon,
    styleIcon: 'mr-1.5',
    value: TagFilterEnum.TAG_IS_LIKE,
    id: 'is_like',
  },
]
