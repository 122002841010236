import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_EDITOR,
  FILTER_KEY_FORMAT,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
} from './filters-items-dynamics.config'
import { TrendsItemFilterActionInterface } from '../../interface/dynamics/trends-item-filter-action.interface'
import { ReactComponent as FormatIcon } from '../../../assets/icons/app/format.icon.svg'
import { ReactComponent as EditorIcon } from '../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as SegmentIcon } from '../../../assets/icons/app/segment.icon.svg'
import { ReactComponent as AuthorIcon } from '../../../assets/icons/app/author.icon.svg'
import { ItemSortActionEnum } from '../../enums/item-filter-action.enum'

export const GroupItemsDynamicsConfig: TrendsItemFilterActionInterface[] = [
  {
    value: ItemSortActionEnum.Author,
    name: 'webApp.dynamics.results.filters.columns.author',
    icon: <AuthorIcon className="w-4 h-4" />,
    active: false,
    key: FILTER_KEY_AUTHORS,
  },
  {
    value: ItemSortActionEnum.Editor,
    name: 'webApp.dynamics.results.filters.columns.editor',
    icon: <EditorIcon className="w-4 h-4" />,
    active: false,
    key: FILTER_KEY_EDITOR,
  },
  {
    value: ItemSortActionEnum.Segment1,
    name: 'webApp.dynamics.results.filters.columns.clil1',
    icon: <SegmentIcon className="w-4 h-4" />,
    active: false,
    key: FILTER_KEY_SEGMENT1,
  },
  {
    value: ItemSortActionEnum.Segment2,
    name: 'webApp.dynamics.results.filters.columns.clil2',
    icon: <SegmentIcon className="w-4 h-4" />,
    active: false,
    key: FILTER_KEY_SEGMENT2,
  },
  {
    value: ItemSortActionEnum.Format,
    name: 'webApp.dynamics.results.filters.columns.format',
    icon: <FormatIcon className="w-4 h-4" />,
    active: false,
    key: FILTER_KEY_FORMAT,
  },
]
