const appendGroupCirclesBaseUtil = (
  svgContainer: any,
  {
    radiusBaseCircle,
    width,
    height,
  }: {
    radiusBaseCircle: number[]
    width: number
    height: number
  },
) => {
  const circleGroup = svgContainer
    .selectAll('g')
    .data([...radiusBaseCircle])
    .enter()
    .append('g')

  circleGroup
    .append('circle')
    .attr('cx', width / 2)
    .attr('cy', height / 2)
    .attr('r', (d: number) => d)
    .attr('fill', 'none')
    .attr('stroke', '#b2b2b233')
}

export default appendGroupCirclesBaseUtil
