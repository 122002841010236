import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { ItemsFilterDynamicsResultsConfig } from '../../../core/config/dynamics/filters-items-dynamics.config'
import { TrendsItemFilterActionInterface } from '../../../core/interface/dynamics/trends-item-filter-action.interface'
import DynamicsResultsFilterDropDown from '../filters/actions-filters/DynamicsResultsFilterDropDown'
import DynamicsResultsFilterType from '../filters/actions-filters/DynamicsResultsFilterType'
import { DateFilterTypeEnum } from '../../../core/enums/date-filter-type.enum'

const HeaderStyle = styled.div`
  -webkit-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  max-height: ${({ open }: { open: boolean }) =>
    open ? '100px ' : 0} !important;
  visibility: ${({ open }: { open: boolean }) =>
    open ? 'visible ' : 'hidden'} !important;
  opacity: ${({ open }: { open: boolean }) => (open ? '1 ' : '0')} !important;
  padding: ${({ open }: { open: boolean }) =>
    open ? '16px ' : '0'} !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: rgba(255, 255, 255, 0.04);
`

const DynamicsResultsHeaderFiltersActions = ({
  open,
  trendsAggregateByColumn,
  handleChangeListFilterSelected,
  filtersActions,
  handleYearTypeSelection,
}: {
  open: boolean
  trendsAggregateByColumn: { [key: string]: Set<any> }
  handleChangeListFilterSelected: (
    columnKey: string,
    newList: Array<string | number | null>,
  ) => void
  filtersActions: {
    [key: string]: string | Array<string | number | null> | null
  }
  handleYearTypeSelection: (value: DateFilterTypeEnum | null) => void
}) => {
  const { t } = useTranslation()

  const [menuOpen, setMenuOpen] = useState<string | null>(null)

  return (
    <HeaderStyle
      open={open}
      className="flex flex-row items-center justify-center gap-2"
    >
      {ItemsFilterDynamicsResultsConfig.map(
        (item: TrendsItemFilterActionInterface) => (
          <DynamicsResultsFilterDropDown
            icon={item.icon}
            label={t(item.name)}
            key={nanoid(5)}
            menuKey={item.key}
            menuOpen={menuOpen}
            handleMenuOpen={setMenuOpen}
          >
            {trendsAggregateByColumn && trendsAggregateByColumn[item.key] && (
              <DynamicsResultsFilterType
                columnKey={item.key}
                data={trendsAggregateByColumn[item.key]}
                filtersActions={filtersActions}
                handleChangeListFilterSelected={(
                  newList: Array<string | number | null>,
                ) => handleChangeListFilterSelected(item.key, newList)}
                handleYearTypeSelection={handleYearTypeSelection}
              />
            )}
          </DynamicsResultsFilterDropDown>
        ),
      )}
    </HeaderStyle>
  )
}

export default DynamicsResultsHeaderFiltersActions
