import axios, { AxiosInstance } from 'axios'
import { ApiPathConfig } from '../config/ApiPathConfig'
import {
  setWebAppStateLoggedIn,
  setWebAppStateLoggedOut,
} from '../../store/reducers/web-app.reducer'
import { webAppStateGetMySelfAction } from '../../store/actions/web-app.actions'
import { WebAppStateInterface } from '../../store/interfaces/web-app.state.interface'
import setAccessRefreshTokenLocalStorageUtil from '../utils/set-access-refresh-token-local-storage.util'
import clearLocalStorageUtil from '../utils/clear-local-storage.util'

const apiInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API_URL,
})

export const apiInstanceImg = axios.create({
  headers: {
    'Content-type': 'multipart/form-data',
  },
  baseURL: process.env.REACT_APP_API_URL,
})

export const awsBack = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  baseURL: process.env.REACT_APP_AWS_BACKEND,
})

const injectStoreByApi = (store: any, apiInst: AxiosInstance) => {
  const { dispatch } = store

  apiInst.interceptors.request.use(
    (config: any) => {
      const { getState } = store
      const { accessToken } = getState().webAppState
      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (err: any) => {
      return Promise.reject(err)
    },
  )

  apiInst.interceptors.response.use(
    (res: any) => {
      return res
    },
    async (err: { config: any; response: { status: number } }) => {
      const originalConfig = err.config

      if (
        originalConfig &&
        originalConfig.url !== ApiPathConfig.SIGN_IN &&
        originalConfig.url !== ApiPathConfig.REFRESH_TOKEN &&
        err.response
      ) {
        const { isLoadingMySelf } = store.getState()
          .webAppState as WebAppStateInterface
        if (
          err.response.status === 403 &&
          // eslint-disable-next-line no-underscore-dangle
          !originalConfig._retry
        ) {
          !isLoadingMySelf && void dispatch(webAppStateGetMySelfAction())
        }
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && !originalConfig._retry) {
          // eslint-disable-next-line no-underscore-dangle
          originalConfig._retry = true

          try {
            const { refreshToken: refresh } = store.getState().webAppState
            const rs = await apiInst.post(ApiPathConfig.REFRESH_TOKEN, {
              refresh,
            })

            const { access: accessToken, refresh: newRefreshToken } = rs.data
            await setAccessRefreshTokenLocalStorageUtil({
              accessToken,
              refreshToken: newRefreshToken,
            })
            dispatch(
              setWebAppStateLoggedIn({
                accessToken,
                refreshToken: newRefreshToken,
              }),
            )
            // eslint-disable-next-line @typescript-eslint/return-await
            return apiInst(originalConfig)
          } catch (_error: any) {
            if (_error.response && _error.response.status === 401) {
              await clearLocalStorageUtil()
              dispatch(setWebAppStateLoggedOut())
            }
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    },
  )
}

export const injectStore = (store: any) => {
  injectStoreByApi(store, apiInstance)
  injectStoreByApi(store, apiInstanceImg)
}

export default apiInstance
