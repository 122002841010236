import { ReactElement } from 'react'
import { PreviewTypeConfigInterface } from '../interface/preview-type-config.interface'
import { PreviewTypeEnum } from '../enums/preview-type.enum'
import { ReactComponent as PeoplesIcon } from '../../assets/icons/app/peoples.icon.svg'
import { ReactComponent as FemaleIcon } from '../../assets/icons/app/female.icon.svg'
import { ReactComponent as BooksIcon } from '../../assets/icons/app/books.icon.svg'
import { ReactComponent as PeopleBirthIcon } from '../../assets/icons/app/people-birth.icon.svg'
import { ReactComponent as PeopleBookIcon } from '../../assets/icons/app/people-book.icon.svg'

export const PreviewTypeConfig: PreviewTypeConfigInterface = {
  [PreviewTypeEnum.READERS]: {
    icon: (
      <PeoplesIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    label: 'Taille des échantillons',
  },
  [PreviewTypeEnum.WOMAN_PERCENT]: {
    icon: (
      <FemaleIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    label: '% de femmes',
    suffix: '%',
  },
  [PreviewTypeEnum.TOTAL_BOOK]: {
    icon: (
      <BooksIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    label: 'Nombre de livres dans leur bibliothèque',
  },
  [PreviewTypeEnum.MEAN_AGE]: {
    icon: (
      <PeopleBirthIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    label: 'Âge moyen des lecteurs',
    suffix: ' ans',
  },
  [PreviewTypeEnum.AVG_BOOK_BY_READER]: {
    icon: (
      <PeopleBookIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    label: 'Nombre moyen de livres par lecteur',
  },
}

export const PreviewConfigType: {
  [key in PreviewTypeEnum]: {
    icon: ReactElement
    labelKey: string
  }
} = {
  [PreviewTypeEnum.READERS]: {
    icon: (
      <PeoplesIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    labelKey: 'NumberOfReaders',
  },
  [PreviewTypeEnum.WOMAN_PERCENT]: {
    icon: (
      <FemaleIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    labelKey: 'WomenPercent',
  },
  [PreviewTypeEnum.AVG_BOOK_BY_READER]: {
    icon: (
      <BooksIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    labelKey: 'NumberOfBooksInTheirLibrary',
  },
  [PreviewTypeEnum.MEAN_AGE]: {
    icon: (
      <PeopleBirthIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    labelKey: 'AverageAgeOfReaders',
  },
  [PreviewTypeEnum.TOTAL_BOOK]: {
    icon: (
      <PeopleBookIcon
        style={{
          minWidth: 40,
          maxWidth: 40,
          minHeight: 40,
          maxHeight: 40,
        }}
      />
    ),
    labelKey: 'AverageNumberOfBooksPerReader',
  },
}
