type Props = {
  payload: { value: number | string; index: number }
  x: number
  y: number
  cx: number
  cy: number
  radius: number
  color?: string
}

const RadarAxisTick = ({
  payload,
  x,
  y,
  cx,
  cy,
  color = '#BEBEBE',
  radius,
}: Props) => {
  const theta = Math.atan2(y - cy, x - cx)

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      x={x - 22.5 + radius * 0.1 * Math.cos(theta)}
      y={y - 22.5 + radius * 0.1 * Math.sin(theta)}
      key={payload.index}
    >
      <path
        d="M36.5654 22.2949C36.5654 30.0269 30.2974 36.2949 22.5654 36.2949C14.8334 36.2949 8.56543 30.0269 8.56543 22.2949C8.56543 14.5629 14.8334 8.29492 22.5654 8.29492C30.2974 8.29492 36.5654 14.5629 36.5654 22.2949ZM10.5254 22.2949C10.5254 28.9444 15.9159 34.3349 22.5654 34.3349C29.2149 34.3349 34.6054 28.9444 34.6054 22.2949C34.6054 15.6454 29.2149 10.2549 22.5654 10.2549C15.9159 10.2549 10.5254 15.6454 10.5254 22.2949Z"
        fill={color}
      />

      <text
        fill="#3B3B3B"
        fontFamily="Fira Sans"
        fontSize="14"
        letterSpacing="0em"
        textAnchor="center"
      >
        <tspan x="19.5371" y="26.9849">
          {payload.index + 1}
        </tspan>
      </text>
    </svg>
  )
}

export default RadarAxisTick
