import { CancelToken } from 'axios'
import { apiInstanceImg } from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'

export const putImageOutputService = (
  reportId: string,
  inputId: string,
  outputId: string,
  imageObject: FormData,
  cancelToken?: CancelToken,
) =>
  apiInstanceImg.put(
    ApiPathConfig.IMAGE_OUTPUT_BY_ID(reportId, inputId, outputId),
    imageObject,
    {
      cancelToken,
    },
  )
