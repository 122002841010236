import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ReactComponent as CrossIcon } from '../../../assets/icons/app/cross.icon.svg'

const TagStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #f7f7f7;
  background: #59595999;
  border: 1px solid #595959;
  border-radius: 24px;
  padding: 4px 8px;
  gap: 4px;
  cursor: pointer;
`

const DeleteButtonStyle = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 2px;
  &:hover:not(.parentElement) {
    border: 1px solid #595959;
    background: #595959b3;
  }

  &:active:not(.parentElement) {
    border: 1px solid #595959;
    background: #59595980;
  }
`

interface Props {
  label: string
  icon: ReactElement
  handleRemoveItemFilter: () => void
  handleOnClickItemFilter?: () => void
  hasMoreElement?: boolean
  itemCount?: number
  columnKey: string
  children?: ReactElement
}

const GlFilterButtonV2 = ({
  label,
  icon,
  handleOnClickItemFilter = undefined,
  handleRemoveItemFilter,
  hasMoreElement = undefined,
  itemCount = 0,
  columnKey,
  children,
}: Props) => {
  const { t } = useTranslation()

  const onClickDeleteFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleRemoveItemFilter()
  }

  return (
    /* <div className="flex flex-row h-6 relative">
      <ButtonStyle
        type="button"
        className="flex-none border rounded-3xl pl-2 pr-8 justify-start items-center active:bg-[#e3e3e3]"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          if (handleOnClickItemFilter) {
            handleOnClickItemFilter()
          }
        }}
      >
        <div className="flex flex-row justify-between items-center gap-1">
          {icon}
          {(type && type === FILTER_KEY_PROGRESS && !hasMoreElement && (
            <img src={getIconProgress(Number(label))} alt="Icône progression" />
          )) ||
            (type &&
              (type === FILTER_KEY_SEGMENT1 || type === FILTER_KEY_SEGMENT2) &&
              !hasMoreElement && <GlBadgeClil id={label} />) || (
              <Label>{t(label).toUpperCase()}</Label>
            )}

          {hasMoreElement && <GlBadgeCount>{itemCount}</GlBadgeCount>}
        </div>
      </ButtonStyle>
      <button
        type="button"
        className="relative left-[-20px]"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          event.stopPropagation()
          if (handleRemove) {
            handleRemove()
          }
        }}
      >
        <CrossIcon className="w-[10px] h-[10px]" />
      </button>
    </div> */
    <TagStyle className="parentElement flex flex-row justify-between items-center gap-1">
      {icon}
      {children || <span>{label}</span>}
      <DeleteButtonStyle
        type="button"
        onClick={onClickDeleteFilter}
        className="deleteButton"
      >
        <CrossIcon className="w-[10px] h-[10px]" />
      </DeleteButtonStyle>
    </TagStyle>
  )
}

export default GlFilterButtonV2
