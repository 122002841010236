import React from 'react'
import PublicLayout from '../../components/layouts/PublicLayout'
import { GcuCurrentConfig } from '../../core/config/legal/gcu.current.config'
import LegalNavSidebar from '../../components/legal/LegalNavSidebar'
import LegalContent from '../../components/legal/LegalContent'
import GlVDivider from '../../components/share/GlVDivider'
import { PrivacyCurrentConfig } from '../../core/config/legal/privacy.current.config'

const LegalView = () => (
  <PublicLayout>
    <div
      className="w-full flex flex-row flex-auto"
      style={{
        height: 'calc(100% -48px)',
      }}
    >
      <LegalNavSidebar configs={[GcuCurrentConfig, PrivacyCurrentConfig]} />

      <GlVDivider height="100%" />

      <LegalContent configs={[GcuCurrentConfig, PrivacyCurrentConfig]} />
    </div>
  </PublicLayout>
)

export default LegalView
