import React, { useEffect } from 'react'
import { nanoid } from 'nanoid'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { LegalConfigInterface } from '../../core/config/legal/LegalConfigInterface'
import GlHDivider from '../share/GlHDivider'
import LegalContentType from './LegalContentType'

const InfoStyle = styled.div`
  margin: 8px 0;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  opacity: 0.5;
`
const LegalContent = ({ configs }: { configs: any[] }) => {
  const { hash } = useLocation()

  useEffect(() => {}, [])

  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''))
    if (element) element.scrollIntoView()
  }, [hash])

  return (
    <PerfectScrollbar>
      <div
        className="flex-auto flex flex-col h-full px-4 py-8 pr-16 text-justify"
        style={{
          height: 'calc(100% -48px)',
        }}
      >
        {configs.map((config: LegalConfigInterface) => {
          return (
            <div key={nanoid(5)}>
              <LegalContentType
                type="main"
                content={config.titleMain}
                refId={`${config.id}`}
              />
              <InfoStyle>
                À compter du <b>{dayjs(config.date).format('DD MMMM YYYY')}</b>
              </InfoStyle>
              <InfoStyle>
                Version spécifique au pays: <b>France</b>
              </InfoStyle>
              <GlHDivider />
              {config.contents.map((content: any, index) => (
                <React.Fragment key={nanoid(5)}>
                  <LegalContentType
                    refId={`${config.id}-${index}`}
                    type={content.type}
                    content={content.titleMain || content.content}
                  />
                  {content.contents &&
                    content.contents.map(
                      (contentChild: any, indexChild: number) => (
                        <LegalContentType
                          key={nanoid(5)}
                          refId={`${config.id}-${index}-${indexChild}`}
                          type={contentChild.type}
                          content={contentChild.content}
                        />
                      ),
                    )}
                </React.Fragment>
              ))}
              <GlHDivider />
              <div className="w-full h-[64px]" />
            </div>
          )
        })}
      </div>
    </PerfectScrollbar>
  )
}

export default LegalContent
