import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import DynamicsResultsTableHeaderV2 from './DynamicsResultsTableHeaderV2'
import DynamicsResultsTableBodyV2 from './DynamicsResultsTableBodyV2'
import DynamicsResultsGroupV2 from './DynamicsResultsGroupV2'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'
import { TrendsItemFilterActionInterface } from '../../../../../core/interface/dynamics/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import { TagFilterEnum } from '../../../../../core/enums/tag-filter.enum'

const DynamicsResultsTableV2 = ({
  trends,
  isLoadingTrends,
  handleSortHeaderSelected,
  handleBookSelected,
  handleSetShowSnackBar,
  groupSelected,
  sortHeaderSelected,
  sortsSelected,
  tagSelected,
  bookSelected,
  searchValue,
}: {
  trends: TrendV2Interface[]
  isLoadingTrends: boolean
  handleSortHeaderSelected: (filter: string, direction: number) => void
  handleBookSelected: (book: TrendV2Interface | null) => void
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  tagSelected: TagFilterEnum
  bookSelected: TrendV2Interface | null
  searchValue: string
}) => {
  return (
    <div className="flex flex-col flex-auto">
      <DynamicsResultsTableHeaderV2
        sortHeaderSelected={sortHeaderSelected}
        sortsSelected={sortsSelected}
        handleSortHeaderSelected={handleSortHeaderSelected}
      />
      <div className="flex flex-col h-0 flex-auto">
        <PerfectScrollbar>
          {(groupSelected && (
            <DynamicsResultsGroupV2
              data={trends}
              isLoadingTrends={isLoadingTrends}
              groupSelected={groupSelected}
              handleSetShowSnackBar={handleSetShowSnackBar}
              searchValue={searchValue}
              handleBookSelected={handleBookSelected}
              tagSelected={tagSelected}
              bookSelected={bookSelected}
            />
          )) || (
            <DynamicsResultsTableBodyV2
              data={trends}
              isLoadingTrends={isLoadingTrends}
              handleSetShowSnackBar={handleSetShowSnackBar}
              handleBookSelected={handleBookSelected}
              tagSelected={tagSelected}
              bookSelected={bookSelected}
            />
          )}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default DynamicsResultsTableV2
