import React, { ReactElement, useState } from 'react'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import InfoEditButtons from './chart/chartComponents/InfoEditButtons'
import { DatasetModuleInterface } from '../../../../../core/interface/analytics/dataset-module.interface'
import { ReadershipProfilePreviewComparisonInterface } from '../../../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import { AnalyticOutputInterface } from '../../../../../core/interface/analytics/analytic-output.interface'
import {
  AnalyticOutputLayoutHeightMap,
  SizableHeightAnalyticOutput,
} from '../../../../../core/enums/analytic-output-layout-full-height.enum'

const AnalyticsOutputWrapper = ({
  children,
  outputId,
  display,
  title,
  subTitle,
  infoBody,
  datasetModule,
  previewModule,
  output,
  isPreview,
  outputType,
  customStyle,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  type,
  shrink = false,
}: {
  children?: ReactElement
  outputId?: string
  display: AnalyticOutputLayoutSizeEnum
  title?: string
  subTitle?: string
  infoBody?: string[]
  datasetModule?: DatasetModuleInterface
  previewModule?: ReadershipProfilePreviewComparisonInterface[]
  output?: AnalyticOutputInterface
  isPreview?: boolean
  outputType: AnalyticOutputTypeEnum
  customStyle?: React.CSSProperties
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  type?: SizableHeightAnalyticOutput
  shrink?: boolean
}) => {
  const [hoverInfoButton, setHoverInfoButton] = useState(false)
  const [hoverOutput, setHoverOutput] = useState(false)

  function handleMouseEnterOutput(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    event.preventDefault()
    setHoverOutput(true)
  }
  function handleMouseExitOutput(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    event.preventDefault()
    setHoverOutput(false)
  }

  return (
    <div
      className={`${
        isPreview ? '' : 'grid-item'
      } flex flex-col rounded-xl gap-4 self-start p-4 relative ${
        hoverInfoButton ? 'bg-[#E9E9E9]' : ''
      } ${!isPreview ? 'border border-[#E9E9E9]' : ''}`}
      style={{
        height:
          (type &&
            (shrink
              ? AnalyticOutputLayoutHeightMap[type].shrink
              : AnalyticOutputLayoutHeightMap[type].full)) ||
          'auto',
        ...(display === AnalyticOutputLayoutSizeEnum.FULL
          ? {
              width: '100%',
            }
          : {
              width: `calc(100% * ${display} / ${AnalyticOutputLayoutSizeEnum.FULL} - 12px)`,
            }),
        ...customStyle,
      }}
      onMouseEnter={handleMouseEnterOutput}
      onMouseLeave={handleMouseExitOutput}
      id={outputId}
    >
      <InfoEditButtons
        outputType={outputType}
        title={title}
        subTitle={subTitle}
        infoBody={infoBody}
        hoverInfoButton={hoverInfoButton}
        setHoverInfoButton={setHoverInfoButton}
        datasetModule={datasetModule}
        previewModule={previewModule}
        output={output}
        showIcon={!isPreview && hoverOutput}
        showInfoIcon={!!infoBody}
        handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
        handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
        size={display}
      />
      {children}
    </div>
  )
}

export default AnalyticsOutputWrapper
