import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const ButtonStyle = styled.button`
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 16px;
  height: 144px;
  ${({ active }: { active: boolean }) =>
    active
      ? `
    color: white;
    background-color: #252525;
  `
      : `
    color: #252525;
    background-color: #f7f7f7;
  `}
`

const LabelStyle = styled.div`
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
`

const InputSelectionButton = ({
  active = false,
  labelKey,
  icon,
  handleOnClickInputSelectionButton,
}: {
  active?: boolean
  labelKey: string
  icon: ReactElement
  handleOnClickInputSelectionButton: () => void
}) => {
  const { t } = useTranslation()
  const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleOnClickInputSelectionButton()
  }
  return (
    <ButtonStyle
      active={active}
      type="button"
      className="flex-1 flex flex-col items-center justify-between"
      onClick={onClickButton}
    >
      {icon}
      <LabelStyle>{t(labelKey)}</LabelStyle>
    </ButtonStyle>
  )
}

export default InputSelectionButton
