import { SetColorConfigInterface } from '../interface/set-color-config.interface'
import { SetColorEnum } from '../enums/set-color.enum'
import { AnalyticVisualThemeEnum } from '../enums/analytic-visual-theme.enum'

export const SetColorConfig: SetColorConfigInterface[] = [
  {
    id: AnalyticVisualThemeEnum.DEFAULT,
    color1: '#FF8718',
    color2: '#41AFED',
    color3: '#031D2D',
    value: SetColorEnum.SET_1,
  },
  {
    id: AnalyticVisualThemeEnum.CLASSIC,
    color1: '#83DBD6',
    color2: '#E57872',
    color3: '#8A8DE3',
    value: SetColorEnum.SET_2,
  },
  {
    id: AnalyticVisualThemeEnum.CONTRASTED,
    color1: '#FF3F34',
    color2: '#263252',
    color3: '#48BB83',
    value: SetColorEnum.SET_3,
  },
  {
    id: AnalyticVisualThemeEnum.PASTEL,
    color1: '#6EA6AE',
    color2: '#DEC769',
    color3: '#C785E5',
    value: SetColorEnum.SET_4,
  },
]
