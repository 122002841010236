import { useTranslation } from 'react-i18next'
import { ReactComponent as WorkMirrorIcon } from '../../../../../../assets/icons/app/work-mirror.icon.svg'
import { ReactComponent as ReaderAgeIcon } from '../../../../../../assets/icons/app/reader-age.icon.svg'
import { ReactComponent as ReadersLibraryIcon } from '../../../../../../assets/icons/app/readers-library.icon.svg'
import { ReactComponent as BooksTopIcon } from '../../../../../../assets/icons/app/books-top.icon.svg'

import PictureIcon from '../../../../../../assets/images/picture.png'
import TitleIcon from '../../../../../../assets/images/title.png'
import TextIcon from '../../../../../../assets/images/text.png'
import StudyTitlesIcon from '../../../../../../assets/images/study-titles.png'

import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import { TitleAddModuleStyle, TextDescriptionStyle } from './AddModuleStyles'
import { AddModuleDataRepresentationEnum } from '../../../../../../core/enums/add-module-data-representation.enum'
import AddModuleEditorialElementsCard from './AddModuleEditorialElementsCard'

const AddModuleHomeView = ({
  setStep,
  handleClickOnEditorialElement,
}: {
  setStep: (step: AddModuleStepEnum) => void
  handleClickOnEditorialElement: (
    dataRepresentation: AddModuleDataRepresentationEnum,
    fileUploaded?: File,
  ) => void
}) => {
  const { t } = useTranslation()

  function handleClickOnDatasRepresentation() {
    setStep(AddModuleStepEnum.DATA_REPRESENTATION)
  }

  function handleClickOnEditorialElementCard(
    id: AddModuleDataRepresentationEnum,
  ) {
    handleClickOnEditorialElement(id)
  }

  return (
    <div className="flex flex-1 justify-center items-center gap-16">
      <div
        className="flex flex-1 flex-col justify-center items-center w-[528px] h-[576px] p-4 gap-8 rounded-xl bg-[#252525] hover:bg-[#000000] cursor-pointer"
        style={{
          boxShadow:
            '0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 0px 20px 0px rgba(0, 0, 0, 0.05)',
        }}
        onClick={() => handleClickOnDatasRepresentation()}
      >
        <div className="flex items-center gap-4">
          <ReadersLibraryIcon className="h-[40px] w-auto shrink-0	text-white" />
          <ReaderAgeIcon className="h-[40px] w-auto shrink-0	text-white" />
          <WorkMirrorIcon className="h-[40px] w-auto shrink-0	text-white" />
          <BooksTopIcon className="h-[40px] w-auto shrink-0	text-white" />
        </div>
        <div className="flex flex-col items-center gap-2">
          <TitleAddModuleStyle className="text-white">
            {t('addModuleMenu.datasRepresentation.title')}
          </TitleAddModuleStyle>
          <TextDescriptionStyle>
            {t('addModuleMenu.datasRepresentation.description')}
          </TextDescriptionStyle>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-8 w-[544px]">
        {[
          {
            icon: TitleIcon,
            id: AddModuleDataRepresentationEnum.TITLE,
          },
          {
            icon: TextIcon,
            id: AddModuleDataRepresentationEnum.TEXT,
          },
          {
            icon: PictureIcon,
            id: AddModuleDataRepresentationEnum.PICTURE,
          },
          {
            icon: StudyTitlesIcon,
            id: AddModuleDataRepresentationEnum.STUDY_TITLES,
          },
        ].map((item) => {
          return (
            <AddModuleEditorialElementsCard
              item={item}
              handleClickOnEditorialElementCard={
                handleClickOnEditorialElementCard
              }
              key={item.id}
            />
          )
        })}
      </div>
    </div>
  )
}

export default AddModuleHomeView
