import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AnalyticDetailsInterface } from '../../../interface/analytics/analytic-details.interface'

export const getShareAnalyticDetailsBySlugService = (
  slug: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<AnalyticDetailsInterface>> =>
  apiInstance.get(ApiPathConfig.SHARE_ANALYTIC_DETAILS_BY_SLUG(slug), {
    cancelToken,
  })
