import {
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts'

type Props = {
  axisNbr: number
  startAngle: number
  innerRadius?: number
}

const RadarAxisLine = ({ axisNbr, startAngle, innerRadius = 0 }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%" className="absolute">
      <RadarChart
        cx="50%"
        cy="50%"
        innerRadius={innerRadius}
        data={Array(axisNbr)}
        startAngle={startAngle}
        endAngle={startAngle - 360}
      >
        <PolarGrid gridType="circle" stroke="#929292" />
        <PolarRadiusAxis axisLine={false} tickCount={0} />
        <Radar dataKey="" />
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default RadarAxisLine
