export enum AnalyticOutputTextSizeEnum {
  LOW = 12,
  MEDIUM = 16,
  HIGH = 20,
}

export const getTextSizeLabel = (size: AnalyticOutputTextSizeEnum) => {
  let label = ''
  switch (size) {
    case AnalyticOutputTextSizeEnum.LOW:
      label = 'Petit'
      break
    case AnalyticOutputTextSizeEnum.MEDIUM:
      label = `Moyen (Par défaut)`
      break
    case AnalyticOutputTextSizeEnum.HIGH:
      label = 'Grand'
      break
    default:
      label = ''
  }

  return label
}

export const mapTextSizes = () => {
  return [
    AnalyticOutputTextSizeEnum.LOW,
    AnalyticOutputTextSizeEnum.MEDIUM,
    AnalyticOutputTextSizeEnum.HIGH,
  ].map((size) => ({
    value: size,
    label: getTextSizeLabel(size),
  }))
}
