import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { SetColorConfig } from '../../../../../../core/config/SetColorConfig'
import { SetColorConfigInterface } from '../../../../../../core/interface/set-color-config.interface'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'

export function getColors(setColor: SetColorEnum): {
  [key: string]: string
} {
  const colorConfiguration =
    SetColorConfig.find(
      (setColorConfig: SetColorConfigInterface) =>
        setColorConfig.value === setColor,
    ) || SetColorConfig[0]

  return {
    [SourceEnum.SUBJECT_OBJECT_VALUE]: colorConfiguration.color1,
    [SourceEnum.COMPARE_GROUP_VALUE]: colorConfiguration.color2,
    [SourceEnum.IPSOS_VALUE]: colorConfiguration.color3,
  }
}
