import { AnalyticDetailsInterface } from '../interface/analytics/analytic-details.interface'
import { AnalyticInputInterface } from '../interface/analytics/analytic-input.interface'
import { OwnerInterface } from '../interface/owner-interface'
import { AnalyticsPermissionEnum } from '../enums/analytics-permission.enum'
import { SharedByInterface } from '../interface/shared-by-interface'
import { AnalyticsTypeEnum } from '../enums/analytics-type.enum'
import { CompanyInterface } from '../interface/company.interface'

export class MockAnalyticsReadershipProfile
  implements Partial<AnalyticDetailsInterface>
{
  company: CompanyInterface
  created: string
  id: string
  inputs: AnalyticInputInterface[]
  modified: string
  name: string
  owner: OwnerInterface
  permission: AnalyticsPermissionEnum
  share_count: number
  shared_by!: SharedByInterface
  slug: string
  type: AnalyticsTypeEnum

  constructor() {
    this.id = 'b91a5fd3-9e63-4af6-a67e-e0dbab27fe13'
    this.company = {
      id: 'b91a5fd3-9e63-4af6-a67e-e0dbab27fe12',
      name: 'Dev-Company-1',
    }
    this.created = '2023-10-31T17:16:23.835508Z'
    this.modified = '2023-10-31T17:16:23.835508Z'
    this.permission = AnalyticsPermissionEnum.EDIT
    this.share_count = 0
    this.slug = 'v2y6y17o9p'
    this.type = AnalyticsTypeEnum.READERSHIP_PROFILE
    this.owner = {
      first_name: 'Choughi',
      last_name: 'Halitim',
      business_unit: {
        name: 'Dev-Company-1',
        id: '2b709f64-c523-42d2-ab59-671d3ad5756d',
      },
      id: 'a69f944f-f2a3-4436-82e5-2e4e46fe18fa',
    }
    this.name = 'Profile lectorat mock'
    this.inputs = []
  }
}
