import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SelectOptionInterface } from '../../core/interface/select-option.interface'
import useOutsideClick from '../../core/hooks/useClickOutsideOld.hook'

const MenuContainer = styled.div`
  -webkit-transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;
  -moz-transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;
  -o-transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;
  transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;
`

const Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #252525;
  display: flex;
  white-space: nowrap;
`
interface CustomStyle {
  width: string | number
  marginBottom?: string | number
  minWidth?: string | number
}

interface Props<T> {
  labelKey?: string
  options: SelectOptionInterface<T>[] | null
  optionSelected: SelectOptionInterface<T> | null
  handleChangeSelect: (option: SelectOptionInterface<T>) => void
  customStyle?: CustomStyle
  zIndex?: number
}

const GlSelect = <T,>({
  labelKey = '',
  options,
  optionSelected,
  handleChangeSelect,
  customStyle = {
    minWidth: 144,
    width: 144,
    marginBottom: 0,
  },
  zIndex = 15,
}: Props<T>) => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState<boolean>(false)
  const ref = useOutsideClick(() => setOpened(false))

  return (
    <div
      style={{
        ...customStyle,
      }}
      className="flex flex-col relative"
    >
      <button
        type="button"
        ref={ref}
        onClick={() => setOpened(!opened)}
        style={{
          ...customStyle,
        }}
        className="
          border
          z-12
          hover:border-black
          border-[#e3e3e3]
          rounded-[4px]
          p-2
          flex
          flex-row
          gap-2
          justify-between
          items-center
          bg-white
          button-select
          h-[32px]
        "
      >
        <Label>
          {optionSelected?.icon && (
            <img
              src={optionSelected.icon}
              className={optionSelected.styleIcon}
              alt="icon"
            />
          )}
          {t(labelKey)}
          {t(optionSelected?.label || '')}
        </Label>
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 5.76364L7.76119 11L2.52727 5.76364"
              stroke="#595959"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {opened && (
        <MenuContainer
          className="
            shadow-lg
            rounded-[4px]
            absolute
            flex
            flex-col
            bg-white
            top
            w-full
            h-auto
          "
          style={{
            zIndex,
          }}
        >
          <div className="absolute z-20 right-3 top-3">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 10.2364L7.76119 5L2.52727 10.2364"
                stroke="#595959"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <PerfectScrollbar className="flex flex-col">
            {options &&
              options.map((option) => (
                <button
                  type="button"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    handleChangeSelect(option)
                    setOpened(false)
                  }}
                  key={nanoid(5)}
                  className={`
                  m-1
                  p-2
                  flex
                  flex-row
                  justify-between
                  w-100
                  hover:bg-[#f7f7f7]
                  rounded
                  items-center
                  active:bg-[#e3e3e3]
                  button-select-item
                  ${
                    optionSelected?.value === option.value ? 'bg-[#e3e3e3]' : ''
                  }
                `}
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <Label>
                    {optionSelected?.icon && (
                      <img
                        src={option.icon}
                        className={option.styleIcon}
                        alt="icon"
                      />
                    )}
                    {t(option.label)}
                  </Label>
                </button>
              ))}
          </PerfectScrollbar>
        </MenuContainer>
      )}
    </div>
  )
}

export default GlSelect
