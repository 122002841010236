import { AnalyticOrganizationEnum } from '../../../../../../core/enums/analytic-organization.enum'
import { AnalyticOutputInterface } from '../../../../../../core/interface/analytics/analytic-output.interface'
import OrganizeMenuStructure from './OrganizeMenuStructure'
import { SetColorConfigInterface } from '../../../../../../core/interface/set-color-config.interface'
import OrganizeMenuLayout from './OrganizeMenuLayout'
import { AnalyticVisualThemeEnum } from '../../../../../../core/enums/analytic-visual-theme.enum'

interface Props {
  organizationSelected: AnalyticOrganizationEnum
  outputs: AnalyticOutputInterface[]
  setShowAddModuleView: (value: boolean) => void
  handleOrderChange: (outputs: AnalyticOutputInterface[]) => void
  chartColorsSelected: SetColorConfigInterface
  handleVisualThemeEdit: (theme: AnalyticVisualThemeEnum) => void
}

const OrganizeMenuSwitch = ({
  organizationSelected,
  outputs,
  setShowAddModuleView,
  handleOrderChange,
  chartColorsSelected,
  handleVisualThemeEdit,
}: Props) => {
  switch (organizationSelected) {
    case AnalyticOrganizationEnum.STRUCTURE:
      return (
        <OrganizeMenuStructure
          outputs={outputs}
          setShowAddModuleView={setShowAddModuleView}
          handleOrderChange={handleOrderChange}
        />
      )
    case AnalyticOrganizationEnum.LAYOUT:
      return (
        <OrganizeMenuLayout
          chartColorsSelected={chartColorsSelected}
          handleVisualThemeEdit={handleVisualThemeEdit}
        />
      )
    default:
      return null
  }
}

export default OrganizeMenuSwitch
