import { CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { GetTopByCatalogIdParamsInterface } from '../../../interface/get-top-by-catalog-id-params.interface'

export const getTopsByCatalogIdService = (
  catalogId: string,
  params: GetTopByCatalogIdParamsInterface,
  cancelToken?: CancelToken,
) => {
  return apiInstance.get(ApiPathConfig.TOPS_BY_CATALOG_ID(catalogId), {
    cancelToken,
    params,
  })
}
