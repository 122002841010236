import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import WorkMirrorConstellationCard from './WorkMirrorConstellationCard'
import WorkMirrorBookDetailsCard from './WorkMirrorBookDetailsCard'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetailsBooksInputValues,
  selectAnalyticDetailsStateBookDetailsBookReco,
  selectAnalyticDetailsStateBookDetailsSelected,
  selectAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  selectAnalyticDetailsStateBookDetailsSelectedEditions,
  selectAnalyticDetailsStateGetBookRecoV3Error,
  selectAnalyticDetailsStateGetBookRecoV3Success,
  selectAnalyticDetailsStateIsLoadingBookDetailsGetBookReco,
  selectAnalyticDetailsStateIsLoadingGetB2BBookDetails,
  selectAnalyticDetailsStateIsLoadingGetBookDetails,
  selectAnalyticDetailsStateIsLoadingGetEditions,
} from '../../../../store/getters/analytics-details/analytic-details-v2.getters'
import {
  analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action,
  analyticDetailsV2StateGetBookDetailsData,
  analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action,
} from '../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { setShowSnackBar } from '../../../../store/reducers/web-app.reducer'
import WorkMirrorListCard from './WorkMirrorListCard'
import { resetGetBookRecoV3Status } from '../../../../store/reducers/analytics-details/analytic-details-v2.reducer'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'
import {
  buildRelativePath,
  PathConfig,
} from '../../../../core/config/PathConfig'
import { AnalyticsTypeEnum } from '../../../../core/enums/analytics-type.enum'
import GlDateManager from '../../../share/GlDateManager'

const AnalyticDetailsWorkMirrorView = ({
  handleSaveAnalytics,
  handleShareAnalytics,
}: {
  handleSaveAnalytics: () => void
  handleShareAnalytics: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openListCard, setOpenListCard] = useState<boolean>(false)
  const { reportId: urlReportId } = useParams<{ reportId: string }>()
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const isLoadingGetBookDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetBookDetails,
  )
  const isLoadingGetEditions = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetEditions,
  )
  const isLoadingGetB2BBookDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetB2BBookDetails,
  )
  const isLoadingBookDetailsGetBookReco = useAppSelector(
    selectAnalyticDetailsStateIsLoadingBookDetailsGetBookReco,
  )

  const getBookRecoV3Success = useAppSelector(
    selectAnalyticDetailsStateGetBookRecoV3Success,
  )
  const getBookRecoV3Error = useAppSelector(
    selectAnalyticDetailsStateGetBookRecoV3Error,
  )

  const bookDetailsBookReco = useAppSelector(
    selectAnalyticDetailsStateBookDetailsBookReco,
  )

  const bookDetailsSelectedB2BInfo = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  )

  const bookDetailsSelected = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelected,
  )

  const bookDetailsSelectedEditions = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelectedEditions,
  )
  const inputBookValues = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsBooksInputValues,
  )

  const analyticsDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )

  const handleOpenListView = () => {
    setOpenListCard(!openListCard)
  }

  const handleAddBookToAnalytic = (eanId: number) => {
    if (inputBookValues.includes(`${eanId}`)) {
      dispatch(setShowSnackBar(t('workMirrorAlreadySelected')))
    } else if (inputBookValues.length < 3)
      if (urlReportId === 'new') {
        const eans = searchParams.get('eans') as string
        let eanIds = []
        if (eans) {
          eanIds = [...`${eans}`.split(','), `${eanId}`]
        } else {
          eanIds.push(eanId)
        }

        navigate(
          buildRelativePath([
            PathConfig.BOOKMETRIE,
            PathConfig.ANALYTICS,
            `new?type=${AnalyticsTypeEnum.WORK_MIRROR}&eans=${eanIds.join(
              ',',
            )}`,
          ]),
        )
      } else {
        dispatch(
          analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action({ eanId }),
        ).finally(() => {
          dispatch(resetGetBookRecoV3Status())
        })
      }
  }

  const handleRemoveBookInput = (eanId: number) => {
    if (urlReportId === 'new') {
      const eans = searchParams.get('eans') as string
      let eanIds: string[] = []
      if (eans) {
        eanIds = [...`${eans}`.split(',')].filter(
          (ean: string) => ean !== `${eanId}`,
        )
      }

      navigate(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.ANALYTICS,
          `new?type=${AnalyticsTypeEnum.WORK_MIRROR}${
            eanIds.length ? `&eans=${eanIds.join(',')}` : ''
          }`,
        ]),
      )
    } else {
      dispatch(
        analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action({ eanId }),
      ).finally(() => {
        dispatch(resetGetBookRecoV3Status())
      })
    }
  }

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(
      analyticDetailsV2StateGetBookDetailsData({ bookDetails: null }),
    )
  }, [bookDetailsSelected])

  const handleCloseList = () => {
    setOpenListCard(false)
  }

  useEffect(() => {
    if (getBookRecoV3Error) {
      // dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }
  }, [getBookRecoV3Success, getBookRecoV3Error])

  const [bookEanIdHovered, setBookEanIdHovered] = useState<number | null>(null)
  const handleBookHovered = useCallback(
    (eanId: number | null) => {
      setBookEanIdHovered(eanId)
    },
    [bookEanIdHovered],
  )

  const handleBookSelected = useCallback(
    (bookDetails: WorkMirrorBookInterface | null) => {
      void dispatch(analyticDetailsV2StateGetBookDetailsData({ bookDetails }))
    },
    [
      bookDetailsSelected,
      bookDetailsSelectedEditions,
      bookDetailsSelectedB2BInfo,
    ],
  )

  return (
    <div className="flex-auto flex flex-col p-8 gap-2">
      <GlDateManager
        prefix={t('ReportReaderShipProfileDate')}
        date={analyticsDetails.modified}
      />
      <div className="flex-auto flex flex-row gap-2">
        <WorkMirrorConstellationCard
          eans={searchParams.get('eans') as string}
          openListCard={openListCard}
          handleOpenListView={handleOpenListView}
          handleSaveAnalytics={handleSaveAnalytics}
          handleShareAnalytics={handleShareAnalytics}
          handleAddBookToAnalytic={handleAddBookToAnalytic}
          handleRemoveBookInput={handleRemoveBookInput}
          handleBookHovered={handleBookHovered}
          bookDetailsSelected={bookDetailsSelected}
          handleBookSelected={handleBookSelected}
        />
        {(bookDetailsSelected && (
          <WorkMirrorBookDetailsCard
            isLoadingBookDetailsGetBookReco={isLoadingBookDetailsGetBookReco}
            bookDetailsBookReco={bookDetailsBookReco}
            isLoadingGetBookDetails={isLoadingGetBookDetails}
            isLoadingGetEditions={isLoadingGetEditions}
            isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
            openListCard={openListCard}
            bookDetails={bookDetailsSelected}
            bookDetailsSelectedEditions={bookDetailsSelectedEditions}
            bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
            handleCloseBookDetails={handleCloseBookDetails}
          />
        )) ||
          (openListCard && (
            <WorkMirrorListCard
              bookEanIdHovered={bookEanIdHovered}
              handleCloseList={handleCloseList}
            />
          )) ||
          null}
      </div>
    </div>
  )
}

export default AnalyticDetailsWorkMirrorView
