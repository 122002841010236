import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GlDialogTitle from '../../share/dialog/GlDialogTitle'
import GlDialogBody from '../../share/dialog/GlDialogBody'
import { useAppDispatch } from '../../../store/hooks'
import GlSpinner from '../../share/GlSpinner'
import { AnalyticsDashboardDialogButton } from './AnalyticsDashboardDialogStyle'
import getPrivateReportById from '../../../core/api/services/bookmetrie/private-reports/get-private-report-by-id.service'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { buildRelativePath, PathConfig } from '../../../core/config/PathConfig'

const AnalyticsDashboardReplicateDialog = ({
  analyticsId,
  analyticsName,
  handleCancelAction,
}: {
  analyticsId: string
  analyticsName: string
  handleCancelAction: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isLoadingReplicateAction, setIsLoadingReplicateAction] =
    useState<boolean>(false)

  const onClickCancelButton = (event: React.MouseEvent) => {
    event.preventDefault()
    handleCancelAction()
  }

  const onClickReplicateButton = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsLoadingReplicateAction(true)
    getPrivateReportById(analyticsId)
      .then((response) => {
        const { data } = response
        navigate(
          buildRelativePath([
            PathConfig.BOOKMETRIE,
            PathConfig.PRIVATE_REPORTS,
            PathConfig.AUDIENCE,
          ]),
          { state: data.inputs[0] },
        )
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        setIsLoadingReplicateAction(false)
      })
  }

  return (
    <div className="flex flex-col gap-4">
      <GlDialogTitle label={analyticsName} />
      <GlDialogBody>
        <span>{t('webApp.reports.confirmReplicateAnalytics')}</span>
      </GlDialogBody>
      <div className="flex flex-row gap-2">
        <AnalyticsDashboardDialogButton
          className="flex-1"
          onClick={onClickCancelButton}
        >
          {t('commons.buttons.cancel')}
        </AnalyticsDashboardDialogButton>
        <AnalyticsDashboardDialogButton
          className="relative flex-1"
          primary
          onClick={onClickReplicateButton}
          disabled={isLoadingReplicateAction}
        >
          {(isLoadingReplicateAction && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <GlSpinner />
            </div>
          )) ||
            null}
          {t('commons.buttons.replicate')}
        </AnalyticsDashboardDialogButton>
      </div>
    </div>
  )
}

export default AnalyticsDashboardReplicateDialog
