import { AnalyticsTypeEnum } from '../enums/analytics-type.enum'
import { AnalyticsStatusEnum } from '../enums/analytics-status.enum'
import { ReportLightResponseInterface } from '../interface/report/report-light-response.interface'
import { ReportInputTypeEnum } from '../enums/report-input-type.enum'

const getAnalyticInputBooksUtil = (
  analytic: ReportLightResponseInterface,
): Array<any> => {
  const input = analytic.inputs.find(
    (input: any) => input.type === ReportInputTypeEnum.BOOKS,
  )

  if (input && analytic.type !== AnalyticsTypeEnum.AUDIENCE) {
    return input.books
  }

  return []
}

export default getAnalyticInputBooksUtil
