import React from 'react'
import { BookInterface } from '../../../../../core/interface/book.interface'
import { ReactComponent as SubLevelIcon } from '../../../../../assets/icons/app/sub-level.icon.svg'
import EditionsTableRow from './EditionsTableRow'

const EditionsTableRowDropDownChildren = ({
  disable,
  isChecked,
  book,
  color,
  handleUpdateBookSelected,
}: {
  disable: boolean
  isChecked: boolean
  book: BookInterface
  color: string
  handleUpdateBookSelected: (books: BookInterface) => void
}) => (
  <div className="flex flex-row gap-1 w-full">
    <div className="flex items-center justify-center flex-none">
      <SubLevelIcon />
    </div>
    <EditionsTableRow
      disable={disable}
      book={book}
      color={color}
      isChecked={isChecked}
      handleOnClick={() => handleUpdateBookSelected(book)}
    />
  </div>
)

export default EditionsTableRowDropDownChildren
