import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AuthorInterface } from '../../../interface/author.interface'

export const getAuthorByBookEanService = (
  { authorName, ean }: { authorName: string; ean: number },
  cancelToken?: CancelToken,
): Promise<AxiosResponse<AuthorInterface>> =>
  apiInstance.get(ApiPathConfig.AUTHOR, {
    params: { name: authorName, ean },
    cancelToken,
  })
