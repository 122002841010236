import { useTranslation } from 'react-i18next'
import { EditOptionTypeEnum } from '../enums/analytic-edit-option-type.enum'
import { EditSectionEnum } from '../enums/analytic-edit-section.enum'
import { mapSizes } from '../enums/analytic-output-layout-size.enum'
import { mapTextSizes } from '../enums/analytic-output-text-size.enum'
import { mapTexboxTheme } from '../enums/analytic-output-textbox-theme.enum'
import { AnalyticOutputInterface } from '../interface/analytics/analytic-output.interface'

export const ConvertOutputTextboxToOption = (
  output: AnalyticOutputInterface,
) => {
  const { t } = useTranslation()

  const layoutSizeAvailable = mapSizes(
    output.layout_size_config?.sizes || [],
    output.layout_size_config?.default_size,
  )
  const layoutSizeSelected =
    layoutSizeAvailable.find((size) => size.value === output.layout_size) ||
    layoutSizeAvailable[0]

  const textSizeAvailable = mapTextSizes()
  const textSizeSelected =
    textSizeAvailable.find(
      (size) => size.value === output.textbox?.text_size,
    ) || textSizeAvailable[0]

  const textboxThemeAvailable = mapTexboxTheme()
  const textboxThemeSelected =
    textboxThemeAvailable.find(
      (theme) => theme.value === output.textbox?.use_dark_theme,
    ) || textboxThemeAvailable[0]

  return [
    {
      id: 'layout_size',
      section: EditSectionEnum.DISPLAY,
      label: t('moduleSize'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: layoutSizeAvailable,
      dropdownEntrySelected: layoutSizeSelected,
    },
    {
      id: 'use_dark_theme',
      section: EditSectionEnum.OPTIONS,
      label: t('color'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: textboxThemeAvailable,
      dropdownEntrySelected: textboxThemeSelected,
    },
    {
      id: 'text_size',
      section: EditSectionEnum.OPTIONS,
      label: t('fontSize'),
      type: EditOptionTypeEnum.DROPDOWN,
      value: true,
      dropdownEntries: textSizeAvailable,
      dropdownEntrySelected: textSizeSelected,
    },
  ]
}
