import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { getFullNameUtil } from '../../../core/utils/get-full-name.util'
import { PersonInterface } from '../../../core/enums/person.interface'
import { TeamInterface } from '../../../core/interface/team.interface'
import { ReactComponent as PersonIcon } from '../../../assets/icons/app/person.icon.svg'
import { ReactComponent as TeamsIcon } from '../../../assets/icons/app/teams.icon.svg'
import { normalizedContains } from '../../../core/utils/search-text.util'

const InputUsersTeamsStyle = styled.input`
  ::placeholder {
    font-family: 'Fira Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #b2b2b2;
  }

  width: 100%;
  padding: 4px;
  box-shadow: 0 1px 0 0 #e3e3e3;
  :focus {
    outline: none;
    box-shadow: 0 1px 0 0 #252525;
  }
`

const ItemStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const ButtonStyle = styled.button`
  width: 100%;
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e3e3e3;
  }
`
const InputUsersShareReport = ({
  users,
  teams,
  usersSelected,
  teamsSelected,
  selectUserToShareReport,
  selectTeamToShareReport,
}: {
  users: PersonInterface[]
  teams: TeamInterface[]
  usersSelected: PersonInterface[]
  teamsSelected: TeamInterface[]
  selectUserToShareReport: (user: PersonInterface) => void
  selectTeamToShareReport: (team: TeamInterface) => void
}) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const [openList, setOpenList] = useState<boolean>(false)

  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (openList) {
        setOpenList(false)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer])

  const onClickSelectUser = (user: PersonInterface) => {
    setOpenList(false)
    selectUserToShareReport(user)
  }

  const onClickSelectTeam = (team: TeamInterface) => {
    setOpenList(false)
    selectTeamToShareReport(team)
  }
  return (
    <div
      className="relative"
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
    >
      <InputUsersTeamsStyle
        type="text"
        placeholder={t('webApp.reports.shared.placeHolderUsersTeams') || ''}
        value={query}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setQuery(event.target.value)
        }
        onFocus={() => setOpenList(true)}
        onBlur={() => setNeedClose(true)}
      />
      {(openList && (users.length || teams.length) && (
        <div className="absolute top-10 left-0 w-full border border-[#e4e4e4] rounded w-full z-30 bg-white flex flex-col max-h-36">
          <PerfectScrollbar className="flex flex-col items-start">
            {users
              .filter(
                (user: PersonInterface) =>
                  !usersSelected.some(
                    (userSelected: PersonInterface) =>
                      userSelected.id === user.id,
                  ) && normalizedContains(getFullNameUtil(user), query),
              )
              .map((user: PersonInterface) => (
                <ButtonStyle
                  type="button"
                  className="flex flex-row gap-1 p-2"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    onClickSelectUser(user)
                  }}
                  key={nanoid(5)}
                  onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) =>
                    event.currentTarget.focus()
                  }
                >
                  <div className="w-4 h-4">
                    <PersonIcon className="w-4 h-4" />
                  </div>
                  <ItemStyle>{getFullNameUtil(user)}</ItemStyle>
                  <ItemStyle style={{ color: '#B2B2B2' }}>
                    {user.email}
                  </ItemStyle>
                </ButtonStyle>
              ))}
            {teams
              .filter(
                (team: TeamInterface) =>
                  !teamsSelected.some(
                    (teamSelected: TeamInterface) =>
                      teamSelected.id === team.id,
                  ) && normalizedContains(team.name, query),
              )
              .map((team: TeamInterface) => (
                <ButtonStyle
                  type="button"
                  className="flex flex-row gap-1 p-2"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    onClickSelectTeam(team)
                  }}
                  key={nanoid(5)}
                  onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) =>
                    event.currentTarget.focus()
                  }
                >
                  <div className="w-4 h-4">
                    <TeamsIcon className="w-4 h-4" />
                  </div>
                  <ItemStyle>{team.name}</ItemStyle>
                  <ItemStyle
                    style={{ color: '#B2B2B2' }}
                  >{`Equipe de l'organisation`}</ItemStyle>
                </ButtonStyle>
              ))}
          </PerfectScrollbar>
        </div>
      )) ||
        null}
    </div>
  )
}

export default InputUsersShareReport
