import {
  Customized,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { useCallback, useEffect, useState } from 'react'
import { CategoricalChartState } from 'recharts/types/chart/types'
import RadarAxisLine from './radarRechart/RadarAxisLine'
import RadarRadiusLine from './radarRechart/RadarRadiusLine'
import CustomizedRadiusTick from './radarRechart/RadarRadiusTick'
import RadarAxisTick from './radarRechart/RadarAxisTick'
import RadarDot from './radarRechart/RadarDot'
import RadarInnerRadius from './radarRechart/RadarInnerRadius'
import RadarActiveDot from './radarRechart/RadarActiveDot'
import { clilReferencesConfig } from '../../../../../../../core/config/clil-references.config'
import { DatasetInterface } from '../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetTypeEnum } from '../../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../../core/interface/analytics/value-configurations.interface'
import CustomLegendMulti from './LibraryStructure/CustomLegendMulti'
import CustomTooltipMulti from './tooltip/CustomTooltipMulti'

type Props = {
  valuesConfigurations: ValueConfigurationsInterface[]
  primaryKey: SourceEnum
  color1: string
  color2: string
  dataset: Array<DatasetInterface>
  innerRadius?: number
  compareGroup?: boolean
  typeDataset: DatasetTypeEnum
  onClick?: (clil: number, lvl: number) => void
  level?: number
  isPreview: boolean
}

const RadarRechart = ({
  valuesConfigurations,
  primaryKey,
  color1,
  color2,
  dataset,
  innerRadius = 0,
  compareGroup = true,
  typeDataset,
  onClick,
  level,
  isPreview,
}: Props) => {
  const [mappedDataset, setMappedDataset] = useState<
    Array<
      DatasetInterface & {
        label: string
      }
    >
  >([])
  const isClickable =
    typeDataset === DatasetTypeEnum.CLIL &&
    onClick &&
    level &&
    level < 3 &&
    !isPreview

  useEffect(() => {
    const otherValuesConfigurations = valuesConfigurations.filter(
      (p) => !p.is_primary_key,
    )
    const study = otherValuesConfigurations[0]
    const compare = otherValuesConfigurations[1]
    if (dataset) {
      setMappedDataset(
        dataset.map(
          (item) =>
            (item[compare.source] !== 0
              ? {
                  ...item,
                  [study.source]: item[study.source],
                  [compare.source]: item[compare.source],
                  label: item[primaryKey],
                }
              : {
                  ...item,
                  [study.source]: 100,
                  [compare.source]: 0,
                  label: item[primaryKey],
                }) as DatasetInterface & {
              label: string
            },
        ),
      )
    }
  }, [valuesConfigurations, dataset])

  const AxisTick = useCallback(
    (props: {
      payload: { value: number; index: number }
      x: number
      y: number
      cx: number
      cy: number
      radius: number
      usingPayloadValue?: boolean
    }) => {
      if (mappedDataset.length === 0) return <g />

      const { label } = mappedDataset[props.payload.index]
      return (
        <RadarAxisTick
          {...props}
          color={
            (label &&
              clilReferencesConfig[label] &&
              clilReferencesConfig[label].color) ||
            '#e9e9e9'
          }
        />
      )
    },
    [mappedDataset],
  )

  const CustomTooltip = useCallback(
    (props: any) => {
      return (
        <CustomTooltipMulti
          {...props}
          typeDataset={typeDataset}
          isClickable={isClickable}
          showCompareStudyObject
          showDelta
          addPositiveSign
        />
      )
    },
    [typeDataset],
  )

  const InnerRadius = useCallback(
    ({ width, height }: any) => {
      return (
        <RadarInnerRadius
          radius={innerRadius}
          fill="#F7F7F7"
          height={height}
          width={width}
        />
      )
    },
    [typeDataset],
  )

  function aroundToFifty(value: number) {
    const reste = value % 50
    if (reste === 0) {
      return value
    }
    return value + (50 - reste)
  }

  const maxScaleRadar = mappedDataset.reduce((maxValueOverall, currentItem) => {
    const maxValueStudyObject = Math.max(
      maxValueOverall,
      currentItem[SourceEnum.SUBJECT_OBJECT_VALUE] || 0,
    )
    const maxValueCompareGroup = Math.max(
      maxValueOverall,
      currentItem[SourceEnum.COMPARE_GROUP_VALUE] || 0,
    )

    return aroundToFifty(Math.max(maxValueStudyObject, maxValueCompareGroup))
  }, -Infinity)

  const onClickClil = (payload: CategoricalChartState) => {
    const { activePayload } = payload
    if (isClickable && activePayload) {
      onClick(activePayload[0].payload.clil, level)
    }
  }

  return (
    <>
      {/* https://github.com/recharts/recharts/issues/172#issuecomment-545975536 */}
      <div className="relative flex justify-center items-center flex-1 w-full overflow-hidden z-10">
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart
            style={{
              position: 'relative',
              zIndex: 100,
              cursor: isClickable ? 'pointer' : 'default',
            }}
            cx="50%"
            cy="50%"
            data={mappedDataset}
            startAngle={120}
            endAngle={-240}
            innerRadius={innerRadius}
            onClick={onClickClil}
          >
            <Tooltip content={CustomTooltip} />
            <PolarGrid gridType="circle" stroke="#3B3B3B" radialLines={false} />
            {compareGroup && (
              <Radar
                dot={RadarDot}
                activeDot={<g />}
                dataKey={SourceEnum.COMPARE_GROUP_VALUE}
                stroke={color2}
                fill={color2}
                fillOpacity={0.1}
              />
            )}
            <Radar
              label={false}
              dot={RadarDot}
              activeDot={RadarActiveDot}
              dataKey={SourceEnum.SUBJECT_OBJECT_VALUE}
              stroke={color1}
              fill={color1}
              fillOpacity={0.1}
            />
            {innerRadius && <Customized component={InnerRadius} />}
            <PolarAngleAxis dataKey={primaryKey} tick={AxisTick} />
            <PolarRadiusAxis
              angle={90}
              axisLine={false}
              domain={[0, maxScaleRadar]}
              orientation="middle"
              tickCount={3}
              tick={CustomizedRadiusTick}
            />
          </RadarChart>
        </ResponsiveContainer>
        <RadarRadiusLine
          radiusNbr={5}
          maxValue={200}
          innerRadius={innerRadius}
        />
        <RadarAxisLine
          axisNbr={mappedDataset.length}
          startAngle={120}
          innerRadius={innerRadius}
        />
      </div>
      <CustomLegendMulti
        dataset={dataset}
        typeDataset={typeDataset}
        displayClilColors
      />
    </>
  )
}

export default RadarRechart
