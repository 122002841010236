import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const patchReportChangeOwnerService = (
  {
    reportId,
    ownerId,
  }: {
    reportId: string
    ownerId: string
  },
  cancelToken?: CancelToken,
) => {
  return apiInstance.patch(
    ApiPathConfig.REPORT_BY_ID_CHANGE_OWNER(reportId),
    {
      owner: ownerId,
    },
    { cancelToken },
  )
}
