import React from 'react'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { ReactComponent as SearchIcon } from '../../../assets/icons/app/search.icon.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/app/cross.icon.svg'

const InputStyle = styled.input`
  width: 100%;
  height: 32px;
  background: #f4f4f4;
  color: #595959;
  padding-left: 32px;

  &:focus {
    background: #e3e3e3;
  }
`

declare type InputType = 'text' | 'number'
interface Props {
  type: InputType
  value: string
  placeholderValue: string
  onChangeValue: (value: string | number) => void
  onResetValue?: () => void
  hasButtonReset?: boolean
}

const GlFilterInput = ({
  type,
  placeholderValue,
  value,
  onChangeValue,
  hasButtonReset = false,
  onResetValue = undefined,
}: Props) => (
  <div className="flex flex-row relative items-center">
    <SearchIcon className="absolute w-4 h-4 text-[#252525] top-2 left-2" />
    <InputStyle
      id={nanoid(5)}
      placeholder={placeholderValue}
      className="flex-auto rounded-t focus:outline-none"
      type={type}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(event.target.value)
      }}
    />
    {(hasButtonReset && (
      <button
        type="button"
        className="absolute right-2"
        onClick={() => onResetValue && onResetValue()}
      >
        <CrossIcon className="w-3 h-3" />
      </button>
    )) ||
      null}
  </div>
)
export default GlFilterInput
