import { nanoid } from 'nanoid'
import React from 'react'
import styled from '@emotion/styled'
import GlHDivider from '../GlHDivider'
import { GenreOptionsConfig } from '../../../core/config/GenreOptionsConfig'
import { AgeOptionsConfig } from '../../../core/config/AgeOptionsConfig'
import { ReactComponent as SelectedIcon } from '../../../assets/icons/selected.icon.svg'
import { AgeFilterEnum } from '../../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../../core/enums/gender-filter.enum'

const MenuStyle = styled.div`
  z-index: 35;
  position: absolute;
  top: 36px;
  left: 0;
  height: ${({ open }: { open: boolean }) => (open ? 'auto' : '0')};
  max-height: ${({ open }: { open: boolean }) => (open ? '334px' : '0')};
  visibility: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }: { open: boolean }) => (open ? '1' : '0')};
  background: white;
  width: 100%;
  border-radius: 4px;
  -webkit-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
`

const TitleSelectStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #b2b2b2;
  padding: 8px 12px;
`

const GlReadersMenu = ({
  open,
  handleChangeQuery,
  genderSelected,
  ageSelected,
}: {
  ageSelected: AgeFilterEnum
  genderSelected: GenderFilterEnum
  handleChangeQuery: (key: any, value: any) => void
  open: boolean
}) => (
  <MenuStyle open={open} className="shadow-2xl z-30">
    <div>
      <TitleSelectStyle>GENRE</TitleSelectStyle>
      <GlHDivider />
      {GenreOptionsConfig.map((option) => (
        <div
          key={nanoid(5)}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            handleChangeQuery('gender', option.value)
          }}
          className={`
                flex
                justify-between
                h-7
                items-center
                px-3
                py-[4.5px]
                ${(genderSelected === option.value && 'bg-[#e3e3e3]') || ''}
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
              `}
        >
          <span>{option.label}</span>
          {genderSelected === option.value && <SelectedIcon />}
        </div>
      ))}
    </div>
    <div>
      <TitleSelectStyle>ÂGE</TitleSelectStyle>
      <GlHDivider />
      {AgeOptionsConfig.map((option) => (
        <div
          key={nanoid(5)}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            handleChangeQuery('age', option.value)
          }}
          className={`
                flex
                justify-between
                h-7 
                items-center
                px-3
                py-[4.5px]
                ${(ageSelected === option.value && 'bg-[#e3e3e3]') || ''}
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
              `}
        >
          <span>{option.label}</span>
          {ageSelected === option.value && <SelectedIcon />}
        </div>
      ))}
    </div>
  </MenuStyle>
)

export default GlReadersMenu
