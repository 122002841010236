import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import ConstellationDataInterface from './constellation-data.interface'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import getClilColor from '../../../../../../../../core/utils/get-clil-color'

const getPopularityRadiusValue = (value: number) => {
  if (value > 80) return 10
  if (value > 60) return 8
  if (value > 40) return 6
  if (value > 20) return 3
  return 1
}

const createLevel = (
  dataset: DatasetInterface[],
  center: { x: number; y: number },
  radius: number,
  filterFn: (angle: number) => boolean,
  isAuthor: boolean,
  itemsCount = 8,
) => {
  const level: ConstellationDataInterface[] = []
  const angleStep = (2 * Math.PI) / itemsCount

  for (let i = 0; i < itemsCount; i += 1) {
    const angle = i * angleStep - Math.PI / 2

    if (dataset[0] && filterFn(angle)) {
      const x = center.x + radius * Math.cos(angle)
      const y = center.y + radius * Math.sin(angle)
      const data = dataset.shift()

      if (data) {
        level.push({
          x,
          y,
          r: getPopularityRadiusValue(
            data[
              isAuthor
                ? SourceEnum.AUTHOR_POPULARITY
                : SourceEnum.WORK_POPULARITY
            ] || 0,
          ),
          angle,
          color: isAuthor
            ? '#252525'
            : getClilColor(data[SourceEnum.CLIL_FIRST]),
          data,
          center,
        })
      }
    }
  }
  return level
}

const ConstellationPositionUtil = ({
  center,
  radiusBaseCircle,
  dataset,
  isAuthor,
}: {
  center: { x: number; y: number }
  radiusBaseCircle: number[]
  dataset: DatasetInterface[]
  isAuthor: boolean
}): {
  level1: ConstellationDataInterface[]
  level2: ConstellationDataInterface[]
  level3: ConstellationDataInterface[]
} => {
  const datasetCopy = [...dataset]

  const level1 = createLevel(
    datasetCopy,
    center,
    radiusBaseCircle[0],
    () => true,
    isAuthor,
  )
  const level2 = createLevel(
    datasetCopy,
    center,
    radiusBaseCircle[1],
    (angle) => Math.cos(angle) !== 1 && Math.cos(angle) !== -1,
    isAuthor,
  )
  const level3 = createLevel(
    datasetCopy,
    center,
    radiusBaseCircle[2],
    () => true,
    isAuthor,
    datasetCopy.length,
  )

  return { level1, level2, level3 }
}

export default ConstellationPositionUtil
