import React, { ReactElement } from 'react'
import styled from '@emotion/styled'

const Label = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #595959;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`
interface Props {
  labelValue?: string | number
  className?: string
  children?: ReactElement | null
}

const GlCellBody = ({
  labelValue = '',
  className = '',
  children = null,
}: Props) => {
  return (
    <div
      className={`flex flex-1 flex-auto items-center justify-start p-1 text-left ${className}`}
    >
      {children || <Label>{labelValue}</Label>}
    </div>
  )
}

export default GlCellBody
