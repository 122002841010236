import { IconContainerStyle, TitleSectionStyle } from './OrganizeMenuStyles'

interface Props {
  itemId: string
  itemTitle: string
  itemIndex: number
  ItemIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  isTitle?: boolean
  dropOnOutput: () => void
  dragEnterOutput: (position: number) => void
  dragStartOutput: (position: number) => void
  handleScrollToThisId: (id: string) => void
}

const OrganizeMenuDraggableItem = ({
  itemId,
  itemTitle,
  itemIndex,
  ItemIcon,
  isTitle = false,
  dropOnOutput,
  dragEnterOutput,
  dragStartOutput,
  handleScrollToThisId,
}: Props) => {
  return (
    <div
      className="flex p-1 gap-2 items-center bg-white cursor-grab hover:bg-[#e9e9e9]"
      style={{
        paddingLeft: isTitle ? '4px' : '32px',
        paddingTop: isTitle ? '12px' : '4px',
      }}
      key={itemId}
      onDragStart={(e) => {
        e.dataTransfer.effectAllowed = 'all'
        dragStartOutput(itemIndex)
      }}
      onDragOver={(e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
        dragEnterOutput(itemIndex)
      }}
      onClick={() => {
        handleScrollToThisId(itemId)
      }}
      onDragEnd={dropOnOutput}
      draggable
    >
      <IconContainerStyle>
        <ItemIcon className="w-4 h-4 shrink-0" />
      </IconContainerStyle>
      <TitleSectionStyle
        style={{
          fontWeight: isTitle ? '500' : '400',
        }}
      >
        {itemTitle}
      </TitleSectionStyle>
    </div>
  )
}

export default OrganizeMenuDraggableItem
