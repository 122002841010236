import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import GlHDivider from '../../../share/GlHDivider'
import { getFullNameUtil } from '../../../../core/utils/get-full-name.util'
import { UserPermissionInterface } from '../../../../core/interface/user-permission.interface'
import { PersonInterface } from '../../../../core/enums/person.interface'
import { TeamInterface } from '../../../../core/interface/team.interface'
import { PermissionEnum } from '../../../../core/enums/permission.enum'
import { ReactComponent as TeamsIcon } from '../../../../assets/icons/app/teams.icon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/app/close.icon.svg'
import { ReactComponent as PersonIcon } from '../../../../assets/icons/app/person.icon.svg'
import {
  buildRelativePath,
  PathConfig,
} from '../../../../core/config/PathConfig'
import GlSpinner from '../../../share/GlSpinner'
import PermissionsMenuLight from '../../../analytics-details/PermissionsMenuLight'
import InputUsersShareReport from '../../../analytics-details/share/InputUsersShareReport'
import GlLoader from '../../../share/GlLoader'
import PermissionsMenu from '../../../analytics-details/PermissionsMenu'
import useAnalyticsDashboardShareDialogHook from './useAnalyticsDashboardShareDialogHook'
import GlDialogTitle from '../../../share/dialog/GlDialogTitle'
import { AnalyticsTypeEnum } from '../../../../core/enums/analytics-type.enum'
import { setShowSnackBar } from '../../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../../store/hooks'

const TitleStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: #252525;
  padding-right: 18px;
`

const DescriptionStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
`

const RoundedButton = styled.button`
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  height: 32px;
  border-radius: 20px;
  border: 1px solid #252525;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
`

const RoundedButtonStyle = styled.button`
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  min-width: 119px;
  max-width: 119px;
  border-radius: 20px;
  border: 1px solid #252525;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  :hover {
    ${({ isMain }: { isMain: boolean }) =>
      !isMain ? 'background: #f4f4f4' : 'opacity: 0.8'};
  }

  :active {
    ${({ isMain }: { isMain: boolean }) =>
      !isMain ? 'background: #e3e3e3' : 'opacity: 1'};
  }

  &:disabled {
    ${({ isMain }: { isMain: boolean }) =>
      !isMain ? 'background: #e3e3e3' : 'opacity: 0.5'};
  }
`

const TextAreaMessageStyle = styled.textarea`
  ::placeholder {
    font-family: 'Fira Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: #b2b2b2;
  }

  width: 100%;
  padding: 4px;
  box-shadow: 0 1px 0 0 #e3e3e3;
  :focus {
    outline: none;
    box-shadow: 0 1px 0 0 #252525;
  }
`

const UsersTeamsContainer = styled.div`
  height: 160px;
`

const TagsInputStyle = styled.div`
  display: inline-flex;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  font-size: 14px;
`

const TagStyle = styled.div`
  display: flex;
  border: 1px solid #e2e2e2;
  padding: 4px 8px;
  border-radius: 36px;
  margin: 4px;
`

const TagButton = styled.button`
  border-radius: 16px;
  color: #e3e3e3;
  padding: 4px;
  &:hover {
    background-color: #f4f4f4;
    color: #252525;
  }
  &:active {
    background-color: #e3e3e3;
    color: #252525;
  }
`

const AnalyticsDashboardShareDialog = ({
  analyticsName,
  analyticsId,
  owner,
  slug,
  companyUserId,
  analyticsType,
}: {
  analyticsName: string
  analyticsId: string
  owner: any
  slug: string
  companyUserId: string
  analyticsType: AnalyticsTypeEnum
}) => {
  const { t } = useTranslation()
  const [permission, setPermission] = useState<PermissionEnum>(
    analyticsType === AnalyticsTypeEnum.READERSHIP_PROFILE
      ? PermissionEnum.READ
      : PermissionEnum.EDIT,
  )
  const [usersSelected, setUsersSelected] = useState<PersonInterface[]>([])
  const [teamsSelected, setTeamsSelected] = useState<TeamInterface[]>([])
  const [message, setMessage] = useState<string>('')

  const callbackAddUserSuccess = () => {
    setUsersSelected([])
    setTeamsSelected([])
  }

  const {
    state: { isLoading: isLoadingShareDialog, users, teams, usersShare },
    actions: {
      addUserTeamsToReport,
      updatePermissionToUser,
      handleChangeOwner,
      deleteUserToShareReport,
      getReportUsersShare,
      getAllUsersFromCompany,
      getAllTeamsFromCompany,
    },
  } = useAnalyticsDashboardShareDialogHook({
    callbackAddUserSuccess,
  })

  const dispatch = useAppDispatch()
  const sendMessageClipBoardSuccess = (link: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.copied')))
      })
      .catch(() => {})
  }

  useEffect(() => {
    getReportUsersShare(analyticsId)
    getAllUsersFromCompany()
    getAllTeamsFromCompany()
  }, [])

  const hasTeamOrUserSelected = (): boolean =>
    usersSelected.length > 0 || teamsSelected.length > 0

  return (
    (hasTeamOrUserSelected() && (
      <div
        className="flex flex-col gap-4"
        style={{
          width: 580,
          zIndex: 600,
        }}
      >
        <GlDialogTitle
          label={`${t('webApp.reports.shared.title', {
            title: analyticsName,
          })}`}
        />
        <TagsInputStyle className="flex flex-row justify-start items-start w-full">
          <PerfectScrollbar
            className="flex flex-row flex-wrap justify-start items-start w-full"
            style={{
              height: 144,
            }}
          >
            <div className="flex flex-row flex-wrap justify-start items-start w-full">
              {usersSelected.map((user: PersonInterface) => (
                <TagStyle
                  key={nanoid(5)}
                  className="flex flex-row gap-1 items-center justify-between h-8 w-auto"
                >
                  <PersonIcon className="w-4 h-4" />
                  <span>{getFullNameUtil(user)}</span>
                  <TagButton
                    type="button"
                    onClick={() => {
                      const newUsers = usersSelected.filter(
                        (userFiltered: PersonInterface) =>
                          userFiltered.id !== user.id,
                      )
                      setUsersSelected(newUsers)
                      if (!teamsSelected.length && !newUsers.length) {
                        setPermission(PermissionEnum.EDIT)
                      }
                    }}
                  >
                    <CloseIcon className="w-3 h-3" />
                  </TagButton>
                </TagStyle>
              ))}
              {teamsSelected.map((team: TeamInterface) => (
                <TagStyle
                  key={nanoid(5)}
                  className="flex flex-row  gap-1 items-center justify-between h-8 w-auto"
                >
                  <TeamsIcon className="w-4 h-4" />
                  <span>{team.name}</span>
                  <TagButton
                    type="button"
                    onClick={() => {
                      const newTeams = teamsSelected.filter(
                        (teamFiltered: TeamInterface) =>
                          teamFiltered.id !== team.id,
                      )
                      setTeamsSelected(newTeams)
                      if (!usersSelected.length && !newTeams.length) {
                        setPermission(PermissionEnum.EDIT)
                      }
                    }}
                  >
                    <CloseIcon className="w-3 h-3" />
                  </TagButton>
                </TagStyle>
              ))}
            </div>
          </PerfectScrollbar>
          <div
            style={{
              float: 'right',
            }}
          >
            <PermissionsMenuLight
              permission={permission}
              handleChangePermission={setPermission}
              users={users}
              teams={teams}
              analyticsType={analyticsType}
            />
          </div>
        </TagsInputStyle>
        <InputUsersShareReport
          usersSelected={[
            owner,
            ...usersShare.map(
              (userShare: UserPermissionInterface) => userShare.user,
            ),
            ...usersSelected,
          ]}
          teamsSelected={teamsSelected}
          users={users}
          teams={teams}
          selectUserToShareReport={(user: PersonInterface) => {
            setUsersSelected([...usersSelected, user])
          }}
          selectTeamToShareReport={(team: TeamInterface) => {
            setTeamsSelected([...teamsSelected, team])
          }}
        />
        <TextAreaMessageStyle
          rows={6}
          placeholder="Ajouter un message"
          value={message}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setMessage(event.target.value)
          }
        />
        <GlHDivider />
        <div className="flex flex-row justify-between items-center">
          <RoundedButtonStyle
            type="button"
            isMain={false}
            className="text-[#252525] p-2 h-10"
            onClick={() => {
              setTeamsSelected([])
              setUsersSelected([])
              setPermission(PermissionEnum.EDIT)
            }}
          >
            {t('commons.buttons.cancel')}
          </RoundedButtonStyle>
          <RoundedButtonStyle
            type="button"
            isMain
            className=" relative bg-[#252525] text-white h-10 items-center justify-center p-2"
            onClick={() => {
              let usersToAdd = [...usersSelected]
              if (teamsSelected && teamsSelected.length) {
                teamsSelected.forEach((team) => {
                  usersToAdd = [...usersToAdd, ...team.users]
                })
              }

              const uniqList: PersonInterface[] = []
              usersToAdd.forEach((userToAdd) => {
                if (!uniqList.some((user) => user.id === userToAdd.id)) {
                  uniqList.push(userToAdd)
                }
              })
              addUserTeamsToReport(analyticsId, uniqList, permission, message)
            }}
            disabled={isLoadingShareDialog}
          >
            {isLoadingShareDialog && (
              <div className="absolute w-full h-full top-0 left-0 justify-center items-center p-0.5">
                <GlSpinner />
              </div>
            )}
            <span>{t('commons.buttons.send')}</span>
          </RoundedButtonStyle>
        </div>
      </div>
    )) || (
      <div className="flex flex-col gap-4">
        <GlDialogTitle
          label={`${t('webApp.reports.shared.title', {
            title: analyticsName,
          })}`}
        />
        <InputUsersShareReport
          usersSelected={[
            owner,
            ...usersShare.map(
              (userShare: UserPermissionInterface) => userShare.user,
            ),
            ...usersSelected,
          ]}
          teamsSelected={teamsSelected}
          users={users}
          teams={teams}
          selectUserToShareReport={(user: PersonInterface) => {
            setUsersSelected([user])
          }}
          selectTeamToShareReport={(team: TeamInterface) => {
            setTeamsSelected([team])
          }}
        />
        <TitleStyle>{t('webApp.reports.shared.userWithAccess')}</TitleStyle>
        <UsersTeamsContainer>
          {(isLoadingShareDialog && (
            <div className="flex h-full w-full items-center justify-center">
              <GlLoader />
            </div>
          )) || (
            <PerfectScrollbar className="flex flex-col gap-1 pr-3">
              {(owner && (
                <DescriptionStyle className="flex flex-row justify-between items-center">
                  <span>{getFullNameUtil(owner)}</span>
                  <PermissionsMenu
                    permission={PermissionEnum.EDIT}
                    isOwner
                    displayMenuOwner={false}
                  />
                </DescriptionStyle>
              )) ||
                null}
              {usersShare
                .filter(
                  (user: UserPermissionInterface) => user.user.id !== owner.id,
                )
                .map((user: UserPermissionInterface) => (
                  <DescriptionStyle
                    key={nanoid(5)}
                    className="flex flex-row justify-between  items-center"
                  >
                    <span>{getFullNameUtil(user.user)}</span>
                    <PermissionsMenu
                      permission={user.permission}
                      isOwner={owner.id === companyUserId}
                      displayMenuOwner
                      handleChangePermission={(permission: PermissionEnum) => {
                        updatePermissionToUser(
                          analyticsId,
                          user.user,
                          permission,
                        )
                      }}
                      handleDeleteUserPermission={() => {
                        deleteUserToShareReport(analyticsId, user.user)
                      }}
                      handleChangeOwner={() =>
                        handleChangeOwner({
                          reportId: analyticsId,
                          ownerId: user?.user.id,
                        })
                      }
                      analyticsType={analyticsType}
                    />
                  </DescriptionStyle>
                ))}
            </PerfectScrollbar>
          )}
        </UsersTeamsContainer>
        <GlHDivider />
        <TitleStyle>{t('webApp.reports.shared.generalAccess')}</TitleStyle>
        <DescriptionStyle className="flex flex-row justify-between">
          <span>{t('webApp.reports.shared.generalAccessDescription')}</span>
        </DescriptionStyle>
        <DescriptionStyle className="flex flex-row border border-[#E3E3E3] rounded items-center p-1 w-full gap-2">
          <div className="flex-grow select-text">
            {`${window.location.origin}${buildRelativePath([
              PathConfig.SHARED,
              PathConfig.ANALYTICS,
              slug,
            ])}`}
          </div>

          <RoundedButton
            type="button"
            className="bg-[#252525] text-white flex-auto flex-wrap max-w-[30%]"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              event.preventDefault()
              sendMessageClipBoardSuccess(
                `${window.location.origin}${buildRelativePath([
                  PathConfig.SHARED,
                  PathConfig.ANALYTICS,
                  slug,
                ])}`,
              )
            }}
          >
            <span>{t('commons.buttons.copyLink')}</span>
          </RoundedButton>
        </DescriptionStyle>
      </div>
    )
  )
}

export default AnalyticsDashboardShareDialog
