import { CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'

export enum GetBookGetTypeEnum {
  GLID = 'glid',
  AUTHOR_ID = 'author_id',
}

export const getBookGetService = (
  { type, id }: { type: GetBookGetTypeEnum; id: string },
  cancelToken?: CancelToken,
) =>
  apiInstance.get(ApiPathConfig.BOOKS_EDITIONS, {
    cancelToken,
    params: { type, id },
  })
