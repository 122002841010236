import React, { useEffect, useRef, useState } from 'react'
import { orderBy } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import { WM_COLUMN_KEY_POSITION } from '../../../../../core/config/reports/wm-report-table.config'
import GlLoader from '../../../../share/GlLoader'
import AnalyticDetailsTableHeader from './AnalyticDetailsTableHeader'
import AnalyticDetailsTableBody from './AnaliticDetailsTableBody'
import { WorkMirrorBookInterface } from '../../../../../core/interface/report/work-mirror-book.interface'

const AnalyticDetailsTable = ({
  isLoadingReloadOutput,
  booksOutput,
  handleBookSelected,
  bookDetailsSelected,
  bookEanIdHovered,
}: {
  isLoadingReloadOutput: boolean
  booksOutput: WorkMirrorBookInterface[]
  handleBookSelected: (eanId: WorkMirrorBookInterface | null) => void
  bookDetailsSelected: WorkMirrorBookInterface | null
  bookEanIdHovered: number | null
}) => {
  const refContainerScroll = useRef<any>(null)
  const [sortDetail, setSortDetails] = useState<{
    sortDirection: SortDirectionEnum
    key: string
  }>({ sortDirection: SortDirectionEnum.ASC, key: WM_COLUMN_KEY_POSITION })

  const sortedBooks = orderBy(
    booksOutput,
    [sortDetail.key],
    [sortDetail.sortDirection === SortDirectionEnum.ASC ? 'asc' : 'desc'],
  )

  useEffect(() => {
    if (refContainerScroll && bookEanIdHovered) {
      const element = document.getElementById(`line-table-${bookEanIdHovered}`)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [bookEanIdHovered])

  return (
    <div className="flex flex-col flex-auto">
      <AnalyticDetailsTableHeader
        sortDetail={sortDetail}
        handleChangeSort={setSortDetails}
      />
      <PerfectScrollbar
        ref={refContainerScroll}
        className="flex-auto"
        style={{
          height: 0,
        }}
      >
        {(isLoadingReloadOutput && <GlLoader />) ||
          (booksOutput && booksOutput.length && (
            <AnalyticDetailsTableBody
              booksOutput={sortedBooks}
              hasSelect={!!handleBookSelected}
              handleBookSelected={handleBookSelected}
              bookDetailsSelected={bookDetailsSelected}
              bookEanIdHovered={bookEanIdHovered}
            />
          )) ||
          null}
      </PerfectScrollbar>
    </div>
  )
}

export default AnalyticDetailsTable
