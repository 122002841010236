import React from 'react'
import { ReactComponent as DeleteIcon } from '../../assets/icons/app/reset-icon.svg'

const GlTrashButton = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <button
      type="button"
      className="bg-[#DA6262] hover:bg-[#D34242] flex h-[28px] w-[28px] items-center justify-center rounded gap-2 cursor-pointer"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        handleClick()
      }}
    >
      <DeleteIcon className="text-white h-4 w-4" />
    </button>
  )
}

export default GlTrashButton
