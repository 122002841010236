import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GlMatInput from '../../components/share/inputs/GlMatInput'
import { postSignInAction } from '../../store/actions/sign-in.actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import GlMdRoundedButton from '../../components/share/buttons/GlMdRoundedButton'
import GlHDivider from '../../components/share/GlHDivider'
import GlPrefixIconButton from '../../components/share/buttons/GlPrefixIconButton'
import { ReactComponent as VisibilityIcon } from '../../assets/icons/app/visibility.icon.svg'
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/app/visibility-off.icon.svg'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import {
  selectSignInStateIsLoadingPostSignIn,
  selectSignInStateSignInError,
  selectSignInStateSignInMessageError,
  selectSignInStateSignInSuccess,
} from '../../store/getters/sign-in.getters'
import { reinitializeSignInState } from '../../store/reducers/sign-in.reducer'

const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 243px;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
`

const LinkStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;

  &:hover {
    color: #252525;
  }
`

const SIGN_UP_PATH_INFO = 'https://gleeph.pro/bookmetrie-offre-site'

const SignInView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useLocation()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const isLoadingPostSignIn = useAppSelector(
    selectSignInStateIsLoadingPostSignIn,
  )
  const signInSuccess = useAppSelector(selectSignInStateSignInSuccess)
  const signInError = useAppSelector(selectSignInStateSignInError)
  const signInMessageError = useAppSelector(selectSignInStateSignInMessageError)

  const handleOnSubmit = () => {
    void dispatch(postSignInAction({ email, password }))
  }

  useEffect(() => {
    if (signInSuccess) {
      if (search && search.startsWith('?redirect=')) {
        const newPath = search.replace('?redirect=', '')
        navigate(newPath)
      } else {
        navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
      }
    }
    if (signInError && signInMessageError !== 'discovery_expired') {
      if (
        [
          'user_deactivated',
          'discovery_expired',
          'no_active_contracts',
        ].includes(signInMessageError)
      ) {
        void dispatch(
          setShowSnackBar(t('commons.errorMessageCode.userDeactivated')),
        )
      } else {
        void dispatch(
          setShowSnackBar(t('authentication.sign-in.accountNotFound')),
        )
      }
    }
  }, [signInSuccess, signInError])

  useEffect(() => {
    return () => {
      dispatch(reinitializeSignInState())
    }
  }, [])

  if (
    signInError &&
    ['user_deactivated', 'discovery_expired', 'no_active_contracts'].includes(
      signInMessageError,
    )
  ) {
    return (
      <div className="z-10 w-[432px] flex flex-col bg-white items-center justify-center p-4 gap-4">
        <TitleContainer>Votre accès Bookmétrie a pris fin</TitleContainer>
        <GlHDivider />
        <div>
          <p>
            <span>
              Pour prolonger l&apos;expérience, contactez nous à&nbsp;
            </span>
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a>
          </p>
          <p>Pensez à bien spécifier votre adresse mail</p>
        </div>
        <GlHDivider />
        <div className="gap-4 flex flex-col p-4">
          <div className="flex flex-row gap-2 items-center justify-center">
            <a
              href={buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN])}
              className="text-center"
            >
              <LinkStyle>Se connecter</LinkStyle>
            </a>
            <span>/</span>
            <Link
              className="text-center"
              to={buildRelativePath([
                PathConfig.AUTH,
                PathConfig.FORGOT_PASSWORD,
              ])}
            >
              <LinkStyle>{t('webApp.signIn.forgotPassword')}</LinkStyle>
            </Link>
          </div>

          <GlMdRoundedButton
            label={t('commons.buttons.contactUs')}
            handleOnClick={() => {
              window.location.href = 'mailto:info@gleeph.pro'
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <div className="z-10 w-[360px] flex flex-col bg-white items-center justify-center p-4">
      <TitleContainer>{t('webApp.signIn.title')}</TitleContainer>

      <GlHDivider />
      <GlMatInput
        label={t('webApp.signIn.email.label')}
        placeholder={t('webApp.signIn.email.placeholder')}
        keyValue="email"
        type="email"
        value={email}
        onChangeValue={setEmail}
        hasClearButton
      />

      <GlMatInput
        label={t('webApp.signIn.password.label')}
        placeholder={t('webApp.signIn.password.placeholder')}
        keyValue="password"
        type={(passwordVisibility && 'text') || 'password'}
        value={password}
        onChangeValue={setPassword}
        hasClearButton
        prefix={
          <GlPrefixIconButton
            icon={
              !passwordVisibility ? (
                <VisibilityOffIcon className="w-4 h-4" />
              ) : (
                <VisibilityIcon className="w-4 h-4" />
              )
            }
            handleClick={() => setPasswordVisibility(!passwordVisibility)}
          />
        }
      />

      <GlHDivider />

      <div className="gap-4 flex flex-col p-4">
        <div className="flex flex-row gap-2 items-center justify-center">
          <Link
            className="text-center"
            to={buildRelativePath([
              PathConfig.AUTH,
              PathConfig.FORGOT_PASSWORD,
            ])}
          >
            <LinkStyle>{t('webApp.signIn.forgotPassword')}</LinkStyle>
          </Link>
          <span>/</span>
          <a
            href={SIGN_UP_PATH_INFO}
            className="text-center"
            target="_blank"
            rel="noreferrer"
          >
            <LinkStyle>{t('webApp.signIn.noAccountSignUp')}</LinkStyle>
          </a>
        </div>

        <GlMdRoundedButton
          label={t('commons.buttons.signIn')}
          hasLoader
          isLoading={isLoadingPostSignIn}
          disabled={!email || !password}
          handleOnClick={handleOnSubmit}
        />
      </div>
    </div>
  )
}

export default SignInView
