import { useEffect, useRef } from 'react'

const DEFAULT_EVENTS = ['mousedown', 'touchstart']

export function useClickOutside<T extends HTMLElement = any>(
  handler: (event: any) => void,
  events?: string[] | null,
  nodes?: (HTMLElement | null)[],
) {
  const ref = useRef<T>()

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {}
      const shouldIgnore =
        target?.hasAttribute('data-ignore-outside-clicks') ||
        (!document.body.contains(target) && target.tagName !== 'HTML')
      if (Array.isArray(nodes)) {
        const shouldTrigger = nodes.every(
          (node) => !!node && !event.composedPath().includes(node),
        )
        shouldTrigger && !shouldIgnore && handler(event)
      } else if (ref.current && !ref.current.contains(target)) {
        !shouldIgnore && handler(event)
      }
    }

    ;(events || DEFAULT_EVENTS).forEach((fn) =>
      document.addEventListener(fn, listener),
    )

    return () => {
      ;(events || DEFAULT_EVENTS).forEach((fn) =>
        document.removeEventListener(fn, listener),
      )
    }
  }, [ref, handler, nodes])

  return ref
}
