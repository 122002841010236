import {
  RequestFiltersGenderDynamicsType,
  RequestFiltersAgeDynamicsType,
} from '../../types/request-filters-users-dynamics.type'
import { GenderFilterEnum } from '../../enums/gender-filter.enum'
import { AgeFilterEnum } from '../../enums/age-filter.enum'

export const RequestFiltersGenderDynamicsConfig: RequestFiltersGenderDynamicsType =
  {
    [GenderFilterEnum.SEX_CATEGORY_ALL]:
      'webApp.dynamics.topRequest.gender.all',
    [GenderFilterEnum.SEX_CATEGORY_FEMALE]:
      'webApp.dynamics.topRequest.gender.female',
    [GenderFilterEnum.SEX_CATEGORY_MALE]:
      'webApp.dynamics.topRequest.gender.male',
  }

export const RequestFiltersAgeDynamicsConfig: RequestFiltersAgeDynamicsType = {
  [AgeFilterEnum.AGE_CATEGORY_ALL]: 'webApp.dynamics.topRequest.age.all',
  [AgeFilterEnum.AGE_CATEGORY_15_19]: 'webApp.dynamics.topRequest.age.15_19',
  [AgeFilterEnum.AGE_CATEGORY_20_24]: 'webApp.dynamics.topRequest.age.20_24',
  [AgeFilterEnum.AGE_CATEGORY_25_34]: 'webApp.dynamics.topRequest.age.25_34',
  [AgeFilterEnum.AGE_CATEGORY_35_44]: 'webApp.dynamics.topRequest.age.35_44',
  [AgeFilterEnum.AGE_CATEGORY_45_59]: 'webApp.dynamics.topRequest.age.45_59',
  [AgeFilterEnum.AGE_CATEGORY_60_MORE]:
    'webApp.dynamics.topRequest.age.60_more',
}
