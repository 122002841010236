import React, { useEffect, useState } from 'react'
import { ReactComponent as ChevronTopIcon } from '../../../assets/icons/app/top-chevron.icon.svg'
import { ReactComponent as ChevronBottomIcon } from '../../../assets/icons/app/bottom-chevron.icon.svg'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'

interface Props {
  sortDirection: SortDirectionEnum
}
const GlHeaderSortButtons = ({
  sortDirection = SortDirectionEnum.EMPTY,
}: Props) => {
  const [direction, setDirection] = useState<SortDirectionEnum>(sortDirection)
  useEffect(() => {
    setDirection(sortDirection)
  }, [sortDirection])

  return (
    <div className="flex-none flex flex-col gap-0.5 items-center justify-center hover:bg-[#f4f4f4] active:bg-[#e3e3e3] rounded-sm">
      <div className="p-1 pb-0.5">
        <ChevronTopIcon
          color={direction === SortDirectionEnum.ASC ? '#252525' : '#b2b2b2'}
        />
      </div>
      <div className="p-1 pb-0.5">
        <ChevronBottomIcon
          color={direction === SortDirectionEnum.DESC ? '#252525' : '#b2b2b2'}
        />
      </div>
    </div>
  )
}

export default GlHeaderSortButtons
