import styled from '@emotion/styled'

const GLDialogButtonStyle = styled.button`
  color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && 'white') || '#252525'};
  background-color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && '#252525') || '#E9E9E9'};
  opacity: ${({
    primary = false,
    isLoading,
  }: {
    primary?: boolean
    isLoading?: boolean
  }) => (isLoading && 0.6) || (primary && 1) || 0.7};
  border-radius: 4px;
  padding: 8px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export default GLDialogButtonStyle
