import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import {
  getBookGetService,
  GetBookGetTypeEnum,
} from '../../../../../../core/api/services/bookmetrie/get-book-get.service'

interface UseLoadEditionsByAuthorState {
  isLoading: boolean
  editions: BookInterface[]
}

interface UseLoadEditionsByAuthorHook {
  state: UseLoadEditionsByAuthorState
  // actions: UseLoadEditionsByAuthorActions
}

const useLoadEditionsByAuthorHook = ({
  authorId,
  callbackAfterLoad,
}: {
  authorId: string
  callbackAfterLoad: (books: BookInterface[]) => void
}): UseLoadEditionsByAuthorHook => {
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editions, setEditions] = useState<BookInterface[]>([])

  const getBookSearchByAuthorName = async (authorId: string) => {
    setIsLoading(true)
    if (cancelToken) {
      cancelToken.cancel()
    }
    const newCancelToken = axios.CancelToken.source()
    setCancelToken(newCancelToken)
    // setIsLoading(true)
    const { data: books } = await getBookGetService(
      {
        type: GetBookGetTypeEnum.AUTHOR_ID,
        id: authorId,
      },
      newCancelToken.token,
    )
    setEditions(books)
    callbackAfterLoad(books.map((edition: BookInterface) => edition))
    setIsLoading(false)
  }

  useEffect(() => {
    void getBookSearchByAuthorName(authorId)
  }, [authorId])

  return {
    state: {
      isLoading,
      editions,
    },
  }
}

export default useLoadEditionsByAuthorHook
