import { GenderFilterEnum } from '../enums/gender-filter.enum'

export const GenderOptionConfig: {
  label: string
  value: GenderFilterEnum
  default?: boolean
}[] = [
  {
    label: 'Tous',
    value: GenderFilterEnum.SEX_CATEGORY_ALL,
    default: true,
  },
  {
    label: 'Masculin',
    value: GenderFilterEnum.SEX_CATEGORY_MALE,
  },
  {
    label: 'Féminin',
    value: GenderFilterEnum.SEX_CATEGORY_FEMALE,
  },
]
