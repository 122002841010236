import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticOutputInterface } from '../../../../../../core/interface/analytics/analytic-output.interface'
import { SetColorConfigInterface } from '../../../../../../core/interface/set-color-config.interface'
import { AnalyticOrganizationEnum } from '../../../../../../core/enums/analytic-organization.enum'
import OrganizeMenuSwitch from './OrganizeMenuSwitch'
import OrganizeMenuButton from './OrganizeMenuButton'
import { ReactComponent as StructureIcon } from '../../../../../../assets/icons/structure.icon.svg'
import { ReactComponent as LayoutIcon } from '../../../../../../assets/icons/mise-en-page.icon.svg'
import { AnalyticVisualThemeEnum } from '../../../../../../core/enums/analytic-visual-theme.enum'

export interface DisplayOptionsInterface {
  id: string
  value: boolean
}

const OrganizeMenuView = ({
  analyticsColorConfig,
  outputs,
  setShowAddModuleView,
  handleOrderChange,
  handleVisualThemeEdit,
}: {
  analyticsColorConfig: SetColorConfigInterface
  outputs: AnalyticOutputInterface[]
  setShowAddModuleView: (value: boolean) => void
  handleOrderChange: (outputs: AnalyticOutputInterface[]) => void
  handleVisualThemeEdit: (theme: AnalyticVisualThemeEnum) => void
}) => {
  const { t } = useTranslation()

  const [organizationSelected, setOrganizationSelected] =
    useState<AnalyticOrganizationEnum>(AnalyticOrganizationEnum.STRUCTURE)

  return (
    <div
      className="sticky top-[64px] flex flex-col w-[360px] min-w-[360px] bg-white p-4 gap-4 rounded-xl"
      style={{
        height: 'calc(100vh - 142px)',
      }}
    >
      <div className="flex items-center gap-1 p-1 bg-[#F7F7F7] rounded">
        <OrganizeMenuButton
          text={t('organizeMenu.structure')}
          Icon={StructureIcon}
          isSelected={
            organizationSelected === AnalyticOrganizationEnum.STRUCTURE
          }
          setOrganizationSelected={() =>
            setOrganizationSelected(AnalyticOrganizationEnum.STRUCTURE)
          }
        />
        <OrganizeMenuButton
          text={t('organizeMenu.layout')}
          Icon={LayoutIcon}
          isSelected={organizationSelected === AnalyticOrganizationEnum.LAYOUT}
          setOrganizationSelected={() =>
            setOrganizationSelected(AnalyticOrganizationEnum.LAYOUT)
          }
        />
      </div>
      <OrganizeMenuSwitch
        organizationSelected={organizationSelected}
        outputs={outputs}
        setShowAddModuleView={setShowAddModuleView}
        handleOrderChange={handleOrderChange}
        chartColorsSelected={analyticsColorConfig}
        handleVisualThemeEdit={handleVisualThemeEdit}
      />
    </div>
  )
}

export default OrganizeMenuView
