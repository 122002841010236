import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SubTitleSelectionStyle,
  TitleSelectionStyle,
} from '../../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'

interface Props {
  volume: number
  setVolume: (volume: number) => void
  maxVolume: number
}

const NewAudienceVolume = ({ volume, setVolume, maxVolume }: Props) => {
  const { t } = useTranslation()

  const [inputFocused, setInputFocused] = useState<boolean>(false)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > maxVolume) {
      setVolume(maxVolume)
    } else {
      setVolume(Number(event.target.value))
    }
  }

  const handleKeyEnterUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <TitleSelectionStyle>
          {t('createAudience.withVolume')}
        </TitleSelectionStyle>
        <SubTitleSelectionStyle>
          <span>{t('createAudience.usersVolumeTargetted')}</span>
        </SubTitleSelectionStyle>
      </div>
      <div className="flex flex-row gap-1 items-center">
        <span>{t('createAudience.iWishAnAudienceOfUsers')}</span>
        <input
          className="w-20 text-center user-select-none p-2 bg-[#F7F7F7] rounded outline-none text-base [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          type={inputFocused ? 'number' : 'string'}
          value={inputFocused ? volume : new Intl.NumberFormat().format(volume)}
          onChange={onChange}
          min={0}
          max={maxVolume}
          step={1000}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onKeyUp={handleKeyEnterUp}
        />
        <span>{t('commons.user', { count: volume })}</span>
      </div>
    </div>
  )
}

export default memo(NewAudienceVolume)
