import { PeriodTypeEnum } from '../../enums/period-type.enum'
import { RequestFiltersPeriodTypeDynamicsType } from '../../types/request-filters-period-type-dynamics.type'

export const RequestFiltersPeriodTypesConfig: RequestFiltersPeriodTypeDynamicsType =
  {
    [PeriodTypeEnum.DAY]: {
      label: 'Jour',
      value: PeriodTypeEnum.DAY,
    },
    [PeriodTypeEnum.WEEK]: {
      label: 'Semaine',
      value: PeriodTypeEnum.WEEK,
    },
    [PeriodTypeEnum.MONTH]: {
      label: 'Mois',
      value: PeriodTypeEnum.MONTH,
    },
    [PeriodTypeEnum.QUARTER]: {
      label: 'Trimestre',
      value: PeriodTypeEnum.QUARTER,
    },
    [PeriodTypeEnum.YEAR]: {
      label: "L'année",
      value: PeriodTypeEnum.YEAR,
    },
    [PeriodTypeEnum.PERIOD]: {
      label: 'Dates personnalisées',
      value: PeriodTypeEnum.PERIOD,
    },
  }
