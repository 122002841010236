import { PercentageNumber } from '../../../../../../../share/PercentageNumber'

type Props = {
  payload: { value: number }
  x: number
  y: number
  textAnchor: string
}

const CustomizedRadiusTick = ({ payload, x, y, textAnchor }: Props) => {
  return (
    <svg
      x={x - 17}
      y={y - 8}
      width="34"
      height="16"
      viewBox="0 0 34 16"
      fill="white"
    >
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V12C33.5 13.933 31.933 15.5 30 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V12C33.5 13.933 31.933 15.5 30 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
        stroke="#3B3B3B"
      />
      <text
        textAnchor={textAnchor}
        fill="#3B3B3B"
        xmlSpace="preserve"
        fontFamily="Fira Sans"
        fontSize="12"
        letterSpacing="0em"
      >
        <tspan x="17" y="12.02">
          <PercentageNumber value={payload.value} />
        </tspan>
      </text>
    </svg>
  )
}

export default CustomizedRadiusTick
