import React, { useEffect, useState } from 'react'
import { validate } from 'uuid'
import { useParams } from 'react-router-dom'
import AnalyticDetailsNewRequestStartStep from './AnalyticDetailsNewRequestStartStep'
import AnalyticDetailsRequestPreviewStep from './AnalyticDetailsRequestPreviewStep'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { NewReadershipProfileStepEnum } from '../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import AnalyticDetailsReadershipProfileDetailsView from './details/AnalyticDetailsReadershipProfileDetailsView'
import { selectNewAnalyticsReadershipProfileStateStep } from '../../../../store/getters/analytics-details/new-analytics-readership-profile.getters'
import { reinitializeNewReadershipProfileState } from '../../../../store/reducers/analytics-details/new-analytics-readership-profile-state.reducer'

const AnalyticDetailsReadershipProfileView = ({
  handleShareAnalytics,
}: {
  handleShareAnalytics: () => void
}) => {
  const { reportId: urlReportId } = useParams<{ reportId: string }>()
  const [reportId, setReportId] = useState<string>(urlReportId as string)
  const dispatch = useAppDispatch()

  const step = useAppSelector(selectNewAnalyticsReadershipProfileStateStep)

  useEffect(() => {
    return () => {
      if (reportId === 'new') {
        void dispatch(reinitializeNewReadershipProfileState())
      }
    }
  }, [])

  if (reportId === 'new') {
    if (step === NewReadershipProfileStepEnum.START) {
      return <AnalyticDetailsNewRequestStartStep />
    }

    if (step === NewReadershipProfileStepEnum.PREVIEW) {
      return <AnalyticDetailsRequestPreviewStep />
    }
  }

  if (validate(reportId)) {
    return (
      <AnalyticDetailsReadershipProfileDetailsView
        handleShareAnalytics={handleShareAnalytics}
      />
    )
  }

  return null
}

export default AnalyticDetailsReadershipProfileView
