import React from 'react'
import styled from '@emotion/styled'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'
import GlBadgeClilV2 from '../../../../share/badge/GlBadgeClilV2'
import Dynamics from './Dynamics'
import DynamicsCardISBNRank from './DynamicsCardISBNRank'
import GlImageBook from '../../../../share/images/GlImageBook'
import { clilReferencesConfig } from '../../../../../core/config/clil-references.config'

const CardStyle = styled.div`
  background-color: ${({ bookSelected }: { bookSelected: boolean }) =>
    bookSelected ? '#e3e3e3' : 'white'};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 0 20px rgba(0, 0, 0, 0.05);
  width: 415px;
  height: 285px;

  &:hover {
    background-color: #f7f7f7;
  }

  &:active {
    background-color: #e3e3e3;
  }
`

interface Props {
  trend: TrendV2Interface
  handleOnClickLine: (book: TrendV2Interface | null) => void
  bookSelected: TrendV2Interface | null
}

const DynamicsCard = ({ trend, handleOnClickLine, bookSelected }: Props) => {
  const {
    rank,
    delta_rank: deltaRank,
    title,
    id_ean: eanId,
    imgs,
    authors,
    publishing_date: publishingDate,
    clil1,
    clil2,
    edition,
    format,
  } = trend

  const { color } =
    (clil1 && clilReferencesConfig[clil1]) || clilReferencesConfig['0000']

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    handleOnClickLine(trend)
  }

  return (
    <CardStyle
      bookSelected={bookSelected?.id_ean === eanId}
      className="flex flex-col gap-2 p-3 bg-white rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row gap-2">
        <GlImageBook pathUrl={imgs} width={149} height={192} color={color} />
        <Dynamics
          title={title}
          strings={authors}
          edition={edition}
          format={format}
          date={publishingDate}
        />
      </div>
      <div className="flex flex-row gap-2 py-1 flex-auto">
        <DynamicsCardISBNRank eanId={eanId} rank={rank} deltaRank={deltaRank} />
        <div className="flex flex-col gap-[5px]">
          <GlBadgeClilV2 id={`${clil1}`} />
          <GlBadgeClilV2 id={`${clil2}`} />
        </div>
      </div>
    </CardStyle>
  )
}

export default DynamicsCard
