import { RootState } from '../store'
import { AnalyticsDashboardStateInterface } from '../interfaces/analytics-dashboard.state.interface'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'

export const selectPrivateReportsDashboardState = (
  state: RootState,
): AnalyticsDashboardStateInterface => {
  return state.privateReportsDashboardState
}

export const selectIsLoadingPrivateReports = (state: RootState): boolean => {
  const { privateReportsDashboardState } = state
  return privateReportsDashboardState.isLoadingInternalReports
}

export const selectIsLoadingInternalReportsNext = (
  state: RootState,
): boolean => {
  const { privateReportsDashboardState } = state
  return privateReportsDashboardState.isLoadingInternalReportsNext
}

export const selectAnalyticDashboardPrivateReports = (
  state: RootState,
): PaginationResponseInterface<ReportLightResponseInterface> | null => {
  const { privateReportsDashboardState } = state
  return privateReportsDashboardState.internalReports
}
