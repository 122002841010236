import React, { ReactElement, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectWebAppStateAccessToken,
  selectWebAppStateRefreshToken,
} from '../../store/getters/web-app.getters'
import {
  setShowSnackBar,
  setWebAppStateLoggedOut,
} from '../../store/reducers/web-app.reducer'
import clearLocalStorageUtil from '../../core/utils/clear-local-storage.util'
import { reinitializeSignInState } from '../../store/reducers/sign-in.reducer'

interface Props {
  children: ReactElement
}
const AuthenticatedRoute = ({ children }: Props): ReactElement | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(selectWebAppStateAccessToken)
  const refreshToken = useAppSelector(selectWebAppStateRefreshToken)

  const checkUserToken = async () => {
    if (!(accessToken && refreshToken)) {
      dispatch(setShowSnackBar(t('authentication.sign-in.error')))
      await clearLocalStorageUtil()
      dispatch(setWebAppStateLoggedOut())
      return navigate({
        pathname: buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN]),
        search: pathname.startsWith(
          buildRelativePath([PathConfig.BOOKMETRIE, '']),
        )
          ? `?redirect=${pathname}${search || ''}`
          : '',
      })
    }
  }

  useEffect(() => {
    void checkUserToken()
    return () => {
      dispatch(reinitializeSignInState())
    }
  }, [accessToken, refreshToken])
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children
}
export default AuthenticatedRoute
