import DynamicsResultsDialogNew from './DynamicsResultsDialogNew'
import DynamicsResultsDialogSave from './DynamicsResultsDialogSave'
import { DynamicsResultsDialogTypeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import { TopRequestSavedInterface } from '../../../core/interface/rank/top-requests-saved.interface'
import { CatalogInterface } from '../../../core/interface/rank/catalog.interface'

const DynamicsResultsDialog = ({
  setDialogOpenType,
  dialogOpenType,
  requestsSaved,
  requestsSavedCount,
  requestParams,
  currentCatalog = undefined,
  isLoadingRequestsSaved,
  handleSaveTopRequest,
  handleOnClickTopRequestSaved,
  handleRenameTopRequestSaved,
  handleDeleteTopRequestSaved,
}: {
  setDialogOpenType: (open: DynamicsResultsDialogTypeEnum | null) => void
  dialogOpenType: DynamicsResultsDialogTypeEnum | null
  requestsSaved: TopRequestSavedInterface[]
  requestsSavedCount: number
  requestParams: any
  currentCatalog?: CatalogInterface
  isLoadingRequestsSaved: boolean
  handleSaveTopRequest: (requestName: string) => void
  handleOnClickTopRequestSaved: (id: string) => void
  handleRenameTopRequestSaved: (id: string, name: string) => void
  handleDeleteTopRequestSaved: (id: string) => void
}) => {
  switch (dialogOpenType) {
    case DynamicsResultsDialogTypeEnum.NEW:
      return (
        <DynamicsResultsDialogNew
          setDialogOpenType={setDialogOpenType}
          handleSaveTopRequest={handleSaveTopRequest}
          requestParams={requestParams}
          currentCatalog={currentCatalog as CatalogInterface}
          isLoadingTopRequestSaved={isLoadingRequestsSaved}
        />
      )
    case DynamicsResultsDialogTypeEnum.SAVE:
      return (
        <DynamicsResultsDialogSave
          isLoadingRequestsSaved={isLoadingRequestsSaved}
          setDialogOpenType={setDialogOpenType}
          requestsSaved={requestsSaved}
          requestsSavedCount={requestsSavedCount}
          handleOnClickTopRequestSaved={handleOnClickTopRequestSaved}
          handleRenameTopRequestSaved={handleRenameTopRequestSaved}
          handleDeleteTopRequestSaved={handleDeleteTopRequestSaved}
        />
      )
    default:
      return null
  }
}

export default DynamicsResultsDialog
