const DownloadCsvUtil = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${filename.replace(' ', '_')}.csv`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default DownloadCsvUtil
