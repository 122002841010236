import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import GlHDivider from '../../../../share/GlHDivider'
import { useAppDispatch } from '../../../../../store/hooks'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import { AnalyticOutputTitleInterface } from '../../../../../core/interface/analytics/analytic-output.interface'
import { analyticsDetailsV2StatePutTitleOutputAction } from '../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import GlEditableInput from '../../../../share/GlEditableInput'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'

const AnalyticsOutputTitle = ({
  outputId,
  display,
  titleObject,
  editMode,
  handleAnalyticsDeleteOutput,
}: {
  outputId: string
  display: AnalyticOutputLayoutSizeEnum
  titleObject: AnalyticOutputTitleInterface
  editMode: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [inputTitleValue, setInputTitleValue] = useState<string>(
    titleObject.text,
  )

  const handleChangeTitle = () => {
    if (inputTitleValue === titleObject.text || inputTitleValue.length === 0)
      return
    void dispatch(
      analyticsDetailsV2StatePutTitleOutputAction({
        outputId,
        titleObject: {
          text: inputTitleValue,
          level: titleObject.level,
          layout_size: display,
        },
      }),
    )
  }

  function handleDeleteTitle() {
    if (handleAnalyticsDeleteOutput) {
      handleAnalyticsDeleteOutput(
        AnalyticOutputTypeEnum.READERSHIP_PROFILE_TITLE,
        outputId,
      )
    }
  }

  return (
    <div
      className="grid-item basis-full"
      id={outputId}
      style={{
        width: '100%',
      }}
    >
      <GlEditableInput
        inputValue={inputTitleValue}
        setInputValue={setInputTitleValue}
        placeHolderValue={t('addModuleMenu.placeHolderTitle')}
        editMode={editMode}
        actionOnBlur={handleChangeTitle}
        customStyle={{
          minWidth: '220px',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '34px',
          letterSpacing: '0.48px',
        }}
        handleDelete={handleDeleteTitle}
      />
      <GlHDivider />
    </div>
  )
}

export default AnalyticsOutputTitle
