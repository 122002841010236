import { CancelToken } from 'axios'
import { apiInstanceImg } from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'

export const postImageOutputService = (
  reportId: string,
  inputId: string,
  imageObject: FormData,
  cancelToken?: CancelToken,
) =>
  apiInstanceImg.post(
    ApiPathConfig.IMAGE_OUTPUT(reportId, inputId),
    imageObject,
    {
      cancelToken,
    },
  )
