import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MoreIcon } from '../../../assets/icons/app/more.icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/app/edit.icon.svg'
import { ReactComponent as TitleIcon } from '../../../assets/icons/app/title.icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/app/duplicate.icon.svg'
import { ReactComponent as ShareIcon } from '../../../assets/icons/shared-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/app/reset-icon.svg'
import { ReactComponent as ReplicateIcon } from '../../../assets/icons/app/replicate.icon.svg'
import { ReportLightResponseInterface } from '../../../core/interface/report/report-light-response.interface'
import { PermissionEnum } from '../../../core/enums/permission.enum'
import { buildRelativePath, PathConfig } from '../../../core/config/PathConfig'
import GlDialog from '../../share/dialog/GlDialog'
import AnalyticsDashboardDeleteDialog from './AnalyticsDashboardDeleteDialog'
import AnalyticsDashboardDuplicateDialog from './AnalyticsDashboardDuplicateDialog'
import AnalyticsDashboardRenameDialog from './AnalyticsDashboardRenameDialog'
import { useAppSelector } from '../../../store/hooks'
import AnalyticsDashboardShareDialog from './share-analytics/AnalyticsDashboardShareDialog'
import { selectWebAppStateCompanyUserId } from '../../../store/getters/web-app.getters'
import {
  AnalyticsTypeEnum,
  PrivateAnalyticsList,
} from '../../../core/enums/analytics-type.enum'
import AnalyticsDashboardReplicateDialog from './AnalyticsDashboardReplicateDialog'

const MoreButtonStyle = styled.button`
  z-index: 3;
  border-radius: 4px;
  background: #f7f7f7;
  color: #666666;
`

const ButtonMenuStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
  padding: 8px 16px;
`
enum DialogEnum {
  RENAME = 'rename',
  DUPLICATE = 'duplicate',
  SHARE = 'share',
  DELETE = 'delete',
  REPLICATE = 'replicate',
}
declare type DialogType =
  | DialogEnum.RENAME
  | DialogEnum.DUPLICATE
  | DialogEnum.SHARE
  | DialogEnum.DELETE
  | DialogEnum.REPLICATE
  | null
const AnalyticsDashboardMoreActionMenu = ({
  menuPositionTop = true,
  className,
  analytic,
  isOwner,
  handleReloadAction,
}: {
  menuPositionTop?: boolean
  className: string
  analytic: ReportLightResponseInterface
  isOwner: boolean
  handleReloadAction: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<DialogType>(null)

  const [analyticName, setAnalyticName] = useState<string>(analytic.name)

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)
  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (showMenu) {
        setShowMenu(false)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer])

  const onClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setShowMenu(true)
  }

  const canEdit = analytic?.permission === PermissionEnum.EDIT
  const isReadershipProfile =
    analytic.type === AnalyticsTypeEnum.READERSHIP_PROFILE ||
    analytic.type === AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE
  const isPrivate = PrivateAnalyticsList.includes(analytic.type)

  return (
    <>
      <div
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MoreButtonStyle
          className="relative right-0 top-0 flex p-2"
          type="button"
          onClick={onClickMoreButton}
          style={{
            zIndex: 5,
          }}
        >
          <MoreIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        </MoreButtonStyle>
        {showMenu && (
          <div
            className={`absolute bg-white ${
              menuPositionTop ? 'top-0' : 'bottom-0'
            } right-10  flex flex-col rounded-md shadow-2xl`}
            style={{
              zIndex: 200,
            }}
          >
            {canEdit &&
              !isReadershipProfile &&
              analytic.type !== AnalyticsTypeEnum.AUDIENCE && (
                <ButtonMenuStyle
                  type="button"
                  className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 items-center"
                  style={{
                    zIndex: 205,
                  }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault()
                    event.stopPropagation()
                    navigate(
                      buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        isPrivate
                          ? PathConfig.PRIVATE_REPORTS
                          : PathConfig.ANALYTICS,
                        analytic?.id,
                      ]),
                    )
                  }}
                >
                  <EditIcon className="w-4 h-4" />
                  <span>{t('commons.buttons.edit')}</span>
                </ButtonMenuStyle>
              )}
            {canEdit && (
              <ButtonMenuStyle
                type="button"
                style={{
                  zIndex: 205,
                }}
                className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 items-center"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  setShowDialog(DialogEnum.RENAME)
                  setAnalyticName(analytic?.name)
                  setShowMenu(false)
                }}
              >
                <TitleIcon className="w-4 h-4" />
                <span>{t('commons.buttons.rename')}</span>
              </ButtonMenuStyle>
            )}
            {canEdit && !isPrivate && (
              <ButtonMenuStyle
                type="button"
                className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 items-center"
                style={{
                  zIndex: 205,
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  setShowDialog(DialogEnum.SHARE)
                }}
              >
                <ShareIcon className="w-4 h-4" />
                <span>{t('commons.buttons.share')}</span>
              </ButtonMenuStyle>
            )}
            {!isReadershipProfile &&
              analytic.type !== AnalyticsTypeEnum.AUDIENCE && (
                <ButtonMenuStyle
                  type="button"
                  className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 items-center"
                  style={{
                    zIndex: 205,
                  }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation()
                    setShowDialog(DialogEnum.DUPLICATE)
                  }}
                >
                  <DuplicateIcon className="w-4 h-4" />

                  <span>{t('commons.buttons.duplicate')}</span>
                </ButtonMenuStyle>
              )}
            {analytic.type === AnalyticsTypeEnum.AUDIENCE && (
              <ButtonMenuStyle
                type="button"
                className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 items-center"
                style={{
                  zIndex: 205,
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  setShowDialog(DialogEnum.REPLICATE)
                }}
              >
                <ReplicateIcon className="w-4 h-4" />

                <span>{t('commons.buttons.replicate')}</span>
              </ButtonMenuStyle>
            )}
            {isOwner && !isReadershipProfile && (
              <ButtonMenuStyle
                type="button"
                className="flex flex-row gap-2 rounded-md hover:bg-[#f7f7f7] active:bg-[#e3e3e3] p-2 text-[#f97e60]"
                style={{
                  zIndex: 205,
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  setShowDialog(DialogEnum.DELETE)
                }}
              >
                <DeleteIcon className="w-4 h-4 text-[#f97e60]" />
                <span className="text-[#f97e60]">
                  {t('commons.buttons.delete')}
                </span>
              </ButtonMenuStyle>
            )}
          </div>
        )}
      </div>
      <GlDialog open={!!showDialog} onClose={() => setShowDialog(null)}>
        <div
          className="flex flex-col"
          style={{
            zIndex: 500,
          }}
        >
          {showDialog === DialogEnum.RENAME && (
            <AnalyticsDashboardRenameDialog
              analyticsId={analytic.id}
              analyticsName={analyticName}
              handleCancelAction={() => setShowDialog(null)}
              handleReloadAction={handleReloadAction}
            />
          )}
          {showDialog === DialogEnum.DUPLICATE && (
            <AnalyticsDashboardDuplicateDialog
              analyticsId={analytic.id}
              analyticsName={analyticName}
              handleCancelAction={() => setShowDialog(null)}
            />
          )}
          {showDialog === DialogEnum.SHARE && (
            <AnalyticsDashboardShareDialog
              analyticsName={analyticName}
              analyticsId={analytic.id}
              owner={analytic.owner}
              companyUserId={companyUserId}
              slug={analytic.slug}
              analyticsType={analytic.type}
            />
          )}
          {showDialog === DialogEnum.DELETE && (
            <AnalyticsDashboardDeleteDialog
              analyticsId={analytic.id}
              analyticsName={analyticName}
              handleCancelAction={() => setShowDialog(null)}
              handleReloadAction={handleReloadAction}
              isPrivate={isPrivate}
            />
          )}
          {showDialog === DialogEnum.REPLICATE && (
            <AnalyticsDashboardReplicateDialog
              analyticsId={analytic.id}
              analyticsName={analyticName}
              handleCancelAction={() => setShowDialog(null)}
            />
          )}
        </div>
      </GlDialog>
    </>
  )
}

export default AnalyticsDashboardMoreActionMenu
