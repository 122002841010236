import React, { memo, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { AnalyticOutputInterface } from '../../../core/interface/analytics/analytic-output.interface'
import {
  ModifiedDateStyle,
  ValidationButtonStyle,
} from '../../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.icon.svg'
import AudienceVerticalRechart from './AudienceVerticalRechart'
import GlSpinner from '../../share/GlSpinner'

const Title = styled.span`
  font-weight: 500;
  font-size: 24px;
  color: #252525;
  font-family: 'Vollkorn', serif;
`

interface Props {
  outputs: AnalyticOutputInterface[]
  modifiedDate: string
  isLoadingFile: boolean
  downloadFile: () => void
  totalUsers: number
}

const AudienceBody = ({
  outputs,
  modifiedDate,
  isLoadingFile,
  downloadFile,
  totalUsers,
}: Props) => {
  const { t } = useTranslation()

  const module = useMemo(() => {
    return outputs[0].dataset_output?.dataset_modules[0]
  }, [outputs[0]])

  return (
    <div
      className="flex-auto flex flex-col bg-white rounded-xl py-4 px-8 gap-8"
      style={{
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <ModifiedDateStyle>
        {`${t('ReportReaderShipProfileDate')} ${dayjs(modifiedDate).format(
          'DD MMMM	YYYY',
        )}`}
      </ModifiedDateStyle>
      {module && (
        <>
          <Title>{module.title}</Title>
          <AudienceVerticalRechart
            dataset={module.dataset}
            totalUsers={totalUsers}
          />
        </>
      )}
      <ValidationButtonStyle
        className="flex flex-row px-2 py-4 self-center gap-2"
        type="button"
        disabled={isLoadingFile}
        onClick={downloadFile}
        autoWidth
      >
        <DownloadIcon
          className={`text-${isLoadingFile ? '[#929292]' : 'white'} w-4 h-4`}
        />
        <span>{t('audienceView.downloadCsv')}</span>
        {isLoadingFile && (
          <div className="absolute w-full h-full flex items-center justify-center top-0">
            <GlSpinner color="#25252566" />
          </div>
        )}
      </ValidationButtonStyle>
    </div>
  )
}

export default memo(AudienceBody)
