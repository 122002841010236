import React, { useState } from 'react'
import { BookInterface } from '../../../../../core/interface/book.interface'
import EditionsTableRow from './EditionsTableRow'
import EditionsTableRowDropDownHeader from './EditionsTableRowDropDownHeader'
import EditionsTableRowDropDownChildren from './EditionsTableRowDropDownChildren'

const EditionsTableRowDropDown = ({
  disable,
  displaySerieName,
  book,
  color,
  handleUpdateBookSelected,
  booksSelected,
  handleCheckboxTableRowDropdownHeader,
}: {
  disable: boolean
  displaySerieName: boolean
  book: BookInterface
  color: string
  handleUpdateBookSelected: (books: BookInterface) => void
  booksSelected: BookInterface[]
  handleCheckboxTableRowDropdownHeader: (
    checked: boolean,
    books: BookInterface[],
  ) => void
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const handleToggleDropDown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  if (book.books && book.books.length > 1) {
    return (
      <>
        <EditionsTableRowDropDownHeader
          disable={disable}
          displaySerieName={displaySerieName}
          dropdownOpen={dropdownOpen}
          book={book}
          color={color}
          booksSelected={booksSelected}
          handleToggleDropDown={handleToggleDropDown}
          handleCheckboxTableRowDropdownHeader={
            handleCheckboxTableRowDropdownHeader
          }
        />
        {(dropdownOpen &&
          book.books.map((bookChild: BookInterface) => (
            <EditionsTableRowDropDownChildren
              disable={disable}
              key={`table-row-dropdown-child-${bookChild.id_ean}`}
              book={bookChild}
              color={color}
              isChecked={booksSelected
                .map((b: BookInterface) => b.id_ean)
                .includes(bookChild.id_ean)}
              handleUpdateBookSelected={handleUpdateBookSelected}
            />
          ))) ||
          null}
      </>
    )
  }
  return (
    <EditionsTableRow
      disable={disable}
      key={`table-row-book-${book.id_ean}`}
      book={book}
      color={color}
      isChecked={booksSelected
        .map((b: BookInterface) => b.id_ean)
        .includes(book.id_ean)}
      handleOnClick={() => handleUpdateBookSelected(book)}
    />
  )
}

export default EditionsTableRowDropDown
