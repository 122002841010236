import { useState } from 'react'
import DynamicsResultsHeaderFiltersRequests from './DynamicsResultsHeaderFiltersRequests'
import DynamicsResultsHeaderFiltersDetails from './DynamicsResultsHeaderFiltersDetails'
import DynamicsResultsHeaderFiltersActions from './DynamicsResultsHeaderFiltersActions'
import { DynamicsResultsViewModeEnum } from '../../../store/interfaces/dynamics-results.state.interface'

import DynamicsResultsHeaderActions from './DynamicsResultsHeaderActions'
import { CatalogInterface } from '../../../core/interface/rank/catalog.interface'
import { DateFilterTypeEnum } from '../../../core/enums/date-filter-type.enum'
import { AgeFilterEnum } from '../../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../../core/enums/gender-filter.enum'
import { TrendsItemFilterActionInterface } from '../../../core/interface/dynamics/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'
import { PeriodTypeEnum } from '../../../core/enums/period-type.enum'

const DynamicsResultsHeader = ({
  viewMode,
  handleViewMode,
  catalogIdSelected,
  catalogs,
  trendsAggregateByColumn,
  handleChangeListFilterSelected,
  filtersActions,
  handleYearTypeSelection,
  hasFilters,
  handleRemoveItemFilter,
  handleResetFilter,
  ageSelected,
  genderSelected,
  tagGroupSelected,
  handleChangeQuery,
  groupSelected,
  handleGroupSelected,
  searchValue,
  handleSearchValue,
  sortsSelected,
  handleSortsSelected,
  periodTypeSelected,
  startDateSelected,
  endDateSelected,
  handleResetAllFilterActions,
  handleOpenDialog,
  needSaveRequest,
}: {
  catalogIdSelected: string
  catalogs: CatalogInterface[]
  viewMode: DynamicsResultsViewModeEnum
  handleViewMode: (viewMode: DynamicsResultsViewModeEnum) => void
  trendsAggregateByColumn: any
  filtersActions: {
    [key: string]: string | Array<string | number | null> | null
  }
  handleChangeListFilterSelected: (
    columnKey: string,
    newList: Array<string | number | null>,
  ) => void
  handleYearTypeSelection: (value: DateFilterTypeEnum | null) => void
  hasFilters: boolean
  handleRemoveItemFilter: (columnKey: string, value: string) => void
  handleOpenDialog: () => void
  handleResetFilter: () => void
  ageSelected: AgeFilterEnum
  genderSelected: GenderFilterEnum
  tagGroupSelected: string
  handleChangeQuery: (key: any, value: any) => void
  groupSelected: TrendsItemFilterActionInterface | null
  handleGroupSelected: (value: TrendsItemFilterActionInterface) => void
  searchValue: string
  handleSearchValue: (value: string) => void
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  handleSortsSelected: (
    sorts: { columnKey: string; sortDirection: SortDirectionEnum }[],
  ) => void
  periodTypeSelected: PeriodTypeEnum
  startDateSelected: string
  endDateSelected: string
  handleResetAllFilterActions: () => void
  needSaveRequest: boolean
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  const handleOpenFilter = () => setOpenFilter(!openFilter)

  return (
    <div className="flex-none flex flex-col bg-[#252525]">
      <DynamicsResultsHeaderActions
        hasFilters={hasFilters}
        viewMode={viewMode}
        openFilter={openFilter}
        handleOpenFilter={handleOpenFilter}
        handleViewMode={handleViewMode}
        groupSelected={groupSelected}
        handleGroupSelected={handleGroupSelected}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        sortsSelected={sortsSelected}
        handleSortsSelected={handleSortsSelected}
        handleResetAllFilterActions={handleResetAllFilterActions}
        handleOpenDialog={handleOpenDialog}
        needSaveRequest={needSaveRequest}
        trendsAggregateByColumn={trendsAggregateByColumn}
      />
      <DynamicsResultsHeaderFiltersRequests
        catalogIdSelected={catalogIdSelected}
        catalogs={catalogs}
        ageSelected={ageSelected}
        genderSelected={genderSelected}
        tagGroupSelected={tagGroupSelected}
        handleChangeQuery={handleChangeQuery}
        periodTypeSelected={periodTypeSelected}
        startDateSelected={startDateSelected}
        endDateSelected={endDateSelected}
      />
      {hasFilters && (
        <DynamicsResultsHeaderFiltersDetails
          hasFilters={hasFilters}
          filtersActions={filtersActions}
          handleRemoveItemFilter={handleRemoveItemFilter}
          handleResetFilter={handleResetFilter}
        />
      )}
      <DynamicsResultsHeaderFiltersActions
        open={openFilter}
        trendsAggregateByColumn={trendsAggregateByColumn}
        filtersActions={filtersActions}
        handleChangeListFilterSelected={handleChangeListFilterSelected}
        handleYearTypeSelection={handleYearTypeSelection}
      />
    </div>
  )
}

export default DynamicsResultsHeader
