import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  value: number
  maximumFractionDigits?: number
  addPositiveSign?: boolean
}

export const PercentageNumber = memo(
  ({ value, maximumFractionDigits = 0, addPositiveSign = false }: Props) => {
    const { t } = useTranslation()
    return (
      <>
        {addPositiveSign && value > 0 && '+'}
        {t('intl.percentageNumber', { value, maximumFractionDigits })}
      </>
    )
  },
)
