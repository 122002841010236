import { RootState } from '../store'
import { AnalyticsDashboardStateInterface } from '../interfaces/analytics-dashboard.state.interface'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'

/*
 isLoadingRecommendedReports: false,
  recommendedReports: [],
  isLoadingLastReports: false,
  lastReports: [],
 */
export const selectReportsDashboardState = (
  state: RootState,
): AnalyticsDashboardStateInterface => {
  return state.reportsDashboardState
}

export const selectIsLoadingRecommendedReports = (
  state: RootState,
): boolean => {
  const { reportsDashboardState } = state
  return reportsDashboardState.isLoadingRecommendedReports
}

export const selectRecommendedReports = (
  state: RootState,
): ReportLightResponseInterface => {
  const { reportsDashboardState } = state
  return reportsDashboardState.recommendedReports
}

export const selectIsLoadingInternalReports = (state: RootState): boolean => {
  const { reportsDashboardState } = state
  return reportsDashboardState.isLoadingInternalReports
}
export const selectIsLoadingInternalReportsNext = (
  state: RootState,
): boolean => {
  const { reportsDashboardState } = state
  return reportsDashboardState.isLoadingInternalReportsNext
}

export const selectAnalyticDashboardInternalReports = (
  state: RootState,
): PaginationResponseInterface<ReportLightResponseInterface> | null => {
  const { reportsDashboardState } = state
  return reportsDashboardState.internalReports
}
