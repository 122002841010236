import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import RecapInputTag from './inputs/recap/RecapInputTag'
import {
  CompareGroupType,
  StudyObjectType,
} from './configs/StudiesObjectsCompareGroupTypesConfig'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { SetColorConfig } from '../../../../core/config/SetColorConfig'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectNewAnalyticsReadershipProfileStateAnalyticsReadershipProfilePreview,
  selectNewAnalyticsReadershipProfileStateCompareGroupBooks,
  selectNewAnalyticsReadershipProfileStateCompareGroupType,
  selectNewAnalyticsReadershipProfileStateCompareGroupValue,
  selectNewAnalyticsReadershipProfileStateIsLoadingPostPreview,
  selectNewAnalyticsReadershipProfileStateIsLoadingReadershipProfileValidation,
  selectNewAnalyticsReadershipProfileStatePostPreviewError,
  selectNewAnalyticsReadershipProfileStatePreviewOutput,
  selectNewAnalyticsReadershipProfileStateStudyObjectBooks,
  selectNewAnalyticsReadershipProfileStateStudyObjectType,
  selectNewAnalyticsReadershipProfileStateStudyObjectValue,
} from '../../../../store/getters/analytics-details/new-analytics-readership-profile.getters'
import { TitleSelectionStyle } from './ReadershipProfileStyles'
import ValidationDialogReadershipProfile from './dialogs/ValidationDialogReadershipProfile'
import ReadershipProfilePreviewOutput from './outputs/ReadershipProfilePreviewOutput'
import { SetColorEnum } from '../../../../core/enums/set-color.enum'
import { newReadershipProfileStateGotToStartStep } from '../../../../store/reducers/analytics-details/new-analytics-readership-profile-state.reducer'
import { newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate } from '../../../../store/actions/analytics-details/new-analytics-readership-profile.actions'
import {
  setReadershipProfileGenerating,
  setShowSnackBar,
} from '../../../../store/reducers/web-app.reducer'
import {
  buildRelativePath,
  PathConfig,
} from '../../../../core/config/PathConfig'
import { analyticsDetailsReadershipProfileStateGetPreviewValue } from '../../../../store/actions/analytics-details/analytics-details-readership-profile-state.actions'
import GlLoader from '../../../share/GlLoader'
import { selectWebAppStateReadershipProfileGenerating } from '../../../../store/getters/web-app.getters'
import { AnalyticsStatusEnum } from '../../../../core/enums/analytics-status.enum'
import AnalyticReadershipProfileGeneratingCard from './details/AnalyticReadershipProfileGeneratingCard'

const ButtonStyle = styled.button`
  width: 280px;
  height: 40px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  background-color: ${({ isPrimary }: { isPrimary: boolean }) =>
    isPrimary ? '#252525' : '#2525251a'};
  color: ${({ isPrimary }: { isPrimary: boolean }) =>
    isPrimary ? 'white' : '#3B3B3B'};

  :disabled {
    background-color: #e9e9e9;
    color: #929292;
  }
`

const RecapInputStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 80px;
  padding: 8px;
`

const AnalyticDetailsRequestPreviewStep = ({
  loadAnalytics = false,
}: {
  loadAnalytics?: boolean
}) => {
  const { t } = useTranslation()
  const [tokenDialog, setTokenDialog] = useState<boolean>(false)
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const studyObjectType = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectType,
  )
  const studyObjectValue = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectValue,
  )
  const studyObjectBooks = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectBooks,
  )

  const compareGroupType = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupType,
  )
  const compareGroupValue = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupValue,
  )
  const compareGroupBooks = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupBooks,
  )

  const isLoadingPostPreview = useAppSelector(
    selectNewAnalyticsReadershipProfileStateIsLoadingPostPreview,
  )
  const postPreviewError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewError,
  )

  const previewOutput = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePreviewOutput,
  )

  const isLoadingReadershipProfileValidation = useAppSelector(
    selectNewAnalyticsReadershipProfileStateIsLoadingReadershipProfileValidation,
  )

  const analyticsReadershipProfilePreview = useAppSelector(
    selectNewAnalyticsReadershipProfileStateAnalyticsReadershipProfilePreview,
  )

  const progress = useAppSelector(selectWebAppStateReadershipProfileGenerating)

  const onClickCreateReadershipProfile = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setTokenDialog(true)
  }

  const onClickEditSearchButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    dispatch(newReadershipProfileStateGotToStartStep())
  }

  const handleAnalyticsValidation = () => {
    void dispatch(
      newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate(
        analyticsReadershipProfilePreview?.id as string,
      ),
    )
      .then(() => {
        setTokenDialog(false)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('simpleErrorMessage')))
      })
  }

  useEffect(() => {
    if (analyticsReadershipProfilePreview) {
      void dispatch(
        analyticsDetailsReadershipProfileStateGetPreviewValue({
          analyticsDetails: analyticsReadershipProfilePreview,
        }),
      )
    }
  }, [analyticsReadershipProfilePreview])

  useEffect(() => {
    const reportProgress = progress.find(
      (report) =>
        report.data.id === (analyticsReadershipProfilePreview?.id as string),
    )
    reportProgress &&
      setIsReportGenerating(
        reportProgress.data.status === AnalyticsStatusEnum.PENDING ||
          reportProgress.data.status === AnalyticsStatusEnum.GENERATING,
      )

    if (
      reportProgress &&
      reportProgress.data.status === AnalyticsStatusEnum.GENERATED
    ) {
      dispatch(
        setReadershipProfileGenerating(
          progress.filter(
            (report) =>
              report.data.id !==
              (analyticsReadershipProfilePreview?.id as string),
          ),
        ),
      )
      navigate(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.ANALYTICS,
          analyticsReadershipProfilePreview?.id as string,
        ]),
      )
    }
  }, [progress])

  const goBackToAnalytics = () => {
    navigate(buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]))
  }

  return (
    <>
      <div className="flex-auto flex flex-col p-8 gap-4">
        <RecapInputStyle
          className="flex-none flex flex-col bg-white rounded justify-between"
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          {(!studyObjectValue && (
            <div className="flex flex-row items-center justify-center ">
              <GlLoader width={32} height={32} />
            </div>
          )) || (
            <div
              className="flex-1 flex flex-row flex-wrap gap-2 justify-center items-center"
              style={{
                padding: '8px 8px 8px 32px',
              }}
            >
              <span>{t('iStudyTheReadersOf')}</span>
              <RecapInputTag<StudyObjectType, StudyObjectValueType>
                type={studyObjectType}
                value={studyObjectValue}
                color={SetColorConfig[0].color1}
                booksSelected={studyObjectBooks}
              />
              <span>{t('inComparisonWithThoseOf')}</span>
              <RecapInputTag<CompareGroupType, CompareGroupValueType>
                type={compareGroupType}
                value={compareGroupValue}
                color={SetColorConfig[0].color2}
                booksSelected={compareGroupBooks}
              />
            </div>
          )}
        </RecapInputStyle>
        <div
          className="flex flex-col flex-auto bg-white rounded p-8 pb-6"
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
            justifyContent: isReportGenerating ? 'flex-start' : 'space-between',
          }}
        >
          <div className="flex flex-col gap-4">
            <TitleSelectionStyle>
              {t('OverviewOfReadersStudied')}
            </TitleSelectionStyle>
            <ReadershipProfilePreviewOutput
              setColor={SetColorEnum.SET_1}
              previewOutputs={previewOutput?.comparison_cards || []}
            />
          </div>
          {isReportGenerating ? (
            <AnalyticReadershipProfileGeneratingCard
              extraMargin
              goBackToAnalytics={goBackToAnalytics}
            />
          ) : (
            <div className="flex flex-row gap-4 items-center justify-center">
              <ButtonStyle
                disabled={isLoadingPostPreview}
                isPrimary={false}
                type="button"
                className="rounded"
                onClick={onClickEditSearchButton}
              >
                {t('EditTheSearch')}
              </ButtonStyle>
              <ButtonStyle
                disabled={isLoadingPostPreview || postPreviewError}
                isPrimary
                type="button"
                className="rounded"
                onClick={onClickCreateReadershipProfile}
              >
                {t('CreateAReadershipProfile')}
              </ButtonStyle>
            </div>
          )}
        </div>
      </div>
      <ValidationDialogReadershipProfile
        open={tokenDialog}
        handleCloseDialog={() => setTokenDialog(false)}
        isLoadingAnalyticsValidate={isLoadingReadershipProfileValidation}
        handleAnalyticsValidation={handleAnalyticsValidation}
      />
    </>
  )
}

export default AnalyticDetailsRequestPreviewStep
