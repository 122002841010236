import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { TopRequestSavedInterface } from '../../../../interface/rank/top-requests-saved.interface'

export const postTopRequestSavedService = (
  request: Partial<TopRequestSavedInterface>,
  cancelToken?: CancelToken,
): Promise<any> =>
  apiInstance.post(
    ApiPathConfig.TOP_REQUESTS_SAVED,
    { ...request },
    { cancelToken },
  )
