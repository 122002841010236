import styled from '@emotion/styled'
import React, { ReactElement } from 'react'
import GlSpinner from '../GlSpinner'

declare type ColorType = 'primary' | 'secondary'

const ButtonStyle = styled.button`
  height: 32px;
  padding: 0 16px;
  border-radius: 32px;
  background-color: ${({ colorType }: { colorType: ColorType }) =>
    (colorType === 'primary' && '#252525') || '#e3e3e3'};
  color: ${({ colorType }: { colorType: ColorType }) =>
    (colorType === 'primary' && 'white') || '#252525'};
  position: relative;

  &:disabled {
    background-color: #595959;
  }

  &:hover {
    background-color: #595959;
  }

  &:active {
    background-color: #252525;
  }
`

declare type ButtonType = 'button' | 'submit' | 'reset'

const GlMdRoundedButton = ({
  label,
  handleOnClick,
  color = 'primary',
  hasLoader = false,
  isLoading = false,
  disabled = false,
  buttonType = 'button',
  iconSuffix = undefined,
}: {
  label: string
  handleOnClick?: () => void
  color?: ColorType
  buttonType?: ButtonType
  hasLoader?: boolean
  isLoading?: boolean
  disabled?: boolean
  iconSuffix?: ReactElement
}) => (
  <ButtonStyle
    colorType={color}
    type={buttonType}
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      handleOnClick && handleOnClick()
    }}
    disabled={disabled || isLoading}
    className="flex flex-row justify-center items-center gap-2"
  >
    {hasLoader && isLoading && (
      <div
        className="absolute w-full h-full top-0 left-0 justify-center items-center"
        style={{ marginBottom: -16 }}
      >
        <GlSpinner />
      </div>
    )}
    {iconSuffix && <span>{iconSuffix}</span>}
    <span>{label}</span>
  </ButtonStyle>
)

export default GlMdRoundedButton
