import { AnalyticsTypeEnum } from '../../enums/analytics-type.enum'
import AnalyticsDashboardModuleInterface from '../../interface/analytics-dashboard/analytics-dashboard-module.interface'

const privateReportDashboardModulesConfig: Array<AnalyticsDashboardModuleInterface> =
  [
    {
      type: AnalyticsTypeEnum.AUDIENCE,
      enable: true,
    },
    {
      type: AnalyticsTypeEnum.PRIVATE_WORK_MIRROR,
      enable: false,
    },
    {
      type: AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE,
      enable: false,
    },
  ]

export default privateReportDashboardModulesConfig
