import {
  FILTER_KEY_PUBLISHING_DATE,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
} from '../../../../core/config/dynamics/filters-items-dynamics.config'
import { DateFilterTypeEnum } from '../../../../core/enums/date-filter-type.enum'
import { SelectOptionInterface } from '../../../../core/interface/select-option.interface'
import { FILTER_KEY_DELTA_RANK } from '../../../../core/config/ranks/items-filter-action.config'
import DynamicsResultsFilterMenuV2 from './DynamicsResultsFilterMenuV2'
import DynamicsResultsYearFiltersActionsV2 from './DynamicsResultsYearFiltersActionsV2'

const DynamicsResultsFilterType = ({
  columnKey,
  data = new Set(),
  handleChangeListFilterSelected,
  filtersActions,
  handleYearTypeSelection,
}: {
  columnKey: string
  data: Set<string | number>
  handleChangeListFilterSelected: (
    newList: Array<string | number | null>,
  ) => void
  filtersActions: {
    [key: string]: string | Array<string | number | null> | null
  }
  handleYearTypeSelection: (value: DateFilterTypeEnum | null) => void
}) => {
  switch (columnKey) {
    case FILTER_KEY_PUBLISHING_DATE:
      return (
        <DynamicsResultsYearFiltersActionsV2
          yearsOptions={(Array.from(data.values()) as number[])
            .map((yearValue: any) => ({
              label: String(yearValue),
              value: yearValue,
            }))
            .sort(
              (
                a: SelectOptionInterface<number>,
                b: SelectOptionInterface<number>,
              ) => a.value + b.value,
            )}
          yearsOptionsSelected={filtersActions[FILTER_KEY_PUBLISHING_DATE]}
          yearTypeSelected={
            filtersActions[
              `${FILTER_KEY_PUBLISHING_DATE}_type`
            ] as DateFilterTypeEnum | null
          }
          handleYearTypeSelection={handleYearTypeSelection}
          handleChangeListFilterSelected={handleChangeListFilterSelected}
        />
      )
    default:
      return (
        <DynamicsResultsFilterMenuV2
          data={data}
          displayFilter={
            columnKey !== FILTER_KEY_DELTA_RANK &&
            columnKey !== FILTER_KEY_SEGMENT2 &&
            columnKey !== FILTER_KEY_SEGMENT1
          }
          columnKey={columnKey}
          filtersActions={filtersActions}
          handleChangeListFilterSelected={handleChangeListFilterSelected}
        />
      )
  }
}

export default DynamicsResultsFilterType
