import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const postPasswordResetByValidateTokenService = ({
  token,
}: {
  token: string
}) =>
  apiInstance.post(ApiPathConfig.FORGOT_PASSWORD_VALIDATE_TOKEN, {
    token,
  })
