import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as BackIcon } from '../../../../../../assets/icons/app/left-arrow.icon.svg'
import { TitleAddModuleStyle } from './AddModuleStyles'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import { InterfaceRepresentationDataList } from './AddModuleView'
import { AnalyticModuleReplace } from '../../../../../../core/interface/analytics/analytic-module-replace.interface'

const AddModuleHeader = ({
  dataRepresentationSelected,
  closeMenu,
  step,
  setStep,
  moduleToReplace,
}: {
  dataRepresentationSelected: InterfaceRepresentationDataList
  closeMenu: () => void
  step?: AddModuleStepEnum
  setStep: (step: AddModuleStepEnum) => void
  moduleToReplace?: AnalyticModuleReplace
}) => {
  const handleGoBack = () => {
    if (step === AddModuleStepEnum.DATA_REPRESENTATION) {
      if (moduleToReplace) {
        closeMenu()
      } else {
        setStep(AddModuleStepEnum.HOME)
      }
    }
    if (step === AddModuleStepEnum.OPTIONS) {
      setStep(AddModuleStepEnum.DATA_REPRESENTATION)
    }
    if (step === AddModuleStepEnum.HOME) {
      closeMenu()
    }
  }

  const { t } = useTranslation()
  return (
    <div className="flex items-center self-stretch justify-between">
      <div className="flex gap-2 items-center">
        <BackIcon
          className="h-8 w-8 text-[#BEBEBE] cursor-pointer"
          onClick={() => handleGoBack()}
        />
        <TitleAddModuleStyle
          className={
            step === AddModuleStepEnum.HOME ? `text-white` : `text-[#929292]`
          }
        >
          {t('addModuleMenu.addModule')}
        </TitleAddModuleStyle>
        {step !== AddModuleStepEnum.HOME && (
          <TitleAddModuleStyle className="text-[#929292]">
            /
          </TitleAddModuleStyle>
        )}
        {step !== AddModuleStepEnum.HOME && (
          <TitleAddModuleStyle
            className={
              step === AddModuleStepEnum.OPTIONS
                ? `text-[#929292]`
                : `text-white`
            }
          >
            {t('addModuleMenu.datasRepresentation.title')}
          </TitleAddModuleStyle>
        )}
        {step === AddModuleStepEnum.OPTIONS && (
          <TitleAddModuleStyle className="text-[#929292]">
            /
          </TitleAddModuleStyle>
        )}
        {step === AddModuleStepEnum.OPTIONS && (
          <TitleAddModuleStyle className="text-white">
            {t(dataRepresentationSelected.label)}
          </TitleAddModuleStyle>
        )}
      </div>
      <button type="button" onClick={() => closeMenu()}>
        <CrossIcon
          className="text-[#BEBEBE]"
          style={{
            width: 20,
            height: 20,
          }}
        />
      </button>
    </div>
  )
}

export default AddModuleHeader
