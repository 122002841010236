import GlHDivider from '../../../../../../share/GlHDivider'

const DatasetModuleInfoMenu = ({ infoBody }: { infoBody: string[] }) => {
  return (
    <>
      <GlHDivider />
      <div className="flex flex-col items-start self-stretch gap-4">
        {infoBody.map((info) => (
          <div key={info}>{info}</div>
        ))}
      </div>
    </>
  )
}

export default DatasetModuleInfoMenu
