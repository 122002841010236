import React from 'react'
import { ToggleButtonOptionInterface } from '../../../components/share/GlToggleButton'
import { AnalyticsTypeEnum } from '../../enums/analytics-type.enum'
import { ReactComponent as CardViewIcon } from '../../../assets/icons/app/card-view.icon.svg'
import { ReactComponent as PrivateWorkMirrorIcon } from '../../../assets/icons/private-work-mirror.icon.svg'
import { ReactComponent as AudienceIcon } from '../../../assets/icons/audience.icon.svg'
import { ReactComponent as PrivateReadershipProfilIcon } from '../../../assets/icons/private-readership-profil.icon.svg'
import AnalyticsDashboardAvailableModuleType from '../../types/analytics-dashboard-available-module.interface'
import AnalyticsDashboardType from '../../enums/analytics-dashboard-type.enum'

const privateReportsToggleOptionConfig: ToggleButtonOptionInterface<AnalyticsDashboardAvailableModuleType>[] =
  [
    {
      label: 'all',
      value: AnalyticsDashboardType.ALL,
      icon: <CardViewIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'audience',
      value: AnalyticsTypeEnum.AUDIENCE,
      icon: <AudienceIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'worksMirrors',
      value: AnalyticsTypeEnum.PRIVATE_WORK_MIRROR,
      icon: <PrivateWorkMirrorIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'readership_profile',
      value: AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE,
      icon: <PrivateReadershipProfilIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
  ]

export default privateReportsToggleOptionConfig
