import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import GlDialog from '../../share/dialog/GlDialog'
import DynamicsSearchTopRequestSaved from '../../dynamics-search/DynamicsSearchTopRequestSaved'
import { ReactComponent as SaveIcon } from '../../../assets/icons/app/save.icon.svg'
import { DynamicsResultsDialogTypeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import { ReactComponent as CheckIcon } from '../../../assets/icons/app/checked.icon.svg'
import { CatalogInterface } from '../../../core/interface/rank/catalog.interface'
import GlSpinner from '../../share/GlSpinner'
import { selectDynamicsResultsViewMode } from '../../../store/getters/dynamics-results.getters'

const ButtonStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: none;
  }
`

const DynamicsResultDialogTitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #252525;
`

const DynamicsResultDialogSubTextStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #595959;
  margin-top: 28px;
`

const DynamicsResultsDialogNew = ({
  isLoadingTopRequestSaved,
  setDialogOpenType,
  handleSaveTopRequest,
  requestParams,
  currentCatalog,
}: {
  isLoadingTopRequestSaved: boolean
  setDialogOpenType: (open: DynamicsResultsDialogTypeEnum | null) => void
  handleSaveTopRequest: (requestName: string) => void
  requestParams: any
  currentCatalog: CatalogInterface
}) => {
  const { t } = useTranslation()
  const [refRequestName, setRefRequestName] = useState<string>('')

  const onClickValidateButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleSaveTopRequest(refRequestName)
  }

  return (
    <GlDialog open onClose={() => setDialogOpenType(null)}>
      <div className="flex flex-col h-[376px] w-[1248px]">
        <div className="flex flex-row items-center justify-start gap-2">
          <SaveIcon className="w-8 h-8" />
          <DynamicsResultDialogTitleStyle>
            {t('webApp.dynamics.commons.saveCurrentRequest')}
          </DynamicsResultDialogTitleStyle>
        </div>
        <div className="flex flex-col justify-center items-center h-full">
          <DynamicsSearchTopRequestSaved
            name={refRequestName}
            catalog={currentCatalog}
            tagGroup={requestParams.tag_group}
            tagGroupName={requestParams.tag_group_name}
            sexCategory={requestParams.gender}
            ageCategory={requestParams.age}
            periodType={requestParams.period}
            startDate={requestParams.startDate}
            endDate={requestParams.endDate}
            id={requestParams.catalogId}
            handleOnClickTopRequestSaved={() => null}
            handleRenameTopRequestSaved={() => null}
            handleDeleteTopRequestSaved={() => null}
            canEditOnClickOnBG
            isXl
            setRefRequestName={setRefRequestName}
          />
          <DynamicsResultDialogSubTextStyle>
            {t('webApp.dynamics.commons.infoRenameRequest')}
          </DynamicsResultDialogSubTextStyle>
        </div>
        <div className="flex flex-row justify-end items-center">
          <ButtonStyle
            type="button"
            disabled={isLoadingTopRequestSaved}
            className="relative flex flex-row items-center self-end m-4 px-4 py-2.5 bg-[#252525] disabled:bg-[#25252566] hover:bg-[#25252599] active:bg-[#252525e6] gap-2 rounded-lg text-white"
            onClick={onClickValidateButton}
          >
            <span>{t('commons.buttons.validate')}</span>
            <CheckIcon className="text-white w-5 h-5" />
            {isLoadingTopRequestSaved && (
              <div
                className="absolute w-full h-full top-1 left-0 justify-center items-center"
                style={{ marginBottom: -16 }}
              >
                <GlSpinner />
              </div>
            )}
          </ButtonStyle>
        </div>
      </div>
    </GlDialog>
  )
}

export default DynamicsResultsDialogNew
