import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import {
  RequestFiltersAgeDynamicsConfig,
  RequestFiltersGenderDynamicsConfig,
} from '../../core/config/dynamics/request-filters-users-dynamics.config'
import GlCatalog from '../share/GlCatalog'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'

const LabelStyle = styled.span`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16.5px;
  line-height: 25px;
  color: #595959;
`
const BoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  max-width: ${({ width }: { width: number }) => `${width}px`};
  height: 32px;
  background: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  padding: 0 8px;
`

const BoxLabelStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #252525;
`

interface Props {
  catalog: CatalogInterface
  sexCategory: GenderFilterEnum
  ageCategory: AgeFilterEnum
  tagGroupName: string
  periodTypeSelected: PeriodTypeEnum
  startDateSelected: string
  endDateSelected: string
}
const DynamicsSearchTopRequestSavedDefault = ({
  catalog,
  sexCategory,
  ageCategory,
  tagGroupName,
  periodTypeSelected,
  startDateSelected,
  endDateSelected,
}: Props) => {
  const { t } = useTranslation()
  const gender = RequestFiltersGenderDynamicsConfig[sexCategory]
  const age = RequestFiltersAgeDynamicsConfig[ageCategory]
  const users = [t(gender), t(age)].join(' ')

  return (
    <div className="flex flex-row items-center gap-2.5">
      <LabelStyle>{t('webApp.dynamics.topRequest.the')}</LabelStyle>
      <BoxStyle width={300}>
        <GlCatalog catalog={catalog} />
      </BoxStyle>
      <LabelStyle>{t('webApp.dynamics.topRequest.that')}</LabelStyle>
      <BoxStyle width={230}>
        <BoxLabelStyle>{users}</BoxLabelStyle>
      </BoxStyle>
      <BoxStyle width={200}>
        <BoxLabelStyle className="flex flex-row gap-2 items-center">
          {tagGroupName}
        </BoxLabelStyle>
      </BoxStyle>
      <LabelStyle>{t('webApp.dynamics.topRequest.the_most')}</LabelStyle>
      <BoxStyle width={264}>
        <BoxLabelStyle className="flex flex-row gap-2 items-center">
          {t(`webApp.dynamics.topRequest.period.${periodTypeSelected}`, {
            startDate: dayjs(startDateSelected).format('DD/MM/YYYY'),
            endDate: dayjs(endDateSelected).format('DD/MM/YYYY'),
            month: dayjs(startDateSelected).format('MMMM YYYY'),
            quarter: dayjs(startDateSelected).format('Q'),
            year: dayjs(startDateSelected).format('YYYY'),
          })}
        </BoxLabelStyle>
      </BoxStyle>
    </div>
  )
}

export default DynamicsSearchTopRequestSavedDefault
