import { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ANALYTICS_DETAILS_READERSHIP_PROFILE_GET_STUDY_OBJECT } from '../../prefix-actions.config'
import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
  StudyObjectType,
} from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../interfaces/analytics-details/new-readership-profile-state.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../../core/enums/analytic-input-type.enum'
import { getAuthorByIdService } from '../../../core/api/services/bookmetrie/get-author-by-id.service'
import { getBookByEanIdService } from '../../../core/api/services/bookmetrie/get-book-by-ean-id.service'
import { BookInterface } from '../../../core/interface/book.interface'
import { AuthorInterface } from '../../../core/interface/author.interface'

const getAuthorByAuthorId = async (
  authorId: string,
): Promise<AuthorInterface> => {
  const { data } = await getAuthorByIdService({ authorId })
  return data
}

const getBookByGlIds = async (glIds: string[]): Promise<BookInterface[]> => {
  const response = await Promise.all(
    glIds.map((glId: string) => getBookByEanIdService(glId.replace('GL-', ''))),
  )
  return response.map(({ data }: AxiosResponse<BookInterface>) => data)
}

const getBookByEanIds = async (eanIds: number[]): Promise<BookInterface[]> => {
  const response = await Promise.all(
    eanIds.map((eanId: number) => getBookByEanIdService(eanId)),
  )
  return response.map(({ data }: AxiosResponse<BookInterface>) => data)
}

export const analyticsDetailsReadershipProfileStateGetPreviewValue =
  createAsyncThunk(
    ANALYTICS_DETAILS_READERSHIP_PROFILE_GET_STUDY_OBJECT,
    async ({
      analyticsDetails,
    }: {
      analyticsDetails: AnalyticDetailsInterface
    }): Promise<{
      studyObjectType: StudyObjectType
      studyObjectValue: StudyObjectValueType
      studyObjectBooks: BookInterface[]
      compareGroupType: CompareGroupType
      compareGroupValue: CompareGroupValueType
      compareGroupBooks: BookInterface[]
    }> => {
      let studyObjectValue: StudyObjectValueType = null
      let compareGroupValue: CompareGroupValueType = null
      try {
        const input = analyticsDetails.inputs.find(
          (input: AnalyticInputInterface) =>
            input.type === AnalyticInputTypeEnum.READERSHIP_PROFILE,
        )

        if (input) {
          switch (input.subject_type as StudyObjectType) {
            case StudyObjectGroupCompareEnum.AUTHOR:
              if (input?.subject_objects) {
                studyObjectValue =
                  input.subject_objects as unknown as AuthorInterface[]
              }
              break
            case StudyObjectGroupCompareEnum.TITLE:
              if (input.subject_objects) {
                studyObjectValue =
                  input.subject_objects as unknown as BookInterface[]
              }
              break
            default:
              break
          }

          switch (input?.comparison_type as CompareGroupType) {
            case StudyObjectGroupCompareEnum.AUTHOR:
              if (input.comparison_objects) {
                compareGroupValue =
                  input.comparison_objects as unknown as AuthorInterface[]
              }
              break
            case StudyObjectGroupCompareEnum.TITLE:
              if (input.comparison_objects) {
                compareGroupValue =
                  input.comparison_objects as unknown as BookInterface[]
              }
              break
            case StudyObjectGroupCompareEnum.CLIL:
              compareGroupValue = (input?.comparison_ids ??
                []) as unknown as string[]
              break
            default:
              break
          }
        }

        return await Promise.resolve({
          studyObjectType: input?.subject_type as StudyObjectType,
          studyObjectValue,
          studyObjectBooks: input?.subject_books as BookInterface[],
          compareGroupType: input?.comparison_type as CompareGroupType,
          compareGroupValue,
          compareGroupBooks: input?.comparison_books as BookInterface[],
        })
      } catch (error) {
        throw new Error()
      }
    },
  )
