import Lottie from 'lottie-react'
import animationLoading from '../../assets/lottieAnimations/LOADING_ANIMATION_BLACK.json'

interface Props {
  text?: string
  width?: number
  height?: number
}

const GlLoader = ({ text, width = undefined, height = undefined }: Props) => {
  return (
    <div className="text-center">
      <Lottie
        animationData={animationLoading}
        loop
        style={{
          width: width || 50,
          height: height || 'auto',
          margin: 'auto',
          marginBottom: '16px',
        }}
      />
      <span className="m-auto">{text}</span>
    </div>
  )
}

export default GlLoader
