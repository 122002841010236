import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import GlDialog from './GlDialog'
import GlDialogBody from './GlDialogBody'
import GLDialogButtonStyle from './GLDialogButtonStyle'

interface Props {
  open: boolean
  onValidate: () => void
  onClose: () => void
}

const AnalyticsDialogExit = ({ open, onValidate, onClose }: Props) => {
  const { t } = useTranslation()

  const closeDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onClose()
  }

  const validate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onValidate()
  }

  return (
    <GlDialog open={open} onClose={onClose}>
      <div
        className="flex flex-col gap-4"
        style={{
          width: 432,
        }}
      >
        <GlDialogBody color="#252525">
          <div>{t('areYouSureToLeave')}</div>
        </GlDialogBody>
        <div className="flex flex-row justify-between items-center gap-4">
          <GLDialogButtonStyle
            type="button"
            className="hover:bg-[#f7f7f7] active:bg-[#e3e3e3] flex-1 flex flex-row items-center justify-center gap-2"
            onClick={validate}
          >
            <span>{t('yes')}</span>
          </GLDialogButtonStyle>
          <GLDialogButtonStyle
            type="button"
            className="relative text-[#ffffff] bg-[#252525] flex-1 border-[#252525] hover:opacity-60 active:opacity-100"
            primary
            onClick={closeDialog}
          >
            <span>{t('no')}</span>
          </GLDialogButtonStyle>
        </div>
      </div>
    </GlDialog>
  )
}

export default memo(AnalyticsDialogExit)
