import { useState } from 'react'
import { ReactComponent as TitleIcon } from '../../../../../../assets/icons/app/book.icon.svg'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { TagStyle, EllipsisDivStyle } from './RecapInputStyles'
import RecapToolTip from './RecapToolTip'
import { StudyObjectGroupCompareEnum } from '../../configs/StudiesObjectsCompareGroupTypesConfig'

const RecapInputTagBooks = ({
  value,
  color,
  menuPositionTop,
  booksSelected,
}: {
  value: BookInterface[]
  color: string
  menuPositionTop: boolean
  booksSelected: BookInterface[]
}) => {
  const [showAllBooks, setShowAllBooks] = useState<boolean>(false)
  const [mousePosX, setMousePosX] = useState<number>(0)
  const [mousePosY, setMousePosY] = useState<number>(0)
  return (
    <div
      className="flex flex-row items-center gap-2"
      onMouseEnter={(event) => {
        setMousePosX(event.clientX)
        setMousePosY(event.clientY)
        setShowAllBooks(true)
      }}
      onMouseLeave={() => setShowAllBooks(false)}
    >
      {value.slice(0, 1).map((bookInput: BookInterface) => (
        <TagStyle
          color={color}
          key={`tag-input-title-${bookInput.id_ean}`}
          className="flex flex-row items-center justify-start py-1 px-2 rounded gap-2"
        >
          <TitleIcon
            className="flex-none"
            style={{
              width: 20,
              height: 20,
            }}
          />
          <EllipsisDivStyle className="flex flex-auto">
            <span>{bookInput.title}</span>
          </EllipsisDivStyle>
        </TagStyle>
      ))}
      {value.length > 1 && (
        <TagStyle
          color={color}
          className="flex flex-row items-center justify-center py-1 px-2 rounded gap-2"
        >
          <span>+</span>
          <span>{value.length - 1}</span>
        </TagStyle>
      )}
      {showAllBooks && (
        <RecapToolTip
          type={StudyObjectGroupCompareEnum.TITLE}
          objectsSelected={booksSelected}
          menuPositionTop={menuPositionTop}
          mousePosX={mousePosX}
          mousePosY={mousePosY}
        />
      )}
    </div>
  )
}

export default RecapInputTagBooks
