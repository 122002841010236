import styled from '@emotion/styled'
import dayjs from 'dayjs'

const ModifiedDateStyle = styled.div`
  color: var(--Grayscale-Gray-3, #666);
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  align-self: stretch;
  font-family: 'Fira Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const GlDateManager = ({
  date,
  prefix = '',
  suffix = '',
}: {
  date: string
  prefix?: string
  suffix?: string
}) => {
  return (
    <ModifiedDateStyle>
      {`${prefix} ${dayjs(date).format('DD MMMM	YYYY')} ${suffix}`}
    </ModifiedDateStyle>
  )
}

export default GlDateManager
