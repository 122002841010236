import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

const getPrivateReportsService = ({
  ordering,
  page_size = 50,
}: {
  ordering?: string
  page_size?: number
}) => {
  return apiInstance.get(ApiPathConfig.PRIVATE_REPORTS, {
    params: {
      ordering,
      page_size,
    },
  })
}

export default getPrivateReportsService
