import React, { useState } from 'react'
import styled from '@emotion/styled'
import GlTrashButton from './GlTrashButton'

const InputStyle = styled.input`
  min-width: 100px;
  max-width: 1000px;
  border: none;
  outline: none;
  color: #252525;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 4px 4px 4px 0px;
  &:disabled {
    pointer-events: none;
    background-color: white;
  }
  &:hover:enabled {
    background-color: #f7f7f7;
  }
  &:focus:enabled {
    background-color: #f7f7f7;
  }
`

const GlEditableInput = ({
  inputValue,
  setInputValue,
  placeHolderValue,
  editMode,
  actionOnBlur,
  customStyle,
  handleDelete,
  setFocus,
}: {
  inputValue: string
  setInputValue: (value: string) => void
  placeHolderValue?: string
  editMode: boolean
  actionOnBlur: () => void
  customStyle?: React.CSSProperties
  handleDelete?: () => void
  setFocus?: (focus: boolean) => void
}) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }

  function handleKeyEnterUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  return (
    <div
      className="flex gap-2 items-center"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      <InputStyle
        type="text"
        style={{ ...customStyle, width: `${inputValue.length}ch` }}
        value={inputValue}
        placeholder={placeHolderValue}
        onChange={(event) => setInputValue(event.target.value)}
        onBlur={actionOnBlur}
        onKeyUp={handleKeyEnterUp}
        disabled={!editMode}
        onFocus={() => setFocus && setFocus(true)}
      />
      {editMode && isHovering && handleDelete && (
        <GlTrashButton handleClick={handleDelete} />
      )}
    </div>
  )
}

export default GlEditableInput
