import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import {
  SubTitleSelectionStyle,
  TitleSelectionStyle,
} from '../ReadershipProfileStyles'
import InputSelectionButton from './InputSelectionButton'
import {
  CompareGroupType,
  GroupsCompareTypesConfig,
  ReadershipProfileInputTypeInterface,
  StudyObjectGroupCompareEnum,
} from '../configs/StudiesObjectsCompareGroupTypesConfig'
import InputTypeSwitchInput from './InputTypeSwitchInput'
import { ReactComponent as RevertIcon } from '../../../../../assets/icons/app/revert-icon.svg'
import { BookInterface } from '../../../../../core/interface/book.interface'
import InputTypeChoice from '../../../../share/InputTypeChoice'
import { CompareGroupValueType } from '../../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { SetColorConfig } from '../../../../../core/config/SetColorConfig'

const RevertButtonStyle = styled.button`
  color: #3b3b3b;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(37, 37, 37, 0.1);
  height: 28px;
`

const ReadershipProfileInputTypeChoice = <InputType, ValueType>({
  disable,
  keyValue,
  color,
  title,
  subTitle,
  inputsTypeConfig,
  inputTypeSelected,
  inputValue,
  handleOnClickInputSelectionButton,
  handleSelectValue,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  keyValue: string
  color: string
  title: string
  subTitle: string
  inputsTypeConfig: ReadershipProfileInputTypeInterface<InputType>[]
  inputTypeSelected: InputType
  handleOnClickInputSelectionButton: (type: InputType) => void
  inputValue: ValueType
  handleSelectValue: (type: ValueType, booksToRemove?: BookInterface[]) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  return (
    <div className="relative basis-1/2 h-full flex flex-col gap-4 p-8">
      <InputTypeChoice<InputType, ValueType>
        disable={disable}
        color={color}
        keyValue={keyValue}
        title={title}
        subTitle={subTitle}
        inputsTypeConfig={inputsTypeConfig}
        inputTypeSelected={inputTypeSelected}
        inputValue={inputValue}
        handleOnClickInputSelectionButton={handleOnClickInputSelectionButton}
        handleSelectValue={handleSelectValue}
        booksSelected={booksSelected}
        handleUpdateBooksSelected={handleUpdateBooksSelected}
        handleOpenBookDetails={handleOpenBookDetails}
        eanSelected={eanSelected}
      />
    </div>
  )
}

export default ReadershipProfileInputTypeChoice
