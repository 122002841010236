import axios, { CancelTokenSource } from 'axios'
import { useEffect, useState } from 'react'
import { BookSearchInterface } from '../../../../../../core/interface/book-search.interface'
import { getBookSearchService } from '../../../../../../core/api/services/bookmetrie/get-book-search.service'

interface UseTitleInputAutocompletionState {
  isLoadingGetBooksByQuery: boolean
  books: BookSearchInterface[]
}

interface UseTitleInputAutocompletionHook {
  state: UseTitleInputAutocompletionState
}
const useTitleInputAutocompletionHook = ({
  query,
}: {
  query: string
}): UseTitleInputAutocompletionHook => {
  let timer: NodeJS.Timer | null = null
  const [isLoadingGetBooksByQuery, setIsLoadingGetBooksByQuery] =
    useState<boolean>(false)
  const [books, setBooks] = useState<BookSearchInterface[]>([])
  const [cancelTokenGetBookSearch, setCancelTokenGetBookSearch] =
    useState<CancelTokenSource | null>(null)

  const getBooksByQuery = (query: string): void => {
    if (cancelTokenGetBookSearch) {
      cancelTokenGetBookSearch.cancel()
    }
    const cancelToken = axios.CancelToken.source()
    setCancelTokenGetBookSearch(cancelToken)
    getBookSearchService(query, cancelToken?.token)
      .then(({ data }) => {
        setBooks(data)
      })
      .catch(() => {
        setBooks([])
      })
      .finally(() => {
        setIsLoadingGetBooksByQuery(false)
        setCancelTokenGetBookSearch(null)
      })
  }

  const handleTimeoutQueryChange = () => {
    timer = setTimeout(() => {
      getBooksByQuery(query)
    }, 500)
  }

  useEffect(() => {
    if (!query) {
      setBooks([])
      setIsLoadingGetBooksByQuery(false)
    } else {
      setIsLoadingGetBooksByQuery(true)
      handleTimeoutQueryChange()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [query])

  return {
    state: {
      isLoadingGetBooksByQuery,
      books,
    },
  }
}

export default useTitleInputAutocompletionHook
