import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/hooks'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'

const TooltipContainer = styled.div`
  border: 1px solid #252525;
  background-color: #252525;
  color: #e9e9e9;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #252525 transparent;
  }
`

const ISBNStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #b2b2b2;
  user-select: text;
`
const TitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  padding: 0 4px 0 0;
  font-style: italic;
  font-weight: 600;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
  user-select: text;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

interface Props {
  miniature?: string
  title: string
  isbn?: number
  titleOnly?: boolean
  largeSize?: boolean
}
const RankTitle = ({
  miniature,
  title,
  isbn = undefined,
  titleOnly = false,
  largeSize = false,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const parentRef = useRef<any>(null)
  const childRed = useRef<any>(null)
  const [isOverflow, setIsOverflow] = useState(false)
  const [displayTooltip, setDisplayTooltip] = useState(false)

  useEffect(() => {
    const element = childRed.current
    const parentElement = parentRef.current
    setIsOverflow(parentElement.scrollWidth - 30 <= element.scrollWidth)

    const handleResize = () => {
      setIsOverflow(parentElement.scrollWidth - 30 <= element.scrollWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div ref={parentRef} className="flex-auto flex flex-row gap-2 ml-[2px]">
      {(miniature && <img width="20" height="30" src={miniature} alt="" />) ||
        null}
      <div className="flex-auto flex flex-col items-start text-left relative">
        <TitleStyle
          ref={childRed}
          onMouseEnter={() => {
            setDisplayTooltip(true)
          }}
          onMouseLeave={() => {
            setDisplayTooltip(false)
          }}
          style={
            largeSize
              ? { fontSize: 16, lineHeight: '20px' }
              : { fontSize: 14, lineHeight: '18px' }
          }
        >
          {title}
        </TitleStyle>
        {isOverflow && displayTooltip && (
          <TooltipContainer className="absolute z-10 rounded p-2 top-6">
            {title}
          </TooltipContainer>
        )}
        {!titleOnly && isbn && (
          <ISBNStyle
            type="button"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault()
              event.stopPropagation()
              navigator.clipboard
                .writeText(String(isbn))
                .then(() => {
                  dispatch(setShowSnackBar(t('snackbarMessage.copied')))
                })
                .catch(() => {})
            }}
            className="flex flex-row gap-2 copy-paste-button opacity-90 hover:opacity-60 active:opacity-100"
          >
            <div>ISBN:</div>
            <div>{isbn}</div>

            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.99982 2.99997H2.48164C2.31596 2.99997 2.18164 3.13429 2.18164 3.29997V10.3363C2.18164 10.502 2.31595 10.6363 2.48164 10.6363H7.88164C8.04733 10.6363 8.18164 10.502 8.18164 10.3363V9.81815M10.0907 8.42725V1.39088C10.0907 1.2252 9.95642 1.09088 9.79073 1.09088H4.39073C4.22505 1.09088 4.09073 1.2252 4.09073 1.39088V8.42725C4.09073 8.59293 4.22505 8.72725 4.39073 8.72725H9.79073C9.95642 8.72725 10.0907 8.59293 10.0907 8.42725Z"
                stroke="#B2B2B2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ISBNStyle>
        )}
      </div>
    </div>
  )
}
export default RankTitle
