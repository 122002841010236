import {
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts'

type Props = {
  radiusNbr: number
  maxValue: number
  innerRadius?: number
}

const RadarRadiusLine = ({ radiusNbr, maxValue, innerRadius = 0 }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%" className="absolute">
      <RadarChart cx="50%" cy="50%" innerRadius={innerRadius}>
        <PolarGrid gridType="circle" stroke="#25252533" />
        <PolarRadiusAxis
          axisLine={false}
          domain={[0, maxValue]}
          tickCount={radiusNbr}
          tick={false}
        />
        <Radar dataKey="" />
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default RadarRadiusLine
