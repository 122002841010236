import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoIcon } from '../../assets/icons/bookmetrie.icon.svg'
import { ReactComponent as GleephProLogo } from '../../assets/icons/gleeph_pro_logo.icon.svg'

const BannerContainerStyle = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: #f7f7f7;
`

const TextStyle = styled.span`
  color: #666;
  text-align: center;
  font-family: 'Fira Sans';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const BookmetrieTextStyle = styled.span`
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Vollkorn;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const ByGleephTextStyle = styled.span`
  color: #929292;
  font-family: 'Fira Sans';
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`

const GlBookmetrieBanner = () => {
  const { t } = useTranslation()
  return (
    <BannerContainerStyle>
      <TextStyle>{t('thisStudyHasBeenRealizedBy')}</TextStyle>
      <LogoIcon className="text-black h-[18px] w-[18px]" />
      <div className="flex gap-1 items-center">
        <BookmetrieTextStyle>{t('bookmetrie')}</BookmetrieTextStyle>
        <ByGleephTextStyle>{t('byGleeph')}</ByGleephTextStyle>
      </div>
      <TextStyle>{t('sortOfProduct')}</TextStyle>
      <GleephProLogo />
    </BannerContainerStyle>
  )
}

export default GlBookmetrieBanner
