import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReportLightResponseInterface } from '../../../core/interface/report/report-light-response.interface'
import TableAnalyticsHeader from './TableAnalyticsHeader'
import TableAnalyticsBody from './TableAnalyticsBody'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'

const TableAnalytics = ({
  analytics,
  handleReloadAction,
  handleClickAnalytics,
  companyUserId,
}: {
  analytics: ReportLightResponseInterface[]
  handleReloadAction: () => void
  handleClickAnalytics: (id: string, type: AnalyticsTypeEnum) => void
  companyUserId: string
}) => {
  return (
    <div className="flex flex-col w-full h-full">
      <TableAnalyticsHeader />
      <div className="relative flex flex-auto flex-col">
        <div className="absolute top-0 left-0 h-full w-full flex flex-col">
          <PerfectScrollbar className="flex-auto flex flex-col w-full h-full pr-2">
            {analytics.map(
              (analytic: ReportLightResponseInterface, index: number) => (
                <TableAnalyticsBody
                  isFirst={index < 3}
                  key={`table-line-${analytic.id}`}
                  analytic={analytic}
                  handleReloadAction={handleReloadAction}
                  handleClickAnalytics={handleClickAnalytics}
                  companyUserId={companyUserId}
                />
              ),
            )}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}

export default TableAnalytics
