import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'
import { DatasetModuleInterface } from '../../../../../interface/analytics/dataset-module.interface'
import { DatasetModuleAvailableModulesInterface } from '../../../../../interface/analytics/analytic-available-modules.interface'

export const postDatasetModulesService = (
  reportId: string,
  outputId: string,
  datasetModule:
    | DatasetModuleAvailableModulesInterface
    | DatasetModuleInterface,
  cancelToken?: CancelToken,
) =>
  apiInstance.post(
    ApiPathConfig.DATASET_MODULES(reportId, outputId),
    datasetModule,
    {
      cancelToken,
    },
  )
