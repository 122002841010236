import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import GlFilterInput from '../../../share/inputs/GlFilterInput'
import DynamicsResultsItemsMenuFiltersActions from './DynamicsResultsItemsMenuFiltersActions'

const MenuStyle = styled.div`
  max-height: 100%;
  width: auto;
`
const DynamicsResultsFilterMenuV2 = ({
  data,
  displayFilter = false,
  columnKey,
  filtersActions,
  handleChangeListFilterSelected,
}: {
  data: Set<string | number>
  displayFilter?: boolean
  columnKey: string
  filtersActions: {
    [key: string]: string | Array<string | number | null> | null
  }
  handleChangeListFilterSelected: (newList: Array<string | number>) => void
}) => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <MenuStyle className="flex flex-col bg-white text-black rounded rounded gap-1">
      {displayFilter && (
        <GlFilterInput
          type="text"
          placeholderValue={t('commons.inputs.toFilter.placeholder')}
          value={searchValue}
          onChangeValue={(value: string | number) =>
            setSearchValue(value as string)
          }
          hasButtonReset={!!searchValue}
          onResetValue={() => setSearchValue('')}
        />
      )}

      <DynamicsResultsItemsMenuFiltersActions
        searchValue={searchValue}
        dataAggregate={Array.from(data.values())}
        dataSelected={filtersActions[columnKey] as Array<string | number>}
        handleChangeListFilterSelected={handleChangeListFilterSelected}
        columnKey={columnKey}
      />
    </MenuStyle>
  )
}

export default DynamicsResultsFilterMenuV2
