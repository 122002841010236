import styled from '@emotion/styled'
import { ConstellationBookDataInterface } from './ConstellationBookData.interface'
import { clilReferencesConfig } from '../../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilIcon from '../../../../share/badge/GlBadgeClilIcon'
import GlPopularityPanel from '../../../../share/GlPopularityPanel'
import { getPopularityIndexByValue } from '../../../../../core/enums/popularity.enum'

const CardStyle = styled.div`
  padding: 8px;
  width: 258px;
  border-radius: 4px;
  background: white;
`

const TitleStyle = styled.div`
  color: #252525;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const AuthorStyle = styled.div`
  color: #666;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`
const ImageStyle = styled.img`
  width: 60px;
  height: 80px;
  object-fit: contain;
  border-radius: 4px;
`

const BookCardConstellation = ({
  data,
}: {
  data: ConstellationBookDataInterface
}) => {
  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const percentage =
    (data.workMirror.distance &&
      `${Math.round((1 - data.workMirror.distance) * 1000) / 10}%`) ||
    ''

  const popularity = getPopularityIndexByValue(data.workMirror.popularity)
  return (
    <CardStyle className="flex flex-col gap-2 shadow-2xl">
      <div className="flex flex-row gap-2">
        <ImageStyle
          className="flex-none"
          src={
            data.workMirror.book.imgs ||
            getBookCoverByClil(data.workMirror.book.clil1)
          }
          alt=""
        />
        <div className="flex-auto flex flex-col gap-2">
          <TitleStyle>{data.workMirror.book.title}</TitleStyle>
          <AuthorStyle>{data.workMirror.book.authors[0]}</AuthorStyle>
        </div>
        <GlBadgeClilIcon id={data.workMirror.book.clil1} />
      </div>
      <GlPopularityPanel popularity={popularity} percentage={percentage} />
    </CardStyle>
  )
}
export default BookCardConstellation
