import React, { useState } from 'react'
import styled from '@emotion/styled'

const AnimationStyle = styled.div`
  -webkit-transition: max-height 500ms ease-in-out, height 500ms ease-in-out;
  -moz-transition: max-height 500ms ease-in-out, height 500ms ease-in-out;
  -o-transition: max-height 500ms ease-in-out, height 500ms ease-in-out;
  transition: max-height 500ms ease-in-out, height 500ms ease-in-out;

  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

interface Props {
  content: string
  nbShowsCharacters: number
}

const GlReadMore = ({ content = '', nbShowsCharacters }: Props) => {
  const [isReadMore, setIsReadMore] = useState(true)
  function toggleReadMore() {
    setIsReadMore(!isReadMore)
  }
  return (
    <div className="flex flex-col">
      {content?.length > nbShowsCharacters ? (
        <AnimationStyle
          className="h-auto"
          style={{
            maxHeight: isReadMore ? 78 : 'none',
          }}
        >
          {isReadMore ? `${content.slice(0, nbShowsCharacters)} ...` : content}
          <br />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className="cursor-pointer text-[#B2B2B2]"
            onClick={toggleReadMore}
            onKeyDown={toggleReadMore}
          >
            {isReadMore ? ' Lire la suite' : ' Moins'}
          </div>
        </AnimationStyle>
      ) : (
        <div>{content}</div>
      )}
    </div>
  )
}

export default GlReadMore
