import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AuthorInterface } from '../../../interface/author.interface'

const getAuthorSearchService = (
  q: string,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<AuthorInterface[]>> =>
  apiInstance.get(ApiPathConfig.AUTHOR_SEARCH, { cancelToken, params: { q } })

export default getAuthorSearchService
