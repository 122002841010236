import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AuthorInterface } from '../../../interface/author.interface'

export const getAuthorByIdService = (
  { authorId }: { authorId: string },
  cancelToken?: CancelToken,
): Promise<AxiosResponse<AuthorInterface>> =>
  apiInstance.get(ApiPathConfig.AUTHOR_BY_ID(authorId), {
    cancelToken,
  })
