import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { PeriodTypeEnum } from '../enums/period-type.enum'
import { REFERENCE_TIMEZONE } from '../config/TimeZoneConfig'

dayjs.extend(timezone)

const SERVER_START = 0
const SERVER_END = 7
export const serverInUpdate = (): boolean => {
  const currentParisHour = dayjs().tz(REFERENCE_TIMEZONE).hour()
  return currentParisHour >= SERVER_START && currentParisHour < SERVER_END
}

export const getPeriodByPeriodTypeUtil = (
  periodType: PeriodTypeEnum,
): { start: string; end: string } => {
  const currentDate = dayjs().tz(REFERENCE_TIMEZONE)
  const isMonday = currentDate.day() === 1

  switch (periodType) {
    case PeriodTypeEnum.PERIOD:
      // eslint-disable-next-line no-case-declarations
      const lastPeriod = currentDate.subtract(
        isMonday && serverInUpdate() ? 2 : 1,
        'weeks',
      )
      return {
        start: lastPeriod.startOf('isoWeek').toISOString(),
        end: lastPeriod.endOf('isoWeek').toISOString(),
      }
    case PeriodTypeEnum.DAY:
      // eslint-disable-next-line no-case-declarations
      const lastDay = currentDate.subtract(serverInUpdate() ? 2 : 1, 'days')
      return {
        start: lastDay.startOf('day').toISOString(),
        end: lastDay.endOf('day').toISOString(),
      }
    case PeriodTypeEnum.WEEK:
      // eslint-disable-next-line no-case-declarations
      const lastWeek = currentDate.subtract(
        isMonday && serverInUpdate() ? 2 : 1,
        'weeks',
      )
      return {
        start: lastWeek.startOf('week').toISOString(),
        end: lastWeek.endOf('week').toISOString(),
      }
    case PeriodTypeEnum.MONTH:
      // eslint-disable-next-line no-case-declarations
      const lastMonth = currentDate.subtract(1, 'months')
      return {
        start: lastMonth.startOf('month').toISOString(),
        end: lastMonth.endOf('month').toISOString(),
      }
    case PeriodTypeEnum.QUARTER:
      // eslint-disable-next-line no-case-declarations
      const lastQuarter = currentDate.subtract(1, 'quarters')
      return {
        start: lastQuarter.startOf('quarter').toISOString(),
        end: lastQuarter.endOf('quarter').toISOString(),
      }
    case PeriodTypeEnum.YEAR:
      // eslint-disable-next-line no-case-declarations
      const lastYear = dayjs().subtract(1, 'years')
      return {
        start: lastYear.startOf('year').toISOString(),
        end: lastYear.endOf('year').toISOString(),
      }
    default:
      // eslint-disable-next-line no-case-declarations
      const defaultValue = currentDate.subtract(
        isMonday && serverInUpdate() ? 2 : 1,
        'weeks',
      )
      return {
        start: defaultValue.startOf('isoWeek').toISOString(),
        end: defaultValue.endOf('isoWeek').toISOString(),
      }
  }
}
