import styled from '@emotion/styled'
import GlISBNButton from '../../../../share/buttons/GlISBNButton'
import GlProgress from '../../../../share/GlProgress'

const RankStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  min-width: 24px;
  width: auto;
  height: 24px;
  text-align: center;
  border: 1px #b2b2b2 solid;
  padding: 0 4px;
  border-radius: 12px;
  color: #252525;
`

const PositionStyle = styled.div`
  height: 24px;
  border-radius: 30px;
`

const DynamicsCardISBNRank = ({
  eanId,
  rank,
  deltaRank,
}: {
  eanId: number
  rank: number
  deltaRank: number | null
}) => {
  return (
    <div className="w-[149px] flex flex-col justify-between items-center ">
      <GlISBNButton isbn={eanId} />
      <PositionStyle className="flex flex-row items-center border border-[#B2B2B2] border-l-0">
        <RankStyle>{rank + 1}</RankStyle>
        <div className="flex justify-center px-2">
          <GlProgress progress={deltaRank} />
        </div>
      </PositionStyle>
    </div>
  )
}

export default DynamicsCardISBNRank
