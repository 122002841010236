import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const RippleAnimation = keyframes`
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    opacity: 0;
  }
`
const RippleStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;

  div {
    position: absolute;
    border: 4px solid ${({ color }: { color: string }) => color};
    opacity: 1;
    border-radius: 50%;
    animation: ${RippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-last-of-type {
    animation-delay: -0.5s;
  }
`

const GlSpinner = ({ color = '#fff' }: { color?: string }) => (
  <RippleStyle color={color}>
    <div />
    <div />
  </RippleStyle>
)

export default GlSpinner
