import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueFormatEnum } from '../../../../../../core/interface/analytics/value-format.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'

export type TabularColumnConfigType = {
  display: boolean
  type: 'badge' | 'default' | 'title' | 'ean' | 'percent' | 'authors'
  minWidth: 'auto' | number
  maxWidth: 'auto' | number
}
export type TabularConfigInterface = {
  [k in SourceEnum]?: TabularColumnConfigType
}

function getCustomSetting(
  source: SourceEnum,
  value_format: ValueFormatEnum,
): {
  type: 'badge' | 'default' | 'title' | 'ean' | 'percent' | 'authors'
  minWidth: 'auto' | number
  maxWidth: 'auto' | number
} {
  switch (source) {
    case SourceEnum.WORK_AUTHORS:
    case SourceEnum.EAN_AUTHORS:
      return { type: 'authors', minWidth: 200, maxWidth: 200 }
    case SourceEnum.AUTHOR_NAME:
      return { type: 'default', minWidth: 160, maxWidth: 160 }
    case SourceEnum.WORK_TITLE:
    case SourceEnum.EAN_TITLE:
      return { type: 'title', minWidth: 120, maxWidth: 'auto' }
    case SourceEnum.EAN_ID:
      return { type: 'ean', minWidth: 128, maxWidth: 128 }
    case SourceEnum.SCORE:
    case SourceEnum.PROPORTION_IN_LIBRARIES:
      return { type: 'percent', minWidth: 90, maxWidth: 90 }
    case SourceEnum.RANK:
      return { type: 'badge', minWidth: 80, maxWidth: 80 }
    default:
      return { type: 'default', minWidth: 200, maxWidth: 200 }
  }
}

export function ModuleTabularConfig(
  valuesConfigurations: ValueConfigurationsInterface[],
): TabularConfigInterface {
  const configs: TabularConfigInterface = {}

  valuesConfigurations.forEach((config) => {
    configs[config.source] = {
      display: true,
      ...getCustomSetting(config.source, config.value_format),
    }
  })

  return configs
}
