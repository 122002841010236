export const passwordIsValidByRulesUtil = (
  value: string,
): {
  lower: boolean
  upper: boolean
  numbers: boolean
  length: boolean
} => {
  const lowerCaseLetters = /[a-z]/g
  const upperCaseLetters = /[A-Z]/g
  const numbersLetters = /[0-9]/g
  const MIN_LENGHT = 8

  return {
    lower: lowerCaseLetters.test(value),
    upper: upperCaseLetters.test(value),
    numbers: numbersLetters.test(value),
    length: value.length >= MIN_LENGHT,
  }
}

export const passwordIsValidUtil = ({
  lower,
  upper,
  numbers,
  length,
}: {
  lower: boolean
  upper: boolean
  numbers: boolean
  length: boolean
}): boolean => lower && upper && numbers && length

export const getErrorMessagePasswordIsNotValid = ({
  lower,
  upper,
  numbers,
  length,
}: {
  lower: boolean
  upper: boolean
  numbers: boolean
  length: boolean
}) => {
  if (!lower) {
    return 'Le mot de passe doit contenir une lettre minuscule'
  }
  if (!upper) {
    return 'Le mot de passe doit contenir une lettre majuscule'
  }
  if (!numbers) {
    return 'Le mot de passe doit contenir au minimum 1 chiffre'
  }
  if (!length) {
    return 'Le mot de passe doit contenir au minimum 8 caractères'
  }

  return ''
}
