import { ResponsiveContainer, Tooltip, Treemap } from 'recharts'
import { useCallback } from 'react'
import { clilReferencesConfig } from '../../../../../../../core/config/clil-references.config'
import { MAX_LIBRARY_STRUCTURE_LEVEL } from '../AnalyticsOutputLibraryStructureRechart'
import { DatasetInterface } from '../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetTypeEnum } from '../../../../../../../core/enums/dataset-type.enum'
import CustomLegendMulti from './LibraryStructure/CustomLegendMulti'
import CustomTooltipMulti from './tooltip/CustomTooltipMulti'

const CustomizedContent = ({
  x,
  y,
  width,
  height,
  index,
  label,
  depth,
  onClick,
  level,
}: any) => {
  const cx = Number(x) + width / 2 - 19 / 2
  const cy = Number(y) + height / 2 - 19 / 2

  if (depth === 0) return <g />

  return (
    <g key={index} onClick={() => onClick(label, level)}>
      <rect
        rx={4}
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill:
            label && level < 3 ? clilReferencesConfig[label].color : '#929292',
          strokeWidth: 2,
          opacity: level !== 1 ? 1 - index * 0.1 : 1,
          cursor: level === MAX_LIBRARY_STRUCTURE_LEVEL ? 'default' : 'pointer',
        }}
      />
      {width > 20 && height > 20 && (
        <g x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x={cx} y={cy} width="19" height="19" rx="9.5" fill="white" />
          <rect
            x={cx}
            y={cy}
            width="19"
            height="19"
            rx="9.5"
            stroke="#E9E9E9"
            strokeWidth={2}
          />
          <text
            fill="#3B3B3B"
            xmlSpace="preserve"
            fontFamily="Fira Sans"
            fontSize="14"
            fontWeight="900"
            letterSpacing="0em"
          >
            <tspan x={cx + 6} y={cy + 14}>
              {Number(index) + 1}
            </tspan>
          </text>
        </g>
      )}
    </g>
  )
}

const TreemapRechart = ({
  dataKey,
  dataset,
  onClick,
  level,
  typeDataset,
  isPreview,
}: {
  dataKey: string
  dataset: Array<DatasetInterface>
  onClick: (clil: number, lvl: number) => void
  level: number
  typeDataset: DatasetTypeEnum
  isPreview: boolean
}) => {
  const CustomTooltip = useCallback((props: any) => {
    return (
      <CustomTooltipMulti
        {...props}
        typeDataset={typeDataset}
        showCompareStudyObject
        isClickable={!isPreview && level < 3}
        backgroundColorClil
        textColorBlack
      />
    )
  }, [])

  return (
    <>
      {/* https://github.com/recharts/recharts/issues/172#issuecomment-545975536 */}
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <ResponsiveContainer width="100%" height="100%">
          <Treemap
            data={dataset}
            dataKey={dataKey}
            stroke="#fff"
            aspectRatio={0.5}
            content={<CustomizedContent onClick={onClick} level={level} />}
          >
            <Tooltip content={CustomTooltip} />
          </Treemap>
        </ResponsiveContainer>
      </div>
      <CustomLegendMulti
        dataset={dataset}
        typeDataset={typeDataset}
        displayClilColors
      />
    </>
  )
}

export default TreemapRechart
