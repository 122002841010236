import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import GlDialog from '../../../../share/dialog/GlDialog'
import GlDialogTitle from '../../../../share/dialog/GlDialogTitle'
import GlDialogBody from '../../../../share/dialog/GlDialogBody'
import GlSpinner from '../../../../share/GlSpinner'
import useReadershipProfileGetMySelfAvailableTokenHook from '../../../../../core/hooks/useReadershipProfileGetMySelfAvailableTokenHook'

const ButtonStyle = styled.button`
  width: 280px;
  height: 36px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  background-color: ${({ isPrimary }: { isPrimary: boolean }) =>
    isPrimary ? '#252525' : '#2525251a'};
  color: ${({ isPrimary }: { isPrimary: boolean }) =>
    isPrimary ? 'white' : '#3B3B3B'};

  :disabled {
    background-color: #e9e9e9;
    color: #929292;
  }
`

const ValidationDialogReadershipProfile = ({
  open,
  handleCloseDialog,
  isLoadingAnalyticsValidate,
  handleAnalyticsValidation,
}: {
  open: boolean
  handleCloseDialog: () => void
  isLoadingAnalyticsValidate: boolean
  handleAnalyticsValidation: () => void
}) => {
  const { t } = useTranslation()

  const {
    state: { availableTokens, isLoadingMySelf },
  } = useReadershipProfileGetMySelfAvailableTokenHook()
  const onClickCancelButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseDialog()
  }
  const onClickContinueButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleAnalyticsValidation()
  }

  return (
    <GlDialog open={open} onClose={handleCloseDialog}>
      <div className="flex flex-col gap-4 w-[612px]">
        <GlDialogTitle label={t('CreateAReadershipProfile')} />
        <GlDialogBody>
          <div className="flex flex-col text-center py-4">
            <div>{t('YouAreAboutToGenerateANewReadershipProfile')}</div>
            {(isLoadingMySelf && (
              <div className="flex items-center justify-center w-full h-full">
                <GlSpinner />
              </div>
            )) || (
              <div>
                {t('YouCurrentlyHaveXProfiles', { count: availableTokens })}
              </div>
            )}
          </div>
        </GlDialogBody>
        <div className="flex flex-row items-center justify-center gap-4">
          <ButtonStyle
            type="button"
            isPrimary={false}
            className="rounded"
            onClick={onClickCancelButton}
            disabled={isLoadingAnalyticsValidate}
          >
            {t('commons.buttons.cancel')}
          </ButtonStyle>
          <ButtonStyle
            type="button"
            isPrimary
            className="relative rounded"
            onClick={onClickContinueButton}
            disabled={
              isLoadingAnalyticsValidate || isLoadingMySelf || !availableTokens
            }
          >
            {(isLoadingAnalyticsValidate && (
              <div className="absolute w-full h-full flex items-center justify-center top-0">
                <GlSpinner color="#25252566" />
              </div>
            )) ||
              null}
            {t('commons.buttons.continue')}
          </ButtonStyle>
        </div>
      </div>
    </GlDialog>
  )
}

export default ValidationDialogReadershipProfile
