import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const REPORT_SIZE_LIST_DEFAULT = 50

declare type ReportOrderingValueType =
  | 'created'
  | '-created'
  | 'modified'
  | '-modified'
  | undefined

export const getReportsService = (
  {
    publicReport = undefined,
    page_size = REPORT_SIZE_LIST_DEFAULT,
    ordering = undefined,
  }: {
    publicReport?: boolean
    page_size?: number
    ordering?: ReportOrderingValueType
  },
  cancelToken?: CancelToken,
) =>
  apiInstance.get(ApiPathConfig.REPORTS, {
    cancelToken,
    params: {
      // TODO: public is a reserved word, see with backend to change name of there value
      public: publicReport,
      page_size,
      ordering,
    },
  })
