import React, { useState } from 'react'
import { CustomProvider, DateRangePicker, DatePicker } from 'rsuite'
import 'rsuite/dist/rsuite-rtl.css'
import fr_FR from 'rsuite/locales/fr_FR'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { isAfter, isBefore } from 'date-fns'
import '../../../../../styles/components/Calendar.scss'
import { PeriodTypeEnum } from '../../../../../core/enums/period-type.enum'
import { DATA_START_DATE } from '../../../../../core/config/RankConfig'
import { REFERENCE_TIMEZONE } from '../../../../../core/config/TimeZoneConfig'
import DynamicsResultsPeriodTypeMenu from './DynamicsResultsPeriodTypeMenu'
import { serverInUpdate } from '../../../../../core/utils/get-period-by-period-type.util'
import { useAppDispatch } from '../../../../../store/hooks'
import { setShowSnackBar } from '../../../../../store/reducers/web-app.reducer'

dayjs.extend(timezone)

type TimeUnit = 'day' | 'week'
type TimeLimit = 'start' | 'end'
const locale = fr_FR
const { allowedRange } = DateRangePicker

const DynamicsResultsPeriodDateType = ({
  periodTypeSelected,
  startDateSelected,
  endDateSelected,
  handleChangeQuery,
  menuIsOpen,
  handleMouseEnterDatePicker,
  handleMouseLeaveDatePicker,
}: {
  periodTypeSelected: PeriodTypeEnum
  startDateSelected: string
  endDateSelected: string
  handleChangeQuery: (key: any, value: any) => void
  menuIsOpen: boolean
  handleMouseEnterDatePicker?: (event: React.MouseEvent<HTMLDivElement>) => void
  handleMouseLeaveDatePicker?: (event: React.MouseEvent<HTMLDivElement>) => void
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [openPeriodCalendar, setOpenPeriodCalendar] = useState<boolean>(false)
  if (!menuIsOpen) {
    return null
  }

  const sharedHandlers = {
    onMouseEnter: handleMouseEnterDatePicker,
    onMouseLeave: handleMouseLeaveDatePicker,
  }

  const styleSelectedWeek = () => {
    const rows = document.querySelectorAll('.rs-calendar-table-row')
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const selectedCell = row.querySelector('.rs-calendar-table-cell-selected')
      if (selectedCell) {
        const cells = row.querySelectorAll('.rs-calendar-table-cell')
        cells.forEach((cell) => {
          ;(cell as HTMLElement).style.backgroundColor = 'rgb(45,45,45)'
          const childDivs = cell.querySelectorAll('div')
          childDivs.forEach((div) => {
            ;(div as HTMLElement).style.backgroundColor = 'rgb(45,45,45)'
          })
        })
        break
      }
    }
  }

  const formatTimeParis = (
    date: Date,
    timeUnit: TimeUnit,
    timeLimit: TimeLimit,
  ) => {
    return dayjs(date)
      .tz(REFERENCE_TIMEZONE)
      [`${timeLimit}Of`](timeUnit)
      .toISOString()
  }

  const calendarIcon = document.querySelector('.rs-stack-item svg')
  calendarIcon?.addEventListener('click', () => {
    !openPeriodCalendar
      ? setOpenPeriodCalendar(true)
      : setOpenPeriodCalendar(false)
  })

  let datePicker
  switch (periodTypeSelected) {
    case PeriodTypeEnum.PERIOD:
      datePicker = (
        <CustomProvider locale={locale}>
          <DateRangePicker
            appearance="default"
            placement="bottomEnd"
            defaultValue={[
              new Date(startDateSelected),
              new Date(endDateSelected),
            ]}
            onChange={(selected) => {
              if (!selected || !selected.length) return

              let [start, end] = selected

              if (start > end) [start, end] = [end, start]

              if (end > dayjs().subtract(1, 'days').toDate()) {
                dispatch(
                  setShowSnackBar(
                    `Vous avez sélectionné une date supérieure à la date maximale autorisé. Veuillez renseigner une date de fin valide.`,
                  ),
                )
                return
              }

              const formatSelected = {
                start: formatTimeParis(selected[0], 'day', 'start'),
                end: formatTimeParis(selected[1], 'day', 'end'),
              }

              setOpenPeriodCalendar(false)
              handleChangeQuery('date', formatSelected)
            }}
            ranges={[]}
            open={openPeriodCalendar}
            editable
            cleanable={false}
            shouldDisableDate={
              allowedRange &&
              allowedRange(
                DATA_START_DATE.toISOString(),
                serverInUpdate()
                  ? dayjs().subtract(2, 'days').toISOString()
                  : dayjs().subtract(1, 'days').toISOString(),
              )
            }
            isoWeek
            placeholder={t('webApp.ranks.filters.byPeriod.placeholder')}
            format="dd-MM-yyyy"
          />
        </CustomProvider>
      )
      break
    case PeriodTypeEnum.DAY:
      // eslint-disable-next-line no-case-declarations
      const subtractDays = serverInUpdate() ? 2 : 1
      datePicker = (
        <CustomProvider locale={locale}>
          <DatePicker
            defaultValue={new Date(startDateSelected)}
            shouldDisableDate={(date) =>
              !date ||
              isBefore(date, DATA_START_DATE.toDate()) ||
              isAfter(date, dayjs().subtract(subtractDays, 'days').toDate())
            }
            onChange={(selected) => {
              if (selected) {
                const formatSelected = {
                  start: formatTimeParis(selected, 'day', 'start'),
                  end: formatTimeParis(selected, 'day', 'end'),
                }
                handleChangeQuery('date', formatSelected)
              }
            }}
            isoWeek
            placement="bottomEnd"
            editable={false}
            cleanable={false}
            oneTap
            placeholder={t('webApp.ranks.filters.byDay.placeholder')}
            format="dd MMMM yyyy"
          />
        </CustomProvider>
      )
      break
    case PeriodTypeEnum.WEEK:
      datePicker = (
        <CustomProvider locale={locale}>
          <DateRangePicker
            defaultValue={[
              new Date(startDateSelected),
              new Date(endDateSelected),
            ]}
            placement="bottomEnd"
            shouldDisableDate={
              allowedRange &&
              allowedRange(
                DATA_START_DATE.toISOString(),
                serverInUpdate()
                  ? dayjs().subtract(2, 'days').toISOString()
                  : dayjs().subtract(1, 'days').toISOString(),
              )
            }
            onChange={(selected) => {
              if (selected && selected.length) {
                const formatSelected = {
                  start: formatTimeParis(selected[0], 'week', 'start'),
                  end: formatTimeParis(selected[1], 'week', 'end'),
                }
                handleChangeQuery('date', formatSelected)
              }
            }}
            oneTap
            editable={false}
            cleanable={false}
            isoWeek
            placeholder={t('webApp.ranks.filters.byWeek.placeholder')}
            hoverRange="week"
            format="dd-MM-yyyy"
            ranges={[]}
            onEntering={styleSelectedWeek}
          />
        </CustomProvider>
      )
      break
    default:
      datePicker = (
        <DynamicsResultsPeriodTypeMenu
          periodTypeSelected={periodTypeSelected}
          startDateSelected={startDateSelected}
          endDateSelected={endDateSelected}
          handleChangeQuery={handleChangeQuery}
        />
      )
  }
  return <div {...sharedHandlers}>{datePicker}</div>
}

export default DynamicsResultsPeriodDateType
