import {
  getIconProgress,
  getProgressColor,
  getProgressEnumValue,
} from '../../core/enums/progress.enum'

interface Props {
  progress: number | null
}
const GlProgress = ({ progress }: Props) => (
  <div className="flex flex-row gap-1 items-center">
    {getIconProgress(getProgressEnumValue(progress))}
    <div
      style={{
        color: getProgressColor(progress),
        fontFamily: 'Fira Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '120%',
      }}
    >
      {progress !== null && progress}
    </div>
  </div>
)
export default GlProgress
