import { AgeFilterEnum } from '../enums/age-filter.enum'
import { GenderFilterEnum } from '../enums/gender-filter.enum'

export const GenreOptionsConfig: {
  label: string
  value: GenderFilterEnum
  default?: boolean
}[] = [
  {
    label: 'Tous',
    value: GenderFilterEnum.SEX_CATEGORY_ALL,
    default: true,
  },
  {
    label: 'Féminins',
    value: GenderFilterEnum.SEX_CATEGORY_FEMALE,
  },
  {
    label: 'Masculins',
    value: GenderFilterEnum.SEX_CATEGORY_MALE,
  },
]
