import { PeriodTypeEnum } from '../../enums/period-type.enum'
import { SelectOptionInterface } from '../../interface/select-option.interface'

export const PeriodOptionsConfig: SelectOptionInterface<PeriodTypeEnum>[] = [
  {
    value: PeriodTypeEnum.PERIOD,
    label: 'enums.periodType.period',
    default: true,
  },
  { value: PeriodTypeEnum.DAY, label: 'enums.periodType.day' },
  { value: PeriodTypeEnum.WEEK, label: 'enums.periodType.week' },
  { value: PeriodTypeEnum.MONTH, label: 'enums.periodType.month' },
  { value: PeriodTypeEnum.QUARTER, label: 'enums.periodType.quarter' },
  { value: PeriodTypeEnum.YEAR, label: 'enums.periodType.year' },
]
