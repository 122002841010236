import styled from '@emotion/styled'
import GlSelect from './GlSelect'
import { SelectOptionInterface } from '../../core/interface/select-option.interface'

interface CustomStyle {
  width: string | number
  marginBottom?: string | number
  minWidth?: string | number
}

const TextSelectStyle = styled.span`
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const GlSelectWithText = <T,>({
  label,
  dropdownEntries,
  dropdownEntrySelected,
  handleChangeSelect,
  customStyle,
  textCustomStyle,
}: {
  label: string
  dropdownEntries?: SelectOptionInterface<T>[]
  dropdownEntrySelected?: SelectOptionInterface<T>
  handleChangeSelect: (value: SelectOptionInterface<T>) => void
  customStyle?: CustomStyle
  textCustomStyle?: React.CSSProperties
}) => {
  return (
    <div className="flex items-center w-full justify-between">
      <TextSelectStyle style={textCustomStyle}>{label}</TextSelectStyle>
      {dropdownEntries &&
        dropdownEntrySelected &&
        dropdownEntries.length > 0 && (
          <GlSelect<T>
            zIndex={1000}
            options={dropdownEntries}
            optionSelected={dropdownEntrySelected}
            handleChangeSelect={(value) => handleChangeSelect(value)}
            customStyle={customStyle}
          />
        )}
    </div>
  )
}

export default GlSelectWithText
