import { DatasetTypeEnum } from '../../../../../../../../../core/enums/dataset-type.enum'
import GlBadgeClilV2 from '../../../../../../../../share/badge/GlBadgeClilV2'

type Props = {
  typeDataset: DatasetTypeEnum
  label: string
  labelDataset: string
}

const CustomContentHeaderTooltip = ({
  typeDataset,
  label,
  labelDataset,
}: Props) => {
  return (
    <div>
      {typeDataset === DatasetTypeEnum.CLIL ? (
        <GlBadgeClilV2 id={label} noBackground autoWidth />
      ) : (
        <div className="text-[#3B3B3B] ">
          {label} <span style={{ opacity: 0.6 }}>{labelDataset}</span>
        </div>
      )}
    </div>
  )
}

export default CustomContentHeaderTooltip
