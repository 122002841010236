import { RootState } from '../store'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { TagGroupInterface } from '../../core/interface/TagGroupInterface'
import {
  AnalyticsProgressStatusInterface,
  ProgressStatusInterface,
} from '../interfaces/analytics-details/analytics-progress-status.interface'
import AudienceCatalogInterface from '../../core/interface/audience/audience-catalog.interface'

export const selectWebAppStateAccessToken = (state: RootState): string => {
  const { webAppState } = state
  return webAppState.accessToken
}

export const selectWebAppStateRefreshToken = (state: RootState): string => {
  const { webAppState } = state
  return webAppState.refreshToken
}

export const selectWebAppStateShowSnackbar = (state: RootState) => {
  const { webAppState } = state
  return webAppState.showSnackBar
}

export const selectWebAppStateSnackbarMessage = (state: RootState) => {
  const { webAppState } = state
  return webAppState.snackBarMessage
}

export const selectWebAppStateNameCompany = (state: RootState) => {
  const { webAppState } = state
  return webAppState?.mySelf?.company_user?.company?.name
}

export const selectWebAppStateCompanyUserId = (state: RootState): string => {
  const { webAppState } = state
  return webAppState?.mySelf?.company_user?.id
}

export const selectWebAppStateBusinessUnitName = (state: RootState): string => {
  const { webAppState } = state
  return webAppState?.mySelf?.company_user?.business_unit?.name
}

export const selectWebAppStateFullName = (state: RootState): string => {
  const { webAppState } = state
  return [webAppState.mySelf?.first_name, webAppState.mySelf?.last_name]
    .join(' ')
    .trim()
}

export const selectWebAppStateIsLoadingGetTopHomeCatalogs = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState.isLoadingGetHomeTopCatalogs
}

export const selectWebAppStateCatalogs = (
  state: RootState,
): CatalogInterface[] => {
  const { webAppState } = state
  return webAppState.catalogs
}

export const selectWebAppStateAudienceCatalogs = (
  state: RootState,
): AudienceCatalogInterface[] => {
  const { webAppState } = state
  return webAppState.audienceCatalogs
}

export const selectWebAppStateHomeTopCatalogs = (
  state: RootState,
): CatalogInterface[] => {
  const { webAppState } = state
  return webAppState.homeTopCatalogs
}

export const selectWebAppStateIsLoadingMySelf = (
  state: RootState,
): CatalogInterface[] => {
  const { webAppState } = state
  return webAppState.isLoadingMySelf
}

export const selectWebAppStateMySelf = (
  state: RootState,
): CatalogInterface[] => {
  const { webAppState } = state
  return webAppState.mySelf
}

export const selectWebAppStateMySelfHasDynamicsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState?.mySelf?.features?.list?.enabled
}

export const selectWebAppStateMySelfHasAnalyticsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState?.mySelf?.features?.reports?.enabled
}

export const selectWebAppStateMySelfHasPrivateReportsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState?.mySelf?.features?.reports.private || false
}

export const selectWebAppStateMySelfHasUniversAnalyticsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState?.mySelf?.features?.reports?.report_universe?.enabled
}

export const selectWebAppStateMySelfHasReadershipProfileAnalyticsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return webAppState?.mySelf?.features?.reports?.report_readership_profile
    ?.enabled
}

export const selectWebAppStateMySelfHasReadershipProfileAnalyticsConsumerAccess =
  (state: RootState): boolean => {
    const { webAppState } = state
    return webAppState?.mySelf?.features?.reports?.report_readership_profile
      ?.consumer
  }

export const selectWebAppStateMySelfHasReadershipProfileAnalyticsAvailableToken =
  (state: RootState): number => {
    const { webAppState } = state
    return (
      webAppState?.mySelf?.features?.reports?.report_readership_profile
        ?.available_tokens || 0
    )
  }

export const selectWebAppStateMySelfHasWorksMirrorsAnalyticsAccess = (
  state: RootState,
): boolean => {
  const { webAppState } = state
  return !!webAppState?.mySelf?.features?.reports?.report_work_mirror?.enabled
}

export const selectWebAppStateTagGroups = (
  state: RootState,
): TagGroupInterface[] => {
  const { webAppState } = state
  return webAppState.tagGroups
}

export const selectWebAppStateReadershipProfileGenerating = (
  state: RootState,
): ProgressStatusInterface<AnalyticsProgressStatusInterface>[] => {
  const { webAppState } = state
  return webAppState.readershipProfileGenerating
}
