import dayjs from 'dayjs'
import { AnalyticDetailsInterface } from '../interface/analytics/analytic-details.interface'
import { AnalyticsTypeEnum } from '../enums/analytics-type.enum'
import { AnalyticInputInterface } from '../interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../enums/analytic-input-type.enum'
import { AnalyticOutputInterface } from '../interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../enums/analytic-output-type.enum'
import { AnalyticsPermissionEnum } from '../enums/analytics-permission.enum'
import { CompanyInterface } from '../interface/company.interface'
import { OwnerInterface } from '../interface/owner-interface'
import { SharedByInterface } from '../interface/shared-by-interface'

export class NewAnalyticDetailsModel implements AnalyticDetailsInterface {
  id!: string
  company!: CompanyInterface
  owner!: OwnerInterface
  slug!: string
  permission!: AnalyticsPermissionEnum
  share_count!: number
  shared_by!: SharedByInterface
  created!: string
  modified!: string
  type!: AnalyticsTypeEnum
  name!: string

  inputs!: AnalyticInputInterface[]

  constructor(type: AnalyticsTypeEnum) {
    switch (type) {
      case AnalyticsTypeEnum.WORK_MIRROR:
        this.analyticDetailsWorksMirrors()
        break
      case AnalyticsTypeEnum.READERSHIP_PROFILE:
        this.analyticDetailsReadershipProfile()
        break
      default:
        break
    }
  }

  private analyticDetailsWorksMirrors() {
    this.type = AnalyticsTypeEnum.WORK_MIRROR
    this.name = `Analytic Œuvres miroirs du ${dayjs().format('DD/MM/YYYY')}`
    this.permission = AnalyticsPermissionEnum.EDIT
    const outputs: AnalyticOutputInterface[] = [
      {
        id: '',
        type: AnalyticOutputTypeEnum.WORK_MIRROR,
        work_mirror_books: [],
      },
    ]
    const inputs: AnalyticInputInterface[] = [
      {
        type: AnalyticInputTypeEnum.BOOKS,
        books: [],
        values: [],
        outputs: [...outputs],
        id: '',
      },
    ]
    this.inputs = [...inputs]
  }

  private analyticDetailsReadershipProfile() {
    this.type = AnalyticsTypeEnum.READERSHIP_PROFILE
    this.name = `Profil lectorat du ${dayjs().format('DD/MM/YYYY')}`
    this.permission = AnalyticsPermissionEnum.EDIT
    this.inputs = []
  }
}
