import { ReportInterface } from '../interface/report/report.interface'
import { ReportTypeEnum } from '../enums/report-type.enum'
import { ReportInputTypeEnum } from '../enums/report-input-type.enum'
import { ReportOutputTypeEnum } from '../enums/report-output-type.enum'

export class ReportDuplicateFromDashboardModel {
  name: string
  type: ReportTypeEnum
  inputs: {
    type: ReportInputTypeEnum
    values: Array<string | number>
    outputs: {
      type: ReportOutputTypeEnum
      work_mirror_books: {
        id_ean: number
        distance: number
        popularity: number
      }[]
    }[]
  }[]

  constructor(report: ReportInterface) {
    this.name = report.name
    this.type = report.type
    this.inputs = report.inputs.map((input) => ({
      type: input.type,
      values: input.values,
      outputs: input.outputs.map((output) => ({
        type: output.type,
        work_mirror_books: output.work_mirror_books || [],
      })),
    })) as any
  }
}
