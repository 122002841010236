import { RootState } from '../store'

export const selectInvitationIsLoadingMain = (state: RootState): boolean => {
  const { invitationState } = state
  return invitationState.isLoadingMain
}

export const selectInvitationEmail = (state: RootState): string => {
  const { invitationState } = state
  return invitationState.email
}

export const selectInvitationIsLoadingPost = (state: RootState): boolean => {
  const { invitationState } = state
  return invitationState.isLoadingPost
}

export const selectInvitationHasError = (state: RootState): boolean => {
  const { invitationState } = state
  return invitationState.hasError
}

export const selectInvitationErrorInfoInvitationToken = (
  state: RootState,
): any => {
  const { invitationState } = state
  return invitationState.errorInfoInvitationToken
}

export const selectInvitationHasErrorPostInvitationPassword = (
  state: RootState,
): any => {
  const { invitationState } = state
  return invitationState.hasErrorPostInvitationPassword
}
