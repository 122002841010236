import React, { ReactElement } from 'react'

const PrefixIconButton = ({
  icon,
  handleClick,
}: {
  icon: ReactElement
  handleClick?: () => void
}) => (
  <button
    type="button"
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      handleClick && handleClick()
    }}
    className=" hover:text-[#595959] active:text-[#000000] "
  >
    {icon}
  </button>
)

export default PrefixIconButton
