import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetTypeEnum } from '../../../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import CustomContentHeaderTooltip from './tooltipContent/CustomContentHeaderTooltip'
import CustomContentValuesTooltip from './tooltipContent/CustomContentValuesTooltip'
import { TootipContainerStyle } from '../../../../ReadershipProfileStyles'

interface TooltipPayload {
  payload: DatasetInterface
  dataKey: string
  name: string
  value: number
}

interface ValueTooltip {
  value: number
  color: string
  key: string
}

type Props = {
  active: boolean
  payload: Array<TooltipPayload>
  typeDataset: DatasetTypeEnum
  targetKey: SourceEnum
  dataset: DatasetInterface[]
  colorMapping: [string]
  isClickable: boolean
}

const StackedTooltip = ({
  active,
  payload,
  targetKey,
  typeDataset,
  dataset,
  colorMapping,
  isClickable = false,
}: Props) => {
  const { t } = useTranslation()

  // TODO : https://gleeph.atlassian.net/browse/B2B-1061
  function decodeClilKey(clil: string) {
    return clil.trim()
  }

  if (active && payload && payload.length && targetKey) {
    const getLabelDataset = () => {
      switch (typeDataset) {
        case DatasetTypeEnum.AGE:
          return t('years')
        case DatasetTypeEnum.BOOK_READ:
          return t('books')
        default:
          return ''
      }
    }

    const values: ValueTooltip[] = []
    dataset.forEach((item: DatasetInterface, index: number) => {
      values.push({
        value: item[targetKey] as number,
        color: colorMapping[index],
        key: `${item.clil}${index}`,
      })
    })

    return (
      <TootipContainerStyle>
        <CustomContentHeaderTooltip
          typeDataset={typeDataset}
          label={decodeClilKey(targetKey)}
          labelDataset={getLabelDataset()}
        />
        <CustomContentValuesTooltip
          values={values}
          maximumFractionDigits={0}
          isClickable={isClickable}
        />
      </TootipContainerStyle>
    )
  }
  return null
}

export default StackedTooltip
