import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import React, { useState, useEffect } from 'react'
import { ReactComponent as LittleArrowBottomArrowIcon } from '../../assets/icons/app/bottom.icon.svg'
import { ReactComponent as SelectedIcon } from '../../assets/icons/selected.icon.svg'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import GlLoader from '../share/GlLoader'
import IconInterface from '../../core/interface/rank/icon.interface'

const CatalogsFilterContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #252525;
  width: 300px;
  height: 32px;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #252525;
  padding: 8px;
  width: 300px;
  height: 32px;
  border-radius: 4px;
`

const MenuStyle = styled.div`
  z-index: 35;
  position: absolute;
  top: 36px;
  left: 0;
  height: ${({ open }: { open: boolean }) => (open ? 'auto' : '0')};
  max-height: ${({ open }: { open: boolean }) => (open ? '334px' : '0')};
  visibility: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }: { open: boolean }) => (open ? '1' : '0')};
  background: white;
  width: 100%;
  border-radius: 4px;
  -webkit-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
`

const CatalogNameStyle = styled.div`
  background-repeat: no-repeat;
  height: 16px;
  margin-left: 6px;
`

const IconStyle = styled.div`
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 4px;
  padding: 2px;
`
const DashboardCatalogsFilters = ({
  isLoading,
  catalogIdSelected,
  catalogs,
  handleCatalogSelected,
}: {
  isLoading: boolean
  catalogIdSelected: string
  catalogs: CatalogInterface[]
  handleCatalogSelected: (catalog: CatalogInterface) => void
}) => {
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen) {
        setMenuOpen(false)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setMenuOpen(true)
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  const getCatalogById = (): CatalogInterface | undefined =>
    catalogs.find(
      (catalog: CatalogInterface) => catalog.id === catalogIdSelected,
    )

  return (
    <CatalogsFilterContainerStyle
      className="relative flex flex-col relative bg-white catalog-filter"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {(isLoading && (
        <div className="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <GlLoader width={32} height={32} />
        </div>
      )) ||
        null}
      {(!isLoading && catalogs.length && (
        <LabelStyle className="w-full h-full flex flex-row justify-between items-center">
          {!!getCatalogById && (
            <div className="flex flex-row gap-[2px] items-center justify-start">
              <IconStyle color={getCatalogById()?.icons[0].color as string}>
                <img
                  className={`bg-[#${getCatalogById()?.icons[0].color}]`}
                  width={20}
                  height={20}
                  src={getCatalogById()?.icons[0].icon}
                  alt="Icon catalog"
                />
              </IconStyle>

              <CatalogNameStyle color={getCatalogById()?.color as string}>
                <span>{getCatalogById()?.name}</span>
              </CatalogNameStyle>
            </div>
          )}
          <LittleArrowBottomArrowIcon className="text-[#b2b2b2]" />
        </LabelStyle>
      )) ||
        null}
      <MenuStyle open={menuOpen} className="shadow-2xl z-30">
        <div className="w-full flex flex-col">
          {catalogs.map((catalog: CatalogInterface, index: number) => (
            <div
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
                handleCatalogSelected(catalog)
                setMenuOpen(false)
              }}
              className={`
                flex
                justify-between
                h-7
                items-center
                px-3
                py-[4.5px]
                ${(catalogIdSelected === catalog.id && 'bg-[#e3e3e3]') || ''}
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
                ${(index === 0 && 'rounded-t-[4px]') || ''}
              `}
              key={nanoid(5)}
            >
              <div className="flex flex-row gap-[2px] items-center justify-start">
                {catalog.icons.map((icon: IconInterface) => (
                  <IconStyle key={nanoid(5)} color={icon.color}>
                    <img
                      className={`bg-[#${icon.color}]`}
                      width={20}
                      height={20}
                      src={icon.icon}
                      alt="Icon catalog"
                    />
                  </IconStyle>
                ))}

                <CatalogNameStyle>
                  <span>{catalog.name}</span>
                </CatalogNameStyle>
              </div>
              {catalogIdSelected === catalog.id && <SelectedIcon />}
            </div>
          ))}
        </div>
      </MenuStyle>
    </CatalogsFilterContainerStyle>
  )
}

export default DashboardCatalogsFilters
