import styled from '@emotion/styled'

const BadgeStyle = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: 'Fira Mono', monospace;
  color: white;
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  height: 18px;
  text-align: center;
  padding: 0 6px;
  background-color: ${({ color }: { color: string }) => color};
`

const GlBadge = ({
  color = '#595959',
  label,
}: {
  color?: string
  label: string | number
}) => (
  <BadgeStyle className="flex" color={color}>
    {label}
  </BadgeStyle>
)
export default GlBadge
