import { CancelToken } from 'axios'
import apiInstance from '../../../../backend-api.setup'
import { ApiPathConfig } from '../../../../../config/ApiPathConfig'

export const deleteDatasetModulesService = (
  reportId: string,
  datasetId: string,
  datasetModuleId: string,
  cancelToken?: CancelToken,
) =>
  apiInstance.delete(
    ApiPathConfig.DATASET_MODULES_BY_ID(reportId, datasetId, datasetModuleId),
    {
      cancelToken,
    },
  )
