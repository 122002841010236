import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { PutReportModel } from '../../../../model/put-report.model'

export const putReportByIdService = (
  report: PutReportModel,
  cancelToken?: CancelToken,
) =>
  apiInstance.put(ApiPathConfig.REPORT_DETAIL_BY_ID(report.id), report, {
    cancelToken,
  })
