import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DynamicsSearchStateInterface } from '../interfaces/dynamics-search.state.interface'
import {
  dynamicsSearchDeleteTopRequestSavedAction,
  dynamicsSearchGetTopRequestSavedAction,
  dynamicsSearchGetTopRequestSavedNextAction,
  dynamicsSearchPutTopRequestSavedAction,
} from '../actions/dynamics-search.actions'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'

const initialState: DynamicsSearchStateInterface = {
  isComputedLoading: false,
  catalogs: [],
  isLoadingTopRequestSaved: false,
  topRequestSaved: { count: 0, next: null, previous: null, results: [] },
  isLoadingTopRequestSavedNext: false,
  cancelTokenTopRequestSavedNext: null,
}

export const dynamicsSearchSlice = createSlice({
  name: 'dynamicsSearchState',
  initialState,
  reducers: {
    reinitializeDynamicsSearchState: (
      state: DynamicsSearchStateInterface,
    ): DynamicsSearchStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        dynamicsSearchGetTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
          topRequestSaved: state.topRequestSaved || {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        dynamicsSearchGetTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<any>,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: action.payload,
        }),
      )
      .addCase(
        dynamicsSearchGetTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        dynamicsSearchGetTopRequestSavedNextAction.pending,
        (state: DynamicsSearchStateInterface): DynamicsSearchStateInterface => {
          const { cancelTokenTopRequestSavedNext } = state
          if (cancelTokenTopRequestSavedNext) {
            cancelTokenTopRequestSavedNext.cancel()
          }
          return {
            ...state,
            isLoadingTopRequestSavedNext: true,
            cancelTokenTopRequestSavedNext: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dynamicsSearchGetTopRequestSavedNextAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<
            PaginationResponseInterface<TopRequestSavedInterface>
          >,
        ): DynamicsSearchStateInterface => {
          return {
            ...state,
            isLoadingTopRequestSavedNext: false,
            topRequestSaved: {
              count: state.topRequestSaved.count,
              next: null,
              previous: null,
              results: [
                ...state.topRequestSaved.results,
                ...action.payload.results,
              ],
            },
          }
        },
      )
      .addCase(
        dynamicsSearchGetTopRequestSavedNextAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSavedNext: false,
        }),
      )
      .addCase(
        dynamicsSearchPutTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
          { meta },
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
          topRequestSaved: {
            ...state.topRequestSaved,
            results: state.topRequestSaved.results.map((entry) =>
              entry.id === meta.arg.requestId
                ? { ...entry, name: meta.arg.request.name }
                : entry,
            ),
          },
        }),
      )
      .addCase(
        dynamicsSearchPutTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<any>,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            ...state.topRequestSaved,
            results: state.topRequestSaved.results.map((entry) =>
              entry.id === action.payload.id
                ? { ...entry, ...action.payload }
                : entry,
            ),
          },
        }),
      )
      .addCase(
        dynamicsSearchPutTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        dynamicsSearchDeleteTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
        }),
      )
      .addCase(
        dynamicsSearchDeleteTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
        }),
      )
      .addCase(
        dynamicsSearchDeleteTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
        }),
      )
  },
})

export const { reinitializeDynamicsSearchState } = dynamicsSearchSlice.actions

export const dynamicsSearchReducer = dynamicsSearchSlice.reducer
