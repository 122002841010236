import { RootState } from '../store'
import { SettingsStateInterface } from '../interfaces/settings.state.interface'

export const selectSettingsState = (
  state: RootState,
): SettingsStateInterface => {
  const { settingsState } = state
  return settingsState
}

export const selectSettingsStateIsLoadingProfile = (
  state: RootState,
): boolean => {
  const { settingsState } = state
  return settingsState.isLoadingProfile
}

export const selectSettingsStateProfile = (state: RootState): boolean => {
  const { settingsState } = state
  return settingsState.profile
}

export const selectSettingsStateIsLoadingChangePassword = (
  state: RootState,
): boolean => {
  const { settingsState } = state
  return settingsState.isLoadingChangePassword
}
export const selectSettingsStateHasErrorChangePassword = (
  state: RootState,
): boolean => {
  const { settingsState } = state
  return settingsState.hasErrorChangePassword
}
