import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { TitleSelectionStyle } from '../ReadershipProfileStyles'

const ButtonStyle = styled.button`
  width: 280px;
  height: 40px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #252525;
  color: white;
`

const WaitingText = styled.div`
  color: #666;
  font-size: 16px;
`
const AnalyticReadershipProfileGeneratingCard = ({
  extraMargin = false,
  goBackToAnalytics,
}: {
  extraMargin?: boolean
  goBackToAnalytics: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div
      className="flex flex-col bg-[#F7F7F7] rounded-xl px-16 py-8 items-center gap-4"
      style={{ marginTop: extraMargin ? 32 : 0 }}
    >
      <TitleSelectionStyle>
        {t('readershipProfileCreationPending')}
      </TitleSelectionStyle>
      <WaitingText>
        {`${t('readershipProfileCreationTime')} ${t(
          'youWillBeNotifyWhenFinished',
        )}`}
      </WaitingText>
      <ButtonStyle
        type="button"
        className="rounded"
        onClick={goBackToAnalytics}
      >
        {t('returnLater')}
      </ButtonStyle>
    </div>
  )
}

export default AnalyticReadershipProfileGeneratingCard
