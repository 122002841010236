import styled from '@emotion/styled'

const ButtonOrganizeStyle = styled.button`
  display: flex;
  padding: 6px 8px;
  gap: 8px;
  align-items: center;
  background-color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? 'white' : '#F7F7F7'};
  border-radius: 2px;
  flex: 1 0 0;
  cursor: pointer;
`

const ButtonOrganizeTextStyle = styled.span`
  overflow: hidden;
  color: #252525;
  text-overflow: ellipsis;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
`

interface Props {
  text: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  isSelected: boolean
  setOrganizationSelected: () => void
}

const OrganizeMenuButton = ({
  text,
  Icon,
  isSelected,
  setOrganizationSelected,
}: Props) => {
  return (
    <ButtonOrganizeStyle
      onClick={() => setOrganizationSelected()}
      isSelected={isSelected}
    >
      <Icon className="w-4 h-4" />
      <ButtonOrganizeTextStyle>{text}</ButtonOrganizeTextStyle>
    </ButtonOrganizeStyle>
  )
}

export default OrganizeMenuButton
