import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { DatasetModuleAvailableModulesInterface } from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import {
  TextDescriptionStyle,
  TitleHorizontalCardStyles,
} from './AddModuleStyles'
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/app/check.icon.svg'

const ButtonsTextStyles = styled.div`
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const HorizontalCardStyles = styled.button`
  display: flex;
  width: 264px;
  height: 520px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #3b3b3b;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  :hover:not(:disabled) {
    background: #252525;
  }
  :disabled {
    opacity: 0.5;
  }
`
const CheckBadge = styled.div`
  position: absolute;
  padding: 4px;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  background-color: #41afed;
`

const ContainerGraph = styled.div`
  display: flex;
  height: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`

const ContainerText = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`

const SuggestedPolice = styled.div`
  color: #7bbfff;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Fira Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

interface Props {
  item: DatasetModuleAvailableModulesInterface
  handleClickOnOptionModule: (
    item: DatasetModuleAvailableModulesInterface,
  ) => void
  handleClickOnAddModule: (item: DatasetModuleAvailableModulesInterface) => void
  mappingIdGrapheImg: (type: string) => string
  alreadyInReport: boolean
  replaceModule: boolean
}

const AddModuleAvailableModulesCard = ({
  item,
  handleClickOnOptionModule,
  handleClickOnAddModule,
  mappingIdGrapheImg,
  alreadyInReport,
  replaceModule,
}: Props) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState(false)

  return (
    <HorizontalCardStyles
      key={item.type}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {alreadyInReport && (
        <CheckBadge>
          <CheckIcon className="text-white w-4 h-4" />
        </CheckBadge>
      )}
      <ContainerGraph>
        <img
          src={mappingIdGrapheImg(item.type)}
          className="w-[120px] h-auto  px-1 py-2"
          alt={item.type}
        />
      </ContainerGraph>
      <ContainerText>
        <TitleHorizontalCardStyles>
          {t(`addModuleMenu.${item.type}.title`)}
        </TitleHorizontalCardStyles>
        <div className="flex flex-col flex-1 justify-between self-stretch items-start">
          <TextDescriptionStyle>
            {t(`addModuleMenu.${item.type}.description`)}
          </TextDescriptionStyle>
          {hover ? (
            <div className="flex flex-col gap-2 w-full">
              <ButtonsTextStyles
                onClick={() => handleClickOnOptionModule(item)}
                className="flex items-center justify-center px-8 py-2 rounded bg-[#3B3B3B] text-white hover:bg-[#2B2B2B]"
              >
                {t('addModuleMenu.options')}
              </ButtonsTextStyles>
              <ButtonsTextStyles
                onClick={() => handleClickOnAddModule(item)}
                className="flex items-center justify-center px-8 py-2 rounded bg-white text-[#252525] hover:bg-[#d4d4d4]"
              >
                {replaceModule
                  ? t('addModuleMenu.change')
                  : t('addModuleMenu.add')}
              </ButtonsTextStyles>
            </div>
          ) : (
            item.is_default && (
              <div className="flex items-center justify-center px-4 py-1 rounded bg-[#41AFED1A]">
                <SuggestedPolice>{t('suggested')}</SuggestedPolice>
              </div>
            )
          )}
        </div>
      </ContainerText>
    </HorizontalCardStyles>
  )
}

export default AddModuleAvailableModulesCard
