import { DynamicsResultsViewModeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import DynamicsResultsViewModeBills from './view-modes/DynamicsResultsViewModeBills'
import DynamicsResultsViewModeTable from './view-modes/DynamicsResultsViewModeTable'
import DynamicsResultsViewModeCards from './view-modes/DynamicsResultsViewModeCards'
import { TrendV2Interface } from '../../../core/interface/trend-v2.interface'
import { BookDetailInterface } from '../../../core/interface/rank/book-detail.interface'
import { TrendsItemFilterActionInterface } from '../../../core/interface/dynamics/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'
import { TagFilterEnum } from '../../../core/enums/tag-filter.enum'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'

const DynamicsResultsBody = ({
  isLoadingTrends,
  viewMode,
  trends,
  handleSortHeaderSelected,
  handleSetShowSnackBar,
  groupSelected,
  sortHeaderSelected,
  sortsSelected,
  bookDetails,
  bookDetailsWorkMirror,
  tagSelected,
  handleBookSelected,
  bookSelected,
  searchValue,
  isLoadingBookDetails,
  isLoadingBookDetailsWorkMirror,
}: {
  isLoadingTrends: boolean
  viewMode: DynamicsResultsViewModeEnum
  trends: TrendV2Interface[]
  bookDetails: BookDetailInterface | null
  bookDetailsWorkMirror: AnalyticInputInterface | null
  handleSortHeaderSelected: (filter: string, direction: number) => void
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  tagSelected: TagFilterEnum
  handleBookSelected: (book: TrendV2Interface | null) => void
  bookSelected: TrendV2Interface | null
  searchValue: string
  isLoadingBookDetails: boolean
  isLoadingBookDetailsWorkMirror: boolean
}) => {
  switch (viewMode) {
    case DynamicsResultsViewModeEnum.BILLS:
      return (
        <DynamicsResultsViewModeBills
          trends={trends}
          handleBookSelected={handleBookSelected}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookSelected={bookSelected}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
          bookDetails={bookDetails}
        />
      )
    case DynamicsResultsViewModeEnum.CARD:
      return (
        <DynamicsResultsViewModeCards
          trends={trends}
          handleBookSelected={handleBookSelected}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookSelected={bookSelected}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
          bookDetails={bookDetails}
        />
      )
    case DynamicsResultsViewModeEnum.TABLE:
      return (
        <DynamicsResultsViewModeTable
          trends={trends}
          isLoadingTrends={isLoadingTrends}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookDetails={bookDetails}
          handleSortHeaderSelected={handleSortHeaderSelected}
          handleSetShowSnackBar={handleSetShowSnackBar}
          groupSelected={groupSelected}
          sortHeaderSelected={sortHeaderSelected}
          sortsSelected={sortsSelected}
          tagSelected={tagSelected}
          handleBookSelected={handleBookSelected}
          bookSelected={bookSelected}
          searchValue={searchValue}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
        />
      )
    default:
      return <div>Pas de mode vue sélectionné</div>
  }
}

export default DynamicsResultsBody
