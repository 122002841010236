import styled from '@emotion/styled'
import { ReactElement } from 'react'
import DynamicsResultsTableBodyV2 from './DynamicsResultsTableBodyV2'
import { TrendsItemFilterActionInterface } from '../../../../../core/interface/dynamics/trends-item-filter-action.interface'
import {
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
} from '../../../../../core/config/dynamics/filters-items-dynamics.config'
import GlBadgeClilV2 from '../../../../share/badge/GlBadgeClilV2'
import { TagFilterEnum } from '../../../../../core/enums/tag-filter.enum'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'

export const Tag = styled.div`
  background: #595959;
  border-radius: 30px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;
  padding: 5px 8px;
`
export const Label = styled.div`
  font-family: Vollkorn, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  /* GENERAL/GRIS GLEEPH */
  color: #252525;
`

const DynamicsResultsExpansionPanel = ({
  keyValue,
  icon,
  data,
  isLoadingTrends,
  handleSetShowSnackBar,
  groupSelected,
  handleBookSelected,
  bookSelected,
  tagSelected,
  handleOpenPanel,
  openPanel,
}: {
  keyValue: string | number
  icon: ReactElement | undefined
  data: any[]
  isLoadingTrends: boolean
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  handleBookSelected: (book: TrendV2Interface | null) => void
  bookSelected: TrendV2Interface | null
  tagSelected: TagFilterEnum
  handleOpenPanel: (keyValue: string | number) => void
  openPanel: boolean
}) => {
  return (
    <div>
      <div
        className={`flex flex-row items-center justify-between bg-white py-2 px-5 ${
          openPanel ? 'border-b-2 rounded-t-lg' : 'rounded-lg'
        } shadow-md`}
      >
        <div className="flex flex-row items-center gap-1">
          {icon}
          {groupSelected?.key === FILTER_KEY_SEGMENT1 ||
          groupSelected?.key === FILTER_KEY_SEGMENT2 ? (
            <GlBadgeClilV2 id={`${keyValue as string}`} />
          ) : (
            <Label>{keyValue}</Label>
          )}
        </div>
        <div className="flex flex-row gap-4">
          <Tag>{data.length}</Tag>
          <button type="button" onClick={() => handleOpenPanel(keyValue)}>
            {(openPanel && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 10.2364L7.76119 5L2.52727 10.2364"
                  stroke="#252525"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )) || (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 5.76364L7.76119 11L2.52727 5.76364"
                  stroke="#252525"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {openPanel && (
        <DynamicsResultsTableBodyV2
          data={data}
          isLoadingTrends={isLoadingTrends}
          handleBookSelected={handleBookSelected}
          handleSetShowSnackBar={handleSetShowSnackBar}
          tagSelected={tagSelected}
          bookSelected={bookSelected}
        />
      )}
    </div>
  )
}

export default DynamicsResultsExpansionPanel
