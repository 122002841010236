export enum AnalyticsTypeEnum {
  WORK_MIRROR = 'work_mirror',
  READERSHIP_PROFILE = 'readership_profile',
  UNIVERS = 'univers',
  AUDIENCE = 'audience',
  PRIVATE_READERSHIP_PROFILE = 'private_readership_profile',
  PRIVATE_WORK_MIRROR = 'private_work_mirror',
}

export const AnalyticsList = [
  AnalyticsTypeEnum.WORK_MIRROR,
  AnalyticsTypeEnum.READERSHIP_PROFILE,
  AnalyticsTypeEnum.UNIVERS,
]
export const PrivateAnalyticsList = [
  AnalyticsTypeEnum.AUDIENCE,
  AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE,
  AnalyticsTypeEnum.PRIVATE_WORK_MIRROR,
]
