import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const postOnboardingInvitationsService = (
  invitationToken: string,
  data: { password: string; confirm_password: string; accept_eula: boolean },
) =>
  apiInstance.post(
    ApiPathConfig.ONBOARDING_INVITATIONS_BY_TOKEN(invitationToken),
    data,
  )
