import React from 'react'
import styled from '@emotion/styled'
import GlCheckBoxInputV2 from '../../../../share/inputs/GlCheckBoxInputV2'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import GlHeaderSortButtons from '../../../../share/buttons/GlHeaderSortButtons'

const TableHeaderStyle = styled.div`
  overflow: hidden;
  background-color: #f7f7f7;
  border-radius: 4px;
  height: 32px;
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const EditionTableHeader = ({
  disable,
  checkboxValue,
  handleCheckboxValueChange,
  sortEditions,
  handleSortDirection,
}: {
  disable: boolean
  checkboxValue: 'checked' | 'empty' | 'indeterminate'
  handleCheckboxValueChange: (checked: boolean) => void
  sortEditions: {
    columnKey: 'title' | 'edition' | 'format' | 'publishing_date'
    sortDirection: SortDirectionEnum
  }
  handleSortDirection: (value: {
    columnKey: 'title' | 'edition' | 'format' | 'publishing_date'
    sortDirection: SortDirectionEnum
  }) => void
}) => {
  const onClickHeader = (
    columnKey: 'title' | 'edition' | 'format' | 'publishing_date',
  ) => {
    if (columnKey === sortEditions.columnKey) {
      handleSortDirection({
        columnKey,
        sortDirection:
          sortEditions.sortDirection === SortDirectionEnum.ASC
            ? SortDirectionEnum.DESC
            : sortEditions.sortDirection === SortDirectionEnum.DESC
            ? SortDirectionEnum.EMPTY
            : SortDirectionEnum.ASC,
      })
    } else {
      handleSortDirection({
        columnKey,
        sortDirection: SortDirectionEnum.ASC,
      })
    }
  }

  return (
    <TableHeaderStyle className="flex flex-col">
      <div className="flex flex-row h-full gap-1">
        <div className="flex justify-center items-center p-2 min-w-[32px] p-1">
          <GlCheckBoxInputV2
            disable={disable}
            value={checkboxValue}
            onChangeEvent={handleCheckboxValueChange}
          />
        </div>
        <div
          className="flex-auto flex flex-row gap-2 justify-start items-center hover:bg-[#f4f4f4] active:bg-[#2525251a] p-1 min-w-[80px]"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            onClickHeader('title')
          }}
        >
          <span>Titre</span>
          {(sortEditions?.columnKey === 'title' && (
            <GlHeaderSortButtons sortDirection={sortEditions.sortDirection} />
          )) ||
            null}
        </div>
        <div
          className="flex-none flex justify-start gap-2 items-center min-w-[120px] max-w-[76px] hover:bg-[#f4f4f4] active:bg-[#2525251a] p-1"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            onClickHeader('edition')
          }}
        >
          <span>Éditeur</span>
          {(sortEditions?.columnKey === 'edition' && (
            <GlHeaderSortButtons sortDirection={sortEditions.sortDirection} />
          )) ||
            null}
        </div>
        <div
          className="flex-none flex justify-start gap-2 items-center min-w-[100px] max-w-[76px] hover:bg-[#f4f4f4] active:bg-[#2525251a] p-1"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            onClickHeader('format')
          }}
        >
          <span>Format</span>
          {(sortEditions?.columnKey === 'format' && (
            <GlHeaderSortButtons sortDirection={sortEditions.sortDirection} />
          )) ||
            null}
        </div>
        <div
          className="flex-none flex justify-start gap-2 items-center  min-w-[76px] max-w-[76px] hover:bg-[#f4f4f4] active:bg-[#2525251a] p-1"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            onClickHeader('publishing_date')
          }}
        >
          <span>Date</span>
          {(sortEditions?.columnKey === 'publishing_date' && (
            <GlHeaderSortButtons sortDirection={sortEditions.sortDirection} />
          )) ||
            null}
        </div>
      </div>
    </TableHeaderStyle>
  )
}

export default EditionTableHeader
