import { reduce } from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import {
  TagInterface,
  TagsFilterConfig,
} from '../../core/config/ranks/TagsFilterConfig'
import { PeriodOptionsConfig } from '../../core/config/ranks/PeriodOptionsConfig'
import { BookDetailTagsFilterConfig } from '../../core/config/ranks/book-detail-tags-filter.config'
import { ThemeInterface } from '../../core/interface/theme.interface'
import { TrendInterface } from '../../core/interface/rank/trend.interface'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { TagFilterEnum } from '../../core/enums/tag-filter.enum'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import { SelectOptionInterface } from '../../core/interface/select-option.interface'
import { PeriodInterface } from '../../core/interface/rank/period.interface'
import { RankItemActionInterface } from '../../core/interface/rank/rank-item-filter-action.interface'
import { RankSearchItemActionInterface } from '../../core/interface/rank/rank-search-item-action.interface'
import { SortDirectionEnum } from '../../core/enums/sort-direction.enum'
import { SortHeaderInterface } from '../../core/interface/sort-header.interface'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_PROGRESS,
  FILTER_KEY_RANK,
  FILTER_KEY_RELEASE_DATE,
} from '../../core/config/ranks/items-filter-action.config'
import AppConfig from '../../core/config/AppConfig'
import { TrendsStateInterface } from '../interfaces/trends.state.interface'
import {
  getBookDetail,
  getCatalogs,
  getRanksBookDetailsConstellation,
  getTagsEvolution,
  getTops,
} from '../actions/trends.actions'
import { AgeOptionsConfig } from '../../core/config/AgeOptionsConfig'
import { GenderOptionConfig } from '../../core/config/GenderOptionConfig'
import { getProgressEnumValue } from '../../core/enums/progress.enum'
import { MenuButtonEnum } from '../../core/enums/menu-button.enum'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'

const aggregate = (ranks: any): any => {
  if (ranks && ranks.length) {
    const result: {
      [key: string]: Set<string | number>
    } = {}
    ranks.forEach((rank: TrendInterface) => {
      reduce(
        rank,
        (previous: any, value: any, key: any) => {
          if (!previous[key]) {
            previous[key] = new Set<string | number>()
          }
          switch (key) {
            case FILTER_KEY_AUTHORS:
              ;(value || []).forEach((author: string) =>
                previous[key].add(author),
              )
              break
            case FILTER_KEY_RELEASE_DATE:
              previous[key].add(dayjs(value).year())
              break
            case FILTER_KEY_PROGRESS:
              previous[key].add(getProgressEnumValue(value))
              break
            default:
              previous[key].add(value)
              break
          }
          return previous
        },
        result,
      )
    })
    result[FILTER_KEY_PROGRESS] = new Set<string | number>(
      Array.from(result[FILTER_KEY_PROGRESS]).sort(),
    )
    return result
  }
}

const initialStatePeriod = (): PeriodInterface => {
  const lastWeekDate = dayjs().subtract(1, 'week')
  return {
    start: lastWeekDate.startOf('isoWeek').toISOString(),
    end: lastWeekDate.endOf('isoWeek').toISOString(),
  }
}

const getPeriodDefaultByPeriodType = (
  periodType: SelectOptionInterface<PeriodTypeEnum>,
) => {
  switch (periodType.value) {
    case PeriodTypeEnum.PERIOD:
      // eslint-disable-next-line no-case-declarations
      const lastPeriod = dayjs().subtract(1, 'week')
      return {
        start: lastPeriod.startOf('isoWeek').toISOString(),
        end: lastPeriod.endOf('isoWeek').toISOString(),
      }
    case PeriodTypeEnum.DAY:
      // eslint-disable-next-line no-case-declarations
      const lastDay = dayjs().subtract(1, 'days')
      return {
        start: lastDay.startOf('days').toISOString(),
        end: lastDay.endOf('days').toISOString(),
      }
    case PeriodTypeEnum.WEEK:
      // eslint-disable-next-line no-case-declarations
      const lastWeek = dayjs().subtract(1, 'weeks')
      return {
        start: lastWeek.startOf('weeks').toISOString(),
        end: lastWeek.endOf('weeks').toISOString(),
      }
    case PeriodTypeEnum.MONTH:
      // eslint-disable-next-line no-case-declarations
      const lastMonth = dayjs().subtract(1, 'months')
      return {
        start: lastMonth.startOf('months').toISOString(),
        end: lastMonth.endOf('months').toISOString(),
      }
    case PeriodTypeEnum.QUARTER:
      // eslint-disable-next-line no-case-declarations
      const lastQuarter = dayjs().subtract(1, 'quarters')
      return {
        start: lastQuarter.startOf('quarters').toISOString(),
        end: lastQuarter.endOf('quarters').toISOString(),
      }
    // TODO
    default:
      // eslint-disable-next-line no-case-declarations
      const defaultValue = dayjs().subtract(1, 'week')
      return {
        start: defaultValue.startOf('isoWeek').toISOString(),
        end: defaultValue.endOf('isoWeek').toISOString(),
      }
  }
}
const initialState: TrendsStateInterface = {
  // Nivation Liste
  showList: true,
  // Partie liste et filtre
  cancelToken: null,
  isLoadingRanks: false,
  ranks: [],
  ranksDataAggregate: null,
  ranksError: null,
  // Catalogues
  isLoadingThemes: false,
  themes: [],
  themeSelected: null,

  isLoadingTagsEvolution: true,
  tagsEvolution: null,
  // Filtres sur la requête des tops
  genderFilterSelected: GenderOptionConfig[0],
  ageFilterSelected: AgeOptionsConfig[0],
  tagSelected: TagsFilterConfig[0],
  bookEanTableSelected: null,
  tagBookDetailSelected: BookDetailTagsFilterConfig[0],
  periodTypeFilterSelected: PeriodOptionsConfig[2],
  periodFilterSelected: initialStatePeriod(),
  filtersActionMenuOpened: null,
  groupActionSelected: null,
  sortHeaderSelected: {
    key: FILTER_KEY_RANK,
    direction: SortDirectionEnum.ASC,
  },
  sortsActionSelected: [],
  filtersMenuOpenId: null,
  filtersActionSelected: [],
  filtersActionEditorSelected: [],
  filtersActionProgressSelected: [],
  filtersActionAuthorSelected: [],
  filtersActionDateTypeSelected: null,
  filtersActionYearsSelected: [null, null],
  filtersActionSegment1Selected: [],
  filtersActionSegment2Selected: [],
  filtersActionFormatSelected: [],
  searchValueActionChanged: '',
  searchActionLastSelection: [],

  // BookDetail
  isLoadingBookDetail: true,
  bookDetail: null,

  isLoadingBooksConstellation: false,
  booksConstellation: [],
}

export const ranksSlice = createSlice({
  name: 'ranksState',
  initialState,
  reducers: {
    ranksShowList: (
      state: TrendsStateInterface,
      action: PayloadAction<boolean>,
    ): TrendsStateInterface => ({
      ...state,
      showList: action.payload,
    }),
    ranksCancelToken: (
      state: TrendsStateInterface,
      action: PayloadAction<any>,
    ): TrendsStateInterface => ({
      ...state,
      cancelToken: action.payload,
    }),
    ranksThemeSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<ThemeInterface>,
    ): TrendsStateInterface => ({
      ...state,
      themeSelected: action.payload,
    }),
    ranksTagSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<TagInterface>,
    ) => ({
      ...state,
      tagSelected: action.payload,
    }),
    ranksGenderSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<SelectOptionInterface<GenderFilterEnum>>,
    ): TrendsStateInterface => ({
      ...state,
      genderFilterSelected: action.payload,
    }),
    ranksAgeSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<SelectOptionInterface<AgeFilterEnum>>,
    ): TrendsStateInterface => ({
      ...state,
      ageFilterSelected: action.payload,
    }),
    ranksSortHeaderSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<SortHeaderInterface | null>,
    ): TrendsStateInterface => ({
      ...state,
      sortHeaderSelected: action.payload,
    }),
    ranksFiltersActionMenuOpened: (
      state: TrendsStateInterface,
      action: PayloadAction<MenuButtonEnum | null>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionMenuOpened: action.payload,
    }),
    ranksFiltersActionAllReset: (
      state: TrendsStateInterface,
    ): TrendsStateInterface => ({
      ...state,
      groupActionSelected: null,
      filtersActionSelected: [],
      filtersActionProgressSelected: [],
      filtersActionAuthorSelected: [],
      filtersActionEditorSelected: [],
      filtersActionSegment1Selected: [],
      filtersActionSegment2Selected: [],
      filtersActionFormatSelected: [],
      filtersActionDateTypeSelected: null,
      filtersActionYearsSelected: [null, null],
      sortsActionSelected: [],
    }),
    ranksGroupActionSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface | null>,
    ): TrendsStateInterface => ({
      ...state,
      groupActionSelected: action.payload,
    }),
    ranksSortActionSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface>,
    ): TrendsStateInterface => ({
      ...state,
      sortsActionSelected: [...state.sortsActionSelected, action.payload],
    }),
    ranksSortsActionUpdated: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface[]>,
    ): TrendsStateInterface => ({
      ...state,
      sortsActionSelected: action.payload,
    }),
    ranksSortActionRemoved: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface>,
    ): TrendsStateInterface => ({
      ...state,
      sortsActionSelected: state.sortsActionSelected.filter(
        (sortAction: RankItemActionInterface) =>
          sortAction.id !== action.payload.id,
      ),
    }),
    ranksFilterActionSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionSelected: [...state.filtersActionSelected, action.payload],
    }),
    ranksFilterActionRemoved: (
      state: TrendsStateInterface,
      action: PayloadAction<RankItemActionInterface>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionSelected: state.filtersActionSelected.filter(
        (filterAction: RankItemActionInterface) =>
          filterAction.id !== action.payload.id,
      ),
    }),
    ranksFiltersActionEditorSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionEditorSelected: action.payload,
    }),
    ranksFiltersActionProgressSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionProgressSelected: action.payload,
    }),
    ranksFiltersActionAuthorSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionAuthorSelected: action.payload,
    }),
    ranksFiltersActionDateTypeSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<DateFilterTypeEnum | null>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionDateTypeSelected: action.payload,
    }),
    ranksFiltersActionYearsSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<any[]>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionYearsSelected: action.payload,
    }),
    ranksFiltersActionSegment1Selected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionSegment1Selected: action.payload,
    }),
    ranksFiltersActionSegment2Selected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionSegment2Selected: action.payload,
    }),
    ranksFiltersActionFormatSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<Array<string | number>>,
    ): TrendsStateInterface => ({
      ...state,
      filtersActionFormatSelected: action.payload,
    }),
    ranksSearchValueActionChanged: (
      state: TrendsStateInterface,
      action: PayloadAction<string>,
    ): TrendsStateInterface => ({
      ...state,
      searchValueActionChanged: action.payload || '',
    }),
    ranksSearchActionLastSelection: (
      state: TrendsStateInterface,
      action: PayloadAction<RankSearchItemActionInterface>,
    ): TrendsStateInterface => {
      if (
        state.searchActionLastSelection.length >=
        AppConfig.LIMIT_SIZE_AUTOCOMPLETION_TO_SAVE
      ) {
        const newLastSearch = [
          action.payload,
          ...state.searchActionLastSelection.filter(
            (item) => item.value !== action.payload.value,
          ),
        ]
        return {
          ...state,
          searchActionLastSelection: newLastSearch.slice(
            0,
            AppConfig.LIMIT_SIZE_AUTOCOMPLETION_TO_SAVE,
          ),
        }
      }
      return {
        ...state,
        searchActionLastSelection: [
          action.payload,
          ...state.searchActionLastSelection.filter(
            (item) => item.value !== action.payload.value,
          ),
        ],
      }
    },
    ranksBookDetailTagSelected: (
      state,
      action: PayloadAction<SelectOptionInterface<TagFilterEnum>>,
    ) => ({
      ...state,
      tagBookDetailSelected: action.payload,
    }),
    ranksBookEanTableSelected: (
      state: TrendsStateInterface,
      action: PayloadAction<number | null>,
    ) => ({
      ...state,
      bookEanTableSelected:
        action.payload === state.bookEanTableSelected ? null : action.payload,
    }),
    ranksPeriodFilterSelected: (
      state,
      action: PayloadAction<PeriodInterface>,
    ) => ({
      ...state,
      periodFilterSelected: action.payload,
    }),
    ranksPeriodTypeFilterSelected: (
      state,
      action: PayloadAction<SelectOptionInterface<PeriodTypeEnum>>,
    ) => ({
      ...state,
      periodTypeFilterSelected: action.payload,
      periodFilterSelected: getPeriodDefaultByPeriodType(action.payload),
    }),
    resetRanksState: (state) => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(getCatalogs.pending, (state: any) => {
        return {
          ...state,
          isLoadingThemes: true,
          themes: [],
          themeSelected: null,
        }
      })
      .addCase(
        getCatalogs.fulfilled,
        (
          state: TrendsStateInterface,
          action: PayloadAction<any>,
        ): TrendsStateInterface => ({
          ...state,
          isLoadingThemes: false,
          themes: action.payload,
          themeSelected: action.payload[0],
        }),
      )
      .addCase(
        getCatalogs.rejected,
        (state: TrendsStateInterface): TrendsStateInterface => ({
          ...state,
          isLoadingThemes: false,
          themes: [],
          themeSelected: null,
        }),
      )
      .addCase(
        getTops.pending,
        (state: TrendsStateInterface): TrendsStateInterface => {
          return {
            ...state,
            isLoadingRanks: true,
            ranks: [],
            ranksDataAggregate: {},
            ranksError: null,
          }
        },
      )
      .addCase(
        getTops.fulfilled,
        (
          state: TrendsStateInterface,
          action: PayloadAction<any>,
        ): TrendsStateInterface => {
          const { cancel } = action.payload
          return {
            ...state,
            isLoadingRanks: !!cancel,
            ranks: cancel ? [] : action.payload,
            ranksDataAggregate: aggregate(action.payload),
            ranksError: null,
          }
        },
      )
      .addCase(
        getTops.rejected,
        (state: TrendsStateInterface): TrendsStateInterface => {
          return {
            ...state,
            isLoadingRanks: false,
            ranks: [],
            ranksDataAggregate: {},
            ranksError: null,
          }
        },
      )
      .addCase(
        getTagsEvolution.pending,
        (state: TrendsStateInterface): TrendsStateInterface => {
          return {
            ...state,
            isLoadingTagsEvolution: true,
            tagsEvolution: null,
          }
        },
      )
      .addCase(
        getTagsEvolution.fulfilled,
        (
          state: TrendsStateInterface,
          action: PayloadAction<any>,
        ): TrendsStateInterface => ({
          ...state,
          isLoadingTagsEvolution: false,
          tagsEvolution: action.payload,
        }),
      )
      .addCase(
        getTagsEvolution.rejected,
        (state: TrendsStateInterface): TrendsStateInterface => ({
          ...state,
          isLoadingTagsEvolution: false,
          tagsEvolution: null,
        }),
      )
      .addCase(
        getBookDetail.pending,
        (state: TrendsStateInterface): TrendsStateInterface => {
          return {
            ...state,
            isLoadingBookDetail: true,
            bookDetail: null,
          }
        },
      )
      .addCase(
        getBookDetail.fulfilled,
        (
          state: TrendsStateInterface,
          action: PayloadAction<any>,
        ): TrendsStateInterface => ({
          ...state,
          isLoadingBookDetail: false,
          bookDetail: action.payload,
        }),
      )
      .addCase(
        getBookDetail.rejected,
        (state: TrendsStateInterface): TrendsStateInterface => ({
          ...state,
          isLoadingBookDetail: false,
          bookDetail: null,
        }),
      )
      .addCase(
        getRanksBookDetailsConstellation.pending,
        (state: TrendsStateInterface): TrendsStateInterface => ({
          ...state,
          isLoadingBooksConstellation: true,
          booksConstellation: [],
        }),
      )
      .addCase(
        getRanksBookDetailsConstellation.fulfilled,
        (
          state: TrendsStateInterface,
          action: PayloadAction<any>,
        ): TrendsStateInterface => ({
          ...state,
          isLoadingBooksConstellation: false,
          booksConstellation: action.payload,
        }),
      )
      .addCase(
        getRanksBookDetailsConstellation.rejected,
        (state: TrendsStateInterface): TrendsStateInterface => ({
          ...state,
          isLoadingBooksConstellation: false,
          booksConstellation: [],
        }),
      )
  },
})

export const {
  ranksShowList,
  ranksCancelToken,
  ranksSearchValueActionChanged,
  ranksSearchActionLastSelection,
  ranksFiltersActionMenuOpened,
  ranksFiltersActionAllReset,
  ranksFiltersActionDateTypeSelected,
  ranksFiltersActionYearsSelected,
  ranksFiltersActionEditorSelected,
  ranksFiltersActionProgressSelected,
  ranksFiltersActionAuthorSelected,
  ranksFiltersActionSegment1Selected,
  ranksFiltersActionSegment2Selected,
  ranksFiltersActionFormatSelected,
  ranksFilterActionRemoved,
  ranksFilterActionSelected,
  ranksGroupActionSelected,
  ranksSortHeaderSelected,
  ranksSortActionSelected,
  ranksSortsActionUpdated,
  ranksSortActionRemoved,
  ranksGenderSelected,
  ranksTagSelected,
  ranksAgeSelected,
  ranksThemeSelected,
  ranksBookDetailTagSelected,
  ranksBookEanTableSelected,
  ranksPeriodFilterSelected,
  ranksPeriodTypeFilterSelected,
  resetRanksState,
} = ranksSlice.actions

/* TODO DO HELPER
  const removeDiacritics = (value: string) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
} */

export const trendsReducer = ranksSlice.reducer
