import AddModuleHomeView from './AddModuleHomeView'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import AddModuleDataRepresentation from './AddModuleDataRepresentation'
import { InterfaceRepresentationDataList } from './AddModuleView'
import { AddModuleDataRepresentationEnum } from '../../../../../../core/enums/add-module-data-representation.enum'
import {
  AnalyticAvailableModulesInterface,
  DatasetModuleAvailableModulesInterface,
} from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import AddModuleOptions from './AddModuleOptions'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'

const AddModuleSwitch = ({
  dataRepresentationSelected,
  setDataRepresentationSelected,
  step,
  setStep,
  representationDataList,
  handleClickOnEditorialElement,
  handleClickOnModule,
  datasetModuleSelected,
  setDatasetModuleSelected,
  outputIdOfSelectedDataset,
  setOutputIdOfSelectedDataset,
  availableModules,
  replaceModule,
  analyticsSetColor,
}: {
  dataRepresentationSelected: InterfaceRepresentationDataList
  setDataRepresentationSelected: (
    dataRepresentation: InterfaceRepresentationDataList,
  ) => void
  step: AddModuleStepEnum
  setStep: (step: AddModuleStepEnum) => void
  representationDataList: InterfaceRepresentationDataList[]
  handleClickOnEditorialElement: (
    dataRepresentation: AddModuleDataRepresentationEnum,
    fileUploaded?: File,
  ) => void
  handleClickOnModule: (
    datasetModule:
      | DatasetModuleAvailableModulesInterface
      | DatasetModuleInterface,
    outputId: string,
  ) => void
  datasetModuleSelected: DatasetModuleAvailableModulesInterface | undefined
  setDatasetModuleSelected: (
    datasetModule: DatasetModuleAvailableModulesInterface,
  ) => void
  outputIdOfSelectedDataset: string
  setOutputIdOfSelectedDataset: (outputId: string) => void
  availableModules: AnalyticAvailableModulesInterface
  replaceModule: boolean
  analyticsSetColor: SetColorEnum
}) => {
  switch (step) {
    case AddModuleStepEnum.HOME:
      return (
        <AddModuleHomeView
          setStep={setStep}
          handleClickOnEditorialElement={handleClickOnEditorialElement}
        />
      )
    case AddModuleStepEnum.DATA_REPRESENTATION:
      return (
        <AddModuleDataRepresentation
          dataRepresentationSelected={dataRepresentationSelected}
          setDataRepresentationSelected={setDataRepresentationSelected}
          setStep={setStep}
          representationDataList={representationDataList}
          handleClickOnModule={handleClickOnModule}
          setDatasetModuleSelected={setDatasetModuleSelected}
          setOutputIdOfSelectedDataset={setOutputIdOfSelectedDataset}
          availableModules={availableModules}
          replaceModule={replaceModule}
        />
      )
    case AddModuleStepEnum.OPTIONS:
      return (
        <AddModuleOptions
          setStep={setStep}
          handleClickOnModule={handleClickOnModule}
          datasetModuleSelected={datasetModuleSelected}
          dataRepresentationSelected={dataRepresentationSelected}
          outputIdOfSelectedDataset={outputIdOfSelectedDataset}
          replaceModule={replaceModule}
          analyticsSetColor={analyticsSetColor}
        />
      )
    default:
      return null
  }
}

export default AddModuleSwitch
