import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { PaginationResponseInterface } from '../../../../interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../../../interface/rank/top-requests-saved.interface'

export const getTopRequestSavedService = (cancelToken?: CancelToken) =>
  apiInstance.get<PaginationResponseInterface<TopRequestSavedInterface>>(
    ApiPathConfig.TOP_REQUESTS_SAVED,
    { cancelToken, params: { limit: 50, ordered: '-modified' } },
  )
