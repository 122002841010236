import Victor from 'victor'

const computeLineBetweenCircleUtil = ({
  x1,
  y1,
  r1,
  x2,
  y2,
  r2,
}: {
  x1: number
  y1: number
  r1: number
  x2: number
  y2: number
  r2: number
}): {
  x1: number
  y1: number
  x2: number
  y2: number
} => {
  const position1 = new Victor(x1, y1)
  const position2 = new Victor(x2, y2)

  const directionalVector = position2.subtract(position1)
  const distance = directionalVector.length()
  const normal = directionalVector.normalize()
  const position1WithRadius = normal
    .clone()
    .multiply(new Victor(r1, r1))
    .add(position1)
  const position2WithRadius = normal
    .clone()
    .multiply(new Victor(distance - r2, distance - r2))
    .add(position1)

  return {
    x1: position1WithRadius.x,
    y1: position1WithRadius.y,
    x2: position2WithRadius.x,
    y2: position2WithRadius.y,
  }
}

export default computeLineBetweenCircleUtil
