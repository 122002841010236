import { RootState } from '../store'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { selectDynamicsResultsViewMode } from './dynamics-results.getters'

export const selectDynamicsSearchTopRequestSaved = (
  state: RootState,
): TopRequestSavedInterface[] => {
  const { dynamicsSearchState } = state
  // TODO : get result for the moment
  return dynamicsSearchState.topRequestSaved.results || []
}

export const selectDynamicsSearchTopRequestSavedCount = (
  state: RootState,
): number => {
  const { dynamicsSearchState } = state
  return dynamicsSearchState.topRequestSaved.count || 0
}

export const selectDynamicsSearchIsLoadingTopRequestSaved = (
  state: RootState,
): boolean => {
  const { dynamicsSearchState } = state
  return dynamicsSearchState.isLoadingTopRequestSaved
}

export const selectDynamicsSearchIsLoadingTopRequestSavedNext = (
  state: RootState,
): boolean => {
  const { dynamicsSearchState } = state
  return dynamicsSearchState.isLoadingTopRequestSavedNext
}
