import { groupBy, orderBy, xor } from 'lodash'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import DynamicsResultsExpansionPanel from './DynamicsResultsExpansionPanel'
import DynamicsResultsTableSearchResult from './DynamicsResultsTableSearchResult'
import GlLoader from '../../../../share/GlLoader'
import DynamicsResultsTableBodyNoData from './DynamicsResultsTableBodyNoData'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'
import { TrendsItemFilterActionInterface } from '../../../../../core/interface/dynamics/trends-item-filter-action.interface'
import { TagFilterEnum } from '../../../../../core/enums/tag-filter.enum'

const DynamicsResultsGroupV2 = ({
  data,
  isLoadingTrends,
  handleSetShowSnackBar,
  groupSelected,
  searchValue,
  handleBookSelected,
  bookSelected,
  tagSelected,
}: {
  data: TrendV2Interface[]
  isLoadingTrends: boolean
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  searchValue: string
  handleBookSelected: (book: TrendV2Interface | null) => void
  tagSelected: TagFilterEnum
  bookSelected: any
}) => {
  const [openPanels, setOpenPanels] = useState<Array<string | number>>([])
  const handleOpenPanel = (keyValue: string | number) => {
    setOpenPanels(xor(openPanels, [keyValue]))
  }
  let group: any = null
  if (groupSelected?.key === 'authors') {
    group = groupBy(
      orderBy(data, [groupSelected?.key], ['asc']),
      (item: TrendV2Interface) => {
        return item.authors[0]
      },
    )
  } else {
    group = groupBy(
      orderBy(data, [groupSelected?.key], ['asc']),
      groupSelected?.key,
    )
  }

  return (
    <div className={`flex flex-col gap-2 ${data.length ? 'pt-[2px]' : ''}`}>
      {searchValue && (
        <DynamicsResultsTableSearchResult itemCount={data.length} />
      )}
      {(isLoadingTrends && <GlLoader />) ||
        (data.length &&
          Object.keys(group).map((keyValue) => {
            return (
              <DynamicsResultsExpansionPanel
                key={nanoid(5)}
                icon={groupSelected?.icon}
                keyValue={keyValue}
                data={group[keyValue]}
                isLoadingTrends={isLoadingTrends}
                handleBookSelected={handleBookSelected}
                handleSetShowSnackBar={handleSetShowSnackBar}
                groupSelected={groupSelected}
                tagSelected={tagSelected}
                bookSelected={bookSelected}
                handleOpenPanel={handleOpenPanel}
                openPanel={openPanels.includes(keyValue)}
              />
            )
          })) || <DynamicsResultsTableBodyNoData />}
    </div>
  )
}

export default DynamicsResultsGroupV2
