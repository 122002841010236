import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_EDITOR,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
  FILTER_KEY_FORMAT,
  FILTER_KEY_PUBLISHING_DATE,
} from '../../core/config/dynamics/filters-items-dynamics.config'

import { SortDirectionEnum } from '../../core/enums/sort-direction.enum'
import {
  getProgressEnumValue,
  ProgressEnum,
} from '../../core/enums/progress.enum'
import { normalizedContains } from '../../core/utils/search-text.util'
import { RootState } from '../store'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'
import { TrendsItemFilterActionInterface } from '../../core/interface/dynamics/trends-item-filter-action.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { BookDetailInterface } from '../../core/interface/rank/book-detail.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'
import { dynamicsSearchGetTopRequestSavedNextAction } from '../actions/dynamics-search.actions'

export const selectDynamicsResultsViewMode = (state: RootState): any => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.viewMode
}

export const selectDynamicsResultsIsLoadingTrends = (
  state: RootState,
): boolean => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.isLoadingTrends
}

export const selectDynamicsResultsTrendsFiltered = (
  state: RootState,
): TrendV2Interface[] => {
  const { dynamicsResultsState } = state
  const { filtersActions, sortHeaderSelected, sortsSelected } =
    dynamicsResultsState

  let result = [...dynamicsResultsState.trends]
  //  Progression filter
  if (filtersActions[FILTER_KEY_DELTA_RANK].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_DELTA_RANK].includes(
        getProgressEnumValue(trend.delta_rank),
      ),
    )
  }

  //  Authors filter
  if (filtersActions[FILTER_KEY_AUTHORS].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_AUTHORS].includes(
        trend.authors.find((author: string) =>
          filtersActions[FILTER_KEY_AUTHORS].includes(author),
        ),
      ),
    )
  }

  //  Editor filter
  if (filtersActions[FILTER_KEY_EDITOR].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_EDITOR].includes(trend.edition),
    )
  }

  //  Format filter
  if (filtersActions[FILTER_KEY_FORMAT].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_FORMAT].includes(trend.format),
    )
  }

  //  Segment 1 filter
  if (filtersActions[FILTER_KEY_SEGMENT1].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_SEGMENT1].includes(trend.clil1),
    )
  }

  //  Segment 2 filter
  if (filtersActions[FILTER_KEY_SEGMENT2].length) {
    result = result.filter((trend: TrendV2Interface) =>
      filtersActions[FILTER_KEY_SEGMENT2].includes(trend.clil2),
    )
  }

  //  Date IN filter
  if (
    filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] ===
      DateFilterTypeEnum.IN &&
    filtersActions[FILTER_KEY_PUBLISHING_DATE][0] !== null
  ) {
    result = result.filter(
      (trend: TrendV2Interface) =>
        dayjs(trend.publishing_date).year() ===
        filtersActions[FILTER_KEY_PUBLISHING_DATE][0],
    )
  }

  //  Date BEFORE filter
  if (
    filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] ===
      DateFilterTypeEnum.BEFORE &&
    filtersActions[FILTER_KEY_PUBLISHING_DATE][0] !== null
  ) {
    result = result.filter(
      (trend: TrendV2Interface) =>
        dayjs(trend.publishing_date).year() <=
        filtersActions[FILTER_KEY_PUBLISHING_DATE][0],
    )
  }

  //  Date AFTER filter
  if (
    filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] ===
      DateFilterTypeEnum.AFTER &&
    filtersActions[FILTER_KEY_PUBLISHING_DATE][0] !== null
  ) {
    result = result.filter(
      (trend: TrendV2Interface) =>
        dayjs(trend.publishing_date).year() >=
        filtersActions[FILTER_KEY_PUBLISHING_DATE][0],
    )
  }

  //  Date BETWEEN filter
  if (
    filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] ===
      DateFilterTypeEnum.BETWEEN &&
    !filtersActions[FILTER_KEY_PUBLISHING_DATE].includes(null)
  ) {
    result = result.filter(
      (trend: TrendV2Interface) =>
        dayjs(trend.publishing_date).year() >=
          Math.min(...filtersActions[FILTER_KEY_PUBLISHING_DATE]) &&
        dayjs(trend.publishing_date).year() <=
          Math.max(...filtersActions[FILTER_KEY_PUBLISHING_DATE]),
    )
  }

  // Ajout recherche textuelle
  if (dynamicsResultsState.searchActions) {
    result = result.filter((value: TrendV2Interface) => {
      return (
        normalizedContains(value.title, dynamicsResultsState.searchActions) ||
        value.authors.find((author: string) =>
          normalizedContains(author, dynamicsResultsState.searchActions),
        ) ||
        normalizedContains(value.edition, dynamicsResultsState.searchActions) ||
        normalizedContains(value.format, dynamicsResultsState.searchActions)
      )
    })
  }

  // Fonction de tri
  if (sortsSelected && sortsSelected.length) {
    const columnsKey = sortsSelected.map(
      (value: { columnKey: string; sortDirection: SortDirectionEnum }) => {
        if (value.columnKey === FILTER_KEY_DELTA_RANK) {
          return (a: TrendV2Interface) => {
            if (a[FILTER_KEY_DELTA_RANK] === null) {
              return value.sortDirection === SortDirectionEnum.ASC
                ? Number.POSITIVE_INFINITY
                : Number.NEGATIVE_INFINITY
            }
            return a[FILTER_KEY_DELTA_RANK]
          }
        }
        return value.columnKey
      },
    )

    const columnsDirection = sortsSelected.map(
      (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
        value.sortDirection === SortDirectionEnum.ASC ? 'asc' : 'desc',
    )
    result = orderBy(result, columnsKey, columnsDirection)
  } else if (
    sortHeaderSelected &&
    sortHeaderSelected?.sortDirection !== SortDirectionEnum.EMPTY
  ) {
    const sortHeaderDirection =
      (sortHeaderSelected.sortDirection === SortDirectionEnum.ASC && 'asc') ||
      'desc'

    switch (sortHeaderSelected.columnKey) {
      case FILTER_KEY_DELTA_RANK:
        result = [
          ...orderBy(
            result.filter(
              (item: TrendV2Interface) =>
                getProgressEnumValue(item[FILTER_KEY_DELTA_RANK]) !==
                ProgressEnum.NC,
            ),
            [sortHeaderSelected.columnKey],
            [sortHeaderDirection],
          ),
          ...result.filter(
            (item: TrendV2Interface) =>
              getProgressEnumValue(item[FILTER_KEY_DELTA_RANK]) ===
              ProgressEnum.NC,
          ),
        ]
        break
      default:
        result = orderBy(
          result,
          [sortHeaderSelected.columnKey],
          [sortHeaderDirection],
        )
        break
    }
  }

  return result
}

export const selectDynamicsResultsTrendsAggregateByColumn = (
  state: RootState,
): TrendV2Interface[] => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.trendsAggregateByColumn
}

export const selectDynamicsResultsFiltersActions = (
  state: RootState,
): {
  [key: string]: string | Array<string | number | null> | null
} => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.filtersActions
}

export const selectDynamicsResultsHasFiltersActions = (
  state: RootState,
): boolean => {
  const { dynamicsResultsState } = state
  const { filtersActions } = dynamicsResultsState
  let hasFilter = false
  if (
    (filtersActions &&
      (filtersActions[FILTER_KEY_DELTA_RANK] as Array<any>) &&
      filtersActions[FILTER_KEY_DELTA_RANK].length) ||
    ((filtersActions[FILTER_KEY_AUTHORS] as Array<any>) &&
      filtersActions[FILTER_KEY_AUTHORS].length) ||
    ((filtersActions[FILTER_KEY_EDITOR] as Array<any>) &&
      filtersActions[FILTER_KEY_EDITOR].length) ||
    ((filtersActions[FILTER_KEY_FORMAT] as Array<any>) &&
      filtersActions[FILTER_KEY_FORMAT].length) ||
    ((filtersActions[FILTER_KEY_SEGMENT1] as Array<any>) &&
      filtersActions[FILTER_KEY_SEGMENT1].length) ||
    ((filtersActions[FILTER_KEY_SEGMENT2] as Array<any>) &&
      filtersActions[FILTER_KEY_SEGMENT2].length) ||
    (filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] !==
      DateFilterTypeEnum.BETWEEN &&
      (filtersActions[FILTER_KEY_PUBLISHING_DATE] as Array<any>).filter(
        (value) => value !== null,
      ).length > 0) ||
    (filtersActions[`${FILTER_KEY_PUBLISHING_DATE}_type`] ===
      DateFilterTypeEnum.BETWEEN &&
      (filtersActions[FILTER_KEY_PUBLISHING_DATE] as Array<any>).filter(
        (value) => value !== null,
      ).length > 1)
  ) {
    hasFilter = true
  }
  return hasFilter
}

export const selectDynamicsResultsGroupSelected = (
  state: RootState,
): TrendsItemFilterActionInterface | null => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.groupSelected
}

export const selectDynamicsResultsSortHeaderSelected = (
  state: RootState,
): { columnKey: string; sortDirection: SortDirectionEnum } => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.sortHeaderSelected
}

export const selectDynamicsResultsSortsSelected = (
  state: RootState,
): { columnKey: string; sortDirection: SortDirectionEnum }[] => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.sortsSelected
}

export const selectDynamicsResultsSearchActions = (
  state: RootState,
): string => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.searchActions
}

export const selectDynamicsResultsRequestsSavedResult = (
  state: RootState,
): TopRequestSavedInterface[] => {
  const { dynamicsResultsState } = state
  const { results } = dynamicsResultsState.requestsSaved
  return results || []
}

export const selectDynamicsResultsRequestsSavedCount = (
  state: RootState,
): number => {
  const { dynamicsResultsState } = state
  const { count } = dynamicsResultsState.requestsSaved
  return count || 0
}

export const selectDynamicsResultsIsLoadingRequestsSaved = (
  state: RootState,
): boolean => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.isLoadingRequestsSaved
}

export const selectDynamicsResultsBookSelected = (
  state: RootState,
): TrendV2Interface | null => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.bookSelected
}

export const selectDynamicsResultsIsLoadingBookDetails = (
  state: RootState,
): boolean => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.isLoadingBookDetails
}

export const selectDynamicsResultsBookDetails = (
  state: RootState,
): BookDetailInterface | null => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.bookDetails
}

export const selectDynamicsResultsIsLoadingBookDetailsWorkMirror = (
  state: RootState,
): boolean => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.isLoadingBookDetailsWorkMirror
}
export const selectDynamicsResultsBookDetailsWorkMirror = (
  state: RootState,
): AnalyticInputInterface | null => {
  const { dynamicsResultsState } = state
  return dynamicsResultsState.bookDetailsWorkMirror
}
