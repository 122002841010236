import styled from '@emotion/styled'
import { ReactNode } from 'react'

const LabelIconStyle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #595959;
`
const GlLabelIcon = ({ children }: { children: ReactNode }) => (
  <LabelIconStyle className="gap-1.5">{children}</LabelIconStyle>
)

export default GlLabelIcon
