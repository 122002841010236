export enum AgeFilterEnum {
  AGE_CATEGORY_ALL = 'all',
  AGE_CATEGORY_15_19 = 'age_15_19',
  AGE_CATEGORY_20_24 = 'age_20_24',
  AGE_CATEGORY_25_34 = 'age_25_34',
  AGE_CATEGORY_35_44 = 'age_35_44',
  AGE_CATEGORY_45_59 = 'age_45_59',
  AGE_CATEGORY_60_MORE = 'age_60',
}

export const AgeMapEnum: { [key: string]: AgeFilterEnum } = {
  all: AgeFilterEnum.AGE_CATEGORY_ALL,
  age_15_19: AgeFilterEnum.AGE_CATEGORY_15_19,
  age_20_24: AgeFilterEnum.AGE_CATEGORY_20_24,
  age_25_34: AgeFilterEnum.AGE_CATEGORY_25_34,
  age_35_44: AgeFilterEnum.AGE_CATEGORY_35_44,
  age_45_59: AgeFilterEnum.AGE_CATEGORY_45_59,
  age_60: AgeFilterEnum.AGE_CATEGORY_60_MORE,
}
