import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnalyticsDetailsReadershipProfileStateInterface } from '../../interfaces/analytics-details/analytics-details-readership-profile-state.interface'
import { analyticsDetailsReadershipProfileStateGetPreviewValue } from '../../actions/analytics-details/analytics-details-readership-profile-state.actions'

const initialState: AnalyticsDetailsReadershipProfileStateInterface = {
  isLoadingReadershipProfileInput: true,
  studyObjectType: null,
  studyObjectValue: null,
  studyObjectBooks: [],
  compareGroupType: null,
  compareGroupValue: null,
  compareGroupBooks: [],
}

const analyticsDetailsReadershipProfileSlice = createSlice({
  name: 'analyticsDetailsReadershipProfileState',
  initialState,
  reducers: {
    reinitializeAnalyticsDetailsReadershipProfileState: (
      state: AnalyticsDetailsReadershipProfileStateInterface,
    ): AnalyticsDetailsReadershipProfileStateInterface => ({
      ...state,
      isLoadingReadershipProfileInput: false,
      studyObjectType: null,
      studyObjectValue: null,
      compareGroupType: null,
      compareGroupValue: null,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        analyticsDetailsReadershipProfileStateGetPreviewValue.pending,
        (
          state: AnalyticsDetailsReadershipProfileStateInterface,
          action: PayloadAction<any>,
        ): AnalyticsDetailsReadershipProfileStateInterface => ({
          ...state,
          isLoadingReadershipProfileInput: true,
        }),
      )
      .addCase(
        analyticsDetailsReadershipProfileStateGetPreviewValue.fulfilled,
        (
          state: AnalyticsDetailsReadershipProfileStateInterface,
          action: PayloadAction<any>,
        ): AnalyticsDetailsReadershipProfileStateInterface => {
          return {
            ...state,
            isLoadingReadershipProfileInput: false,
            studyObjectType: action.payload.studyObjectType,
            studyObjectValue: action.payload.studyObjectValue,
            studyObjectBooks: action.payload.studyObjectBooks,
            compareGroupType: action.payload.compareGroupType,
            compareGroupValue: action.payload.compareGroupValue,
            compareGroupBooks: action.payload.compareGroupBooks,
          }
        },
      )
      .addCase(
        analyticsDetailsReadershipProfileStateGetPreviewValue.rejected,
        (
          state: AnalyticsDetailsReadershipProfileStateInterface,
          action: PayloadAction<any>,
        ): AnalyticsDetailsReadershipProfileStateInterface => ({
          ...state,
          isLoadingReadershipProfileInput: false,
        }),
      )
  },
})

export const { reinitializeAnalyticsDetailsReadershipProfileState } =
  analyticsDetailsReadershipProfileSlice.actions
export const analyticsDetailsReadershipProfileReducer =
  analyticsDetailsReadershipProfileSlice.reducer
