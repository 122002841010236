import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { intersection } from 'lodash'
import GlCheckBoxInputV2 from '../../../../share/inputs/GlCheckBoxInputV2'
import {
  AuthorBookTableRowStyle,
  TitleBookTableRowStyle,
} from '../ReadershipProfileStyles'
import { BookInterface } from '../../../../../core/interface/book.interface'
import { ReactComponent as ChevronBottomIcon } from '../../../../../assets/icons/app/bottom.icon.svg'

const TableRowStyle = styled.div`
  overflow: hidden;
  display: flex;
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 4px;
  height: 40px;
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  cursor: pointer;
`

const BoldLabelStyle = styled.span`
  color: ${({ color }: { color: string }) => color};
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
`

const EditionsTableRowDropDownHeader = ({
  disable,
  displaySerieName,
  dropdownOpen,
  book,
  color,
  handleToggleDropDown,
  handleCheckboxTableRowDropdownHeader,
  booksSelected,
}: {
  disable: boolean
  displaySerieName: boolean
  dropdownOpen: boolean
  book: BookInterface
  color: string
  handleToggleDropDown: () => void
  handleCheckboxTableRowDropdownHeader: (
    checked: boolean,
    books: BookInterface[],
  ) => void
  booksSelected: BookInterface[]
}) => {
  const { t } = useTranslation()
  const onClickTableRowHeader = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    handleToggleDropDown()
  }

  const getEditionsNumberSelected = (): number => {
    return intersection(booksSelected, book.books).length
  }

  const onClickCheckboxTableRowHeader = (checked: boolean): void => {
    handleCheckboxTableRowDropdownHeader(checked, book.books || [])
  }

  return (
    <TableRowStyle
      key={`table-row-title-${book.id_ean}`}
      color={
        getEditionsNumberSelected() === book.books?.length
          ? `${color}1a`
          : !getEditionsNumberSelected()
          ? 'transparent'
          : `${color}0D`
      }
      className="flex flex-row gap-1"
      onClick={onClickTableRowHeader}
    >
      <div className="flex-none p-2 flex items-center justify-center px-2 py-3">
        <GlCheckBoxInputV2
          disable={disable}
          color={color}
          value={
            getEditionsNumberSelected() === book.books?.length
              ? 'checked'
              : !getEditionsNumberSelected()
              ? 'empty'
              : 'indeterminate'
          }
          onChangeEvent={onClickCheckboxTableRowHeader}
        />
      </div>
      <div className="flex-auto flex flex-col items-start justify-center min-w-[80px] p-1">
        <TitleBookTableRowStyle className="flex w-full">
          <span>
            {displaySerieName
              ? book.series_display_name || book.title
              : book.title}
          </span>
        </TitleBookTableRowStyle>
        <AuthorBookTableRowStyle className="flex w-full">
          <BoldLabelStyle color={color}>
            {t('titleEditionsSelected', { count: getEditionsNumberSelected() })}
          </BoldLabelStyle>
          <span>&nbsp;sur&nbsp;</span>
          <span>
            {t('titleEditionsAssociation', {
              count: book.books?.length || 0,
            })}
          </span>
        </AuthorBookTableRowStyle>
      </div>
      <div className="flex-none flex justify-start items-center p-4">
        <ChevronBottomIcon
          style={{
            transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            color: '#929292',
            width: 20,
            height: 20,
          }}
        />
      </div>
    </TableRowStyle>
  )
}

export default EditionsTableRowDropDownHeader
