import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import {
  DynamicsResultsStateInterface,
  DynamicsResultsViewModeEnum,
} from '../interfaces/dynamics-results.state.interface'
import { SortDirectionEnum } from '../../core/enums/sort-direction.enum'
import {
  dynamicsResultStatePostTopRequestSavedAction,
  dynamicsResultsStateDeleteTopRequestSavedAction,
  dynamicsResultsSateGetBookDetail,
  dynamicsResultsStateGetBookDetailsConstellation,
  dynamicsResultsStateGetTopRequestSavedAction,
  dynamicsResultsStateGetTopsByCatalogId,
  dynamicsResultsStatePutTopRequestSavedAction,
  dynamicsResultStateGetBookDetailsData,
} from '../actions/dynamics-results.actions'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { aggregateTrendsDataByColumnUtil } from '../../core/utils/aggregate-trends-data-by-column.util'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_EDITOR,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_FORMAT,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_RANK,
  FILTER_KEY_SEGMENT2,
  FILTER_KEY_PUBLISHING_DATE,
} from '../../core/config/dynamics/filters-items-dynamics.config'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'
import { TrendsItemFilterActionInterface } from '../../core/interface/dynamics/trends-item-filter-action.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'

const initialState: DynamicsResultsStateInterface = {
  isLoading: false,
  viewMode: DynamicsResultsViewModeEnum.BILLS,
  // Catalogs
  cancelTokenGetCatalogs: null,
  catalogs: [],
  // Trends
  cancelTokenGetTrendsByCatalogId: null,
  isLoadingTrends: false,
  trends: [],
  // Data for actions
  trendsAggregateByColumn: null,
  // Filters Actions
  filtersActions: {
    [FILTER_KEY_DELTA_RANK]: [],
    [FILTER_KEY_AUTHORS]: [],
    [FILTER_KEY_EDITOR]: [],
    [FILTER_KEY_SEGMENT1]: [],
    [FILTER_KEY_SEGMENT2]: [],
    [FILTER_KEY_FORMAT]: [],
    [`${FILTER_KEY_PUBLISHING_DATE}_type`]: DateFilterTypeEnum.IN,
    [FILTER_KEY_PUBLISHING_DATE]: [null, null],
  },
  searchActions: '',
  sortHeaderSelected: {
    columnKey: FILTER_KEY_RANK,
    sortDirection: SortDirectionEnum.ASC,
  },
  sortsSelected: [],
  groupSelected: null,
  bookSelected: null,
  isLoadingBookDetails: false,
  bookDetails: null,
  cancelTokenBookDetails: null,
  isLoadingBookDetailsWorkMirror: false,
  cancelTokenBookDetailsWorkMirror: null,
  bookDetailsWorkMirror: null,
  isLoadingRequestsSaved: false,
  requestsSaved: { count: 0, next: null, previous: null, results: [] },
}

export const dynamicsResultsSlice = createSlice({
  name: 'dynamicsResultsState',
  initialState,
  reducers: {
    dynamicsResultsSetViewMode: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<DynamicsResultsViewModeEnum>,
    ): DynamicsResultsStateInterface => ({
      ...state,
      viewMode: action.payload,
    }),
    dynamicsResultsSetCancelTokenGetCatalogs: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<CancelTokenSource>,
    ): DynamicsResultsStateInterface => ({
      ...state,
      cancelTokenGetCatalogs: action.payload,
    }),
    reinitializeDynamicsResultsState: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => ({
      ...state,
      ...initialState,
      viewMode: state.viewMode,
      catalogs: state.catalogs,
    }),
    dynamicsResultsSetFiltersActions: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<any>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        filtersActions: action.payload,
      }
    },
    dynamicsResultsResetFilters: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        filtersActions: initialState.filtersActions,
      }
    },
    dynamicsResultsSetSearchActions: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<string>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        searchActions: action.payload,
      }
    },
    dynamicsResultsSetSortHeaderSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<{
        columnKey: string
        sortDirection: SortDirectionEnum
      }>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortHeaderSelected: action.payload,
      }
    },
    dynamicsResultsSetGroupSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<TrendsItemFilterActionInterface | null>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        groupSelected: action.payload,
      }
    },
    dynamicsResultsSetSortsSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<any>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortsSelected: action.payload,
      }
    },
    dynamicsResultsResetAllFiltersActions: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortsSelected: initialState.sortsSelected,
        groupSelected: initialState.groupSelected,
        filtersActions: initialState.filtersActions,
      }
    },
    dynamicsResultsResetBookDetails: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        bookDetails: null,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        dynamicsResultsStateGetTopsByCatalogId.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenGetTrendsByCatalogId } = state
          if (cancelTokenGetTrendsByCatalogId) {
            cancelTokenGetTrendsByCatalogId.cancel()
          }
          return {
            ...state,
            isLoadingTrends: true,
            cancelTokenGetTrendsByCatalogId: axios.CancelToken.source(),
            trends: [],
            trendsAggregateByColumn: null,
          }
        },
      )
      .addCase(
        dynamicsResultsStateGetTopsByCatalogId.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<TrendV2Interface[]>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingTrends: false,
          cancelTokenGetTrendsByCatalogId: null,
          trends: action.payload,
          trendsAggregateByColumn: aggregateTrendsDataByColumnUtil(
            action.payload,
          ),
        }),
      )
      .addCase(
        dynamicsResultsStateGetTopsByCatalogId.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingTrends: false,
          cancelTokenGetTrendsByCatalogId: null,
          trends: [],
          trendsAggregateByColumn: null,
        }),
      )
      .addCase(
        dynamicsResultStatePostTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        dynamicsResultStatePostTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultStatePostTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultsStateGetTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
          requestsSaved: initialState.requestsSaved,
        }),
      )
      .addCase(
        dynamicsResultsStateGetTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<
            PaginationResponseInterface<TopRequestSavedInterface>
          >,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
          requestsSaved: action.payload,
        }),
      )
      .addCase(
        dynamicsResultsStateGetTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
          requestsSaved: initialState.requestsSaved,
        }),
      )
      .addCase(
        dynamicsResultsStatePutTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        dynamicsResultsStatePutTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultsStatePutTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultsStateDeleteTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        dynamicsResultsStateDeleteTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultsStateDeleteTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        dynamicsResultsSateGetBookDetail.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenBookDetails } = state
          if (cancelTokenBookDetails) {
            cancelTokenBookDetails.cancel()
          }
          return {
            ...state,
            isLoadingBookDetails: true,
            bookDetails: null,
            cancelTokenBookDetails: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dynamicsResultsSateGetBookDetail.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<any>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetails: false,
          bookDetails: action.payload,
        }),
      )
      .addCase(
        dynamicsResultsSateGetBookDetail.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetails: false,
          bookDetails: null,
        }),
      )
      .addCase(
        dynamicsResultsStateGetBookDetailsConstellation.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenBookDetailsWorkMirror } = state
          if (cancelTokenBookDetailsWorkMirror) {
            cancelTokenBookDetailsWorkMirror.cancel()
          }
          return {
            ...state,
            isLoadingBookDetailsWorkMirror: true,
            bookDetailsWorkMirror: null,
            cancelTokenBookDetailsWorkMirror: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        dynamicsResultsStateGetBookDetailsConstellation.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<Partial<AnalyticInputInterface>>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetailsWorkMirror: false,
          bookDetailsWorkMirror: action.payload,
        }),
      )
      .addCase(
        dynamicsResultsStateGetBookDetailsConstellation.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetailsWorkMirror: false,
          bookDetailsWorkMirror: null,
        }),
      )
      .addCase(
        dynamicsResultStateGetBookDetailsData.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: null,
        }),
      )
      .addCase(
        dynamicsResultStateGetBookDetailsData.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<TrendV2Interface | null>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: action.payload,
        }),
      )
      .addCase(
        dynamicsResultStateGetBookDetailsData.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: null,
        }),
      )
  },
})

export const {
  dynamicsResultsSetViewMode,
  reinitializeDynamicsResultsState,
  dynamicsResultsSetFiltersActions,
  dynamicsResultsResetFilters,
  dynamicsResultsSetSearchActions,
  dynamicsResultsSetGroupSelected,
  dynamicsResultsSetSortHeaderSelected,
  dynamicsResultsSetSortsSelected,
  dynamicsResultsResetAllFiltersActions,
} = dynamicsResultsSlice.actions

export const dynamicsResultsReducer = dynamicsResultsSlice.reducer
