import { SelectOptionInterface } from '../interface/select-option.interface'

export enum AnalyticOutputImageModeEnum {
  PORTRAIT = 'portrait',
  SQUARE = 'square',
  LANDSCAPE = 'landscape',
}

export const getImageModeOptions =
  (): SelectOptionInterface<AnalyticOutputImageModeEnum>[] => {
    return [
      {
        value: AnalyticOutputImageModeEnum.LANDSCAPE,
        label: 'imageModeOptions.landscape',
      },
      {
        value: AnalyticOutputImageModeEnum.PORTRAIT,
        label: 'imageModeOptions.portrait',
      },
      {
        value: AnalyticOutputImageModeEnum.SQUARE,
        label: 'imageModeOptions.square',
      },
    ]
  }
