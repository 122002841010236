export const PrivacyCurrentConfig = {
  id: 'privacy',
  version: '1.0',
  date: '2023-06-19',
  titleMain: 'Politique de Confidentialité Bookmétrie by Gleeph',
  shortTitle: 'Politique de Confidentialité',
  contents: [
    {
      type: 'h1',
      titleMain: `PRÉAMBULE`,
      shortTitle: `Préambule`,
      contents: [
        {
          type: 'p',
          content: `Bookmétrie by Gleeph est un Site proposé par <b>F-451</b>, société par actions simplifiée au capital de 50
                    277,10 €, ayant son siège social au 28 Bis avenue de Strasbourg, 17340 Châtelaillon-Plage,
                    immatriculée au Registre du Commerce et des Sociétés de LA ROCHELLE sous le numéro 808 534 465
                    RCS LA ROCHELLE (ci-après <b>« F-451 »</b>).`,
        },
        {
          type: 'p',
          content: `F-451 peut être contacté aux coordonnées suivantes :<br>Email : <a href="mailto:dpo@gleeph.net">dpo@gleeph.net</a>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DÉFINITIONS`,
      shortTitle: `Définitions`,
      contents: [
        {
          type: 'p',
          content: `<b>«  Bookmétrie by Gleeph   »</b>  ou   <b>«  SITE Bookmétrie by Gleeph    »</b>  ou   <b>«  Site    »</b> : Désigne le site Internet
développé par   <b>F-451</b> et permettant à l’Utilisateur d’utiliser les Services.`,
        },
        {
          type: 'p',
          content: `  <b>«  Client    »</b> : Désigne le client ayant passé un contrat avec   <b>F-451</b>, dont l’objet est la mise à disposition
de ses salariés des Services Gleeph.`,
        },
        {
          type: 'p',
          content: `  <b>«  Compte Client    »</b> ou   <b>«  Compte Administrateur    »</b>  : Désigne un compte électronique créé par   <b>F-451</b>,
sur la base d’informations fournies par le Client et mis à sa disposition afin qu’il ait accès à tout ou
partie des Services Gleeph.`,
        },
        {
          type: 'p',
          content: `  <b>«  Compte Utilisateur   »</b>  : Désigne le compte électronique créé par   <b>F-451</b>, sur la base d’informations
fournies par le Client et/ou par l’Utilisateur et mis à disposition de ce dernier afin qu’il ait accès à
tout ou partie des Services Gleeph.`,
        },
        {
          type: 'p',
          content: `  <b>«  Données Personnelles    »</b>  : Désignent toute information relative à l’Utilisateur permettant de
l’identifier de manière directe ou indirecte confiée à   <b>F-451</b> par le Client ou par l’Utilisateur lui-même
par tout moyen.`,
        },
        {
          type: 'p',
          content: `  <b>« Services   »</b> ou   <b>« Services Gleeph   »</b> : Désignent les services proposés par   <b>F-451</b> via Bookmétrie by
Gleeph tels que décrits`,
        },
        {
          type: 'p',
          content: `  <b>«  Utilisateur    »</b> Désigne la personne physique désignée par le Client et inscrite sur Bookmétrie by
Gleeph.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `OBJET`,
      shortTitle: `Objet`,
      contents: [
        {
          type: 'p',
          content: `La présente Politique de Confidentialité a pour objet d’informer l’Utilisateur sur les Données
                    Personnelles traitées par   <b>F-451</b> dans le cadre de son utilisation du Site.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `RESPONSABLE DU TRAITEMENT`,
      shortTitle: `Responsable du traitement`,
      contents: [
        {
          type: 'p',
          content: `  <b>F-451</b> agit en tant que:`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">sous-traitant du responsable du 
                        traitement qu’est le Client responsable des traitements de données mis en œuvre pour
                        la mise à disposition du site Bookmétrie by Gleeph aux Utilisateurs.<br>
                        Le Client demeure responsable des traitements effectués par   <b>F-451</b> pour son compte.
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">responsable des traitements effectués
                        pour offrir les Services aux Utilisateurs et au Client.
                      </li>
                    </ul>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `FINALITÉS DU TRAITEMENT`,
      shortTitle: `Finalités du traitement`,
      contents: [
        {
          type: 'p',
          content:
            'Les finalités des traitements mis en œuvre par   <b>F-451</b> sont les suivantes :',
        },
        {
          type: 'h3',
          content:
            '1 - Permettre au Client de bénéficier des fonctionnalités du Site telles que :',
        },
        {
          type: 'p',
          content:
            'Ces traitements sont mis en œuvre sur la base de l’intérêt légitime du Client à fournir les\n' +
            'Services Gleeph aux Utilisateurs afin d’exécuter le contrat signé par le Client avec   <b>F-451</b>.',
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">La création des comptes Utilisateurs :
                        <br>La création des comptes Utilisateurs sera déléguée à   <b>F-451</b> dans la première version de
                        Bookmétrie by Gleeph.
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">La gestion des droits des Utilisateurs.
                        <br>La gestion des droits des Utilisateurs sera déléguée à   <b>F-451</b> dans la première version de
                        Bookmétrie by Gleeph.
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">La consultation de son historique de paiement et de facturation.
                        <br>Ces Services seront dans, un premier temps, disponibles par email.
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">Le paiement des Services.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">La Gestion de la relation commerciale et comptable.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">La consultation de la consommation des Services par ses Utilisateurs.</li>
                    </ul>`,
        },
        {
          type: 'p',
          content: `Ces traitements sont mis en œuvre sur la base de l’intérêt légitime du Client à fournir les Services
                    Gleeph aux Utilisateurs afin d’exécuter le contrat signé par le Client avec   <b>F-451</b>.`,
        },
        {
          type: 'h3',
          content: `2 - Permettre à l’Utilisateur de bénéficier des fonctionnalités du Site telles que :`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">La création du compte Utilisateur.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">la consultation des Résultats d’analyse du lectorat,</li>
                      <li style="list-style-type: disc; list-style-position: inside;">le partage des Résultats d’analyse du lectorat.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">La réponse aux demandes des Utilisateurs,</li>
                    </ul>`,
        },
        {
          type: 'p',
          content: `La liste des fonctionnalités précédentes est donnée à titre indicatif et pourra être amenée à
                    évoluer au fil des évolutions du site.`,
        },
        {
          type: 'p',
          content: `Ces traitements sont mis en œuvre sur la base des Conditions Générales d’Utilisation
                    acceptées par le l’Utilisateur lors de la création/activation de son compte Utilisateur.`,
        },
        {
          type: 'h3',
          content: `3 - La réalisation d’analyses statistiques et marketing en rapport avec l’utilisation du Site et des
                    Services par les Utilisateurs et le Client.`,
        },
        {
          type: 'p',
          content: `Ce traitement est mis en œuvre sur la base de l’intérêt légitime de   <b>F-451</b> d’améliorer la
                    connaissance de ses Utilisateurs et Clients à travers plusieurs indicateurs (temps passé sur le
                    Site, type de parcours…). Ces analyses permettent également d’améliorer le fonctionnement
                    des Services (ex : optimisation de l’expérience Utilisateur ; ajout de nouvelles
                    fonctionnalités…)`,
        },
        {
          type: 'h3',
          content: `4 - L’envoi de la lettre d’information et de communications aux Utilisateurs par email ou par
                    notification mobile.`,
        },
        {
          type: 'p',
          content: `Ce traitement est mis en œuvre sur la base de l’intérêt légitime de   <b>F-451</b> de proposer des
                    lettres d’information. L’Utilisateur peut s’opposer à la réception de ces lettres d’information
                    à tout moment dans le lien de désabonnement figurant en pied de page des emails.`,
        },
        {
          type: 'h3',
          content: `5 - L’amélioration des Services et du Support Client`,
        },
        {
          type: 'p',
          content: `Ce traitement est mis en œuvre sur la base de l’intérêt légitime de   <b>F-451</b> d’améliorer ses
                    Services et de fournir des prestations de support au Client.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DONNÉES PERSONNELLES TRAITÉES`,
      shortTitle: `Données personnelles traitées`,
      contents: [
        {
          type: 'h2',
          content: `1 - Données Personnelles relatives aux Utilisateurs`,
        },
        {
          type: 'line',
          content: `<span style="text-decoration: underline">Données Personnelles communiquées par l’Utilisateur ou le Client :</span>`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">
                        Données relatives à l’identité. <br>
                        L’inscription aux Services nécessite la transmission obligatoire des informations suivantes :
                        nom, prénom et adresse mail.<br>
                        L’Utilisateur s’engage à vérifier que l’adresse email utilisée est valide afin de permettre la
                        communication avec   <b>F-451</b>.<br>
                        L’Utilisateur peut également renseigner sa photo.<br>
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">Poste occupé</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Equipe de rattachement</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Messages envoyés par l’Utilisateur à Gleeph via le Site ou à <a href="mailto:dpo@gleeph.net">dpo@gleeph.net</a></li>
                    </ul>`,
        },
        {
          type: 'line',
          content: `<span style="text-decoration: underline">Données Personnelles traitées lors de l’utilisation du Site par l’Utilisateur et le Client :</span>`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">
                        Données relatives aux Prestations d’analyse du lectorat. <br>
                        La liste des recherches effectuées par l’Utilisateur est enregistrée.<br>
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">Données relatives à l’appareil de l’Utilisateur et du Client.</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Fichiers journaux</li>
                    </ul>`,
        },
        {
          type: 'p',
          content: `Lorsque l’Utilisateur ou le Client utilise le Site, les requêtes générées par le Site sont conservées
                    dans les fichiers journaux des serveurs de   <b>F-451</b> (adresse IP de l’appareil de l’Utilisateur, heure de
                    la requête, url de la requête, service demandé).`,
        },
        {
          type: 'h2',
          content: `2 - Données Personnelles relatives au Client :`,
        },
        {
          type: 'p',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">nom, prénom, email des
                        gestionnaires et des contacts facturations
                      </li>
                      <li style="list-style-type: disc; list-style-position: inside;">photographie</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Messages envoyés par le Client à F-451.</li>
                    </ul>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DESTINATAIRES DES DONNÉES PERSONNELLES`,
      shortTitle: `Destinataire des données personnelles`,
      contents: [
        {
          type: 'p',
          content: `  <b>F-451</b> peut être amené à divulguer des Données Personnelles, dans le cas où elle y serait enjointe
                    dans le cadre de procédures judiciaires ou fiscales par les autorités publiques, ou dans le cadre d’un
                    transfert tel que défini aux Conditions Générales d’Utilisation. Le nouvel acquéreur serait alors tenu
                    aux mêmes droits et obligations que   <b>F-451</b>.`,
        },
        {
          type: 'p',
          content: `Par ailleurs, les Données Personnelles peuvent être transmises ou collectées par les prestataires de 
                    <b>F-451</b> pour la finalité décrite au paragraphe <b>« Finalité   »</b>.`,
        },
        {
          type: 'p',
          content: `Les Prestataires de   <b>F-451</b> agissant en tant que sous-traitant au sens de la réglementation sur la
                    protection des données sont les suivants :`,
        },
        {
          type: 'line',
          content: `<ul>
                      <li style="list-style-type: disc; list-style-position: inside;">Hébergement des données</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Mise à disposition d’un outil de mesure de trafic</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Mise à disposition d’un outil de gestion du service Client</li>
                      <li style="list-style-type: disc; list-style-position: inside;">Mise à disposition d’outils de reporting et d’analyse marketing</li>
                    </ul>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DURÉE DE CONSERVATION DES DONNÉES`,
      shortTitle: `Durée de conservation des données`,
      contents: [
        {
          type: 'p',
          content: `  <b>F-451</b> conserve les Données de l’Utilisateur tant que ce dernier dispose d’un Compte ainsi que pour
                    la durée nécessaire au respect de ses obligations légales et réglementaires soit pour une durée
                    maximale de 10 ans à compter de la fin de la relation contractuelle.`,
        },
        {
          type: 'p',
          content: `L’Utilisateur peut, à tout moment, demander la suppression de son compte en effectuant la
                    demande auprès du Client.`,
        },
        {
          type: 'p',
          content: `A compter de la réception de la demande du Client,   <b>F-451</b> fera ses meilleurs efforts pour supprimer
                    le Compte Utilisateur dans les meilleurs délais.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DROITS DES PERSONNES`,
      shortTitle: `Droit des personnes`,
      contents: [
        {
          type: 'h2',
          content: `Droits généraux`,
        },
        {
          type: 'p',
          content: `Aux fins de simplification de gestion des demandes, le droit d’accès, de rectification, d’opposition, de
                    limitation, de suppression et de portabilité des Données Personnelles devra être exercé auprès du
                    Client qui transmettra la demande à   <b>F-451</b>.`,
        },
        {
          type: 'p',
          content: `Une réponse sera adressée à l’Utilisateur dans un délai d’un mois après la réception de la demande.
                    Dans certains cas, liés à la complexité de la demande ou du nombre de demande, ce délai peut être
                    prolongé de 2 mois.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `RÉCLAMATIONS`,
      shortTitle: `Réclamations`,
      contents: [
        {
          type: 'p',
          content: `L’Utilisateur dispose du droit d’introduire une réclamation auprès de la CNIL. Pour plus
                  d’information, l’Utilisateur peut consulter le site de la CNIL en suivant le lien suivant
                  <a href="https://www.cnil.fr">https://www.cnil.fr</a>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `TRANSFERTS DE DONNÉES PERSONNELLES EN DEHORS DE L’UNION EUROPÉENNE`,
      shortTitle: `Transferts de données personnelles en dehors de l'union européenne`,
      contents: [
        {
          type: 'p',
          content: `L’Utilisateur est informé que des Données Personnelles le concernant peuvent être transmises pour
                    les besoins des finalités définies ci-dessus à des sociétés situées dans des pays situés hors de l’Union
                    Européenne et dont la législation ne présente pas un niveau de protection adéquat s’agissant de la
                    protection des données personnelles.`,
        },
        {
          type: 'p',
          content: `Préalablement au transfert hors Union Européenne, et conformément à la réglementation en
                    vigueur,   <b>F-451</b> mets en œuvre toutes les procédures requises pour obtenir les garanties nécessaires à
                    la sécurisation de tels transferts.`,
        },
        {
          type: 'p',
          content: `Des transferts hors Union Européenne peuvent être réalisés notamment dans le cadre des activités
                    réalisées par les Prestataires listés ci-dessus.`,
        },
        {
          type: 'p',
          content: `Les transferts réalisés par   <b>F-451</b> sont les suivants :`,
        },
        {
          type: 'p',
          content: `Pays de destination des données : Encadrement du transfert de données<br/>
                    Etats-Unis : Clauses contractuelles types et mesures de sécurité complémentaires<br/>
                    Canada : Décision d’adéquation<br/>`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `GESTION DES COOKIES, TAGS ET AUTRES TRACEURS`,
      shortTitle: `Gestion des cookies, tags et autres traceurs`,
      contents: [
        {
          type: 'line',
          content: `<b>Qu’est-ce qu’un cookie ?</b>`,
        },
        {
          type: 'p',
          content: `Un cookie est un fichier texte déposé sur votre terminal lors de la visite d'un site ou de la
                    consultation d'une publicité. Il a pour but notamment de collecter des informations relatives à votre
                    navigation et de vous adresser des services adaptés à votre terminal (ordinateur, mobile ou tablette).
                    Dans votre ordinateur les cookies sont gérés par votre navigateur internet.`,
        },
        {
          type: 'line',
          content: `<b>Les cookies analytiques</b>`,
        },
        {
          type: 'p',
          content: `Il s'agit des cookies qui nous permettent de connaître l'utilisation et les performances de notre site et
                    d'en améliorer le fonctionnement (par exemple, les pages le plus souvent consultées).`,
        },
        {
          type: 'p',
          content: `Nous utilisons Google Analytics. Google Analytics est susceptible d’enregistrer les informations
                    suivantes :<br/>
                    Identifiants Online tels que des cookies, adresse IP, identifiant de votre appareil.<br />
                    Lieu de traitement par Google Inc : Etats-Unis`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `ACCEPTER OU REFUSER LES COOKIES`,
      shortTitle: `Accepter ou refuser les cookies`,
      contents: [
        {
          type: 'h2',
          content: `Acceptation ou refus des cookies`,
        },
        {
          type: 'p',
          content: `L’enregistrement de cookies sur votre ordinateur est subordonné à l’obtention de votre
                    consentement que vous donnez en cliquant sur le bouton <b>« Ok   »</b> du bandeau cookie.`,
        },
        {
          type: 'h2',
          content: `Finalités des cookies`,
        },
        {
          type: 'p',
          content: `Selon vos choix (que vous pouvez modifier à tout moment), les cookies que nous émettons nous
                    permettent principalement de conserver vos informations de connexion et de réaliser des
                    statistiques de visites.`,
        },
        {
          type: 'h2',
          content: `Durée de conservation des cookies`,
        },
        {
          type: 'p',
          content: `Sauf indication contraire dans le gestionnaire de consentement implémenté par   <b>F-451</b>, les cookies
                    sont conservés pour une durée de 13 mois après leur premier dépôt dans votre équipement.`,
        },
        {
          type: 'h2',
          content: `Typologie de cookies utilisés`,
        },
        {
          type: 'p',
          content: `Les cookies utilisés sont détaillés dans le gestionnaire de consentement implémenté par <b>F-451</b>.`,
        },
      ],
    },
    {
      type: 'h1',
      titleMain: `DÉLÉGUÉ À LA PROTECTION DES DONNÉES`,
      shortTitle: `Délégué à la protection des données`,
      contents: [
        {
          type: 'p',
          content: `Le délégué à la protection des données nommé par <b>F-451.</b> a pour mission de veiller au respect de la
                    réglementation et des règles décrites dans le présent document.`,
        },
        {
          type: 'p',
          content: `Il veille notamment à établir un registre des traitements de données à caractère personnel mis en
                    œuvre dans l’entreprise et à s’assurer de la conformité de ceux-ci avec la réglementation et ses
                    évolutions.`,
        },
        {
          type: 'p',
          content: `Il assure une sensibilisation périodique des équipes et répond aux Utilisateurs souhaitant exercer
                    leurs droits concernant les données personnelles collectées par <b>F-451.</b>`,
        },
        {
          type: 'p',
          content: `Le délégué à la protection des données peut être contacté aux coordonnées suivantes : <br/>Email: <a href="mailto:dpo@gleeph.net">dpo@gleeph.net</a>`,
        },
      ],
    },
  ],
}
