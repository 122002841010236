import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as LeftChevronIcon } from '../../../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as EditionIcon } from '../../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as FormatIcon } from '../../../../assets/icons/app/format.icon.svg'
import { ReactComponent as StarPopularityIcon } from '../../../../assets/icons/app/star-popularity.icon.svg'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'
import GlSpacer from '../../../share/GlSpacer'
import GlLoader from '../../../share/GlLoader'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilV2 from '../../../share/badge/GlBadgeClilV2'
import GlReadMore from '../../../share/GlReadMore'
import { BookDetailResponseInterface } from '../../../../core/interface/rank/book-detail.interface'
import RankCommentHorizontalList from '../../../trends/book-details/RankCommentHorizontalList'
import DynamicsResultsBookDetailsProvider from '../../../dynamics-results/body/book-details/DynamicsResultsBookDetailsProvider'
import { getPopularityIndexByValue } from '../../../../core/enums/popularity.enum'

const TitleStyle = styled.div`
  color: white;
  /* Book Title/T1 */
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 370px;
`

const AuthorStyle = styled.div`
  color: #e9e9e9;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const BadgeStyle = styled.span`
  color: #fff;
  /* Caption/C1 - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  background: #e9e9e933;
  padding: 4px;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  /* Caption/C1 - Medium - Uppercase */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 4px;
  background: #e9e9e9;
  border-radius: 4px;
`

const HeaderStyle = styled.div`
  color: #3b3b3b;
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/B2 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const ShareAnalyticDetailsBookDetails = ({
  isLoadingGetBookDetails,
  bookDetailsSelected,
  isLoadingGetB2BBookDetails,
  bookDetailsSelectedB2BInfo,
  openListCard,
  handleCloseBookDetails,
}: {
  isLoadingGetBookDetails: boolean
  bookDetailsSelected: WorkMirrorBookInterface | null
  isLoadingGetB2BBookDetails: boolean
  bookDetailsSelectedB2BInfo: BookDetailResponseInterface | null
  openListCard: boolean
  handleCloseBookDetails: (returnToList?: boolean) => void
}) => {
  const onClickCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseBookDetails()
  }

  const onClickReturnToListButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleCloseBookDetails(false)
  }

  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const percentage = (value: number) =>
    (value && `${Math.round((1 - value) * 1000) / 10}%`) || ''

  const getProvider = () => {
    if (bookDetailsSelectedB2BInfo) {
      const { data } = bookDetailsSelectedB2BInfo
      if (data) {
        const { provider } = data
        return provider
      }
    }
    return null
  }

  return (
    <div
      className="flex flex-col basis-1/3 gap-4"
      style={{
        maxWidth: 506,
      }}
    >
      <div className="flex flex-row items-center justify-between">
        {(openListCard && (
          <button
            type="button"
            className="rounded flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9]"
            onClick={onClickReturnToListButton}
          >
            <LeftChevronIcon className="w-4 h-4" />
            <span>Retour à la liste</span>
          </button>
        )) || <GlSpacer />}
        <button
          type="button"
          className="flex flex-row items-center justify-center gap-2 p-2 bg-[#e9e9e9] rounded"
          onClick={onClickCloseButton}
        >
          <CrossIcon className="w-4 h-4" />
          <span>Fermer</span>
        </button>
      </div>
      <div className="relative flex-auto flex flex-col rounded-xl bg-white">
        {(isLoadingGetBookDetails && (
          <div className="absolute w-full h-full flex items-center justify-center">
            <GlLoader />
          </div>
        )) || (
          <div className="flex-none flex flex-col rounded-t-xl bg-[#444444] shadow-2xl">
            <div className="flex flex-col">
              <div className="flex flex-row p-4  rounded-t-[8px] gap-2">
                <img
                  className="rounded"
                  src={
                    bookDetailsSelected?.book?.imgs ||
                    getBookCoverByClil(bookDetailsSelected?.book?.clil1)
                  }
                  alt="cover book"
                  style={{
                    minWidth: 88,
                    maxWidth: 88,
                    minHeight: 120,
                    maxHeight: 120,
                    objectFit: 'cover',
                  }}
                />
                <div
                  className="flex flex-col gap-1"
                  style={{
                    minHeight: 120,
                    maxHeight: 120,
                  }}
                >
                  <TitleStyle className="flex flex-row  basis-1/4">
                    {bookDetailsSelected?.book?.title}
                  </TitleStyle>
                  <AuthorStyle className="flex flex-row basis-1/4">
                    {bookDetailsSelected?.book?.authors[0]}
                  </AuthorStyle>
                  <div className="flex flex-row basis-1/4">
                    <GlBadgeClilV2
                      id={String(bookDetailsSelected?.book?.clil1)}
                      fontColor="white"
                    />
                  </div>
                  <div className="flex flex-row gap-2 basis-1/4">
                    <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                      {dayjs(bookDetailsSelected?.book?.publishing_date).format(
                        'MM/YYYY',
                      )}
                    </BadgeStyle>
                    <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                      <EditionIcon className="w-4 h-4" />
                      {bookDetailsSelected?.book?.edition}
                    </BadgeStyle>
                    <BadgeStyle className="flex flex-row gap-2 items-center justify-center">
                      <FormatIcon className="w-4 h-4" />
                      {bookDetailsSelected?.book?.format}
                    </BadgeStyle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <PerfectScrollbar
          className="flex-auto flex flex-col p-4 bg-white rounded-b-xl"
          style={{
            height: 0,
          }}
        >
          <div className="flex flex-col gap-4">
            {(bookDetailsSelected?.distance &&
              bookDetailsSelected?.popularity && (
                <div className="flex flex-row gap-4">
                  <LabelStyle className="flex flex-auto items-center justify-between">
                    <div>Proximité</div>
                    <div>{percentage(bookDetailsSelected?.distance || 0)}</div>
                  </LabelStyle>
                  <LabelStyle className="flex flex-auto items-center justify-between">
                    <div>Popularité</div>
                    <div className="flex flex-row items-center justify-center gap-2">
                      {Array(5)
                        .fill(null)
                        .map((_: null, index: number) => (
                          <StarPopularityIcon
                            key={nanoid(5)}
                            style={{
                              color:
                                getPopularityIndexByValue(
                                  bookDetailsSelected?.popularity,
                                ) >= index
                                  ? '#252525'
                                  : '#bebebe',
                            }}
                          />
                        ))}
                    </div>
                  </LabelStyle>
                </div>
              )) ||
              null}
            {(bookDetailsSelected?.book?.description.length && (
              <div className="flex flex-col gap-4">
                <HeaderStyle>Resumé</HeaderStyle>
                <div className="text-xs">
                  <GlReadMore
                    content={`${bookDetailsSelected?.book?.description}`}
                    nbShowsCharacters={120}
                  />
                </div>
              </div>
            )) || (
              <div className="flex flex-col gap-4">
                <HeaderStyle>Pas de description présent</HeaderStyle>
              </div>
            )}
            {(isLoadingGetB2BBookDetails && (
              <div>
                <GlLoader />
              </div>
            )) ||
              (bookDetailsSelectedB2BInfo &&
                (bookDetailsSelectedB2BInfo?.comments || []).length && (
                  <div
                    className="flex flex-col gap-4"
                    style={{
                      maxWidth: 474,
                    }}
                  >
                    <HeaderStyle>Derniers avis sur cette œuvre</HeaderStyle>
                    <div className="text-xs">
                      <RankCommentHorizontalList
                        comments={bookDetailsSelectedB2BInfo?.comments || []}
                      />
                    </div>
                  </div>
                )) ||
              null}
            <GlSpacer />
            {(bookDetailsSelectedB2BInfo?.data?.provider && (
              <div className="flex flex-row items-center justify-center">
                <DynamicsResultsBookDetailsProvider provider={getProvider()} />
              </div>
            )) ||
              null}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default ShareAnalyticDetailsBookDetails
