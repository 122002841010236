import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import AnalyticDetailsLayout from '../../components/analytics-details-v2/AnalyticDetailsLayout'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import NewAudience from '../../components/audience/new-audience/NewAudience'
import AnalyticsDialogExit from '../../components/share/dialog/AnalyticsDialogExit'
import useAudienceCatalogHook from '../../core/hooks/useAudienceCatalog.hook'
import useShowWorkProfileHook from '../../core/hooks/useShowWorkProfile.hook'
import GlSideBar from '../../components/share/GlSideBar'
import WorkMirrorBookDetailsCard from '../../components/analytics-details-v2/view/work-mirror/WorkMirrorBookDetailsCard'
import { AudienceInputInterface } from '../../core/interface/audience/audience-input.interface'

const NewAudienceView = () => {
  const navigate = useNavigate()
  const { state: replicatedInput } = useLocation()
  const { t } = useTranslation()

  const [audienceName, setAudienceName] = useState<string>(
    t('createAudience.defaultTitle', { date: dayjs().format('DD/MM/YYYY') }),
  )
  const [exitDialog, setExitDialog] = useState<boolean>(false)

  const { catalogs, isLoading } = useAudienceCatalogHook()
  const {
    bookSelectedEan,
    setBookSelectedEan,
    bookDetails,
    isLoadingBookDetails,
    bookEditions,
    isLoadingEditions,
    bookB2BInfos,
    isLoadingB2BInfos,
    bookReco,
    isLoadingReco,
  } = useShowWorkProfileHook()

  const backToPrivateReports = useCallback(() => {
    navigate(
      buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.PRIVATE_REPORTS]),
    )
  }, [])

  const closeDialog = useCallback(() => {
    setExitDialog(false)
  }, [])

  return (
    <>
      <AnalyticDetailsLayout
        analyticName={audienceName}
        handleRenameAnalytic={setAudienceName}
        handleExitAnalyticDetails={() => setExitDialog(true)}
        isLoading={isLoading}
        hasButtonPrevious={false}
      >
        <div className="flex-auto flex flex-col">
          <NewAudience
            title={audienceName}
            isLoading={isLoading}
            catalogs={catalogs}
            eanSelected={bookSelectedEan}
            handleOpenBookDetails={setBookSelectedEan}
            replicatedInput={replicatedInput as AudienceInputInterface}
          />
          {bookDetails && (
            <GlSideBar
              open
              position="right"
              hasOverlay
              width={506}
              scrollable={false}
              height="h-[calc(100%-48px)]"
            >
              <WorkMirrorBookDetailsCard
                bookDetails={bookDetails}
                isLoadingGetBookDetails={isLoadingBookDetails}
                bookDetailsSelectedEditions={bookEditions}
                isLoadingGetEditions={isLoadingEditions}
                bookDetailsSelectedB2BInfo={bookB2BInfos}
                isLoadingGetB2BBookDetails={isLoadingB2BInfos}
                bookDetailsBookReco={bookReco}
                isLoadingBookDetailsGetBookReco={isLoadingReco}
                handleCloseBookDetails={() => setBookSelectedEan(null)}
                openListCard={false}
              />
            </GlSideBar>
          )}
        </div>
      </AnalyticDetailsLayout>
      <AnalyticsDialogExit
        open={exitDialog}
        onValidate={backToPrivateReports}
        onClose={closeDialog}
      />
    </>
  )
}

export default NewAudienceView
