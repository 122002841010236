import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { PermissionEnum } from '../../../../enums/permission.enum'

export const patchUserPermissionToShareReportService = (
  reportId: string,
  usersPermissionToAdd: {
    users: string[]
    message: string
    permission: PermissionEnum
  },
  cancelToken?: CancelToken,
) => {
  return apiInstance.patch(
    ApiPathConfig.REPORT_BY_ID_USERS_SHARE(reportId),
    usersPermissionToAdd,
    { cancelToken },
  )
}
