import styled from '@emotion/styled'

export const TextCardStyle = styled.div`
  color: #bebebe;
  font-family: 'Fira Sans';
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const TextDescriptionStyle = styled.div`
  color: #bebebe;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Fira Sans';
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const TitleAddModuleStyle = styled.div`
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Vollkorn;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`

export const TitleHorizontalCardStyles = styled.div`
  color: #fff;
  font-family: 'Fira Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

export const SubTitleAddModuleStyle = styled.div`
  color: #e9e9e9;
  text-align: center;
  font-family: Vollkorn;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
