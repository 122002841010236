import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ApiPathConfig } from '../../core/config/ApiPathConfig'
import apiInstance from '../../core/api/backend-api.setup'
import { RanksResponseInterface } from '../../core/interface/rank/ranks-response.interface'
import { TagsEvolutionResponseInterface } from '../../core/interface/rank/tags-evolution-response.interface'
import { RankDTOModel } from '../../core/model/rank-dto.model'
import { ThemeInterface } from '../../core/interface/theme.interface'
import { RootState } from '../store'
import {
  ranksCancelToken,
  ranksFiltersActionAllReset,
} from '../reducers/trends.reducer'
import { getB2bBookDetailsByEanIdService } from '../../core/api/services/others/get-b2b-book-details-by-ean-id.service'
import { getCatalogsService } from '../../core/api/services/bookmetrie/get-catalogs.service'
import getBookRecoV3Service from '../../core/api/services/bookmetrie/get-book-reco-v3.service'

export const getTops = createAsyncThunk(
  'ranks/get-tops',
  async (catalogId: string, { dispatch, getState }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (getState().ranksState.cancelToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getState().ranksState.cancelToken.cancel()
      }

      dispatch(ranksCancelToken(axios.CancelToken.source()))
      dispatch(ranksFiltersActionAllReset())

      const response = await apiInstance.get<RanksResponseInterface[]>(
        ApiPathConfig.TOPS(catalogId),
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cancelToken: getState().ranksState.cancelToken?.token,
          params: {
            start_date:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.periodFilterSelected?.start,
            end_date:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.periodFilterSelected?.end,
            age_category:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.ageFilterSelected?.value,
            sex_category:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.genderFilterSelected?.value,
            tag:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.tagSelected?.code,
          },
        },
      )
      return response.data.map((item) => new RankDTOModel(item))
    } catch (error) {
      if (axios.isCancel(error)) {
        return { cancel: true }
      }
      throw new Error()
    }
  },
)

export const getCatalogs = createAsyncThunk(
  'ranks/get-catalogs',
  async (_, { dispatch }) => {
    try {
      const response = await getCatalogsService()
      const { id: catalogId } = response.data[0]
      if (catalogId) {
        await dispatch(getTops(catalogId))
      }

      return (response.data || []).map((value: any) => value as ThemeInterface)
    } catch (error) {
      throw new Error()
    }
  },
)

export const getTagsEvolution = createAsyncThunk(
  'ranks/get-tag-evolution',
  async (ean: number, { getState }) => {
    try {
      const response = await apiInstance.get<TagsEvolutionResponseInterface[]>(
        ApiPathConfig.TAGS_EVOLUTION,
        {
          params: {
            start_date:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.periodFilterSelected.start,
            end_date:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getState<RootState>().ranksState.periodFilterSelected.end,
            ean,
          },
        },
      )
      return response.data
    } catch (error) {
      throw new Error()
    }
  },
)

export const getRanksBookDetailsConstellation = createAsyncThunk(
  'ranks/get-ranks-book-details-constellation',
  async (ean: number) => {
    try {
      const { data } = await getBookRecoV3Service({
        eanIds: [ean],
        nbrecos: 7,
      })
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const getBookDetail = createAsyncThunk(
  'ranks/get-book-detail',
  async (ean: number, { dispatch }) => {
    try {
      const response = await getB2bBookDetailsByEanIdService(ean)
      void dispatch(getTagsEvolution(ean))
      void dispatch(getRanksBookDetailsConstellation(ean))
      return response.data
    } catch (error) {
      throw new Error()
    }
  },
)
