import { AgeFilterEnum } from '../enums/age-filter.enum'

export const AgeOptionsConfig: {
  label: string
  value: AgeFilterEnum
  default?: boolean
}[] = [
  {
    label: 'Tous',
    value: AgeFilterEnum.AGE_CATEGORY_ALL,
    default: true,
  },
  {
    label: '15 - 19 ans',
    value: AgeFilterEnum.AGE_CATEGORY_15_19,
  },
  {
    label: '20 - 24 ans',
    value: AgeFilterEnum.AGE_CATEGORY_20_24,
  },
  {
    label: '25 - 34 ans',
    value: AgeFilterEnum.AGE_CATEGORY_25_34,
  },
  {
    label: '35 - 44 ans',
    value: AgeFilterEnum.AGE_CATEGORY_35_44,
  },
  {
    label: '45 - 59 ans',
    value: AgeFilterEnum.AGE_CATEGORY_45_59,
  },
  {
    label: '60+ ans',
    value: AgeFilterEnum.AGE_CATEGORY_60_MORE,
  },
]
