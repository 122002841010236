import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { intersection, uniq, xor, remove } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ClilDropDownInterface } from '../../../../../../core/config/clil-dropdown.util'
import GlCheckBoxInputV2 from '../../../../../share/inputs/GlCheckBoxInputV2'
import { ReactComponent as BottomArrowIcon } from '../../../../../../assets/icons/app/bottom-chevron.icon.svg'
import { ReactComponent as SubLevelIcon } from '../../../../../../assets/icons/app/sub-level.icon.svg'
import ClilReferencesMappingConfig, {
  ClilInterface,
} from '../../../../../../core/config/clil-refs-mapping.config'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'

const LabelStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const LevelStyle = styled.div`
  color: #666;
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
`

const ClilNumberStyle = styled.div`
  color: #929292;
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
`

const ToggleButtonLevelStyle = styled.button`
  width: 99px;
  overflow: hidden;
  color: #929292;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  padding: 8px;
  border-radius: 4px;
  margin-right: 8px;
  :hover {
    background-color: #2525251a;
  }

  :active {
    background-color: #25252533;
  }
`
const ClilMenu = ({
  color,
  clils,
  openLevels,
  setOpenLevels,
  inputValue,
  handleSelectClil,
}: {
  color: string
  clils: ClilDropDownInterface[]
  openLevels: string[]
  setOpenLevels: (openLevels: string[]) => void
  inputValue: string[]
  handleSelectClil: (clilIds: string[]) => void
}) => {
  const { t } = useTranslation()
  const getClilReference = (clilId: any): ClilInterface => {
    const { 1: level1 } =
      clilReferencesConfig[clilId] || clilReferencesConfig['0000']

    return (
      ClilReferencesMappingConfig[level1.id] ||
      ClilReferencesMappingConfig['0000']
    )
  }

  const getCheckBoxValueLevel1and2 = (
    clilLevel: ClilDropDownInterface,
  ): 'checked' | 'empty' | 'indeterminate' => {
    if ((inputValue || []).includes(clilLevel.id)) return 'checked'

    const clilFiltered = clils
      .filter((clil: ClilDropDownInterface) => clil.parentId === clilLevel.id)
      .map((clil: ClilDropDownInterface) => clil.id)

    const clilFilteredGrandChild = clils
      .filter((clil: ClilDropDownInterface) =>
        clilFiltered.includes(clil.parentId as string),
      )
      .map((clil: ClilDropDownInterface) => clil.id)

    const inter = intersection(
      [...clilFiltered, ...clilFilteredGrandChild],
      [...(inputValue || [])],
    )

    let type = 'indeterminate' as 'checked' | 'empty' | 'indeterminate'
    if (
      (inputValue || []).includes(clilLevel?.parentId as string) ||
      (inputValue || []).includes(clilLevel.id)
    ) {
      type = 'checked'
    } else if (inter.length === 0) {
      type = 'empty'
    }
    return type
  }

  const getCheckBoxValueLevel3 = (
    clilLevel: ClilDropDownInterface,
  ): 'checked' | 'empty' | 'indeterminate' => {
    const clilLevelParent = clils.filter(
      (clil: ClilDropDownInterface) => clil.id === clilLevel.parentId,
    )[0]

    if (
      (inputValue || []).includes(clilLevelParent?.parentId as string) ||
      (inputValue || []).includes(clilLevel?.parentId as string) ||
      (inputValue || []).includes(clilLevel.id)
    )
      return 'checked'

    return 'empty'
  }

  function allChildAreChecked(
    clilLevel: ClilDropDownInterface,
    inputValue: string[],
    inter: string[],
  ) {
    handleSelectClil(
      xor([...(inputValue || [])], [clilLevel?.parentId as string, ...inter]),
    )
  }

  function childOfParentSelectedIsChecked(
    clilLevel: ClilDropDownInterface,
    inputValue: string[],
    allChildWithSameParentAsClilSelected: string[],
  ) {
    handleSelectClil(
      xor(
        [...(inputValue || []), ...allChildWithSameParentAsClilSelected],
        [clilLevel?.parentId as string, clilLevel.id],
      ),
    )
  }

  function checkParent(
    clilLevel: ClilDropDownInterface,
    inputValue: string[],
    allChildWithClilSelectedAsParent: string[],
    allChildWithClilSelectedAsGrandParent: string[],
  ) {
    handleSelectClil(
      remove(xor([...(inputValue || [])], [clilLevel.id]), (n) => {
        return (
          !allChildWithClilSelectedAsParent.includes(n) &&
          !allChildWithClilSelectedAsGrandParent.includes(n)
        )
      }),
    )
  }

  function allChildAndAllParentAreChecked(
    clilLevel: ClilDropDownInterface,
    clilLevelParent: ClilDropDownInterface | undefined,
    inputValue: string[],
    childWithSameParentAlreadySelected: string[],
    allChildWithSameParentAsClilParentSelected: string[],
  ) {
    handleSelectClil(
      xor(
        [...(inputValue || []), clilLevel.parentId as string],
        [
          clilLevelParent?.parentId as string,
          ...childWithSameParentAlreadySelected,
          ...allChildWithSameParentAsClilParentSelected,
        ],
      ),
    )
  }

  function checkChildWhenGrandParentIsSelected(
    clilLevel: ClilDropDownInterface,
    clilLevelParent: ClilDropDownInterface | undefined,
    allChildWithSameParentAsClilSelected: string[],
    allChildWithSameParentAsClilParentSelected: string[],
  ) {
    handleSelectClil(
      xor(
        [
          ...(inputValue || []),
          ...allChildWithSameParentAsClilSelected,
          clilLevel.parentId as string,
        ],
        [
          clilLevel.id,
          clilLevelParent?.parentId as string,
          ...allChildWithSameParentAsClilParentSelected,
        ],
      ),
    )
  }

  function checkOneBox(clilLevel: ClilDropDownInterface, inputValue: string[]) {
    handleSelectClil(xor([...(inputValue || [])], [clilLevel.id]))
  }

  const handleSelectClilLevel = (clilLevel: ClilDropDownInterface) => {
    const allChildWithSameParentAsClilSelected = clils
      .filter(
        (clil: ClilDropDownInterface) => clil.parentId === clilLevel.parentId,
      )
      .map((clil: ClilDropDownInterface) => clil.id)

    const childWithSameParentAlreadySelected = intersection(
      [...allChildWithSameParentAsClilSelected],
      [...(inputValue || [])],
    )
    const allChildWithClilSelectedAsParent = clils
      .filter((clil: ClilDropDownInterface) => clil.parentId === clilLevel.id)
      .map((clil: ClilDropDownInterface) => clil.id)

    const allChildWithClilSelectedAsGrandParent = clils
      .filter((clil: ClilDropDownInterface) =>
        allChildWithClilSelectedAsParent.includes(clil.parentId as string),
      )
      .map((clil: ClilDropDownInterface) => clil.id)

    const clilLevelParent = clils.find(
      (clil: ClilDropDownInterface) => clil.id === clilLevel.parentId,
    )

    let allChildWithSameParentAsClilParentSelected = [] as string[]
    if (clilLevelParent) {
      allChildWithSameParentAsClilParentSelected = clils
        .filter(
          (clil: ClilDropDownInterface) =>
            clil.parentId === clilLevelParent.parentId,
        )
        .map((clil: ClilDropDownInterface) => clil.id)
    }

    if (
      allChildWithSameParentAsClilSelected.length ===
      uniq([...childWithSameParentAlreadySelected, clilLevel.id]).length
    ) {
      if (
        inputValue &&
        allChildWithSameParentAsClilParentSelected.length ===
          uniq(
            intersection(
              [...inputValue, clilLevelParent?.id],
              [...allChildWithSameParentAsClilParentSelected],
            ),
          ).length
      ) {
        allChildAndAllParentAreChecked(
          clilLevel,
          clilLevelParent,
          inputValue,
          childWithSameParentAlreadySelected,
          allChildWithSameParentAsClilParentSelected,
        )
      } else {
        allChildAreChecked(
          clilLevel,
          inputValue,
          childWithSameParentAlreadySelected,
        )
      }
    } else if (
      inputValue &&
      inputValue.includes(clilLevel?.parentId as string) &&
      !childWithSameParentAlreadySelected.length
    ) {
      childOfParentSelectedIsChecked(
        clilLevel,
        inputValue,
        allChildWithSameParentAsClilSelected,
      )
    } else if (clilLevel.level && clilLevel.level < 3) {
      checkParent(
        clilLevel,
        inputValue,
        allChildWithClilSelectedAsParent,
        allChildWithClilSelectedAsGrandParent,
      )
    } else if (
      inputValue &&
      inputValue.includes(clilLevelParent?.parentId as string)
    ) {
      checkChildWhenGrandParentIsSelected(
        clilLevel,
        clilLevelParent,
        allChildWithSameParentAsClilSelected,
        allChildWithSameParentAsClilParentSelected,
      )
    } else {
      checkOneBox(clilLevel, inputValue)
    }
  }

  return (
    <div className="flex flex-col flex-auto relative">
      <div className="absolute top-0 left-0 h-full w-full">
        <PerfectScrollbar className="h-full flex flex-col gap-1">
          {clils
            .filter(
              (clil: ClilDropDownInterface) => !clil.parentId && clil.show,
            )
            .map((clil1: ClilDropDownInterface) => (
              <Fragment key={`item-clil1-${clil1.id}`}>
                <div
                  className="flex flex-row items-center justify-start w-full rounded gap-1"
                  style={{
                    height: 40,
                    backgroundColor: ['checked', 'indeterminate'].includes(
                      getCheckBoxValueLevel1and2(clil1),
                    )
                      ? `${color}1a`
                      : 'initial',
                    cursor: 'pointer',
                  }}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.preventDefault()
                    handleSelectClilLevel(clil1)
                  }}
                >
                  <div
                    className="flex-none"
                    style={{
                      padding: '12px 8px',
                    }}
                  >
                    <GlCheckBoxInputV2
                      disable={false}
                      color={color}
                      value={getCheckBoxValueLevel1and2(clil1)}
                      onChangeEvent={() => null}
                    />
                  </div>
                  <div className="flex flex-none">
                    <div
                      className="rounded flex items-center justify-center"
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: getClilReference(clil1.id)?.color,
                        transform: 'scale(1.2)',
                      }}
                    >
                      {getClilReference(clil1.id)?.icon}
                    </div>
                  </div>
                  <div className="flex flex-col flex-auto items-start justify-center px-2 py-1">
                    <LabelStyle>{clil1.label}</LabelStyle>
                    <div className="flex flex-row gap-1">
                      <LevelStyle>
                        {t('level')} {clil1.level}
                      </LevelStyle>
                      <ClilNumberStyle>{clil1.id}</ClilNumberStyle>
                    </div>
                  </div>
                  {(clils.some(
                    (clil: ClilDropDownInterface) => clil.parentId === clil1.id,
                  ) && (
                    <ToggleButtonLevelStyle
                      type="button"
                      className="flex flex-row items-center justify-around gap-2 flex-none"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation()
                        setOpenLevels(xor(openLevels, [clil1.id]))
                      }}
                    >
                      <div>{t('level_2')}</div>
                      <BottomArrowIcon
                        style={{
                          width: 12,
                          height: 12,
                          transform: openLevels.includes(clil1.id)
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        }}
                      />
                    </ToggleButtonLevelStyle>
                  )) ||
                    null}
                </div>
                {openLevels.includes(clil1.id) &&
                  clils
                    .filter(
                      (clil: ClilDropDownInterface) =>
                        clil.parentId === clil1.id && clil.show,
                    )
                    .map((clil2: ClilDropDownInterface) => (
                      <Fragment key={`item-clil2-${clil2.id}`}>
                        <div className="flex flex-row items-center justify-center">
                          <div className="flex-none">
                            <SubLevelIcon
                              style={{
                                width: 28,
                                height: 28,
                              }}
                            />
                          </div>
                          <div
                            className="flex flex-row items-center justify-start w-full rounded"
                            style={{
                              height: 40,
                              backgroundColor: [
                                'checked',
                                'indeterminate',
                              ].includes(getCheckBoxValueLevel1and2(clil2))
                                ? `${color}1a`
                                : 'initial',
                              cursor: 'pointer',
                            }}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>,
                            ) => {
                              event.preventDefault()
                              handleSelectClilLevel(clil2)
                            }}
                          >
                            <div
                              className="flex-none"
                              style={{
                                padding: '12px 8px',
                              }}
                            >
                              <GlCheckBoxInputV2
                                disable={false}
                                color={color}
                                value={getCheckBoxValueLevel1and2(clil2)}
                                onChangeEvent={() => null}
                              />
                            </div>

                            <div className="flex flex-col flex-auto">
                              <LabelStyle>{clil2.label}</LabelStyle>
                              <div className="flex flex-row gap-1">
                                <LevelStyle>
                                  {t('level')} {clil2.level}
                                </LevelStyle>
                                <ClilNumberStyle>{clil2.id}</ClilNumberStyle>
                              </div>
                            </div>
                            {(clils.some(
                              (clil: ClilDropDownInterface) =>
                                clil.parentId === clil2.id,
                            ) && (
                              <ToggleButtonLevelStyle
                                type="button"
                                className="flex flex-row items-center justify-around flex-none"
                                onClick={(
                                  event: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                  event.stopPropagation()
                                  setOpenLevels(xor(openLevels, [clil2.id]))
                                }}
                              >
                                <div>{t('level_3')}</div>
                                <BottomArrowIcon
                                  style={{
                                    width: 12,
                                    height: 12,
                                    transform: openLevels.includes(clil2.id)
                                      ? 'rotate(180deg)'
                                      : 'rotate(0deg)',
                                  }}
                                />
                              </ToggleButtonLevelStyle>
                            )) ||
                              null}
                          </div>
                        </div>
                        {openLevels.includes(clil2.id) &&
                          clils
                            .filter(
                              (clil: ClilDropDownInterface) =>
                                clil.parentId === clil2.id && clil.show,
                            )
                            .map((clil3: ClilDropDownInterface) => (
                              <div
                                key={`item-clil3-${clil3.id}`}
                                className="flex flex-row items-center justify-center"
                              >
                                <div
                                  className="flex-none"
                                  style={{
                                    width: 28,
                                    height: 28,
                                  }}
                                />
                                <div className="flex-none">
                                  <SubLevelIcon
                                    style={{
                                      width: 28,
                                      height: 28,
                                    }}
                                  />
                                </div>
                                <div
                                  className="flex flex-row items-center justify-start gap-2 w-full rounded"
                                  style={{
                                    height: 40,
                                    backgroundColor:
                                      getCheckBoxValueLevel3(clil3) ===
                                      'checked'
                                        ? `${color}1a`
                                        : 'initial',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(
                                    event: React.MouseEvent<HTMLDivElement>,
                                  ) => {
                                    event.preventDefault()
                                    handleSelectClilLevel(clil3)
                                  }}
                                >
                                  <div
                                    className="flex-none"
                                    style={{
                                      padding: '12px 8px',
                                    }}
                                  >
                                    <GlCheckBoxInputV2
                                      disable={false}
                                      color={color}
                                      value={getCheckBoxValueLevel3(clil3)}
                                      onChangeEvent={() => null}
                                    />
                                  </div>
                                  <div className="flex flex-col flex-auto">
                                    <LabelStyle>{clil3.label}</LabelStyle>
                                    <div className="flex flex-row gap-1">
                                      <LevelStyle>
                                        {t('level')} {clil3.level}
                                      </LevelStyle>
                                      <ClilNumberStyle>
                                        {clil3.id}
                                      </ClilNumberStyle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </Fragment>
                    ))}
              </Fragment>
            ))}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default ClilMenu
