import { cloneDeep } from 'lodash'
import { WorkMirrorBookInterface } from '../../../../../core/interface/report/work-mirror-book.interface'
import { getPopularityRadiusValue } from '../../../../../core/enums/popularity.enum'
import getClilColor from '../../../../../core/utils/get-clil-color'
import { ConstellationBookDataInterface } from './ConstellationBookData.interface'

const workMirrorConstellationPositionUtil = ({
  center,
  radiusBaseCircle,
  booksOutput,
}: {
  center: { x: number; y: number }
  radiusBaseCircle: number[]
  booksOutput: WorkMirrorBookInterface[]
}): {
  level1: ConstellationBookDataInterface[]
  level2: ConstellationBookDataInterface[]
  level3: ConstellationBookDataInterface[]
  level4: ConstellationBookDataInterface[]
  level5: ConstellationBookDataInterface[]
} => {
  const angleA = (2 * Math.PI) / 3
  const angleDecayA = Math.PI / 2

  const level1: ConstellationBookDataInterface[] = []
  const booksOutputDeep: WorkMirrorBookInterface[] = cloneDeep(booksOutput)

  for (let i = 0; i < 3; i += 1) {
    if (booksOutputDeep[0]) {
      const workMirror = booksOutputDeep[0]
      const angle = i * angleA + angleDecayA
      const x = center.x + radiusBaseCircle[0] * Math.cos(angle)
      const y = center.y + radiusBaseCircle[0] * Math.sin(angle)
      level1.push({
        x,
        y,
        r: getPopularityRadiusValue(workMirror?.popularity),
        angle,
        color: getClilColor(workMirror?.book.clil1),
        workMirror,
        center,
      })
      booksOutputDeep.shift()
    }
  }

  const level2: ConstellationBookDataInterface[] = []

  for (let i = 0; i < 3; i += 1) {
    if (booksOutputDeep[0]) {
      const workMirror = booksOutputDeep[0]
      const angle = i * angleA - angleDecayA
      const x = center.x + radiusBaseCircle[1] * Math.cos(angle)
      const y = center.y + radiusBaseCircle[1] * Math.sin(angle)
      level2.push({
        x,
        y,
        r: getPopularityRadiusValue(workMirror?.popularity),
        angle,
        color: getClilColor(workMirror?.book.clil1),
        workMirror,
        center,
      })
      booksOutputDeep.shift()
    }
  }

  const level3: ConstellationBookDataInterface[] = []

  const angleB = (2 * Math.PI) / 6
  for (let i = 0; i < 6; i += 1) {
    if (booksOutputDeep[0]) {
      const workMirror = booksOutputDeep[0]
      const angle = i * angleB
      const x = center.x + radiusBaseCircle[2] * Math.cos(angle)
      const y = center.y + radiusBaseCircle[2] * Math.sin(angle)
      level3.push({
        x,
        y,
        r: getPopularityRadiusValue(workMirror?.popularity),
        angle,
        color: getClilColor(workMirror?.book.clil1),
        workMirror,
        center,
      })
      booksOutputDeep.shift()
    }
  }

  const level4: ConstellationBookDataInterface[] = []
  for (let i = 0; i < 6; i += 1) {
    if (booksOutputDeep[0]) {
      const workMirror = booksOutputDeep[0]
      const angle = i * angleB + angleDecayA
      const x = center.x + radiusBaseCircle[3] * Math.cos(angle)
      const y = center.y + radiusBaseCircle[3] * Math.sin(angle)
      level4.push({
        x,
        y,
        r: getPopularityRadiusValue(workMirror?.popularity),
        angle,
        color: getClilColor(workMirror?.book.clil1),
        workMirror,
        center,
      })
      booksOutputDeep.shift()
    }
  }

  const level5: ConstellationBookDataInterface[] = []
  const angleC = Math.PI / 6
  for (let i = 0; i < 12; i += 1) {
    if (booksOutputDeep[0]) {
      const workMirror = booksOutputDeep[0]
      const angle = i * angleC + angleDecayA
      const x = center.x + radiusBaseCircle[4] * Math.cos(angle)
      const y = center.y + radiusBaseCircle[4] * Math.sin(angle)
      level4.push({
        x,
        y,
        r: getPopularityRadiusValue(workMirror?.popularity),
        angle,
        color: getClilColor(workMirror?.book.clil1),
        workMirror,
        center,
      })
      booksOutputDeep.shift()
    }
  }

  level1.forEach((l1: ConstellationBookDataInterface) => {
    l1.edges = [
      {
        x: center.x,
        y: center.y,
        r: 50,
      },
    ]
    if (l1?.workMirror?.edges?.length) {
      l1.workMirror.edges.forEach((value: number) => {
        const levelSelect =
          (level1.some(
            (l1a: ConstellationBookDataInterface) =>
              l1a.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level2.some(
            (l2: ConstellationBookDataInterface) =>
              l2.workMirror?.id_ean === value,
          ) &&
            level2) ||
          (level3.some(
            (l3: ConstellationBookDataInterface) =>
              l3.workMirror?.id_ean === value,
          ) &&
            level3) ||
          (level4.some(
            (l4: ConstellationBookDataInterface) =>
              l4.workMirror?.id_ean === value,
          ) &&
            level4) ||
          (level5.some(
            (l5: ConstellationBookDataInterface) =>
              l5.workMirror?.id_ean === value,
          ) &&
            level5) ||
          null
        if (levelSelect) {
          const node = levelSelect.find(
            (levelS: ConstellationBookDataInterface) =>
              levelS.workMirror?.id_ean === value,
          )
          l1.edges?.push({ x: node?.x, y: node?.y, r: node?.r })
        }
      })
    }
  })

  level2.forEach((l2: ConstellationBookDataInterface) => {
    l2.edges = [
      {
        x: center.x,
        y: center.y,
        r: 50,
      },
    ]
    if (l2?.workMirror?.edges?.length) {
      l2.workMirror.edges.forEach((value: number) => {
        const levelSelect =
          (level1.some(
            (l1: ConstellationBookDataInterface) =>
              l1.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level2.some(
            (l2a: ConstellationBookDataInterface) =>
              l2a.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level3.some(
            (l3: ConstellationBookDataInterface) =>
              l3.workMirror?.id_ean === value,
          ) &&
            level3) ||
          (level4.some(
            (l4: ConstellationBookDataInterface) =>
              l4.workMirror?.id_ean === value,
          ) &&
            level4) ||
          (level5.some(
            (l5: ConstellationBookDataInterface) =>
              l5.workMirror?.id_ean === value,
          ) &&
            level5) ||
          null
        if (levelSelect) {
          const node = levelSelect.find(
            (levelS: ConstellationBookDataInterface) =>
              levelS.workMirror?.id_ean === value,
          )
          l2.edges?.push({ x: node?.x, y: node?.y, r: node?.r })
        }
      })
    }
  })

  level3.forEach((l3: ConstellationBookDataInterface) => {
    l3.edges = [
      {
        x: center.x,
        y: center.y,
        r: 50,
      },
    ]
    if (l3?.workMirror?.edges?.length) {
      l3?.workMirror?.edges.forEach((value: number) => {
        const levelSelect =
          (level1.some(
            (l1: ConstellationBookDataInterface) =>
              l1.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level2.some(
            (l2: ConstellationBookDataInterface) =>
              l2.workMirror?.id_ean === value,
          ) &&
            level2) ||
          (level3.some(
            (l3a: ConstellationBookDataInterface) =>
              l3a.workMirror?.id_ean === value,
          ) &&
            level3) ||
          (level4.some(
            (l4: ConstellationBookDataInterface) =>
              l4.workMirror?.id_ean === value,
          ) &&
            level4) ||
          (level5.some(
            (l5: ConstellationBookDataInterface) =>
              l5.workMirror?.id_ean === value,
          ) &&
            level5) ||
          null
        if (levelSelect) {
          const node = levelSelect.find(
            (levelS: ConstellationBookDataInterface) =>
              levelS.workMirror?.id_ean === value,
          )
          l3.edges?.push({ x: node?.x, y: node?.y, r: node?.r })
        }
      })
    }
  })

  level4.forEach((l4: ConstellationBookDataInterface) => {
    l4.edges = [
      {
        x: center.x,
        y: center.y,
        r: 50,
      },
    ]
    if (l4?.workMirror?.edges?.length) {
      l4.workMirror.edges.forEach((value: number) => {
        const levelSelect =
          (level1.some(
            (l1: ConstellationBookDataInterface) =>
              l1.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level2.some(
            (l2: ConstellationBookDataInterface) =>
              l2.workMirror?.id_ean === value,
          ) &&
            level2) ||
          (level3.some(
            (l3: ConstellationBookDataInterface) =>
              l3.workMirror?.id_ean === value,
          ) &&
            level3) ||
          (level4.some(
            (l4a: ConstellationBookDataInterface) =>
              l4a.workMirror?.id_ean === value,
          ) &&
            level4) ||
          (level5.some(
            (l5: ConstellationBookDataInterface) =>
              l5.workMirror?.id_ean === value,
          ) &&
            level5) ||
          null
        if (levelSelect) {
          const node = levelSelect.find(
            (levelS: ConstellationBookDataInterface) =>
              levelS.workMirror?.id_ean === value,
          )
          l4.edges?.push({ x: node?.x, y: node?.y, r: node?.r })
        }
      })
    }
  })

  level5.forEach((l5: ConstellationBookDataInterface) => {
    l5.edges = [
      {
        x: center.x,
        y: center.y,
        r: 50,
      },
    ]
    if (l5?.workMirror?.edges?.length) {
      l5.workMirror.edges.forEach((value: number) => {
        const levelSelect =
          (level1.some(
            (l1: ConstellationBookDataInterface) =>
              l1.workMirror?.id_ean === value,
          ) &&
            level1) ||
          (level2.some(
            (l2: ConstellationBookDataInterface) =>
              l2.workMirror?.id_ean === value,
          ) &&
            level2) ||
          (level3.some(
            (l3: ConstellationBookDataInterface) =>
              l3.workMirror?.id_ean === value,
          ) &&
            level3) ||
          (level4.some(
            (l4: ConstellationBookDataInterface) =>
              l4.workMirror?.id_ean === value,
          ) &&
            level4) ||
          (level5.some(
            (l5a: ConstellationBookDataInterface) =>
              l5a.workMirror?.id_ean === value,
          ) &&
            level5) ||
          null
        if (levelSelect) {
          const node = levelSelect.find(
            (levelS: ConstellationBookDataInterface) =>
              levelS.workMirror?.id_ean === value,
          )
          l5.edges?.push({ x: node?.x, y: node?.y, r: node?.r })
        }
      })
    }
  })

  return { level1, level2, level3, level4, level5 }
}

export default workMirrorConstellationPositionUtil
