import React, { useState } from 'react'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GroupIcon } from '../../../../assets/icons/app/group.icon.svg'
import { GroupItemsDynamicsConfig } from '../../../../core/config/dynamics/group-items-dynamics.config'
import { TrendsItemFilterActionInterface } from '../../../../core/interface/dynamics/trends-item-filter-action.interface'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import useClickOutsideHook from '../../../../core/hooks/useClickOutsideOld.hook'

const HasCursorStyle = styled.div`
  height: 8px;
  width: 8px;
  background: white;
  border-radius: 6px;
  border: 2px solid #252525;
  position: absolute;
  top: -4px;
  right: -4px;
`

const ButtonItemStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`
const DynamicsResultsGroupButton = ({
  groupSelected,
  handleGroupSelected,
}: {
  groupSelected: TrendsItemFilterActionInterface | null
  handleGroupSelected: (value: TrendsItemFilterActionInterface) => void
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const handleClose = () => setOpen(false)
  const ref = useClickOutsideHook(handleClose)

  return (
    <div className="relative" ref={ref}>
      <button
        type="button"
        className={`
          border
          border-[#595959]
          w-8
          h-8 
          flex
          items-center
          justify-center
          rounded
          ${(open && 'bg-white text-[#595959]') || 'bg-[#595959] text-white'}
        `}
        onClick={() => setOpen(!open)}
      >
        <GroupIcon className="w-[18px] h-[18px]" />
        {groupSelected && <HasCursorStyle />}
      </button>
      {open && (
        <div className="absolute top-10 right-0 bg-white z-40 rounded w-[140px] shadow-2xl">
          {GroupItemsDynamicsConfig.map(
            (item: TrendsItemFilterActionInterface, index: number) => (
              <ButtonItemStyle
                key={nanoid(5)}
                type="button"
                className={`
                  flex
                  flex-row
                  justify-between
                  items-center
                  gap-2
                  px-3
                  py-[4.5px]
                  w-full
                  active:bg-[#e3e3e3]
                  ${(groupSelected?.key === item.key && 'bg-[#e3e3e3]') || ''}
                  hover:bg-[#f7f7f7]
                  ${(index === 0 && 'rounded-t-[4px]') || ''}
                  ${
                    (index + 1 === GroupItemsDynamicsConfig.length &&
                      'rounded-b-[4px]') ||
                    ''
                  }
                `}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  handleGroupSelected(item)
                }}
              >
                <div className="flex flex-row justify-between items-center gap-2">
                  <span>{item.icon}</span>
                  <span> {t(item.name)}</span>
                </div>
                {groupSelected?.key === item.key && <SelectedIcon />}
              </ButtonItemStyle>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export default DynamicsResultsGroupButton
