import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GlMatInput from '../../components/share/inputs/GlMatInput'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import GlHDivider from '../../components/share/GlHDivider'
import {
  selectForgotPasswordStateError,
  selectForgotPasswordStateIsLoading,
  selectForgotPasswordStateSuccess,
} from '../../store/getters/forgot-password.getters'
import { forgotPasswordAction } from '../../store/actions/forgot-password.actions'
import GlMdRoundedButton from '../../components/share/buttons/GlMdRoundedButton'
import { isValidEmailUtil } from '../../core/utils/is-valid-email.util'
import { reinitializeForgotPasswordState } from '../../store/reducers/forgot-password.reducer'

const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
`

const LinkStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;

  color: #000000;

  &:hover {
    color: #252525;
  }
  & a {
    text-decoration-line: underline;
  }
`

const ForgotPasswordView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [email, setEmail] = useState<string>('')
  const isLoading = useAppSelector<boolean>(selectForgotPasswordStateIsLoading)
  const forgotPasswordSuccess = useAppSelector<boolean>(
    selectForgotPasswordStateSuccess,
  )
  const forgotPasswordError = useAppSelector<boolean>(
    selectForgotPasswordStateError,
  )

  const handleOnSubmit = () => {
    void dispatch(forgotPasswordAction({ email }))
  }

  const handleOnRedirect = () => {
    navigate(buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN]))
  }

  useEffect(() => {
    return () => {
      dispatch(reinitializeForgotPasswordState())
    }
  }, [])

  return (
    <>
      {!forgotPasswordSuccess && !forgotPasswordError && (
        <form className="z-10 w-[516px]" onSubmit={handleOnSubmit}>
          <div className="z-45 flex flex-col bg-white items-center justify-center p-4">
            <TitleContainer>{t('webApp.forgotPassword.title')}</TitleContainer>
            <div className="mt-4 mb-2 w-full">
              <GlHDivider />
            </div>

            <GlMatInput
              hasError={!email || !isValidEmailUtil(email)}
              hasErrorMessage={
                (!email && t('commons.errorMessageCode.fieldRequired')) ||
                (!isValidEmailUtil(email) &&
                  t('commons.errorMessageCode.emailNotValid')) ||
                ''
              }
              label={t('webApp.signIn.email.label')}
              placeholder={t('webApp.signIn.email.placeholder')}
              keyValue="email"
              type="email"
              value={email}
              onChangeValue={setEmail}
            />

            <div className="mt-6 mb-2 w-full">
              <GlHDivider />
            </div>

            <div className="gap-4 flex flex-col p-4">
              <Link
                className="text-center"
                to={buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN])}
              >
                <LinkStyle>{t('webApp.forgotPassword.signIn')}</LinkStyle>
              </Link>

              <GlMdRoundedButton
                label={t('commons.buttons.send')}
                hasLoader
                isLoading={isLoading}
                disabled={!email}
                handleOnClick={handleOnSubmit}
              />
            </div>
          </div>
        </form>
      )}
      {forgotPasswordSuccess && (
        <div className="flex flex-col bg-white items-center justify-center p-4">
          <TitleContainer
            dangerouslySetInnerHTML={{
              __html: `${t('webApp.forgotPassword.titleSuccess')}`,
            }}
          />
          <div className="mt-4 mb-2 w-full">
            <GlHDivider />
          </div>
          <div className="gap-4 flex flex-col p-4 items-start">
            <div>{t('webApp.forgotPassword.messageSuccess1')}</div>
            <div
              style={{
                fontWeight: 'bold',
              }}
            >
              {email}
            </div>
            <div>{t('webApp.forgotPassword.messageSuccess2')}</div>
          </div>
          <div className="mt-4 mb-2 w-full">
            <GlHDivider />
          </div>
          <div className="gap-4 flex flex-col p-4 items-center">
            <LinkStyle
              dangerouslySetInnerHTML={{
                __html: `${t('webApp.forgotPassword.placeHolderSuccess')}`,
              }}
            />
            <div>
              <GlMdRoundedButton
                label={t('webApp.forgotPassword.signIn')}
                hasLoader
                isLoading={isLoading}
                disabled={!email}
                handleOnClick={handleOnRedirect}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ForgotPasswordView
