import { createSlice } from '@reduxjs/toolkit'
import { ForgotPasswordStateInterface } from '../interfaces/forgot-password.state.interface'
import { forgotPasswordAction } from '../actions/forgot-password.actions'

const initialState: ForgotPasswordStateInterface = {
  isLoading: false,
  success: false,
  error: false,
}

export const forgotPasswordSlice = createSlice({
  name: 'forgotPasswordState',
  initialState,
  reducers: {
    reinitializeForgotPasswordState: (
      state: ForgotPasswordStateInterface,
    ): ForgotPasswordStateInterface => ({
      ...state,
      success: false,
      error: false,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        forgotPasswordAction.pending,
        (state: ForgotPasswordStateInterface) => ({
          ...state,
          isLoading: true,
          success: false,
          error: false,
        }),
      )
      .addCase(
        forgotPasswordAction.fulfilled,
        (state: ForgotPasswordStateInterface) => ({
          ...state,
          isLoading: false,
          success: true,
          error: false,
        }),
      )
      .addCase(
        forgotPasswordAction.rejected,
        (state: ForgotPasswordStateInterface) => ({
          ...state,
          isLoading: false,
          success: false,
          error: true,
        }),
      )
  },
})

export const { reinitializeForgotPasswordState } = forgotPasswordSlice.actions
export const forgotPasswordReducer = forgotPasswordSlice.reducer
