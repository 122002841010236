import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const LegalTextStyle = styled.div`
  color: var(--Grayscale-Gray-3, #666);
  flex: 1 0 0;
  /* Caption/C2 - Regular */
  font-family: 'Fira Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
`

const GlLegalText = () => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center justify-center gap-8 ">
      <LegalTextStyle>{t('legalText.1')}</LegalTextStyle>
      <LegalTextStyle>{t('legalText.2')}</LegalTextStyle>
    </div>
  )
}

export default GlLegalText
