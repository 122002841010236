import { ReactElement } from 'react'

interface Props {
  button?: ReactElement | null
  children?: Array<ReactElement> | ReactElement | null
  opened: boolean
}

const GlMenu = ({ opened, button, children }: Props) => {
  return (
    <div
      className="flex flex-col relative"
      style={{
        zIndex: 200,
      }}
    >
      {button}
      {opened && (
        <div className="right-0 shadow-lg rounded-0.5 flex flex-col bg-header absolute top-10 w-[167px] rounded">
          {children}
        </div>
      )}
    </div>
  )
}

export default GlMenu
