import { ApiPathConfig } from '../../../config/ApiPathConfig'
import apiInstance from '../../backend-api.setup'
import { ReportInterface } from '../../../interface/report/report.interface'

export const deleteReportByIdService = (reportId: string): Promise<any> =>
  apiInstance.delete(ApiPathConfig.REPORT_DETAIL_BY_ID(reportId))

export const getReportByIdService = (
  reportId: string,
): Promise<{ data: ReportInterface }> => {
  try {
    return apiInstance.get(ApiPathConfig.REPORT_DETAIL_BY_ID(reportId))
  } catch (err) {
    throw new Error('REPORT_GET')
  }
}

export const postReportService = (report: any): Promise<any> => {
  try {
    return apiInstance.post(ApiPathConfig.REPORTS_NEW, report)
  } catch (err) {
    throw new Error('REPORT_POST')
  }
}

export const patchReportService = (
  reportId: string,
  report: Partial<ReportInterface>,
): Promise<any> => {
  try {
    return apiInstance.patch(
      ApiPathConfig.REPORT_DETAIL_BY_ID(reportId),
      report,
    )
  } catch (err) {
    throw new Error('REPORT_POST')
  }
}
