import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import CustomTooltipMulti from './chartComponents/tooltip/CustomTooltipMulti'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { getColors } from './getColors'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'
import { firstCharToUpperCaseHelper } from '../../../../../../core/helpers/first-char-to-upper-case.helper'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'

interface VerticalClilAxisProps {
  x: number
  y: number
  width: number
  height: number
  datasetLength: number
  payload: { value: string }
}

const VerticalClilAxis: (props: any) => ReactElement = ({
  x,
  y,
  width,
  height,
  datasetLength,
  payload,
}: VerticalClilAxisProps) => {
  return (
    <foreignObject
      x={x - width / (datasetLength * 2)}
      y={y}
      height={height}
      width={width / datasetLength}
    >
      <div
        className="text-center text-xs leading-3"
        style={{
          width: `${width / datasetLength}px`,
        }}
      >
        {firstCharToUpperCaseHelper(
          clilReferencesConfig[payload.value || '0000'].libelle.toLowerCase(),
        )}
      </div>
    </foreignObject>
  )
}

const AnalyticsOutputVerticalReCharts = ({
  valuesConfigurations,
  display,
  title,
  subTitle,
  setColor,
  dataset,
  datasetModule,
  typeDataset,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  isOrganizeMenuOpen,
  outputIdToScroll,
  onClick,
  level,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  display: AnalyticOutputLayoutSizeEnum
  title: string
  subTitle?: string
  setColor: SetColorEnum
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  typeDataset: DatasetTypeEnum
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  isOrganizeMenuOpen?: boolean
  outputIdToScroll?: string
  onClick?: (clil: number, lvl: number) => void
  level?: number
}) => {
  const { t } = useTranslation()

  const primaryValueConfiguration = valuesConfigurations.filter(
    (p) => p.is_primary_key,
  )[0]
  const isClil = typeDataset === DatasetTypeEnum.CLIL
  const isClickable = isClil && onClick && level && level < 3 && !isPreview

  const CustomTooltip = useCallback((props: any) => {
    return (
      <CustomTooltipMulti
        {...props}
        typeDataset={typeDataset}
        isClickable={isClickable}
      />
    )
  }, [])

  const ClilAxis = useCallback(
    (props: any) => {
      return <VerticalClilAxis datasetLength={dataset.length} {...props} />
    },
    [dataset],
  )

  const colorMapping = getColors(setColor)

  const onClickClil = (payload: CategoricalChartState) => {
    const { activePayload } = payload
    if (isClickable && activePayload) {
      onClick(activePayload[0].payload.clil, level)
    }
  }

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={
        primaryValueConfiguration.source === SourceEnum.AGE
          ? [t('InfoBulles.distributionByAgeVertical')]
          : [t('InfoBulles.distributionByLibraries')]
      }
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.VERTICAL_BAR}
      shrink={isOrganizeMenuOpen}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          style={{ cursor: isClickable ? 'pointer' : 'default' }}
          data={dataset}
          barCategoryGap={10}
          margin={{
            bottom:
              isOrganizeMenuOpen && outputIdToScroll !== datasetModule.id
                ? (isClil && 35) || 25
                : (isClil && 10) || 0,
          }}
          onClick={onClickClil}
        >
          <Tooltip content={CustomTooltip} />
          <XAxis
            height={
              primaryValueConfiguration.source === SourceEnum.CLIL ? 25 : 20
            }
            dataKey={primaryValueConfiguration.source}
            axisLine={false}
            tickLine={false}
            tick={
              primaryValueConfiguration.source === SourceEnum.CLIL
                ? ClilAxis
                : { fill: '#3B3B3B', fontSize: 12 }
            }
            tickFormatter={(value: string) => {
              if (
                primaryValueConfiguration.source ===
                  SourceEnum.BOOK_READ_COUNT &&
                value === '1251+'
              ) {
                return `${value} livres`
              }
              if (
                primaryValueConfiguration.source === SourceEnum.AGE &&
                value === '60+'
              ) {
                return `${value} ans`
              }
              return value
            }}
          />
          <YAxis
            width={30}
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#3B3B3B', fontSize: 12 }}
            domain={[0, 100]}
            ticks={[0, 100]}
          />
          {valuesConfigurations
            .filter((c) => !c.is_primary_key && c.show_in_chart)
            .map((valueConfiguration) => {
              return (
                <Bar
                  key={valueConfiguration.source}
                  dataKey={valueConfiguration.source}
                  fill={colorMapping[valueConfiguration.source]}
                  background={{
                    fill: `${colorMapping[valueConfiguration.source]}1a`,
                  }}
                />
              )
            })}
        </BarChart>
      </ResponsiveContainer>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputVerticalReCharts
