import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GlMatInput from '../../components/share/inputs/GlMatInput'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import GlLoader from '../../components/share/GlLoader'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import GlHDivider from '../../components/share/GlHDivider'
import GlMdRoundedButton from '../../components/share/buttons/GlMdRoundedButton'
import {
  selectChangePasswordStateIsLoadingValidateToken,
  selectChangePasswordStateSuccessValidateToken,
  selectChangePasswordStateErrorValidateToken,
  selectChangePasswordStateIsLoadingChangePassword,
  selectChangePasswordStateSuccessChangePassword,
  selectChangePasswordStateErrorChangePassword,
} from '../../store/getters/change-password.getters'
import {
  changePasswordPostPasswordResetByValidateTokenAction,
  changePasswordPostPasswordResetConfirmAction,
} from '../../store/actions/change-password.actions'
import GlPrefixIconButton from '../../components/share/buttons/GlPrefixIconButton'
import { ReactComponent as VisibilityIcon } from '../../assets/icons/app/visibility.icon.svg'
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/app/visibility-off.icon.svg'
import {
  getErrorMessagePasswordIsNotValid,
  passwordIsValidByRulesUtil,
  passwordIsValidUtil,
} from '../../core/utils/password.utils'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { reinitializeChangePasswordState } from '../../store/reducers/change-password.reducer'

const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #252525;
`

const HintStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #b2b2b2;
  margin: 16px 0;
`

const LinkStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;

  &:hover {
    color: #252525;
  }
`

const ChangePasswordView = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [passwordChange, setPasswordChange] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const [passwordRepeatChange, setPasswordRepeatChange] =
    useState<boolean>(false)
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')

  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false)
  const [passwordRepeatVisibility, setPasswordRepeatVisibility] =
    useState<boolean>(false)

  const isLoadingValidateToken = useAppSelector<boolean>(
    selectChangePasswordStateIsLoadingValidateToken,
  )
  const successValidateToken = useAppSelector<boolean>(
    selectChangePasswordStateSuccessValidateToken,
  )
  const errorValidateToken = useAppSelector<boolean>(
    selectChangePasswordStateErrorValidateToken,
  )
  const isLoadingChangePassword = useAppSelector<boolean>(
    selectChangePasswordStateIsLoadingChangePassword,
  )
  const successChangePassword = useAppSelector<boolean>(
    selectChangePasswordStateSuccessChangePassword,
  )
  const errorChangePassword = useAppSelector<boolean>(
    selectChangePasswordStateErrorChangePassword,
  )

  const { changePasswordToken } = useParams<{
    changePasswordToken: string
  }>()

  useEffect(() => {
    void dispatch(
      changePasswordPostPasswordResetByValidateTokenAction({
        token: changePasswordToken as string,
      }),
    )
    return () => {
      dispatch(reinitializeChangePasswordState())
    }
  }, [changePasswordToken])

  useEffect(() => {
    if (successChangePassword) {
      dispatch(setShowSnackBar(t('snackbarMessage.passwordIsChange')))
    }
    if (errorChangePassword) {
      dispatch(setShowSnackBar(t('snackbarMessage.checkPasswordError2')))
    }
  }, [successChangePassword, errorChangePassword])

  const isValidForm = (): boolean => {
    return !!password && !!passwordRepeat && password === passwordRepeat
  }

  const handleOnSubmit = () => {
    void dispatch(
      changePasswordPostPasswordResetConfirmAction({
        token: changePasswordToken as string,
        password,
      }),
    )
  }

  const handleOnRedirect = () => {
    navigate(buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN]))
  }

  return (
    (isLoadingValidateToken && (
      <div className="z-10 w-[516px] flex flex-col bg-white items-center justify-center p-4">
        <GlLoader />
      </div>
    )) ||
    (successValidateToken && !successChangePassword && (
      <form className="z-10 w-[516px]" onSubmit={handleOnSubmit}>
        <div className="flex flex-col bg-white items-center justify-center p-4">
          <TitleContainer>{t('webApp.forgotPassword.title')}</TitleContainer>
          <div className="mt-4 mb-2 w-full">
            <GlHDivider />
          </div>

          <GlMatInput
            hasError={
              passwordChange &&
              (!password ||
                !passwordIsValidUtil(passwordIsValidByRulesUtil(password)))
            }
            hasErrorMessage={
              (!password && t('commons.errorMessageCode.fieldRequired')) ||
              getErrorMessagePasswordIsNotValid(
                passwordIsValidByRulesUtil(password),
              )
            }
            label={t('commons.inputs.password.label')}
            placeholder={t('commons.inputs.password.placeholder')}
            keyValue="new-password"
            type={(passwordVisibility && 'text') || 'password'}
            value={password}
            onChangeValue={(value: string) => {
              setPasswordChange(true)
              setPassword(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            }
          />

          <GlMatInput
            hasError={
              passwordRepeatChange &&
              (!passwordRepeat ||
                (!!password && !!passwordRepeat && password !== passwordRepeat))
            }
            hasErrorMessage={
              (!passwordRepeat &&
                t('commons.errorMessageCode.fieldRequired')) ||
              t('commons.errorMessageCode.passwordIsNotSame')
            }
            label={t('commons.inputs.confirmPassword.label')}
            placeholder={t('commons.inputs.confirmPassword.placeholder')}
            keyValue="new-password-repeat"
            type={(passwordRepeatVisibility && 'text') || 'password'}
            value={passwordRepeat}
            onChangeValue={(value: string) => {
              setPasswordRepeatChange(true)
              setPasswordRepeat(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordRepeatVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() =>
                  setPasswordRepeatVisibility(!passwordRepeatVisibility)
                }
              />
            }
          />
          <HintStyle>{t('authentication.invitation.passwordRules')}</HintStyle>

          <div className="mt-6 mb-2 w-full">
            <GlHDivider />
          </div>

          <div className="gap-4 flex flex-col p-4">
            <Link
              className="text-center"
              to={buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN])}
            >
              <LinkStyle>{t('webApp.forgotPassword.signIn')}</LinkStyle>
            </Link>

            <GlMdRoundedButton
              label={t('commons.buttons.send')}
              hasLoader
              disabled={
                !isValidForm() ||
                !passwordIsValidUtil(passwordIsValidByRulesUtil(password))
              }
              isLoading={isLoadingChangePassword}
              handleOnClick={handleOnSubmit}
            />
          </div>
        </div>
      </form>
    )) ||
    (errorValidateToken && (
      <div className="flex flex-col bg-white items-center justify-center p-4">
        <TitleContainer>
          {t('authentication.changePassword.linkExpired')}
        </TitleContainer>
        <div className="mt-4 mb-2 w-full">
          <GlHDivider />
        </div>
        <div className="gap-4 flex flex-col p-4 items-start">
          <p>{t('authentication.changePassword.renewRequest')}</p>
          <p>
            <span>{t('authentication.changePassword.ifProblemMessage')}</span>
            <a href="mailto:info@gleeph.pro">info@gleeph.pro</a>
          </p>
        </div>
        <div className="mt-4 mb-2 w-full">
          <GlHDivider />
        </div>
        <div className="gap-4 flex flex-col">
          <div className="flex flex-row justify-center items-center gap-2">
            <Link
              className="text-center"
              to={buildRelativePath([PathConfig.AUTH, PathConfig.SIGN_IN])}
            >
              <LinkStyle>{t('webApp.forgotPassword.signIn')}</LinkStyle>
            </Link>
            <span>/</span>
            <Link
              className="text-center"
              to={buildRelativePath([
                PathConfig.AUTH,
                PathConfig.FORGOT_PASSWORD,
              ])}
            >
              <LinkStyle>{t('webApp.signIn.forgotPassword')}</LinkStyle>
            </Link>
          </div>
          <div className="flex items-center justify-center">
            <GlMdRoundedButton
              label={t('commons.buttons.contactUs')}
              handleOnClick={() => {
                window.location.href = 'mailto:info@gleeph.pro'
              }}
            />
          </div>
        </div>
      </div>
    )) ||
    (successChangePassword && (
      <div className="flex flex-col bg-white items-center justify-center p-4">
        <TitleContainer>
          {t('webApp.changePassword.titleSuccess')}
        </TitleContainer>
        <div className="mt-4 mb-2 w-full">
          <GlHDivider />
        </div>
        <div className="gap-4 flex flex-col p-4 items-start">
          <div>{t('webApp.changePassword.messageSuccess1')}</div>
          <div>{t('webApp.changePassword.messageSuccess2')}</div>
        </div>
        <div className="mt-4 mb-2 w-full">
          <GlHDivider />
        </div>
        <div className="gap-4 flex flex-col p-4 items-center">
          <div>
            <GlMdRoundedButton
              label={t('commons.buttons.ok')}
              hasLoader
              handleOnClick={handleOnRedirect}
            />
          </div>
        </div>
      </div>
    )) ||
    null
  )
}

export default ChangePasswordView
