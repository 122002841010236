import React from 'react'
import styled from '@emotion/styled'
import DynamicsBillRank from './DynamicsBillRank'
import DynamicsBillDetails from './DynamicsBillDetails'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'
import { clilReferencesConfig } from '../../../../../core/config/clil-references.config'
import GlImageBook from '../../../../share/images/GlImageBook'

const LineStyle = styled.div`
  background-color: ${({ bookSelected }: { bookSelected: boolean }) =>
    bookSelected ? '#e3e3e3' : 'white'};

  &:hover {
    background-color: #f7f7f7;
  }

  &:active {
    background-color: #e3e3e3;
  }
`

interface Props {
  trend: TrendV2Interface
  firstElement: boolean
  handleOnClickLine: (book: TrendV2Interface | null) => void
  bookSelected: TrendV2Interface | null
}
const DynamicsBill = ({
  trend,
  handleOnClickLine,
  firstElement,
  bookSelected,
}: Props) => {
  const {
    rank,
    delta_rank: deltaRank,
    title,
    id_ean: eanId,
    imgs,
    authors,
    publishing_date: publishingDate,
    clil1,
    clil2,
    edition,
    format,
  } = trend

  const { color } =
    (clil1 && clilReferencesConfig[clil1]) || clilReferencesConfig['0000']

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    handleOnClickLine(trend)
  }

  return (
    <LineStyle
      onClick={onClick}
      className="flex flex-row border-b border-[#e3e3e3] box-content cursor-pointer"
      style={{
        height: firstElement ? 160 : 96,
      }}
      bookSelected={bookSelected?.id_ean === eanId}
    >
      <DynamicsBillRank rank={rank} delta_rank={deltaRank} />
      <GlImageBook
        pathUrl={imgs}
        height={firstElement ? 160 : 96}
        width={firstElement ? 116 : 69}
        color={color}
      />
      <DynamicsBillDetails
        title={title}
        eanId={eanId}
        authors={authors}
        publishingDate={publishingDate}
        clil1={clil1}
        clil2={clil2}
        edition={edition}
        format={format}
        firstElement={firstElement}
      />
    </LineStyle>
  )
}

export default DynamicsBill
