import { createSlice } from '@reduxjs/toolkit'
import { ChangePasswordStateInterface } from '../interfaces/change-password.state.interface'
import {
  changePasswordPostPasswordResetByValidateTokenAction,
  changePasswordPostPasswordResetConfirmAction,
} from '../actions/change-password.actions'

const initialState: ChangePasswordStateInterface = {
  isLoadingValidateToken: false,
  successValidateToken: false,
  errorValidateToken: false,
  isLoadingChangePassword: false,
  successChangePassword: false,
  errorChangePassword: false,
}

export const changePasswordSlice = createSlice({
  name: 'changePasswordState',
  initialState,
  reducers: {
    reinitializeChangePasswordState: (
      state: ChangePasswordStateInterface,
    ): ChangePasswordStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        changePasswordPostPasswordResetByValidateTokenAction.pending,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingValidateToken: true,
          successValidateToken: false,
          errorValidateToken: false,
        }),
      )
      .addCase(
        changePasswordPostPasswordResetByValidateTokenAction.fulfilled,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingValidateToken: false,
          successValidateToken: true,
          errorValidateToken: false,
        }),
      )
      .addCase(
        changePasswordPostPasswordResetByValidateTokenAction.rejected,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingValidateToken: false,
          successValidateToken: false,
          errorValidateToken: true,
        }),
      )
      .addCase(
        changePasswordPostPasswordResetConfirmAction.pending,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingChangePassword: true,
          successChangePassword: false,
          errorChangePassword: false,
        }),
      )
      .addCase(
        changePasswordPostPasswordResetConfirmAction.fulfilled,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingChangePassword: false,
          successChangePassword: true,
          errorChangePassword: false,
        }),
      )
      .addCase(
        changePasswordPostPasswordResetConfirmAction.rejected,
        (state: ChangePasswordStateInterface) => ({
          ...state,
          isLoadingChangePassword: false,
          successChangePassword: false,
          errorChangePassword: true,
        }),
      )
  },
})

export const { reinitializeChangePasswordState } = changePasswordSlice.actions
export const changePasswordReducer = changePasswordSlice.reducer
