import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { TopRequestSavedInterface } from '../../../../interface/rank/top-requests-saved.interface'

export const putTopRequestSavedService = (
  requestId: string,
  request: Partial<TopRequestSavedInterface>,
  cancelToken?: CancelToken,
): Promise<any> =>
  apiInstance.put(
    ApiPathConfig.TOP_REQUESTS_SAVED_BY_ID(requestId),
    { ...request },
    { cancelToken },
  )
