import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { AddModuleDataRepresentationEnum } from '../../../../../../core/enums/add-module-data-representation.enum'
import {
  TextDescriptionStyle,
  TitleHorizontalCardStyles,
} from './AddModuleStyles'

const HorizontalCardStyles = styled.button`
  display: flex;
  width: 256px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #3b3b3b;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  :hover:not(:disabled) {
    background: #000;
  }
  :disabled {
    opacity: 0.5;
  }
`

interface Props {
  item: {
    icon: string
    id: AddModuleDataRepresentationEnum
  }
  handleClickOnEditorialElementCard: (
    id: AddModuleDataRepresentationEnum,
  ) => void
}

const AddModuleEditorialElementsCard = ({
  item,
  handleClickOnEditorialElementCard,
}: Props) => {
  const { t } = useTranslation()

  return (
    <HorizontalCardStyles
      onClick={() => handleClickOnEditorialElementCard(item.id)}
      key={item.id}
    >
      <div className="flex h-[160px] w-full bg-[#3B3B3B] rounded-t-xl justify-center items-center">
        <img
          src={item.icon}
          className="  text-white p-8"
          alt="Icon of Editorial Element"
        />
      </div>
      <div className="flex flex-col self-stretch p-4 gap-2 items-start">
        <TitleHorizontalCardStyles>
          {t(`addModuleMenu.${item.id}.title`)}
        </TitleHorizontalCardStyles>
        <TextDescriptionStyle>
          {t(`addModuleMenu.${item.id}.description`)}
        </TextDescriptionStyle>
      </div>
    </HorizontalCardStyles>
  )
}

export default AddModuleEditorialElementsCard
