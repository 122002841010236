import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AnalyticsProgressStatusInterface } from '../../../../store/interfaces/analytics-details/analytics-progress-status.interface'

export const getReportByIdStatusService = (
  {
    reportId,
  }: {
    reportId: string
  },
  cancelToken?: CancelToken,
): Promise<AxiosResponse<AnalyticsProgressStatusInterface>> =>
  apiInstance.get(ApiPathConfig.REPORT_BY_ID_STATUS(reportId), {
    cancelToken,
  })
