import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CheckIcon } from '../../../assets/icons/app/check.icon.svg'

interface Props {
  isSelected: boolean
  onClick: () => void
  name: string
  nbUsers: number
}

const NewAudienceCatalogItem = ({
  isSelected,
  onClick,
  name,
  nbUsers,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div
      className={`flex flex-row p-2 rounded gap-4 cursor-pointer items-center ${
        isSelected ? 'bg-[#252525]' : 'bg-[#f7f7f7]'
      }`}
      onClick={onClick}
    >
      <div
        className={`flex justify-center items-center w-4 h-4 rounded-sm ml-2 ${
          isSelected ? 'bg-white' : 'border border-[#2525251A]'
        }`}
      >
        {isSelected && <CheckIcon className="w-3 h-3 text-[#252525]" />}
      </div>
      <div className="flex flex-col">
        <span
          className={isSelected ? 'text-white font-medium' : 'text-[#252525]'}
        >
          {t(`createAudience.catalog.${name}`)}
        </span>
        <span
          className={`text-xs ${isSelected ? 'text-white' : 'text-[#929292]'}`}
        >
          {t('createAudience.nbrUser', {
            nb: new Intl.NumberFormat().format(nbUsers),
          })}
        </span>
      </div>
    </div>
  )
}

export default memo(NewAudienceCatalogItem)
