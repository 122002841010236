import styled from '@emotion/styled'

export const TagEmptyStyle = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: #e9e9e9;
  width: 164px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #929292;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const TagStyle = styled.div`
  background-color: ${({ color }: { color: string }) => `${color}1a`};
  color: ${({ color }: { color: string }) => `${color}`};
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  max-width: 258px;
`

export const EllipsisDivStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`
