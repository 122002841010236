import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import {
  TextDescriptionStyle,
  TitleHorizontalCardStyles,
} from './AddModuleStyles'
import { DatasetModuleAvailableModulesInterface } from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import { ConvertDatasetModuleToOption } from '../../../../../../core/utils/convert-datasetmodule-to-option.util'
import { UpdateDatasetModuleByToggle } from '../../../../../../core/utils/update-datasetmodule-by-toggle.util'
import AddModuleOptionsDisplay from './AddModuleOptionsDisplay'
import OutputChartTypeSwitch from '../../outputs/OutputChartTypeSwitch'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { InterfaceRepresentationDataList } from './AddModuleView'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { UpdateDatasetmoduleByDropdown } from '../../../../../../core/utils/update-datasetmodule-by-dropdown.util'
import { SelectOptionInterface } from '../../../../../../core/interface/select-option.interface'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'

const Container = styled.div`
  display: flex;
  padding: 32px 32px 0px 32px;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

const ButtonStyles = styled.button`
  display: flex;
  width: 320px;
  height: 48px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  text-align: center;
  font-family: 'Fira Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

interface LevelInterface {
  [SourceEnum.CLIL]: number | null
  datasets: Array<Array<DatasetInterface>>
}

const AddModuleOptionsView = ({
  setStep,
  handleClickOnModule,
  datasetModuleSelected,
  setDatasetModuleSelected,
  dataRepresentationSelected,
  outputIdOfSelectedDataset,
  replaceModule,
  analyticsSetColor,
}: {
  setStep: (step: AddModuleStepEnum) => void
  handleClickOnModule: (
    datasetModule:
      | DatasetModuleAvailableModulesInterface
      | DatasetModuleInterface,
    outputId: string,
  ) => void
  datasetModuleSelected: DatasetModuleInterface | undefined
  setDatasetModuleSelected: (datasetModule: DatasetModuleInterface) => void
  dataRepresentationSelected: InterfaceRepresentationDataList
  outputIdOfSelectedDataset: string
  replaceModule: boolean
  analyticsSetColor: SetColorEnum
}) => {
  const { t } = useTranslation()
  const [level, setLevel] = useState<Array<LevelInterface>>([])
  const [height, setHeight] = useState<number>(0)

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const datasetModuleOptionsDatas = ConvertDatasetModuleToOption(
    datasetModuleSelected,
  )

  function handleValidate() {
    if (datasetModuleSelected) {
      handleClickOnModule(datasetModuleSelected, outputIdOfSelectedDataset)
    }
  }

  function handleBack() {
    setStep(AddModuleStepEnum.DATA_REPRESENTATION)
  }

  const handleToggleSetOptionToDisplay = (optionId: string, value: boolean) => {
    if (!datasetModuleSelected) return
    const newDatasetModuleSelected = UpdateDatasetModuleByToggle(
      datasetModuleSelected,
      optionId,
      value,
    )
    setDatasetModuleSelected(newDatasetModuleSelected)
  }

  const handleSizeDropdownSetOptionToDisplay = (
    optionId: string,
    value: SelectOptionInterface<AnalyticOutputLayoutSizeEnum>,
  ) => {
    if (!datasetModuleSelected) return
    const newDatasetModuleSelected = UpdateDatasetmoduleByDropdown(
      datasetModuleSelected,
      optionId,
      value.value,
    )
    setDatasetModuleSelected(newDatasetModuleSelected)
  }

  const filterByClil = (
    dataset: Array<DatasetInterface>,
    clil: number | null,
  ) => {
    return dataset
      .filter((item) => item[SourceEnum.PARENT_CLIL] === clil)
      .sort(
        (a, b) =>
          (b[SourceEnum.SUBJECT_OBJECT_VALUE] as number) -
          (a[SourceEnum.SUBJECT_OBJECT_VALUE] as number),
      )
  }

  const dataType = dataRepresentationSelected.data_type as DatasetTypeEnum

  useEffect(() => {
    if (datasetModuleSelected && dataType === DatasetTypeEnum.CLIL) {
      setLevel([
        {
          [SourceEnum.CLIL]: null,
          datasets: [filterByClil(datasetModuleSelected.dataset, null)],
        },
      ])
    }
  }, [datasetModuleSelected?.dataset.length])

  return (
    <>
      <Container>
        <div className="flex items-start gap-8 self-stretch">
          <AddModuleOptionsDisplay
            options={datasetModuleOptionsDatas || []}
            handleOnToggle={handleToggleSetOptionToDisplay}
            handleOnSizeDropdown={handleSizeDropdownSetOptionToDisplay}
          />
          <PreviewContainer>
            {datasetModuleSelected && (
              <div className="relative bg-white w-full overflow-hidden rounded-xl">
                <div
                  className="absolute top-0 w-full h-full pointer-events-none z-10"
                  style={{
                    background:
                      [
                        AnalyticsChartTypeEnum.TABULAR,
                        AnalyticsChartTypeEnum.BUBBLE_CHART,
                        AnalyticsChartTypeEnum.SCATTER_PLOT,
                        AnalyticsChartTypeEnum.CONSTELLATION,
                      ].includes(datasetModuleSelected.type) &&
                      datasetModuleSelected.show_table
                        ? 'linear-gradient(180deg, rgba(50, 50, 50, 0.00)76.97%, #323232 100%)'
                        : '',
                  }}
                />
                <OutputChartTypeSwitch
                  dataType={dataType}
                  dataset={
                    dataType === DatasetTypeEnum.CLIL &&
                    datasetModuleSelected.type !==
                      AnalyticsChartTypeEnum.VERTICAL_STACKED_BAR_CHART &&
                    level[0]
                      ? level[0].datasets[0]
                      : datasetModuleSelected.dataset
                  }
                  datasetModule={datasetModuleSelected}
                  display={AnalyticOutputLayoutSizeEnum.FULL}
                  eanSelected={null}
                  isPreview
                  setColor={analyticsSetColor}
                  isScreenSmall={height < 900}
                />
              </div>
            )}
            <TextDescriptionStyle>
              {t('addModuleMenu.preview')}
            </TextDescriptionStyle>
            <TitleHorizontalCardStyles>
              {t(`addModuleMenu.${datasetModuleSelected?.type}.title`)}
            </TitleHorizontalCardStyles>
          </PreviewContainer>
        </div>
      </Container>
      <div className="fixed bottom-[32px] gap-4 flex justify-center">
        <ButtonStyles
          className="bg-[#252525] text-white hover:bg-[#252525cc]"
          onClick={handleBack}
        >
          {t('commons.buttons.back')}
        </ButtonStyles>
        <ButtonStyles
          className="bg-[#F7F7F7] text-[#252525] hover:bg-[#F7F7F7cc]"
          onClick={handleValidate}
        >
          {replaceModule ? t('addModuleMenu.change') : t('addModuleMenu.add')}
        </ButtonStyles>
      </div>
    </>
  )
}

export default AddModuleOptionsView
