import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const getOnboardingInvitationsService = (
  invitationToken: string,
  cancelToken?: CancelToken,
) =>
  apiInstance.get(
    ApiPathConfig.ONBOARDING_INVITATIONS_BY_TOKEN(invitationToken),
    {
      cancelToken,
    },
  )
