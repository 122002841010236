import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { InputStyle } from '../../ReadershipProfileStyles'
import EditionsByTitleCard from './EditionsByTitleCard'
import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/app/cross.icon.svg'
import { BookSearchInterface } from '../../../../../../core/interface/book-search.interface'
import TitlesMenu from './TitlesMenu'
import useTitleInputAutocompletionHook from './useTitleInputAutocompletionHook'
import { BookInterface } from '../../../../../../core/interface/book.interface'

const TitleInputAutoCompletion = ({
  disable,
  color,
  inputValue,
  handleOnClickItemMenu,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  color: string
  inputValue: BookSearchInterface[] | null
  handleOnClickItemMenu: (
    book: BookSearchInterface[] | null,
    booksToRemove?: BookInterface[],
  ) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  const { t } = useTranslation()
  let timer: NodeJS.Timer | null = null
  const inputValueEan = inputValue?.map((book) => book.id_ean) || []

  const [query, setQuery] = useState<string>('')
  const [needCloseMenu, setNeedCloseMenu] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const {
    state: { isLoadingGetBooksByQuery, books },
  } = useTitleInputAutocompletionHook({ query })

  const onChangeQueryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setQuery(event.target.value)
  }

  const onClickResetButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setQuery('')
  }
  const handleOnClickTitleItem = (book: BookSearchInterface) => {
    handleOnClickItemMenu([...(inputValue || []), book])
    setQuery('')
    setMenuOpen(false)
  }

  const handleFocusToInput = () => {
    setMenuOpen(true)
  }
  const handleBlurToInput = () => {
    setNeedCloseMenu(true)
  }

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setMenuOpen(false)
      setNeedCloseMenu(false)
    }, 300)
  }

  useEffect(() => {
    if (needCloseMenu) {
      handleTimeOut()
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needCloseMenu])

  return (
    <div className="flex-auto flex flex-col w-full gap-4 justify-start">
      <div className="relative flex flex-col">
        <InputStyle
          disabled={disable}
          type="text"
          placeholder={
            inputValue && inputValue.length
              ? t('ContinueAddATitle')
              : t('SearchATitle')
          }
          value={query}
          onChange={onChangeQueryInput}
          onFocus={handleFocusToInput}
          onBlur={handleBlurToInput}
        />
        {!!query && (
          <button
            type="button"
            className="absolute top-4 right-3"
            onClick={onClickResetButton}
          >
            <CrossIcon
              style={{
                width: 16,
                height: 16,
              }}
            />
          </button>
        )}
      </div>
      <div
        className="relative flex flex-col w-full"
        style={{
          margin: '-8px 0',
        }}
      >
        {!!query && menuOpen && (
          <TitlesMenu
            isLoading={isLoadingGetBooksByQuery}
            books={books}
            inputValueEan={inputValueEan}
            handleOnClickTitleItem={handleOnClickTitleItem}
          />
        )}
      </div>
      {inputValue && inputValue.length > 0 && (
        <div
          className="relative flex flex-auto"
          style={{
            overflow: 'hidden',
            margin: '0 -32px',
          }}
        >
          <div className="absolute w-full h-full">
            <PerfectScrollbar className="absolute h-0 flex flex-col h-full gap-2 px-8">
              {inputValue.map((input: BookSearchInterface) => (
                <EditionsByTitleCard
                  disable={disable}
                  key={`input-title-${input.id_ean}`}
                  color={color}
                  book={input}
                  handleOnClickRemoveItem={(booksToRemove: BookInterface[]) => {
                    const newBooks = (inputValue || []).filter(
                      (bookSearch: BookSearchInterface) =>
                        bookSearch.id_ean !== input.id_ean,
                    )
                    handleOnClickItemMenu(
                      !newBooks?.length ? null : newBooks,
                      booksToRemove || [],
                    )
                  }}
                  booksSelected={booksSelected}
                  handleUpdateBooksSelected={handleUpdateBooksSelected}
                  handleOpenBookDetails={handleOpenBookDetails}
                  eanSelected={eanSelected}
                />
              ))}
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </div>
  )
}

export default TitleInputAutoCompletion
