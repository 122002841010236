import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import GlHDivider from '../share/GlHDivider'
import GlMatInput from '../share/inputs/GlMatInput'
import { ReactComponent as LockIcon } from '../../assets/icons/app/lock.icon.svg'
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/app/visibility-off.icon.svg'
import { ReactComponent as VisibilityIcon } from '../../assets/icons/app/visibility.icon.svg'

import GlPrefixIconButton from '../share/buttons/GlPrefixIconButton'
import GlMdRoundedButton from '../share/buttons/GlMdRoundedButton'
import GlDialog from '../share/dialog/GlDialog'
import {
  getErrorMessagePasswordIsNotValid,
  passwordIsValidByRulesUtil,
  passwordIsValidUtil,
} from '../../core/utils/password.utils'

const HintStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #b2b2b2;
  margin: 16px 0;
`

const SettingProfileCard = ({
  isLoadingChangePassword,
  profile,
  handleChangePassword,
}: {
  isLoadingChangePassword: boolean
  profile: any
  handleChangePassword: (
    {
      currentPassword,
      password,
      confirmPassword,
    }: {
      currentPassword: string
      password: string
      confirmPassword: string
    },
    callbackSuccess: any,
  ) => void
}) => {
  const { t } = useTranslation()
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState<boolean>(false)

  const [lastPasswordChange, setLastPasswordChange] = useState<boolean>(false)
  const [lastPassword, setLastPassword] = useState<string>('')
  const [lastPasswordVisibility, setLastPasswordVisibility] =
    useState<boolean>(false)

  const [passwordChange, setPasswordChange] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false)

  const [passwordRepeatChange, setPasswordRepeatChange] =
    useState<boolean>(false)
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [passwordRepeatVisibility, setPasswordRepeatVisibility] =
    useState<boolean>(false)

  const isValidChangePasswordForm = (): boolean => {
    return (
      !!lastPassword &&
      !!password &&
      !!passwordRepeat &&
      password === passwordRepeat &&
      passwordIsValidUtil(passwordIsValidByRulesUtil(password))
    )
  }

  const onClickHandleChangePassword = () => {
    handleChangePassword(
      {
        currentPassword: lastPassword,
        password,
        confirmPassword: passwordRepeat,
      },
      () => setOpenChangePasswordDialog(false),
    )
  }
  return (
    <>
      <div className="flex flex-col w-[784px] gap-4">
        <div>{t('webApp.settings.profile.title')}</div>
        <GlHDivider />
        <div className="flex flex-col  bg-white rounded p-4">
          <div className="flex flex-row flex-wrap gap-4">
            <div className="basis-1/3">
              <GlMatInput
                label={t('commons.inputs.firstName.label')}
                placeholder=""
                type="text"
                keyValue="first_name"
                value={profile?.first_name}
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
            <div className="basis-1/3">
              <GlMatInput
                label={t('commons.inputs.lastName.label')}
                placeholder=""
                type="text"
                keyValue="last_name"
                value={profile?.last_name}
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-1">
            <div className="basis-1/3">
              <GlMatInput
                label={t('commons.inputs.email.label')}
                placeholder=""
                type="text"
                keyValue="email"
                value={profile?.email}
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-4">
            <div className="basis-1/3">
              <GlMatInput
                label={t('commons.inputs.homeEdition.label')}
                placeholder=""
                type="text"
                keyValue="company"
                value={profile?.business_unit?.name}
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
            <div className="basis-1/3">
              <GlMatInput
                label={t('commons.inputs.businessUnit.label')}
                placeholder=""
                type="text"
                keyValue="company"
                value={profile?.company?.name}
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-4 items-end justify-start">
            <div className="basis-1/3 items-end justify-start">
              <GlMatInput
                label={t('commons.inputs.password.label')}
                placeholder=""
                type="password"
                keyValue="fake-password"
                value="XXXXXXXXXX"
                readOnly
                prefix={
                  <GlPrefixIconButton icon={<LockIcon className="w-4 h-4" />} />
                }
              />
            </div>
            <div className="basis-1/3 flex items-center justify-start h-full">
              <GlMdRoundedButton
                label={t('commons.buttons.edit')}
                hasLoader={false}
                handleOnClick={() => setOpenChangePasswordDialog(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <GlDialog
        open={openChangePasswordDialog}
        onClose={() => setOpenChangePasswordDialog(false)}
      >
        <div className="flex flex-col w-[408px] gap-1">
          <div>{t('webApp.settings.profile.editPassword')}</div>
          <GlHDivider />

          <GlMatInput
            hasError={lastPasswordChange && !lastPassword}
            hasErrorMessage={t('commons.errorMessageCode.fieldRequired')}
            label={t('commons.inputs.lastPassword.label')}
            placeholder={t('commons.inputs.lastPassword.placeholder')}
            keyValue="current-password"
            type={(lastPasswordVisibility && 'text') || 'password'}
            value={lastPassword}
            onChangeValue={(value: string) => {
              setLastPasswordChange(true)
              setLastPassword(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !lastPasswordVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() =>
                  setLastPasswordVisibility(!lastPasswordVisibility)
                }
              />
            }
          />

          <GlMatInput
            hasError={
              passwordChange &&
              (!password ||
                !passwordIsValidUtil(passwordIsValidByRulesUtil(password)))
            }
            hasErrorMessage={
              (!password && t('commons.errorMessageCode.fieldRequired')) ||
              getErrorMessagePasswordIsNotValid(
                passwordIsValidByRulesUtil(password),
              )
            }
            label={t('commons.inputs.newPassword.label')}
            placeholder={t('commons.inputs.newPassword.placeholder')}
            keyValue="new-password"
            type={(passwordVisibility && 'text') || 'password'}
            value={password}
            onChangeValue={(value: string) => {
              setPasswordChange(true)
              setPassword(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            }
          />

          <GlMatInput
            hasError={
              passwordRepeatChange &&
              (!passwordRepeat ||
                (!!password && !!passwordRepeat && password !== passwordRepeat))
            }
            hasErrorMessage={
              (!passwordRepeat &&
                t('commons.errorMessageCode.fieldRequired')) ||
              t('commons.errorMessageCode.passwordIsNotSame')
            }
            label={t('commons.inputs.confirmPassword.label')}
            placeholder={t('commons.inputs.confirmPassword.placeholder')}
            keyValue="new-password-repeat"
            type={(passwordRepeatVisibility && 'text') || 'password'}
            value={passwordRepeat}
            onChangeValue={(value: string) => {
              setPasswordRepeatChange(true)
              setPasswordRepeat(value)
            }}
            hasClearButton
            prefix={
              <GlPrefixIconButton
                icon={
                  !passwordRepeatVisibility ? (
                    <VisibilityOffIcon className="w-4 h-4" />
                  ) : (
                    <VisibilityIcon className="w-4 h-4" />
                  )
                }
                handleClick={() =>
                  setPasswordRepeatVisibility(!passwordRepeatVisibility)
                }
              />
            }
          />
          <HintStyle>{t('authentication.invitation.passwordRules')}</HintStyle>
          <GlHDivider />
          <div className="flex items-center justify-center">
            <GlMdRoundedButton
              label={t('commons.buttons.editPassword')}
              hasLoader
              isLoading={isLoadingChangePassword}
              disabled={!isValidChangePasswordForm() || isLoadingChangePassword}
              handleOnClick={onClickHandleChangePassword}
            />
          </div>
        </div>
      </GlDialog>
    </>
  )
}

export default SettingProfileCard
