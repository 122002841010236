import { ItemFilterActionEnum } from '../../enums/item-filter-action.enum'
import { ReactComponent as AuthorActive } from '../../../assets/icons/app/author.icon.svg'
import { ReactComponent as ReleaseIcon } from '../../../assets/icons/app/release-year.icon.svg'
import { ReactComponent as EditorIcon } from '../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as SegmentIcon } from '../../../assets/icons/app/segment.icon.svg'
import { ReactComponent as BookFormatIcon } from '../../../assets/icons/app/book-format.icon.svg'
import { ReactComponent as ProgressIcon } from '../../../assets/icons/app/progress.icon.svg'
import { TrendsItemFilterActionInterface } from '../../interface/dynamics/trends-item-filter-action.interface'

export const FILTER_KEY_AUTHORS = 'authors'
export const FILTER_KEY_PUBLISHING_DATE = 'publishing_date'
export const FILTER_KEY_EDITOR = 'edition'
export const FILTER_KEY_SEGMENT1 = 'clil1'
export const FILTER_KEY_SEGMENT2 = 'clil2'
export const FILTER_KEY_TITLE = 'title'
export const FILTER_KEY_FORMAT = 'format'
export const FILTER_KEY_DELTA_RANK = 'delta_rank'
export const FILTER_KEY_RANK = 'rank'

export const ItemsFilterDynamicsResultsConfig: TrendsItemFilterActionInterface[] =
  [
    {
      value: ItemFilterActionEnum.Progress,
      name: 'webApp.dynamics.results.filters.columns.progress',
      icon: <ProgressIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_DELTA_RANK,
    },
    {
      value: ItemFilterActionEnum.Author,
      name: 'webApp.dynamics.results.filters.columns.author',
      icon: <AuthorActive className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_AUTHORS,
    },
    {
      value: ItemFilterActionEnum.ReleaseYear,
      name: 'webApp.dynamics.results.filters.columns.publishingDate',
      icon: <ReleaseIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_PUBLISHING_DATE,
    },
    {
      value: ItemFilterActionEnum.Editor,
      name: 'webApp.dynamics.results.filters.columns.editor',
      icon: <EditorIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_EDITOR,
    },
    {
      value: ItemFilterActionEnum.Segment1,
      name: 'webApp.dynamics.results.filters.columns.segment1',
      icon: <SegmentIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_SEGMENT1,
    },
    {
      value: ItemFilterActionEnum.Segment2,
      name: 'webApp.dynamics.results.filters.columns.segment2',
      icon: <SegmentIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_SEGMENT2,
    },
    {
      value: ItemFilterActionEnum.Format,
      name: 'webApp.dynamics.results.filters.columns.format',
      icon: <BookFormatIcon className="w-4 h-4" />,
      active: false,
      key: FILTER_KEY_FORMAT,
    },
  ]
