import React, { memo } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TableViewIcon } from '../../../../assets/icons/app/table-view.icon.svg'
import D3ConstellationWorkMirror from './constellation/D3ConstellationWorkMirror'
import AnalyticDetailsBooksInput from '../../AnalyticDetailsBooksInput'
import { useAppSelector } from '../../../../store/hooks'
import {
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetailsBooksInput,
  selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  selectAnalyticDetailsStateIsLoadingGetBookRecoV3,
  selectAnalyticDetailsStateIsLoadingGetBookRecoV3Add,
  selectAnalyticDetailsStateIsLoadingGetBookRecoV3Remove,
  selectAnalyticDetailsStateIsLoadingNewConstellationGetBookReco,
  selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  selectAnalyticDetailsStateIsLoadingPutAnalytic,
  selectAnalyticDetailsStateNeedSaveAnalyticDetails,
} from '../../../../store/getters/analytics-details/analytic-details-v2.getters'
import GlLoader from '../../../share/GlLoader'
import { WorkMirrorBookInterface } from '../../../../core/interface/report/work-mirror-book.interface'

const CardLayoutStyle = styled.div`
  z-index: 0;
  background: rgba(255, 255, 255, 0.01);
`

const WorkMirrorConstellationCard = ({
  openListCard,
  handleOpenListView,
  handleSaveAnalytics,
  handleShareAnalytics,
  handleAddBookToAnalytic,
  handleRemoveBookInput,
  handleBookHovered,
  bookDetailsSelected,
  handleBookSelected,
  eans,
}: {
  openListCard: boolean
  handleOpenListView: () => void
  handleSaveAnalytics: () => void
  handleShareAnalytics: () => void
  handleAddBookToAnalytic: (eanId: number) => void
  handleRemoveBookInput: (eanId: number) => void
  handleBookHovered: (eanId: number | null) => void
  bookDetailsSelected: WorkMirrorBookInterface | null
  handleBookSelected: (book: WorkMirrorBookInterface | null) => void
  eans: string
}) => {
  const { t } = useTranslation()
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleOpenListView()
  }

  const analyticsDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )
  const isLoadingGetBookRecoV3 = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetBookRecoV3,
  )

  const isLoadingNewConstellationGetBookReco = useAppSelector(
    selectAnalyticDetailsStateIsLoadingNewConstellationGetBookReco,
  )

  const isLoadingGetBookRecoV3Add = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetBookRecoV3Add,
  )

  const isLoadingGetBookRecoV3Remove = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetBookRecoV3Remove,
  )

  const booksInput = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsBooksInput,
  )

  const booksOutput = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  )

  const isLoadingPostNewAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  )
  const isLoadingPutAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPutAnalytic,
  )

  const needSaveAnalyticDetails = useAppSelector(
    selectAnalyticDetailsStateNeedSaveAnalyticDetails,
  )

  return (
    <CardLayoutStyle className="relative flex-auto flex flex-col rounded-xl shadow-lg bg-white basis-2/3">
      <div className="flex items-start justify-between m-2">
        <AnalyticDetailsBooksInput
          permission={analyticsDetails?.permission}
          isLoadingNewConstellationGetBookReco={
            isLoadingNewConstellationGetBookReco
          }
          eans={eans}
          isLoadingAdd={isLoadingGetBookRecoV3Add}
          isLoadingRemove={isLoadingGetBookRecoV3Remove}
          needSave={needSaveAnalyticDetails}
          canShare={!!analyticsDetails?.id}
          handleSaveAnalytics={handleSaveAnalytics}
          handleShareAnalytics={handleShareAnalytics}
          booksInput={booksInput}
          handleAddBookToAnalytic={handleAddBookToAnalytic}
          handleRemoveBookInput={handleRemoveBookInput}
          bookDetailsSelected={bookDetailsSelected}
          handleBookSelected={handleBookSelected}
        />
        {(!openListCard && (
          <button
            type="button"
            className="flex flex-row gap-2 rounded p-2 items-center justify-center hover:shadow-xl bg-black text-white"
            onClick={handleOnClick}
            style={{
              zIndex: 10,
            }}
          >
            <TableViewIcon />
            <span>{t('commons.buttons.list')}</span>
          </button>
        )) ||
          null}
      </div>
      {((isLoadingGetBookRecoV3 ||
        isLoadingNewConstellationGetBookReco ||
        isLoadingGetBookRecoV3Add ||
        isLoadingGetBookRecoV3Remove ||
        isLoadingPostNewAnalytic ||
        isLoadingPutAnalytic) && (
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          style={{
            zIndex: 2,
          }}
        >
          <GlLoader />
        </div>
      )) || (
        <D3ConstellationWorkMirror
          booksInput={booksInput}
          booksOutput={booksOutput}
          handleBookHovered={handleBookHovered}
          bookDetailsSelected={bookDetailsSelected}
          handleBookSelected={handleBookSelected}
        />
      )}
    </CardLayoutStyle>
  )
}

export default memo(WorkMirrorConstellationCard)
