import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { selectWebAppStateFullName } from '../../store/getters/web-app.getters'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import SettingProfileCard from '../../components/settings/SettingProfileCard'
import {
  settingsGetAccountsProfileActions,
  settingsPostAccountsChangePasswordActions,
} from '../../store/actions/settings.actions'
import {
  selectSettingsStateHasErrorChangePassword,
  selectSettingsStateIsLoadingChangePassword,
  selectSettingsStateIsLoadingProfile,
  selectSettingsStateProfile,
} from '../../store/getters/settings.getters'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { reinitializeSettingsState } from '../../store/reducers/settings.reducer'
import GlLoader from '../../components/share/GlLoader'

const WelcomeStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: #252525;
`

const ManageView = () => {
  const { t } = useTranslation()
  const fullName = useAppSelector(selectWebAppStateFullName)
  const dispatch = useAppDispatch()

  const isLoadingProfile = useAppSelector(selectSettingsStateIsLoadingProfile)
  const profile = useAppSelector(selectSettingsStateProfile)
  const isLoadingChangePassword = useAppSelector(
    selectSettingsStateIsLoadingChangePassword,
  )
  const hasErrorChangePassword = useAppSelector(
    selectSettingsStateHasErrorChangePassword,
  )

  useEffect(() => {
    void dispatch(settingsGetAccountsProfileActions())
    return () => {
      dispatch(reinitializeSettingsState())
    }
  }, [])

  useEffect(() => {
    if (hasErrorChangePassword) {
      void dispatch(setShowSnackBar(t('snackbarMessage.checkPasswordError')))
    }
  }, [hasErrorChangePassword])

  const handleChangePassword = (
    {
      currentPassword,
      password,
      confirmPassword,
    }: {
      currentPassword: string
      password: string
      confirmPassword: string
    },
    callbackSuccess: any,
  ) => {
    void dispatch(
      settingsPostAccountsChangePasswordActions({
        currentPassword,
        password,
        confirmPassword,
      }),
    )
      .then(() => {
        void dispatch(settingsGetAccountsProfileActions())
        dispatch(setShowSnackBar(t('snackbarMessage.passwordIsChange')))
        callbackSuccess()
      })
      .catch(() => {})
  }

  return (
    <div className="w-full h-full flex flex-col py-8 px-12 gap-10">
      <WelcomeStyle>{t('commons.welcome', { fullName })}</WelcomeStyle>
      {(isLoadingProfile && (
        <div className="w-full h-full flex items-center justify-center">
          <GlLoader />
        </div>
      )) || (
        <SettingProfileCard
          isLoadingChangePassword={isLoadingChangePassword}
          profile={profile}
          handleChangePassword={handleChangePassword}
        />
      )}
    </div>
  )
}

export default ManageView
