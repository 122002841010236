import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { ShareAnalyticDetailsStateInterface } from '../interfaces/share-analytic-details-state.interface'
import {
  shareAnalyticDetailsGetShareAnalyticBySlugAction,
  shareAnalyticDetailsStateGetB2BBookByEanId,
  shareAnalyticDetailsV2StateGetBookDetailsData,
} from '../actions/share-analytic-details.actions'
import { WorkMirrorBookInterface } from '../../core/interface/report/work-mirror-book.interface'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import { BookDetailResponseInterface } from '../../core/interface/rank/book-detail.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../core/enums/analytic-input-type.enum'
import { AnalyticOutputInterface } from '../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../../core/enums/analytic-output-type.enum'

const initialState: ShareAnalyticDetailsStateInterface = {
  isLoadingGetShareAnalyticDetails: false,
  analyticDetails: null,
  getShareAnalyticDetailsSuccess: false,
  getShareAnalyticDetailsError: false,
  cancelTokenGetShareAnalyticDetailsBySlug: null,
  isLoadingBookDetailsSelected: false,
  bookDetailsSelected: null,
  bookDetailsSelectedSuccess: false,
  bookDetailsSelectedError: false,
  isLoadingGetB2BBookDetails: false,
  bookDetailsSelectedB2BInfo: null,
  getB2BBookDetailsSuccess: false,
  getB2BBookDetailsError: false,
  cancelTokenGetB2BBookDetails: null,
}

const shareAnalyticDetailsSlice = createSlice({
  name: 'sharedAnalyticDetailsState',
  initialState,
  reducers: {
    resetShareAnalyticDetailsStatus: (
      state: ShareAnalyticDetailsStateInterface,
    ): ShareAnalyticDetailsStateInterface => ({
      ...state,
      getShareAnalyticDetailsSuccess: false,
      getShareAnalyticDetailsError: false,
    }),
    reinitializeShareAnalyticDetailsState: (
      state: ShareAnalyticDetailsStateInterface,
    ): ShareAnalyticDetailsStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        shareAnalyticDetailsGetShareAnalyticBySlugAction.pending,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => {
          const { cancelTokenGetShareAnalyticDetailsBySlug } = state
          if (cancelTokenGetShareAnalyticDetailsBySlug) {
            cancelTokenGetShareAnalyticDetailsBySlug.cancel()
          }

          return {
            ...state,
            isLoadingGetShareAnalyticDetails: true,
            analyticDetails: null,
            getShareAnalyticDetailsSuccess: false,
            getShareAnalyticDetailsError: false,
            cancelTokenGetShareAnalyticDetailsBySlug:
              axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        shareAnalyticDetailsGetShareAnalyticBySlugAction.fulfilled,
        (
          state: ShareAnalyticDetailsStateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): ShareAnalyticDetailsStateInterface => {
          const analyticDetails = cloneDeep(action.payload)
          analyticDetails.inputs = analyticDetails?.inputs.map(
            (input: AnalyticInputInterface) => {
              if (input?.type === AnalyticInputTypeEnum.BOOKS) {
                input.outputs = input.outputs.map(
                  (output: AnalyticOutputInterface) => {
                    if (output.type === AnalyticOutputTypeEnum.WORK_MIRROR) {
                      output.work_mirror_books = (
                        output.work_mirror_books || []
                      ).map((node: WorkMirrorBookInterface, index1: number) => {
                        return {
                          ...node,
                          distance: node.distance || node.book.distance || 0,
                          position: index1 + 1,
                        } as WorkMirrorBookInterface
                      })
                    }
                    return output
                  },
                )
              }
              return input
            },
          )

          return {
            ...state,
            isLoadingGetShareAnalyticDetails: false,
            analyticDetails,
            getShareAnalyticDetailsSuccess: true,
            getShareAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        shareAnalyticDetailsGetShareAnalyticBySlugAction.rejected,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingGetShareAnalyticDetails: false,
          analyticDetails: null,
          getShareAnalyticDetailsSuccess: false,
          getShareAnalyticDetailsError: true,
        }),
      )
      .addCase(
        shareAnalyticDetailsV2StateGetBookDetailsData.pending,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingBookDetailsSelected: true,
          bookDetailsSelected: null,
        }),
      )
      .addCase(
        shareAnalyticDetailsV2StateGetBookDetailsData.fulfilled,
        (
          state: ShareAnalyticDetailsStateInterface,
          action: PayloadAction<WorkMirrorBookInterface | null>,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingBookDetailsSelected: false,
          bookDetailsSelected: action.payload,
          bookDetailsSelectedB2BInfo:
            action.payload === null ? null : state.bookDetailsSelectedB2BInfo,
        }),
      )
      .addCase(
        shareAnalyticDetailsV2StateGetBookDetailsData.rejected,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingBookDetailsSelected: false,
          bookDetailsSelected: null,
        }),
      )
      .addCase(
        shareAnalyticDetailsStateGetB2BBookByEanId.pending,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => {
          const { cancelTokenGetB2BBookDetails } = state
          if (cancelTokenGetB2BBookDetails) {
            cancelTokenGetB2BBookDetails.cancel()
          }
          return {
            ...state,
            isLoadingGetB2BBookDetails: true,
            bookDetailsSelectedB2BInfo: null,
            getB2BBookDetailsSuccess: false,
            getB2BBookDetailsError: false,
            cancelTokenGetB2BBookDetails: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        shareAnalyticDetailsStateGetB2BBookByEanId.fulfilled,
        (
          state: ShareAnalyticDetailsStateInterface,
          action: PayloadAction<BookDetailResponseInterface>,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingGetB2BBookDetails: false,
          bookDetailsSelectedB2BInfo: action.payload,
          getB2BBookDetailsSuccess: true,
          getB2BBookDetailsError: false,
        }),
      )
      .addCase(
        shareAnalyticDetailsStateGetB2BBookByEanId.rejected,
        (
          state: ShareAnalyticDetailsStateInterface,
        ): ShareAnalyticDetailsStateInterface => ({
          ...state,
          isLoadingGetB2BBookDetails: false,
          bookDetailsSelectedB2BInfo: null,
          getB2BBookDetailsSuccess: false,
          getB2BBookDetailsError: true,
        }),
      )
  },
})

export const {
  resetShareAnalyticDetailsStatus,
  reinitializeShareAnalyticDetailsState,
} = shareAnalyticDetailsSlice.actions
export const shareAnalyticDetailsReducer = shareAnalyticDetailsSlice.reducer
