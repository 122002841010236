import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import DashboardView from '../../pages/webapp/DashboardView'
import ReportsDashboard from '../../pages/webapp/ReportsDashboard'
import WebApp from '../../pages/WebApp'
import {
  KEY_PARAM_CHANGE_PASSWORD,
  KEY_PARAM_AUDIENCE,
  KEY_PARAM_INVITATION,
  KEY_PARAM_REPORT,
  KEY_PARAM_SLUG,
  PathConfig,
} from '../config/PathConfig'
import AuthApp from '../../pages/authentication/AuthApp'
import SignInView from '../../pages/authentication/SignInView'
import LegalView from '../../pages/public/LegalView'
import AuthenticatedRoute from '../../components/layouts/AuthenticatedRoute'
import NonAuthenticatedRoute from '../../components/layouts/NonAuthenticatedRoute'
import ShareApp from '../../pages/share/ShareApp'
import ShareAnalyticDetailsView from '../../pages/share/ShareAnalyticDetailsView'
import InvitationView from '../../pages/authentication/InvitationView'
import ForgotPasswordView from '../../pages/authentication/ForgotPasswordView'
import ChangePasswordView from '../../pages/authentication/ChangePasswordView'
import ManageView from '../../pages/webapp/ManageView'
import DevView from '../../pages/DevView'
import DynamicsSearchView from '../../pages/webapp/DynamicsSearchView'
import DynamicsLayout from '../../components/layouts/DynamicsLayout'
import DynamicsResultView from '../../pages/webapp/DynamicsResultsView'
import AnalyticDetailsView from '../../pages/webapp/AnalyticDetailsView'
import PrivateReportsDashBoard from '../../pages/webapp/PrivateReportsDashBoard'
import NewAudienceView from '../../pages/webapp/NewAudienceView'
import AudienceView from '../../pages/webapp/AudienceView'

const router = createBrowserRouter([
  {
    path: PathConfig.BASE,
    element: <Navigate to={PathConfig.BOOKMETRIE} />,
  },
  {
    path: PathConfig.LEGAL,
    element: <LegalView />,
  },
  {
    path: PathConfig.BOOKMETRIE,
    element: (
      <AuthenticatedRoute>
        <WebApp />
      </AuthenticatedRoute>
    ),

    children: [
      {
        index: true,
        element: <DashboardView />,
      },
      {
        path: PathConfig.DYNAMICS,
        element: <DynamicsLayout />,
        children: [
          {
            path: PathConfig.SEARCH,
            element: <DynamicsSearchView />,
          },
          {
            path: PathConfig.RESULTS,
            element: <DynamicsResultView />,
          },
        ],
      },
      {
        path: PathConfig.ANALYTICS,
        children: [
          {
            path: PathConfig.BASE,
            element: <ReportsDashboard />,
          },
          {
            path: PathConfig.PARAMS(KEY_PARAM_REPORT),
            element: <AnalyticDetailsView />,
          },
        ],
      },
      {
        path: PathConfig.PRIVATE_REPORTS,
        children: [
          {
            path: PathConfig.BASE,
            element: <PrivateReportsDashBoard />,
          },
          {
            path: PathConfig.AUDIENCE,
            children: [
              { path: PathConfig.BASE, element: <NewAudienceView /> },
              {
                path: PathConfig.PARAMS(KEY_PARAM_AUDIENCE),
                element: <AudienceView />,
              },
            ],
          },
        ],
      },
      {
        path: PathConfig.MANAGE,
        element: <ManageView />,
      },
      {
        path: PathConfig.DEV,
        element: <DevView />,
      },
    ],
  },
  {
    path: PathConfig.AUTH,
    element: (
      <NonAuthenticatedRoute>
        <AuthApp />
      </NonAuthenticatedRoute>
    ),
    children: [
      {
        path: PathConfig.SIGN_IN,
        element: <SignInView />,
      },
      {
        path: [
          PathConfig.INVITATION,
          PathConfig.TOKEN_PARAMS(KEY_PARAM_INVITATION),
        ].join('/'),
        element: <InvitationView />,
      },
      {
        path: PathConfig.FORGOT_PASSWORD,
        element: <ForgotPasswordView />,
      },
      {
        path: [
          PathConfig.CHANGE_PASSWORD,
          PathConfig.TOKEN_PARAMS(KEY_PARAM_CHANGE_PASSWORD),
        ].join('/'),
        element: <ChangePasswordView />,
      },
    ],
  },
  {
    path: PathConfig.SHARED,
    element: <ShareApp />,
    children: [
      {
        path: [PathConfig.ANALYTICS, PathConfig.PARAMS(KEY_PARAM_SLUG)].join(
          '/',
        ),
        element: <ShareAnalyticDetailsView />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={PathConfig.BASE} />,
  },
])

export default router
