import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { ReactElement, useCallback } from 'react'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { getColors } from './getColors'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import CustomTooltipMulti from './chartComponents/tooltip/CustomTooltipMulti'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'
import { firstCharToUpperCaseHelper } from '../../../../../../core/helpers/first-char-to-upper-case.helper'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'

interface HorizontalClilAxisProps {
  y: number
  width: number
  height: number
  payload: { value: string; offset: number }
}

const HorizontalClilAxis: (props: any) => ReactElement = ({
  y,
  width,
  height,
  payload,
}: HorizontalClilAxisProps) => {
  const { libelle, color } = clilReferencesConfig[payload.value || '0000']
  return (
    <foreignObject x={0} y={y - payload.offset} height={height} width={width}>
      <div className="min-h-[25px] flex flex-row items-center justify-between">
        <span
          className="text-right text-xs truncate"
          style={{ width: 'calc(100% - 32px)' }}
        >
          {firstCharToUpperCaseHelper(libelle.toLowerCase())}
        </span>
        <div
          className="w-3 h-3 rounded-full mr-3"
          style={{ backgroundColor: color }}
        />
      </div>
    </foreignObject>
  )
}

const AnalyticsOutputHorizontalReCharts = ({
  valuesConfigurations,
  display,
  title,
  subTitle,
  setColor,
  dataset,
  datasetModule,
  typeDataset,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  isOrganizeMenuOpen,
  outputIdToScroll,
  onClick,
  level,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  display: AnalyticOutputLayoutSizeEnum
  title?: string
  subTitle?: string
  setColor: SetColorEnum
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  typeDataset: DatasetTypeEnum
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  isOrganizeMenuOpen?: boolean
  outputIdToScroll?: string
  onClick?: (clil: number, lvl: number) => void
  level?: number
}) => {
  const primaryValueConfiguration = valuesConfigurations.filter(
    (p) => p.is_primary_key,
  )[0]
  const { t } = useTranslation()
  const colorMapping = getColors(setColor)

  const isClil = typeDataset === DatasetTypeEnum.CLIL
  const isClickable = isClil && onClick && level && level < 3 && !isPreview

  const CustomTooltip = useCallback((props: any) => {
    return (
      <CustomTooltipMulti
        {...props}
        typeDataset={typeDataset}
        isClickable={isClickable}
      />
    )
  }, [])

  const onClickClil = (payload: CategoricalChartState) => {
    const { activePayload } = payload
    if (isClickable && activePayload) {
      onClick(activePayload[0].payload.clil, level)
    }
  }

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={
        primaryValueConfiguration.source === SourceEnum.AGE
          ? [t('InfoBulles.distributionByAgeVertical')]
          : [t('InfoBulles.distributionByLibraries')]
      }
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.HORIZONTAL_BAR}
      shrink={isOrganizeMenuOpen}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          style={{ cursor: isClickable ? 'pointer' : 'default' }}
          layout="vertical"
          data={dataset}
          barGap={2}
          barCategoryGap={2}
          margin={{
            bottom:
              isOrganizeMenuOpen && outputIdToScroll !== datasetModule.id
                ? 25
                : 0,
          }}
          onClick={onClickClil}
        >
          <Tooltip cursor={{ strokeWidth: 30 }} content={CustomTooltip} />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#3B3B3B', fontSize: 12 }}
            tickFormatter={(value: string) => {
              return t('intl.percentageNumber', { value })
            }}
            height={20}
          />
          <YAxis
            dataKey={primaryValueConfiguration.source}
            type="category"
            scale="auto"
            axisLine={false}
            tickLine={false}
            width={
              typeDataset === DatasetTypeEnum.CLIL
                ? (display / AnalyticOutputLayoutSizeEnum.FULL) * 300
                : 75
            }
            tick={
              isClil ? HorizontalClilAxis : { fill: '#3B3B3B', fontSize: 12 }
            }
            tickFormatter={(value: string) => {
              if (primaryValueConfiguration.source === SourceEnum.AGE) {
                return `${value} ${t('years')}`
              }
              if (
                primaryValueConfiguration.source ===
                  SourceEnum.BOOK_READ_COUNT &&
                value === '1251+'
              ) {
                return `${value} ${t('books')}`
              }
              return value
            }}
            interval={0}
          />
          <CartesianGrid vertical horizontal={false} />
          {valuesConfigurations
            .filter((c, index) => !c.is_primary_key && c.show_in_chart)
            .map((valueConfiguration) => {
              return (
                <Bar
                  key={valueConfiguration.source}
                  dataKey={valueConfiguration.source}
                  fill={colorMapping[valueConfiguration.source]}
                  radius={[0, 3, 3, 0]}
                  background={{
                    fill: `${colorMapping[valueConfiguration.source]}1a`,
                  }}
                />
              )
            })}
        </ComposedChart>
      </ResponsiveContainer>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputHorizontalReCharts
