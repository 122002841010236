export enum AddModuleDataRepresentationEnum {
  READERS_AGE = 'readersAge',
  READERS_LIBRARY = 'readersLibrary',
  MIRROR = 'mirror',
  TOP_BOOKS = 'topBooks',
  TEXT = 'text',
  PICTURE = 'picture',
  TITLE = 'title',
  STUDY_TITLES = 'studyTitles',
}
