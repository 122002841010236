import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import GlCheckBoxInputV2 from '../../../../share/inputs/GlCheckBoxInputV2'
import { BookInterface } from '../../../../../core/interface/book.interface'
import {
  AuthorBookTableRowStyle,
  TableCellStyle,
  TitleBookTableRowStyle,
} from '../ReadershipProfileStyles'
import { ReactComponent as CopyPasteIcon } from '../../../../../assets/icons/app/copy-paste.icon.svg'
import { useAppDispatch } from '../../../../../store/hooks'
import { setShowSnackBar } from '../../../../../store/reducers/web-app.reducer'

const TableRowStyle = styled.div`
  overflow: hidden;
  display: flex;
  background-color: ${({ color }: { disable: boolean; color: string }) =>
    color};
  border-radius: 4px;
  height: 40px;
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  cursor: ${({ disable }: { disable: boolean; color: string }) =>
    disable ? 'not-allowed' : 'pointer'};
`

const EditionsTableRow = ({
  disable,
  isChecked = false,
  book,
  color,
  handleOnClick,
  disableCheckbox = false,
}: {
  disable: boolean
  isChecked?: boolean
  book: BookInterface
  color: string
  handleOnClick: () => void
  disableCheckbox?: boolean
}) => {
  const onClickTableRow = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!disable && handleOnClick) {
      handleOnClick()
    }
  }

  const dispatch = useAppDispatch()
  const onClickISBNCopyButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    navigator.clipboard
      .writeText(String(book.id_ean))
      .then(() => {
        dispatch(setShowSnackBar('ISBN copié'))
      })
      .catch(() => {})
  }

  return (
    <TableRowStyle
      disable={disable}
      key={`table-row-title-${book.id_ean}`}
      color={isChecked ? `${color}1a` : 'transparent'}
      className="flex-auto flex flex-row gap-1"
      onClick={onClickTableRow}
    >
      {!disableCheckbox && (
        <div className="flex-none p-2 flex items-center justify-center px-2 py-3">
          <GlCheckBoxInputV2
            disable={disable}
            color={color}
            value={isChecked ? 'checked' : 'empty'}
            onChangeEvent={() => {
              handleOnClick && handleOnClick()
            }}
          />
        </div>
      )}
      <div
        className={`flex-auto flex flex-col items-start justify-center min-w-[80px] h-full gap-0 ${
          disableCheckbox ? 'p-2' : ''
        }`}
      >
        <TitleBookTableRowStyle
          title={book.title?.toString()}
          className="flex w-full  m-[-2px]"
        >
          <span> {book.title}</span>
        </TitleBookTableRowStyle>
        <AuthorBookTableRowStyle className="flex w-full  m-[-2px] flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-start">
            <span>EAN {book.id_ean}</span>
            <button
              type="button"
              onClick={onClickISBNCopyButton}
              className="p-1"
            >
              <CopyPasteIcon />
            </button>
          </div>
        </AuthorBookTableRowStyle>
      </div>
      <div className="flex-none flex justify-start items-center min-w-[120px] max-w-[120px]">
        <TableCellStyle title={book.edition?.toString()}>
          {book.edition}
        </TableCellStyle>
      </div>
      <div className="flex-none flex justify-start items-center min-w-[100px] max-w-[100px]">
        <TableCellStyle title={book.format?.toString()}>
          {book.format}
        </TableCellStyle>
      </div>
      <div className="flex-none flex justify-start items-center min-w-[76px] max-w-[76px]">
        <TableCellStyle>
          {dayjs(book.publishing_date).format('DD/MM/YYYY')}
        </TableCellStyle>
      </div>
    </TableRowStyle>
  )
}

export default EditionsTableRow
