import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import GlToggleIconButtons from '../../share/buttons/GlToggleIconButtons'
import { DynamicsResultsViewModeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import { ReactComponent as SaveIcon } from '../../../assets/icons/app/save.icon.svg'
import { ReactComponent as BillsIcon } from '../../../assets/icons/app/bills-view.icon.svg'
import { ReactComponent as CardIcon } from '../../../assets/icons/app/card-view.icon.svg'
import { ReactComponent as TableIcon } from '../../../assets/icons/app/table-view.icon.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/app/filter.icon.svg'
import { ReactComponent as ResetIcon } from '../../../assets/icons/app/reset.icon.svg'
import DynamicsResultsSearchButton from '../filters/actions-filters/DynamicsResultsSearchButton'
import DynamicsResultsSortButton from '../filters/actions-filters/DynamicsResultsSortButton'
import DynamicsResultsGroupButton from '../filters/actions-filters/DynamicsResultsGroupButton'
import { TrendsItemFilterActionInterface } from '../../../core/interface/dynamics/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  85% {
    opacity: 1;
    transform: scale(1.05);

  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const fadeOut = keyframes`
  100% {
    opacity: 0;
    transform: scale(0.1);
  }
  85% {
    opacity: 1;
    transform: scale(1.05);

  }
  0% {
    opacity: 1;
    transform: scale(1);
  }
`

const ButtonActionsTableStyle = styled.button`
  animation: ${({ hasTableView }: { hasTableView: boolean }) =>
      (hasTableView && fadeIn) || fadeOut}
    0.1s linear;
  animation-fill-mode: both;
  &:nth-of-type(3) {
    animation-delay: ${({ hasTableView }: { hasTableView: boolean }) =>
      (hasTableView && '0.2s') || '0.4s'};
  }
  &:nth-of-type(2) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(1) {
    animation-delay: ${({ hasTableView }: { hasTableView: boolean }) =>
      (hasTableView && '0.4s') || '0.2s'};
  }
`

const HasCursorStyle = styled.div`
  height: 8px;
  width: 8px;
  background: white;
  border-radius: 6px;
  border: 2px solid #252525;
  position: absolute;
  top: -4px;
  right: -4px;
`

const DynamicsResultsHeaderActions = ({
  viewMode,
  openFilter,
  handleOpenFilter,
  handleViewMode,
  handleOpenDialog,
  hasFilters,
  groupSelected,
  handleGroupSelected,
  searchValue,
  handleSearchValue,
  sortsSelected,
  handleSortsSelected,
  handleResetAllFilterActions,
  needSaveRequest,
  trendsAggregateByColumn,
}: {
  viewMode: DynamicsResultsViewModeEnum
  openFilter: boolean
  handleOpenFilter: () => void
  handleViewMode: (viewMode: DynamicsResultsViewModeEnum) => void
  handleOpenDialog: () => void
  hasFilters: boolean
  groupSelected: TrendsItemFilterActionInterface | null
  handleGroupSelected: (value: TrendsItemFilterActionInterface) => void
  searchValue: string
  handleSearchValue: (value: string) => void
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  handleSortsSelected: (
    sorts: { columnKey: string; sortDirection: SortDirectionEnum }[],
  ) => void
  handleResetAllFilterActions: () => void
  needSaveRequest: boolean
  trendsAggregateByColumn: { [key: string]: Set<any> }
}) => {
  const onClickSaveActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleOpenDialog()
  }

  return (
    <div className="flex flex-row px-12 py-4 items-center justify-between">
      <div className="flex flex-row gap-2">
        <ButtonActionsTableStyle
          hasTableView
          type="button"
          className="
            border
            relative
            border-[#595959]
            w-8
            h-8
            flex
            items-center
            justify-center
            rounded
            bg-[#595959] 
            text-white"
          onClick={onClickSaveActions}
        >
          <SaveIcon />
          {(needSaveRequest && <HasCursorStyle />) || null}
        </ButtonActionsTableStyle>
        <GlToggleIconButtons
          buttons={[
            {
              icon: <BillsIcon />,
              value: DynamicsResultsViewModeEnum.BILLS,
            },
            {
              icon: <CardIcon />,
              value: DynamicsResultsViewModeEnum.CARD,
            },
            {
              icon: <TableIcon />,
              value: DynamicsResultsViewModeEnum.TABLE,
            },
          ]}
          buttonValueActive={viewMode}
          handleOnClick={(value: any) =>
            handleViewMode(value as DynamicsResultsViewModeEnum)
          }
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <DynamicsResultsSearchButton
          searchValue={searchValue}
          handleSearchValue={handleSearchValue}
          trendsAggregateByColumn={trendsAggregateByColumn}
        />
        <div
          className={`flex flex-row gap-2 p-1 rounded ${
            hasFilters || sortsSelected.length || groupSelected
              ? 'border border-[#f97e60]'
              : ''
          }`}
        >
          <ButtonActionsTableStyle
            hasTableView
            type="button"
            className={`
            border
            relative
            border-[#595959]
            w-8
            h-8
            flex
            items-center
            justify-center
            rounded
            ${
              (openFilter && 'bg-white text-[#595959]') ||
              'bg-[#595959] text-white'
            }
            
          `}
            onClick={handleOpenFilter}
          >
            <FilterIcon />
            {(hasFilters && <HasCursorStyle />) || null}
          </ButtonActionsTableStyle>
          {viewMode === DynamicsResultsViewModeEnum.TABLE && (
            <>
              <DynamicsResultsSortButton
                sortsSelected={sortsSelected}
                handleSortsSelected={handleSortsSelected}
              />
              <DynamicsResultsGroupButton
                groupSelected={groupSelected}
                handleGroupSelected={handleGroupSelected}
              />
            </>
          )}
        </div>
        <button
          type="button"
          className="
    border
    border-[#595959]
    w-8 h-8
    flex
    items-center
    justify-center
    rounded
    bg-[#f97e60]
    text-white
    "
          style={{
            pointerEvents:
              hasFilters || sortsSelected.length || groupSelected
                ? 'auto'
                : 'none',
            visibility:
              hasFilters || sortsSelected.length || groupSelected
                ? 'visible'
                : 'hidden',
          }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            handleResetAllFilterActions()
          }}
        >
          <ResetIcon className="w-[18px] h-[18px]" />
        </button>
      </div>
    </div>
  )
}

export default DynamicsResultsHeaderActions
