import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AudienceRecap from '../AudienceRecap'
import { StudyObjectGroupCompareEnum } from '../../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { AudienceInputInterface } from '../../../core/interface/audience/audience-input.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import AudienceCatalogInterface from '../../../core/interface/audience/audience-catalog.interface'
import AudienceBody from './AudienceBody'
import { useAppDispatch } from '../../../store/hooks'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import GetAudienceFile from '../../../core/api/services/bookmetrie/private-reports/audience/get-audience-file.service'
import DownloadCsvUtil from '../../../core/utils/downloadCsv.util'

interface Props {
  audience: AnalyticDetailsInterface<AudienceInputInterface> | null
  catalogs: AudienceCatalogInterface[]
}

const Audience = ({ audience, catalogs }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)

  const catalogSelected = useMemo(() => {
    if (!audience) return null
    return (
      catalogs.find(
        (catalog) => catalog.id === audience?.inputs[0].user_catalog_id,
      ) || null
    )
  }, [catalogs, audience?.inputs[0].user_catalog_id])

  const downloadFile = useCallback(() => {
    if (!audience) return null
    setIsLoadingFile(true)
    GetAudienceFile(audience.id)
      .then(({ data }) => {
        DownloadCsvUtil(data, audience.name)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        setIsLoadingFile(false)
      })
  }, [audience?.id, audience?.name])

  if (!audience) return null
  const {
    type,
    ids,
    objects,
    books,
    filter_user_already_own_book: filterUserAlreadyOwnBook,
    user_catalog_id: userCatalogId,
    is_nano: isNano,
    request_size: requestSize,
    outputs,
  } = audience.inputs[0]

  return (
    <div className="flex-auto flex flex-col px-8 py-4 gap-4">
      <AudienceRecap
        searchType={type}
        values={type === StudyObjectGroupCompareEnum.CLIL ? ids : objects}
        booksSelected={books}
        userAlreadyOwnBook={filterUserAlreadyOwnBook}
        useFullGleephCatalog={!userCatalogId}
        catalogSelected={catalogSelected}
        isNano={isNano}
        volume={requestSize}
        isLoading={false}
      />
      <AudienceBody
        outputs={outputs}
        modifiedDate={audience.modified}
        isLoadingFile={isLoadingFile}
        downloadFile={downloadFile}
        totalUsers={requestSize}
      />
    </div>
  )
}

export default memo(Audience)
