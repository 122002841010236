import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'
import LibraryStructureRecharts from './chartComponents/LibraryStructure/LibraryStructureRecharts'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { setShowSnackBar } from '../../../../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../../../../store/hooks'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import { AnalyticModuleReplace } from '../../../../../../core/interface/analytics/analytic-module-replace.interface'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'

interface LevelInterface {
  [SourceEnum.CLIL]: number | null
  datasets: Array<Array<DatasetInterface>>
}

export const MAX_LIBRARY_STRUCTURE_LEVEL = 3

const AnalyticsOutputLibraryStructureRechart = ({
  setColor,
  datasetModules,
  scrollToTreemap,
  handleAnalyticsDeleteOutput,
  handleChangeDatasetOutput,
  isOrganizeMenuOpen,
  outputIdToScroll,
  refreshLayout,
}: {
  setColor: SetColorEnum
  datasetModules: DatasetModuleInterface[]
  scrollToTreemap: (id: string) => void
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleChangeDatasetOutput?: (moduleToReplace: AnalyticModuleReplace) => void
  isOrganizeMenuOpen: boolean
  outputIdToScroll?: string
  refreshLayout?: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [level, setLevel] = useState<Array<LevelInterface>>([])
  const [datasetModulesState, setDatasetModulesState] = useState<
    Array<DatasetModuleInterface>
  >([])

  const modulesOrderHasChanged = useMemo(() => {
    return (
      datasetModules.map((d) => d.id).join() !==
      datasetModulesState.map((d) => d.id).join()
    )
  }, [datasetModules])

  const filterByClil = (
    dataset: Array<DatasetInterface>,
    clil: number | null,
  ) => {
    return dataset
      .filter((item) => item[SourceEnum.PARENT_CLIL] === clil)
      .slice(0, 6)
  }

  const filterDatasetsByClil = (clil: number | null) =>
    datasetModules.map((d) =>
      d.type === AnalyticsChartTypeEnum.VERTICAL_STACKED_BAR_CHART
        ? d.dataset
        : filterByClil(d.dataset, clil),
    )

  useEffect(() => {
    if (refreshLayout && level.length > 0) {
      refreshLayout()
    }
  }, [level])

  useEffect(() => {
    if (!modulesOrderHasChanged && refreshLayout) {
      refreshLayout()
    }
  }, [datasetModulesState])

  useEffect(() => {
    if (
      JSON.stringify(datasetModules) !== JSON.stringify(datasetModulesState)
    ) {
      setDatasetModulesState(datasetModules)
    }
    if (modulesOrderHasChanged) {
      setLevel((lvl) =>
        lvl.length > 0
          ? lvl.map((level) => ({
              [SourceEnum.CLIL]: level[SourceEnum.CLIL],
              datasets: filterDatasetsByClil(level[SourceEnum.CLIL]),
            }))
          : [
              {
                [SourceEnum.CLIL]: null,
                datasets: filterDatasetsByClil(null),
              },
            ],
      )
    }
  }, [datasetModules])

  const onClickClil = (clil: number, lvl: number) => {
    if (lvl < MAX_LIBRARY_STRUCTURE_LEVEL) {
      const newDatasets = filterDatasetsByClil(clil)
      if (newDatasets[0].length === 0) {
        dispatch(setShowSnackBar(t('StructureOfLibrariesByClilNotEnoughData')))
      } else {
        const newLevel = level.slice(0, lvl)
        setLevel([
          ...newLevel,
          { [SourceEnum.CLIL]: clil, datasets: newDatasets },
        ])
        setTimeout(() => {
          scrollToTreemap(`treemap-${lvl + 1}`)
        }, 100)
      }
    }
  }

  return (
    <>
      {level.map(
        (level, index) =>
          level.datasets.length > 0 && (
            <LibraryStructureRecharts
              key={level[SourceEnum.CLIL] || '0'}
              level={index + 1}
              title={
                index === 0
                  ? t('AllBooks')
                  : t('ZoomOf', {
                      clilName:
                        clilReferencesConfig[
                          level[SourceEnum.CLIL] || '0000'
                        ].libelle.toLowerCase(),
                      level: index > 1 ? `(${index - 1})` : '',
                    })
              }
              dataType={DatasetTypeEnum.CLIL}
              datasets={level.datasets}
              onClickClil={onClickClil}
              datasetModules={datasetModulesState}
              setColor={setColor}
              handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
              handleChangeDatasetOutput={handleChangeDatasetOutput}
              parentId={level[SourceEnum.CLIL]}
              isOrganizeMenuOpen={isOrganizeMenuOpen}
              outputIdToScroll={outputIdToScroll}
            />
          ),
      )}
    </>
  )
}

export default AnalyticsOutputLibraryStructureRechart
