import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { SetColorConfig } from '../../../../../../core/config/SetColorConfig'
import { SetColorConfigInterface } from '../../../../../../core/interface/set-color-config.interface'
import { AnalyticVisualThemeEnum } from '../../../../../../core/enums/analytic-visual-theme.enum'

const OrganizeMenuLayoutTitle = styled.div`
  color: #929292;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`

const OrganizeMenuLayoutColorText = styled.div`
  color: #3b3b3b;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: 'Fira Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const OrganizeMenuLayout = ({
  chartColorsSelected,
  handleVisualThemeEdit,
}: {
  chartColorsSelected: SetColorConfigInterface
  handleVisualThemeEdit: (theme: AnalyticVisualThemeEnum) => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <OrganizeMenuLayoutTitle>{t('chartColors')}</OrganizeMenuLayoutTitle>
      <div className="flex flex-wrap gap-2">
        {SetColorConfig.map((colorConfig) => (
          <div
            key={colorConfig.value}
            className="flex flex-col gap-1 h-[185px] w-[160px]"
            onClick={() => handleVisualThemeEdit(colorConfig.id)}
          >
            <div
              className="flex flex-col gap-2 p-2 rounded-lg border border-[#E9E9E9] h-[80px]"
              style={
                colorConfig.value === chartColorsSelected.value
                  ? { borderColor: '#929292', borderWidth: 2 }
                  : {}
              }
            >
              <div className="flex items-center self-stretch gap-1 ">
                <div
                  className="flex-[1_0_0%] h-[32px] rounded"
                  style={{ backgroundColor: colorConfig.color1 }}
                />
                <div
                  className="flex-[1_0_0%] h-[32px] rounded"
                  style={{ backgroundColor: colorConfig.color2 }}
                />
                <div
                  className="flex-[1_0_0%] h-[32px] rounded"
                  style={{ backgroundColor: colorConfig.color3 }}
                />
              </div>
              <OrganizeMenuLayoutColorText key={colorConfig.value}>
                {t(`themeColorsLabel.${colorConfig.id}`)}
              </OrganizeMenuLayoutColorText>
            </div>
            <div className="px-2 py-1">
              {t(`themeColorsText.${colorConfig.id}`)}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default OrganizeMenuLayout
