import { useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as GleephIcon } from '../../../assets/icons/app/gleeph.icon.svg'

const DefaultLayoutStyle = styled.div`
  ${({
    heightLayout,
    widthLayout,
    color,
  }: {
    heightLayout: number
    widthLayout: number
    color: string
  }) => `
    background-color: ${color};
    min-height: ${heightLayout}px !important;
    max-height: ${heightLayout}px !important;
    min-width: ${widthLayout}px !important;
    max-width: ${widthLayout}px !important;
    gap: ${heightLayout / 15}px !important;
  `}
`

const GlImageBook = ({
  pathUrl,
  color = '#595959',
  height = 100,
  width = 70,
}: {
  pathUrl: string
  color?: any
  height?: any
  width?: any
}) => {
  const [displayDefault, setDisplayDefault] = useState<boolean>(false)

  return (
    (!displayDefault && pathUrl && (
      <img
        src={`${pathUrl}`}
        alt="Cover book"
        onError={() => setDisplayDefault(true)}
        style={{
          height,
          width,
          objectFit: 'cover',
        }}
      />
    )) || (
      <DefaultLayoutStyle
        color={color}
        heightLayout={height}
        widthLayout={width}
        className="flex flex-col justify-start items-center"
      >
        <GleephIcon
          className="text-white"
          style={{
            height: height / 2.5,
            width: width / 2.5,
          }}
        />
        <div
          className="flex flex-col items-center justify-center"
          style={{
            gap: height / 15,
          }}
        >
          <div
            className="bg-white rounded-2xl"
            style={{
              height: height / 15,
              width: width / 1.3,
            }}
          />
          <div
            className="bg-white h-2 rounded w-full"
            style={{
              height: height / 20,
              width: width / 2,
            }}
          />
        </div>
      </DefaultLayoutStyle>
    )
  )
}

export default GlImageBook
