import { ReactElement } from 'react'
import Header from './WebAppHeader'
import WebAppNavBar from './WebAppNavBar'
import CustomSnackBar from '../share/snackbar/GlSnackBarMessage'
import RPSnackBar from '../share/snackbar/GlSnackBarRPNotif'

interface Props {
  children: ReactElement
}
const WebAppLayout = ({ children }: Props) => {
  return (
    <div className="w-full h-full flex flex-col bg-webApp overflow-hidden relative">
      <Header />
      <div className="flex-auto flex flex-row">
        <WebAppNavBar />
        <div className="flex-auto flex flex-col">{children}</div>
      </div>
      <CustomSnackBar />
      <RPSnackBar />
    </div>
  )
}

export default WebAppLayout
