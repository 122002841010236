import { RootState } from '../store'
import { DashboardStateInterface } from '../interfaces/dashboard.state.interface'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'

export const selectDashboardState = (
  state: RootState,
): DashboardStateInterface => {
  const { dashboardState } = state
  return dashboardState
}

export const selectDashboardIsLoadingLastReports = (
  state: RootState,
): boolean => {
  const { dashboardState } = state
  return dashboardState.isLoadingLastReports
}

export const selectDashboardLastReports = (
  state: RootState,
): ReportLightResponseInterface[] => {
  const { dashboardState } = state
  return dashboardState.lastReports
}

export const selectDashboardStateCatalogSelected = (
  state: RootState,
): CatalogInterface => {
  const { dashboardState } = state
  return dashboardState.catalogSelected
}

export const selectDashboardStateIsLoadingTrends = (
  state: RootState,
): boolean => {
  const { dashboardState } = state
  return dashboardState.isLoadingTrends
}

export const selectDashboardTrends = (state: RootState): TrendV2Interface[] => {
  const { dashboardState } = state
  return dashboardState.trends
}

export const selectDashboardIsLoadingTopRequestSaved = (
  state: RootState,
): boolean => {
  const { dashboardState } = state
  return dashboardState.isLoadingTopRequestSaved
}
export const selectDashboardTopsRequestSaved = (
  state: RootState,
): PaginationResponseInterface<TopRequestSavedInterface> => {
  const { dashboardState } = state
  return dashboardState.topsRequestSaved
}
