import { CancelTokenSource } from 'axios'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'
import { SortDirectionEnum } from '../../core/enums/sort-direction.enum'
import { TrendsItemFilterActionInterface } from '../../core/interface/dynamics/trends-item-filter-action.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { BookDetailInterface } from '../../core/interface/rank/book-detail.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'

export enum DynamicsResultsViewModeEnum {
  BILLS,
  TABLE,
  CARD,
  COVER,
}
export interface DynamicsResultsStateInterface {
  isLoading: boolean
  viewMode: DynamicsResultsViewModeEnum
  // Catalogs
  cancelTokenGetCatalogs: CancelTokenSource | null
  catalogs: CatalogInterface[]
  // Trends
  cancelTokenGetTrendsByCatalogId: CancelTokenSource | null
  isLoadingTrends: boolean
  trends: TrendV2Interface[]
  // Filters actions
  trendsAggregateByColumn: Set<string | number> | null
  filtersActions: {
    [key: string]:
      | string
      | Array<string | number | null>
      | null
      | DateFilterTypeEnum
  }
  searchActions: string
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  groupSelected: TrendsItemFilterActionInterface | null
  bookSelected: TrendV2Interface | null
  isLoadingBookDetails: boolean
  bookDetails: BookDetailInterface | null
  cancelTokenBookDetails: CancelTokenSource | null
  isLoadingBookDetailsWorkMirror: boolean
  bookDetailsWorkMirror: Partial<AnalyticInputInterface> | null
  cancelTokenBookDetailsWorkMirror: CancelTokenSource | null
  isLoadingRequestsSaved: boolean
  requestsSaved: PaginationResponseInterface<TopRequestSavedInterface>
}

export enum DynamicsResultsDialogTypeEnum {
  NEW,
  SAVE,
}
