import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProps,
} from 'react-beautiful-dnd'
import { arrayMoveImmutable } from 'array-move'
import SortButtons from '../../../share/buttons/SortButtons'
import { SortDirectionEnum } from '../../../../core/enums/sort-direction.enum'
import { ItemsSortActionConfig } from '../../../../core/config/ranks/items-filter-action.config'
import { RankItemActionInterface } from '../../../../core/interface/rank/rank-item-filter-action.interface'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ranksSortsActionUpdated } from '../../../../store/reducers/trends.reducer'
import GlSelect from '../../../share/GlSelect'
import { SelectOptionInterface } from '../../../../core/interface/select-option.interface'
import { ReactComponent as ResetIcon } from '../../../../assets/icons/app/reset.icon.svg'
import { ReactComponent as DragDropIcon } from '../../../../assets/icons/app/drag-drop.icon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/app/close.icon.svg'
import {
  selectRanksSortState,
  selectTrendsState,
} from '../../../../store/getters/trends.getters'
import GlHDivider from '../../../share/GlHDivider'

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

interface Props {
  isOpen?: boolean
  onCloseMenu?: () => void
}

const MenuSortsAction = ({
  isOpen = false,
  onCloseMenu = undefined,
}: Props) => {
  const dispatch = useAppDispatch()
  const ranksState = useAppSelector(selectTrendsState)
  const ranksSortState = useAppSelector(selectRanksSortState)
  const options = [
    {
      value: SortDirectionEnum.ASC,
      label: 'Croissant',
      default: true,
    },
    {
      value: SortDirectionEnum.DESC,
      label: 'Décroissant',
    },
  ] as SelectOptionInterface<SortDirectionEnum>[]
  const [sortDefaultDirection, setSortDefaultDirection] = useState<
    SelectOptionInterface<SortDirectionEnum>
  >(options[0])

  // a little function to help us with reordering the result

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const newList = arrayMoveImmutable(
      ranksSortState,
      result.source.index,
      result.destination.index,
    )
    dispatch(ranksSortsActionUpdated(newList as RankItemActionInterface[]))
  }

  const handleOnChangeDirection = (
    index: number,
    sortDirection: SortDirectionEnum,
  ) => {
    const newList = ranksSortState.map(
      (value: RankItemActionInterface, index1: number) => {
        return {
          ...value,
          sortDirection: index === index1 ? sortDirection : value.sortDirection,
        }
      },
    )
    // setInternalListSort(newList)
    dispatch(ranksSortsActionUpdated(newList))
  }

  return (
    <div className="p-2 flex flex-col relative">
      {(ranksSortState && ranksSortState.length && (
        <button
          type="button"
          className="absolute top-2 right-2 p-1 hover:bg-[#f4f4f4] active:bg-[#e3e3e3] rounded-sm"
          onClick={() => {
            dispatch(ranksSortsActionUpdated([]))
          }}
        >
          <ResetIcon />
        </button>
      )) ||
        null}
      {(ranksSortState && ranksSortState.length && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided) => (
              <div
                className="flex flex-col gap-2"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {ranksSortState.map(
                  (sortAction: RankItemActionInterface, index: number) => (
                    <Draggable
                      key={`sortable-${sortAction.id}`}
                      draggableId={`sortable-${sortAction.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="
                            flex
                            flex-row
                            gap-1
                            items-center
                            text-[#b2b2b2]
                            border-[#b2b2b2]
                            "
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className="
                            flex
                            flex-row
                            border
                            rounded
                            p-2
                            items-center
                            gap-2
                            h-6
                            hover:border-[#595959]
                            hover:bg-[#f4f4f4]
                          "
                          >
                            <div
                              className="icon-drag cursor-grab active:cursor-grabbing"
                              {...provided.dragHandleProps}
                            >
                              <DragDropIcon className="hover:text-[#595959]" />
                            </div>

                            <div className="text-[#595959]">
                              {sortAction.name}
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                const newList = ranksSortState.filter(
                                  (item: RankItemActionInterface) =>
                                    item.id !== sortAction.id,
                                )
                                // setInternalListSort(newList)
                                dispatch(ranksSortsActionUpdated(newList))
                              }}
                            >
                              <CloseIcon className="hover:text-[#595959]" />
                            </button>
                          </div>
                          <SortButtons
                            handleChangeDirection={(sortDirection) =>
                              handleOnChangeDirection(index, sortDirection)
                            }
                            sortDirection={
                              sortAction.sortDirection as SortDirectionEnum
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      )) ||
        ''}
      {(ranksSortState && ranksSortState.length && <GlHDivider />) || ''}
      <div
        style={{
          marginBottom: 4,
        }}
      >
        <GlSelect<SortDirectionEnum>
          optionSelected={sortDefaultDirection}
          options={options}
          handleChangeSelect={(option) => setSortDefaultDirection(option)}
          customStyle={{
            width: '100%',
          }}
        />
      </div>
      {ItemsSortActionConfig.map((sortAction: RankItemActionInterface) => (
        <button
          type="button"
          key={`sortable-item-${sortAction.id}`}
          style={{
            color: '#252525',
          }}
          disabled={
            ranksSortState &&
            !!ranksSortState.find(
              (item: RankItemActionInterface) => item.id === sortAction.id,
            )
          }
          className="flex flex-row gap-2 items-center active:bg-[#e3e3e3] hover:bg-[#f5f5f5] p-2 rounded"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            event.preventDefault()
            if (
              !ranksSortState.some(
                (value: RankItemActionInterface) => value.id === sortAction.id,
              )
            ) {
              const newItem = {
                ...sortAction,
                sortDirection: sortDefaultDirection.value,
              }
              /* setInternalListSort(
                Object.assign([], [...internalListSort, newItem]),
              ) */
              dispatch(
                ranksSortsActionUpdated(
                  Object.assign([], [...ranksSortState, newItem]),
                ),
              )
            }
          }}
        >
          <img src={sortAction.activeIcon} alt="" />
          <div>{sortAction.name}</div>
        </button>
      ))}
    </div>
  )
}

export default MenuSortsAction
