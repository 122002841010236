import { useLocation } from 'react-router-dom'
import React from 'react'
import { nanoid } from 'nanoid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { LegalConfigInterface } from '../../core/config/legal/LegalConfigInterface'
import GlHDivider from '../share/GlHDivider'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import LegalNavType from './LegalNavType'

const LegalNavSidebar = ({ configs }: { configs: any[] }) => {
  const { hash } = useLocation()

  return (
    <PerfectScrollbar className="p-4 min-w-[340px] max-w-fit">
      <div
        className="flex-auto flex flex-col w-full"
        style={{
          height: 'calc(100% -48px)',
        }}
      >
        {configs.map((config: LegalConfigInterface) => {
          return (
            <div key={nanoid(5)}>
              <LegalNavType
                isActive={hash === `#${config.id}`}
                type="main"
                path={buildRelativePath([`${PathConfig.LEGAL}#${config.id}`])}
                content={config.shortTitle}
              />
              <GlHDivider />
              {config.contents.map((content: any, index: number) => {
                return (
                  <React.Fragment key={nanoid(5)}>
                    <LegalNavType
                      isActive={hash === `#${config.id}-${index}`}
                      type={content.type}
                      path={buildRelativePath([
                        `${PathConfig.LEGAL}#${config.id}-${index}`,
                      ])}
                      content={content.shortTitle}
                    />
                    {content.contents &&
                      content.contents.map(
                        (contentChild: any, indexChild: number) => (
                          <LegalNavType
                            key={nanoid(5)}
                            isActive={
                              hash === `#${config.id}-${index}-${indexChild}`
                            }
                            path={buildRelativePath([
                              `${PathConfig.LEGAL}#${config.id}-${index}-${indexChild}`,
                            ])}
                            type={contentChild.type}
                            content={contentChild.content}
                          />
                        ),
                      )}
                  </React.Fragment>
                )
              })}
            </div>
          )
        })}
      </div>
    </PerfectScrollbar>
  )
}

export default LegalNavSidebar
