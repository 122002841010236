import { ReactElement } from 'react'
import { ReactComponent as AuthorIcon } from '../../../../../assets/icons/app/author.icon.svg'
import { ReactComponent as BookIcon } from '../../../../../assets/icons/app/book.icon.svg'
import { ReactComponent as SegmentIcon } from '../../../../../assets/icons/app/segment.icon.svg'

export enum StudyObjectGroupCompareEnum {
  AUTHOR = 'author',
  TITLE = 'books',
  CLIL = 'clil',
}

export interface ReadershipProfileInputTypeInterface<T> {
  icon: ReactElement
  labelKey: string
  value: T
}

export declare type StudyObjectType =
  | StudyObjectGroupCompareEnum.AUTHOR
  | StudyObjectGroupCompareEnum.TITLE
  | null

export declare type CompareGroupType =
  | StudyObjectGroupCompareEnum.AUTHOR
  | StudyObjectGroupCompareEnum.TITLE
  | StudyObjectGroupCompareEnum.CLIL
  | null

export const StudiesObjectsCompareGroupTypesConfig: ReadershipProfileInputTypeInterface<StudyObjectType>[] =
  [
    {
      labelKey: 'OfAOrManyTitles',
      icon: <BookIcon className="w-[48px] h-[48px]" />,
      value: StudyObjectGroupCompareEnum.TITLE,
    },
    {
      labelKey: 'OfAOrManyAuthors',
      icon: <AuthorIcon className="w-[48px] h-[48px]" />,
      value: StudyObjectGroupCompareEnum.AUTHOR,
    },
  ]

export const GroupsCompareTypesConfig: ReadershipProfileInputTypeInterface<CompareGroupType>[] =
  [
    {
      labelKey: 'OfAOrManyTitles',
      icon: <BookIcon className="w-[48px] h-[48px]" />,
      value: StudyObjectGroupCompareEnum.TITLE,
    },
    {
      labelKey: 'OfAOrManyAuthors',
      icon: <AuthorIcon className="w-[48px] h-[48px]" />,
      value: StudyObjectGroupCompareEnum.AUTHOR,
    },

    {
      labelKey: 'OfAOrManyCategories',
      icon: <SegmentIcon className="w-[48px] h-[48px]" />,
      value: StudyObjectGroupCompareEnum.CLIL,
    },
  ]
