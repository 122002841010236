import { useState } from 'react'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { TagStyle, EllipsisDivStyle } from './RecapInputStyles'
import { ReactComponent as AuthorIcon } from '../../../../../../assets/icons/app/author.icon.svg'
import RecapToolTip from './RecapToolTip'
import { StudyObjectGroupCompareEnum } from '../../configs/StudiesObjectsCompareGroupTypesConfig'

const RecapInputTagAuthors = ({
  value,
  color,
  menuPositionTop,
  booksSelected,
}: {
  value: AuthorInterface[]
  color: string
  menuPositionTop: boolean
  booksSelected: BookInterface[]
}) => {
  const [showAllBooks, setShowAllBooks] = useState<boolean>(false)
  const [mousePosX, setMousePosX] = useState<number>(0)
  const [mousePosY, setMousePosY] = useState<number>(0)
  const multipleAuthors = value.map((author) => author.name).join(', ')
  return (
    <div
      className="flex flex-row items-center gap-2"
      onMouseEnter={(event) => {
        setMousePosX(event.clientX)
        setMousePosY(event.clientY)
        setShowAllBooks(true)
      }}
      onMouseLeave={() => setShowAllBooks(false)}
    >
      {value.slice(0, 1).map((authorInput: AuthorInterface) => (
        <TagStyle
          color={color}
          className="flex flex-row items-center justify-start py-1 px-2 rounded gap-2"
        >
          <AuthorIcon
            className="flex flex-none"
            style={{
              width: 20,
              height: 20,
            }}
          />
          <EllipsisDivStyle className="flex flex-auto">
            <span>{authorInput.name}</span>
          </EllipsisDivStyle>
        </TagStyle>
      ))}
      {value.length > 1 && (
        <TagStyle
          color={color}
          className="flex flex-row items-center justify-center py-1 px-2 rounded gap-2"
        >
          <span>+</span>
          <span>{value.length - 1}</span>
        </TagStyle>
      )}
      {showAllBooks && (
        <RecapToolTip
          type={StudyObjectGroupCompareEnum.AUTHOR}
          objectsSelected={booksSelected}
          menuPositionTop={menuPositionTop}
          mousePosX={mousePosX}
          mousePosY={mousePosY}
          author={multipleAuthors}
        />
      )}
    </div>
  )
}

export default RecapInputTagAuthors
