import { useTranslation } from 'react-i18next'
import GlHDivider from '../../../../../../share/GlHDivider'
import { analyticsDetailsV2StatePutPreviewAction } from '../../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { useAppDispatch } from '../../../../../../../store/hooks'
import { ReadershipProfilePreviewComparisonInterface } from '../../../../../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import { EditSectionEnum } from '../../../../../../../core/enums/analytic-edit-section.enum'
import { EditOptionTypeEnum } from '../../../../../../../core/enums/analytic-edit-option-type.enum'
import EditMenuSection from './EditMenuSection'

const EditMenuPreview = ({
  previewModule,
}: {
  previewModule?: ReadershipProfilePreviewComparisonInterface[]
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const previewModuleOptions = previewModule?.map(
    (card: ReadershipProfilePreviewComparisonInterface) => {
      return {
        id: card.id,
        section: EditSectionEnum.DATAS,
        label: t(`EditReadershipProfil.${card.type}`),
        type: EditOptionTypeEnum.TOGGLE,
        value: card.is_visible,
      }
    },
  )

  const handlerToggleSetOptionToDisplay = (
    optionId: string,
    value: boolean,
  ) => {
    void dispatch(
      analyticsDetailsV2StatePutPreviewAction({
        cardId: optionId,
        isVisible: value,
      }),
    )
  }

  return (
    <>
      <GlHDivider />
      <EditMenuSection
        sectionTitle={t('EditReadershipProfil.data')}
        section={EditSectionEnum.DATAS}
        options={previewModuleOptions}
        handleOnToggle={handlerToggleSetOptionToDisplay}
      />
    </>
  )
}

export default EditMenuPreview
