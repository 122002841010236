import { createAsyncThunk } from '@reduxjs/toolkit'
import { getShareAnalyticDetailsBySlugService } from '../../core/api/services/bookmetrie/get-share-analytic-details-by-slug.service'
import {
  SHARE_ANALYTIC_DETAILS_GET_ANALYTIC_BY_SLUG_ID_ACTION,
  SHARE_ANALYTICS_DETAILS_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  SHARE_ANALYTICS_DETAILS_STATE_GET_BOOK_DETAIL_DATA_ACTION,
} from '../prefix-actions.config'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import { RootState } from '../store'
import { WorkMirrorBookInterface } from '../../core/interface/report/work-mirror-book.interface'
import { getB2bBookDetailsByEanIdService } from '../../core/api/services/others/get-b2b-book-details-by-ean-id.service'
import { BookDetailResponseInterface } from '../../core/interface/rank/book-detail.interface'

export const shareAnalyticDetailsGetShareAnalyticBySlugAction =
  createAsyncThunk(
    SHARE_ANALYTIC_DETAILS_GET_ANALYTIC_BY_SLUG_ID_ACTION,
    async (slug: string, { getState }): Promise<AnalyticDetailsInterface> => {
      try {
        const { shareAnalyticDetailsState } = getState() as RootState
        const { cancelTokenGetShareAnalyticBySlug } = shareAnalyticDetailsState
        const { data } = await getShareAnalyticDetailsBySlugService(
          slug,
          cancelTokenGetShareAnalyticBySlug?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const shareAnalyticDetailsStateGetB2BBookByEanId = createAsyncThunk(
  SHARE_ANALYTICS_DETAILS_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  async (eanId: number, { getState }): Promise<BookDetailResponseInterface> => {
    try {
      const { shareAnalyticDetailsState } = getState() as RootState
      const { cancelTokenGetB2BBookDetails } = shareAnalyticDetailsState

      const { data } = await getB2bBookDetailsByEanIdService(
        eanId,
        cancelTokenGetB2BBookDetails?.token,
      )

      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const shareAnalyticDetailsV2StateGetBookDetailsData = createAsyncThunk(
  SHARE_ANALYTICS_DETAILS_STATE_GET_BOOK_DETAIL_DATA_ACTION,
  async (
    { bookDetails }: { bookDetails: WorkMirrorBookInterface | null },
    { dispatch },
  ): Promise<any> => {
    try {
      if (bookDetails) {
        void dispatch(
          shareAnalyticDetailsStateGetB2BBookByEanId(bookDetails.id_ean),
        )
      }
      const data = await new Promise((resolve) => {
        resolve(bookDetails)
      })
      return data
    } catch (error) {
      throw new Error('')
    }
  },
)
