import { DatasetModuleInterface } from '../interface/analytics/dataset-module.interface'
import { ValueConfigurationsInterface } from '../interface/analytics/value-configurations.interface'
import { AnalyticsChartTypeEnum } from '../enums/analytics-chart-type.enum'

export const UpdateDatasetModuleByToggle = (
  datasetModule: DatasetModuleInterface,
  optionId: string,
  value: boolean,
) => {
  return {
    ...datasetModule,
    values_configurations: datasetModule.values_configurations.map(
      (value_configuration: ValueConfigurationsInterface) => {
        if (value_configuration.source === optionId) {
          if (
            value_configuration.target_axis &&
            datasetModule.type !== AnalyticsChartTypeEnum.BUBBLE_CHART &&
            datasetModule.type !== AnalyticsChartTypeEnum.SCATTER_PLOT &&
            datasetModule.type !== AnalyticsChartTypeEnum.CONSTELLATION
          ) {
            return {
              ...value_configuration,
              show_in_chart: value,
            }
          }
          return {
            ...value_configuration,
            is_visible: value,
          }
        }
        return value_configuration
      },
    ),
    show_table: optionId === 'show_table' ? value : datasetModule.show_table,
  }
}
