import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { PermissionEnum } from '../../../../enums/permission.enum'

export const putPermissionReportToUserService = (
  reportId: string,
  userId: string,
  permission: PermissionEnum,
  cancelToken?: CancelToken,
) => {
  return apiInstance.put(
    ApiPathConfig.REPORT_BY_ID_USERS_BY_ID(reportId, userId),
    { permission },
    { cancelToken },
  )
}
