import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ShareAnalyticDetailsBookDetails from './ShareAnalyticDetailsBookDetails'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import ShareAnalyticDetailsWorkMirrorList from './ShareAnalyticDetailsWorkMirrorList'
import ShareAnalyticDetailsWorkMirrorConstellationCard from './ShareAnalyticDetailsWorkMirrorConstellationCard'
import {
  selectShareAnalyticDetailsStateAnalyticDetails,
  selectShareAnalyticDetailsStateBookDetailsSelected,
  selectShareAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  selectShareAnalyticDetailsStateGetB2BBookDetailsError,
  selectShareAnalyticDetailsStateGetB2BBookDetailsSuccess,
  selectShareAnalyticDetailsStateIsLoadingBookDetailsSelected,
  selectShareAnalyticDetailsStateIsLoadingGetB2BBookDetails,
} from '../../../../store/getters/share-analytic-details.getters'
import { shareAnalyticDetailsV2StateGetBookDetailsData } from '../../../../store/actions/share-analytic-details.actions'
import GlDateManager from '../../../share/GlDateManager'
import GlLegalText from '../../../share/GlLegalText'

const ShareAnalyticDetailsWorkMirrorView = () => {
  const { t } = useTranslation()
  const [openListCard, setOpenListCard] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const isLoadingBookDetailsSelected = useAppSelector(
    selectShareAnalyticDetailsStateIsLoadingBookDetailsSelected,
  )

  const bookDetailsSelected = useAppSelector(
    selectShareAnalyticDetailsStateBookDetailsSelected,
  )

  const isLoadingGetB2BBookDetails = useAppSelector(
    selectShareAnalyticDetailsStateIsLoadingGetB2BBookDetails,
  )

  const bookDetailsSelectedB2BInfo = useAppSelector(
    selectShareAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  )
  const getB2BBookDetailsSuccess = useAppSelector(
    selectShareAnalyticDetailsStateGetB2BBookDetailsSuccess,
  )
  const getB2BBookDetailsError = useAppSelector(
    selectShareAnalyticDetailsStateGetB2BBookDetailsError,
  )

  const analyticsDetails = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetails,
  )

  const handleOpenListView = () => {
    setOpenListCard(!openListCard)
  }

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(
      shareAnalyticDetailsV2StateGetBookDetailsData({ bookDetails: null }),
    )
  }, [bookDetailsSelected, bookDetailsSelectedB2BInfo])

  const handleCloseList = () => {
    setOpenListCard(false)
  }

  const [bookEanIdHovered, setBookEanIdHovered] = useState<number | null>(null)
  const handleBookHovered = useCallback(
    (eanId: number | null) => {
      setBookEanIdHovered(eanId)
    },
    [bookEanIdHovered],
  )

  return (
    <div className="flex-auto flex flex-col p-8 gap-2">
      <GlDateManager
        prefix={t('ReportReaderShipProfileDate')}
        date={analyticsDetails.modified}
      />
      <div className="flex-auto flex flex-row gap-2">
        <ShareAnalyticDetailsWorkMirrorConstellationCard
          openListCard={openListCard}
          handleOpenListView={handleOpenListView}
          handleBookHovered={handleBookHovered}
        />
        {(bookDetailsSelected && (
          <ShareAnalyticDetailsBookDetails
            isLoadingGetBookDetails={isLoadingBookDetailsSelected}
            isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
            bookDetailsSelected={bookDetailsSelected}
            bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
            openListCard={openListCard}
            handleCloseBookDetails={handleCloseBookDetails}
          />
        )) ||
          (openListCard && (
            <ShareAnalyticDetailsWorkMirrorList
              bookEanIdHovered={bookEanIdHovered}
              handleCloseList={handleCloseList}
            />
          )) ||
          null}
      </div>
      <GlLegalText />
    </div>
  )
}

export default ShareAnalyticDetailsWorkMirrorView
