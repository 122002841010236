import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'

export const getAnalyticsLightNextService = (
  path: string,
  cancelToken?: CancelToken,
) => {
  const url = new URL(path)
  return apiInstance.get(url.pathname + url.search, {
    cancelToken,
  })
}
