import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BackGroundAnalyticsDashboard from '../../assets/icons/background-analytic-dashboard.svg'
import GlToggleButton, {
  ToggleButtonOptionInterface,
} from '../share/GlToggleButton'
import GlToggleIconButton from '../share/GlToggleIconButton'
import GlLoader from '../share/GlLoader'
import AnalyticsDashboardCardView from './card-view/AnalyticsDashboardCardView'
import AnalyticsDashboardListView from './table-view/AnalyticsDashboardListView'
import GlDialog from '../share/dialog/GlDialog'
import AnalyticsDashboardAddAnalyticsDialog from './AnalyticsDashboardAddAnalyticsDialog'
import GlSlideToggle from '../share/GlSlideToggle'
import { ReportLightResponseInterface } from '../../core/interface/report/report-light-response.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { ReactComponent as CardViewIcon } from '../../assets/icons/app/card-view.icon.svg'
import { ReactComponent as TableViewIcon } from '../../assets/icons/app/table-view.icon.svg'
import AnalyticsDashboardAvailableModuleType from '../../core/types/analytics-dashboard-available-module.interface'
import AnalyticsDashboardModuleInterface from '../../core/interface/analytics-dashboard/analytics-dashboard-module.interface'
import {
  AnalyticsTypeEnum,
  PrivateAnalyticsList,
} from '../../core/enums/analytics-type.enum'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import AnalyticsDashboardType from '../../core/enums/analytics-dashboard-type.enum'

export enum SwitchViewAnalyticsEnum {
  CARDS = 'CARDS',
  LIST = 'LIST',
}
const AnalyticsDashboardLayout = styled.div`
  padding: 32px;
`

type Props = {
  reports: PaginationResponseInterface<ReportLightResponseInterface> | null
  isLoadingReports: boolean
  analyticsTypesOptions: ToggleButtonOptionInterface<AnalyticsDashboardAvailableModuleType>[]
  dashboardModules: Array<AnalyticsDashboardModuleInterface>
  handleReloadAction: () => void
  companyUserId: string
  isLoadingMySelf?: boolean
  availableTokens?: number
  isPrivate?: boolean
}

const AnalyticsDashBoard = ({
  reports,
  isLoadingReports,
  analyticsTypesOptions,
  dashboardModules,
  handleReloadAction,
  companyUserId,
  isLoadingMySelf,
  availableTokens,
  isPrivate,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [displayOnlyMyAnalytics, setDisplayOnlyMyAnalytics] =
    useState<boolean>(false)

  const [addNewAnalyticsDialog, setAddNewAnalyticsDialog] =
    useState<boolean>(false)

  const [viewTypeSelected, setViewTypeSelected] =
    useState<SwitchViewAnalyticsEnum>(SwitchViewAnalyticsEnum.CARDS)

  const [analyticsTypeSelected, setAnalyticsTypeSelected] =
    useState<AnalyticsDashboardAvailableModuleType>(AnalyticsDashboardType.ALL)

  const handleCreateNewAnalytics = () => setAddNewAnalyticsDialog(true)

  const handleOnSelectViewType = (value: SwitchViewAnalyticsEnum) => {
    setViewTypeSelected(value)
  }

  const handleOnSelectAnalyticType = (
    value: AnalyticsDashboardAvailableModuleType,
  ) => {
    setAnalyticsTypeSelected(value)
  }

  const handleClickAnalytics = (id: string, type: AnalyticsTypeEnum) => {
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        ...(PrivateAnalyticsList.includes(type)
          ? [PathConfig.PRIVATE_REPORTS, type]
          : [PathConfig.ANALYTICS]),
        id,
      ]),
    )
  }

  return (
    <>
      <AnalyticsDashboardLayout className="flex-auto flex flex-col">
        <div
          className="bg-white flex flex-col flex-auto rounded-lg bg-no-repeat"
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
            backgroundImage: reports?.count
              ? 'none'
              : `url('${BackGroundAnalyticsDashboard}')`,
            backgroundPosition: '30% 340px',
          }}
        >
          <div className="bg-white flex flex-row justify-between items-center m-4">
            {isPrivate ? (
              <div className="w-20 h-1" />
            ) : (
              <GlSlideToggle
                label={t('webApp.reports.dashboard.myAnalyticsOnly')}
                handleOnToggle={() =>
                  setDisplayOnlyMyAnalytics(!displayOnlyMyAnalytics)
                }
              />
            )}

            <GlToggleButton<AnalyticsDashboardAvailableModuleType>
              options={analyticsTypesOptions}
              optionSelected={analyticsTypeSelected}
              handleOnSelect={handleOnSelectAnalyticType}
            />
            <GlToggleIconButton<SwitchViewAnalyticsEnum>
              options={[
                {
                  icon: <CardViewIcon className="w-[18px] h-[18px]" />,
                  value: SwitchViewAnalyticsEnum.CARDS,
                },
                {
                  icon: <TableViewIcon className="w-[18px] h-[18px]" />,
                  value: SwitchViewAnalyticsEnum.LIST,
                },
              ]}
              handleOnSelect={handleOnSelectViewType}
              optionSelected={viewTypeSelected}
            />
          </div>
          {(isLoadingReports && (
            <div className="bg-white flex-auto flex flex-col items-center justify-center m-4">
              <GlLoader />
            </div>
          )) || (
            <div className="relative flex-auto flex flex-col">
              {viewTypeSelected === SwitchViewAnalyticsEnum.CARDS && (
                <PerfectScrollbar className="flex-auto mx-4">
                  <AnalyticsDashboardCardView
                    analytics={reports}
                    analyticsTypeSelected={analyticsTypeSelected}
                    displayOnlyMyAnalytics={displayOnlyMyAnalytics}
                    handleCreateNewAnalytics={handleCreateNewAnalytics}
                    handleClickAnalytics={handleClickAnalytics}
                    handleReloadAction={handleReloadAction}
                    companyUserId={companyUserId}
                    isPrivate={isPrivate}
                  />
                </PerfectScrollbar>
              )}
              {viewTypeSelected === SwitchViewAnalyticsEnum.LIST && (
                <AnalyticsDashboardListView
                  analytics={reports}
                  analyticsTypeSelected={analyticsTypeSelected}
                  displayOnlyMyAnalytics={displayOnlyMyAnalytics}
                  handleCreateNewAnalytics={handleCreateNewAnalytics}
                  handleClickAnalytics={handleClickAnalytics}
                  handleReloadAction={handleReloadAction}
                  companyUserId={companyUserId}
                  isPrivate={isPrivate}
                />
              )}
            </div>
          )}
        </div>
      </AnalyticsDashboardLayout>
      <GlDialog
        open={addNewAnalyticsDialog}
        onClose={() => setAddNewAnalyticsDialog(false)}
      >
        <AnalyticsDashboardAddAnalyticsDialog
          dashboardModules={dashboardModules}
          isLoadingMySelf={isLoadingMySelf}
          availableTokens={availableTokens}
          isPrivate={isPrivate}
          handleCloseDialog={() => setAddNewAnalyticsDialog(false)}
        />
      </GlDialog>
    </>
  )
}

export default AnalyticsDashBoard
