import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import { clilReferencesConfig } from '../../../../../../../../core/config/clil-references.config'
import { DatasetTypeEnum } from '../../../../../../../../core/enums/dataset-type.enum'

const LegendRoundStyle = styled.div<{ borderColor?: string }>`
  border: 1px solid ${({ borderColor = '#BEBEBE' }) => borderColor};
  border-radius: 50%;
  padding: 10px;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LegendLabelStyle = styled.div`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

type Props = {
  dataset: Array<DatasetInterface>
  typeDataset: DatasetTypeEnum
  displayClilColors?: boolean
}

const CustomLegendMulti = ({
  dataset,
  typeDataset,
  displayClilColors,
}: Props) => {
  const { t } = useTranslation()
  return (
    (dataset && dataset.length > 0 && (
      <div className="w-full relative flex gap-4 justify-center items-center flex-wrap">
        {dataset.map(({ clil, age_range }, index) =>
          typeDataset === DatasetTypeEnum.CLIL && clil ? (
            <div
              key={`${clil} ${age_range}`}
              className="gap-2 flex justify-center items-center"
            >
              <LegendRoundStyle
                borderColor={
                  displayClilColors
                    ? clilReferencesConfig[clil].color
                    : '#BEBEBE'
                }
              >
                <span>{index + 1}</span>
              </LegendRoundStyle>
              <LegendLabelStyle>
                {clilReferencesConfig[clil].libelle}
              </LegendLabelStyle>
            </div>
          ) : (
            typeDataset === DatasetTypeEnum.AGE &&
            age_range && (
              <div
                key={`${clil} ${age_range}`}
                className="gap-2 flex justify-center items-center"
              >
                <LegendRoundStyle>
                  <span>{index + 1}</span>
                </LegendRoundStyle>
                <LegendLabelStyle>
                  {age_range} {t('years')}
                </LegendLabelStyle>
              </div>
            )
          ),
        )}
      </div>
    )) ||
    null
  )
}

export default CustomLegendMulti
