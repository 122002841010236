import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  SVGProps,
  useState,
} from 'react'
import styled from '@emotion/styled'

const LabelStyle = styled.div`
  font-weight: ${({ enable }: { enable: boolean }) =>
    enable ? 'bold !important' : '400 !important'};
  color: var(--grayscale-gray-3, #666);
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const BarreStyle = styled.div`
  height: 18px;
  width: 32px;
  border-radius: 24px;
  background: ${({ enable }: { enable: boolean }) =>
    enable ? '#252525' : '#bebebe'};
`

const ButtonStyle = styled.button`
  background-color: ${({ enable }: { enable: boolean }) =>
    enable ? '#252525' : 'transparent'};
  color: ${({ enable }: { enable: boolean }) => (enable ? 'white' : '#929292')};
  border-radius: 4px;
  padding: 8px;
`

export interface SlideToggleOptionInterface<T> {
  // eslint-disable-next-line react/no-unused-prop-types
  icon: ReactElement
  // eslint-disable-next-line react/no-unused-prop-types
  value: T
}

const GlToggleIconButton = <T,>({
  handleOnSelect,
  optionSelected,
  options,
}: {
  handleOnSelect: (value: T) => void
  optionSelected: T
  options: SlideToggleOptionInterface<T>[]
}) => {
  return (
    <div
      className="flex flex-row items-center justify-center gap-2 bg-[#f7f7f7]"
      style={{
        padding: 2,
        borderRadius: 4,
      }}
    >
      {options.map(({ value, icon }: SlideToggleOptionInterface<T>) => (
        <ButtonStyle
          key={`analytics-switch-view-${value}`}
          type="button"
          enable={value === optionSelected}
          onClick={(event: React.MouseEvent) => {
            event.preventDefault()
            handleOnSelect(value)
          }}
        >
          {icon}
        </ButtonStyle>
      ))}
    </div>
  )
}

export default GlToggleIconButton
