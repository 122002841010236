import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../../../../../store/hooks'
import { analyticsDetailsV2StatePutTextBoxesOutputAction } from '../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { AnalyticOutputInterface } from '../../../../../core/interface/analytics/analytic-output.interface'
import GlEditableInput from '../../../../share/GlEditableInput'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import AnalyticsOutputWrapper from './AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../core/enums/analytic-output-layout-full-height.enum'

const TextStyleInput = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  color: #3b3b3b;
  white-space: pre-wrap;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 4px 4px 0px;
  &:disabled {
    background-color: white;
  }
  &:hover:enabled {
    ${({ backgroundColorOnHover }: { backgroundColorOnHover: string }) =>
      `background-color:  ${backgroundColorOnHover} !important`}
  }
  &:focus:enabled {
    ${({ backgroundColorOnHover }: { backgroundColorOnHover: string }) =>
      `background-color:  ${backgroundColorOnHover} !important`}
  }
`

const AnalyticsOutputTextBox = ({
  output,
  display,
  editMode = false,
  handleAnalyticsDeleteOutput,
  isOrganizeMenuOpen,
}: {
  output: AnalyticOutputInterface
  display: AnalyticOutputLayoutSizeEnum
  editMode?: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  isOrganizeMenuOpen: boolean
}) => {
  const dispatch = useAppDispatch()
  const [inputTitleValue, setInputTitleValue] = useState<string>(
    output.textbox?.title || '',
  )
  const [inputTextValue, setInputTextValue] = useState<string>(
    output.textbox?.text || '',
  )
  const [inputFocus, setInputFocus] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (
      textAreaRef.current &&
      output.layout_size === AnalyticOutputLayoutSizeEnum.FULL
    ) {
      textAreaRef.current.style.height = '0px'
      const { scrollHeight } = textAreaRef.current
      textAreaRef.current.style.height = `${scrollHeight}px`
    }
  }, [textAreaRef, inputTextValue, output.layout_size])

  const handleRenameButton = () => {
    setInputFocus(false)
    if (
      !output.layout_size ||
      inputTitleValue.length === 0 ||
      inputTextValue.length === 0 ||
      (inputTitleValue === output.textbox?.title &&
        inputTextValue === output.textbox?.text)
    )
      return
    void dispatch(
      analyticsDetailsV2StatePutTextBoxesOutputAction({
        outputId: output.id,
        textBoxesObject: {
          title: inputTitleValue,
          text: inputTextValue,
          layout_size: output.layout_size,
          use_dark_theme: output.textbox?.use_dark_theme || false,
          text_size: output.textbox?.text_size || 16,
        },
      }),
    )
  }

  const getBackgroundColor = () => {
    if (output.textbox?.use_dark_theme) {
      if (inputFocus) {
        return '#505050'
      }
      return '#3B3B3B'
    }
    if (inputFocus) {
      return '#E9E9E9'
    }
    return 'white'
  }

  return (
    <AnalyticsOutputWrapper
      outputId={output.id}
      display={display}
      output={output}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX}
      customStyle={{
        flexDirection: 'row-reverse',
        backgroundColor: getBackgroundColor(),
      }}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      type={
        (output.layout_size !== AnalyticOutputLayoutSizeEnum.FULL &&
          SizableHeightAnalyticOutput.TEXTBOX) ||
        undefined
      }
      shrink={isOrganizeMenuOpen}
    >
      <div className="flex flex-col w-full">
        <GlEditableInput
          inputValue={inputTitleValue}
          setInputValue={setInputTitleValue}
          editMode={editMode}
          actionOnBlur={handleRenameButton}
          setFocus={setInputFocus}
          customStyle={{
            backgroundColor: getBackgroundColor(),
            color: output.textbox?.use_dark_theme ? 'white' : '',
            fontSize: `${output.textbox?.text_size}px`,
          }}
        />
        <TextStyleInput
          ref={textAreaRef}
          value={inputTextValue}
          onChange={(event) => setInputTextValue(event.target.value)}
          onBlur={handleRenameButton}
          onFocus={() => setInputFocus(true)}
          disabled={!editMode}
          backgroundColorOnHover={
            output.textbox?.use_dark_theme ? '#404040' : '#F7F7F7'
          }
          style={{
            backgroundColor: getBackgroundColor(),
            color: output.textbox?.use_dark_theme ? 'white' : '#3B3B3B',
            fontSize: `${output.textbox?.text_size}px`,
            ...((output.layout_size === AnalyticOutputLayoutSizeEnum.FULL && {
              resize: 'none',
              overflow: 'hidden',
            }) || { flex: 1 }),
          }}
        />
      </div>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputTextBox
