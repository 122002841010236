import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const CheckboxLayout = styled.label`
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;

  :hover {
    background-color: #e3e3e3;
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checker {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #252525;
    box-sizing: content-box;
  }

  &:hover input ~ .checker {
    background-color: #e3e3e3;
  }

  input:checked ~ .checker {
    background-color: #e3e3e3;
  }

  .checker:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checker:after {
    display: block;
    width: 16px;
    height: 16px;
    border: 3px solid white;
    background: #252525;
  }

  input:indeterminate ~ .checker:after {
    display: block;
    width: 16px;
    height: 16px;
    border-color: white;
    border-style: solid;
    border-width: 6px 3px;
    background: #252525;
  }
`

const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`
interface Props {
  label?: string | number
  value: 'checked' | 'empty' | 'indeterminate'
  onChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void
  children?: ReactElement | null
}

const GlInputCheckBox = ({
  label,
  value,
  onChangeEvent,
  children = null,
}: Props): ReactElement => {
  const { t } = useTranslation()
  const checkboxRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (checkboxRef.current) {
      if (value === 'checked') {
        checkboxRef.current.checked = true
        checkboxRef.current.indeterminate = false
      } else if (value === 'empty') {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = false
      } else if (value === 'indeterminate') {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = true
      }
    }
  }, [value])

  return (
    <CheckboxLayout className="flex flex-row items-center rounded custom-checkbox p-2 gap-2">
      {children || <Label>{t(label as string)}</Label>}
      <input ref={checkboxRef} type="checkbox" onChange={onChangeEvent} />
      <span className="checker" />
    </CheckboxLayout>
  )
}

export default GlInputCheckBox
