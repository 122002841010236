import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import React, { useState, useEffect, memo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CatalogInterface } from '../../../../core/interface/rank/catalog.interface'
import { ReactComponent as LittleArrowBottomArrowIcon } from '../../../../assets/icons/app/bottom.icon.svg'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import IconInterface from '../../../../core/interface/rank/icon.interface'

const CatalogsFilterContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #252525;
  width: 300px;
  height: 32px;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #252525;
  padding: 8px;
  width: 300px;
  height: 32px;
  border-radius: 4px;
`

const MenuStyle = styled.div`
  z-index: 35;
  position: absolute;
  top: 36px;
  left: 0;
  height: ${({ open }: { open: boolean }) => (open ? 'auto' : '0')};
  max-height: ${({ open }: { open: boolean }) => (open ? '334px' : '0')};
  visibility: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }: { open: boolean }) => (open ? '1' : '0')};
  background: white;
  width: 100%;
  border-radius: 4px;
  -webkit-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
`

const CatalogNameStyle = styled.div`
  background-repeat: no-repeat;
  height: 16px;
  padding: 0 2px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const IconStyle = styled.div`
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 4px;
  padding: 2px;
`
const DynamicsResultsCatalogsFilters = ({
  catalogIdSelected,
  catalogs,
  handleChangeQuery,
  menuKey,
  menuOpen,
  handleMenuOpen,
}: {
  catalogIdSelected: string
  catalogs: CatalogInterface[]
  handleChangeQuery: (key: any, value: any) => void
  menuKey: string
  menuOpen: string | null
  handleMenuOpen: (value: string | null) => void
}) => {
  let timer: NodeJS.Timeout | null = null
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen === menuKey) {
        handleMenuOpen(null)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer, menuOpen])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    handleMenuOpen(menuKey)
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  const getCatalogById = (): CatalogInterface | undefined =>
    catalogs.find(
      (catalog: CatalogInterface) => catalog.id === catalogIdSelected,
    )

  return (
    <CatalogsFilterContainerStyle
      className="flex flex-col relative bg-white catalog-filter"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <LabelStyle className="w-full h-full flex flex-row justify-between items-center">
        {!!getCatalogById() && (
          <div className="flex flex-row gap-[2px] items-center justify-start">
            {getCatalogById()?.icons.map((iconSelect?: IconInterface) => (
              <IconStyle
                key={`catalog-icon-item-filter-${getCatalogById()?.id}-${
                  iconSelect?.color
                }`}
                color={iconSelect?.color as string}
              >
                <img
                  width={20}
                  height={20}
                  src={iconSelect?.icon}
                  alt="Icon catalog"
                />
              </IconStyle>
            ))}

            <CatalogNameStyle className="flex flex-1">
              <span>{getCatalogById()?.name}</span>
            </CatalogNameStyle>
          </div>
        )}
        <LittleArrowBottomArrowIcon className="text-[#b2b2b2]" />
      </LabelStyle>
      <MenuStyle open={menuOpen === menuKey} className="shadow-2xl z-30">
        <PerfectScrollbar
          className="w-full flex flex-col"
          style={{
            maxHeight: 234,
          }}
        >
          {catalogs.map((catalog: CatalogInterface, index: number) => (
            <div
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
                handleChangeQuery('catalogId', catalog.id)
              }}
              className={`
                flex
                justify-between
                h-7
                items-center
                px-3
                py-[4.5px]
                ${(catalogIdSelected === catalog.id && 'bg-[#e3e3e3]') || ''}
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
                ${(index === 0 && 'rounded-t-[4px]') || ''}
              `}
              key={`catalog-item-filter-request-${catalog.id}`}
            >
              <div className="flex flex-row gap-[2px] items-center justify-start">
                {catalog.icons.map((icon: IconInterface) => (
                  <IconStyle key={nanoid(5)} color={icon.color}>
                    <img
                      className={`bg-[#${icon.color}]`}
                      width={20}
                      height={20}
                      src={icon.icon}
                      alt="Icon catalog"
                    />
                  </IconStyle>
                ))}

                <CatalogNameStyle>
                  <span>{catalog.name}</span>
                </CatalogNameStyle>
              </div>
              {catalogIdSelected === catalog.id && <SelectedIcon />}
            </div>
          ))}
        </PerfectScrollbar>
      </MenuStyle>
    </CatalogsFilterContainerStyle>
  )
}

export default memo(DynamicsResultsCatalogsFilters)
