import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { BookInterface } from '../../../interface/book.interface'

export const getBookByEanIdService = (
  eanId: string | number,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<BookInterface>> =>
  apiInstance.get(ApiPathConfig.BOOK_DETAIL_BY_EAN_ID(eanId), { cancelToken })
