import React from 'react'
import styled from '@emotion/styled'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'

const InitialNameStyle = styled.span`
  text-align: center;
  color: #3b3b3b;
  /* Body/B1 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`

const AuthorNameStyle = styled.span`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Body/B2 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const AuthorItemStyle = styled.button`
  background: ${({ isSelectable }: { isSelectable: boolean }) =>
    !isSelectable ? '#e3e3e3' : ''};
  cursor: ${({ isSelectable }: { isSelectable: boolean }) =>
    isSelectable ? 'pointer' : 'default'};

  :hover {
    background: ${({ isSelectable }: { isSelectable: boolean }) =>
      isSelectable ? '#f7f7f7' : ''};
  }

  :not(:disabled):active {
    background: #e3e3e3;
  }
  :focus {
    outline: none;
  }
`

const AuthorItemMenu = ({
  author,
  handleOnClickItem,
}: {
  author: AuthorInterface
  handleOnClickItem: (author: AuthorInterface) => void
}) => {
  const onClickAuthorItem = (event: React.MouseEvent) => {
    event.preventDefault()
    if (author.is_profilable) {
      handleOnClickItem(author)
    }
  }

  const getInitial = (): string | null => {
    const words = author.name.replace(/[!@#$%^&*_.()]/g, '').split(' ')
    if (words.length === 1) {
      const oneWords = words[0].split('-')
      if (oneWords.length > 1) {
        return oneWords.map((value: string) => value.charAt(0)).join('')
      }
      return words[0].slice(0, 2)
    }
    return words
      .slice(0, 2)
      .map((value: string) => value.charAt(0))
      .join('')
  }

  return (
    <AuthorItemStyle
      key={`author-item-${author.name}`}
      type="button"
      className="flex flex-row rounded min-h-[48px] max-h-[48px] items-center justify-center gap-1 p-1"
      isSelectable={author.is_profilable}
      onClick={onClickAuthorItem}
    >
      <div
        className="rounded bg-[#f7f7f7] flex flex-none items-center justify-center"
        style={{
          width: 40,
          height: 40,
        }}
      >
        <InitialNameStyle>{getInitial()}</InitialNameStyle>
      </div>
      <div className="flex-1 flex items-center justify-start">
        <AuthorNameStyle>{author.name}</AuthorNameStyle>
      </div>
    </AuthorItemStyle>
  )
}

export default AuthorItemMenu
