import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SignInStateInterface } from '../interfaces/sign-in.state.interface'
import { postSignInAction } from '../actions/sign-in.actions'

const initialState: SignInStateInterface = {
  isLoadingPostSignIn: false,
  signInSuccess: false,
  signInError: false,
  signInMessageError: '',
}

export const signInSlice = createSlice({
  name: 'signInState',
  initialState,
  reducers: {
    reinitializeSignInState: (
      state: SignInStateInterface,
    ): SignInStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(postSignInAction.pending, (state: SignInStateInterface) => {
        return {
          ...state,
          isLoadingPostSignIn: true,
          signInSuccess: false,
          signInError: false,
        }
      })
      .addCase(
        postSignInAction.fulfilled,
        (
          state: SignInStateInterface,
          action: PayloadAction<{ access: string; refresh: string }>,
        ) => {
          const { access, refresh } = action.payload
          return {
            ...state,
            isLoadingPostSignIn: false,
            signInSuccess: Boolean(access && refresh),
            signInError: !access || !refresh,
          }
        },
      )
      .addCase(
        postSignInAction.rejected,
        (
          state: SignInStateInterface,
          action: PayloadAction<any>,
        ): SignInStateInterface => {
          let signInMessageError = ''
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (action?.error?.message) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            signInMessageError = action.error.message
          }
          return {
            ...state,
            isLoadingPostSignIn: false,
            signInSuccess: false,
            signInError: true,
            signInMessageError,
          }
        },
      )
  },
})

export const { reinitializeSignInState } = signInSlice.actions
export const signInReducer = signInSlice.reducer
