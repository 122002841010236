import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'
import { AnalyticsProgressStatusInterface } from '../../../../store/interfaces/analytics-details/analytics-progress-status.interface'

// /!\ route API incorrect. ajouter validate au path actuel. Utiliser cette route : PUT /reports/readership-profile/{analyticsId}/validate
export const postReportByIdReadershipProfileValidateService = (
  { reportId }: { reportId: string },
  cancelToken?: CancelToken,
): Promise<AxiosResponse<AnalyticsProgressStatusInterface>> =>
  apiInstance.post(
    ApiPathConfig.REPORT_READERSHIP_PROFILE_BY_ID(reportId),
    {},
    { cancelToken },
  )
