import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useState } from 'react'
import { PermissionEnum } from '../../core/enums/permission.enum'
import { ReactComponent as BottomChevronIcon } from '../../assets/icons/app/bottom-chevron.icon.svg'
import { PersonInterface } from '../../core/enums/person.interface'
import { TeamInterface } from '../../core/interface/team.interface'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'

const ContainerStyle = styled.div`
  position: relative;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const ButtonMainStyle = styled.button`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e2e2e2;
  }
`

const ButtonStyle = styled.button`
  width: 100%;
  padding: 8px 4px;
  border-radius: 4px;
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e2e2e2;
  }
`

const MenuStyle = styled.button`
  background-color: white;
  position: absolute;
  top: 42px;
  right: 0;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 8px;
  min-width: 160px;
  max-width: 160px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const PermissionsMenuLight = ({
  permission,
  handleChangePermission,
  users,
  teams,
  analyticsType,
}: {
  permission: PermissionEnum
  handleChangePermission: (permission: PermissionEnum) => void
  users: PersonInterface[]
  teams: TeamInterface[]
  analyticsType?: AnalyticsTypeEnum
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <ContainerStyle>
        <ButtonMainStyle
          type="button"
          className="flex flex-row justify-around items-center px-2 py-1 gap-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{t(`commons.permissions.${permission}`)}</span>
          <BottomChevronIcon className="w-4 h-4" />
        </ButtonMainStyle>
      </ContainerStyle>
      {(isOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full"
            onClick={() => setIsOpen(false)}
          />
          <MenuStyle className="flex flex-col">
            {analyticsType !== AnalyticsTypeEnum.READERSHIP_PROFILE && (
              <ButtonStyle
                type="button"
                className="flex flex-row items-center"
                onClick={() => {
                  handleChangePermission(PermissionEnum.EDIT)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 100)
                }}
              >
                <span>{t('commons.permissions.edit')}</span>
              </ButtonStyle>
            )}
            <ButtonStyle
              type="button"
              className="flex flex-row items-center"
              onClick={() => {
                handleChangePermission(PermissionEnum.READ)
                setTimeout(() => {
                  setIsOpen(false)
                }, 100)
              }}
            >
              <span>{t('commons.permissions.read')}</span>
            </ButtonStyle>
          </MenuStyle>
        </>
      )) ||
        null}
    </>
  )
}

export default PermissionsMenuLight
