import {
  CompareGroupType,
  StudyObjectType,
} from '../../../components/analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { AuthorInterface } from '../../../core/interface/author.interface'
import { BookInterface } from '../../../core/interface/book.interface'
import { ReadershipProfilePreviewInterface } from '../../../core/interface/analytics/readership-profile-preview.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticPreviewTypeErrorEnum } from '../../../core/enums/analytic-preview-type-error'

export enum NewReadershipProfileStepEnum {
  START = 'start',
  PREVIEW = 'preview',
}

export type NewReadershipProfileStepType =
  | NewReadershipProfileStepEnum.START
  | NewReadershipProfileStepEnum.PREVIEW

export type StudyObjectValueType =
  | BookInterface[]
  | AuthorInterface[]
  | string
  | null
export type CompareGroupValueType =
  | BookInterface[]
  | AuthorInterface[]
  | string[]
  | null

export interface NewReadershipProfileStateInterface {
  analyticsName: string
  step: NewReadershipProfileStepType
  studyObjectType: StudyObjectType
  studyObjectValue: StudyObjectValueType
  studyObjectBooks: BookInterface[]
  compareGroupType: CompareGroupType
  compareGroupValue: CompareGroupValueType
  compareGroupBooks: BookInterface[]
  isLoadingPostPreview: boolean
  postPreviewSuccess: boolean
  postPreviewError: boolean
  previewId: string | null
  isLoadingGetPreviewById: boolean
  getPreviewByIdSuccess: boolean
  getPreviewByIdError: boolean
  previewOutput: ReadershipProfilePreviewInterface | null
  isLoadingReadershipProfileValidation: boolean
  analyticsReadershipProfilePreview: AnalyticDetailsInterface | null
  postReadershipProfileValidateSuccess: boolean
  postReadershipProfileValidateError: boolean
  postPreviewTypeError: AnalyticPreviewTypeErrorEnum | null
}
