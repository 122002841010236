import { useEffect, useState } from 'react'
import AddModuleOptionsView from './AddModuleOptionsView'
import { AddModuleStepEnum } from '../../../../../../core/enums/add-module-step.enum'
import { DatasetModuleAvailableModulesInterface } from '../../../../../../core/interface/analytics/analytic-available-modules.interface'
import { InterfaceRepresentationDataList } from './AddModuleView'
import { analyticsDetailsV2StateGetDatasetModulesPreviewAction } from '../../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { selectAnalyticDetailsStateDatasetModulesPreview } from '../../../../../../store/getters/analytics-details/analytic-details-v2.getters'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import GlLoader from '../../../../../share/GlLoader'
import { SetColorEnum } from '../../../../../../core/enums/set-color.enum'

const AddModuleOptions = ({
  setStep,
  handleClickOnModule,
  datasetModuleSelected,
  dataRepresentationSelected,
  outputIdOfSelectedDataset,
  replaceModule,
  analyticsSetColor,
}: {
  setStep: (step: AddModuleStepEnum) => void
  handleClickOnModule: (
    datasetModule:
      | DatasetModuleAvailableModulesInterface
      | DatasetModuleInterface,
    outputId: string,
  ) => void
  datasetModuleSelected: DatasetModuleAvailableModulesInterface | undefined
  dataRepresentationSelected: InterfaceRepresentationDataList
  outputIdOfSelectedDataset: string
  replaceModule: boolean
  analyticsSetColor: SetColorEnum
}) => {
  const dispatch = useAppDispatch()

  const datasetModulesPreview = useAppSelector(
    selectAnalyticDetailsStateDatasetModulesPreview,
  )

  const [datasetModule, setDatasetModule] = useState<
    DatasetModuleInterface | undefined
  >(
    datasetModulesPreview?.find(
      (datasetModule) => datasetModule.id === datasetModuleSelected?.id,
    ),
  )

  useEffect(() => {
    if (!datasetModuleSelected || !datasetModulesPreview) return
    const datasetModulePreview = datasetModulesPreview.find(
      (datasetModule) => datasetModule.id === datasetModuleSelected.id,
    )
    if (datasetModulePreview) {
      setDatasetModule(datasetModulePreview)
    } else {
      void dispatch(
        analyticsDetailsV2StateGetDatasetModulesPreviewAction({
          outputId: outputIdOfSelectedDataset,
          datasetModule: { ...datasetModuleSelected, is_preview: true },
        }),
      )
    }
  }, [datasetModulesPreview])

  return datasetModule ? (
    <AddModuleOptionsView
      setStep={setStep}
      handleClickOnModule={handleClickOnModule}
      datasetModuleSelected={datasetModule}
      setDatasetModuleSelected={setDatasetModule}
      dataRepresentationSelected={dataRepresentationSelected}
      outputIdOfSelectedDataset={outputIdOfSelectedDataset}
      replaceModule={replaceModule}
      analyticsSetColor={analyticsSetColor}
    />
  ) : (
    <GlLoader />
  )
}

export default AddModuleOptions
