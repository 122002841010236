import React from 'react'
import { ToggleButtonOptionInterface } from '../../../components/share/GlToggleButton'
import { AnalyticsTypeEnum } from '../../enums/analytics-type.enum'
import { ReactComponent as CardViewIcon } from '../../../assets/icons/app/card-view.icon.svg'
import { ReactComponent as WorkMirrorIcon } from '../../../assets/icons/app/work-mirror.icon.svg'
import { ReactComponent as UniversIcon } from '../../../assets/icons/app/univers.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../../assets/icons/readership-profil-card.icon.svg'
import AnalyticsDashboardAvailableModuleType from '../../types/analytics-dashboard-available-module.interface'
import AnalyticsDashboardType from '../../enums/analytics-dashboard-type.enum'

const reportsToggleOptionConfig: ToggleButtonOptionInterface<AnalyticsDashboardAvailableModuleType>[] =
  [
    {
      label: 'all',
      value: AnalyticsDashboardType.ALL,
      icon: <CardViewIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'worksMirrors',
      value: AnalyticsTypeEnum.WORK_MIRROR,
      icon: <WorkMirrorIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'readership_profile',
      value: AnalyticsTypeEnum.READERSHIP_PROFILE,
      icon: <ReadershipProfilIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
    {
      label: 'univers',
      value: AnalyticsTypeEnum.UNIVERS,
      icon: <UniversIcon className="w-[18px] h-[18px]" />,
      enable: true,
    },
  ]

export default reportsToggleOptionConfig
