import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import './import-days'
import './styles/index.scss'
import './i18n'
import router from './core/router/router'
import store from './store/store'
import { injectStore } from './core/api/backend-api.setup'

injectStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
)
