import { ReactElement } from 'react'
import { ReactComponent as RankNCIcon } from '../../assets/icons/progresses/rank-nc-icon.svg'
import { ReactComponent as RankNoneIcon } from '../../assets/icons/progresses/rank-none-icon.svg'
import { ReactComponent as RankDown1Icon } from '../../assets/icons/progresses/rank-down-1-icon.svg'
import { ReactComponent as RankDown2Icon } from '../../assets/icons/progresses/rank-down-2-icon.svg'
import { ReactComponent as RankDown3Icon } from '../../assets/icons/progresses/rank-down-3-icon.svg'
import { ReactComponent as RankUp1Icon } from '../../assets/icons/progresses/rank-up-1-icon.svg'
import { ReactComponent as RankUp2Icon } from '../../assets/icons/progresses/rank-up-2-icon.svg'
import { ReactComponent as RankUp3Icon } from '../../assets/icons/progresses/rank-up-3-icon.svg'

export enum ProgressEnum {
  NC,
  RANK_UP_3,
  RANK_UP_2,
  RANK_UP_1,
  RANK_NONE,
  RANK_DOWN_1,
  RANK_DOWN_2,
  RANK_DOWN_3,
}

export const getProgressEnumValue = (progress: number | null): ProgressEnum => {
  if (progress !== null) {
    if (progress > 30) return ProgressEnum.RANK_UP_3
    if (progress > 15 && progress <= 30) return ProgressEnum.RANK_UP_2
    if (progress > 0 && progress <= 15) return ProgressEnum.RANK_UP_1
    if (progress === 0) return ProgressEnum.RANK_NONE
    if (progress >= -15 && progress < 0) return ProgressEnum.RANK_DOWN_1
    if (progress >= -30 && progress < -15) return ProgressEnum.RANK_DOWN_2
    if (progress < -30) return ProgressEnum.RANK_DOWN_3
  }

  return ProgressEnum.NC
}

export const getIconProgress = (
  progressEnum: ProgressEnum | number,
): ReactElement => {
  switch (progressEnum) {
    case ProgressEnum.RANK_UP_3:
      return <RankUp3Icon />
    case ProgressEnum.RANK_UP_2:
      return <RankUp2Icon />
    case ProgressEnum.RANK_UP_1:
      return <RankUp1Icon />
    case ProgressEnum.RANK_NONE:
      return <RankNoneIcon />
    case ProgressEnum.RANK_DOWN_1:
      return <RankDown1Icon />
    case ProgressEnum.RANK_DOWN_2:
      return <RankDown2Icon />
    case ProgressEnum.RANK_DOWN_3:
      return <RankDown3Icon />
    default:
      return <RankNCIcon />
  }
}

export const getProgressColor = (progress: number | null): string => {
  if (progress !== null) {
    if (progress && progress > 0) {
      return '#89DA8E'
    }
    if (progress && progress < 0) {
      return '#F97E60'
    }
  }
  return '#595959'
}
