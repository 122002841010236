import React, { useCallback, useEffect, useRef, useState } from 'react'
import { select, zoom } from 'd3'
import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../../core/interface/analytics/dataset.interface'
import { DatasetTypeEnum } from '../../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../../core/interface/analytics/source.enum'
import ConstellationTooltip from './tooltip/ConstellationTooltip'
import ConstellationItem from './ConstellationChart/ConstellationItem'
import ConstellationDataInterface from './ConstellationChart/constellation-data.interface'
import ConstellationPositionUtil from './ConstellationChart/constellation-position.util'

interface Props {
  dataset: DatasetInterface[]
  typeDataset: DatasetTypeEnum
  refresh: number
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
}

const ConstellationChart = ({
  dataset,
  typeDataset,
  refresh,
  eanSelected,
  handleOpenBookDetails,
}: Props) => {
  const { t } = useTranslation()
  const svgRef = useRef<any>(null)
  const svgContainerRef = useRef<any>(null)
  const [constellationItem, setConstellationItem] = useState<{
    level1: ConstellationDataInterface[]
    level2: ConstellationDataInterface[]
    level3: ConstellationDataInterface[]
  }>({
    level1: [],
    level2: [],
    level3: [],
  })
  const [tooltip, setTooltip] = useState<ConstellationDataInterface | null>(
    null,
  )
  const [transform, setTransform] = useState<{
    x: number
    y: number
    k: number
  }>({ x: 0, y: 0, k: 1 })

  const setTransformCb = useCallback(
    (values: { x: number; y: number; k: number }) => {
      setTransform(values)
    },
    [transform],
  )

  const radiusBaseCircle: number[] = [127, 210, 293]

  const isAuthor = typeDataset === DatasetTypeEnum.AUTHORS_MIRROR

  useEffect(() => {
    const svg = select(svgRef.current)
    const svgContainer = select(svgContainerRef.current)
    const width = svgRef?.current?.clientWidth
    const height = svgRef?.current?.clientHeight

    if (dataset.length) {
      const { level1, level2, level3 } = ConstellationPositionUtil({
        center: { x: width / 2, y: height / 2 },
        radiusBaseCircle,
        dataset,
        isAuthor,
      })
      setConstellationItem({ level1, level2, level3 })
    }

    svg.call(
      zoom().on('zoom', (event) => {
        svgContainer.attr('transform', event.transform)
        setTransformCb(event.transform)
      }),
    )

    return () => {}
  }, [refresh])

  const onClick = (eanId: number | undefined) => {
    eanId &&
      handleOpenBookDetails &&
      handleOpenBookDetails(eanSelected === eanId ? null : eanId, false)
  }

  const graphHeight = svgRef.current?.clientHeight
  const graphWidth = svgRef.current?.clientWidth
  const menuPositionTop =
    tooltip && tooltip.y * transform.k + transform.y >= graphHeight / 2

  return (
    <div className="relative flex-1 w-full h-full ">
      <div
        className="absolute top-0 w-full h-full pointer-events-none"
        style={{
          boxShadow:
            'inset 0px 0px 100px 0px rgba(255, 255, 255, 0.65), inset 0px 0px 100px 0px rgba(255, 255, 255, 0.65), inset 0px 0px 100px 0px rgba(255, 255, 255, 0.65)',
        }}
      />
      <svg
        ref={svgRef}
        className="w-full h-full bg-transparent"
        preserveAspectRatio="xMidYMid slice"
      >
        <g ref={svgContainerRef}>
          {radiusBaseCircle.map((r) => (
            <circle
              key={r}
              cx="50%"
              cy="50%"
              r={r}
              stroke="#25252533"
              fill="none"
            />
          ))}
          <rect
            x="50%"
            y="50%"
            width="120"
            height="28"
            rx="5"
            ry="5"
            transform="translate(-60,-14)"
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            fill="white"
            dominantBaseline="middle"
            fontWeight={500}
          >
            {t('studyObjectValue')}
          </text>
          {Object.values(constellationItem).map(
            (level: ConstellationDataInterface[]) =>
              level.map((value: ConstellationDataInterface) => (
                <ConstellationItem
                  key={`node-${value?.data[SourceEnum.RANK]}`}
                  data={value}
                  isAuthor={isAuthor}
                  onMouseEnter={setTooltip}
                  onMouseLeave={setTooltip}
                  onClick={(!isAuthor && onClick) || undefined}
                />
              )),
          )}
        </g>
      </svg>
      {tooltip && (
        <div
          style={{
            width: '300px',
            position: 'absolute',
            top:
              transform.y +
              tooltip.y * transform.k +
              (menuPositionTop ? -30 : 30) * transform.k,
            left: transform.x + tooltip.x * transform.k - 150,
            zIndex: 10,
            transform: `translate(${
              ((graphWidth / 2 - tooltip.x * transform.k - transform.x) /
                graphWidth) *
              100
            }%,${menuPositionTop ? '-100%' : '0'})`,
          }}
        >
          <ConstellationTooltip dataset={tooltip.data} isAuthor={isAuthor} />
        </div>
      )}
    </div>
  )
}

export default ConstellationChart
