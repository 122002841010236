import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/app/cross.icon.svg'
import { ReactComponent as ReadershipProfileScreenShot } from '../../assets/images/readership-profile-screen-shot.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import { useAppDispatch } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import AnalyticsDashboardModuleInterface from '../../core/interface/analytics-dashboard/analytics-dashboard-module.interface'
import AnalyticIcon from './AnalyticIcon'
import getAnalyticColor from '../../core/utils/get-analytic-color.util'
import GlSpinner from '../share/GlSpinner'
import GlProBadge from '../share/badge/GlProBadge'

const DialogTitle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 500;
  line-height: 32px; /* 160% */
`

const ButtonActionStyle = styled.button`
  background-color: #252525;
  color: white;
  width: 100%;
  height: 48px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;

  :hover {
    background-color: #252525cc;
  }

  :active {
    background-color: #252525e6;
  }

  :disabled {
    background-color: #252525e6;
  }

  &:disabled.not-available {
    border: 1px solid #252525;
    background-color: white;
    color: #252525;
  }
`

const TitleCardStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: Vollkorn, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  letter-spacing: 0.48px;
`

const ContentCardStyle = styled.div`
  color: #252525;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const ExplainStyle = styled.div`
  color: #252525;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const AnalyticsDashboardAddAnalyticsDialog = ({
  dashboardModules,
  isLoadingMySelf,
  availableTokens,
  isPrivate,
  handleCloseDialog,
}: {
  dashboardModules: Array<AnalyticsDashboardModuleInterface>
  isLoadingMySelf?: boolean
  availableTokens?: number
  isPrivate?: boolean
  handleCloseDialog: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [explainReadershipProfile, setExplainReadershipProfile] =
    useState<boolean>(false)

  const onClickReturnButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setExplainReadershipProfile(false)
  }
  const onClickKnowMoreButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setExplainReadershipProfile(true)
  }
  const onClickCloseDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseDialog()
  }

  const getTitle = (type: AnalyticsTypeEnum) => {
    switch (type) {
      case AnalyticsTypeEnum.WORK_MIRROR:
      case AnalyticsTypeEnum.PRIVATE_WORK_MIRROR:
        return t('worksMirrors')
      case AnalyticsTypeEnum.READERSHIP_PROFILE:
      case AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE:
        return t('readership_profile')
      case AnalyticsTypeEnum.AUDIENCE:
        return t('audience')
      case AnalyticsTypeEnum.UNIVERS:
        return t('univers')
      default:
        return null
    }
  }
  const getContent = (type: AnalyticsTypeEnum) => {
    switch (type) {
      case AnalyticsTypeEnum.WORK_MIRROR:
      case AnalyticsTypeEnum.PRIVATE_WORK_MIRROR:
        return t('workMirrorExplain')
      case AnalyticsTypeEnum.READERSHIP_PROFILE:
      case AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE:
        return t('readershipProfileExplain')
      case AnalyticsTypeEnum.AUDIENCE:
        return t('audienceExplain')
      case AnalyticsTypeEnum.UNIVERS:
        return t('ComingSoon')
      default:
        return null
    }
  }

  const getButtonContent = (module: AnalyticsDashboardModuleInterface) => {
    if (!module.enable) return <span>{t('ComingSoon')}</span>
    switch (module.type) {
      case AnalyticsTypeEnum.WORK_MIRROR:
      case AnalyticsTypeEnum.AUDIENCE:
      case AnalyticsTypeEnum.PRIVATE_WORK_MIRROR:
      case AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE:
        return t('illimited')
      case AnalyticsTypeEnum.READERSHIP_PROFILE:
        return (
          (isLoadingMySelf && (
            <div className="flex items-center justify-center w-full h-full">
              <GlSpinner />
            </div>
          )) || (
            <span>
              {t('createReadershipProfile', { count: availableTokens })}
            </span>
          )
        )
      default:
        return t('ComingSoon')
    }
  }

  const onClickNew = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement>,
      module: AnalyticsDashboardModuleInterface,
    ) => {
      e.preventDefault()
      if (!module.enable) return
      if (
        module.type === AnalyticsTypeEnum.READERSHIP_PROFILE &&
        !availableTokens
      ) {
        dispatch(setShowSnackBar(t('notEnoughReadershipProfileToken')))
      }
      navigate(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          ...(isPrivate
            ? [PathConfig.PRIVATE_REPORTS, module.type]
            : [PathConfig.ANALYTICS, `${PathConfig.NEW}?type=${module.type}`]),
        ]),
      )
    },
    [availableTokens, isPrivate],
  )

  return (
    <div
      className="flex flex-col gap-4"
      style={{
        width: 968,
        height: 660,
      }}
    >
      <DialogTitle className="relative">
        {(explainReadershipProfile && (
          <span>
            <button
              type="button"
              className="flex items-center justify-center gap-2"
              onClick={onClickReturnButton}
            >
              <LeftArrowIcon
                style={{
                  width: 20,
                  height: 20,
                }}
              />
              <span>{t('commons.buttons.back')}</span>
            </button>
          </span>
        )) ||
        isPrivate ? (
          <span>{t('accessPrivateReports')}</span>
        ) : (
          <span>{t('createNewAnalytics')}</span>
        )}
        <button
          type="button"
          className="absolute top-2 right-2"
          onClick={onClickCloseDialog}
        >
          <CrossIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        </button>
      </DialogTitle>
      {(explainReadershipProfile && (
        <div className="flex flex-row gap-8 h-full">
          <div
            className="basis-1/2 flex items-end justify-center"
            style={{
              borderRadius: 8,
              background:
                'linear-gradient(180deg, #7BBFFF 0%, rgba(123, 191, 255, 0.10) 100%)',
            }}
          >
            <ReadershipProfileScreenShot />
          </div>
          <div className="basis-1/2 flex flex-col gap-8">
            <TitleCardStyle className="flex justify-start">
              <span>{t('ReadershipProfileWhatsThePoint')}</span>
            </TitleCardStyle>
            <ExplainStyle>{t('ReadershipProfileWhatsThePoint1')}</ExplainStyle>
            <ExplainStyle>{t('ReadershipProfileWhatsThePoint2')}</ExplainStyle>
            <ExplainStyle
              dangerouslySetInnerHTML={{
                __html: t('ReadershipProfileWhatsThePoint3'),
              }}
            />
          </div>
        </div>
      )) || (
        <div className="flex flex-row gap-4 h-full">
          {dashboardModules.map((module) => (
            <div className="flex-1 flex flex-col gap-2">
              <div
                className="flex-auto flex flex-col items-center justify-between"
                style={{
                  borderRadius: 8,
                  background: `linear-gradient(180deg, ${
                    module.enable ? getAnalyticColor(module.type) : '#BEBEBE'
                  } 0%, ${
                    module.enable ? getAnalyticColor(module.type) : '#BEBEBE'
                  }1A 100%)`,
                }}
              >
                <div className="flex flex-col my-[32px] items-center justify-center gap-4 p-2">
                  <AnalyticIcon type={module.type} color="black" size={48} />
                  {(isPrivate && (
                    <div className="flex flex_row items-center gap-1">
                      <TitleCardStyle>{getTitle(module.type)}</TitleCardStyle>
                      <GlProBadge />
                    </div>
                  )) || (
                    <TitleCardStyle>{getTitle(module.type)}</TitleCardStyle>
                  )}
                  <ContentCardStyle>{getContent(module.type)}</ContentCardStyle>
                  {(module.type === AnalyticsTypeEnum.READERSHIP_PROFILE ||
                    module.type ===
                      AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE) && (
                    <button
                      type="button"
                      className="flex items-center justify-center gap-1"
                      onClick={onClickKnowMoreButton}
                    >
                      <span className="font-bold">{t('KnowMore')}</span>
                    </button>
                  )}
                </div>
              </div>
              <ButtonActionStyle
                type="button"
                className="flex items-center justify-center"
                onClick={(e) => onClickNew(e, module)}
                disabled={isLoadingMySelf || !module.enable}
              >
                {getButtonContent(module)}
              </ButtonActionStyle>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnalyticsDashboardAddAnalyticsDialog
