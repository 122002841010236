import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  NEW_ANALYTICS_READERSHIP_PROFILE_STATE_GET_PREVIEW_FOR_READERSHIP_PROFILE_ACTION,
  NEW_ANALYTICS_READERSHIP_PROFILE_STATE_POST_PREVIEW_FOR_READERSHIP_PROFILE_ACTION,
  NEW_ANALYTICS_READERSHIP_PROFILE_STATE_PUT_ANALYTICS_BY_ID_VALIDATE,
} from '../../prefix-actions.config'
import { RootState } from '../../store'
import { postReadershipProfilePreviewService } from '../../../core/api/services/bookmetrie/analytics/post-readership-profile-preview.service'
import { BookInterface } from '../../../core/interface/book.interface'
import { postReportByIdPurchaseService } from '../../../core/api/services/bookmetrie/post-report-by-id-purchage.service'
import { postReportByIdReadershipProfileValidateService } from '../../../core/api/services/bookmetrie/post-report-by-id-readership-profile-validate.service'
import { setReadershipProfileGenerating } from '../../reducers/web-app.reducer'
import { newReadershipProfileStateSetPostPreviewTypeError } from '../../reducers/analytics-details/new-analytics-readership-profile-state.reducer'
import getAnalyticsIdsValueUtils from '../../../core/utils/getAnalyticsIdsValue.utils'

export const newAnalyticsReadershipProfileStateGetPreviewForReadershipProfile =
  createAsyncThunk(
    NEW_ANALYTICS_READERSHIP_PROFILE_STATE_GET_PREVIEW_FOR_READERSHIP_PROFILE_ACTION,
    async (
      { previewId }: { previewId: string },
      { getState },
    ): Promise<any> => {
      try {
        return await new Promise((resolve, reject) => {
          setTimeout(() => {
            Math.random() > 0.2 ? resolve(true) : reject()
          }, 1000)
        })
      } catch (error) {
        throw new Error()
      }
    },
  )

export const newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile =
  createAsyncThunk(
    NEW_ANALYTICS_READERSHIP_PROFILE_STATE_POST_PREVIEW_FOR_READERSHIP_PROFILE_ACTION,
    async (_, { getState, dispatch }): Promise<any> => {
      try {
        const { newAnalyticsReadershipProfileState } = getState() as RootState
        const {
          analyticsName,
          studyObjectType,
          studyObjectValue,
          studyObjectBooks,
          compareGroupType,
          compareGroupValue,
          compareGroupBooks,
        } = newAnalyticsReadershipProfileState

        const { data } = await postReadershipProfilePreviewService({
          title: analyticsName,
          studyObject: {
            type: studyObjectType,
            ids: getAnalyticsIdsValueUtils(studyObjectType, studyObjectValue),
            eans: studyObjectBooks.map((book: BookInterface) => book.id_ean),
          },
          compareGroup: {
            type: compareGroupType,
            ids: getAnalyticsIdsValueUtils(compareGroupType, compareGroupValue),
            eans: compareGroupBooks.map((book: BookInterface) => book.id_ean),
          },
        })

        return data
      } catch (error: any) {
        if (error.response.data.errors[0].code) {
          dispatch(
            newReadershipProfileStateSetPostPreviewTypeError(
              error.response.data.errors[0].code,
            ),
          )
        }

        throw new Error()
      }
    },
  )

export const newAnalyticsReadershipProfileStatePutAnalyticsByIdValidate =
  createAsyncThunk(
    NEW_ANALYTICS_READERSHIP_PROFILE_STATE_PUT_ANALYTICS_BY_ID_VALIDATE,
    async (reportId: string, { getState, dispatch }): Promise<null> => {
      const { webAppState } = getState() as RootState
      try {
        const { status } = await postReportByIdPurchaseService({ reportId })
        if (status === 201) {
          const { data, status } =
            await postReportByIdReadershipProfileValidateService({ reportId })

          dispatch(
            setReadershipProfileGenerating([
              ...webAppState.readershipProfileGenerating,
              { data, show: true },
            ]),
          )
        }
        throw new Error()
      } catch (error) {
        throw new Error()
      }
    },
  )
