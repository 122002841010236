import React from 'react'

const GlProBadge = () => {
  return (
    <span className="bg-[#3B3B3B] px-2 rounded-lg text-white font-medium text-xs">
      Pro
    </span>
  )
}

export default GlProBadge
