import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const getAnalyticsLightService = ({
  publicValue,
  ordering,
  page_size = 50,
}: {
  publicValue: boolean
  ordering?: string
  page_size?: number
}) => {
  return apiInstance.get(ApiPathConfig.REPORTS, {
    params: {
      public: publicValue,
      ordering,
      page_size,
    },
  })
}
