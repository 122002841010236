import React, { memo, useState } from 'react'
import '../../styles/_shared.scss'
import '../../styles/components/Header.scss'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoIcon } from '../../assets/icons/bookmetrie-logo.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setWebAppStateLoggedOut } from '../../store/reducers/web-app.reducer'
import GlMenu from '../share/menu/GlMenu'
import useOutsideClick from '../../core/hooks/useClickOutsideOld.hook'
import {
  selectWebAppStateBusinessUnitName,
  selectWebAppStateFullName,
} from '../../store/getters/web-app.getters'

import { ReactComponent as PersonIcon } from '../../assets/icons/app/person.icon.svg'
import { ReactComponent as LegalIcon } from '../../assets/icons/app/legal.icon.svg'
import { ReactComponent as LogOutIcon } from '../../assets/icons/app/log-out.icon.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import clearLocalStorageUtil from '../../core/utils/clear-local-storage.util'

const Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: white;
  display: flex;
`

const UserNameStyle = styled.span`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
`

const CompanyNameStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
`

function HeaderMenu() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const businessUnit = useAppSelector(selectWebAppStateBusinessUnitName)
  const fullName = useAppSelector(selectWebAppStateFullName)

  const [opened, setOpened] = useState<boolean>(false)
  const ref = useOutsideClick(() => setOpened(false))

  function logOut() {
    void clearLocalStorageUtil()
    dispatch(setWebAppStateLoggedOut())
  }

  return (
    <GlMenu
      opened={opened}
      button={
        <button
          type="button"
          ref={ref}
          onClick={() => setOpened(!opened)}
          className="flex flex-row gap-2 justify-between items-center"
        >
          <div className="flex flex-col text-right">
            <UserNameStyle>{fullName}</UserNameStyle>
            <CompanyNameStyle>{businessUnit}</CompanyNameStyle>
          </div>

          {opened ? (
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 10.2364L7.76119 5L2.52727 10.2364"
                  stroke="#595959"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 5.76364L7.76119 11L2.52727 5.76364"
                  stroke="#595959"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </button>
      }
    >
      <Link
        to={buildRelativePath(
          [PathConfig.BOOKMETRIE, PathConfig.MANAGE],
          PathConfig.SETTINGS_PROFILE,
        )}
        className="m-1 p-2 flex flex-row justify-between w-100 hover:bg-[#595959] rounded items-center button-select-item"
      >
        <Label className="items-center flex flex-row gap-2">
          <PersonIcon className="w-4 h-4" />
          {t('webApp.header.menu.profile')}
        </Label>
      </Link>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to={buildRelativePath([PathConfig.LEGAL], PathConfig.SETTINGS_LEGAL)}
        className="m-1 p-2 flex flex-row justify-between w-100 hover:bg-[#595959] rounded items-center button-select-item"
      >
        <Label className="items-center flex flex-row gap-2">
          <LegalIcon className="w-4 h-4" />
          {t('webApp.header.menu.legal')}
        </Label>
      </Link>

      <button
        type="button"
        onClick={logOut}
        className="m-1 p-2 flex flex-row justify-between w-100 hover:bg-[#595959] rounded items-center button-select-item"
      >
        <Label className="items-center flex flex-row gap-2">
          <LogOutIcon className="w-4 h-4" />
          {t('webApp.header.menu.logout')}
        </Label>
      </button>
    </GlMenu>
  )
}

const WebAppHeader = () => {
  return (
    <header
      className="flex flex-row bg-[#252525] justify-between py-3 px-6 relative top-0 h-12 items-center"
      style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}
    >
      <Link to={PathConfig.BASE}>
        <LogoIcon />
      </Link>
      <HeaderMenu />
    </header>
  )
}

export default memo(WebAppHeader)
