import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { getMySelfService } from '../api/services/bookmetrie/get-my-self.service'
import { MySelfInterface } from '../interface/my-self.interface'

interface UseAnalyticsDashboardAddAnalyticsState {
  isLoadingMySelf: boolean
  availableTokens: number
  getMySelfSuccess: boolean
  getMySelfError: boolean
}

interface UseReadershipProfileGetMySelfAvailableTokenHook {
  state: UseAnalyticsDashboardAddAnalyticsState
}
const useReadershipProfileGetMySelfAvailableTokenHook =
  (): UseReadershipProfileGetMySelfAvailableTokenHook => {
    const [isLoadingMySelf, setIsLoadingMySelf] = useState<boolean>(true)
    const [availableTokens, setAvailableTokens] = useState<number>(0)
    const [getMySelfSuccess, setGetMySelfSuccess] = useState<boolean>(false)
    const [getMySelfError, setGetMySelfError] = useState<boolean>(false)

    const getMySelfAction = () => {
      getMySelfService()
        .then(({ data: mySelf }: AxiosResponse<MySelfInterface>) => {
          setGetMySelfSuccess(true)
          setGetMySelfError(false)
          setAvailableTokens(
            mySelf?.features?.reports?.report_readership_profile
              .available_tokens || 0,
          )
        })
        .catch(() => {
          setGetMySelfSuccess(false)
          setGetMySelfError(true)
        })
        .finally(() => setIsLoadingMySelf(false))
    }
    useEffect(() => {
      getMySelfAction()
    }, [])

    return {
      state: {
        isLoadingMySelf,
        availableTokens,
        getMySelfSuccess,
        getMySelfError,
      },
    }
  }

export default useReadershipProfileGetMySelfAvailableTokenHook
