const RadarDot = ({ cx, cy, stroke, index }: any) => {
  return (
    <svg key={index}>
      <circle cx={cx} cy={cy} r="7.12453" fill={stroke} fillOpacity="0.1" />
      <circle cx={cx} cy={cy} r="2.6717" fill={stroke} />
    </svg>
  )
}

export default RadarDot
