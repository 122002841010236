import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const deleteUserToShareReportService = (
  reportId: string,
  userId: string,
  cancelToken?: CancelToken,
) => {
  return apiInstance.delete(
    ApiPathConfig.REPORT_BY_ID_USERS_BY_ID(reportId, userId),
    { cancelToken },
  )
}
