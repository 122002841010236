import { CancelToken } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'

export const putDatasetModulesOrderService = (
  reportId: string,
  outputId: string | undefined,
  datasetModules: { dataset_modules: (string | undefined)[] },
  cancelToken?: CancelToken,
) =>
  apiInstance.put(
    ApiPathConfig.REPORT_BY_ID_OUTPUT_BY_ID_DATASET_MODULES_ORDER(
      reportId,
      outputId,
    ),
    datasetModules,
    {
      cancelToken,
    },
  )
